import User from "../../../models/User";
import { injectable } from "inversify";
import Cohort from "../../../models/Cohort";
import Course from "../../../models/Course";
import { LearningPlan } from "../../../models/LearningPlan";
import { Survey } from "../../../models/Survey";

export interface ISearchFilter {
    usersForSearchTerm(term: string, data: User[]): User[]
    cohortsForSearchTerm(term: string, data: Cohort[]): Cohort[]
    trainingsForSearchTerm(term: string, data: Course[]): Course[]
    learningPlansForSearchTerm(term: string, data: LearningPlan[]): LearningPlan[]
    surveysForSearchTerm(term: string, data: Survey[]): Survey[]
}

@injectable()
export class SearchFilter implements ISearchFilter {
    usersForSearchTerm(term: string, data: User[]): User[] {
        const canonicalTerm = term.trim().toLowerCase().replace(/\s/g, "")

        if (data.length === 0 || canonicalTerm === "") {
            return []
        }

        let results: User[] = []

        data.forEach(user => {
            const canonicalName = user.firstName.toLowerCase().replace(/\s/g, "") + user.lastName.toLowerCase().replace(/\s/g, "")
            if (canonicalName.includes(canonicalTerm)) {
                results.push(user)
            }
        })

        return results
    }

    cohortsForSearchTerm(term: string, data: Cohort[]): Cohort[] {
        const canonicalTerm = term.trim().toLowerCase().replace(/\s/g, "")

        if (data.length === 0 || canonicalTerm === "") {
            return []
        }

        let results: Cohort[] = []

        data.forEach(cohort => {
            const canonicalName = cohort.name.toLowerCase().replace(/\s/g, "")
            if (canonicalName.includes(canonicalTerm)) {
                results.push(cohort)
            }
        })

        return results
    }

    trainingsForSearchTerm(term: string, data: Course[]): Course[] {
        const canonicalTerm = term.trim().toLowerCase().replace(/\s/g, "")

        if (data.length === 0 || canonicalTerm === "") {
            return []
        }

        let results: Course[] = []

        data.forEach(training => {
            const canonicalName = training.name.toLowerCase().replace(/\s/g, "")
            if (canonicalName.includes(canonicalTerm)) {
                results.push(training)
            }
        })

        return results
    }

    learningPlansForSearchTerm(term: string, data: LearningPlan[]): LearningPlan[] {
        const canonicalTerm = term.trim().toLowerCase().replace(/\s/g, "")

        if (data.length === 0 || canonicalTerm === "") {
            return []
        }

        let results: LearningPlan[] = []

        data.forEach(learningPlan => {
            const canonicalName = learningPlan.name.toLowerCase().replace(/\s/g, "")
            if (canonicalName.includes(canonicalTerm)) {
                results.push(learningPlan)
            }
        })

        return results
    }

    surveysForSearchTerm(term: string, data: Survey[]): Survey[] {
        const canonicalTerm = term.trim().toLowerCase().replace(/\s/g, "")

        if (data.length === 0 || canonicalTerm === "") {
            return []
        }

        let results: Survey[] = []

        data.forEach(survey => {
            const canonicalName = survey.name.toLowerCase().replace(/\s/g, "")
            if (canonicalName.includes(canonicalTerm)) {
                results.push(survey)
            }
        })

        return results
    }
}