import React, { Component } from "react"
import ICourseService from "../../../services/CourseService"
import {
	Stack,
	IStackTokens,
	Spinner,
	MessageBar,
	MessageBarType,
} from "@fluentui/react"
import "./NewCourseModal.css"
import { TextField } from '../../../ui-kit/TextField'
import { PrimaryButton } from "../../../ui-kit/PrimaryButton"
import { container } from "../../../DIContainer"
import { TYPES } from "../../../Types"
import { DurationSlider } from "../../../ui-kit/DurationSlider"
import { DocumentId } from "../../../global/TypeAliases"

interface NewCourseModalProps {
	clientId: DocumentId
	courseAdded: (successMessage: string) => void
}

interface NewCourseModalState {
	name: string
	description: string
	addButtonDisabled: boolean
	showSpinner: boolean
	errorMessage: string | undefined
	clientRequestSent: boolean
	zipFile: File | undefined
	imageFile: File | undefined
	minDuration: number
	maxDuration: number
	duration: string
}

const stackStyling: IStackTokens = {
	childrenGap: "20px",
}

const defaultCourseDuration = 30

export default class NewCourseModal extends Component<NewCourseModalProps, NewCourseModalState> {
	private courseService: ICourseService = container.get<ICourseService>(TYPES.ICourseService)

	state: NewCourseModalState = {
		addButtonDisabled: true,
		showSpinner: false,
		name: "",
		description: "",
		errorMessage: undefined,
		clientRequestSent: false,
		zipFile: undefined,
		imageFile: undefined,
		minDuration: defaultCourseDuration,
		maxDuration: defaultCourseDuration,
		duration: defaultCourseDuration + " minutes"
	}

	render() {
		let zipFileText = "Select course zip file";
		if (this.state.zipFile !== undefined) {
			zipFileText = this.state.zipFile.name
		}

		let imageFileText = "Select course image file";
		if (this.state.imageFile !== undefined) {
			imageFileText = this.state.imageFile.name
		}

		return (
			<div id="add-new-course-modal-container">
				<Stack tokens={stackStyling}>
					<TextField
						id="new-course-name-textfield"
						label="Name" 
						onChange={this.nameTextFieldChanged} 
					/>
					<TextField
						id="new-course-description-textfield"
						label="Description"
						onChange={this.descriptionTextFieldChanged}
					/>
					<label htmlFor="zip-file-upload" className="inputFile">
						{zipFileText}
					</label>
					<input
						id="zip-file-upload"
						type="file"
						accept=".zip"
						onChange={this.zipFileSelectionChanged}
						width="300px"
					/>
					<label htmlFor="image-file-upload" className="inputFile">
						{imageFileText}
					</label>
					<input
						id="image-file-upload"
						type="file"
						accept="image/*"
						onChange={this.imageFileSelectionChanged}
						width="300px"
					/>
					<DurationSlider
						initialMax={defaultCourseDuration}
						initialMin={defaultCourseDuration}
						durationValueChanged={this.durationSliderUpdated}
					/>
					{this.state.errorMessage !== undefined && (
						<MessageBar
							id="new-course-error-banner"
							messageBarType={MessageBarType.error}
							dismissButtonAriaLabel="Close"
							onDismiss={this.dismissErrorBanner}
						>
							{this.state.errorMessage}
						</MessageBar>
					)}
					<PrimaryButton
						id="new-course-primary-button"
						text="Add"
						onClick={this.addButtonClicked}
						disabled={this.state.addButtonDisabled}
						hidden={this.state.showSpinner}
					/>
					<div hidden={!this.state.showSpinner}>
						<Spinner
							label="Adding course..."
							ariaLive="assertive"
							labelPosition="right"
						/>
					</div>
				</Stack>
			</div>
		)
	}

	private dismissErrorBanner = () => {
		this.setState({ errorMessage: undefined })
	}

	private addButtonClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: undefined,
			showSpinner: true,
			addButtonDisabled: true,
		})

		this.courseService
			.addCourse(
				this.state.name, 
				this.state.description, 
				this.props.clientId, 
				this.state.zipFile!, 
				this.state.imageFile!,
				this.state.duration)
			.then(() => {
				let message = this.state.name + " has been added"
				this.props.courseAdded(message)
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showSpinner: false,
					addButtonDisabled: false,
				})
			})
	}

	private nameTextFieldChanged = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return
		}

		let val = newValue

		this.setState({ name: val }, () => {
			this.enableAddButtonIfNeeded()
		})
	}

	private descriptionTextFieldChanged = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return
		}

		let val = newValue

		this.setState({ description: val }, () => {
			this.enableAddButtonIfNeeded()
		})
	}

	private enableAddButtonIfNeeded = () => {
		let disable = true

		if (this.state.name.length > 0 && 
			this.state.description.length > 0 && 
			this.state.zipFile !== undefined) {
			disable = false
		}

		this.setState({ addButtonDisabled: disable })
	}

	private zipFileSelectionChanged = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (!event.target.files || event.target.files.length === 0) {
			return
		}

		const file: File = event.target.files[0]
		this.setState({ zipFile: file }, () => {
			this.enableAddButtonIfNeeded()
		})
	}

	private imageFileSelectionChanged = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (!event.target.files || event.target.files.length === 0) {
			return
		}

		const file: File = event.target.files[0]
		this.setState({ imageFile: file }, () => {
			this.enableAddButtonIfNeeded()
		})
	}

	durationSliderUpdated = (newDuration: string) => {
		this.setState({ duration: newDuration })
		this.enableAddButtonIfNeeded()
	}
}
