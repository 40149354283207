import { ISurveyService } from './SurveyService'
import { ILRSService } from './LRSService'
import { container } from '../DIContainer';
import { TYPES } from '../Types';
import { Uid, DocumentId, Dictionary } from '../global/TypeAliases';
import { Survey } from '../models/Survey';
import { injectable } from 'inversify';
import User from '../models/User';

export interface ISurveyCompositionService {
    /**
     * Post a passed statement to the LRS and store the survey responses 
     * in a state object for the learner.
     * @param userId The uid of the learner
     * @param clientId The client id of the survey
     * @param activityId The survey lrs id
     * @param data The survey responses
     * @param progress The progress made on the survey
     */
    progressSurvey(userId: Uid, clientId: DocumentId, activityId: string, data: Dictionary, progress: number): Promise<void>

    /**
     * Post a passed statement to the LRS and store the survey responses 
     * in a state object for the learner.
     * @param userId The uid of the learner
     * @param clientId The client id of the survey
     * @param activityId The survey lrs id
     * @param data The survey responses
     */
    completeSurvey(userId: Uid, clientId: DocumentId, activityId: string, data: Dictionary): Promise<void>

    /**
     * Get a survey object and survey state data
     * @param userId The uid of the learner
     * @param documentId The document id of the survey
     * @returns The survey object
     */
     loadSurveyData(userId: Uid, documentId: DocumentId): Promise<SurveyCompositionData>

    /**
     * Given a user object, checks all surveys assigned to the user and checks
     * whether each survey has been completed or not
     * @param user The user object
     * @param clientId The client id of the survey
     * @returns A dictionary containing whether each survey has been completed or not
     * { "surveyDocumentId": boolean }
     */
    getSurveyCompletionsForUser(user: User, clientId: DocumentId): Promise<Dictionary>
    
    /**
     * Given users, a clientId, and survey LRS ID, retrieves all the survey state data for those users.
     * @param users An array of User objects
     * @param clientId The client id of the survey
     * @param surveyActivityId The lrsId of the survey
     * @returns An array of survey state data as dictionaries
     * [{data: "some data"}]
     */
    getSurveyResponseStateData(users: User[], clientId: DocumentId, surveyActivityId: string): Promise<Dictionary[]>
}

export interface SurveyCompositionData {
    survey: Survey
    stateData: Dictionary
}

@injectable()
export class SurveyCompositionService implements ISurveyCompositionService {
    private lrsService = container.get<ILRSService>(TYPES.ILRSService)
    private surveyService = container.get<ISurveyService>(TYPES.ISurveyService)

    progressSurvey(userId: Uid, clientId: DocumentId, activityId: string, data: Dictionary, progress: number): Promise<void> {
        return new Promise((resolve, reject) => {
            const promises = [
                this.lrsService.postSurveyProgress(userId, clientId, activityId, progress),
                this.lrsService.saveSurveyState(userId, clientId, activityId, data)
            ]

            Promise.all(promises).then(() => {
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    }

    completeSurvey(userId: Uid, clientId: DocumentId, activityId: string, data: Dictionary): Promise<void> {
        return new Promise((resolve, reject) => {
            const promises = [
                this.lrsService.postSurveyCompletion(userId, clientId, activityId),
                this.lrsService.saveSurveyState(userId, clientId, activityId, data)
            ]

            Promise.all(promises).then(() => {
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    }

    loadSurveyData(userId: Uid, documentId: DocumentId): Promise<SurveyCompositionData> {
        return new Promise((resolve, reject) => {
            this.surveyService
                .surveyForDocumentId(documentId)
                .then(survey => {
                    const clientIds = Object.keys(survey.clients)
                    if (clientIds.length === 0) {
                        throw new Error("No client assigned to this survey")
                    }

                    const promises = [
                        Promise.resolve(survey), 
                        this.lrsService.getSurveyState(userId, clientIds[0], survey.lrsId)
                    ]
                    
                    return Promise.all(promises)
                }).then(result => {
                    const data: SurveyCompositionData = {
                        survey: result[0] as Survey,
                        stateData: result[1] as Dictionary
                    }
    
                    resolve(data)
                }).catch(error => {
                    reject(error)
                })
        })
    }

    getSurveyCompletionsForUser(user: User, clientId: DocumentId): Promise<Dictionary> {
        let surveys: Survey[] = []

        return new Promise((resolve, reject) => {
            this.surveyService
            .surveysForCurrentUser()
            .then(allSurveys => {
                surveys = allSurveys
                const promises = allSurveys.map(survey => {
                    return this.lrsService.getSurveyCompletionForUser(user, survey.lrsId, clientId)
                })

                return Promise.all(promises)
            }).then(completionData => {
                let surveyCompletionData: Dictionary = {}
                surveys.forEach((survey, index) => {
                    let result = completionData[index]
                    if (result) {
                        surveyCompletionData[survey.documentId] = result.data !== undefined
                    }
                })
                resolve(surveyCompletionData)
            }).catch(error => {
                reject(error)
            })
        })
    }

    getSurveyResponseStateData(users: User[], clientId: DocumentId, surveyActivityId: string): Promise<Dictionary[]> {
        return new Promise((resolve, reject) => {
            const stateDataPromises: Promise<any>[] = []

            users.forEach(user => {
                stateDataPromises.push(this.lrsService.getSurveyState(user.uid, clientId, surveyActivityId))
            })

            Promise.all(stateDataPromises)                
                .then(result => {
                    resolve(result)
                }).catch(error => {
                    reject(error)
                })
        })
    }
}