import { Component, MouseEvent, ReactElement } from "react"
import { ICohortService } from "../../../services/CohortService"
import {
	Stack,
	IStackTokens,
	Spinner,
	MessageBar,
	MessageBarType,
	Label,
	SearchBox,
} from "@fluentui/react"
import { TabPanelProps } from "../TabPanelProps"
import "./AddLearnerToCohort.css"
import DTSTable from "../../../ui-kit/DTSTable"
import User from "../../../models/User"
import DTSTableData from "../../../models/DTSTableData"
import IUserService from "../../../services/UserService"
import IDataTableFactory from "../../../factories/DataTableFactory"
import { PrimaryButton } from "../../../ui-kit/PrimaryButton"
import { ISearchFilter } from "../users/SearchFilter"
import { container } from "../../../DIContainer"
import { TYPES } from "../../../Types"
import { IAccessController } from "../../../services/AccessController"
import { log } from "../../../global/Logger"
import { AdminUIStyles } from "../users/AdminUI"
import { i18nKey, Ii18n } from "../../../global/i18n"

interface AddLearnerToCohortProps extends TabPanelProps {
	documentId: string
}

interface AddLearnerToCohortState {
	searchResults: User[]
	tableData: DTSTableData | undefined
	primaryButtonDisabled: boolean
	showSpinner: boolean
	showSearchSpinner: boolean
	errorMessage: string | undefined
	successMessage: string | undefined
    selectedUsers: string[]
}

const stackStyling: IStackTokens = {
	childrenGap: "20px",
}

export default class AddLearnerToCohort extends Component<AddLearnerToCohortProps, AddLearnerToCohortState> {
	private cohortService: ICohortService = container.get<ICohortService>(TYPES.ICohortService)
	private userService: IUserService = container.get<IUserService>(TYPES.IUserService)
	private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
	private searchFilter: ISearchFilter = container.get<ISearchFilter>(TYPES.ISearchFilter)
	private acl: IAccessController = container.get<IAccessController>(TYPES.IAccessController)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	state: AddLearnerToCohortState = {
		searchResults: [],
		tableData: undefined,
		primaryButtonDisabled: true,
		showSpinner: false,
		showSearchSpinner: false,
		errorMessage: undefined,
		successMessage: undefined,
		selectedUsers: []
	}

	render() {
		let banner = null
		if (this.state.errorMessage) {
			banner = (
				<MessageBar
					id="error-banner"
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.errorMessage}
				</MessageBar>
			)
		} else if (this.state.successMessage) {
			banner = (
				<MessageBar
					id="success-banner"
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			)
		}

		let searchResult: ReactElement | undefined
		if (this.state.tableData !== undefined && !this.state.showSearchSpinner) {
			searchResult = (
				<div id="add-learner-cohort-table">
					<Stack tokens={stackStyling}>
						<DTSTable
							data={this.state.tableData}
							pagination={true}
                            onSelectedRowsChange={this.onSelectedRowChange}
						/>
						<PrimaryButton
							id="add-learner-primary-button"
							text="Add Learners"
							onClick={this.primaryButtonClicked}
							disabled={this.state.primaryButtonDisabled}
							hidden={this.state.showSpinner}
						/>
						{ this.state.showSpinner &&
							<Spinner
								label={this.i18n.get(i18nKey.updatingGroup)}
								ariaLive="assertive"
								labelPosition="right"
							/>
						}
					</Stack>
				</div>
			)
		}

		return (
			<div id="add-learner-to-cohort-modal-container">
				<Stack tokens={stackStyling} horizontalAlign="center">
					<Label>Search for learners to add</Label>
					<SearchBox
						id="searchBox"
						styles={AdminUIStyles.searchBoxStyle}
						placeholder="Search"
						onEscape={(ev) => {
							log("Custom onEscape Called")
						}}
						onClear={(ev) => {
							log("Custom onClear Called")
						}}
						onChange={(_, newValue) =>
							log("SearchBox onChange fired: " + newValue)
						}
						onSearch={(newValue) => this.search(newValue)}
					/>

					{ this.state.showSearchSpinner && 
						<Spinner
							id="search-spinner"
							label="Searching"
							ariaLive="assertive"
							labelPosition="right"
						/>
					}

					{searchResult}
					{banner}
				</Stack>
			</div>
		)
	}

	onSelectedRowChange = (selectedRowState: {
		allSelected: boolean
		selectedCount: number
		selectedRows: { [key: string]: any }[]
	}) => {
		if (selectedRowState.selectedCount === 0) {
			this.setState({ selectedUsers: [] }, () => {
				this.enablePrimaryButtonIfNeeded()
			})
			return
		}

		const selectedIds = selectedRowState.selectedRows.map((row) => {
			return row.id
		})

		log(selectedIds.length + " learners selected")

		this.setState({ selectedUsers: selectedIds }, () => {
			this.enablePrimaryButtonIfNeeded()
		})
	}

	dismissBanner = () => {
		this.setState({ errorMessage: undefined, successMessage: undefined })
	}

	primaryButtonClicked = (e: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: undefined,
			successMessage: undefined,
			showSpinner: true,
			primaryButtonDisabled: true,
		})

		this.cohortService
			.addLearnersToCohort(this.state.selectedUsers, this.props.documentId)
			.then(() => {
				let message: string
				if (this.state.selectedUsers.length === 1) {
					message = "1 learner added"
				} else {
					message = this.state.selectedUsers.length + " learners added"
				}

				this.setState({
					successMessage: message,
					showSpinner: false,
                    primaryButtonDisabled: false,
				})
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showSpinner: false,
					primaryButtonDisabled: false,
				})
			})
	}

	/**
	 * The search is actually just a filter on all users in the db. Obviously this doesn't scale 
	 * once we have a large set of users but is a solution until we get search index in Firebase.
	 * Blame Firebase: `Cloud Firestore doesn't support native indexing or search for text fields in documents.`
	 * https://firebase.google.com/docs/firestore/solutions/search
	 */
	search(searchTerm: string) {		
		const successHandler = (users: User[]) => {
			const filteredUsers = this.searchFilter.usersForSearchTerm(searchTerm, users)
			const data = this.dataTableFactory.createCohortSearchTableData(filteredUsers)

			this.setState({ showSearchSpinner: false, searchResults: filteredUsers, tableData: data })
		}

		this.setState({ showSearchSpinner: true })

		if (this.acl.isGlobalAdmin()) {
			this.userService
				.users()
				.then(users => {
					successHandler(users)
				}).catch(error => {
					this.setState({ showSearchSpinner: false, errorMessage: error.message })
				})
			return
		} 
		
		const clientId = this.acl.isClientAdmin()
		if (clientId !== null) {
			this.userService
				.searchUsersWithin(clientId)
				.then(users => {
					successHandler(users)
				}).catch(error => {
					this.setState({ showSearchSpinner: false, errorMessage: error.message })
				})
		} else {
			this.setState({ showSearchSpinner: false })
		}
		
	}

	enablePrimaryButtonIfNeeded() {
		let disable = true

		if (this.state.selectedUsers.length > 0) {
			disable = false
		}

		this.setState({
			primaryButtonDisabled: disable,
		})
	}
}
