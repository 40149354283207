import React, { useState } from "react";
import { Carousel, CarouselItem, CarouselIndicators } from "reactstrap";
import { container } from "../../DIContainer";
import { TYPES } from "../../Types";
import { MarketingIi18n, i18nKey } from "../../global/Marketing-i18n";

import NorthShore_Logo from "../../images/marketing/testimonial-nsd-logo-circle.jpeg";
import BellevueSchool_Logo from "../../images/marketing/testimonial-bsd-logo-circle.png";
import Office365_Logo from "../../images/marketing/testimonial-ms365-logo-round.png";

const TestimonialsPageV2 = () => {
    const i18n = container.get<MarketingIi18n>(TYPES.MarketingIi18n);

    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const items = [
        {
            id: 1,
            img: BellevueSchool_Logo,
            name: `${i18n.get(i18nKey.testimonialBellevueSchoolOrg)}`,
            designation: `${i18n.get(i18nKey.testimonialBellevueSchoolCardTitle1)}`,
            description: `${i18n.get(i18nKey.testimonialBellevueSchoolCardText2)}`,
        },
        {
            id: 2,
            img: NorthShore_Logo,
            name: `${i18n.get(i18nKey.testimonialNorthshoreSchoolCardTitle1)}`,
            designation: `${i18n.get(i18nKey.testimonialNorthshoreSchoolOrg)}`,
            description: `${i18n.get(i18nKey.testimonialNorthshoreSchoolCardText1)}`,
        },
        {
            id: 3,
            img: Office365_Logo,
            name: `${i18n.get(i18nKey.testimonialMicrosoftOrg)}`,
            designation: `${i18n.get(i18nKey.testimonialMicrosoftCardTitle2)}`,
            description: `${i18n.get(i18nKey.testimonialMicrosoftCardText2)}`,
        },
    ];

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex: number) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map((item) => {
        return (
            <CarouselItem
                tag="div"
                key={item.id}
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
            >
                <div className="carousel-item active">
                    <div className="testi-contain text-white">
                        <i className="bx bxs-quote-alt-left text-pink display-6"></i>
                        <h4 className="mt-4 fw-medium lh-base text-white">“{item.description}”</h4>
                        <div className="mt-4 pt-3 pb-5">
                            <div className="d-flex align-items-start">
                                <div className="flex-shrink-0">
                                    <img src={item.img} className="avatar-md img-fluid rounded-circle" alt="..." />
                                </div>
                                <div className="flex-grow-1 ms-3 mb-4">
                                    <h5 className="font-size-18 text-white">{item.name}</h5>
                                    <p className="mb-0 text-white-50">{item.designation}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CarouselItem>
        );
    });

    return (
        <React.Fragment>
            <div className="col-xxl-9 col-lg-8 col-md-7">
                <div className="auth-bg pt-md-5 p-4 d-flex">
                    <div className="bg-overlay bg-primary"></div>
                    <ul className="bg-bubbles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-xl-7">
                            <div className="p-0 p-sm-4 px-xl-0">
                                <div
                                    id="reviewcarouselIndicators"
                                    className="carousel slide"
                                    data-bs-ride="carousel"
                                    data-testid="carousel-slides"
                                >
                                    <Carousel
                                        activeIndex={activeIndex}
                                        next={next}
                                        previous={previous}
                                        data-testid="testimonials-carousel"
                                    >
                                        {slides}
                                        <CarouselIndicators
                                            items={items}
                                            activeIndex={activeIndex}
                                            onClickHandler={goToIndex}
                                            data-testid="carousel-indicators"
                                        />
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default TestimonialsPageV2;
