import { IStackStyles, ITextStyles, IImageStyles } from '@fluentui/react'

export class FeaturesConvenienceStyles {
    static outerStackStyles: IStackStyles = {
        root: {
            background: 'linear-gradient(180deg, #000000 0%, #0F72AC 100%)',
            maxWidth: '100vw',
            overflow: 'hidden',
        },
    }

    static headerTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '10vw',
                    lineHeight: '12vw',
                },
            },

            textAlign: 'center',
            fontFamily: 'Noto Sans JP',
            color: '#FFFFFF',
            fontWeight: 900,
            fontSize: '3vw',
            paddingTop: '3vw',
            lineHeight: '3.5vw',
        },
    }

    static headerStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    maxWidth: '65vw',
                },
            },
            maxWidth:'30vw'
        },
    }

    static subHeaderTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '4vw',
                    lineHeight: '5vw',
                    paddingTop: '6vw',
                    marginBottom: '6vw',
                },
            },

            textAlign: 'center',
            fontFamily: 'Lato',
            color: '#FFFFFF',
            fontWeight: 400,
            fontSize: '1.3vw',
            marginBottom: '3vw',
            paddingTop: '2.5vw',
            lineHeight: '1.5vw',
        },
    }

    static subHeaderStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    maxWidth: '40vw',
                },
            },
        },
    }

    static imageStyles: Partial<IImageStyles> = {
        image: {
            selectors: {
                '@media(max-width: 600px)': {
                    width: '140vw',
                },
            },
            width: '85vw',
        },
    }
}
