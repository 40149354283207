import DTSTableData from "../models/DTSTableData"
import { injectable } from "inversify"
import { Dictionary } from "../global/TypeAliases"
import { TableColumn } from "react-data-table-component"
import { Ii18n, i18nKey } from "../global/i18n"
import { container } from "../DIContainer"
import { TYPES } from "../Types"
export interface ISurveyReportDataTableFactory {
    createRadioChoiceGroupDataTable(questionKey: string, choices: any[], aggregatedData: Dictionary[], optionalQuestion: boolean): DTSTableData
    createCheckboxGroupDataTable(questionKey: string, choices: any[], aggregatedData: Dictionary[], optionalQuestion: boolean): DTSTableData
    createDropdownGroupDataTable(questionKey: string, choices: any[], aggregatedData: Dictionary[], optionalQuestion: boolean): DTSTableData
    createRatingGroupDataTable(questionKey: string, minRating: number, maxRating: number, rateStep: number, aggregatedData: Dictionary[], optionalQuestion: boolean): DTSTableData
    createRankingGroupDataTable(questionKey: string, choices: any[], aggregatedData: Dictionary[], optionalQuestion: boolean): DTSTableData
    createMatrixGroupDataTable(questionKey: string, columnChoices: any[], rowChoices: any[], aggregatedData: Dictionary[], optionalQuestion: boolean): DTSTableData
    createTextGroupDataTable(questionKey: string, aggregatedData: Dictionary[], optionalQuestion: boolean): DTSTableData
}

@injectable()
export default class SurveyReportDataTableFactory implements ISurveyReportDataTableFactory {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    
    createRadioChoiceGroupDataTable(questionKey: string, choices: any[], aggregatedData: Dictionary[], optionalQuestion: boolean): DTSTableData {
        const columns: TableColumn<Dictionary>[] = [
            {
                name: this.i18n.get(i18nKey.answerChoice),
                selector: (row: any) => row.answer,
                sortable: true,
            },
            {
                name: this.i18n.get(i18nKey.percentageOfTotal),
                selector: (row: any) => row.percentage,
                sortable: true,
                minWidth: "24%"
            },
            {
                name: this.i18n.get(i18nKey.numberOfResponses),
                selector: (row: any) => row.total,
                sortable: true,
                minWidth: "26%"
            }
        ]

        const responses = aggregatedData.filter((response) => response[questionKey] !== undefined)
                                        .map(filteredResponse => {return filteredResponse[questionKey]})

        let choiceResponseData = choices.map(choice => {
            const total: number = responses.filter(response => response === choice).length

            if (total !== 0) {
                const displayedPercentage = this.calculateDisplayedPercentage(total, responses.length)
    
                return {
                    answer: choice,
                    percentage: displayedPercentage,
                    total: total
                }
            } else {
                return {
                    answer: choice,
                    percentage: this.i18n.get(i18nKey.zeroPercentTableData),
                    total: total
                }
            }
        })

        if (optionalQuestion) {
            const total: number = responses.filter(response => response === this.i18n.get(i18nKey.notAnsweredSurveyData)).length

            if (total !== 0) {
                const displayedPercentage = this.calculateDisplayedPercentage(total, responses.length)
    
                choiceResponseData.push(
                    {
                        answer: this.i18n.get(i18nKey.notAnsweredSurveyData),
                        percentage: displayedPercentage,
                        total: total
                    }
                )
            } else {
                choiceResponseData.push(
                    {
                        answer: this.i18n.get(i18nKey.notAnsweredSurveyData),
                        percentage: this.i18n.get(i18nKey.zeroPercentTableData),
                        total: total
                    }
                )
            }
        }

        choiceResponseData.push(
            {
                answer: this.i18n.get(i18nKey.total),
                percentage: (responses.length !== 0) ? this.i18n.get(i18nKey.oneHundredPercentTableData) : this.i18n.get(i18nKey.zeroPercentTableData),
                total: responses.length
            }
        )

        return new DTSTableData(columns,choiceResponseData)
    }

    createCheckboxGroupDataTable(questionKey: string, choices: any[], aggregatedData: Dictionary[], optionalQuestion: boolean): DTSTableData {
        const columns: TableColumn<Dictionary>[] = [
            {
                name: this.i18n.get(i18nKey.answerChoice),
                selector: (row: any) => row.answer,
                sortable: true,
            },
            {
                name: this.i18n.get(i18nKey.percentageOfTotal),
                selector: (row: any) => row.percentage,
                sortable: true,
                minWidth: "24%"
            },
            {
                name: this.i18n.get(i18nKey.numberOfResponses),
                selector: (row: any) => row.total,
                sortable: true,
                minWidth: "26%"
            }
        ]

        const responses = aggregatedData.filter((response) => response[questionKey] !== undefined)
                                        .map(filteredResponse => {return filteredResponse[questionKey]}).flat()

        let choiceResponseData = choices.map(choice => {
            const total: number = responses.filter(response => response === choice).length

            if (total !== 0) {
                const displayedPercentage = this.calculateDisplayedPercentage(total, responses.length)
    
                return {
                    answer: choice,
                    percentage: displayedPercentage,
                    total: total
                }
            } else {
                return {
                    answer: choice,
                    percentage: this.i18n.get(i18nKey.zeroPercentTableData),
                    total: total
                }
            }
        })

        if (optionalQuestion) {
            const total: number = responses.filter(response => response === this.i18n.get(i18nKey.notAnsweredSurveyData)).length

            if (total !== 0) {
                const displayedPercentage = this.calculateDisplayedPercentage(total, responses.length)
    
                choiceResponseData.push(
                    {
                        answer: this.i18n.get(i18nKey.notAnsweredSurveyData),
                        percentage: displayedPercentage,
                        total: total
                    }
                )
            } else {
                choiceResponseData.push(
                    {
                        answer: this.i18n.get(i18nKey.notAnsweredSurveyData),
                        percentage: this.i18n.get(i18nKey.zeroPercentTableData),
                        total: total
                    }
                )
            }
        }

        choiceResponseData.push(
            {
                answer: this.i18n.get(i18nKey.total),
                percentage: (responses.length !== 0) ? this.i18n.get(i18nKey.oneHundredPercentTableData) : this.i18n.get(i18nKey.zeroPercentTableData),
                total: responses.length
            }
        )

        return new DTSTableData(columns, choiceResponseData)
    }

    createDropdownGroupDataTable(questionKey: string, choices: any[], aggregatedData: Dictionary[], optionalQuestion: boolean): DTSTableData {
        const columns: TableColumn<Dictionary>[] = [
            {
                name: this.i18n.get(i18nKey.answerChoice),
                selector: (row: any) => row.answer,
                sortable: true,
            },
            {
                name: this.i18n.get(i18nKey.percentageOfTotal),
                selector: (row: any) => row.percentage,
                sortable: true,
                minWidth: "24%"
            },
            {
                name: this.i18n.get(i18nKey.numberOfResponses),
                selector: (row: any) => row.total,
                sortable: true,
                minWidth: "26%"
            }
        ]

        const responses = aggregatedData.filter((response) => response[questionKey] !== undefined)
                                        .map(filteredResponse => {return filteredResponse[questionKey]}).flat()

        let choiceResponseData = choices.map(choice => {
            const total: number = responses.filter(response => response === choice).length

            if (total !== 0) {
                const displayedPercentage = this.calculateDisplayedPercentage(total, responses.length)
    
                return {
                    answer: choice,
                    percentage: displayedPercentage,
                    total: total
                }
            } else {
                return {
                    answer: choice,
                    percentage: this.i18n.get(i18nKey.zeroPercentTableData),
                    total: total
                }
            }
        })

        if (optionalQuestion) {
            const total: number = responses.filter(response => response === this.i18n.get(i18nKey.notAnsweredSurveyData)).length

            if (total !== 0) {
                const displayedPercentage = this.calculateDisplayedPercentage(total, responses.length)
    
                choiceResponseData.push(
                    {
                        answer: this.i18n.get(i18nKey.notAnsweredSurveyData),
                        percentage: displayedPercentage,
                        total: total
                    }
                )
            } else {
                choiceResponseData.push(
                    {
                        answer: this.i18n.get(i18nKey.notAnsweredSurveyData),
                        percentage: this.i18n.get(i18nKey.zeroPercentTableData),
                        total: total
                    }
                )
            }
        }

        choiceResponseData.push(
            {
                answer: this.i18n.get(i18nKey.total),
                percentage: (responses.length !== 0) ? this.i18n.get(i18nKey.oneHundredPercentTableData) : this.i18n.get(i18nKey.zeroPercentTableData),
                total: responses.length
            }
        )

        return new DTSTableData(columns, choiceResponseData)
    }

    createRatingGroupDataTable(questionKey: string, minRating: number, maxRating: number, rateStep: number, aggregatedData: Dictionary[], optionalQuestion: boolean): DTSTableData {
        const columns: TableColumn<Dictionary>[] = [
            {
                name: this.i18n.get(i18nKey.answerChoice),
                selector: (row: any) => row.answer,
                sortable: true,
            },
            {
                name: this.i18n.get(i18nKey.percentageOfTotal),
                selector: (row: any) => row.percentage,
                sortable: true,
                minWidth: "24%"
            },
            {
                name: this.i18n.get(i18nKey.numberOfResponses),
                selector: (row: any) => row.total,
                sortable: true,
                minWidth: "26%"
            }
        ]

        const responses = aggregatedData.filter((response) => response[questionKey] !== undefined)
                                        .map(filteredResponse => {return filteredResponse[questionKey]}).flat()

        let choiceResponseData: Dictionary[] = []

        for(let i = minRating; i <= maxRating; i += rateStep) {

            const total: number = responses.filter(response => response === i).length

            if (total !== 0) {
                const displayedPercentage = this.calculateDisplayedPercentage(total, responses.length)
    
                choiceResponseData.push( {
                    answer: i,
                    percentage: displayedPercentage,
                    total: total
                })
            } else {
                choiceResponseData.push({
                    answer: i,
                    percentage: this.i18n.get(i18nKey.zeroPercentTableData),
                    total: total
                })
            }
        }

        if (optionalQuestion) {
            const total: number = responses.filter(response => response === this.i18n.get(i18nKey.notAnsweredSurveyData)).length

            if (total !== 0) {
                const displayedPercentage = this.calculateDisplayedPercentage(total, responses.length)
    
                choiceResponseData.push(
                    {
                        answer: this.i18n.get(i18nKey.notAnsweredSurveyData),
                        percentage: displayedPercentage,
                        total: total
                    }
                )
            } else {
                choiceResponseData.push(
                    {
                        answer: this.i18n.get(i18nKey.notAnsweredSurveyData),
                        percentage: this.i18n.get(i18nKey.zeroPercentTableData),
                        total: total
                    }
                )
            }
        }

        choiceResponseData.push(
            {
                answer: this.i18n.get(i18nKey.total),
                percentage: (responses.length !== 0) ? this.i18n.get(i18nKey.oneHundredPercentTableData) : this.i18n.get(i18nKey.zeroPercentTableData),
                total: responses.length
            }
        )

        return new DTSTableData(columns, choiceResponseData)
    }

    createRankingGroupDataTable(questionKey: string, choices: any[], aggregatedData: Dictionary[], optionalQuestion: boolean): DTSTableData {

        // First need to create n-columns for the amount of choices the user is able to rank
        let rankIndex: number = 0
        const rankedColumns = choices.map(() => {
            rankIndex += 1
            const columnTitle: string = this.i18n.get(i18nKey.rank) + " " + rankIndex
            return {
                    name: columnTitle,
                    selector: (row: any) => row[`${columnTitle}`],
                    sortable: true,
                    minWidth: "5%",
            }
        })
        let columns: TableColumn<Dictionary>[] = [
            {
                name: this.i18n.get(i18nKey.answerChoice),
                selector: (row: any) => row.answer,
                sortable: true,
            },
        ]

        // concatenate the programmatically generated columns with the static columns for each choice.
        columns = columns.concat(rankedColumns)

        // filter the responses for just those pertaining to this question
        const responses = aggregatedData.filter((response) => response[questionKey] !== undefined)
                                        .map(filteredResponse => {return filteredResponse[questionKey]})

        // map the filtered response to generate each row of ranked data for a specific choice
        let choiceResponseData = choices.map(choice => {
            const total: number = responses.length
            let choiceRankData: Dictionary = {}

            for (let i = 1; i <= rankIndex; i++) {
                choiceRankData[i] = 0
            }

            let row: Dictionary = {answer: choice}

            // if there is response data, process as normal 
            if (total !== 0) {
                // for each response, find the rank of the choice from the respondent and add one to get a 1-index rank
                responses.forEach((response: any[]) => {
                    const choiceRank = response.indexOf(choice) + 1
                    choiceRankData[choiceRank] += 1
                })

                for(let i = 1; i <= choices.length; i++) {
                    // store the number and percentage for the choice and it's appropriate rank
                    row[`${this.i18n.get(i18nKey.rank)} ${i}`] = this.CombinedResponseNumberAndPercentageCell(choiceRankData[i], this.calculateDisplayedPercentage(choiceRankData[i], total))
                }
            } else {
                for(let i = 1; i <= choices.length; i++) {
                    // store the number and percentage for the choice and it's appropriate rank
                    row[`${this.i18n.get(i18nKey.rank)} ${i}`] = this.CombinedResponseNumberAndPercentageCell(choiceRankData[i], this.i18n.get(i18nKey.zeroPercentTableData))
                }
            }

            return row
        })

        // if the question is optional push the data recorded where respondent did not answer
        if (optionalQuestion) {
            const total: number = responses.filter(response => response === this.i18n.get(i18nKey.notAnsweredSurveyData)).length

            if (total !== 0) {
                choiceResponseData.push(
                    {
                        answer: this.i18n.get(i18nKey.notAnsweredSurveyData),
                        [this.i18n.get(i18nKey.rankOne)]: this.CombinedResponseNumberAndPercentageCell(total, this.calculateDisplayedPercentage(total, total)),
                    }
                )
            } else {
                choiceResponseData.push(
                    {
                        answer: this.i18n.get(i18nKey.notAnsweredSurveyData),
                        [this.i18n.get(i18nKey.rankOne)]: this.CombinedResponseNumberAndPercentageCell(0, this.i18n.get(i18nKey.zeroPercentTableData))
                    }
                )
            }
        }
        
        // finally add the final row to show total responses and according 100%
        choiceResponseData.push(
            {
                answer: this.i18n.get(i18nKey.totalRespondents),
                [this.i18n.get(i18nKey.rankOne)]: (responses.length !== 0) ? 
                    this.CombinedResponseNumberAndPercentageCell(responses.length, this.i18n.get(i18nKey.oneHundredPercentTableData)) :
                    this.CombinedResponseNumberAndPercentageCell(0, this.i18n.get(i18nKey.zeroPercentTableData))
            }
        )

        return new DTSTableData(columns, choiceResponseData)
    }       

    createTextGroupDataTable(questionKey: string, aggregatedData: Dictionary[], optionalQuestion: boolean): DTSTableData {
        const columns: TableColumn<Dictionary>[] = [
            {
                name: this.i18n.get(i18nKey.responses),
                selector: (row: any) => row.response,
                sortable: true,
                minWidth: "100%"
            }
        ]

        const responses = aggregatedData.filter((response) => response[questionKey] !== undefined)
                                        .map(filteredResponse => {return filteredResponse[questionKey]}).flat()

        const total: number = responses.length
        let responseData: any[] = []
        if (total !== 0) {
            responseData = responses.map(response => {
                return {
                    response: response
                }
            })
        } else {
            responseData.push({response: this.i18n.get(i18nKey.thereAreNoResponsesToDisplay)})
        }

        if (optionalQuestion) {
            const total: number = responses.filter(response => response === this.i18n.get(i18nKey.notAnsweredSurveyData)).length

            if (total !== 0) {
                responseData.push(
                    {
                        response: this.i18n.get(i18nKey.notAnsweredSurveyData)
                    }
                )
            } else {
                responseData.push(
                    {
                        response: this.i18n.get(i18nKey.notAnsweredSurveyData),
                    }
                )
            }
        }

        return new DTSTableData(columns, responseData)
    }

    createMatrixGroupDataTable(questionKey: string, columnChoices: any[], rowChoices: any[], aggregatedData: Dictionary[], optionalQuestion: boolean): DTSTableData {

        // First need to create n-columns for the amount of choices the user is able to rank
        const matrixColumns = columnChoices.map(columnChoice => {
            return {
                    name: columnChoice,
                    selector: (row: any) => row[columnChoice],
                    sortable: true,
                    minWidth: "5%",
            }
        })

        let tableColumns: TableColumn<Dictionary>[] = [
            {
                name: this.i18n.get(i18nKey.answerChoice),
                selector: (row: any) => row.answer,
                sortable: true,
            },
        ]

        // concatenate the programmatically generated columns with the static columns for each choice.
        tableColumns = tableColumns.concat(matrixColumns)

        // filter the responses for just those pertaining to this question
        const responses = aggregatedData.filter((response) => response[questionKey] !== undefined)
                                        .map(filteredResponse => {return filteredResponse[questionKey]})

        // initialize a dictionary to tally up the data for each row
        let choiceMatrixData: Dictionary = {}
        for (let i = 0; i < columnChoices.length; i++) {
            choiceMatrixData[columnChoices[i]] = 0
        }

        // map the filtered response to generate each row of ranked data for a specific choice
        let choiceResponseData = rowChoices.map(rowChoice => {
         const total: number = responses.length

            let row: Dictionary = {answer: rowChoice}

            // if there is response data, process as normal 
            if (total !== 0) {
                // for each response, find the matrix of the choice from the respondent and increment the count
                responses.forEach((response: any[]) => {
                    const matrixChoice = response[rowChoice]
                    choiceMatrixData[matrixChoice] += 1
                })

                for (let choice in columnChoices) {
                    // store the number and percentage for the choice and it's appropriate matrix choice
                    row[`${columnChoices[choice]}`] = this.CombinedResponseNumberAndPercentageCell(choiceMatrixData[columnChoices[choice]], this.calculateDisplayedPercentage(choiceMatrixData[columnChoices[choice]], total))
                }
            } else {
                for (let choice in columnChoices) {
                    // store the number and percentage for the choice and it's appropriate matrix choice as zero if this code is invoked
                    row[`${columnChoices[choice]}`] = this.CombinedResponseNumberAndPercentageCell(choiceMatrixData[columnChoices[choice]], this.i18n.get(i18nKey.zeroPercentTableData))
                }
            }

            // clear the dictionary for the next row
            for (let key in choiceMatrixData) {
                choiceMatrixData[key] = 0
            }

            return row
        })

        // if the question is optional push the data recorded where respondent did not answer
        if (optionalQuestion) {
            const total: number = responses.filter(response => response === this.i18n.get(i18nKey.notAnsweredSurveyData)).length

            if (total !== 0) {
                choiceResponseData.push(
                    {
                        answer: this.i18n.get(i18nKey.notAnsweredSurveyData),
                        [columnChoices[0]]: this.CombinedResponseNumberAndPercentageCell(total, this.calculateDisplayedPercentage(total, total)),
                    }
                )
            } else {
                choiceResponseData.push(
                    {
                        answer: this.i18n.get(i18nKey.notAnsweredSurveyData),
                        [columnChoices[0]]: this.CombinedResponseNumberAndPercentageCell(0, this.i18n.get(i18nKey.zeroPercentTableData))
                    }
                )
            }
        }
        
        // finally add the finally row to show total responses and according 100%
        choiceResponseData.push(
            {
                answer: this.i18n.get(i18nKey.totalRespondents),
                [columnChoices[0]]: (responses.length !== 0) ? 
                    this.CombinedResponseNumberAndPercentageCell(responses.length, this.i18n.get(i18nKey.oneHundredPercentTableData)) :
                    this.CombinedResponseNumberAndPercentageCell(0, this.i18n.get(i18nKey.zeroPercentTableData))
            }
        )

        return new DTSTableData(tableColumns, choiceResponseData)
    }

    private CombinedResponseNumberAndPercentageCell = (responseTotal: number, percentage: string) => {
        return (
            <div>
                <div style={{fontWeight: 600, fontSize: "16px"}}>{percentage}</div>
                <div>{responseTotal}</div>
            </div>
        )
    }

    private calculateDisplayedPercentage = (total: number, responses: number): string => {
        const calculatedPercentage = ((total/responses)*100)
        const displayedPercentage = ((calculatedPercentage) % 1 === 0) ? calculatedPercentage.toFixed() + "%" : calculatedPercentage.toFixed(2) + "%"
        return displayedPercentage
    }
}