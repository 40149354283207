import React, { Component } from "react"
import { 
    Stack,
    Text, 
    Image, 
    Modal, 
    IconButton,
    IIconProps, 
} from '@fluentui/react'
import { MarketingIi18n, i18nKey } from "../global/Marketing-i18n"
import { container } from "../DIContainer"
import { TYPES } from "../Types"
import { LandingVideoStyles } from "./LandingVideoStyles"

import videoBackground from "../images/marketing/landing-video-background.jpg"
import playIcon from "../images/marketing/landing-video-play-icon.svg"

const cancelIcon: IIconProps = { iconName: 'Cancel' };

interface LandingVideoProps {}

interface LandingVideoState {
    isModalOpen: boolean
}

export class LandingVideo extends Component<LandingVideoProps, LandingVideoState> {
    private i18n = container.get<MarketingIi18n>(TYPES.MarketingIi18n)

    state: LandingVideoState = {
        isModalOpen: false
    }

    render() {
        return (
            <Stack>
                <Stack grow horizontalAlign="center">
                    <Stack horizontalAlign="center" styles={LandingVideoStyles.descriptionStackStyles}>
                        <Text styles={LandingVideoStyles.headerTextStyles}>
                            {this.i18n.get(i18nKey.seeItInAction)}
                        </Text>
                        <Text styles={LandingVideoStyles.descriptionTextStyles}>
                            {this.i18n.get(i18nKey.landingVideoInfo)}
                        </Text>
                    </Stack>
                    <Stack horizontalAlign="center" styles={LandingVideoStyles.playIconStackStyles}>
                        <Image 
                            src={playIcon} 
                            styles={LandingVideoStyles.playIconStyles}
                            onClick={this.showVideoModal}
                        />
                    </Stack>
                    <Stack styles={LandingVideoStyles.imageStackStyles}>
                        <Image 
                            src={videoBackground}
                            width="100%"
                            styles={LandingVideoStyles.backgroundImageStyles}
                        />
                    </Stack>
                </Stack>
                <Modal
                    isOpen={this.state.isModalOpen}
                    onDismiss={this.dismissModal}
                    styles={LandingVideoStyles.modalStyles}
                >
                    <IconButton
                        iconProps={cancelIcon}
                        ariaLabel="Close dts video modal"
                        onClick={this.dismissModal}
                        styles={LandingVideoStyles.cancelIconStyles}
                    />
                    <video 
                        id="landing-dts-video"
                        src="https://proddts.blob.core.windows.net/dts-storage/marketing-dts-video.mp4"
                        controls={true}
                        width="100%"
                        autoPlay
                    />
                </Modal>
            </Stack>
        )
    }

    showVideoModal = () => {
        this.setState({ isModalOpen: true })
    }

    dismissModal = () => {
		this.setState({ isModalOpen: false })
	}
}