/* istanbul ignore file */
import CancelablePromise, { cancelable } from "cancelable-promise";
import { injectable } from "inversify";

export interface IPromiseHelper {
    makeCancelable<T>(promise: Promise<T>): CancelablePromise
    cancel(promise?: CancelablePromise<any>): void
}

@injectable()
export class PromiseHelper implements IPromiseHelper {
    makeCancelable<T>(promise: Promise<T>): CancelablePromise<any> {
        return cancelable(promise)
    }

    cancel(promise?: CancelablePromise<any>): void {
        promise?.cancel()
    }
}