import { CleverMe } from './../models/CleverMe'
import { CleverUser } from './../models/CleverUser';
import axios from "axios"
import { BearerToken, CleverUserId } from './../global/TypeAliases'
import { IServiceConfig } from './ServiceConfig'
import { container } from '../DIContainer'
import { TYPES } from '../Types'
import { injectable } from "inversify"
import { IEventReportingService } from '../services/EventReportingService'

export interface ICleverService {
    /**
     * Get a bearer token from Clevers auth API
     * @param code The code obtained from Clevers redirect URI
     * @param redirectUri 
     */
    getBearerToken(code: string, redirectUri: string): Promise<BearerToken>

    /**
     * Get the Clever user id
     * @param token The bearer token
     */
    getUserIdFromBearerToken(token: BearerToken): Promise<CleverMe>

    /**
     * Get user details for a school admin
     * @param userId The clever user id
     * @param token The bearer token
     */
    getSchoolAdmin(userId: CleverUserId, token: BearerToken): Promise<CleverUser>

    /**
     * Get user details for a district admin
     * @param userId The clever user id
     * @param token The bearer token
     */
    getDistrictAdmin(userId: CleverUserId, token: BearerToken): Promise<CleverUser>

    /**
     * Get user details for a teacher
     * @param userId The clever user id
     * @param token The bearer token
     */
    getTeacher(userId: CleverUserId, token: BearerToken): Promise<CleverUser>
}

enum Constants {
    accessTokenKey = "access_token",
    dataKey = "data",
    userId = "userId",
    typeKey = "type",
    emailKey = "email",
    nameKey = "name",
    firstNameKey = "first",
    lastNameKey = "last",
    districtIdKey = "districtId"
}

@injectable()
export class CleverService implements ICleverService {
    private serviceConfig: IServiceConfig = container.get<IServiceConfig>(TYPES.IServiceConfig)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)
    private INVALID_GRANT = "invalid_grant"
    
    getBearerToken(code: string, redirectUri: string): Promise<BearerToken> {
        let options = this.serviceConfig.getCleverBearerTokenConfig(code, redirectUri)

        return new Promise((resolve, reject) => {
            axios.post(options.url, options.body, options.config)
                .then(response => {
                    const bearerToken = response.data[Constants.accessTokenKey]

                    if (bearerToken === undefined) {
                        let error = new Error("Missing access_token key in the response")
                        this.eventReportingService.error(error.message, error)
                        reject(error)
                    }

                    resolve(bearerToken)
                })
                .catch(error => {
                    let message = error.message

                    if (error.response 
                        && error.response.data 
                        && error.response.data.error 
                        && error.response.data.error === this.INVALID_GRANT) {
                        message += ' - Clever Code Expired'
                    }

                    this.eventReportingService.error(message, error)
                    reject(error)
                })
		})
    }

    getUserIdFromBearerToken(token: BearerToken): Promise<CleverMe> {
        let options = this.serviceConfig.getCleverMeConfig(token)

        return new Promise((resolve, reject) => {
            axios.get(options.url, options.config)
                .then(response => {
                    const userId = response.data[Constants.userId]
                    const userType = response.data[Constants.typeKey]
                    const cleverMe: CleverMe = {
                        id: userId,
                        type: userType
                    }

                    resolve(cleverMe)
                })
                .catch(error => {
                    this.eventReportingService.error(error.message, error)
                    reject(error)
                })
		})
    }

    getSchoolAdmin(userId: CleverUserId, token: BearerToken): Promise<CleverUser> {
        let options = this.serviceConfig.getCleverSchoolAdminConfig(userId, token)

        return new Promise((resolve, reject) => {
            axios.get(options.url, options.config)
                .then(response => {
                    const emailAddress = response.data[Constants.emailKey]
                    const firstName = response.data[Constants.firstNameKey]
                    const lastName = response.data[Constants.lastNameKey]
                    const districtId = response.data[Constants.districtIdKey]

                    const cleverUser: CleverUser = {
                        id: userId,
                        districtId: districtId,
                        firstName: firstName,
                        lastName: lastName,
                        emailAddress: emailAddress
                    }
                    
                    resolve(cleverUser)
                })
                .catch(error => {
                    this.eventReportingService.error(error.message, error)
                    reject(error)
                })
		})
    }

    getDistrictAdmin(userId: CleverUserId, token: BearerToken): Promise<CleverUser> {
        let options = this.serviceConfig.getCleverDistrictAdminConfig(userId, token)

        return new Promise((resolve, reject) => {
            axios.get(options.url, options.config)
                .then(response => {
                    const emailAddress = response.data[Constants.emailKey]
                    const firstName = response.data[Constants.firstNameKey]
                    const lastName = response.data[Constants.lastNameKey]
                    const districtId = response.data[Constants.districtIdKey]

                    const cleverUser: CleverUser = {
                        id: userId,
                        districtId: districtId,
                        firstName: firstName,
                        lastName: lastName,
                        emailAddress: emailAddress
                    }
                    
                    resolve(cleverUser)
                })
                .catch(error => {
                    this.eventReportingService.error(error.message, error)
                    reject(error)
                })
		})
    }

    getTeacher(userId: CleverUserId, token: BearerToken): Promise<CleverUser> {
        let options = this.serviceConfig.getCleverTeacherConfig(userId, token)

        return new Promise((resolve, reject) => {
            axios.get(options.url, options.config)
                .then(response => {
                    const emailAddress = response.data[Constants.emailKey]
                    const firstName = response.data[Constants.firstNameKey]
                    const lastName = response.data[Constants.lastNameKey]
                    const districtId = response.data[Constants.districtIdKey]

                    const cleverUser: CleverUser = {
                        id: userId,
                        districtId: districtId,
                        firstName: firstName,
                        lastName: lastName,
                        emailAddress: emailAddress
                    }
                    
                    resolve(cleverUser)
                })
                .catch(error => {
                    this.eventReportingService.error(error.message, error)
                    reject(error)
                })
		})
    }
}
