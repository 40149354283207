import { IDetailsListStyles } from "@fluentui/react";
import { container } from "../../DIContainer";
import { TYPES } from "../../Types";
import { Ii18n, i18nKey } from "../../global/i18n";
import { Theme } from "../../ui-kit/Theme";

const i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

export const SurveyAggregatedReportDetailsTableRowStyles = [
	{
		when: (row: any) => row.answer === i18n.get(i18nKey.total) || row.answer === i18n.get(i18nKey.totalRespondents),
		style: {
            fontWeight: 600,
            fontSize: "16px"
		},
	},
  {
		when: (row: any) => row.response,
		style: {
            fontWeight: 500,
            fontSize: "16px"
		},
	}
]

export const SurveyAggregatedReportTableHeaderCustomStyles = {
    headCells: {
      style: {
        backgroundColor: Theme.color.secondary
      },
    },
}

export const questionNamesListStyles: IDetailsListStyles = {
  root: {
    cursor: "pointer",
    borderRadius: 10,
    backgroundColor: "white",
  },
  focusZone: undefined,
  headerWrapper: undefined,
  contentWrapper: undefined
}