import { IUserUploadCompositionService } from './UserUploadCompositionService';
import { UserStatus } from './../models/User';
import { IUserService } from './UserService';
import { injectable } from "inversify"
import { container } from "../DIContainer"
import { TYPES } from "../Types"
import { IAuthService } from './AuthService';

export interface IResetPasswordCompositionService {
    /**
     * Fetches user and determines account status. If the account 
     * is pending, sends a registration email to user. If the account
     * is active, sends an email to reset account password.
     * @param email users email address
     */
    handleResetRequestFor(email: string): Promise<void>

    /**
     * Updates the password for a user and flips the value of `tempPassword` to false
     * @param firebaseUser firebase User
     * @param credential firebase credential verified with email and password
     * @param newPassword new password to change
     */
    updatePasswordFor(firebaseUser: firebase.User, credential: firebase.auth.AuthCredential, newPassword: string): Promise<void> 
}

@injectable()
export class ResetPasswordCompositionService implements IResetPasswordCompositionService {
    private authService: IAuthService = container.get<IAuthService>(TYPES.IAuthService) 
    private userService: IUserService = container.get<IUserService>(TYPES.IUserService)
    private compositionService: IUserUploadCompositionService = container.get<IUserUploadCompositionService>(TYPES.IUserUploadCompositionService)

    handleResetRequestFor(email: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.userService
                .getUserWithEmail(email)
                .then((user) => {
                    if (!user) {
                        throw new Error("Learner does not exist")
                    }

                    if (user.status === UserStatus.Pending) {
                        return this.compositionService.sendRegistrationEmailForExistingUser(email)
                    } else {
                        return this.authService.resetPasswordRequest(email)
                    }
                }).then(() => {
                    resolve()
                }).catch(error => {
					reject(error)
                })
        })
    }

    updatePasswordFor(firebaseUser: firebase.User, credential: firebase.auth.AuthCredential, newPassword: string): Promise<void> {
        return new Promise((resolve, reject) => {
            firebaseUser
                .reauthenticateWithCredential(credential)
                .then(() => {
                    let promises: Promise<any>[] = [
                        firebaseUser.updatePassword(newPassword),
                        this.userService.getUser(firebaseUser.uid)
                    ]
                    return Promise.all(promises)
                }).then((result) => {
                    if (!result) {
                        throw new Error("Error updating password and fetching user data")
                    }
                    return this.userService.updateUserTempPasswordStatusFor(result[1].documentId)
                }).then(() => {
                    resolve()
                }).catch(error => {
                    reject(error)
                })
        })
    }
}