import { injectable } from "inversify";
import { container } from "../DIContainer";
import { TYPES } from "../Types";
import { IAuth } from "../auth/Auth";

export interface IAppInitializerInformer {
    /**
     * Execute a handler when our app initializes firebase auth
     * @param handler Handler for when firebase auth is initialized
     */
    onInitialization(handler: () => void): void
}

@injectable()
export class AppInitializerInformer implements IAppInitializerInformer {
    private auth: IAuth = container.get<IAuth>(TYPES.IAuth)
    private handler: (() => void) | undefined

    constructor() {
        this.auth.addAuthObserver(this)
    }

    onInitialization(handler: () => void) {
        this.handler = handler
    }

    onAuthStateChange(_: boolean) {
        if (this.handler === undefined) {
            return
        }

        this.handler()
        this.handler = undefined
    }
}
