import React, { Component, MouseEvent } from "react"
import {
	Stack,
	IStackTokens,
	IStackStyles,
} from "@fluentui/react"
import { TabPanelProps } from "../TabPanelProps"
import { LearningPlan } from "../../../models/LearningPlan";
import { Checkbox, IIconStyles, ITooltipHostStyles, Label, MessageBar, MessageBarType, Spinner, TooltipHost, Icon } from "@fluentui/react";
import { TextField } from "../../../ui-kit/TextField";
import { PrimaryButton } from "../../../ui-kit/PrimaryButton";
import { ILearningPlanService } from "../../../services/LearningPlanService";
import { container } from "../../../DIContainer";
import { TYPES } from "../../../Types";
import { DocumentId } from "../../../global/TypeAliases";
import { LearningPlanEditor } from "../../../ui-kit/LearningPlanEditor";
import { i18nKey, Ii18n } from "../../../global/i18n";
import { Theme } from "../../../ui-kit/Theme";
import { DocumentDeletedOrNotFound } from "../../../ui-kit/DocumentDeletedOrNotFound";
import { ConfirmationDialog } from "../../../ui-kit/ConfirmationDialog"
import { IRouter } from "../../../Router";
import { ContentType, IContent } from "../../../models/IContent";
import { IFeatureFlagService } from "../../../services/FeatureFlagService";
import { IDateProvider } from "../../../services/DateProvider";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DestructiveIconButton } from "../../../ui-kit/DestructiveIconButton";
import { ICourseParser } from "../../../models/CourseParser";
import { ISurveyParser } from "../../../models/SurveyParser";
import { IEventReportingService } from "../../../services/EventReportingService";


interface EditLearningPlanDetailsProps extends TabPanelProps {
	documentId: DocumentId
	clientId: DocumentId
	learningPlan: LearningPlan
}

interface EditLearningPlanDetailsState {
	name: string
	description: string
	contents: IContent[] | null
	contentsDidUpdate: boolean
	updateButtonDisabled: boolean
	showSpinner: boolean
	showLearningPlanSpinner: boolean
	deleteButtonDisabled: boolean
	showDeleteLearningPlanSpinner: boolean
	didLearningPlanDelete: boolean
	deleteDialogIsHidden: boolean
	errorMessage: string | null
	successMessage: string | null

	//deadline states
	deadlineDate: Date | undefined
	addDeadlineIsChecked: boolean
	sendDeadlineReminderIsChecked: boolean
}

const stackStyling: IStackTokens = {
	childrenGap: "20px",
}

const deadlineStackStyling: IStackStyles = {
	root: {
		minWidth: 300
	}
}

const deleteIconStyles: IIconStyles = {
	root: {
		color: Theme.color.primary,
		fontSize: 20,
		justifyContent: 'center',
	},
}

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'center' } };
const addDeadlineCalloutProps = {
    gapSpace: 0,
    // If the tooltip should point to an absolutely-positioned element,
    // you must manually specify the callout target.
    target: `#add-learning-plan-deadline-information-icon`,
}

const sendDeadlineCalloutProps = {
	gapSpace: 0,
    // If the tooltip should point to an absolutely-positioned element,
    // you must manually specify the callout target.
    target: `#send-learning-plan-deadline-information-icon`,
}

export class EditLearningPlanDetails extends Component<EditLearningPlanDetailsProps, EditLearningPlanDetailsState> {
	private learningPlanService: ILearningPlanService = container.get<ILearningPlanService>(TYPES.ILearningPlanService)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
	private router: IRouter = container.get<IRouter>(TYPES.IRouter)
	private featureFlagService: IFeatureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService)
	private dateProvider: IDateProvider = container.get<IDateProvider>(TYPES.IDateProvider)
	private courseParser: ICourseParser = container.get<ICourseParser>(TYPES.ICourseParser)
	private surveyParser: ISurveyParser = container.get<ISurveyParser>(TYPES.ISurveyParser)
	private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)

	state: EditLearningPlanDetailsState = {
		name: this.props.learningPlan.name,
		description: this.props.learningPlan.description,
		contents: null,
		contentsDidUpdate: false,
		updateButtonDisabled: true,
		showSpinner: false,
		showLearningPlanSpinner: false,
		deleteButtonDisabled: false,
		deleteDialogIsHidden: true,
		showDeleteLearningPlanSpinner: false,
		didLearningPlanDelete: false,
		errorMessage: null,
		successMessage: null,
		addDeadlineIsChecked: false,
		sendDeadlineReminderIsChecked: false,
		deadlineDate: undefined
	}

	componentDidMount() {
		this.loadLearningPlan()
	}

	render() {
		return (
			<div
				className="admin-tab-panel"
				id={`admin-tab-panel-${this.props.index}`}
				aria-labelledby={`admin-tab-${this.props.index}`}
			>
				<Stack id="edit-learning-plan-details-container" tokens={stackStyling} styles={{ root: { marginBottom: 25 }}}>
					{ this.state.didLearningPlanDelete &&
						<>
							<DocumentDeletedOrNotFound message={this.i18n.get(i18nKey.learningPlanDoesNotExist)}/>
						</>
					}

						<>
							<Label>{this.i18n.get(i18nKey.updateLearningNameAndDescription)}</Label>
							<TextField
								id="edit-learning-plan-name-textfield"
								label="Name"
								onChange={this.nameTextFieldChanged}
								value={this.state.name}
							/>
							<TextField
								id="edit-learning-plan-description-textfield"
								label="Description"
								onChange={this.descriptionTextFieldChanged}
								value={this.state.description}
							/>
							
							<Stack id="learning-plan-editor">
								{ this.state.showLearningPlanSpinner &&
									<Spinner
										id="learning-plan-spinner"
										label="Loading learning plan..."
										ariaLive="assertive"
										labelPosition="right"
									/>
								}

								{ !this.state.showLearningPlanSpinner && this.state.contents && 
									<LearningPlanEditor 
										clientId={this.props.clientId} 
										initialContents={this.state.contents} 
										contentsDidUpdate={this.contentsDidUpdate}
									/>
								}
							</Stack>
							{ this.featureFlagService.deadline() &&
								<Stack id= "learning-plan-deadline-stack" tokens={stackStyling}>
									<Stack horizontal tokens={stackStyling}>
											<TooltipHost
												content={this.i18n.get(i18nKey.learningPlanDeadlineTooltipText)}
												hostClassName="learning-plan-deadline-reminder-tooltip"
												styles={hostStyles}
												calloutProps={addDeadlineCalloutProps}
											>
												<Stack horizontal styles={deadlineStackStyling} tokens={stackStyling}>
													<Icon id="add-learning-plan-deadline-information-icon" iconName="Info" styles={deleteIconStyles}/>
													<Checkbox 
														id="add-deadline-checkbox"
														label={this.i18n.get(i18nKey.learningPlanDeadlineCheckboxText)} 
														checked={this.state.addDeadlineIsChecked} 
														onChange={this.addDeadlineCheckboxOnChange} 
														boxSide="end"
													/>
												</Stack>
											</TooltipHost>
										{ this.state.addDeadlineIsChecked && 
											<DatePicker
												id="learning-plan-deadline-date-picker"
												selected={this.state.deadlineDate}
												onChange={this.onDeadlineDateChange}
												selectsStart
												showMonthDropdown
												showYearDropdown
												showTimeSelect
												dateFormat="MMMM d, yyyy h:mm aa"
												startDate={this.dateProvider.getDate()} 
												minDate={this.dateProvider.getDate()}
												placeholderText={this.i18n.get(i18nKey.learningPlanDatepickerPlaceholderText)}
											/>
										}
									</Stack>
									{ this.state.addDeadlineIsChecked &&
											<TooltipHost
												content={this.i18n.get(i18nKey.sendDeadlineReminderTooltipText)}
												hostClassName="learning-plan-send-deadline-reminder-tooltip"
												styles={hostStyles}
												calloutProps={sendDeadlineCalloutProps}
											>
												<Stack horizontal tokens={stackStyling}>
													<Icon id="send-learning-plan-deadline-information-icon" iconName="Info" styles={deleteIconStyles}/>
													<Checkbox 
														id="learning-plan-send-deadline-reminder-checkbox"
														label={this.i18n.get(i18nKey.sendDeadlineReminder)} 
														checked={this.state.sendDeadlineReminderIsChecked} 
														onChange={this.sendDeadlineReminderCheckboxOnChange} 
														boxSide="end"
													/>
												</Stack>
											</TooltipHost>
									}
								</Stack>
							}

							{ !this.state.showSpinner &&
								<PrimaryButton
									id="edit-learning-plan-primary-button"
									text="Update"
									onClick={this.updateButtonClicked}
									disabled={this.state.updateButtonDisabled}
									hidden={this.state.showSpinner}
								/>						
							}

							{ this.state.showDeleteLearningPlanSpinner && 
								<Spinner
									id="edit-learning-plan-details-delete-spinner"
									label="Deleting Learning Plan..."
									ariaLive="assertive"
									labelPosition="right"
								/>
							}
							{ !this.state.showDeleteLearningPlanSpinner 
								&& this.state.successMessage === null 
								&& this.state.errorMessage === null &&
								<DestructiveIconButton
									id="edit-learning-plan-details-delete-button"
									onClick={this.deleteButtonClicked}
									disabled={this.state.deleteButtonDisabled}
								/> 
							}

							{ !this.state.deleteDialogIsHidden && 
								<ConfirmationDialog
									title={this.i18n.get(i18nKey.deleteLearningPlanTitle)}
									dialogMessage={this.i18n.get(i18nKey.deleteLearningPlanMessage)}
									affirmativeButtonText={this.i18n.get(i18nKey.deleteLearningPlanAffirmativeButton)}
									dismissButtonText={this.i18n.get(i18nKey.deleteLearningPlanDismissButton)}
									affirmativeActionHandler={this.deleteButtonDestructiveAction}
									dismissActionHandler={this.deleteDialogDismissed}
									isDestructive={true}
								/>
							}
						</>

					{this.banner()}

					{ this.state.showSpinner &&
						<Spinner
							id="edit-learning-plan-spinner"
							label="Updating learning plan..."
							ariaLive="assertive"
							labelPosition="right"
						/>
					}
				</Stack>
			</div>
		)
	}

	private loadLearningPlan = () => {
		try {
			if (this.props.learningPlan.contents.length <= 0 || this.props.learningPlan.contents === undefined) {
				return []
			} else {
				const contents: IContent[] = this.props.learningPlan.contents.map((content: any) => { 
					if (content.contentType === ContentType.survey) {
						return this.surveyParser.parse(content.documentId, content) as IContent
					} else {
						return this.courseParser.parse(content.documentId, content) as IContent
					}
				})

				this.setState({ 
					contents: contents, 
					errorMessage: null,
					showLearningPlanSpinner: false 
				})
			}
		} catch {
			const error = new Error(this.i18n.get(i18nKey.contentsForLearningPlanParseError) + this.props.learningPlan.documentId)
			this.eventReportingService.error(error.message, error)
			this.setState({ 
				errorMessage: "Failed to load learning plan",
				showLearningPlanSpinner: false,
				didLearningPlanDelete: true  
			})

			return []
		}
	}

	dismissBanner = () => {
		this.setState({ 
			errorMessage: null, 
			successMessage: null 
		});
	}

	private updateButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: null,
			successMessage: null,
			showSpinner: true,
			updateButtonDisabled: true,
			deleteButtonDisabled: true
		});

		this.learningPlanService
			.updateLearningPlan(
				this.props.documentId,
				this.state.name,
				this.state.description,
				this.state.contents ?? []
			)
			.then(() => {
				this.setState({
					successMessage: this.state.name + " has been updated",
					showSpinner: false,
					updateButtonDisabled: false,
					deleteButtonDisabled: false
				});
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showSpinner: false,
					updateButtonDisabled: false,
					deleteButtonDisabled: false
				});
			});
	}

	deleteButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({deleteDialogIsHidden: false})
	}
	
	deleteDialogDismissed = () => {
		this.setState({deleteDialogIsHidden: true})
	}

	deleteButtonDestructiveAction = () => {
		this.setState({
			errorMessage: null,
			successMessage: null,
			showDeleteLearningPlanSpinner: true,
			updateButtonDisabled: true,
			deleteButtonDisabled: true,
		});

		this.learningPlanService
			.deleteLearningPlan(this.props.learningPlan.documentId)
			.then(() => {
				this.setState({
					successMessage: "Learning Plan " + this.props.learningPlan.name + " has been deleted.",
					showDeleteLearningPlanSpinner: false,
					didLearningPlanDelete: true
				})
				
				this.router.goToContentsLearningPlanTab(this.props.clientId)
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showDeleteLearningPlanSpinner: false,
					updateButtonDisabled: false,
					deleteButtonDisabled: false,
					deleteDialogIsHidden: true
				});
			})
	}

	contentsDidUpdate = (updatedContents: IContent[]) => {
		this.setState({ contentsDidUpdate: true, contents: updatedContents }, () => {
			this.enableUpdateButtonIfNeeded()
		})
	}

	private nameTextFieldChanged = (
		_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return;
		}

		this.setState({ name: newValue }, () => {
			this.enableUpdateButtonIfNeeded();
		});
	}

	private descriptionTextFieldChanged = (
		_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return;
		}

		this.setState({ description: newValue }, () => {
			this.enableUpdateButtonIfNeeded();
		});
	}

	private enableUpdateButtonIfNeeded = () => {
		let disable = true

		if (this.state.name.length > 0 || 
			this.state.description.length > 0 || 
			this.state.contentsDidUpdate) {
			disable = false
		}

		this.setState({ updateButtonDisabled: disable })
	}
	
	private banner = () => {
		let banner = null

		if (this.state.errorMessage) {
			banner = (
				<MessageBar
					id="edit-learning-plan-error-banner"
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.errorMessage}
				</MessageBar>
			)
		} else if (this.state.successMessage) {
			banner = (
				<MessageBar
					id="edit-learning-plan-success-banner"
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			)
		}

		return banner
	}

	addDeadlineCheckboxOnChange = () => {
		this.setState({addDeadlineIsChecked: !this.state.addDeadlineIsChecked})
	}

	sendDeadlineReminderCheckboxOnChange = () => {
		this.setState({sendDeadlineReminderIsChecked: !this.state.sendDeadlineReminderIsChecked})
	}

	onDeadlineDateChange = (date: Date) => {
        this.setState({
            deadlineDate: date
        })
    }
}