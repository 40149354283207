
import { 
    IIconProps,
    IStackStyles, 
    IStackTokens,
} from '@fluentui/react';
import { ISeparatorStyles } from '@fluentui/react';
import { Theme } from '../../ui-kit/Theme';

export class ProfileUIStyles {
    static separatorStyles: Partial<ISeparatorStyles> = {
        root: {
            padding: "0px",
            backgroundColor: Theme.control.textFieldBorder,
            height: "1px",
            margin: "20px 0px"
        }
    }

    static editProfileModalStackStyles: IStackStyles = {
        root: {
            maxWidth: 800,
            padding: "20px",
        }
    }

    static editProfileCloseButton: IStackStyles = {
        root: {
            position: "absolute",
            right: "0px"
        }
    }

    static pageContainerStyle: IStackStyles = {
        root: {
            width: '100%'
        }
    }

    static imageContainerStyle: IStackStyles = {
        root: {
            width: '100%'
        }
    }

    static profileInfoContainerStyle: IStackStyles = {
        root: {
            width: '85%',
            padding: '25px',
            backgroundColor: "#ffffff",
            borderRadius: '10px'
        }
    }

    static profileInfoPrimaryContainerStyle: IStackStyles = {
        root: {
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            paddingRight: '50px'
        }
    }

    static profileInfoSecondaryContainerStyle: IStackStyles = {
        root: {
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '50px',
            paddingRight: '10px'
        }
    }

    static profileButtonContainerStyle: IStackStyles = {
        root: {
            width: '50%'
        }
    }
}

export class ProfileUITokens {
	static verticalGapStackTokens: IStackTokens = {
        childrenGap: 20,
    };

	static formFieldGapStackTokens: IStackTokens = {
        childrenGap: 20,
    };

    static horizontalGapStackTokens: IStackTokens = {
        childrenGap: 20,
    };
}

export class ProfileUIIcons {
    static edit: IIconProps = { iconName: 'Edit'};
    static settings: IIconProps = { iconName: 'Settings'};
    static logout: IIconProps = { iconName: 'SignOut'};
    static cancel: IIconProps = {iconName: 'Cancel'}
    static lock: IIconProps = {iconName: 'Lock'}
}
