/* istanbul ignore file */
import "reflect-metadata"
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './css/Global.css';
import App from './App';
import { container } from './DIContainer'
import * as serviceWorker from './serviceWorker';
import { initializeIcons } from '@fluentui/react';
import { TYPES } from "./Types";
import { IAppInitializerInformer } from "./services/AppInitializerInformer";
import { Theme } from './ui-kit/Theme'
import { Router } from "react-router-dom";
import history from './History'
import { ContainerProvider } from "./ContainerProvider";

Theme.load()
initializeIcons();

const appInitializer = container.get<IAppInitializerInformer>(TYPES.IAppInitializerInformer)

appInitializer.onInitialization(() => {   
  ReactDOM.render(
    <React.StrictMode>
        <Router history={history}>
          <ContainerProvider container={container}>
            <App />
          </ContainerProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
  );
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
