import React, { Component } from "react"
import { i18nKey, Ii18n } from "../../global/i18n"
import { container } from "../../DIContainer"
import { TYPES } from "../../Types"
import { GuestGridItem } from "./GuestGridItem"
import { IContent } from "../../models/IContent"

interface GuestGridProps {
    content: IContent[]
    areGridItemsClickable: boolean
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}

export class GuestGrid extends Component<GuestGridProps> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    render() {
        if (!this.props.content.length) {
            return <div id="guest-grid-no-content-displayed">{this.i18n.get(i18nKey.noGuestContent)}</div>
        }

        const gridItems = this.props.content.map((entry) => {
            return <GuestGridItem key={entry.documentId} content={entry} isClickable={this.props.areGridItemsClickable} onClick={this.props.onClick} />
        })

        return (
            <div id="guest-grid">
                {gridItems}
            </div>
        )
    }
}
