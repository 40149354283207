import React, { Component } from "react";
import { Form, Input, Label, UncontrolledAlert } from "reactstrap";
import { Ii18n, i18nKey } from "../../global/i18n";
import { container } from "../../DIContainer";
import { TYPES } from "../../Types";
import { IAuthService } from "../../services/AuthService";
import { IResetPasswordCompositionService } from "../../services/ResetPasswordCompositionService";
import { ILocalStorage } from "../../utils/LocalStorage";

interface UpdatePasswordFormProps {
    fbUser: firebase.User | null;
}

interface UpdatePasswordFormState {
    currentPassword: string,
    showCurrentPW: boolean,
    newPassword: string,
    showNewPW: boolean,
    newPasswordConfirm: string,
    showNewPWConfirm: boolean,
    disablePrimaryButton: boolean,
    showSpinner: boolean,
    errorMessage: string | undefined,
    successMessage: string | undefined,
}

export default class UpdatePasswordForm extends Component<UpdatePasswordFormProps, UpdatePasswordFormState> {
    private authService: IAuthService = container.get<IAuthService>(TYPES.IAuthService);
	private compositionService: IResetPasswordCompositionService = container.get<IResetPasswordCompositionService>(TYPES.IResetPasswordCompositionService)
	private storage = container.get<ILocalStorage>(TYPES.ILocalStorage)

    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n);
    private showUpdatePWModalStorageKey = "showUpdatePWModal"

    state = {
        currentPassword: "",
        showCurrentPW: false,
        newPassword: "",
        showNewPW: false,
        newPasswordConfirm: "",
        showNewPWConfirm: false,
        disablePrimaryButton: true,
        showSpinner: false,
        errorMessage: undefined,
        successMessage: undefined,
    }

    checkIsValid = () => {
        const isValid =
            this.state.currentPassword.length > 0 &&
            this.state.newPassword.length >= 0 &&
            this.state.newPasswordConfirm.length > 0 &&
            this.state.newPasswordConfirm === this.state.newPassword;

        this.setState({ disablePrimaryButton: isValid })
    }

    handleCurrentPasswordFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ currentPassword: e.target.value })
        this.checkIsValid()
    };

    handleNewPasswordFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ newPassword: e.target.value })
        this.checkIsValid()
    };

    handleNewPasswordConfirmFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({newPasswordConfirm: e.target.value})
        this.checkIsValid()
    };

    onUpdatePasswordClick = () => {
        this.setState({
            showSpinner: true,
            disablePrimaryButton: true,
            errorMessage: undefined,
            successMessage: undefined
        })

        if (this.state.newPassword !== "" && this.props.fbUser && this.props.fbUser.email !== null) {
            const credential = this.authService.userCredentials(this.props.fbUser.email, this.state.currentPassword);

            this.compositionService
                .updatePasswordFor(this.props.fbUser, credential, this.state.newPassword)
                .then(() => {
                    this.setState({
                        showSpinner: false,
                        disablePrimaryButton: true,
                        successMessage: this.i18n.get(i18nKey.changePasswordSuccess)
                    })

                    this.storage.delete(this.showUpdatePWModalStorageKey)
                }).catch((error) => {
                    this.setState({
                        showSpinner: false,
                        disablePrimaryButton: true,
                        errorMessage: this.i18n.get(i18nKey.passwordUpdateError)
                    })
                });
        }
    };

    render() {
        return (
        <>
            {this.state.errorMessage && (
                <UncontrolledAlert data-testid="error-banner" color="danger">
                    {this.state.errorMessage}
                </UncontrolledAlert>
            )}
            {this.state.successMessage && (
                <UncontrolledAlert data-testid="success-banner" color="success">
                    {this.state.successMessage}
                </UncontrolledAlert>
            )}
            <Form>
                <div className="mb-3">
                    <Label className="form-label">{this.i18n.get(i18nKey.currentPassword)}</Label>
                    <div className="input-group auth-pass-inputgroup">
                        <Input
                            type={this.state.showCurrentPW ? "text" : "password"}
                            className="form-control"
                            data-testid="current-pw-input"
                            onChange={this.handleCurrentPasswordFieldChange}
                        />
                        <button
                            onClick={() => this.setState({ showCurrentPW: !this.state.showCurrentPW})}
                            className="btn btn-light shadow-none ms-0"
                            type="button"
                        >
                            {this.state.showCurrentPW ? (
                                <i className="mdi mdi-eye-outline"></i>
                            ) : (
                                <i className="mdi mdi-eye-off"></i>
                            )}
                        </button>
                    </div>
                </div>
                <div className="mb-3">
                    <Label className="form-label">{this.i18n.get(i18nKey.newPassword)}</Label>
                    <div className="input-group auth-pass-inputgroup">
                        <Input
                            type={this.state.showNewPW ? "text" : "password"}
                            className="form-control"
                            data-testid="new-pw-input"
                            onChange={this.handleNewPasswordFieldChange}
                        />
                        <button
                            onClick={() => this.setState({ showNewPW: !this.state.showNewPW})}
                            className="btn btn-light shadow-none ms-0"
                            type="button"
                        >
                            {this.state.showNewPW ? <i className="mdi mdi-eye-outline"></i> : <i className="mdi mdi-eye-off"></i>}
                        </button>
                    </div>
                </div>
                <div className="mb-3">
                    <Label className="form-label">{this.i18n.get(i18nKey.confirmPassword)}</Label>
                    <div className="input-group auth-pass-inputgroup">
                        <Input
                            type={this.state.showNewPWConfirm ? "text" : "password"}
                            className="form-control"
                            data-testid="confirm-pw-input"
                            onChange={this.handleNewPasswordConfirmFieldChange}
                        />
                        <button
                            onClick={() => this.setState({ showNewPWConfirm: !this.state.showNewPWConfirm})}
                            className="btn btn-light shadow-none ms-0"
                            type="button"
                        >
                            {this.state.showNewPWConfirm ? (
                                <i className="mdi mdi-eye-outline"></i>
                            ) : (
                                <i className="mdi mdi-eye-off"></i>
                            )}
                        </button>
                    </div>
                </div>
                <div className="text-center mt-4">
                    {!this.state.showSpinner && (
                        <button
                            type="button"
                            className="btn btn-secondary profile-button"
                            data-testid="update-pw-primary-button"
                            onClick={this.onUpdatePasswordClick}
                            disabled={this.state.disablePrimaryButton}
                        >
                            {this.i18n.get(i18nKey.updatePassword)}
                        </button>
                    )}
                    {this.state.showSpinner && (
                        <>
                            <button type="button" className="btn btn-light waves-effect" data-testid="spinner-button">
                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                            </button>
                        </>
                    )}
                </div>
            </Form>
        </>
        )
    }
}