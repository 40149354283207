import { injectable } from "inversify"
import { DocumentId } from "../global/TypeAliases"

export type APIKey = string
export const APIKEY_NOTFOUND = 'not-found'

export interface IAPIKeyProvider {
    /**
     * Get the authorization token for learning locker
     * @param clientId The client id
     */
    learningLockerKeyForClientId(clientId: DocumentId): APIKey
}

@injectable()
export class APIKeyProvider implements IAPIKeyProvider {
    /**
     * Key is the Firebase document id for the client
     * Value is the auth token in learning locker
     */
    private keys: {[key: string]: APIKey} = {
        /**
         * SPS Prod
         */
        "9G8s9oCOYRr0SvDDi2p4": "Basic YjBkNWMyNWJjMTEzODNmMmFhNjBjNTM2MzkwMTEyYjM3ZmNkYTBhOTo5ODE1OTNkNWQwNjU0ODBkOTc1MDBjNjUwZWFjZWQwNzRiM2ViYzMx",
        
        /**
         * SPS Dev
         */
        "yQ1jNaYJszQJuCBxzQer": "Basic NDEwY2YyNmQ2OWJhYmI3MTAzNmMzZDk2YzBmYjEyOTllZTgyMTczMTo5YTdiMTY4ZGIyZmRmNWZhNzZlY2EzNGQ0NzM3M2EyNzQ0NjI0MTgz",
        
        /**
          * SPS Local
          */
        "jO4aHCZJx9M0XCm3Nq2D": "Basic NDEwY2YyNmQ2OWJhYmI3MTAzNmMzZDk2YzBmYjEyOTllZTgyMTczMTo5YTdiMTY4ZGIyZmRmNWZhNzZlY2EzNGQ0NzM3M2EyNzQ0NjI0MTgz",
 
        /**
         * RSD Prod
         */
        "Qtuh7HBzf2yHoPpqwS4I": "Basic NGZjMTA0MzEwYzE5ZmYwODg0YTNlMTdlOWRiYmMwMGRiNzAyNzQ4MDplZWQ2M2ZhYWQ5NTkxOWUzMDA0ZmEzZGIwMDliYjYzMDhhODNkNzEy",
        
        /**
         * RSD Local
         */
        "tWwVNBinaqTZcFZMFP92": "Basic ODA2ZWZkZWM0MmM2ZWE1NjQyMTY2YmJkZjQxZGQ2Mzc5MDYxNmRmNjplM2QxZWY5NjhkODliYTE1YjQ2MzU4NjU4ZGI1NmI3MDQ3NTA5MDJl",

        /**
         * RSD Dev
         */
        "Q3vjJIHO5LXzbOcZJfod": "Basic ODA2ZWZkZWM0MmM2ZWE1NjQyMTY2YmJkZjQxZGQ2Mzc5MDYxNmRmNjplM2QxZWY5NjhkODliYTE1YjQ2MzU4NjU4ZGI1NmI3MDQ3NTA5MDJl",

        /**
         * Cyborg Mobile Prod
         */
        "og9I6J3f8gL7Zt0X7x05": "Basic NTVkOWQ0MGRmOGFkM2NmMmQ0ZDFmOTQ1YzgyYTI5ZDQ1YWE2OGIyODo4YjlmODdiMjc3MzdhNjJhZDBmYzlmNDE2ZWI3Mzc5ODE2OTZiM2E4",

        /**
         * Cyborg Mobile Dev
         */
        "sH9HTDJ8Qu91xcOF6jRJ": "Basic Mjk0YWFkZDJmYWFkMGJiZTAwN2Y4MzBkMzNmOTUzNjEyYWE3ZGNkYzpmMzhjNmE2MzNiODNhZmFhMDI1NTVkOWMyYzI3MzI5YzBhYmNkNzJk",

        /**
         * Cyborg Mobile Local
         */
        "eI2bAl2YF5hiU1phpdDE": "Basic Mjk0YWFkZDJmYWFkMGJiZTAwN2Y4MzBkMzNmOTUzNjEyYWE3ZGNkYzpmMzhjNmE2MzNiODNhZmFhMDI1NTVkOWMyYzI3MzI5YzBhYmNkNzJk",

        /**
         * Issaquah School District (ISD) Prod
         */
        "s4CtDJDmQiVZms9OTjIv": "Basic NzgyZWViMGZjOWUxMjUxZmM2ODE4MGM0ZjdlM2ZkOGE1NTg5NzQzYTo1NmI5NzcyMjJkYWJhMDk3MGMxZWZmMTU4OTljOGUzZDFhNzkxM2Nk",

        /**
         * Issaquah School District (ISD) Dev
         */
        "A1MHshvU6NS83n7oxuwS": "Basic NmM1MjgwY2M0ZjEzZWI5YjcxYjIyNmI0NDhlMTE2ODA1ZTNkZWZlMToxYmVjZWU2NzIzNDEwNWFkOGZmODMwZmIzYWM4MmM2YjhmM2VmZDFj",

        /**
         * Issaquah School District (ISD) Local
         */
        "5mutd9wdrqOelckMbe6Z": "Basic NmM1MjgwY2M0ZjEzZWI5YjcxYjIyNmI0NDhlMTE2ODA1ZTNkZWZlMToxYmVjZWU2NzIzNDEwNWFkOGZmODMwZmIzYWM4MmM2YjhmM2VmZDFj",

        /**
         * Leafly Prod
         */
        "p9ON7TDjMxIjyzcF2ru5": "Basic NTM4NjE2OGFiYjJiZTA1MWUwYzVmOTdhYjc2MmZmMjE3ZWM2YWU4MzozMmZhZmY0MTI1OTBhZWYzZjhjNjBmYmU0ODczZDM3NjQzZWYzNTU5",

        /**
         * Leafly Dev
         */
        "NJBwBvVFod6UrDrDHXYC": "Basic MzYxNDEzZGVhNTZiMDhhNDE2YWE2NTIwZWI0MGQ1NmYzMjg2NTNkNDowYjYzYTIzNzJjYjFkYzc0MTFkMjQ2NjQ3OTY3ZTgzYWNhNzVkNzAy",

        /**
         * Leafly Local
         */
        "j7wu0DWwdKETYDACljAi": "Basic MzYxNDEzZGVhNTZiMDhhNDE2YWE2NTIwZWI0MGQ1NmYzMjg2NTNkNDowYjYzYTIzNzJjYjFkYzc0MTFkMjQ2NjQ3OTY3ZTgzYWNhNzVkNzAy",

        /**
         * Phase 2 Prod
         */
        "InlDIk4PFFE52TyF2eHc": "Basic ODgyOTE0ODc4Nzk1YjEzZDZkZjNmM2NmMzljMWI0ZTI5ODYxZjkwNDoyMzcwNmM5YzIxNmY3MWNiNjc0YTk3ODA0ZjMyMTRiYmQxZTgzMDgz",

        /**
         * Phase 2 Dev
         */
        "WpbiTTUka6GxzIeXHt0L": "Basic MmNiZmNjNDBkYzU1NjYxNmJkNjk2MTYwZmEzOWQ4NmY2MDlmODY5MTphMGMyODgxZDg2MTYxNGZhODE0YWQzZDA2Mzk4NWIzNTlkNWU4ODlm",

        /**
         * Phase 2 Local
         */
        "Ub8Dv6D0zdhyGE1v2MXG": "Basic MmNiZmNjNDBkYzU1NjYxNmJkNjk2MTYwZmEzOWQ4NmY2MDlmODY5MTphMGMyODgxZDg2MTYxNGZhODE0YWQzZDA2Mzk4NWIzNTlkNWU4ODlm",

        /**
         * NSD Prod
         */
        "OrRBF3pDQEL0bSkFTlKF": "Basic MDYwMmI0NjFjMjk5ZWQ1ODAzZjlhYjNkYWY3YTZmYzBhZjg2ZWI4MDpmYWE4Y2RiNWI3NzQ3MDQ0OGEyNWVhN2EyZWJiY2EyNDAyYzQwNTE4",

        /**
         * NSD Dev
         */
        "bEqGbPGREMz4TCUIGA1F": "Basic ODk2ZThiZmZjMmNlZjNjZDVhNTQ5ZjA4YjRmYTAzNDVmMzJkYjNiNDpiZmJjMDQ1ZmRjYTA3MGM4ODZhMDIxMGNhNDNiYWQzNzFmMjhiMDlm",

        /**
         * NSD Local
         */
        "sZVSe8W1m5pd0cqNK5iN": "Basic ODk2ZThiZmZjMmNlZjNjZDVhNTQ5ZjA4YjRmYTAzNDVmMzJkYjNiNDpiZmJjMDQ1ZmRjYTA3MGM4ODZhMDIxMGNhNDNiYWQzNzFmMjhiMDlm",

        /**
         * Preview Prod
         */
        "L1ecsT0H9UqxyhzDIkgW": "Basic MjI0MDg2YWY2Njg4MDA4NTM0ZWRiMGI0NmEyMWFkOTU4Y2IwZjE0ZjpmM2Q4ZWQ3NmUyMGI3M2FjODhiYjMxYmI0MmI5Nzg2MTU2ZGM1N2Ew",

        /**
         * Preview Dev doesn't exist
         */

        /**
         * Preview Local doesn't exist
         */

        /**
         * Cyborg Mobile Demo Prod
         */
        "kUW4Kdr1wW7QTJvuysoo": "Basic NmM5OWFhY2I1NTViMDhlOGJmN2QwODZjZTZhYzUzMDllZjc1NGFmYzoxYjEwNmFhMDUyODllNzJhNTUzOTUyNmUwMzg1MmY4MmM2YTAyNTE4",

        /**
         * Cyborg Mobile Dev doesn't exist
         */

        /**
         * Cyborg Mobile Local doesn't exist
         */

        /**
         * Guest Prod
         */
        "hNPXfFgPNoL8duFnWpNv": "Basic OGY5MTE4MDQyZjQ5OTQ5ZDFlNjI3MzhhYzIwYjQ5NDY5Yzg0ZjBmMjpmMmQyZGMxYThiMDJiNjJhY2RlMjYyMGM3ODRiNTUyNjk4NTA4N2Vi",

         /**
          * Guest Dev
          */
        "WLIvyRGKqy1dc7ibXWmD": "Basic ZmExYjc0ZTgwMWY4ZmQ5ODdkMDk2ZTFhYjcwMjY0ZjRiNWYyODAzODo1OWY1ODk2NDA0ZTZlMTY0NmY1Y2Q5MGI4ODJhMTIxZTk5YTM1YjBl",
 
         /**
          * Guest Local
          */
        "oLma2pfvbkB0lkHtyAO9": "Basic ZmExYjc0ZTgwMWY4ZmQ5ODdkMDk2ZTFhYjcwMjY0ZjRiNWYyODAzODo1OWY1ODk2NDA0ZTZlMTY0NmY1Y2Q5MGI4ODJhMTIxZTk5YTM1YjBl",

         /**
         * SPS Schools Community Partnerships Prod
         */
        "pNAwpvUNFkX8DjEnpv6Y": "Basic Nzc3OGQ5ODEyYTk2ODA4ODE0ZmIzNjhhNGY3MGUwNzEyOWVjM2YwZDpiN2I3NjI4MjViNTM4YTg2ZGI3N2M3ZGYyN2M0NTk0OTRiMjhhMTk5",

        /**
         * SPS Schools Community Partnerships Dev doesn't exist 
         */
  
        /**
         * SPS Schools Community Partnerships Local doesn't exist
         */

        /**
         * Tripr Prod
         */
        "Yis6nTspvgGEJV5Pdy4L": "Basic ZGRjMjY3NTNkOTQ2ZGE5Y2ViNjk2ZWVhYWE4MGZmNzg5ZmZiZDM0Nzo4YTFiNjQ5MzZlNmQ4OTkzMTk2MmE2ZTY0ODA1NzE3Y2Y1Yjc4MGI3",

        /**
         * Tripr Dev
         */
        "kH0Zv3nbLAwAUXAlj5jr": "Basic YjJjMmE5NjdjNDQ2Yjc1MTM4ZjIwNzVlMjRhMTUxMGEwMmRhMjFkNjpjNjBiOWEwYTVjZGQ5NzZmYWNkNWE5Y2FiNDEzYTM5OThlNDA0OTE5",

        /**
         * Tripr Local
         */
        "sVvkY4pmvtQ0fch6ArKC": "Basic YjJjMmE5NjdjNDQ2Yjc1MTM4ZjIwNzVlMjRhMTUxMGEwMmRhMjFkNjpjNjBiOWEwYTVjZGQ5NzZmYWNkNWE5Y2FiNDEzYTM5OThlNDA0OTE5",

        /**
         * M365 Prod
         */
        "1ny62NOzbyKN4O5vLtPz": "Basic MWM4YWExNzM3ZTRhMDczYmY3ODE3NzY2YWM2NWVmZWJhMWU0MTM3Yzo2OGMwY2EwZGFiMTM0ZTEzM2U1YWFmZTgwNjE4YzA5OTBkNmI5MTRi",

        /**
         * M365 Dev
         */
        "IQZrDNhNYNwpwFpeTBIi": "Basic OGU4ZDI4OGU0MTExMjc2YmZjMjdkYmYzM2I5YWJiNDZjNzM4NTNhNzpjMjJlZGVjZGViYWQ1OTQzNzBjZDFmYmFiMmNjMWUxMGM5NDFkNGFj",

        /**
         * M365 Local
         */
        "I18HSVyt8eq2P30J8ybf": "Basic OGU4ZDI4OGU0MTExMjc2YmZjMjdkYmYzM2I5YWJiNDZjNzM4NTNhNzpjMjJlZGVjZGViYWQ1OTQzNzBjZDFmYmFiMmNjMWUxMGM5NDFkNGFj",

        /**
         * John Knox - Marvista Volunteers Prod
         */
        "bkJ7IttN53cNMJY2EcUY": "Basic MGQ2NWNlMmMyMjI2NzVjYzVlODZhYzhkNWQ5NjI4NzRlMWJiZTNhMDpmYjg5OTk1ZTIwMTU3MDUwZjU1MzI0YWJiODk5OGIwMGE0MzE2ZTRl",

        /**
         * John Knox - Marvista Volunteers Dev
         */
        "DdWm4DKcHdfaXiBxQgE9":"Basic M2IxMjQ5NjEzMjM4MWFkNDU4ZWJlZmNmNjFiMWU5MDY3MTg0M2RjZjo1ZjhkNjBmODMwNzg5MzljZDUyOThjZGRlOWRkNDljYWEwODEzMTA3",

        /**
         * John Knox - Marvista Volunteers Local
         */
        "TD4tMuTCMsqce9yHNGTG":"Basic M2IxMjQ5NjEzMjM4MWFkNDU4ZWJlZmNmNjFiMWU5MDY3MTg0M2RjZjo1ZjhkNjBmODMwNzg5MzljZDUyOThjZGRlOWRkNDljYWEwODEzMTA3",

        /**
         * Cascadia College Prod
         */
        "FXcKQDjsBL9IhV6XfylQ": "Basic NjM5YmYwNDU0YThkZTUyYzNkOWQzZjg1NjZiMzc0NGZlOGM1YzZlMzowZmViMGM0MzM3MTRkNGI2ODM0OGNiZTg3N2M2MDAzMTk1MWI5NTM3",

        /**
         * Cascadia College Dev
         */
        "TR6wecqHn3KPXaoZ0CYw":"Basic NWMzMjA0NGQ0NTM0M2NjYzIzMTljOTMzZjg3M2RkMTk3OTI3MTRiZTo5YjAzNGZlNzRhYTE1ZDBiYzI5MTcwMWIyNzAyNDg1ZThlYjQ4ODg0",

        /**
         * Cascadia College Local
         */
        "aOML6zzVdbssKlgCyum1":"Basic NWMzMjA0NGQ0NTM0M2NjYzIzMTljOTMzZjg3M2RkMTk3OTI3MTRiZTo5YjAzNGZlNzRhYTE1ZDBiYzI5MTcwMWIyNzAyNDg1ZThlYjQ4ODg0"
    }

    learningLockerKeyForClientId(clientId: DocumentId): APIKey {
        const key = this.keys[clientId]

        if (!key) {
            return APIKEY_NOTFOUND
        }

        return key
    }
}