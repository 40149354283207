import { CleverUserId, Dictionary } from './../global/TypeAliases';
import { RoleType } from './RoleType';
import firebase from 'firebase';
import { IDateProvider } from './../services/DateProvider';
import { container } from '../DIContainer';
import { TYPES } from '../Types';

export enum UserDocumentKey {
	Email = "email",
	FirstName = "firstName",
	LastName = "lastName",
	UID = "uid",
	MID = 'mId'
}

export enum UserStatus {
	Pending = "pending",
	Active = "active"
}

export default class User {
	uid: string;
	firstName: string;
	lastName: string;
	email: string;
	documentId: string;
	role: RoleType;
	readonly createdAt: string;
	clientManaged: string; // Will contain a value if role is client admin
	status: string
	cleverId: CleverUserId | undefined
	cleverDistrictId: string | undefined
	cohorts: Dictionary | undefined
	mId: string
	tempPassword: boolean 

	get fullName(): string {
		return this.firstName + " " + this.lastName;
	}

	get roleName(): string {
		switch (this.role) {
			case RoleType.learner:
				return "Learner";
			case RoleType.clientAdmin:
				return "Client Admin";
			case RoleType.globalAdmin:
				return "Global Admin";
			default:
				return "Learner"
		}
	}

	get _status(): string {
		switch (this.status.toLowerCase()) {
			case UserStatus.Pending:
				return "Pre-registered"
			case UserStatus.Active: 
				return "Registered"
			default: 
				return "Unknown"
		}
	}

	constructor(
		uid: string,
		firstName: string,
		lastName: string,
		email: string,
		documentId: string,
		role: RoleType,
		createdAt: string,
		clientManaged: string,
		status: string,
		cleverId: CleverUserId,
		cleverDistrictId: string,
		cohorts: Dictionary,
		mId: string,
		tempPassword: boolean
	) {
		this.uid = uid;
		this.firstName = firstName;
		this.lastName = lastName;
		this.email = email;
		this.documentId = documentId;
		this.role = role;
		this.createdAt = createdAt;
		this.clientManaged = clientManaged;
		this.status = status
		this.cleverId = cleverId
		this.cleverDistrictId = cleverDistrictId
		this.cohorts = cohorts
		this.mId = mId
		this.tempPassword = tempPassword
	}

	static Converter = {
		toFirestore: function (user: User): firebase.firestore.DocumentData {
			const dateProvider: IDateProvider = container.get<IDateProvider>(TYPES.IDateProvider)
			return {
				uid: user.uid,
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				documentId: user.documentId,
				role: user.role,
				createdAt: dateProvider.parseStringDateToTimestamp(user.createdAt),
				clientManaged: user.clientManaged,
				status: user.status,
				cleverId: user.cleverId,
				cleverDistrictId: user.cleverDistrictId,
				cohorts: user.cohorts,
				mId: user.mId,
				tempPassword: user.tempPassword
			};
		},
		fromFirestore: function (
			snapshot: firebase.firestore.QueryDocumentSnapshot, 
			options: firebase.firestore.SnapshotOptions) {
				const dateProvider: IDateProvider = container.get<IDateProvider>(TYPES.IDateProvider)
				const data = snapshot.data(options);
				return new User(
					data.uid,
					data.firstName,
					data.lastName,
					data.email,
					data.documentId,

					// If they never had a role assigned to them assign them to learner by default
					data.role === undefined ? RoleType.learner : data.role,
					data.createdAt === undefined ? "N/A" : dateProvider.parseFirebaseTimestampToStringDate(data.createdAt),
					data.clientManaged === undefined ? "" : data.clientManaged,
					data.status === undefined ? "" : data.status,
					data.cleverId,
					data.cleverDistrictId,
					data.cohorts === undefined ? {} : data.cohorts,
					data.mId === undefined ? "" : data.mId,
					data.tempPassword === undefined ? false : data.tempPassword
				);
		},
	};
}
