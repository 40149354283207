import { Component } from "react"
import { ChartData } from "../models/ChartData"
import { Text } from "@fluentui/react"
import { Cell, PieChart, Pie, Sector, Legend } from "recharts"
import { Theme } from "./Theme"
import { FontSize } from "./FontSize"
import { i18nKey, Ii18n } from "../global/i18n"
import { container } from "../DIContainer"
import { TYPES } from "../Types"

export interface PieChartProps {
    data: ChartData[]
}

export interface PieChartState {
    activeIndex: number
}

export class CMPieChart extends Component<PieChartProps, PieChartState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    state: PieChartState = {
        activeIndex: 0
    }

    componentDidMount() {
        this.setActiveIndex()
    }

    /**
     * Colors used to highlight two values of the pie chart
     */
    private COLORS = [Theme.color.secondary, Theme.color.tertiary]

    /**
     * Updates the active index value depending on which chart sector is being hovered
     *
     * @param index the index for the cell being hovered over
     */
    onPieEnter = (index: number) => {
        this.setState({
            activeIndex: index
        })
    }

    /**
     * Computes the design elements for the active shape of the pie chart
     *
     * @param props the contents of the pie chart used to compute the active shape
     * @returns an updated state for the active pie chart
     */
    renderActiveShape = (props: any) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props

        return (
            <g>
                <text x={cx} y={15} textAnchor="middle" fill={fill} fontSize={20} fontWeight="bold">
                    {`${payload.name}`}
                </text>
                <text x={cx} y={cy} dy={-5} textAnchor="middle" fill={fill} fontSize={30} fontWeight="bold">
                    {`${value}`}
                </text>
                <text x={cx} y={cy} dy={15} textAnchor="middle" fill={fill}>
                    {`(Rate ${(percent * 100).toFixed(2)}%)`}
                </text>
                <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
            </g>
        )
    }

    render() {
        const dataToDisplay = this.dataToDisplay()

        return (
            <>
                {dataToDisplay && (
                    <PieChart width={300} height={325}>
                        <Pie
                            id="pie-chart"
                            activeIndex={this.state.activeIndex}
                            activeShape={this.renderActiveShape}
                            data={this.props.data}
                            innerRadius={80}
                            outerRadius={110}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={(_, index) => this.onPieEnter(index)}
                        >
                            {this.props.data.map((entry, index) => (
                                <Cell id={entry.name} key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />
                            ))}
                        </Pie>
                        <Legend />
                    </PieChart>
                )}

                {!dataToDisplay && (
                    <Text id="no-data-text" variant={FontSize.xLarge}>
                        {this.i18n.get(i18nKey.noDataForChartDisplay)}
                    </Text>
                )}
            </>
        )
    }

    /**
     * Evaluates if a pie chart should be displayed or not.
     *
     * @returns true if there exists at least one value greater than 0
     * false otherwise indicating no values are greater than 0
     */
    private dataToDisplay = (): boolean => {
        const data = this.props.data
        return data.some((entry) => entry.value > 0)
    }

    /**
     * Evaluates which data entry should be the first active index if data
     * entries may contain empty values and updates the state's active index
     */
    private setActiveIndex = (): void => {
        const data = this.props.data
        let index = data.findIndex((entry) => entry.value)
        if (index > 0) {
            this.setState({
                activeIndex: index
            })
        }
    }
}
