import React, { Component } from 'react'
import { Stack } from '@fluentui/react'
import { MarketingHeader } from '../../ui-kit/MarketingHeader'
import { LandingHero } from '../../ui-kit/LandingHero'
import { LandingFeatures } from '../../ui-kit/LandingFeatures'
import { MarketingFooter } from '../../ui-kit/MarketingFooter'
import { LandingVideo } from '../../ui-kit/LandingVideo'
import { LandingTestimonials } from '../../ui-kit/LandingTestimonials'
import { MarketingForm } from "../../ui-kit/MarketingForm"

import './MarketingStyles.css'

export class LandingPage extends Component {
    componentDidMount() {
        document.getElementById('container')?.classList.add('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.add('hidden')
        document.getElementById('navigation-bar')?.classList.add('hidden')

        const hash = window.location.hash
        // Check if there is a hash and if an element with that id exists
        const el = hash && document.getElementById(hash.substring(1))
        if (el) {    
            el.scrollIntoView(true)
        }
    }

    componentWillUnmount() {
        document.getElementById('container')?.classList.remove('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.remove('hidden')
        document.getElementById('navigation-bar')?.classList.remove('hidden')
    }

    render() {
        return (
            <Stack id='marketing-content'>
                <MarketingHeader />
                <LandingHero />
                <LandingFeatures />
                <LandingVideo />
                <LandingTestimonials />
                <MarketingForm />
                <MarketingFooter />
            </Stack>
        )
    }
}
