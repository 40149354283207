import { APIKEY_NOTFOUND } from './APIKeyProvider'
import { Dictionary, LrsId } from './../global/TypeAliases'
import { LRSCompletionStatement } from './../models/LRSStatement';
import { ICohortService } from './CohortService';
import { IServiceConfig } from './ServiceConfig';
import { ILRSParser } from './../models/LRSParser';
import { LRSStatement } from "../models/LRSStatement";
import { injectable } from "inversify";
import axios from "axios"
import { container } from '../DIContainer';
import { TYPES } from '../Types';
import Course from '../models/Course';
import User from '../models/User';
import { IDateProvider } from './DateProvider';
import { log } from '../global/Logger';
import { IEventReportingService } from '../services/EventReportingService'
import { DocumentId, Uid } from '../global/TypeAliases';
import { LRSLaunchStatement } from '../models/LRSLaunchStatement';
import { ISurveyServiceConfig } from './SurveyServiceConfig';
import { ILearningPlanService } from './LearningPlanService';

export interface ILRSService {
    getProgressForUser(userId: Uid, clientId: DocumentId): Promise<LRSStatement[]>
    getCourseCompletions(course: Course, clientId: DocumentId, additionalFoundGroups: Set<DocumentId>): Promise<LRSCompletionStatement[]>
    getFilteredCourseCompletions(course: Course, clientId: DocumentId, additionalFoundGroups: Set<DocumentId>, startDate: Date, endDate: Date): Promise<LRSCompletionStatement[]> 
    getCourseAttempts(course: Course, clientId: DocumentId, additionalFoundGroups: Set<DocumentId>): Promise<LRSCompletionStatement[]>
    getLastLessonOnCourse(userId: Uid, clientId: DocumentId, activityId: string): Promise<LRSStatement[]>
    getOldestPassForCourse(userId: Uid, clientId: DocumentId, activityId: string): Promise<LRSStatement[]>
    getNumberOfAttemptsOnCourse(userId: Uid, clientId: DocumentId, activityId: string): Promise<LRSStatement[]>
    getProgressionOnCourseForUser(userId: Uid, clientId: DocumentId, activityId: string): Promise<LRSStatement[]>
    getTrainingLaunchLocation(clientId: DocumentId, activityId: string): Promise<LRSLaunchStatement[]>
    postTrainingLaunchLocation(userId: Uid, clientId: DocumentId, activityId: string, launchLocation: string): Promise<void>
    postCompletionOverride(userId: Uid, clientId: DocumentId, activityId: string): Promise<void>
    getSurveyCompletions(surveyLrsId: LrsId, clientId: DocumentId, groupIds: Set<DocumentId>, anonymousData: boolean): Promise<LRSCompletionStatement[]>
    getProgressionOnSurveyForUser(userId: Uid, clientId: DocumentId, activityId: string): Promise<LRSStatement[]>
    getSurveyCompletionForUser(user: User, lrsId: LrsId, clientId: DocumentId): Promise<LRSCompletionStatement | null>
    getSurveyAttempts(surveyLrsId: LrsId, clientId: DocumentId, groupIds: Set<DocumentId>, anonymousData: boolean): Promise<LRSCompletionStatement[]>
    postSurveyCompletion(userId: Uid, clientId: DocumentId, activityId: string): Promise<void>
    postSurveyAttempt(userId: Uid, clientId: DocumentId, activityId: string): Promise<void>
    postSurveyProgress(userId: Uid, clientId: DocumentId, activityId: string, progress: number): Promise<void>
    getSurveyProgress(userId: Uid, clientId: DocumentId, activityId: LrsId): Promise<number>
    saveSurveyState(userId: Uid, clientId: DocumentId, activityId: string, data: Dictionary): Promise<void>
    getSurveyState(userId: Uid, clientId: DocumentId, activityId: string): Promise<Dictionary>
}

@injectable()
export class LRSService implements ILRSService {
    private lrsParser: ILRSParser = container.get<ILRSParser>(TYPES.ILRSParser)
    private serviceConfig: IServiceConfig = container.get<IServiceConfig>(TYPES.IServiceConfig)
    private surveyServiceConfig: ISurveyServiceConfig = container.get<ISurveyServiceConfig>(TYPES.ISurveyServiceConfig)
    private cohortService: ICohortService = container.get<ICohortService>(TYPES.ICohortService)
    private dateProvider: IDateProvider = container.get<IDateProvider>(TYPES.IDateProvider)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)
    private learningPlanService: ILearningPlanService = container.get<ILearningPlanService>(TYPES.ILearningPlanService)

    getProgressForUser(userId: Uid, clientId: DocumentId): Promise<LRSStatement[]> {
        let options = this.serviceConfig.getUserCourseProgress(userId, clientId)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            let error = new Error("Unable to load the report. Error code 66.")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }

        return new Promise((resolve, reject) => {
            axios.get(options.url, options.config)
            .then(response => {
                const statements = this.lrsParser.parseStatements(response.data)
                const uniqueProgress = this.getUniqueProgressStatements(statements)
                resolve(uniqueProgress)
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                reject(error)
            })
		})
    }
    
    getCourseCompletions(course: Course, clientId: DocumentId, additionalFoundGroups: Set<DocumentId>): Promise<LRSCompletionStatement[]> {
        let cohortIds: DocumentId[]
        if (course.cohorts !== undefined) {
            cohortIds = Object.keys(course.cohorts)
            cohortIds = cohortIds.concat(Array.from(additionalFoundGroups))

            if (cohortIds === undefined || cohortIds.length === 0) {
                let error = new Error("No groups have been assigned to this content.")
                return Promise.reject(error)
            }
        }

        else {
            let error = new Error("Cohorts property on course is undefined")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }

        let options = this.serviceConfig.getCourseCompletionConfig(course.lrsId, clientId)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            let error = new Error("Unable to load the report. Error code 66.")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }

        let promises: Promise<any>[] = []
        promises.push(axios.get(options.url, options.config))

        cohortIds.forEach(cohortId => {
            promises.push(this.cohortService.learnersForCohort(cohortId))
        })
        
        return new Promise((resolve, reject) => {
            Promise.all(promises)
                .then(async results => {
                    const statements = await this.getPaginationResults(results[0], clientId)

                    /**
                     * Remove the first element of the array since
                     * the remaining elements will be users
                     */
                    results.shift()

                    const users = this.getUniqueUsers(results.flat())

                    const result = this.lrsParser.parseCompletionStatements(statements.data, users)
                    resolve(result)
                })
                .catch(error => {
                    this.eventReportingService.error(error.message, error)
                    reject(error)
                })
        })
    }

    getFilteredCourseCompletions(course: Course, clientId: DocumentId, additionalFoundGroups: Set<DocumentId>, startDate: Date, endDate: Date): Promise<LRSCompletionStatement[]> {  
        let cohortIds: DocumentId[]
        if (course.cohorts !== undefined) {
            cohortIds = Object.keys(course.cohorts)
            cohortIds = cohortIds.concat(Array.from(additionalFoundGroups))

            if (cohortIds === undefined || cohortIds.length === 0) {
                let error = new Error("No cohorts assigned to the course")
                this.eventReportingService.warn(error.message, error)
                return Promise.reject(error)
            }
        }

        else {
            let error = new Error("Cohorts property on course is undefined")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }

        let options = this.serviceConfig.getFilteredCourseCompletionConfig(course.lrsId, clientId, startDate, endDate)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            let error = new Error("Unable to load the report. Error code 66.")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }

        let promises: Promise<any>[] = []
        promises.push(axios.get(options.url, options.config))

        cohortIds.forEach(cohortId => {
            promises.push(this.cohortService.learnersForCohort(cohortId))
        })
        
        return new Promise((resolve, reject) => {
            Promise.all(promises)
                .then(async results => {
                    const statements = await this.getPaginationResults(results[0], clientId)

                    /**
                     * Remove the first element of the array since
                     * the remaining elements will be users
                     */
                    results.shift()

                    const users = this.getUniqueUsers(results.flat())

                    const result = this.lrsParser.parseFilteredCompletionStatements(statements.data, users)
                    resolve(result)
                })
                .catch(error => {
                    this.eventReportingService.error(error.message, error)
                    reject(error)
                })
        })
    }

    getCourseAttempts(course: Course, clientId: DocumentId, additionalFoundGroups: Set<DocumentId>): Promise<LRSCompletionStatement[]> {
        let cohortIds: DocumentId[]
        if (course.cohorts !== undefined) {
            cohortIds = Object.keys(course.cohorts)
            cohortIds = cohortIds.concat(Array.from(additionalFoundGroups))

            if (cohortIds === undefined || cohortIds.length === 0) {
                let error = new Error("No cohorts assigned to the course")
                this.eventReportingService.warn(error.message, error)
                return Promise.reject(error)
            }
        }

        else {
            let error = new Error("Cohorts property on course is undefined")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }

        let options = this.serviceConfig.getCourseAttemptsConfig(course.lrsId, clientId)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            return Promise.reject(new Error("Unable to load the report. Error code 66."))
        }

        let promises: Promise<any>[] = []
        promises.push(axios.get(options.url, options.config))

        cohortIds.forEach(cohortId => {
            promises.push(this.cohortService.learnersForCohort(cohortId))
        })
        
        return new Promise((resolve, reject) => {
            Promise.all(promises)
                .then(async results => {
                    const statements = await this.getPaginationResults(results[0], clientId)

                    /**
                     * Remove the first element of the array since
                     * the remaining elements will be users
                     */
                    results.shift()

                    const users = this.getUniqueUsers(results.flat())

                    const result = this.lrsParser.parseAttemptStatements(statements.data, users)
                    resolve(result)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    getLastLessonOnCourse(userId: Uid, clientId: DocumentId, activityId: string): Promise<LRSStatement[]> {
        let options = this.serviceConfig.getLastLessonOnCourseConfig(userId, clientId)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            let error = new Error("Unable to load the report. Error code 66.")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }
        
        return new Promise((resolve, reject) => {
            axios.get(options.url, options.config)
            .then(response => {
                const statements = this.lrsParser.parseStatements(response.data)
                const lessonStatement = this.getMostRecentCourseLessonStatements(statements, activityId)
                resolve(lessonStatement)
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                reject(error)
            })
		})
    }

    getOldestPassForCourse(userId: Uid, clientId: DocumentId, activityId: string): Promise<LRSStatement[]> {
        let options = this.serviceConfig.getOldestPassForCourseConfig(userId, clientId, activityId)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            let error = new Error("Unable to load the report. Error code 66.")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }
        
        return new Promise((resolve, reject) => {
            axios.get(options.url, options.config)
            .then(response => {
                const statements = this.lrsParser.parseStatements(response.data)
                const passStatement: LRSStatement[] = this.getLatestStatementFrom(statements)

                resolve(passStatement)
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                log('LRSService - getOldestPassForCourse - Failed - ' + error)
                reject(error)
            })
		})
    }

    async getNumberOfAttemptsOnCourse(userId: Uid, clientId: DocumentId, activityId: string): Promise<LRSStatement[]> {
        let timestamp = this.dateProvider.getDateAsISOString()
        await this.getOldestPassForCourse(userId, clientId, activityId)
        .then(statements => {
            if (statements.length >= 1) {
                timestamp = statements[0].createdDate.toISOString()
            }
        })

        let options = this.serviceConfig.getNumberOfAttemptsOnCourseConfig(userId, clientId, activityId, timestamp)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            let error = new Error("Unable to load the report. Error code 66.")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }
        
        return new Promise((resolve, reject) => {
            axios.get(options.url, options.config)
            .then(response => {
                const statements = this.lrsParser.parseStatements(response.data)

                resolve(statements)
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                reject(error)
            })
		})
    }

    getProgressionOnCourseForUser(userId: Uid, clientId: DocumentId, activityId: string): Promise<LRSStatement[]> {
        let options = this.serviceConfig.getProgressionOnCourseForUserConfig(userId, clientId, activityId)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            let error = new Error("Unable to load the report. Error code 66.")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }
        
        return new Promise((resolve, reject) => {
            axios.get(options.url, options.config)
            .then(response => {
                const statements = this.lrsParser.parseStatements(response.data)
                const progressStatement: LRSStatement[] = this.getLatestStatementFrom(statements)

                resolve(progressStatement)
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                reject(error)
            })
		})
    }

    getTrainingLaunchLocation(clientId: DocumentId, activityId: string): Promise<LRSLaunchStatement[]> {
        let options = this.serviceConfig.getTrainingLaunchLocationConfig(clientId, activityId)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            let error = new Error("Unable to load the report. Error code 66.")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }
        
        return new Promise((resolve, reject) => {
            axios.get(options.url, options.config)
            .then(async response => {
                const statementData = await this.getPaginationResults(response, clientId)
                const statements: LRSLaunchStatement[] = this.lrsParser.parseLaunchStatements(statementData.data)
                resolve(statements)
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                reject(error)
            })
		})
    }

    postTrainingLaunchLocation(userId: Uid, clientId: DocumentId, activityId: string, launchLocation: string): Promise<void> {
        let options = this.serviceConfig.postTrainingLaunchLocationConfig(userId, clientId, activityId, launchLocation)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            let error = new Error("Unable to load the report. Error code 66.")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }
        
        return new Promise((resolve, reject) => {
            axios.post(options.url, options.config.data, options.config)
            .then(_ => {
                resolve()
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                reject(error)
            })
		})
    }

    postCompletionOverride(userId: Uid, clientId: DocumentId, activityId: string): Promise<void> {
        let options = this.serviceConfig.postCompletionOverrideConfig(userId, clientId, activityId)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            let error = new Error("Unable to load the report. Error code 66.")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }
        
        return new Promise((resolve, reject) => {
            axios.post(options.url, options.config.data, options.config)
            .then(_ => {
                resolve()
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                reject(error)
            })
		})
    }

    getSurveyCompletions(surveyLrsId: LrsId, clientId: DocumentId, groupIds: Set<DocumentId>, anonymousData: boolean): Promise<LRSCompletionStatement[]> {
        if (groupIds.size === 0) {
            return Promise.reject(new Error("No groups are assigned to this survey"))
        }

        let options = this.surveyServiceConfig.getSurveyCompletionConfig(surveyLrsId, clientId)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            let error = new Error("Unable to load the report. Error code 66.")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }

        let promises: Promise<any>[] = []
        promises.push(axios.get(options.url, options.config))

        groupIds.forEach(id => {
            promises.push(this.cohortService.learnersForCohort(id))
        })
        
        return new Promise((resolve, reject) => {
            Promise.all(promises)
                .then(async results => {
                    const statements = await this.getPaginationResults(results[0], clientId)

                    /**
                     * Remove the first element of the array since
                     * the remaining elements will be users
                     */
                    results.shift()

                    const users = this.getUniqueUsers(results.flat())

                    const result = this.lrsParser.parseSurveyCompletionStatements(statements.data, users, anonymousData)
                    resolve(result)
                })
                .catch(error => {
                    this.eventReportingService.error(error.message, error)
                    reject(error)
                })
        })
    }

    getSurveyCompletionForUser(user: User, lrsId: LrsId, clientId: DocumentId): Promise<LRSCompletionStatement | null> {
        let options = this.surveyServiceConfig.getSurveyCompletionForUserConfig(user.uid, lrsId, clientId)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            let error = new Error("Unable to load the report. Error code 66.")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }
        
        return new Promise((resolve, reject) => {
            axios.get(options.url, options.config)
            .then(response => {
                let completionStatements = this.lrsParser.parseCompletionStatements(response.data, [user])

                if (completionStatements.length === 0) {
                    resolve(null)
                } else {
                    resolve(completionStatements[0])
                }
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                reject(error)
            })
		})
    }

    getSurveyAttempts(surveyLrsId: LrsId, clientId: DocumentId, groupIds: Set<DocumentId>, anonymousReporting: boolean): Promise<LRSCompletionStatement[]> {
        if (groupIds.size === 0) {
            return Promise.reject(new Error("No groups are assigned to this survey"))
        }

        let options = this.surveyServiceConfig.getSurveyAttemptsConfig(surveyLrsId, clientId)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            return Promise.reject(new Error("Unable to load the report. Error code 66."))
        }

        let promises: Promise<any>[] = []
        promises.push(axios.get(options.url, options.config))

        groupIds.forEach(id => {
            promises.push(this.cohortService.learnersForCohort(id))
        })
        
        return new Promise((resolve, reject) => {
            Promise.all(promises)
                .then(async results => {
                    const statements = await this.getPaginationResults(results[0], clientId)

                    /**
                     * Remove the first element of the array since
                     * the remaining elements will be users
                     */
                    results.shift()

                    const users = this.getUniqueUsers(results.flat())

                    const result = this.lrsParser.parseSurveyAttemptStatements(statements.data, users, anonymousReporting)
                    resolve(result)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    postSurveyCompletion(userId: Uid, clientId: DocumentId, activityId: string): Promise<void> {
        let options = this.surveyServiceConfig.postSurveyCompletionConfig(userId, clientId, activityId)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            let error = new Error("Unable to load the report. Error code 66.")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }
        
        return new Promise((resolve, reject) => {
            axios.post(options.url, options.config.data, options.config)
            .then(_ => {
                resolve()
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                reject(error)
            })
		})
    }

    postSurveyAttempt(userId: Uid, clientId: DocumentId, activityId: string): Promise<void> {
        let options = this.surveyServiceConfig.postSurveyAttemptConfig(userId, clientId, activityId)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            let error = new Error("Unable to load the report. Error code 66.")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }
        
        return new Promise((resolve, reject) => {
            axios.post(options.url, options.config.data, options.config)
            .then(_ => {
                resolve()
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                reject(error)
            })
		})
    }

    postSurveyProgress(userId: Uid, clientId: DocumentId, activityId: string, progress: number): Promise<void> {
        let options = this.surveyServiceConfig.postSurveyProgressConfig(userId, clientId, activityId, progress)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            let error = new Error("Unable to load the report. Error code 66.")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }
        
        return new Promise((resolve, reject) => {
            axios.post(options.url, options.config.data, options.config)
            .then(_ => {
                resolve()
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                reject(error)
            })
        })
    }

    getSurveyProgress(userId: Uid, clientId: DocumentId, activityId: LrsId): Promise<number> {
        let options = this.surveyServiceConfig.getSurveyProgressForUserConfig(userId, clientId, activityId)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            let error = new Error("Unable to load the report. Error code 66.")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }
        
        return new Promise((resolve, reject) => {
            axios.get(options.url, options.config)
                .then(response => {
                    const statements = this.lrsParser.parseStatements(response.data)
                    const progressStatement = this.getLatestStatementFrom(statements)

                    let progress = 0
                    if (progressStatement[0]) {
                        progress = progressStatement[0].progress ?? 0
                    }
                    
                    resolve(Math.trunc(progress))
                }).catch(error => {
                    this.eventReportingService.error(error.message, error)
                    reject(error)
                })
		})
    }

    getProgressionOnSurveyForUser(userId: Uid, clientId: DocumentId, activityId: LrsId): Promise<LRSStatement[]> {
        let options = this.surveyServiceConfig.getSurveyProgressForUserConfig(userId, clientId, activityId)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            let error = new Error("Unable to load the report. Error code 66.")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }
        
        return new Promise((resolve, reject) => {
            axios.get(options.url, options.config)
            .then(response => {
                const statements = this.lrsParser.parseStatements(response.data)
                const progressStatement: LRSStatement[] = this.getLatestStatementFrom(statements)
               
                resolve(progressStatement)
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                reject(error)
            })
		})
    }

    saveSurveyState(userId: Uid, clientId: DocumentId, activityId: string, data: Dictionary): Promise<void> {
        let options = this.surveyServiceConfig.postSurveyStateConfig(userId, clientId, activityId, data)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            let error = new Error("Unable to load the report. Error code 66.")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }
        
        return new Promise((resolve, reject) => {
            axios.post(options.url, options.config.data, options.config)
            .then(_ => {
                resolve()
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                reject(error)
            })
		})
    }

    getSurveyState(userId: Uid, clientId: DocumentId, activityId: string): Promise<Dictionary> {
        let options = this.surveyServiceConfig.getSurveyStateConfig(userId, clientId, activityId)

        /**
         * Code 66 means the client id doesn't exist in the LRS
         */
        if (options.config.headers && options.config.headers.authorization === APIKEY_NOTFOUND) {
            let error = new Error("Unable to load the report. Error code 66.")
            this.eventReportingService.warn(error.message, error)
            return Promise.reject(error)
        }
        
        return new Promise((resolve, reject) => {
            axios.get(options.url, options.config)
            .then(result => {
                resolve(result)
            }).catch(error => {
                /**
                 * If the learner doesn't have any state saved yet the service will return a 404 
                 * so we check for that and return an empty dictionary
                 */
                if (this.isMissingSurveyStateError(error)) {
                    resolve({ data: {} })
                    return
                }

                this.eventReportingService.error(error.message, error)
                reject(error)
            })
		})
    }

    /**
     * Private Functions
     */

    private getUniqueUsers(users: User[]): User[] {
        let uniqueIds: Set<string> = new Set()
        let uniqueUsers: User[] = []

        users.forEach(user => {
            if (uniqueIds.has(user.documentId)) {
                return
            }

            uniqueUsers.push(user)
            uniqueIds.add(user.documentId)
        })

        return uniqueUsers
    }

    private getUniqueObjects(objects: any[]): any[] {
        let uniqueIds: Set<any> = new Set()
        let uniqueObjects: any[] = []

        objects.forEach(object => {
            if (uniqueIds.has(object)) {
                return
            }

            uniqueObjects.push(object)
            uniqueIds.add(objects)
        })

        return uniqueObjects
    }

    private getUniqueProgressStatements(statements: LRSStatement[]): LRSStatement[] {
        if (statements.length < 2) {
            return statements
        }

        let highestProgressForCourse: {[key: string]: LRSStatement} = {}

        statements.forEach(statement => {
            /**
             * Let's get the highest progress for a course
             */
            const currentMaxProgressStatement = highestProgressForCourse[statement.activityId]
            
            if (currentMaxProgressStatement === undefined) {
                highestProgressForCourse[statement.activityId] = statement
            } else {
                const progress = statement.progress ?? 0
                const currentProgress = currentMaxProgressStatement.progress ?? 0
                if (progress > currentProgress) {
                    highestProgressForCourse[statement.activityId] = statement
                }
            }
        })
        
        return Object.values(highestProgressForCourse)
    }

    private getMostRecentCourseLessonStatements(statements: LRSStatement[], activityId: string): LRSStatement[] {
        let lessonsForCourse: LRSStatement[] = []

        statements.some(statement => {
            const lessonId = statement.activityId
            
            if (lessonId.includes(activityId)) {
                lessonsForCourse.push(statement)
            }
            return statement === lessonsForCourse[0]
        })
        
        return Object.values(lessonsForCourse)
    }

    /**
     * Checks to see if it's a learning locker error related to no
     * state data.
     * @param error The error returned from the service
     * @returns true if the error is a learning locker error 
     * indicating the learner doesn't have any state saved for 
     * an activity, false if it's an unknown error
     */
    private isMissingSurveyStateError = (error: any): boolean => {
        if (error.response !== undefined &&
            error.response.status !== undefined &&
            error.response.status === 404 && 
            error.response.data !== undefined &&
            error.response.data.message !== undefined &&
            error.response.data.message.toLowerCase() === "no state found") {
            return true
        }

        return false
    }

    /**
     * Grabs the first statement from an array of LRSStatements. We grab the latest progress
     * from the frist element in the array.
     * @param statements An array of LRSStatements. 
     * @returns The first LRSStatement in the array, contained in it's own array
     */
    private getLatestStatementFrom = (statements: LRSStatement[]): LRSStatement[] => {
        if (statements.length > 0) {
            return [statements[0]]
        }

        return statements
    }

    private getPaginationResults = async (statementDataFromLRS: Dictionary, clientId: string): Promise<Dictionary> => {
        let paginationUrl: string = statementDataFromLRS.data.more

        if (paginationUrl !== '') {
            do {
                const options = this.serviceConfig.getLRSStatementsByPaginationConfig(clientId, paginationUrl)
                paginationUrl = await axios.get(options.url, options.config)
                    .then((response =>{
                        statementDataFromLRS.data.statements.push(response.data.statements)
                        return response.data.more
                    }))
                
            } while (paginationUrl !== '')
            statementDataFromLRS.data.statements = statementDataFromLRS.data.statements.flat()
        }
        
        return statementDataFromLRS
    }
}