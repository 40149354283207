import { Component } from "react";
import { 
	Image,
	Stack, 
	Text,
} from '@fluentui/react';
import { AuthenticationUIStyles } from "./AuthenticationUI"
import logo from "../../images/logo-large.svg";
import { Ii18n, i18nKey } from "../../global/i18n";
import { container } from "../../DIContainer";
import { TYPES } from "../../Types";
import { NavLink } from "react-router-dom";
import { FontSize } from "../../ui-kit/FontSize";

interface ResetPasswordSentPageProps {
	email: string
}

interface ResetPasswordSentPageState {}

export class ResetPasswordSentPage extends Component<ResetPasswordSentPageProps, ResetPasswordSentPageState> {
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	componentDidMount() {
        document.getElementById('container')?.classList.add('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.add('hidden')
        document.getElementById('navigation-bar')?.classList.add('hidden')
    }

    componentWillUnmount() {
        document.getElementById('container')?.classList.remove('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.remove('hidden')
        document.getElementById('navigation-bar')?.classList.remove('hidden')
	}

    render() {
		return (
			<Stack horizontalAlign="center" tokens={{ childrenGap: 20 }}>
				<Stack styles={AuthenticationUIStyles.formStackStyles}>
					<Stack horizontalAlign="center" tokens={{ childrenGap: 50 }}>
						<Image 
							id="dts-logo" 
							src={logo} 
							alt={this.i18n.get(i18nKey.altDtsLogo)} 
							width={230} 
							height={100} 
						/>
						<Text variant={FontSize.large} styles={{root: { width: '100%'}}}>
							{this.i18n.get(i18nKey.resetPasswordSentPrefix) + this.props.email}
						</Text>
						<Text variant={FontSize.large} styles={{root: { width: '100%'}}}>
							{this.i18n.get(i18nKey.resetPasswordSentExistingAccount)}
						</Text>
						<Text variant={FontSize.large} styles={{root: { width: '100%'}}}>
							{this.i18n.get(i18nKey.resetPasswordSentNewAccount)}
						</Text>
					</Stack>
				</Stack>

				<NavLink 
					id="goToLoginLink"
					to="/login" 
				>
					{this.i18n.get(i18nKey.backToLogin)}
				</NavLink>
			</Stack>
        );
    }
}