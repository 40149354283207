import { Dialog, DialogFooter, DialogType, Dropdown, IDropdownOption, PrimaryButton } from "@fluentui/react"
import React, { Component } from "react"
import { IAuth } from "../auth/Auth"
import { container } from "../DIContainer"
import { i18nKey, Ii18n } from "../global/i18n"
import { log } from "../global/Logger"
import { DocumentId } from "../global/TypeAliases"
import Client from "../models/Client"
import { IRouter } from "../Router"
import { IAccessController } from "../services/AccessController"
import { IClientService } from "../services/ClientService"
import { IEventReportingService } from "../services/EventReportingService"
import { TYPES } from "../Types"
import { Loader } from "./Loader"

interface SelectClientDialogProps {
    target: string
    onDismiss: () => void
    onSelectClientClicked: (clientId: DocumentId) => void
    clientSelectedFromDropdown?: (clientId: DocumentId) => void
    clientId: DocumentId | null
}

interface SelectClientDialogState {
    primaryButtonDisabled: boolean
    clientListOptions: IDropdownOption[]
    selectedClientId: DocumentId | null
    errorMessage: string | null
}

export class SelectClientDialog extends Component<SelectClientDialogProps, SelectClientDialogState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private acl: IAccessController = container.get<IAccessController>(TYPES.IAccessController)
    private router: IRouter = container.get<IRouter>(TYPES.IRouter)
    private auth = container.get<IAuth>(TYPES.IAuth)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)
    private clientService: IClientService = container.get<IClientService>(TYPES.IClientService)

    state: SelectClientDialogState = {
        primaryButtonDisabled: true,
        clientListOptions: [],
        selectedClientId: null,
        errorMessage: null
    }

    // i18n the strings here
    private dialogContentProps = {
        type: DialogType.largeHeader,
        title: this.i18n.get(i18nKey.selectClientDialogTitle),
        closeButtonAriaLabel: 'Close',
    }

    private modalProps = {
        titleAriaId:"select-client",
        subtitleAriaId: "select a client to administrate as",
        isBlocking: true,
    }

    componentDidMount() {
        this.loadDialog()
    }


    render() {
        if (this.state.clientListOptions.length === 0) {
            return <Loader />
        }
        return (
            <div id="select-client-dialog-container">
            <Dialog
                hidden={false}
                onDismiss={this.onDismissClicked}
                dialogContentProps={this.dialogContentProps}
                modalProps={this.modalProps}
                minWidth={500}
            >
                { this.state.clientListOptions.length > 0 &&
                    <Dropdown
                        id="select-client-dialog-clients-dropdown"
                        placeholder={this.i18n.get(i18nKey.selectAClient)}
                        label={this.i18n.get(i18nKey.selectClientDialogDropdownMessage)}
                        options={this.state.clientListOptions}
                        onChange={this.clientChanged}
                    />
                }
                <DialogFooter>
                    <PrimaryButton id="select-client-dialog-affirmative-button" onClick={this.onSelectedClicked} disabled={this.enablePrimaryButtonIfNeeded()} text={this.i18n.get(i18nKey.save)}/>
                </DialogFooter>
            </Dialog>
        </div>  
        )
    }

    private loadDialog = () => {
        if (this.acl.isGlobalAdmin()) {
            this.getClients()
            return
        } 

        const clientId = this.acl.isClientAdmin();
        if (clientId !== null) {
            this.getManagedClient(clientId);
        } else {
            this.setState({
                errorMessage: this.i18n.get(i18nKey.failedToLoadClientList)
            })
        }
    }

    private getManagedClient = (clientId: string) => {
        this.clientService.clientForId(clientId)
            .then((managedClient) => {
                this.setClientDropdown([managedClient])
            })
			.catch(error => {
                this.eventReportingService.error(error.message, error)
                this.setState({
                    errorMessage: this.i18n.get(i18nKey.failedToLoadClientList)
                })
			})
    }

    private getClients = () => {
        this.clientService
            .clients()
			.then((clients) => {
                this.setClientDropdown(clients)
			})
			.catch(error => {
                this.eventReportingService.error(error.message, error)
                this.setState({
                    errorMessage: this.i18n.get(i18nKey.failedToLoadClientList)
                })
			})
    }

    private setClientDropdown = (clients: Client[]) => {
        const clientOptions: IDropdownOption[] = clients.map(client => {
            return {
                key: client.documentId,
                text: client.name,
                data: client,
                selected: client.documentId === this.props.clientId
            }
        })

        this.setState({
            clientListOptions: clientOptions
        })

        /**
         * If we have any selected options we should load the table automatically
         */
        const selectedOption = clientOptions.find(option => option.selected)
        if (selectedOption !== undefined) {
            const clientId = selectedOption.key as DocumentId
            this.setState({ selectedClientId: clientId })
        }
    }
    
    clientChanged = (
		event: React.FormEvent<HTMLDivElement>,
		option?: IDropdownOption,
		index?: number
	) => {
		log("Client selected: " + option?.data.name)
		const selectedClient: Client = option?.data
		this.setState({ selectedClientId: selectedClient.documentId })
    }

    onSelectedClicked = () => {
        if (this.state.selectedClientId === null) {
            return
        }

        this.auth.setClientContext(this.state.selectedClientId)
        this.props.onSelectClientClicked(this.state.selectedClientId)
        this.router.goToDashboard()
        this.router.refreshPage()
    }

    onDismissClicked = () => {
        this.props.onDismiss()
    }
    
    private enablePrimaryButtonIfNeeded = () => {
		let disable = true

		if (this.state.selectedClientId !== null) {
			disable = false
		}

        return disable
	}
}
