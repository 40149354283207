import React, { Component } from "react";
import { Stack } from "@fluentui/react";
import { LearningPlanGridStyle } from "./LearningPlanGridStyle";
import { LearningPlan } from "../../models/LearningPlan";
import { LearningPlanGridItem } from "../admin/learning_plan/LearningPlanGridItem";
import { Dictionary } from "../../global/TypeAliases";
import { container } from "../../DIContainer";
import { i18nKey, Ii18n } from "../../global/i18n";
import { TYPES } from "../../Types";

interface LearningPlanGridProps {
	learningPlans: LearningPlan[]
	completions?: Dictionary
	deadlines?: Dictionary
	onLearningPlanClick: (e: React.MouseEvent<HTMLDivElement>) => void
}

export class LearningPlanGrid extends Component<LearningPlanGridProps> {
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	render() {
		if (this.props.learningPlans.length === 0) {
			return( 
            <div id="lp-grid-no-lps-displayed">
                <Stack styles={{root: {paddingBottom: 30}}}>
                    {this.i18n.get(i18nKey.noLearningPlansAssigned)}
                </Stack>
            </div>
            );
		}

		const gridItems = this.props.learningPlans.map((learningPlan) => {
			let deadline: string | undefined;
			let isComplete: boolean | undefined; 

			if (this.props.deadlines) {
				deadline = this.props.deadlines[learningPlan.documentId]
			}

			if (this.props.completions) {
				isComplete = this.props.completions[learningPlan.documentId]
			}

            return <LearningPlanGridItem 
						learningPlan={learningPlan} 
						key={learningPlan.documentId} 
						isComplete={isComplete}
						deadline={deadline}
						onLearningPlanClick={this.props.onLearningPlanClick} 
					/>;
		});

		return (
			<div id="learning-plan-grid">
				<Stack 
					horizontal={true} 
					wrap={true}
					tokens={LearningPlanGridStyle.stackTokens}
				>
					{gridItems}
				</Stack>
			</div>
		);
    }
}
