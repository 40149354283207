import React, { Component } from "react";
import {
	Image,
	ImageFit,
	IImageStyles,
	IImageProps,
} from "@fluentui/react";

interface ProfileImageProps {
	urlString: string
	width?: number
	height?: number
}

export default class ProfileImage extends Component<ProfileImageProps> {
	private imageProps: Partial<IImageProps> = {
		imageFit: ImageFit.centerContain,
		width: this.props.width ? this.props.width : 200,
		height: this.props.height ? this.props.height : 200,
	};

	private imageStyles: IImageStyles = {
		root: {
			borderRadius: `100px`, // Should always be imageProps.width/2
			border: "1px solid #ffffff"
		},
		image: {},
	};

	render() {
		return (
			<Image
				src={this.props.urlString}
				alt="profile image"
				{...this.imageProps}
				styles={this.imageStyles}
				id="profile-image"
			/>
		);
	}
}
