import { Component } from "react"
import { Stack } from "@fluentui/react"
import { PrimaryButton } from "../../ui-kit/PrimaryButton"
import { TextField } from "../../ui-kit/TextField"
import { container } from "../../DIContainer"
import { TYPES } from "../../Types"
import { i18nKey, Ii18n } from "../../global/i18n"
import { GuestContentStyles } from "./GuestContentStyles"
import "./GuestEnterCode.css"

export interface GuestEnterCodeProps {
    onSubmitClick: (entity: string) => void
}

export interface GuestEnterCodeState {
    code: string
    primaryButtonDisabled: boolean
}

export class GuestEnterCode extends Component<GuestEnterCodeProps, GuestEnterCodeState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    state: GuestEnterCodeState = {
        code: "",
        primaryButtonDisabled: true,
    }

    render() {
        return (
            <div id="guest-code-container">
                <Stack
                    id="guest-code-stack"
                    tokens={GuestContentStyles.guestHeaderTokens}
                >
                    <TextField
                        id="code-textfield"
                        label={this.i18n.get(i18nKey.enterCode)}
                        placeholder={this.i18n.get(i18nKey.codePlaceholder)}
                        onChange={this.codeTextFieldChanged}
                    />

                    <PrimaryButton
                        id="guest-code-primary-button"
                        disabled={this.state.primaryButtonDisabled}
                        text={this.i18n.get(i18nKey.submit)}
                        onClick={() => this.props.onSubmitClick(this.state.code)}
                        width="150px"
                    />
                </Stack>
            </div>
        )
    }

    /**
     * Updates the code state when the textfield is modified
     *
     * @param event the event object from a textfield
     * @param newValue the string value from a textfield
     */
    codeTextFieldChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue) {
            this.setState({ code: newValue }, () => {
                this.enablePrimaryButtonIfNeeded()
            })
        }
    }

    /**
     * Evaluates if the primary button should be enabled or not.
     * Enabled if:
     *      - code exists
     * Otherwise disabled
     */
    enablePrimaryButtonIfNeeded = () => {
        const disable: boolean = !this.state.code
        this.setState({ primaryButtonDisabled: disable })
    }
}
