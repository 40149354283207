import { IStackStyles, ITextStyles } from '@fluentui/react'
import backgroundImage from '../images/marketing/testimonials-hero-background.png'

export class TestimonialsHeroStyles {
    static stackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    backgroundSize: 'cover',
                    padding: '25vw 9vw',
                },
            },

            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundColor: 'white',
            padding: '12vw',
        },
    }

    static headerTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '9vw',
                    paddingBottom: '10vw',
                },
            },

            color: '#FFFFFF',
            fontFamily: 'Noto Sans JP, sans-serif',
            fontWeight: '900',
            fontSize: '3.2vw',
            paddingBottom: '2vw',
        },
    }

    static subHeaderTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '5.5vw',
                    maxWidth: '100%',
                    lineHeight: '8vw',
                },
            },

            color: '#FFFFFF',
            fontFamily: 'Lato, sans-serif',
            fontSize: '1.4vw',
            opacity: '0.8',
            maxWidth: '36vw',
        },
    }
}
