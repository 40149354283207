import React, { Component } from "react";
import { container } from "../../DIContainer";
import { TYPES } from "../../Types";
import { Ii18n, i18nKey } from "../../global/i18n";
import { IAuth } from "../../auth/Auth";
import { IUserService } from "../../services/UserService";
import User from "../../models/User";
import { IUserRepository } from "../../services/UserRepository";
import { IRouter } from "../../Router";
import ProfileV2Form from "./ProfileV2Form";
import "./ProfileV2.css";
import ProfilePasswordUpdateV2 from "./ProfilePasswordUpdateV2";
import ProfileUploadPicV2 from "./ProfileUploadPicV2";
import { Loader } from "../../ui-kit/Loader";

interface ProfilePageProps {}
export interface ProfilePageState {
    editMode: boolean
    firebaseUser: firebase.User | null
    user: User | null
    loggedIn: boolean
    modalOpen: boolean
    userServiceComplete: boolean
    errorMessage: string | undefined
    successMessage: string | undefined
    firstName: string
    lastName: string
    isUpdatePWModalOpen: boolean
    profileImageUrlString: string
    isUpdatePhotoModalOpen: boolean
}

export default class ProfileV2 extends Component<ProfilePageProps, ProfilePageState> {
    private auth = container.get<IAuth>(TYPES.IAuth)
    private userService = container.get<IUserService>(TYPES.IUserService)
    private userRepository = container.get<IUserRepository>(TYPES.IUserRepository)
    private router = container.get<IRouter>(TYPES.IRouter)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    state = {
        editMode: false,
        firebaseUser: this.auth.firebaseUser(),
        loggedIn: this.auth.isLoggedIn(),
        modalOpen: false,
        userServiceComplete: false,
        user: null,
        errorMessage: undefined,
        successMessage: undefined,
        firstName: "",
        lastName: "",
        isUpdatePWModalOpen: false,
        profileImageUrlString: "",
        isUpdatePhotoModalOpen: false
    }

    componentDidMount() {
        const firebaseUser = this.auth.firebaseUser()

        if (firebaseUser === null) {
            return
        }

        this.loadUser(firebaseUser.uid)
    }

    render() {
        if (!this.state.userServiceComplete) {
			return <Loader text="Loading your profile" />
        }

        if (this.state.user === null || this.state.firebaseUser === null) {
            this.router.goToLogin()
            return null
        }

        const user: User = this.state.user!
        
        return (
            <div className="profile-page-content">
                <ProfileV2Form
                    firstName={this.state.firstName}
                    lastName={this.state.lastName}
                    profileImageURLString={this.state.firebaseUser.photoURL}
                    displayName={this.state.firstName}
                    email={user.email}
                    successMessage={this.state.successMessage}
                    errorMessage={this.state.errorMessage}
                    editMode={this.state.editMode}
                    handleFirstNameChange={this.handleFirstNameChange}
                    handleLastNameChange={this.handleLastNameChange}
                    saveButtonClicked={this.saveButtonClicked}
                    cancelButtonClicked={this.cancelButtonClicked}
                    editButtonClicked={this.editButtonClicked}
                    updatePasswordLinkClicked={this.openUpdatePasswordModal}
                    editProfilePicButtonClicked={this.openEditProfilePicButtonClicked}
                />
                <ProfileUploadPicV2
                    fbUser={this.state.firebaseUser}
                    isOpen={this.state.isUpdatePhotoModalOpen}
                    profileImageURLString={this.state.firebaseUser.photoURL}
                    onClose={this.closeEditProfilePicButtonClicked}
                    onProfileUpdate={this.onProfileUpdate}
                /> 
                <ProfilePasswordUpdateV2
                    fbUser={this.state.firebaseUser}
                    isOpen={this.state.isUpdatePWModalOpen}
                    onClose={this.closeUpdatePasswordModal}
                /> 
            </div>
        )
    }

    handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState ({ firstName: e.target.value})
    };

     handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState ({ lastName: e.target.value})
    };

    loadUser = (uid: string) => {
        this.userService
            .getUser(uid)
            .then(user => {
                this.setState(
                    { 
                        user: user, 
                        firstName: user.firstName, 
                        lastName: user.lastName,
                        userServiceComplete: true 
                    }
                )
            })
            .catch(_ => {
                this.setState({
                    errorMessage: "Profile failed to load",
                    userServiceComplete: true,
                })
            })
    }

    editButtonClicked = () => {
        this.setState({ editMode: !this.state.editMode})
    };

    cancelButtonClicked = () => {
        if (this.state.user === null) {
            this.setState({ editMode: false})
            return;
        }

        const user: User = this.state.user!
        const firstName = user.firstName
        const lastName = user.lastName
    
        this.setState({ editMode: false, firstName: firstName, lastName: lastName })
    };

    saveButtonClicked = () => {
        if (this.state.firebaseUser === null || this.state.user === null || this.state.firstName === null || this.state.lastName === null) {
            return;
        }

        this.userRepository
            .updateCurrentUser(this.state.firebaseUser, this.state.user, this.state.firstName, this.state.lastName)
            .then(() => {
                this.setState({ 
                    successMessage: this.i18n.get(i18nKey.profileUpdatedSuccessfully),
                    errorMessage: undefined
                })
                this.loadUser(this.state.firebaseUser!.uid);

            })
            .catch((_) => {
                this.setState({ 
                    successMessage: undefined,
                    errorMessage: this.i18n.get(i18nKey.profileFailedToUpdate)
                })
            })
            .finally(() => {
                this.setState({ editMode: false})
            });
    };

    openUpdatePasswordModal = () => {
        this.setState({ 
            isUpdatePWModalOpen: true
        })
    };

    closeUpdatePasswordModal = () => {
        this.setState({ 
            isUpdatePWModalOpen: false
        })
    };

    openEditProfilePicButtonClicked = () => {
        this.setState({
            isUpdatePhotoModalOpen: true
        })
    };

    closeEditProfilePicButtonClicked = () => {
        this.setState({
            isUpdatePhotoModalOpen: false
        })
    };

    onProfileUpdate = (fbUser: firebase.User) => {
        this.setState({
            firebaseUser: fbUser,
            isUpdatePWModalOpen: false,
            isUpdatePhotoModalOpen: false
        })
        if (fbUser !== null && fbUser.photoURL !== null) {
            this.setState({
                profileImageUrlString: fbUser.photoURL
            })
        }
    };
}