import { IStackTokens, IStackStyles, IImageStyles, ITextStyles } from "@fluentui/react"

export class GuestGridItemStyle {
    static stackTokens: IStackTokens = {
        padding: "20px"
    }

    static completedDiv = {
        paddingLeft: "10px"
    }

    static containerStyle: IStackStyles = {
        root: {
            width: "275px",
            height: "325px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px #eeeeee",
            cursor: "pointer",
            backgroundColor: "#ffffff",
            marginBottom: "5vw"
        }
    }

    static headerStyle: IStackStyles = {
        root: {
            height: "60%"
        }
    }

    static imageStyle: IImageStyles = {
        image: {},
        root: {
            height: "100%",
            width: "100%",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px"
        }
    }

    static lockIconImageStyle: Partial<IImageStyles> = {
        image: {
            position: "absolute",
            top: 87,
            left: 62,
            width: 150
        }
    }

    static bodyStyle: IStackStyles = {
        root: {
            height: "20%"
        }
    }

    static footerStyle: IStackStyles = {
        root: {
            height: "20%",
            justifyContent: "space-between"
        }
    }

    static startCourseText: ITextStyles = {
        root: {
            color: "#24c4f0"
        }
    }

    static lockIcon: IImageStyles = {
        image: {},
        root: {
            paddingLeft: "16em",
            height: "23em"
        }
    }

    static deadlineStyle: ITextStyles = {
        root: {
            color: "red"
        }
    }
}
