import { 
    IStackStyles, 
    IStackTokens,
} from '@fluentui/react';
import { Theme } from '../../ui-kit/Theme'

export class AuthenticationUIStyles {
    static formStackStyles: IStackStyles = {
        root: {
            width: 450,
            boxShadow: `0px 0px 10px ${Theme.shadow.light}`,
            padding: "25px",
            borderRadius: '10px',
            backgroundColor: '#ffffff'
        }
    };

    static emailVerifyStackStyles: IStackStyles = {
        root: {
            width: '70%',
            boxShadow: `0px 0px 10px ${Theme.shadow.light}`,
            padding: "50px 100px",
            borderRadius: '10px',
            textAlign: 'center'
        }
    };

    static emailVerifyButtonStackStyles: IStackStyles = {
        root: {
            width: '200px',
            margin: 'auto'
        }
    };

    static containerStyle: IStackStyles = {
        root: {
            margin: "0 auto",
        }
    };

    static logoStyle: IStackStyles = {
        root: {
            marginBottom: '50px'
        }
    }

    static emailVerificationStyle: IStackStyles = {
        root: {
            padding: '30px 0px',
            marginBottom: '30px',
        }
    };
}

export class AuthenticationUITokens {
	static verticalGapStackTokens: IStackTokens = {
        childrenGap: 30,
    };

	static formFieldGapStackTokens: IStackTokens = {
        childrenGap: 25,
    };
}
