import React, { Component } from 'react'
import { PrimaryButton as FluentUIPrimaryButton, IButtonProps, IButtonStyles } from '@fluentui/react'
import { Dictionary } from '../global/TypeAliases'

interface MarketingButtonProps extends IButtonProps {
    mobileWidth?: string | number | undefined
}

export class MarketingButton extends Component<MarketingButtonProps> {
    render() {
        let style: Dictionary = {
            root: {
                fontFamily: 'Rubik, sans-serif',
                border: 'none',
                backgroundColor: '#FC376B',
                height: '3vw',
                width: `${this.props.width ? this.props.width : ''}`,
                borderRadius: '0.5vw',

                selectors: {
                    '@media(max-width:600px)': {
                        height: '12vw',
                        width: `${this.props.mobileWidth ? this.props.mobileWidth : ''}`,
                        borderRadius: '2vw',
                    },
                },
            },

            label: {
                fontWeight: 500,
                fontSize: '1vw',

                selectors: {
                    '@media(max-width:600px)': {
                        fontSize: '4vw',
                    },
                },
            },

            rootHovered: {
                backgroundColor: '#FC376B',
                border: 'none',
            },

            rootPressed: {
                backgroundColor: '#FC376B',
                border: 'none',
            },
        }

        const buttonStyle: Partial<IButtonStyles> = style

        return <FluentUIPrimaryButton {...this.props} styles={buttonStyle} />
    }
}
