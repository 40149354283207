import React from "react";
interface DocumentDeletedOrNotFoundProps {
    message: string
}

export const DocumentDeletedOrNotFound: React.FC<DocumentDeletedOrNotFoundProps> = ({message}) => {
    return (
        <div id="document-deleted-component-container">
            <h2 id="document-deleted-message" className="mt-2 mb-4">{message}</h2>
        </div>    
    )
}