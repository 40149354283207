import React, { Component } from "react";
import Iframe from "react-iframe"
import { Stack } from "@fluentui/react";
import { PrivacyRequestForm } from "./privacy/PrivacyRequestForm";
import "./PrivacyPolicyPage.css";

interface PrivacyPolicyPageProps {}
interface PrivacyPolicyPageState {}

export default class PrivacyPolicyPage extends Component<PrivacyPolicyPageProps, PrivacyPolicyPageState> {
    componentDidMount() {
        document.getElementById('content')?.classList.add('full-height', 'no-padding')
    }

    /* istanbul ignore next */
    componentWillUnmount() {
        document.getElementById('content')?.classList.remove('full-height', 'no-padding')
    }

    render() {
        return (
            <Stack id="privacy-policy-container">
                <Iframe 
                    url={this.privacyPolicyUrl()} 
                    id="privacy-policy-iframe" 
                    scrolling="no"
                    frameBorder={0} 
                />
                <PrivacyRequestForm/>
            </Stack>  
        )
    }

    private privacyPolicyUrl = () => {
        if (process.env.REACT_APP_ENV_PRIVACY_POLICY_URL) {
            return process.env.REACT_APP_ENV_PRIVACY_POLICY_URL
        } else {
            return ""
        }
    }
}
