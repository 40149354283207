import { Component } from "react";
import { Pivot, PivotItem } from "@fluentui/react";

import EditCohortDetails from "./EditCohortDetails";
import AddLearnerToCohort from "./AddLearnerToCohort";

import "./EditCohort.css";
import Cohort from "../../../models/Cohort";
import RemoveLearnerFromCohort from "./RemoveLearnerFromCohort";

import { CohortContentsPage } from "./CohortContentsPage"
import PageTitle from "../../../ui-kit/PageTitle";
import { i18nKey, Ii18n } from "../../../global/i18n";
import { container } from "../../../DIContainer";
import { TYPES } from "../../../Types";

interface EditCohortProps {
	documentId: string | undefined
	cohort: Cohort
}

export default class EditCohort extends Component<EditCohortProps> {
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
	
	render() {
		if (this.props.documentId === undefined) {
			return null
		}

		return (
			<>
				<PageTitle title={this.i18n.get(i18nKey.editGroup)} />
				<div id="modal-container-cohort">
					<Pivot aria-label="edit cohort admin panels">
						<PivotItem headerText={this.i18n.get(i18nKey.editGroupDetails)}>
							<EditCohortDetails
								title={this.i18n.get(i18nKey.editGroupDetailsPanelTitle)}
								index={0}
								documentId={this.props.documentId}
								cohort={this.props.cohort}
							/>
						</PivotItem>
						{ !this.props.cohort.isDefault && 
							<PivotItem headerText={this.i18n.get(i18nKey.addLearners)}>
								<AddLearnerToCohort
									title={this.i18n.get(i18nKey.editGroupAddLearnerPanelTitle)}
									index={1}
									documentId={this.props.documentId}
								/>
							</PivotItem>
						}
						{ !this.props.cohort.isDefault && 
							<PivotItem headerText={this.i18n.get(i18nKey.removeLearners)}>
								<RemoveLearnerFromCohort
									title={this.i18n.get(i18nKey.editGroupRemoveLearnerPanelTitle)}
									index={2}
									documentId={this.props.documentId}
								/>
							</PivotItem>
						}

						<PivotItem headerText={this.i18n.get(i18nKey.content)}>
							<CohortContentsPage
								title={this.i18n.get(i18nKey.editGroupContentsPanelTitle)}
								index={3}
								cohort={this.props.cohort}
							/>
						</PivotItem>
					</Pivot>
				</div>
			</>
		);
	}
}
