import { Component } from "react"
import { Text, Stack } from "@fluentui/react"
import { FontSize } from "./FontSize"

interface GuestDashboardErrorProps {
    message: string
}

export class GuestDashboardError extends Component<GuestDashboardErrorProps> {
    private stackTokens = {
        childrenGap: 15
    }

    render() {
        return (
            <div id="guest-dashboard-error-container">
                <Stack tokens={this.stackTokens}>
                    <Text id="error-message" variant={FontSize.xLarge}>
                        {this.props.message}
                    </Text>
                </Stack>
            </div>
        )
    }
}
