import { ISurveyCompositionService } from './SurveyCompositionService';
import { injectable } from "inversify";
import { container } from "../DIContainer";
import { DocumentId, Dictionary } from "../global/TypeAliases";
import Course from "../models/Course";
import { TYPES } from "../Types";
import { IUserService } from "./UserService";
import { IFeatureFlagService } from "./FeatureFlagService";
import { ICourseService } from "./CourseService";
import { ILearningPlanService } from "./LearningPlanService";
import { IAuth } from "../auth/Auth";
import { LearningPlan } from "../models/LearningPlan";
import { ISurveyService } from "./SurveyService";
import { Survey } from "../models/Survey";
import { IDeadlineService } from "../services/DeadlineService";
import { ILearningPlanCompositionService } from './LearningPlanCompositionService';
import User from '../models/User';

export interface IDashboardCompositionService {
    getData(): Promise<IDashboardData>
}

export interface IDashboardData {
    courses: Course[]
    learningPlans: LearningPlan[]
    surveys: Survey[]
    surveyCompletionData: Dictionary
    associatedClientsOfUser: DocumentId[]
    deadlines: Dictionary
    learningPlanCompletions: Dictionary
    user: User
}

@injectable()
export class DashboardCompositionService implements IDashboardCompositionService {
    private userService: IUserService = container.get<IUserService>(TYPES.IUserService)
    private auth = container.get<IAuth>(TYPES.IAuth)
    private learningPlanService: ILearningPlanService = container.get<ILearningPlanService>(TYPES.ILearningPlanService)
    private courseService: ICourseService = container.get<ICourseService>(TYPES.ICourseService)
    private surveyService: ISurveyService = container.get<ISurveyService>(TYPES.ISurveyService)
    private featureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService)
    private surveyCompositionService: ISurveyCompositionService = container.get<ISurveyCompositionService>(TYPES.ISurveyCompositionService)
    private deadlineService: IDeadlineService = container.get<IDeadlineService>(TYPES.IDeadlineService)
	private learningPlanCompositionService: ILearningPlanCompositionService = container.get<ILearningPlanCompositionService>(TYPES.ILearningPlanCompositionService)

    getData(): Promise<IDashboardData> {
        let promiseArray: Promise<any>[] = []
        const clientId: string | null = this.auth.getClientContext()

        promiseArray.push(this.courseService.coursesForCurrentUser())
        promiseArray.push(this.learningPlanService.learningPlansForCurrentUser())

        if (this.featureFlagService.surveysFeature()) {
            promiseArray.push(this.surveyService.surveysForCurrentUser())
        } else {
            promiseArray.push(Promise.resolve([]))
        }

        if (this.auth.user?.cohorts) {
            promiseArray.push(this.userService.getClientsForUser(this.auth.user?.cohorts))
        } else {
            promiseArray.push(Promise.resolve([]))
        }

        if (this.featureFlagService.surveysFeature()) {
            if (clientId !== null && this.auth.user !== undefined) {
                promiseArray.push(this.surveyCompositionService.getSurveyCompletionsForUser(this.auth.user, clientId))
            } else {
                promiseArray.push(Promise.resolve({}))
            }
        } else {
            promiseArray.push(Promise.resolve({}))
        }
        
        if (this.featureFlagService.deadline()) {
            promiseArray.push(this.deadlineService.deadlines())
            promiseArray.push(this.learningPlanCompositionService.getLearningPlanCompletionData())
        } else {
            promiseArray.push(Promise.resolve({}))
            promiseArray.push(Promise.resolve({}))
        }

        if (this.auth.user) {
            promiseArray.push(Promise.resolve(this.auth.user))
        } else {
            promiseArray.push(Promise.resolve())
        }

        return new Promise((resolve, reject) => {
            Promise.all(promiseArray)
                .then(result => {
                    resolve({ 
                        courses: result[0], 
                        learningPlans: result[1], 
                        surveys: result[2], 
                        associatedClientsOfUser: result[3], 
                        surveyCompletionData: result[4],
                        deadlines: result[5],
                        learningPlanCompletions: result[6],
                        user: result[7]
                    })
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}