import React from "react";
import { NavLink } from "react-router-dom";
import { ENVIRONMENT } from "../services/Firebase";
import { Theme } from "./Theme";
import { PathNames } from "../PathNames";
import { container } from "../DIContainer";
import { i18nKey, Ii18n } from "../global/i18n";
import { TYPES } from "../Types";

interface FooterProps {
    ssoFooter?: boolean
}

export const FooterV2: React.FC<FooterProps> = ({ssoFooter}) => {
    const i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    const navLinkStyles: React.CSSProperties = {
        color: ssoFooter ? Theme.color.primary : Theme.color.secondary
    }

    let env = process.env.REACT_APP_ENV === ENVIRONMENT.prod ? "" : " - " + process.env.REACT_APP_ENV

    return(
        <div className="text-center d-grid gap-3">
            <div 
                id="copyright-footer-text"
                className="fs-6" 
                style={{
                    color: ssoFooter ? Theme.color.primary : Theme.color.secondary,
                    paddingBottom: 10
                }}
            >
                {i18n.get(i18nKey.digitalTrainingSolutions)}{env}
            </div>
            <NavLink to={PathNames.PrivacyPolicy} style={navLinkStyles} id="privacyLink">
                <div 
                    id="privacy-policy-footer-text"
                    className="fs-6" 
                    style={{
                        color: ssoFooter ? Theme.color.primary : Theme.color.secondary,
                        paddingBottom: 10
                    }}
                >
                    Privacy Policy
                </div>
            </NavLink>
        </div>
    )
}