import { Component } from "react"
import {
	Modal,
	MessageBarType,
	MessageBar,
	Text,
	Spinner
} from "@fluentui/react"
import { container } from "../../../DIContainer"
import { TabPanelProps } from "../TabPanelProps"
import User, { UserStatus } from "../../../models/User"
import DTSTable from "../../../ui-kit/DTSTable"
import IDataTableFactory from "../../../factories/DataTableFactory"
import DTSTableData from "../../../models/DTSTableData"
import { Loader } from "../../../ui-kit/Loader"
import NewUserModal from "./NewUserModal"
import { TYPES } from "../../../Types"
import EditUser from "./EditUser"
import ErrorPage from "../../ErrorPage"
import { SecondaryButton } from '../../../ui-kit/SecondaryButton'
import { Stack } from "@fluentui/react"
import { IRouter } from "../../../Router"
import { i18nKey, Ii18n } from "../../../global/i18n"
import { DocumentId } from "../../../global/TypeAliases"
import { FontSize } from "../../../ui-kit/FontSize"
import { UsersAdminStyles } from "./UsersAdminStyles"
import { ConfirmationDialog } from "../../../ui-kit/ConfirmationDialog"
import { IUserUploadCompositionService } from "../../../services/UserUploadCompositionService"
import { IEmailRegistrationCompositionService } from "../../../services/EmailRegistrationCompositionService"
import { IFeatureFlagService } from "../../../services/FeatureFlagService";
import { ReportCSVExporter } from "../../../services/ReportCSVExporter"
import { IUsersAdminCompositionService } from "../../../services/UsersAdminCompositionService"
import Client from "../../../models/Client"

enum ModalType {
	NEW = 0,
	EDIT,
}

interface UsersAdminProps extends TabPanelProps {
	clientId: DocumentId
}

interface UsersAdminState {
	users: User[]
	client: Client | undefined
	tableData: DTSTableData | undefined
	isModalOpen: boolean
	successMessage: string | undefined
	selectedUserId: string | undefined
	modalType: ModalType
	showErrorPage: boolean
	showConfirmationDialog: boolean
	showEmailSpinner: boolean
	errorMessage: string | undefined
}

export default class UsersAdmin extends Component<UsersAdminProps, UsersAdminState> {
	private loadingCompositionService: IUsersAdminCompositionService = container.get<IUsersAdminCompositionService>(TYPES.IUsersAdminCompositionService)
	private compositionService: IUserUploadCompositionService = container.get<IUserUploadCompositionService>(TYPES.IUserUploadCompositionService)
	private emailRegistrationCompositionSerivce: IEmailRegistrationCompositionService = container.get<IEmailRegistrationCompositionService>(TYPES.IEmailRegistrationCompositionService)
	private featureFlagService: IFeatureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService)
	private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
	private router: IRouter = container.get<IRouter>(TYPES.IRouter)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	state: UsersAdminState = {
		users: [],
		client: undefined,
		tableData: undefined,
		isModalOpen: false,
		successMessage: undefined,
		selectedUserId: undefined,
		modalType: ModalType.NEW,
		showErrorPage: false,
		showConfirmationDialog: false,
		showEmailSpinner: false,
		errorMessage: undefined
	}

	componentDidMount() {
		this.load()
	}

	render() {
		if (this.state.showErrorPage) {
			return <ErrorPage retryHandler={this.load} />
		}

		if (this.state.tableData === undefined) {
			return <Loader />
		}

		const tableData = this.state.tableData
		const shouldShowRegistrationReminderButton = this.featureFlagService.resendRegistrationReminder()

		return (
			<div
				className="admin-tab-panel"
				id={`admin-tab-panel-${this.props.index}`}
				aria-labelledby={`admin-tab-${this.props.index}`}
			>
				{this.state.successMessage !== undefined && (
					<MessageBar
						id="users-admin-success-banner"
						messageBarType={MessageBarType.success}
						dismissButtonAriaLabel="Close"
						onDismiss={this.dismissBanner}
					>
						{this.state.successMessage}
					</MessageBar>
				)}
				{this.state.errorMessage !== undefined && (
					<MessageBar
						id="users-admin-error-banner"
						messageBarType={MessageBarType.error}
						dismissButtonAriaLabel="Close"
						onDismiss={this.dismissBanner}
					>
						{this.state.errorMessage}
					</MessageBar>
				)}

				<Stack 
					className="admin-command-bar" 
					horizontal={true} 
					tokens={{ childrenGap: 20}}
				>
					{ this.addLearnerButtonsElements() }

					<ReportCSVExporter tableData={this.state.tableData!} fileName={"all_users"}/>
					<Text id="learner-count"
						variant={ FontSize.medium } 
						styles={ UsersAdminStyles.learnerCountStyle }>
						{ this.learnerCountText() }
					</Text>
					{ this.state.showEmailSpinner &&
						<Spinner
							id="send-registration-email-spinner"
							label="Sending registration emails..."
							ariaLive="assertive"
							labelPosition="right"
						/>
					}
				</Stack>
				{ !this.learnerSeatsAvailable() &&
					<MessageBar
						id="learner-seat-error-message"
						messageBarType={MessageBarType.error}
						styles={UsersAdminStyles.learnerErrorStyle}
					>
						{this.i18n.get(i18nKey.noMoreLearners)}
					</MessageBar>
				}
				<DTSTable
					data={tableData!}
					aria-label="user table"
					onRowClicked={this.onRowClicked}
					pointerOnHover={true}
					highlightOnHover={true}
					pagination={true}
					filter={true}
					filterPlaceholder={this.i18n.get(i18nKey.searchForLearner)}
					resendRegistrationButton={shouldShowRegistrationReminderButton}
					onResendRegistrationButtonClicked={this.onResendRegistrationButtonClicked}
				/>
				{ this.state.showConfirmationDialog &&
					<>
						<ConfirmationDialog
							title={this.i18n.get(i18nKey.resendRegEmailsDialogTitle)}
							dialogMessage={this.i18n.get(i18nKey.resendRegEmailsDialogMessage)}
							affirmativeButtonText={this.i18n.get(i18nKey.resendRegEmailsDialogAffirmativeAction)}
							dismissButtonText={this.i18n.get(i18nKey.dismiss)}
							affirmativeActionHandler={this.resendRegEmailsConfirmationAction}
							dismissActionHandler={this.dialogDismissed}
							isDestructive={false}
						/>
					</>
                }
				<Modal
					titleAriaId="add/edit user modal"
					isOpen={this.state.isModalOpen}
					onDismiss={this.modalDismissed}
					isBlocking={false}
				>
					{this.createModalElement()}
				</Modal>
			</div>
		)
	}

	private load = () => {
		this.setState({ showErrorPage: false })

		this.loadingCompositionService
			.getData(this.props.clientId)
			.then((data) => {
				let tableData = this.dataTableFactory.createLearnersTableData(data.users, data.cohorts)
				this.setState({ users: data.users, tableData: tableData, client: data.client })
			}).catch(_ => {
				this.setState({showErrorPage: true})
			})
	}

	userDeleted = (message: string) => {
		this.reloadData(message)
	}

	createModalElement = () => {
		if (!this.state.isModalOpen) {
			return null
		}

		switch (this.state.modalType) {
			case ModalType.NEW:
				return <NewUserModal userAdded={this.reloadData} clientId={this.props.clientId}/>
			case ModalType.EDIT:
				const user = this.state.users.find(
					(user: User) => user.documentId === this.state.selectedUserId
				)
				if (user !== undefined && this.state.selectedUserId !== undefined) {
					return (
						<EditUser
							documentId={this.state.selectedUserId!}
							user={user}
							userUpdatedHandler={this.load}
							userDeletedHandler={this.userDeleted}
						/>
					)
				}

				return null
			default:
				return null
		}
	}

	onRowClicked = (row: { [key: string]: string }) => {
		this.setState({
			selectedUserId: row.id,
			isModalOpen: true,
			modalType: ModalType.EDIT,
		})
	}

	addUserClicked = () => {
		this.setState({ isModalOpen: true, modalType: ModalType.NEW })
	}

	uploadUserClicked = () => {
		this.router.goToUserUpload()
	}

	reloadData = (successMessage: string) => {
		this.modalDismissed()
		this.load()
		this.setState({ successMessage: successMessage })
	}

	modalDismissed = () => {
		this.setState({ isModalOpen: false })
	}

	dismissBanner = () => {
		this.setState({ errorMessage: undefined, successMessage: undefined });
	}

	onResendRegistrationButtonClicked = () => {
		this.setState({ showConfirmationDialog: true })
	}

	dialogDismissed = () => {
        this.setState({ showConfirmationDialog: false })
    }

	resendRegEmailsConfirmationAction = () => {
		this.setState({ 
			errorMessage: undefined,
			successMessage: undefined,
			showEmailSpinner: true 
		})
		
		this.emailRegistrationCompositionSerivce.getUsersByStatusForClient(this.props.clientId, UserStatus.Pending)
		.then((users) => {
			return this.compositionService.sendRegistrationEmailsForExistingUsers(users, this.props.clientId)
		})
		.then(_ => {
			this.setState({ 
				successMessage: this.i18n.get(i18nKey.registrationReminderEmailsSuccess),
				showEmailSpinner: false
			})	
		}).catch(_ => {
			this.setState({
				errorMessage: this.i18n.get(i18nKey.failedToSendRegistrationReminerEmails),
				showEmailSpinner: false
			})
		})
	}

	private learnerCountText = () => {
		let text = `${ this.i18n.get(i18nKey.activeLearners) }: ${ this.state.users.length }`

		if (this.state.client?.licenseSeatCount) {
			text += ` out of ${ this.state.client?.licenseSeatCount }`
		}

		return text
	}

	/**
	 * When learner limit is reached the buttons are disabled, otherwise
	 * they are enabled.
	 * @returns Buttons that will be enabled or disabled 
	 */
	private addLearnerButtonsElements = (): React.ReactElement => {		
		let learnerSeatsAvailable = this.learnerSeatsAvailable()

		return  <>
			<SecondaryButton
				id="add-new-user"
				iconProps={{ iconName: "AddFriend" }}
				text={this.i18n.get(i18nKey.addNewUser)}
				onClick={this.addUserClicked}
				disabled={!learnerSeatsAvailable}
			/>
			<SecondaryButton
				id="upload-users"
				iconProps={{ iconName: "AddGroup" }}
				text={this.i18n.get(i18nKey.uploadUsersButtonText)}
				onClick={this.uploadUserClicked}
				disabled={!learnerSeatsAvailable}
			/>
		</>
	}

	/**
	 * Calculates if more learners can be added based on a clients available seat count
	 * @returns If learner seats are available 
	 */
	private learnerSeatsAvailable = (): boolean => {
		const availableSeats = this.state.client?.licenseSeatCount
		
		let learnerSeatsAvailable: boolean
		if (availableSeats === 0) {
			learnerSeatsAvailable = false
		} else if (availableSeats && availableSeats > 0) {
			learnerSeatsAvailable = availableSeats - this.state.users.length > 0
		} else {
			learnerSeatsAvailable = true
		}

		return learnerSeatsAvailable
	}
}
