import { Dictionary, BearerToken, DocumentId } from './../global/TypeAliases';
import { IAPIKeyProvider } from './APIKeyProvider';
import { injectable } from "inversify"
import { AxiosRequestConfig } from "axios"
import { container } from '../DIContainer';
import { TYPES } from '../Types';
import { IBase64Encoder } from '../utils/Base64Encoder';
import { IRegistrationTokenService } from './RegistrationTokenService';
import { IDateProvider } from './DateProvider';
import { UUID } from '../utils/UUIDGenerator';
import { HTTPMethods } from '../HTTPMethods';
import { IEmailRegistrationCompositionService } from './EmailRegistrationCompositionService';
import { IFeatureFlagService } from './FeatureFlagService';

export enum ServiceConfigType {
    getStatement = 0,
    getCompletions
}

export enum Constants {
    account = "account",
    xApiVersionKey = "X-Experience-API-Version",
    xApiVersion = "1.0.3",
    xAPIVerbAttempted = "http://adlnet.gov/expapi/verbs/attempted",
    xAPIVerbExperienced = "http://adlnet.gov/expapi/verbs/experienced",
    xAPIVerbPassed = "http://adlnet.gov/expapi/verbs/passed",
    xAPIVerbProgressed = "http://adlnet.gov/expapi/verbs/progressed",
    xAPIVerbLaunched = "http://adlnet.gov/expapi/verbs/launched",
    xAPIActivityCourse = "http://adlnet.gov/expapi/activities/course",
    xAPIActivitySurvey = "http://adlnet.gov/expapi/activities/survey",
    xAPIExtensionProgress = "http://w3id.org/xapi/cmi5/result/extensions/progress",
    homePage = "homePage",
    name = "name",
    contentType = 'Content-Type',
    mimeTypeMultipartFormData = 'multipart/form-data',
    authorization = "authorization",
    statementPathUrl = "/data/xAPI/statements",
    statePathUrl = "/data/xAPI/activities/state",

    //  POST Statement Constants
    authority = "authority",
    objectType = "objectType",
    agent = "Agent",
    authorityName = "Digital Training Solutions",
    mailboxName = "mbox",
    mailboxAddress = "mailto:hello@cm-dts.com",
    actor = "actor",
    verb = "verb",
    id = "id",
    launched = "launched",
    display = "display",
    enUS = "en-US",
    timestamp = "timestamp",
    version = "version",
    statementVersionNumber = "1.0.0",
    object = "object",
    activity = "Activity",
    definition = "definition",
    typeConstant = "type",
    result = "result",
    response = "response",
    contentLength = "Content-Length",
    accept = "Accept",
    acceptTypes = "*/*",
    success = "success",
    durationOverride = "PT0M0.000S",
    duration = "duration",
    completion = "completion",
    override = "override",
    passed = "passed",
    attempted = "attempted",
    progressed = "progressed",
    extensions = "extensions",
    temporaryPassword = "temporaryPassword",
    link = "link",
    email = "email",

    //  Clever SSO Constants
    applicationJson = "application/json",
    redirectUriKey = "redirect_uri",
    codeKey = "code",
    authorizationCode = "authorization_code",
    basic = "Basic",
    bearer = "Bearer",
    cleverDevClientID = "002048cdfbdfb2e74822",
    cleverDevClientSecret = "97dc032a90e52b440882d4a0c9f7955fe0b7d736",
    cleverProdClientID = "96b9efed4ae943a8ed0b",
    cleverProdClientSecret = "f89874be5e7fcfce567888cd568661e8a2e93040",
    cleverTokenPath = "/clever/token",
    cleverMePath = "/clever/me",
    cleverSchoolAdminPath = "/clever/school_admins",
    cleverDistrictAdminPath = "/clever/district_admins",
    cleverTeacherPath = "/clever/teachers",
    marketingDemoMailUrl = "https://dtsemailservicedev.azurewebsites.net/email/demoRequest"
}

export interface LRSServiceConfig {
    url: string,
    config: AxiosRequestConfig
}

export interface ServiceRequestConfig {
    url: string,
    config: RequestConfig,
    body?: Dictionary
}

export interface UserUploadData {
    recipients: Dictionary,
    uploadTokenPromises: Promise<void>[]
}

export interface RequestConfig extends AxiosRequestConfig {}

export interface IServiceConfig {
    getUserCourseProgress(userId: string, clientId: string): LRSServiceConfig
    getCourseUploadConfig(zipFile: File, imageFile: File, clientId: string): LRSServiceConfig
    getCourseUpdateConfig(zipFile: File, imageFile: File, couresFolder: string, clientId: string): LRSServiceConfig
    getCourseCompletionConfig(courseId: string, clientId: string): LRSServiceConfig
    getFilteredCourseCompletionConfig(courseId: string, clientId: string, startDate: Date, endDate: Date): LRSServiceConfig
    getCourseAttemptsConfig(courseId: string, clientId: string): LRSServiceConfig
    getLastLessonOnCourseConfig(userId: string, clientId: string): LRSServiceConfig
    getOldestPassForCourseConfig(userId: string, clientId: string, activityId: string): LRSServiceConfig
    getNewAccountEmailsConfig(userData: Dictionary[], clientId: string): Promise<LRSServiceConfig>
    getNewAccountEmailsConfigV2(userData: Dictionary[]): LRSServiceConfig
    getSetOnboardingRemindersConfig (userData: Dictionary[], clientId: DocumentId): Promise<LRSServiceConfig>
    getOnboardingReminderDeleteConfig(emailAddress: string): LRSServiceConfig
    getNumberOfAttemptsOnCourseConfig(userId: string, clientId: string, activityId: string, timestamp?: string): LRSServiceConfig
    getProgressionOnCourseForUserConfig(userId: string, clientId: string, activityId: string): LRSServiceConfig
    getMarketingDemoEmailConfig(formData: Dictionary): LRSServiceConfig
    getPrivacyRequestEmailConfig(formData: Dictionary): LRSServiceConfig
    getTrainingLaunchLocationConfig(clientId: DocumentId, activityId: string): LRSServiceConfig
    postTrainingLaunchLocationConfig(userId: string, clientId: string, activityId: string, launchLocation: string): LRSServiceConfig
    postCompletionOverrideConfig(userId: string, clientId: string, activityId: string): LRSServiceConfig
    getCourseDeleteConfig(courseFolder: string): LRSServiceConfig
    getPasswordResetConfig(emailAddress: string, emailLink: string): LRSServiceConfig

    /**
     * Configuration to obtains any additional statements that exceeds the rate limit of the initial query.
     * @param clientId : string representing the client's assigned ID
     * @param callbackUrl : string representing the URL to fetch additional statements from the initial query
     */
     getLRSStatementsByPaginationConfig(clientId: string, callbackUrl: string): LRSServiceConfig

    /**
     * Clever Configurations
     */

    /**
     * Configuration to obtain a bearer token from Clever
     * @param code The code from the redirect URI
     * @param redirectUri The redirect URI
     */
    getCleverBearerTokenConfig(code: string, redirectUri: string): ServiceRequestConfig

    /**
     * Configuration to obtain user info from a bearer token
     * @param token A bearer token
     */
    getCleverMeConfig(token: BearerToken): ServiceRequestConfig

    /**
     * Configuration to obtain data about a school admin
     * @param userId The user id of the school admin
     * @param token A bearer token
     */
    getCleverSchoolAdminConfig(userId: string, token: BearerToken): ServiceRequestConfig

    /**
     * Configuration to obtain data about a district admin
     * @param userId The user id of the district admin
     * @param token A bearer token
     */
    getCleverDistrictAdminConfig(userId: string, token: BearerToken): ServiceRequestConfig

    /**
     * Configuration to obtain data about a teacher
     * @param userId The user id of the teacher
     * @param token A bearer token
     */
    getCleverTeacherConfig(userId: string, token: BearerToken): ServiceRequestConfig
}

@injectable()
export class ServiceConfig implements IServiceConfig {
    private apiKeyProvider: IAPIKeyProvider = container.get<IAPIKeyProvider>(TYPES.IAPIKeyProvider)
    private base64Encoder: IBase64Encoder = container.get<IBase64Encoder>(TYPES.IBase64Encoder)
    private registrationTokenService: IRegistrationTokenService = container.get<IRegistrationTokenService>(TYPES.IRegistrationTokenService)
    private emailRegistrationCompositionService: IEmailRegistrationCompositionService = container.get<IEmailRegistrationCompositionService>(TYPES.IEmailRegistrationCompositionService)
    private dateProvider: IDateProvider = container.get<IDateProvider>(TYPES.IDateProvider)
    private uuid: UUID = container.get<UUID>(TYPES.UUID)
    private featureFlagService: IFeatureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService)

    getUserCourseProgress(userId: string, clientId: string): LRSServiceConfig {
        const homePageUrl = process.env.REACT_APP_ENV_LRS_HOME_PAGE_URL
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL

        const agent = {
            [Constants.account]: {
                [Constants.name]: userId,
                [Constants.homePage]: homePageUrl
            }
        }

        const config: AxiosRequestConfig = {
            method: HTTPMethods.get,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            },
            params: {
                agent: encodeURIComponent(JSON.stringify(agent))
            }
        }
        
        return {
            url: learningLockerBaseUrl + Constants.statementPathUrl,
            config: config
        }
    }

    getCourseUpdateConfig(
        zipFile: File, 
        imageFile: File, 
        courseFolder: string,
        clientId: string) : LRSServiceConfig {
        var formData = new FormData();

        formData.append("clientid", clientId)
        formData.append("folder", courseFolder)
        if (zipFile != null) {
		    formData.append("zipfile", zipFile)
        }
        if (imageFile != null) {
            formData.append("image", imageFile)
        }

        const config: AxiosRequestConfig = {
            method: HTTPMethods.post,
            data: formData,
            headers: {
                [Constants.contentType]: Constants.mimeTypeMultipartFormData
            }
        }

        let courseUploadUrl = ""
        if (process.env.REACT_APP_ENV_COURSE_UPLOAD_URL) {
            courseUploadUrl = process.env.REACT_APP_ENV_COURSE_UPLOAD_URL
        } 

        return {
            url: courseUploadUrl,
            config: config
        }
    }

    getCourseUploadConfig(zipFile: File, imageFile: File, clientId: string) : LRSServiceConfig {
        var formData = new FormData();
		formData.append("clientid", clientId)
		formData.append("zipfile", zipFile)
		formData.append("image", imageFile)

        const config: AxiosRequestConfig = {
            method: HTTPMethods.post,
            data: formData,
            headers: {
                [Constants.contentType]: Constants.mimeTypeMultipartFormData
            }
        }

        let courseUploadUrl = ""
        if (process.env.REACT_APP_ENV_COURSE_UPLOAD_URL) {
            courseUploadUrl = process.env.REACT_APP_ENV_COURSE_UPLOAD_URL
        } 

        return {
            url: courseUploadUrl,
            config: config
        }
    }

    getCourseCompletionConfig(courseId: string, clientId: string): LRSServiceConfig {
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL

        const config: AxiosRequestConfig = {
            method: HTTPMethods.get,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            },
            params: {
                verb: Constants.xAPIVerbPassed,
                activity: courseId
            }
        }

        return {
            url: learningLockerBaseUrl + Constants.statementPathUrl,
            config: config
        }
    }

    getLRSStatementsByPaginationConfig(clientId: string, callbackUrl: string): LRSServiceConfig {
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL

        const config: AxiosRequestConfig = {
            method: HTTPMethods.get,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            }
        }

        return {
            url: learningLockerBaseUrl + callbackUrl,
            config: config
        }
    }

    getFilteredCourseCompletionConfig(courseId: string, clientId: string, startDate: Date, endDate: Date): LRSServiceConfig {
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL

        const config: AxiosRequestConfig = {
            method: HTTPMethods.get,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            },
            params: {
                verb: Constants.xAPIVerbPassed,
                activity: courseId,
                since: startDate.toISOString(),
                until: endDate.toISOString()
            }
        }

        return {
            url: learningLockerBaseUrl + Constants.statementPathUrl,
            config: config
        }
    }

    getCourseAttemptsConfig(courseId: string, clientId: string): LRSServiceConfig {
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL

        const config: AxiosRequestConfig = {
            method: HTTPMethods.get,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            },
            params: {
                verb: Constants.xAPIVerbAttempted,
                activity: courseId
            }
        }

        return {
            url: learningLockerBaseUrl + Constants.statementPathUrl,
            config: config
        }
    }
    
    getLastLessonOnCourseConfig(userId: string, clientId: string): LRSServiceConfig {
        const homePageUrl = process.env.REACT_APP_ENV_LRS_HOME_PAGE_URL
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL

        const agent = {
            [Constants.account]: {
                [Constants.name]: userId,
                [Constants.homePage]: homePageUrl
            },
            [Constants.name]: userId
        }

        const config: AxiosRequestConfig = {
            method: HTTPMethods.get,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            },

            params: {
                verb: Constants.xAPIVerbExperienced,
                agent: JSON.stringify(agent)
            }
        }
        
        return {
            url: learningLockerBaseUrl + Constants.statementPathUrl,
            config: config
        }
    }

    getOldestPassForCourseConfig(userId: string, clientId: string, activityId: string): LRSServiceConfig {
        const homePageUrl = process.env.REACT_APP_ENV_LRS_HOME_PAGE_URL
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL

        const agent = {
            [Constants.account]: {
                [Constants.name]: userId,
                [Constants.homePage]: homePageUrl
            },
            [Constants.name]: userId
        }

        const config: AxiosRequestConfig = {
            method: HTTPMethods.get,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            },

            params: {
                verb: Constants.xAPIVerbPassed,
                agent: JSON.stringify(agent),
                activity: activityId,
                ascending: true
            }
        }

        return {
            url: learningLockerBaseUrl + Constants.statementPathUrl,
            config: config
        }
    }

    getNumberOfAttemptsOnCourseConfig(userId: string, clientId: string, activityId: string, timestamp?: string): LRSServiceConfig {
        const homePageUrl = process.env.REACT_APP_ENV_LRS_HOME_PAGE_URL
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL

        const agent = {
            [Constants.account]: {
                [Constants.name]: userId,
                [Constants.homePage]: homePageUrl
            },
            [Constants.name]: userId
        }
        let config: AxiosRequestConfig


        if (timestamp !== undefined) {
            config = {
                method: HTTPMethods.get,
                headers: {
                    [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                    [Constants.xApiVersionKey]: Constants.xApiVersion
                },
                params: {
                    verb: Constants.xAPIVerbAttempted,
                    agent: JSON.stringify(agent),
                    activity: activityId,
                    until: timestamp
                }
            }
        }

        else {
            config = {
                method: HTTPMethods.get,
                headers: {
                    [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                    [Constants.xApiVersionKey]: Constants.xApiVersion
                },
                params: {
                    verb: Constants.xAPIVerbAttempted,
                    agent: JSON.stringify(agent),
                    activity: activityId,
                }
            }
        }

        return {
            url: learningLockerBaseUrl + Constants.statementPathUrl,
            config: config
        }
    }

    getProgressionOnCourseForUserConfig(userId: string, clientId: string, activityId: string): LRSServiceConfig {
        const homePageUrl = process.env.REACT_APP_ENV_LRS_HOME_PAGE_URL
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL

        const agent = {
            [Constants.account]: {
                [Constants.name]: userId,
                [Constants.homePage]: homePageUrl
            },
            [Constants.name]: userId
        }
        
        const config: AxiosRequestConfig = {
            method: HTTPMethods.get,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            },

            params: {
                verb: Constants.xAPIVerbProgressed,
                agent: JSON.stringify(agent),
                activity: activityId,
                ascending: false
            }
        }
        
        return {
            url: learningLockerBaseUrl + Constants.statementPathUrl,
            config: config
        }
    }

    getCourseDeleteConfig(courseFolder: string): LRSServiceConfig {
        const config: AxiosRequestConfig = {
            method: HTTPMethods.post,
            data: {courseFolder: courseFolder},
        }

        let courseDeleteUrl = ""
        if (process.env.REACT_APP_ENV_COURSE_DELETE_URL) {
            courseDeleteUrl = process.env.REACT_APP_ENV_COURSE_DELETE_URL
        } 

        return {
            url: courseDeleteUrl,
            config: config
        }
    }

    getNewAccountEmailsConfig(userData: Dictionary[], clientId: string): Promise<LRSServiceConfig> {
        const userUploadData: UserUploadData = this.addUsersToUploadData(userData, clientId)

        return new Promise((resolve, reject) => {
            Promise.all(userUploadData.uploadTokenPromises).then(() => {
                const config: AxiosRequestConfig = {
                    method: HTTPMethods.post,
                    data: {
                        recipients: userUploadData.recipients
                    }
                }

                let newAccountMailUrl = ""
                if (process.env.REACT_APP_ENV_NEW_ACCOUNT_MAIL_URL) {
                    newAccountMailUrl = process.env.REACT_APP_ENV_NEW_ACCOUNT_MAIL_URL
                }
        
                const options = {
                    url: newAccountMailUrl,
                    config: config
                }
                resolve(options)
            }).catch(error => {
                reject(error)
            })
        })
    }
    
    getNewAccountEmailsConfigV2(userData: Dictionary[]): LRSServiceConfig {
        let emailData: Dictionary[] = userData.map(dict => {
            return {
                [Constants.email]: dict.emailAddress,
                [Constants.name]: dict.firstName,
                [Constants.temporaryPassword]: dict.originalPassword,
                [Constants.link]: process.env.REACT_APP_ENV_LOGIN_PAGE_URL
            }
        })

        const config: AxiosRequestConfig = {
            method: HTTPMethods.post,
            data: emailData
        }

        return {
            url: process.env.REACT_APP_ENV_NEW_ACCOUNTS_MAIL_URL ?? "",
            config: config
        }
    }

    getSetOnboardingRemindersConfig (userData: Dictionary[], clientId: DocumentId): Promise<LRSServiceConfig> {
        const userUploadData: UserUploadData = this.addUsersToUploadData(userData, clientId)

        return new Promise((resolve, reject) => {
            Promise.all(userUploadData.uploadTokenPromises).then(() => {
                const config: AxiosRequestConfig = {
                    method: HTTPMethods.post,
                    data: {
                        recipients: userUploadData.recipients
                    }
                }

                let setOnboardingRemindersUrl = ""
                if (process.env.REACT_APP_ENV_SET_ONBOARDING_REMINDERS) {
                    setOnboardingRemindersUrl = process.env.REACT_APP_ENV_SET_ONBOARDING_REMINDERS
                }

                const options = {
                    url: setOnboardingRemindersUrl,
                    config: config
                }
                resolve(options)
            }).catch(error => {
                reject(error)
            })
        })
    }

    getOnboardingReminderDeleteConfig(emailAddress: string): LRSServiceConfig {
        let deleteReminderUrl = ""
        if (process.env.REACT_APP_ENV_DELETE_ONBOARDING_REMINDER) {
            deleteReminderUrl = process.env.REACT_APP_ENV_DELETE_ONBOARDING_REMINDER
        } 

        const config: AxiosRequestConfig = {
            method: HTTPMethods.post,
            data: emailAddress
        }

        return {
            url: deleteReminderUrl,
            config: config
        } 
    }

    getPasswordResetConfig(emailAddress: string, emailLink: string): LRSServiceConfig {
        let passwordResetUrl = ""
        var data = {}
        if (process.env.REACT_APP_ENV_PASSWORD_RESET_URL) {
            passwordResetUrl = process.env.REACT_APP_ENV_PASSWORD_RESET_URL
            data = { 
                emailAddress: emailAddress, 
                url: emailLink 
            }
        }

        if (this.featureFlagService.mailServiceV2() && process.env.REACT_APP_ENV_PASSWORD_RESET_V2_URL) {
            passwordResetUrl = process.env.REACT_APP_ENV_PASSWORD_RESET_V2_URL
            data = { 
                email: emailAddress, 
                link: emailLink 
            }
        }

        const config: AxiosRequestConfig = {
            method: HTTPMethods.post,
            data: data
        }

        return {
            url: passwordResetUrl,
            config: config
        } 
    }

    getCleverBearerTokenConfig(code: string, redirectUri: string): ServiceRequestConfig {
        let cId = process.env.REACT_APP_ENV === "production" ? Constants.cleverProdClientID : Constants.cleverDevClientID
        let sId = process.env.REACT_APP_ENV === "production" ? Constants.cleverProdClientSecret : Constants.cleverDevClientSecret
        const encodedAuth = this.base64Encoder.encode(`${cId}:${sId}`)

        const config: RequestConfig = {
            method: HTTPMethods.post,
            headers: {
                [Constants.authorization]: `${Constants.basic} ${encodedAuth}`
            }
        }

        let url = ""
        if (process.env.REACT_APP_ENV_PROXY_BASE_AUTH_URL) {
            url = process.env.REACT_APP_ENV_PROXY_BASE_AUTH_URL + Constants.cleverTokenPath
        }

        return {
            url: url,
            config: config,
            body: {
                [Constants.codeKey]: code,
                [Constants.redirectUriKey]: redirectUri
            }
        }
    }

    getCleverMeConfig(token: BearerToken): ServiceRequestConfig {
        const config: RequestConfig = {
            method: HTTPMethods.get,
            headers: {
                [Constants.authorization]: `${Constants.bearer} ${token}`
            }
        }
        
        let url = ""
        if (process.env.REACT_APP_ENV_PROXY_BASE_AUTH_URL) {
            url = process.env.REACT_APP_ENV_PROXY_BASE_AUTH_URL + Constants.cleverMePath
        }
        
        return {
            url: url,
            config: config
        }
    }

    getCleverSchoolAdminConfig(userId: string, token: BearerToken): ServiceRequestConfig {
        const config: RequestConfig = {
            method: HTTPMethods.get,
            headers: {
                [Constants.authorization]: `${Constants.bearer} ${token}`
            }
        }

        let url = ""
        if (process.env.REACT_APP_ENV_PROXY_BASE_AUTH_URL) {
            url = process.env.REACT_APP_ENV_PROXY_BASE_AUTH_URL + Constants.cleverSchoolAdminPath
        }

        return {
            url: `${url}/${userId}`,
            config: config
        }
    }

    getCleverDistrictAdminConfig(userId: string, token: BearerToken): ServiceRequestConfig {
        const config: RequestConfig = {
            method: HTTPMethods.get,
            headers: {
                [Constants.authorization]: `${Constants.bearer} ${token}`
            }
        }

        let url = ""
        if (process.env.REACT_APP_ENV_PROXY_BASE_AUTH_URL) {
            url = process.env.REACT_APP_ENV_PROXY_BASE_AUTH_URL + Constants.cleverDistrictAdminPath
        }

        return {
            url: `${url}/${userId}`,
            config: config
        }
    }

    getCleverTeacherConfig(userId: string, token: BearerToken): ServiceRequestConfig {
        const config: RequestConfig = {
            method: HTTPMethods.get,
            headers: {
                [Constants.authorization]: `${Constants.bearer} ${token}`
            }
        }

        let url = ""
        if (process.env.REACT_APP_ENV_PROXY_BASE_AUTH_URL) {
            url = process.env.REACT_APP_ENV_PROXY_BASE_AUTH_URL + Constants.cleverTeacherPath
        }

        return {
            url: `${url}/${userId}`,
            config: config
        }
    }

    getMarketingDemoEmailConfig(formData: Dictionary) : LRSServiceConfig {
        const config: AxiosRequestConfig = {
            method: HTTPMethods.post,
            data: formData
        }

        return {
            url: Constants.marketingDemoMailUrl,
            config: config
        }
    }

    getPrivacyRequestEmailConfig(formData: Dictionary) : LRSServiceConfig {
        let privacyRequestMailUrl = ""
        if (process.env.REACT_APP_ENV_PRIVACY_POLICY_EMAIL_WEBHOOK_ENDPOINT !== undefined) {
            privacyRequestMailUrl = process.env.REACT_APP_ENV_PRIVACY_POLICY_EMAIL_WEBHOOK_ENDPOINT
        }

        const config: AxiosRequestConfig = {
            method: HTTPMethods.post,
            data: formData
        }

        return {
            url: privacyRequestMailUrl,
            config: config
        }
    }

    getTrainingLaunchLocationConfig(clientId: DocumentId, activityId: string): LRSServiceConfig {
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL
        
        const config: AxiosRequestConfig = {
            method: HTTPMethods.get,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            },
            params: {
                verb: Constants.xAPIVerbLaunched,
                activity: activityId,
                ascending: false
            }
        }
        
        return {
            url: learningLockerBaseUrl + Constants.statementPathUrl,
            config: config
        }
    }

    postTrainingLaunchLocationConfig(userId: string, clientId: string, activityId: string, launchLocation: string): LRSServiceConfig {
        const homePageUrl = process.env.REACT_APP_ENV_LRS_HOME_PAGE_URL
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL

        const statement = {
            [Constants.authority]: {
                [Constants.objectType]: Constants.agent,
                [Constants.name]: Constants.authorityName,
                [Constants.mailboxName]: Constants.mailboxAddress
            },
            [Constants.actor]: {
                [Constants.objectType]: Constants.agent,
                [Constants.account]: {
                    [Constants.name]: userId,
                    [Constants.homePage]: homePageUrl,
                },
                [Constants.name]: userId
            },
            [Constants.verb]: {
                [Constants.id]: Constants.xAPIVerbLaunched,
                [Constants.display]: {
                    [Constants.enUS]: Constants.launched
                }
            },
            [Constants.timestamp]: this.dateProvider.getDateAsISOString(),
            [Constants.version]: Constants.statementVersionNumber,
            [Constants.id]: this.uuid.uuidv4(),
            [Constants.result]: {
                [Constants.response]: launchLocation,
            },
            [Constants.object]: {
                [Constants.id]: activityId,
                [Constants.objectType]: Constants.activity,
                [Constants.definition]: {
                    [Constants.typeConstant]: Constants.xAPIActivityCourse 
                }
            }
        }
        
        const config: AxiosRequestConfig = {
            method: HTTPMethods.post,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            },

            data: statement
        }
        
        return {
            url: learningLockerBaseUrl + Constants.statementPathUrl,
            config: config
        }
    }

    postCompletionOverrideConfig(userId: string, clientId: string, activityId: string): LRSServiceConfig {
        const homePageUrl = process.env.REACT_APP_ENV_LRS_HOME_PAGE_URL
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL

        const statement = {
            [Constants.authority]: {
                [Constants.objectType]: Constants.agent,
                [Constants.name]: Constants.authorityName,
                [Constants.mailboxName]: Constants.mailboxAddress
            },
            [Constants.actor]: {
                [Constants.objectType]: Constants.agent,
                [Constants.account]: {
                    [Constants.name]: userId,
                    [Constants.homePage]: homePageUrl,
                },
                [Constants.name]: userId
            },
            [Constants.verb]: {
                [Constants.id]: Constants.xAPIVerbPassed,
                [Constants.display]: {
                    [Constants.enUS]: Constants.passed
                }
            },
            [Constants.timestamp]: this.dateProvider.getDateAsISOString(),
            [Constants.version]: Constants.statementVersionNumber,
            [Constants.id]: this.uuid.uuidv4(),
            [Constants.result]: {
                [Constants.success]: true,
                [Constants.duration]: Constants.durationOverride,
                [Constants.completion]: true,
                [Constants.response]: Constants.override,
            },
            [Constants.object]: {
                [Constants.id]: activityId,
                [Constants.objectType]: Constants.activity,
                [Constants.definition]: {
                    [Constants.typeConstant]: Constants.xAPIActivityCourse 
                }
            }
        }
        
        const config: AxiosRequestConfig = {
            method: HTTPMethods.post,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            },

            data: statement
        }
        
        return {
            url: learningLockerBaseUrl + Constants.statementPathUrl,
            config: config
        }
    }

    private addUsersToUploadData = (users: Dictionary[], clientId: DocumentId): UserUploadData => {
        const newAccountMailCallbackUrl = process.env.REACT_APP_ENV_NEW_ACCOUNT_MAIL_CALLBACK_URL

        let recipients: Dictionary[] = []
        let uploadData: Promise<void>[] =[]

        users.forEach(dict => {
            let timestamp = this.dateProvider.getDateAsISOString();
            let token = this.registrationTokenService.createToken(dict.firstName, dict.lastName, dict.emailAddress, clientId, timestamp)
            recipients.push({
                "email": dict.emailAddress,
                "name": dict.firstName,
                "url": [newAccountMailCallbackUrl, token].join("/")
            })

            uploadData.push(this.emailRegistrationCompositionService.getUidAndUploadToken(dict.emailAddress, token))
        })

        return {
            recipients: recipients,
            uploadTokenPromises: uploadData
        }
    }
}