import { IStackTokens, IStackStyles, IImageStyles, IIconStyles, ITextStyles } from "@fluentui/react"

export class LearningPlanContentItemStyles {
    static stackTokens: IStackTokens = {
        padding: '20px'
    }

    static completedDiv = {
        paddingLeft: '10px'
    }

    static containerStyle: IStackStyles = {
        root: {
            width: '275px',
            height: '325px',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px #eeeeee',
            cursor: 'pointer',
            backgroundColor: '#ffffff',
        }
    }

    static headerStyle: IStackStyles = {
        root: {
            height: '60%',
        },
    }

    static imageStyle: IImageStyles = {
        image: {},
        root: {
            height: '100%',
            width: '100%',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px'
        }
    }

    static bodyStyle: IStackStyles = {
        root: {
            height: '20%',
        },
    }
    
    static footerStyle: IStackStyles = {
        root: {
            height: '20%',
            justifyContent: "space-between"
        },
    }

    static startCourseText: ITextStyles = {
        root: {
            color: '#24c4f0'
        }
    }

    static completedIcon: IIconStyles = {
        root: {
            color: 'green'
        },
    }

    static deadlineStyle: ITextStyles = {
        root: {
            color: 'red'
        }
    }

    static greyedOutContainerStyle: IStackStyles = {
        root: {
            width: '275px',
            height: '325px',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px #eeeeee',
            cursor: 'default',
            backgroundColor: '#ffffff',
            opacity: "20%",
            border: "1px solid rgb(0,0,0,0.2)"
        }
    }

    static showRedProgressionArrowImageStyle = {
        root: {
            alignContent: 'center'
        }, 
        image: {
            opacity: 100,
            filter: 'invert(18%) sepia(66%) saturate(6545%) hue-rotate(352deg) brightness(95%) contrast(94%)'
        }
    }

    static showGreenProgressionArrowImageStyle = {
        root: {
            alignContent: 'center'
        }, 
        image: {
            opacity: 100,
            filter: 'invert(68%) sepia(26%) saturate(7094%) hue-rotate(79deg) brightness(113%) contrast(96%)'
        }
    }

    static noShowProgressionArrowImageStyle = {
        root: {
            alignContent: 'center'
        }, 
        image: {
            opacity: 0
        }
    }
}