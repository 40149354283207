import { Component, MouseEvent } from "react"
import ICourseService from "../../../services/CourseService"
import {
	Stack,
	IStackTokens,
	Spinner,
	MessageBar,
	MessageBarType,
} from "@fluentui/react"
import { TabPanelProps } from "../TabPanelProps"
import "./RemoveCohortFromCourse.css"
import DTSTable from "../../../ui-kit/DTSTable"
import DTSTableData from "../../../models/DTSTableData"
import IDataTableFactory from "../../../factories/DataTableFactory"
import { log } from "../../../global/Logger"
import { Loader } from "../../../ui-kit/Loader"
import { TYPES } from "../../../Types"
import { container } from "../../../DIContainer"
import { i18nKey, Ii18n } from "../../../global/i18n"
import { DestructiveButton } from "../../../ui-kit/DestructiveButton"
import { DocumentId } from "../../../global/TypeAliases"

interface RemoveCohortFromCourseProps extends TabPanelProps {
	documentId: string
	clientId: DocumentId
}

interface RemoveCohortFromCourseState {
	loadingData: boolean
	tableData: DTSTableData | undefined
	primaryButtonDisabled: boolean
	showSpinner: boolean
	errorMessage: string | undefined
	successMessage: string | undefined
    selectedCohorts: string[]
    clearSelectedRows: boolean
}

const stackStyling: IStackTokens = {
	childrenGap: "20px",
}

export default class RemoveCohortFromCourse extends Component<
	RemoveCohortFromCourseProps,
	RemoveCohortFromCourseState
> {
	private courseService: ICourseService = container.get<ICourseService>(TYPES.ICourseService)
	private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	state: RemoveCohortFromCourseState = {
		loadingData: false,
		tableData: undefined,
		primaryButtonDisabled: true,
		showSpinner: false,
		errorMessage: undefined,
		successMessage: undefined,
		selectedCohorts: [],
		clearSelectedRows: false
	}

	componentDidMount() {
		this.loadData()
	}

	render() {
		if (this.state.loadingData) {
			return <Loader />
		}
		
		let banner = null
		if (this.state.errorMessage) {
			banner = (
				<MessageBar
					id="remove-cohort-error-banner"
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.errorMessage}
				</MessageBar>
			)
		} else if (this.state.successMessage) {
			banner = (
				<MessageBar
					id="remove-cohort-success-banner"
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			)
		}

		return (
			<div id="remove-cohort-from-course-modal-container">
				<Stack tokens={stackStyling}>
					{this.state.tableData !== undefined && (
						<div id="remove-cohort-from-course-table">
							<Stack tokens={stackStyling}>
								<DTSTable
									data={this.state.tableData}
									pagination={true}
                                    onSelectedRowsChange={this.onSelectedRowsChange}
                                    clearSelectedRows={this.state.clearSelectedRows}
								/>
								
								<DestructiveButton
									id="remove-cohort-primary-button"
									text={this.i18n.get(i18nKey.removeGroups)}
									onClick={this.primaryButtonClicked}
									disabled={this.state.primaryButtonDisabled}
									hidden={this.state.showSpinner}
									/>

								{ this.state.showSpinner && 
									<Spinner
										id="remove-cohort-spinner"
										label="Removing"
										ariaLive="assertive"
										labelPosition="right"
									/>
								}
							</Stack>
						</div>
					)}
					
					{banner}
				</Stack>
			</div>
		)
	}

    onSelectedRowsChange = (selectedRowState: {
		allSelected: boolean
		selectedCount: number
		selectedRows: { [key: string]: any }[]
	}) => {
		if (selectedRowState.selectedCount === 0) {
			this.setState({ selectedCohorts: [] }, () => {
				this.enablePrimaryButtonIfNeeded()
			})
			return
		}

        const selectedIds = selectedRowState.selectedRows.map((row) => {
			return row.id
		})

		log(selectedIds.length + " cohorts selected")

		this.setState({ selectedCohorts: selectedIds }, () => {
			this.enablePrimaryButtonIfNeeded()
		})
	}

	private loadData = () => {
		this.setState({ loadingData: true })

		this.courseService
			.getCohortsForCourse(this.props.documentId)
			.then((cohorts) => {
				const data = this.dataTableFactory.createCohortsTableData(cohorts)
				this.setState({ loadingData: false, tableData: data, clearSelectedRows: false })
			})
			.catch(error => {
				this.setState({ loadingData: false, errorMessage: error.message })
			})
	}

	dismissBanner = () => {
		this.setState({ errorMessage: undefined, successMessage: undefined })
	}

	primaryButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: undefined,
			successMessage: undefined,
			showSpinner: true,
			primaryButtonDisabled: true,
		})

		this.courseService
			.removeCohortsFromCourse(this.state.selectedCohorts, this.props.documentId, this.props.clientId)
			.then(() => {
				let message: string
                if (this.state.selectedCohorts.length === 1) {
                    message = "1 cohort removed"
                } else {
                    message = this.state.selectedCohorts.length + " cohorts removed"
                }

				this.setState({
					successMessage: message,
					showSpinner: false,
					primaryButtonDisabled: true,
                    selectedCohorts: [],
                    clearSelectedRows: true
				})

				this.loadData()
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showSpinner: false,
					primaryButtonDisabled: false,
				})
			})
	}

	enablePrimaryButtonIfNeeded = () => {
		let disable = true

		if (this.state.selectedCohorts.length > 0) {
			disable = false
		}

		this.setState({ primaryButtonDisabled: disable })
	}
}
