import React, { Component } from 'react'
import { 
    Stack,
} from '@fluentui/react'
import { IAuth } from "../../auth/Auth"
import { ProfileUser } from './ProfileUser'
import { ProfilePasswordUpdate } from './ProfilePasswordUpdate'
import { container } from '../../DIContainer'
import { TYPES } from '../../Types'
import { TextField } from '../../ui-kit/TextField'
import { Label, Separator, ActionButton, MessageBar, MessageBarType } from '@fluentui/react'
import { SecondaryButton } from '../../ui-kit/SecondaryButton'
import { PrimaryButton } from '../../ui-kit/PrimaryButton'
import { ProfileUIStyles, ProfileUITokens } from './ProfileUI'
import { IUserService } from '../../services/UserService'
import { Loader } from '../../ui-kit/Loader'
import User from '../../models/User'
import { IUserRepository } from '../../services/UserRepository'
import { IRouter } from '../../Router'

interface ProfilePageProps {}
export interface ProfilePageState {
    editMode: boolean
    firebaseUser: firebase.User | null
    user: User | null
    loggedIn: boolean
    modalOpen: boolean
    userServiceComplete: boolean
    errorMessage: string | null
    successMessage: string | null
    firstName: string
    lastName: string
}

export default class Profile extends Component<ProfilePageProps, ProfilePageState> {
    private auth = container.get<IAuth>(TYPES.IAuth)
    private userService = container.get<IUserService>(TYPES.IUserService)
    private userRepository = container.get<IUserRepository>(TYPES.IUserRepository)
    private router = container.get<IRouter>(TYPES.IRouter)

    private passwordUpdateModalRef = React.createRef<ProfilePasswordUpdate>()

    state = {
        editMode: false,
        firebaseUser: this.auth.firebaseUser(),
        loggedIn: this.auth.isLoggedIn(),
        modalOpen: false,
        userServiceComplete: false,
        user: null,
        errorMessage: null,
        successMessage: null,
        firstName: "",
        lastName: ""
    }

    componentDidMount() {
        const firebaseUser = this.auth.firebaseUser()

        if (firebaseUser === null) {
            return
        }

        this.loadUser(firebaseUser.uid)
    }

    render() {
        if (!this.state.userServiceComplete) {
			return <Loader text="Loading your profile" />
        }
        
        if (this.state.user === null || this.state.firebaseUser === null) {
            this.router.goToLogin()
            return null
        }

        const user: User = this.state.user!
        
        return (
            <Stack 
                id="profile-page-container" 
                tokens={ProfileUITokens.verticalGapStackTokens} 
                styles={ProfileUIStyles.pageContainerStyle} 
                horizontalAlign="center">
                {this.state.errorMessage !== null && (
					<MessageBar
						messageBarType={MessageBarType.error}
						dismissButtonAriaLabel="Close"
						onDismiss={this.dismissBanner}
					>
						{this.state.errorMessage}
					</MessageBar>
				)}

                {this.state.successMessage !== null && (
					<MessageBar
						messageBarType={MessageBarType.success}
						dismissButtonAriaLabel="Close"
                        onDismiss={this.dismissBanner}
                        id="profile-success-banner"
					>
						{this.state.successMessage}
					</MessageBar>
				)}
                
                <ProfileUser user={this.state.firebaseUser} />

                <Stack styles={ProfileUIStyles.pageContainerStyle}>
                    <>
                    <Separator styles={ProfileUIStyles.separatorStyles}></Separator>
                    </>
                </Stack>
                
                <Stack id="profile-info-container" 
                        horizontal={true} 
                        styles={ProfileUIStyles.profileInfoContainerStyle}
                        wrap>
                    <Stack 
                        id="profile-info-primary" 
                        horizontal={false} 
                        styles={ProfileUIStyles.profileInfoPrimaryContainerStyle}
                        tokens={ProfileUITokens.verticalGapStackTokens}
                        grow>

                        <Label>First Name</Label>
                        <TextField 
                            id="profile-first-name-textfield" 
                            value={this.state.firstName} 
                            disabled={!this.state.editMode} 
                            onChange={this.firstNameTextFieldChanged} 
                        />

                        <Label>Last Name</Label>
                        <TextField
                            id="profile-last-name-textfield" 
                            value={this.state.lastName} 
                            disabled={!this.state.editMode} 
                            onChange={this.lastNameTextFieldChanged} 
                        />
                    </Stack>
                    <Stack 
                        id="profile-info-secondary" 
                        horizontal={false} 
                        styles={ProfileUIStyles.profileInfoPrimaryContainerStyle}
                        tokens={ProfileUITokens.verticalGapStackTokens} 
                        grow>

                        <Label>Email</Label>
                        <TextField 
                            id="profile-email-textfield"
                            value={user.email} 
                            disabled={true}
                            readOnly={true}
                        />

                        <Label>Password
                            <ActionButton onClick={this.updatePasswordClicked}>(Update Password)</ActionButton>
                        </Label>
                        <TextField
                            id="profile-password-textfield" 
                            value="*************" 
                            disabled={true} 
                            readOnly={true}
                        />
                        
                    </Stack>
                </Stack>

                <Stack id="profile-button-container" horizontal={true}>
                    { this.state.editMode && 
                        <div id="profile-buttons-edit-on">
                            <Stack 
                                tokens={ProfileUITokens.horizontalGapStackTokens}
                                horizontal>
                                <SecondaryButton
                                    id="profile-cancel-button"
                                    text="CANCEL" 
                                    onClick={this.cancelButtonClicked}
                                    width="150px"
                                />

                                <PrimaryButton
                                    id="profile-save-button"
                                    text="SAVE" 
                                    onClick={this.saveButtonClicked}
                                    width="150px"
                                />
                            </Stack>
                        </div>
                    }

                    { !this.state.editMode && 
                        <div id="profile-buttons-edit-off">
                            <PrimaryButton
                                id="profile-edit-button"
                                text="EDIT" 
                                onClick={this.editButtonClicked}
                                width='150px'
                            />
                        </div>
                    }
                </Stack>
                
                <ProfilePasswordUpdate
                    user={this.state.firebaseUser} 
                    ref={this.passwordUpdateModalRef} 
                    OnPasswordUpdate={this.onPasswordUpdate}
                />
            </Stack>
        )
    }

    loadUser = (uid: string) => {
        this.userService
            .getUser(uid)
            .then(user => {
                this.setState(
                    { 
                        user: user, 
                        firstName: user.firstName, 
                        lastName: user.lastName,
                        userServiceComplete: true 
                    }
                )
            })
            .catch(_ => {
                this.setState({
                    errorMessage: "Profile failed to load",
                    userServiceComplete: true,
                })
            })
    }

    editButtonClicked = () => {
        this.setState({ editMode: true })
    }

    cancelButtonClicked = () => {
        if (this.state.user === null) {
            this.setState({ editMode: false })
            return
        }

        const user: User = this.state.user!
        const firstName = user.firstName
        const lastName = user.lastName
    
        this.setState({ editMode: false, firstName: firstName, lastName: lastName })
    }

    saveButtonClicked = () => {
        const firebaseUser = this.state.firebaseUser
        const user = this.state.user
        const firstName = this.state.firstName
        const lastName = this.state.lastName

        if (firebaseUser === null || 
            user === null || 
            firstName === null || 
            lastName === null) {
            return
        }

        this.userRepository
        .updateCurrentUser(firebaseUser, user, firstName, lastName)
        .then(() => {
            this.setState(
                { 
                    successMessage: "Profile updated successfully", 
                    editMode: false,
                    errorMessage: null,
                }
            )
            this.loadUser(firebaseUser.uid)            
        }).catch(_ => {
            this.setState(
                { 
                    successMessage: null, 
                    errorMessage: "Profile failed to update. Please try again."
                }
            )
        })
    }

    updatePasswordClicked = () => {
        if (this.passwordUpdateModalRef.current !== null) {
            this.passwordUpdateModalRef.current.Show()
        }   
    }

    firstNameTextFieldChanged = (
		_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return
		}

		this.setState({ firstName: newValue })
    }
    
    lastNameTextFieldChanged = (
		_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return
		}

		this.setState({ lastName: newValue })
	}

    onPasswordUpdate = () => {
        this.setState({modalOpen: false})
    }

    dismissBanner = () => {
		this.setState({ successMessage: null, errorMessage: null })
	}
}
