import { Dictionary, DocumentId } from './../global/TypeAliases';
import { IContent } from './IContent';

export class LearningPlan {
    documentId: DocumentId
    name: string
    description: string
    clients: Dictionary
    cohorts: Dictionary
    trainings: DocumentId[]
    contents: IContent[]

    constructor(
        documentId: DocumentId, 
        name: string, 
        description: string, 
        clients: Dictionary, 
        cohorts: Dictionary, 
        trainings: DocumentId[],
        contents: IContent[]) {
        this.documentId = documentId
        this.name = name
        this.description = description
        this.clients = clients
        this.cohorts = cohorts
        this.trainings = trainings
        this.contents = contents
    }

    get clientId(): DocumentId {
		return Object.keys(this.clients)[0]
	}

    static Converter = {
        toFirestore: function(learningPlan: LearningPlan) {
            return {
                documentId: learningPlan.documentId,
                name: learningPlan.name,
                description: learningPlan.description,
                clients: learningPlan.clients,
                cohorts: learningPlan.cohorts,
                trainings: learningPlan.trainings,
                contents: learningPlan.contents
            }
        },
        fromFirestore: function(snapshot: any, options: any) {
            const data = snapshot.data(options);
            return new LearningPlan(
                data.documentId,
                data.name,
                data.description,
                data.clients,
                data.cohorts === undefined ? {}: data.cohorts,
                data.trainings,
                data.contents
            )
        }
    }
}