import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Label, Input, Form, Alert, UncontrolledAlert } from "reactstrap";
import { container } from "../../DIContainer";
import { TYPES } from "../../Types";
import { Ii18n, i18nKey } from "../../global/i18n";
import { ProfileImageV2 } from "../../ui-kit/ProfileImageV2";
import defaultProfileImage from "../../images/default-profile.png";

interface ProfileV2FormProps {
    firstName: string;
    lastName: string;
    profileImageURLString: string | null;
    displayName?: string;
    email?: string;
    successMessage: string | undefined;
    errorMessage: string | undefined;
    editMode: boolean;
    handleFirstNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleLastNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    saveButtonClicked: () => void;
    cancelButtonClicked: () => void;
    editButtonClicked: () => void;
    updatePasswordLinkClicked: () => void;
    editProfilePicButtonClicked: () => void;
}

export default class ProfileV2Form extends Component<ProfileV2FormProps> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    render() {
        let profileImageURLString = defaultProfileImage;
		if (this.props.profileImageURLString !== null) {
			profileImageURLString = this.props.profileImageURLString
		}
        
        return (
        <Container fluid>
            <Row>
                <Col lg="12">
                    <div className="d-flex vstack align-items-center mb-3">
                        <div className="profile-photo mb-3">
                            <ProfileImageV2
                                className="rounded-circle profile-photo-img"
                                source={profileImageURLString}
                                height={150}
                            /> 
                            <div className="profile-photo-hover">
                                <span className="profile-photo-icon">
                                    <i className="fas fa-camera" />
                                </span>
                                <button
                                    type="button"
                                    className="btn profile-photo-text profile-photo-btn"
                                    data-testid="edit-profile-photo-btn"
                                    onClick={this.props.editProfilePicButtonClicked}
                                >
                                    {this.i18n.get(i18nKey.editPhoto)}
                                </button>
                            </div>
                        </div>
                        <h5 data-testid="display-name">{this.props.displayName}</h5>
                        <p data-testid="email">{this.props.email}</p>
                    </div>
                </Col>
            </Row>
            <Card>
                <CardBody>
                    {this.props.successMessage && (
                        <UncontrolledAlert color="success" data-testid="success-banner">
                            {this.props.successMessage}
                        </UncontrolledAlert>
                    )}
                    {this.props.errorMessage && (
                        <Alert color="danger" data-testid="error-banner">
                            {this.props.errorMessage}
                        </Alert>
                    )}
                    <Form>
                        <div className="mb-3">
                            <Row>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label className="form-label">{this.i18n.get(i18nKey.firstName)}</Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            data-testid="first-name-input"
                                            value={this.props.firstName}
                                            onChange={this.props.handleFirstNameChange}
                                            disabled={!this.props.editMode ? true : false}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label className="form-label">{this.i18n.get(i18nKey.lastName)}</Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            data-testid="last-name-input"
                                            value={this.props.lastName}
                                            onChange={this.props.handleLastNameChange}
                                            disabled={!this.props.editMode ? true : false}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label className="form-label">{this.i18n.get(i18nKey.emailAddress)}</Label>
                                    <Input
                                        type="email"
                                        className="form-control mt-2"
                                        data-testid="email-input"
                                        placeholder={this.props.email}
                                        disabled={true}
                                        readOnly={true}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label className="form-label mb-0">{this.i18n.get(i18nKey.password)}</Label>
                                    <button
                                        type="button"
                                        className="btn btn-link"
                                        data-testid="update-password-link"
                                        onClick={this.props.updatePasswordLinkClicked}
                                    >
                                        ({this.i18n.get(i18nKey.updatePassword)})
                                    </button>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        data-testid="password-input"
                                        placeholder="*************"
                                        disabled={true}
                                        readOnly={true}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div className="text-center mt-4">
                            {!this.props.editMode && (
                                <button
                                    type="button"
                                    className="btn btn-secondary btn-block pt-2 profile-button"
                                    data-testid="update-profile-button"
                                    onClick={this.props.editButtonClicked}
                                >
                                    {this.i18n.get(i18nKey.editProfile)}
                                </button>
                            )}
                            {this.props.editMode && (
                                <>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-block pt-2 m-2 profile-button"
                                        data-testid="cancel-update-profile-button"
                                        onClick={this.props.cancelButtonClicked}
                                    >
                                        {this.i18n.get(i18nKey.cancel)}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-block pt-2 m-2 profile-button"
                                        data-testid="save-update-profile-button"
                                        onClick={this.props.saveButtonClicked}
                                    >
                                        {this.i18n.get(i18nKey.save)}
                                    </button>
                                </>
                            )}
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </Container>
    );
    }
}