import { Dictionary } from './../../../global/TypeAliases'
import { injectable } from "inversify";
import { IElement, ItemValue, QuestionCheckboxModel, QuestionCommentModel, QuestionDropdownModel, QuestionMatrixModel, QuestionRadiogroupModel, QuestionRankingModel, QuestionRatingModel } from "survey-react";
import { Survey } from "../../../models/Survey";

export interface ISurveyResumer {
    resume(survey: Survey, surveyState: Dictionary): Survey
}

@injectable()
export class SurveyResumer implements ISurveyResumer {
    resume(survey: Survey, surveyState: Dictionary): Survey {
        if (survey.pages.length === 0) {
            return survey
        }

        if (surveyState.data === undefined) {
            return survey
        }

        const page = survey.pages[0]
        
        const keys = Object.keys(surveyState.data)
        keys.forEach(key => {
            const question = page.getElementByName(key)
            if (question) {
                this.restore(question, surveyState.data[key], surveyState.data)
            }
        })

        return survey
    }

    /**
     * Private Functions
     */

    private restore(element: IElement, value: any, surveyState: Dictionary) {
        if (element instanceof QuestionCommentModel) {
            (element as QuestionCommentModel).defaultValue = value
        } else if (element instanceof QuestionRankingModel) {
            let choices: ItemValue[] = []

            Object.values(value).forEach(val => {
                const item = element.choices.find(choice => { 
                    return choice.value === val
                })

                if (item !== undefined) {
                    choices.push(item)
                }
            })

            element.choices = choices
        } else if (element instanceof QuestionRadiogroupModel) {
            if (element.hasOther) { 
                const otherText: string = surveyState[`${element.name}-Comment`]

                if (otherText) {
                    (element as QuestionRadiogroupModel).defaultValue = otherText

                }
            } else {
                const selectedChoice = (element as QuestionRadiogroupModel).choices.find(choice => {
                    return choice.text === value
                })
                
                if (selectedChoice !== undefined) {
                    (element as QuestionRadiogroupModel).defaultValue = selectedChoice
                }
            }
        } else if (element instanceof QuestionCheckboxModel) {
            const selectedChoices: Dictionary = value as Dictionary
            let choices: ItemValue[] = []

            Object.values(selectedChoices).forEach(val => {               
                const result = element.choices.find(choice => { 
                    return val === choice.text
                })

                if (result !== undefined) {
                    choices.push(result)
                }
            })

            if (element.hasOther) {
                const otherText: string = surveyState[`${element.name}-Comment`]

                if (otherText) {
                    element.otherItem.value = otherText
                }

                choices.push(element.otherItem)
            }

            if (choices.length > 0) {
                (element as QuestionCheckboxModel).defaultValue = choices
            }
        } else if (element instanceof QuestionRatingModel) {
            element.defaultValue = value
        } else if (element instanceof QuestionMatrixModel) {
            element.defaultValue = value
        } else if (element instanceof QuestionDropdownModel) {
            if (element.hasOther) { 
                const otherText: string = surveyState[`${element.name}-Comment`]

                if (otherText) {
                    element.defaultValue = otherText
                }
            } else {
                element.defaultValue = value
            }
        }
    }
}