import React, { Component } from "react";
import { Stack, Text } from "@fluentui/react";
import { AuthenticationUIStyles, AuthenticationUITokens } from "./AuthenticationUI";
import { NavLink } from "react-router-dom";

export interface EmailVerificationSentPageProps {
    email: string
}

interface EmailVerificationSentPageState {}

export class EmailVerificationSentPage extends Component<EmailVerificationSentPageProps, EmailVerificationSentPageState> {
    componentDidMount() {
        document.getElementById('container')?.classList.add('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.add('hidden')
        document.getElementById('navigation-bar')?.classList.add('hidden')
    }

    componentWillUnmount() {
        document.getElementById('container')?.classList.remove('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.remove('hidden')
        document.getElementById('navigation-bar')?.classList.remove('hidden')
    }

    render() {
        const message = `A verification email has been sent to "${this.props.email}"`
        return (
            <div>
                <Stack horizontalAlign="center" styles={AuthenticationUIStyles.containerStyle}>
                    <Stack tokens={AuthenticationUITokens.verticalGapStackTokens} 
                            styles={AuthenticationUIStyles.emailVerifyStackStyles}>
                        <h4>{message}</h4>

                        <Stack tokens={AuthenticationUITokens.verticalGapStackTokens} 
                        styles={AuthenticationUIStyles.emailVerifyButtonStackStyles}>
                            <Text variant="medium">
							    <NavLink to="/login">Go to log in</NavLink>
						    </Text>                        
                        </Stack>
                    </Stack>
                </Stack>
            </div>
        )
    }
}
