import React, { Component } from "react";
import { Toggle, IToggleProps } from '@fluentui/react';

export class ToggleControl extends Component<IToggleProps> {
    render() {
        return (
            <Toggle 
                aria-label={this.props.ariaLabel}
                label={this.props.label}
                checked={this.props.checked}
                onText={this.props.onText}
                offText={this.props.offText}
                onChange={this.props.onChange}
            />
        )
    }
}
