import { FC } from "react";
import { 
    IconButton as FluentUIIconButton,
    IButtonProps,
    IIconStyles,
} from '@fluentui/react';
import { Theme } from "./Theme";

export const DestructiveIconButton: FC<IButtonProps> = (props) => {

    const deleteIconStyles: IIconStyles = {
        root: {
            color: Theme.destructiveColor.primary,
            fontSize: 20,
            justifyContent: 'center',
        }
    }

    const iconButtonProps: IButtonProps = {
        iconProps: {iconName: 'Delete', styles: deleteIconStyles},
    }

    return (
        <FluentUIIconButton 
            {...props}
            iconProps={iconButtonProps.iconProps}
        />  
    )
}