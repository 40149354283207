import { ContentType, IContent } from './IContent'
import { SurveyPage } from './../global/TypeAliases'
import { Dictionary, DocumentId } from '../global/TypeAliases'
import { SurveyFactory } from '../pages/admin/surveys/SurveyFactory'

export class Survey implements IContent {
	documentId: DocumentId
	lrsId: string
	name: string
	canonicalName: string
	description: string
	imageUrl: string
	locationUrl: string
	cohorts: Dictionary
	clients: Dictionary
	duration: string
	folderUrl: string
	pages: SurveyPage[]
	anonymous: boolean

	constructor(
		documentId: string,
		lrsId: string,
		name: string,
		canonicalName: string,
		description: string,
		imageUrl: string,
		locationUrl: string,
		cohorts: Dictionary,
		clients: Dictionary,
		duration: string,
		folderUrl: string,
		pages: SurveyPage[],
		anonymous: boolean
	) {
		this.documentId = documentId
		this.lrsId = lrsId
		this.name = name
		this.canonicalName = canonicalName
		this.description = description
		this.imageUrl = imageUrl
		this.locationUrl = locationUrl
		this.cohorts = cohorts
		this.clients = clients
		this.duration = duration
		this.folderUrl = folderUrl
		this.pages = pages
		this.anonymous = anonymous
	}

	contentType(): ContentType {
		return ContentType.survey
	}

	isNewSurvey(): boolean {
		return this.documentId.startsWith(SurveyFactory.NewSurveyPrefix)
	}

	static toData = (survey: Survey) => {
		let canonicalName: string = ""
		let pageData: Dictionary

		if (survey.name.startsWith(SurveyFactory.DuplicatePrefix)) {
			canonicalName = survey.canonicalName
			/**
			 * When we are duplicating a survey, pageData is already JSON
			 * and does not need to be converted unlike with a new survey
			 */
			pageData = survey.pages
		} else {
			canonicalName = survey.name.trim().toLowerCase().replace(/\s/g,'')
			pageData = survey.pages.map(page => {
				return page.toJSON()
			})
		}

		return {
			lrsId: survey.lrsId,
			imageUrl: survey.imageUrl,
			name: survey.name,
			canonicalName: canonicalName,
			description: survey.description,
			locationUrl: survey.locationUrl,
			cohorts: survey.cohorts,
			clients: survey.clients,
			duration: survey.duration,
			folderUrl: survey.folderUrl,
			pages: pageData,
			anonymous: survey.anonymous,
			documentId: survey.documentId,
			contentType: ContentType.survey
		}
	}

	static Converter = {
		toFirestore: function (survey: Survey) {
			return {
				documentId: survey.documentId,
				lrsId: survey.lrsId,
				imageUrl: survey.imageUrl,
				name: survey.name,
				canonicalName: survey.canonicalName,
				description: survey.description,
				locationUrl: survey.locationUrl,
				cohorts: survey.cohorts,
				clients: survey.clients,
				duration: survey.duration,
				folderUrl: survey.folderUrl,
				pages: survey.pages,
				anonymous: survey.anonymous,
				contentType: ContentType.survey
			}
		},
		fromFirestore: function (snapshot: any, options: any) {
			const data = snapshot.data(options)
			return new Survey(
				data.documentId,
				data.lrsId === undefined ? "" : data.lrsId,
				data.name,
				data.canonicalName,
				data.description,
				data.imageUrl === undefined ? "" : data.imageUrl,
				data.locationUrl === undefined ? "" : data.locationUrl,
				data.cohorts === undefined ? {}: data.cohorts,
				data.clients === undefined ? {}: data.clients,
				data.duration === undefined ? "" : data.duration,
				data.folderUrl === undefined ? "" : data.folderUrl,
				data.pages === undefined ? [] : data.pages,
				data.anonymous === undefined ? false : data.anonymous
			)
		}
	}

	public getDuration(): Dictionary {
		/**
		 * Empty duration means a duration has not been set on the survey
		*/
		if (this.duration === "") {
			return {
				min: 0,
				max: 0
			}
		}

		let min, max

		if (this.duration.includes("-")) {
			const components = this.duration.split(' ')

			min = components[0]
			max = components[2]	
		} else {
			const components = this.duration.split(' ')

			min = components[0]
			max = components[0]
		}

		return {
			min: parseInt(min),
			max: parseInt(max)
		}
	}
}