/* istanbul ignore file */
export class FirebaseUserMock implements firebase.User {
    displayName: string | null;
    email: string | null;
    phoneNumber: string | null;
    photoURL: string | null;
    providerId: string;
    uid: string;
    
    constructor() {
        this.uid = ""
        this.providerId = ""
        this.displayName = null
        this.email = null
        this.phoneNumber = null
        this.photoURL = null
    }

    delete(): Promise<void> {
        throw new Error("Method not implemented.");
    }
    emailVerified: boolean = false;
    getIdTokenResult(forceRefresh?: boolean | undefined): Promise<import("firebase").auth.IdTokenResult> {
        throw new Error("Method not implemented.");
    }
    getIdToken(forceRefresh?: boolean | undefined): Promise<string> {
        throw new Error("Method not implemented.");
    }
    isAnonymous: boolean = false;
    linkAndRetrieveDataWithCredential(credential: import("firebase").auth.AuthCredential): Promise<import("firebase").auth.UserCredential> {
        throw new Error("Method not implemented.");
    }
    linkWithCredential(credential: import("firebase").auth.AuthCredential): Promise<import("firebase").auth.UserCredential> {
        throw new Error("Method not implemented.");
    }
    linkWithPhoneNumber(phoneNumber: string, applicationVerifier: import("firebase").auth.ApplicationVerifier): Promise<import("firebase").auth.ConfirmationResult> {
        throw new Error("Method not implemented.");
    }
    linkWithPopup(provider: import("firebase").auth.AuthProvider): Promise<import("firebase").auth.UserCredential> {
        throw new Error("Method not implemented.");
    }
    linkWithRedirect(provider: import("firebase").auth.AuthProvider): Promise<void> {
        throw new Error("Method not implemented.");
    }
    metadata!: import("firebase").auth.UserMetadata;
    multiFactor!: import("firebase").User.MultiFactorUser;
    providerData: (import("firebase").UserInfo | null)[] = [];
    reauthenticateAndRetrieveDataWithCredential(credential: import("firebase").auth.AuthCredential): Promise<import("firebase").auth.UserCredential> {
        throw new Error("Method not implemented.");
    }
    reauthenticateWithCredential(credential: import("firebase").auth.AuthCredential): Promise<import("firebase").auth.UserCredential> {
        throw new Error("Method not implemented.");
    }
    reauthenticateWithPhoneNumber(phoneNumber: string, applicationVerifier: import("firebase").auth.ApplicationVerifier): Promise<import("firebase").auth.ConfirmationResult> {
        throw new Error("Method not implemented.");
    }
    reauthenticateWithPopup(provider: import("firebase").auth.AuthProvider): Promise<import("firebase").auth.UserCredential> {
        throw new Error("Method not implemented.");
    }
    reauthenticateWithRedirect(provider: import("firebase").auth.AuthProvider): Promise<void> {
        throw new Error("Method not implemented.");
    }
    refreshToken!: string;
    reload(): Promise<void> {
        throw new Error("Method not implemented.");
    }
    sendEmailVerification(actionCodeSettings?: import("firebase").auth.ActionCodeSettings | null | undefined): Promise<void> {
        throw new Error("Method not implemented.");
    }
    tenantId!: string | null;
    toJSON(): Object {
        throw new Error("Method not implemented.");
    }
    unlink(providerId: string): Promise<import("firebase").User> {
        throw new Error("Method not implemented.");
    }
    updateEmail(newEmail: string): Promise<void> {
        throw new Error("Method not implemented.");
    }
    updatePassword(newPassword: string): Promise<void> {
        throw new Error("Method not implemented.");
    }
    updatePhoneNumber(phoneCredential: import("firebase").auth.AuthCredential): Promise<void> {
        throw new Error("Method not implemented.");
    }
    updateProfile(profile: { displayName?: string | null | undefined; photoURL?: string | null | undefined; }): Promise<void> {
        throw new Error("Method not implemented.");
    }
    verifyBeforeUpdateEmail(newEmail: string, actionCodeSettings?: import("firebase").auth.ActionCodeSettings | null | undefined): Promise<void> {
        throw new Error("Method not implemented.");
    }
}
