import React, { Component } from "react";
import { 
    Dropdown as FluentUIDropdown, 
    IDropdownProps,
    IDropdownStyles
} from '@fluentui/react';
import { Theme } from './Theme'
import { Dictionary } from "../global/TypeAliases";

export interface DropDownProps extends IDropdownProps {
    margin?: string
}

export class Dropdown extends Component<DropDownProps> {
    height = "50px"

    render() {
        let defaultStyle: Dictionary = {
            title: {
                height: this.height,
                borderColor: Theme.accent.primary,
                padding: "10px"
            },
            dropdownItem: {
                height: this.height
            },
            dropdownItemSelected: {
                height: this.height
            }
        }

        if (this.props.margin) {
            defaultStyle["root"] = {
                margin: this.props.margin
            }
        }

        const style: Partial<IDropdownStyles> = defaultStyle
        
        return (
            <FluentUIDropdown {...this.props} styles={style} />
        )
    }
}
