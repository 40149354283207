import { IEventReportingService } from './../services/EventReportingService'
import { injectable } from "inversify"
import { container } from '../DIContainer';
import { TYPES } from '../Types';

export enum LoadType {
    Course = "Course Load Time"
}

export interface ILoadTimer {
    /**
     * Start a timer for a specific type
     * @param type The timer type
     * @param id An id that'll be appended to the log title e.g. Course Load Time - UID123
     */
    start(type: LoadType, id: string): void

    /**
     * Stop a timer for a specific type
     * @param type The timer type
     */
    stop(type: LoadType): void
}

@injectable()
export class LoadTimer implements ILoadTimer {
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)
    private timer: NodeJS.Timeout | null = null
    private minute = 1000 // 1 second
    private timerType: LoadType | null = null
    private elapsedTime: number = 0
    private id: string | null = null
    private loadTimeThreshold = 5 // 5 seconds

    start(type: LoadType, id: string | null): void {
        this.reset()
        this.timer = setInterval(this.timerHandler, this.minute)
        this.timerType = type
        this.id = id
    }

    stop(): void {
        if (!this.timerType || this.elapsedTime <= this.loadTimeThreshold) {
            this.reset()
            return
        }

        const data = {
            elapsedTime: this.elapsedTime
        }

        let title: string = this.timerType
        if (this.id) {
            title = title + " - " + this.id
        }

        this.eventReportingService.info(title, data)
        this.reset()
    }

    /**
     * Private Functions
     */

    /**
     * Callback for the timer that will increment elapsed time by 1 second
     */
    private timerHandler = () => {
        this.elapsedTime += 1
    }

    /**
     * Reset all values so a new timer can be started
     */
    private reset = () => {
        if (this.timer) {
            clearInterval(this.timer)
        }

        this.timer = null
        this.timerType = null
        this.elapsedTime = 0
        this.id = null
    }
}
