import React, { Component } from "react"
import {
	Stack,
	IStackTokens,
	Spinner,
	MessageBar,
	MessageBarType,
} from "@fluentui/react"
import { container } from "../../../DIContainer"
import { ILearningPlanService } from "../../../services/LearningPlanService"
import { TYPES } from "../../../Types"
import { TextField } from "../../../ui-kit/TextField"
import { LearningPlanEditor } from "../../../ui-kit/LearningPlanEditor"
import { DocumentId } from "../../../global/TypeAliases"
import { PrimaryButton } from "../../../ui-kit/PrimaryButton"
import PageTitle from "../../../ui-kit/PageTitle"
import { IContent } from "../../../models/IContent"
import { i18nKey, Ii18n } from "../../../global/i18n"

interface AddNewLearningPlanProps {
	clientId: DocumentId
}

interface AddNewLearningPlanState {
	name: string
	description: string
	contents: IContent[]
	primaryButtonDisabled: boolean
	showSpinner: boolean
	errorMessage: string | null
	successMessage: string | null
}

const stackTokens: IStackTokens = {
	childrenGap: "20px",
}

export class AddNewLearningPlan extends Component<AddNewLearningPlanProps, AddNewLearningPlanState> {
	private learningPlanService: ILearningPlanService = container.get<ILearningPlanService>(TYPES.ILearningPlanService)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
	
	state: AddNewLearningPlanState = {
		name: "",
		description: "",
		contents: [],
		primaryButtonDisabled: true,
		showSpinner: false,
		errorMessage: null,
		successMessage: null
	}

	render() {
		return (
			<>
				<PageTitle title="Add New Learning Plan" />
				<div className="container">
					<Stack tokens={stackTokens} className="learning-plan-stack">
						{ this.state.successMessage &&
							<MessageBar
								id="add-learning-plan-success-banner"
								messageBarType={MessageBarType.success}
								dismissButtonAriaLabel="Close"
								onDismiss={this.dismissBanner}
							>
								{this.state.successMessage}
							</MessageBar>
						}

						{ this.state.errorMessage &&
							<MessageBar
								id="add-learning-plan-error-banner"
								messageBarType={MessageBarType.error}
								dismissButtonAriaLabel="Close"
								onDismiss={this.dismissBanner}
							>
								{this.state.errorMessage}
							</MessageBar>
						}

						<TextField
							id="add-learning-plan-name-textfield"
							label="Name"
							onChange={this.nameTextFieldChanged}
							value={this.state.name}
						/>
						<TextField
							id="add-learning-plan-description-textfield"
							label="Description"
							onChange={this.descriptionTextFieldChanged}
							value={this.state.description}
						/>
						
						<LearningPlanEditor 
							clientId={this.props.clientId} 
							initialContents={[]} 
							contentsDidUpdate={this.contentsDidUpdate}
						/>

						{ this.state.showSpinner && 
							<Spinner
								label="Adding learning plan..."
								ariaLive="assertive"
								labelPosition="right"
							/>
						}
							
						{ !this.state.showSpinner && 
							<PrimaryButton
								id="add-learning-plan-primary-button"
								text={this.i18n.get(i18nKey.learningPlanAddNew)}
								onClick={this.primaryButtonClicked}
								disabled={this.state.primaryButtonDisabled}
							/>
						}						
					</Stack>
				</div>
			</>
		)
	}

	private nameTextFieldChanged = (
		_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return;
		}

		this.setState({ name: newValue }, () => {
			this.enablePrimaryButtonIfNeeded();
		});
	}

	private descriptionTextFieldChanged = (
		_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return;
		}

		this.setState({ description: newValue }, () => {
			this.enablePrimaryButtonIfNeeded();
		});
	}

	private enablePrimaryButtonIfNeeded = () => {
		let disable = true

		if (this.state.name.length > 0 && 
			this.state.description.length > 0 && 
			this.state.contents.length > 0) {
			disable = false
		}

		this.setState({ primaryButtonDisabled: disable })
	}

	private primaryButtonClicked = (_: React.MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: null,
			successMessage: null,
			showSpinner: true
		})

		this.learningPlanService
			.addLearningPlan(this.props.clientId, this.state.name, this.state.description, this.state.contents)
			.then(() => {
				this.setState({ 
					successMessage: this.state.name + " has been added", 
					showSpinner: false 
				})
			}).catch(error => {
				this.setState({ 
					errorMessage: "Failed to add the learning plan. Please try again.", 
					showSpinner: false 
				})
			})
	}

	dismissBanner = () => {
		this.setState({ errorMessage: null, successMessage: null })
	}
	
	/**
	 * Learning Plan Editor callbacks
	 */

	contentsDidUpdate = (contents: IContent[]) => {
		this.setState({ contents: contents }, () => {
			this.enablePrimaryButtonIfNeeded()
		})
	}
}
