import { PageModel } from 'survey-react'
import { Dictionary, DocumentId } from './../global/TypeAliases';
import { Survey } from './Survey';
import { injectable } from 'inversify';

export interface ISurveyParser {
    parse(documentId: DocumentId, data: Dictionary): Survey
}

@injectable()
export class SurveyParser implements ISurveyParser {    
    parse(documentId: DocumentId, data: Dictionary): Survey {        
        let pagesOfQuestions: PageModel[] = []
        data.pages.forEach((page: Dictionary) => {
            const pageModel = new PageModel(page.name)
            pageModel.fromJSON(page)
            pagesOfQuestions.push(pageModel)
        })
        
        return new Survey(
            documentId, 
            data.lrsId, 
            data.name, 
            data.canonicalName, 
            data.description, 
            data.imageUrl, 
            data.locationUrl, 
            data.cohorts, 
            data.clients, 
            data.duration, 
            data.folderUrl, 
            pagesOfQuestions,
            data.anonymous
        )
    }
}
