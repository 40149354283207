import { Component, MouseEvent } from "react"
import { ICohortService } from "../../../services/CohortService"
import {
	Stack,
	IStackTokens,
	Spinner,
	MessageBar,
	MessageBarType,
} from "@fluentui/react"
import { TabPanelProps } from "../TabPanelProps"
import "./RemoveLearnerFromCohort.css"
import DTSTable from "../../../ui-kit/DTSTable"
import DTSTableData from "../../../models/DTSTableData"
import IDataTableFactory from "../../../factories/DataTableFactory"
import { container } from "../../../DIContainer"
import { TYPES } from "../../../Types"
import { log } from "../../../global/Logger"
import { Loader } from "../../../ui-kit/Loader"
import { i18nKey, Ii18n } from "../../../global/i18n"
import { DestructiveButton } from "../../../ui-kit/DestructiveButton"

interface RemoveLearnerFromCohortProps extends TabPanelProps {
	documentId: string
}

interface RemoveLearnerFromCohortState {
	loadingData: boolean
	tableData: DTSTableData | undefined
	primaryButtonDisabled: boolean
	showSpinner: boolean
	errorMessage: string | undefined
	successMessage: string | undefined
    selectedUsers: string[]
    clearSelectedRows: boolean
}

const stackStyling: IStackTokens = {
	childrenGap: "20px",
}

export default class RemoveLearnerFromCohort extends Component<
	RemoveLearnerFromCohortProps,
	RemoveLearnerFromCohortState
> {
	private cohortService: ICohortService = container.get<ICohortService>(TYPES.ICohortService)
	private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	state: RemoveLearnerFromCohortState = {
		loadingData: false,
		tableData: undefined,
		primaryButtonDisabled: true,
		showSpinner: false,
		errorMessage: undefined,
		successMessage: undefined,
		selectedUsers: [],
		clearSelectedRows: false
	}

	componentDidMount() {
		this.loadData()
	}

	render() {
		if (this.state.loadingData) {
			return <Loader />
		}
		
		let banner = null
		if (this.state.errorMessage) {
			banner = (
				<MessageBar
					id="remove-learner-error-banner"
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.errorMessage}
				</MessageBar>
			)
		} else if (this.state.successMessage) {
			banner = (
				<MessageBar
					id="remove-learner-success-banner"
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			)
		}

		return (
			<div id="remove-learner-to-cohort-modal-container">
				<Stack tokens={stackStyling}>
					{this.state.tableData !== undefined && (
						<div id="remove-learner-cohort-table">
							<Stack tokens={stackStyling}>
								<DTSTable
									data={this.state.tableData}
									pagination={true}
                                    onSelectedRowsChange={this.onSelectedRowsChange}
									clearSelectedRows={this.state.clearSelectedRows}
									filter={true}
									filterPlaceholder="Search for a learner"		
								/>
								
								<DestructiveButton
									id="remove-learner-primary-button"
									text={this.i18n.get(i18nKey.removeLearners)}
									onClick={this.primaryButtonClicked}
									disabled={this.state.primaryButtonDisabled}
									hidden={this.state.showSpinner}
								/>

								{ this.state.showSpinner &&
									<Spinner
										id="remove-learner-spinner"
										label="Removing..."
										ariaLive="assertive"
										labelPosition="right"
									/>
								}
							</Stack>
						</div>
					)}

					{banner}
				</Stack>
			</div>
		)
	}

    onSelectedRowsChange = (selectedRowState: {
		allSelected: boolean
		selectedCount: number
		selectedRows: { [key: string]: any }[]
	}) => {
        if (selectedRowState.selectedCount === 0) {
			this.setState({ selectedUsers: [] }, () => {
				this.enablePrimaryButtonIfNeeded()
			})
			return
        }
        
		const selectedIds = selectedRowState.selectedRows.map((row) => {
			return row.id
        })
        
		log(selectedIds.length + " learners selected")

		this.setState({ selectedUsers: selectedIds }, () => {
			this.enablePrimaryButtonIfNeeded()
		})
	}

	private loadData = () => {
		this.setState({ loadingData: true })

		this.cohortService
			.learnersForCohort(this.props.documentId)
			.then((users) => {
				const data = this.dataTableFactory.createCohortSearchTableData(users)
				this.setState({ loadingData: false, tableData: data, clearSelectedRows: false })
			})
			.catch(error => {
				this.setState({ loadingData: false, errorMessage: error.message })
			})
	}

	dismissBanner = () => {
		this.setState({ errorMessage: undefined, successMessage: undefined })
	}

	primaryButtonClicked = (e: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: undefined,
			successMessage: undefined,
			showSpinner: true,
			primaryButtonDisabled: true,
		})

		this.cohortService
			.removeLearnersFromCohort(this.state.selectedUsers, this.props.documentId)
			.then(() => {
				let message: string
				if (this.state.selectedUsers.length === 1) {
					message = "1 learner removed"
				} else {
					message = this.state.selectedUsers.length + " learners removed"
				}

				this.setState({
					successMessage: message,
					showSpinner: false,
					primaryButtonDisabled: true,
                    selectedUsers: [],
                    clearSelectedRows: true
				})

				this.loadData()
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showSpinner: false,
					primaryButtonDisabled: false,
				})
			})
	}

	enablePrimaryButtonIfNeeded = () => {
		let disable = true

		if (this.state.selectedUsers.length > 0) {
			disable = false
		}

		this.setState({ primaryButtonDisabled: disable })
	}
}
