import { injectable } from "inversify";
import { container } from "../DIContainer";
import Course from "../models/Course";
import { TYPES } from "../Types";
import { IEventReportingService } from '../services/EventReportingService'
import { ICourseService } from "./CourseService";
import { IClientService } from "./ClientService";
import { LearningPlan } from "../models/LearningPlan";
import Client from "../models/Client";
import { ILearningPlanService } from "./LearningPlanService";
import { Survey } from "../models/Survey";
import { ISurveyService } from "./SurveyService";

export interface ICohortsContentsPageCompositionService {
    loadData(cohortDocumentId: string): Promise<ICohortsContentsPageData>
}

export interface ICohortsContentsPageData {
    learningPlans: LearningPlan[]
    courses: Course[]
    clients: Client[]
    surveys: Survey[]
}


@injectable()
export class CohortsContentsPageCompositionService implements ICohortsContentsPageCompositionService {
    private learningPlanService: ILearningPlanService = container.get<ILearningPlanService>(TYPES.ILearningPlanService)
    private courseService: ICourseService = container.get<ICourseService>(TYPES.ICourseService)
    private clientService: IClientService = container.get<IClientService>(TYPES.IClientService)
    private surveyService: ISurveyService = container.get<ISurveyService>(TYPES.ISurveyService)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)

    loadData(cohortDocumentId: string): Promise<ICohortsContentsPageData> {
        let promiseArray: Promise<any>[] = []

        promiseArray.push(this.learningPlanService.learningPlansForCohort(cohortDocumentId))
        promiseArray.push(this.courseService.coursesForCohort(cohortDocumentId))
        promiseArray.push(this.surveyService.surveysForGroup(cohortDocumentId))
        promiseArray.push(this.clientService.clients())

        return new Promise((resolve, reject) => {
            Promise.all(promiseArray)
                    .then(result => {
                        resolve({ learningPlans: result[0], courses: result[1], surveys: result[2], clients: result[3] })
                    })
                    .catch(error => {
                        this.eventReportingService.error(error.message, error)
                        reject(error)
                    })
        })
    }
}