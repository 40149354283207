import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import { IAuth } from "../auth/Auth"
import { container } from "../DIContainer"
import { TYPES } from "../Types"
import { IAuthObserver } from "../auth/Auth"
import IAuthService from "../services/AuthService"

import dashboardIcon from "../images/navigation-bar/icon-dashboard.svg"
import learnersIcon from "../images/navigation-bar/icon-user.svg"
import contentsIcon from "../images/navigation-bar/icon-compass.svg"
import profileIcon from "../images/navigation-bar/icon-profile.svg"
import logoutIcon from "../images/navigation-bar/icon-follow.svg"
import reportIcon from "../images/navigation-bar/icon-report.svg"
import clientsIcon from "../images/navigation-bar/icon-clients.svg"
import globalStatsIcon from "../images/navigation-bar/icon-global-stats.svg"
import logInAsIcon from "../images/navigation-bar/icon-log-in-as.svg"
import manageInvitationsIcon from "../images/navigation-bar/icon-manage-invitations.svg"

import './Header.css'
import './DestructiveButton.css'

import { IAccessController } from '../services/AccessController'
import { Stack, Text, Image } from '@fluentui/react'
import defaultProfileImage from "../images/default-profile.png"
import ProfileImage from './ProfileImage'
import logoLarge from "../images/logo-large2.svg"
import { FontSize } from './FontSize'
import { Subject } from 'rxjs'
import User from '../models/User'
import { IRouter } from '../Router'
import { PrimaryButton } from './PrimaryButton'
import { log } from '../global/Logger'
import { PathNames } from '../PathNames'
import { DocumentId } from '../global/TypeAliases'
import { ErrorDialog } from './ErrorDialog'
import { i18nKey, Ii18n } from '../global/i18n'
import { ClientContextSelector } from './ClientContextSelector'
import { MobileHeader } from '../ui-kit/MobileHeader'

interface HeaderProps {
    firebaseUserObservable: Subject<firebase.User | null>
    userObservable: Subject<User | null>
}

interface HeaderState {
    user: User | undefined
    firebaseUser: firebase.User | null
    clientId: DocumentId | null
    loggedIn: boolean
    showLogInAsCallout: boolean
    showClientContextDialog: boolean
    showErrorDialog: boolean
    isMobileView: boolean
}

const MOBILE_SCREEN_WIDTH = 600

export class Header extends Component<HeaderProps, HeaderState> implements IAuthObserver {
    private authService: IAuthService = container.get<IAuthService>( TYPES.IAuthService)
    private auth: IAuth = container.get<IAuth>(TYPES.IAuth)
    private acl: IAccessController = container.get<IAccessController>(TYPES.IAccessController)
    private router: IRouter = container.get<IRouter>(TYPES.IRouter)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    
    state: HeaderState = {
        user: this.auth.user,
        firebaseUser: this.auth.firebaseUser(),
        clientId: this.auth.getClientContext(),
        loggedIn: this.auth.isLoggedIn(),
        showLogInAsCallout: false,
        showClientContextDialog: false,
        showErrorDialog: false,
        isMobileView: window.innerWidth <= MOBILE_SCREEN_WIDTH ? true : false,
    }

    componentDidMount() {
        window.addEventListener('resize', this.checkWindowChange)

        this.auth.addAuthObserver(this)
    
        this.props.firebaseUserObservable.subscribe(firebaseUser => {
            this.setState({ firebaseUser: firebaseUser })
        })
        
        this.props.userObservable.subscribe(user => {
            if (user !== null) {
                this.setState({ user: user })
            }
        })
    }

    render() {
        const user = this.state.user
        if (user === undefined) {
            return null
        }

        let navigationLinks

        if (this.state.loggedIn && this.acl.canAdministrate()) {
            navigationLinks = (
                <>
                    <NavLink to={PathNames.Dashboard}>
                        <img src={dashboardIcon} alt="dashboard-icon" className="nav-icon" />
                        Dashboard
                    </NavLink>
                    <NavLink to={PathNames.Profile}>
                        <img src={profileIcon} alt="profile-icon" className="nav-icon" />Profile
                    </NavLink>

                    <Text className="navigation-section-header" variant={FontSize.medium}>Admin</Text>
                    <NavLink to={PathNames.Learners}>
                        <img src={learnersIcon} alt="learners-icon" className="nav-icon" />
                        Learners
                    </NavLink>
                    <NavLink to={PathNames.Contents}>
                        <img src={contentsIcon} alt="contents-icon" className="nav-icon" />
                        Content
                    </NavLink>
                    <NavLink to={PathNames.Reports}>
                        <img src={reportIcon} alt="reports-icon" className="nav-icon" />
                        Reports
                    </NavLink>

                    { this.acl.isGlobalAdmin() &&
                        <> 
                            <Text className="navigation-section-header" variant={FontSize.medium}> Global Admin</Text>
                            <NavLink to="/clients">
                                <img src={clientsIcon} alt="learners-icon" className="admin-nav-icon" />
                                Clients
                            </NavLink>
                            <NavLink to="/global-stats">
                                <img src={globalStatsIcon} alt="reports-icon" className="admin-nav-icon" />
                                Global Stats
                            </NavLink>
                            <NavLink to={PathNames.ManageInvitations}>
                                <img src={manageInvitationsIcon} alt="reports-icon" className="admin-nav-icon" />
                                Guest Codes
                            </NavLink>
                        </>
                    }

                    { this.acl.canLogInAsUser() &&
                        <NavLink to="/logInAs">
                            <img src={logInAsIcon} alt="logInAs-icon" className="admin-nav-icon" />
                            Log In As
                        </NavLink>
                    }
                    
                    <NavLink id="logout-link" to={PathNames.Login} onClick={this.onLogoutClick}>
                        <img src={logoutIcon} alt="logout-icon" className="nav-icon" />
                        Logout
                    </NavLink>
                </>
            )
        } else if (this.state.loggedIn && !this.acl.canAdministrate()) {
            navigationLinks = (
                <>
                    <NavLink to="/dashboard">
                        <img src={dashboardIcon} alt="dashboard-icon" className="nav-icon" />
                        Dashboard
                    </NavLink>
                    <NavLink to="/profile">
                        <img src={profileIcon} alt="profile-icon" className="nav-icon" />Profile
                    </NavLink>
                    <NavLink id="logout-link" to={PathNames.Login} onClick={this.onLogoutClick}>
                        <img src={logoutIcon} alt="logout-icon" className="nav-icon" />
                        Logout
                    </NavLink>
                </>
            )
        } else {
            navigationLinks = (
                <NavLink to="/login">
                    <img src={logoutIcon} alt="login-icon" className="nav-icon" />
                    Login
                </NavLink>
            )
        }

        let profileImageURLString = defaultProfileImage
        const fbUser = this.state.firebaseUser
		if (fbUser !== null && fbUser.photoURL !== null) {
			profileImageURLString = fbUser.photoURL
        }
                
        return (
            <div id="navigation-bar">
                { this.state.isMobileView &&
                    <MobileHeader 
                        clientId={this.state.clientId}
                    />
                }
                { !this.state.isMobileView && 
                    <Stack horizontalAlign="center" tokens={{ childrenGap: 30 }}>
                        <Stack id="navigation-logo-stack">
                            <Image src={logoLarge} alt="DTS Logo" width={150} height={100} />
                        </Stack>
                        <NavLink to="/profile" id="profile-image-and-name-navlink">
                            <Stack
                                horizontalAlign="center"
                                horizontal={false} 
                                tokens={{ childrenGap: 10 }} 
                            >
                                <ProfileImage 
                                    urlString={profileImageURLString} 
                                    width={75} 
                                    height={75} 
                                />
                                <Text variant={FontSize.large} className="navigation-profile-name">{user.firstName}</Text>
                                { !this.acl.canSelectClientContext() && 
                                    <Text id='role-name-label' variant={FontSize.small} className="navigation-profile-role">{user.roleName}</Text>
                                }
                            </Stack>
                        </NavLink>
                        { this.acl.canSelectClientContext() &&
                            <ClientContextSelector clientId={this.state.clientId} />
                        }
                        <Stack>
                            <ul id="navigation-links">
                                {navigationLinks}
                            </ul>
                            
                            { this.auth.isLogInAsActive() &&
                                <PrimaryButton
                                    className="destructive-button" 
                                    id="endLogInAsButton" 
                                    text={this.i18n.get(i18nKey.endLogInAs)}
                                    onClick={this.endLogInAsClicked}
                                />
                            }
                        </Stack>
                    </Stack>
                }
                
                { this.state.showErrorDialog &&
                    <ErrorDialog 
                        title={this.i18n.get(i18nKey.error)} 
                        message={this.i18n.get(i18nKey.failedToEndLogInAs)} 
                        onClick={this.errorDialogOnClick} 
                    />
                }
            </div>
        )
    }

    onAuthStateChange = (isLoggedIn: boolean) => {
        log(`Header - isLoggedIn - ${isLoggedIn}`)        
        this.setState({
            loggedIn: isLoggedIn,
            user: this.auth.user,
            firebaseUser: this.auth.firebaseUser()
        })
    }

    checkWindowChange = () => {
        if (window.innerWidth <= MOBILE_SCREEN_WIDTH) {
            this.setState({ isMobileView: true })
        } else {
            this.setState({ isMobileView: false })
        }
    }

    onLogoutClick = () => {
        this.authService.logout().then(() => {
            this.router.goToLogin()
        }).catch(_ => {
            /**
             * Silent fail
             */
        })
    }

    errorDialogOnClick = () => {
        this.setState({ showErrorDialog: false })
    }

    private endLogInAsClicked = () => {
        this.auth
            .turnOffLogInAs()
            .then(() => {
                this.router.refreshPage()
            }).catch(_ => {
                this.setState({ showErrorDialog: true })
            })
    }
} 