/* istanbul ignore file */
import { injectable } from "inversify"
import { ENVIRONMENT, IFirebase } from "./Firebase"
import { container } from "../DIContainer"
import { TYPES } from "../Types"

export enum FeatureFlag {
    cleverSSO = 'CleverSSOEnabled',
    deadline = 'DeadlineEnabled',
    learningPlansPage = 'LearningPlansPage',
    markAsComplete = 'MarkAsCompleteEnabled',
    onboardingRegistrationReminder = 'RegistrationReminder',
    selectClientContext = 'SelectClientContextEnabled',
    signInWithClever = 'SignInWithClever',
    surveys = 'SurveysEnabled',
    resendRegistrationReminder = 'ResendRegistrationReminder',
    enforceLearnerCount = 'EnforceLearnerCount',
    anonymousSurveyReporting = 'AnonymousSurveyReporting',
    phoenixRedesign = 'PhoenixRedesign',
    mailServiceV2 = 'MailServiceV2',
    onboardingV2 = "OnboardingV2"
}

export const RemoteDefaultConfig = {
    local: {
        [FeatureFlag.cleverSSO]: true,
        [FeatureFlag.deadline]: true,
        [FeatureFlag.learningPlansPage]: true,
        [FeatureFlag.markAsComplete]: true,
        [FeatureFlag.onboardingRegistrationReminder]: true,
        [FeatureFlag.selectClientContext]: true,
        [FeatureFlag.signInWithClever]: true,
        [FeatureFlag.surveys]: true,
        [FeatureFlag.resendRegistrationReminder]: true,
        [FeatureFlag.enforceLearnerCount]: true,
        [FeatureFlag.anonymousSurveyReporting]: true,
        [FeatureFlag.phoenixRedesign]: true,
        [FeatureFlag.mailServiceV2]: true,
        [FeatureFlag.onboardingV2]: true
    },
    dev: {
        [FeatureFlag.cleverSSO]: true,
        [FeatureFlag.deadline]: true,
        [FeatureFlag.learningPlansPage]: true,
        [FeatureFlag.markAsComplete]: true,
        [FeatureFlag.onboardingRegistrationReminder]: true,
        [FeatureFlag.selectClientContext]: true,
        [FeatureFlag.signInWithClever]: true,
        [FeatureFlag.surveys]: true,
        [FeatureFlag.resendRegistrationReminder]: true,
        [FeatureFlag.enforceLearnerCount]: true,
        [FeatureFlag.anonymousSurveyReporting]: true,
        [FeatureFlag.phoenixRedesign]: false,
        [FeatureFlag.mailServiceV2]: true,
        [FeatureFlag.onboardingV2]: true
    },
    prod: {
        [FeatureFlag.cleverSSO]: true,
        [FeatureFlag.deadline]: false,
        [FeatureFlag.learningPlansPage]: false,
        [FeatureFlag.markAsComplete]: true,
        [FeatureFlag.onboardingRegistrationReminder]: true,
        [FeatureFlag.selectClientContext]: true,
        [FeatureFlag.signInWithClever]: true,
        [FeatureFlag.surveys]: true,
        [FeatureFlag.resendRegistrationReminder]: false,
        [FeatureFlag.enforceLearnerCount]: false,
        [FeatureFlag.anonymousSurveyReporting]: false,
        [FeatureFlag.phoenixRedesign]: false,
        [FeatureFlag.mailServiceV2]: false,
        [FeatureFlag.onboardingV2]: false
    }
}

export interface IFeatureFlagService {
    cleverSSO(): boolean
    deadline(): boolean
    learningPlansPage(): boolean
    markAsComplete(): boolean
    onboardingRegistrationReminder(): boolean
    selectClientContext(): boolean
    signInWithClever(): boolean
    deadline(): boolean
    onboardingRegistrationReminder(): boolean
    learningPlansPage(): boolean
    surveysFeature(): boolean
    resendRegistrationReminder(): boolean
    anonymousSurveyReporting(): boolean
    mailServiceV2(): boolean
    phoenixRedesign(): boolean
    onboardingV2(): boolean
}

@injectable()
export class FeatureFlagService implements IFeatureFlagService {  
    private firebase: IFirebase = container.get<IFirebase>(TYPES.IFirebase)

    /**
     * Public Functions
     */

    cleverSSO(): boolean {
        return this.getFeatureFlagValueFor(FeatureFlag.cleverSSO)
    }

    surveysFeature(): boolean {
        return this.getFeatureFlagValueFor(FeatureFlag.surveys)
    }

    markAsComplete(): boolean {
        return this.getFeatureFlagValueFor(FeatureFlag.markAsComplete)
    }

    selectClientContext(): boolean {
        return this.getFeatureFlagValueFor(FeatureFlag.selectClientContext)
    }
    
    signInWithClever(): boolean {
        return this.getFeatureFlagValueFor(FeatureFlag.signInWithClever)
    }

    deadline(): boolean {
        return this.getFeatureFlagValueFor(FeatureFlag.deadline)
    }

    onboardingRegistrationReminder(): boolean {
        return this.getFeatureFlagValueFor(FeatureFlag.onboardingRegistrationReminder)
    }

    learningPlansPage(): boolean { 
        return this.getFeatureFlagValueFor(FeatureFlag.learningPlansPage)
    }

    resendRegistrationReminder(): boolean {
        return this.firebase.remoteConfig.getBoolean(FeatureFlag.resendRegistrationReminder)
    }

    enforceLearnerCount(): boolean {
        return this.firebase.remoteConfig.getBoolean(FeatureFlag.enforceLearnerCount)
    }

    anonymousSurveyReporting(): boolean {
        return this.firebase.remoteConfig.getBoolean(FeatureFlag.anonymousSurveyReporting)
    }

    phoenixRedesign(): boolean {
        return this.firebase.remoteConfig.getBoolean(FeatureFlag.phoenixRedesign)
    }

    mailServiceV2(): boolean {
        return this.firebase.remoteConfig.getBoolean(FeatureFlag.mailServiceV2)
    }
    
    onboardingV2(): boolean {
        return this.firebase.remoteConfig.getBoolean(FeatureFlag.onboardingV2)
    }

    /**
     * Private Functions
     */

    private getFeatureFlagValueFor(feature: FeatureFlag): boolean {
        if (!this.firebase.remoteConfig) {
            /**
             * We failed to get the remote config file from Firebase. This happens
             * when the user is incognito (Chrome) or private (Safari, Firefox). So
             * we load fallback values that we define in RemoteDefaultConfig.
             */
            let value = false

            switch (process.env.REACT_APP_ENV) {
                case ENVIRONMENT.prod:
                    value = RemoteDefaultConfig.prod[feature]
                    break
                case ENVIRONMENT.dev:
                    value = RemoteDefaultConfig.dev[feature]
                    break
                default:
                    value = RemoteDefaultConfig.local[feature]
            }

            return value
        }

        return this.firebase.remoteConfig.getBoolean(feature)
    }
}