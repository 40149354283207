import { IImageStyles, IStackStyles, IStackTokens } from "@fluentui/react"

export class SurveyInteractionPanelStyles {
    static imageDimensions: Partial<IImageStyles> = {
        image: {
            width: 20,
            height: 20
        }
    }

    static surveyStyles: Partial<IStackStyles> = {
        root: { 
            backgroundColor: '#ffffff', 
            padding: 25, 
            borderRadius: 10 
        }
    }

    static surveyInteractionTokens: Partial<IStackTokens> = {
        childrenGap: 10
    }
    
    static defaultStackTokens: Partial<IStackTokens> = {
        childrenGap: 20
    }
}