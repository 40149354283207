import React, { Component } from "react";
import { 
    PrimaryButton as FluentUIPrimaryButton,
    IButtonProps,
    IButtonStyles,
} from '@fluentui/react';
import { Dictionary } from "../global/TypeAliases";

export class PrimaryButton extends Component<IButtonProps> {
    height = 40
    cornerRadius = 5

    render() {
        let style: Dictionary = {
            root: {
                height: `${this.height}px`,
                borderRadius: `${this.cornerRadius}px`
            }
        }

        if (this.props.width) {
            style.root['width'] = this.props.width
        }

        const buttonStyle: Partial<IButtonStyles> = style

        return (
            <FluentUIPrimaryButton 
                {...this.props} 
                styles={buttonStyle}
            />  
        )
    }
}
