import React, { Component } from 'react'
import { Stack } from '@fluentui/react'
import { MarketingHeader } from '../../ui-kit/MarketingHeader'
import { MarketingFooter } from '../../ui-kit/MarketingFooter'
import { MarketingForm } from '../../ui-kit/MarketingForm'

import './MarketingStyles.css'

export class ContactUsPage extends Component {
    componentDidMount() {
        document.getElementById('container')?.classList.add('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.add('hidden')
        document.getElementById('navigation-bar')?.classList.add('hidden')
    }

    componentWillUnmount() {
        document.getElementById('container')?.classList.remove('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.remove('hidden')
        document.getElementById('navigation-bar')?.classList.remove('hidden')
    }

    render() {
        return (
            <Stack id='marketing-content'>
                <MarketingHeader />
                <MarketingForm />
                <MarketingFooter />
            </Stack>
        )
    }
}
