import { IStackStyles, ITextStyles } from '@fluentui/react'

export class FeaturesDifferentiatorsStyles {
    static outerStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    padding: '5vw 5vw 10vw 9vw',
                },
            },

            backgroundColor: 'white',
            padding: '5vw 15vw',
        },
    }

    static innerLeftStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    width: '100%',
                },
            },

            width: '50%',
        },
    }

    static innerRightStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    width: '100%',
                },
            },

            width: '50%',
        },
    }

    static listStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    marginRight: '1.5vw',
                    textIndent: '-8vw',
                    padding: '8vw 9vw 8vw 14vw',
                },

                '& li': {
                    selectors: {
                        '@media(max-width: 600px)': {
                            fontSize: '4.5vw',
                            lineHeight: '8vw',
                            paddingBottom: '3vw',
                        },
                    },

                    paddingBottom: '2vw',
                    lineHeight: '1.8vw',
                },

                '& li::marker': {
                    selectors: {
                        '@media(max-width: 600px)': {
                            fontSize: '5.5vw',
                        },
                    },

                    color: '#0F72AC',
                    fontSize: '1.5vw',
                },
            },

            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            fontFamily: 'Lato, sans-serif',
            fontSize: '1vw',
            color: '#FFFFFF',
            padding: '2vw 3vw 1vw 5vw',
            marginRight: '2vw',
            textIndent: '-2vw',
        },
    }

    static sectionNameTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '4.5vw',
                    paddingBottom: '5vw',
                },
            },

            fontFamily: 'Noto Sans JP, sans-serif',
            color: '#0F72AC',
            fontWeight: 700,
            fontSize: '1vw',
            paddingBottom: '1vw',
        },
    }

    static headerTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '11vw',
                    paddingBottom: '5vw',
                },
            },

            color: '#000000',
            fontFamily: 'Noto Sans JP, sans-serif',
            fontWeight: 700,
            fontSize: '2.8vw',
            marginRight: '5vw',
        },
    }

    static descriptionTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '4.5vw',
                    lineHeight: '8vw',
                    paddingTop: '6vw',
                    marginRight: '0',
                },
            },

            color: '#7C7C7C',
            fontFamily: 'Lato, sans-serif',
            fontWeight: '600',
            fontSize: '1vw',
            lineHeight: '2vw',
            paddingTop: '1vw',
            marginRight: '7.5vw',
        },
    }

    static subHeaderTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '4vw',
                    padding: '10vw 4vw 0',
                },
            },

            color: '#282828',
            fontFamily: 'Noto Sans JP, sans-serif',
            fontWeight: 700,
            fontSize: '1vw',
            margin: '1vw',
            paddingTop: '2vw',
        },
    }
}
