import { 
    Dialog, 
    DialogFooter, 
    DialogType, 
    IDialogContentProps, 
    IModalProps, 
    IModalStyles } from "@fluentui/react";
import React, { Component } from "react";
import { container } from "../DIContainer";
import { i18nKey, Ii18n } from "../global/i18n";
import { TYPES } from "../Types";
import { PrimaryButton } from "./PrimaryButton";

interface GeneralDialogProps {
    title: string
    message: string
    className?: string
    onClick: () => void
}

interface GeneralDialogState {}

export class GeneralDialog extends Component<GeneralDialogProps, GeneralDialogState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    
    private contentProps: IDialogContentProps = {
        type: DialogType.normal,
        title: this.props.title,
        closeButtonAriaLabel: this.i18n.get(i18nKey.close),
        subText: this.props.message,
        styles: {
            header: {
                textAlign: 'center'
            }
        }
    }

    private modalStyles: Partial<IModalStyles> = {
        main: {
            width: 400,
            borderRadius: 5
        }
    }

    private modalProps: IModalProps = {
        isBlocking: false,
        styles: this.modalStyles,
        isDarkOverlay: false,
        className: this.props.className ?? "general-dialog"
    }

    render() {
        return (
            <Dialog
                hidden={false}
                dialogContentProps={this.contentProps}
                modalProps={this.modalProps}
            >
                <DialogFooter>
                    <PrimaryButton 
                        id="general-dialog-primary-button"
                        onClick={this.props.onClick} 
                        text={this.i18n.get(i18nKey.okay)} 
                    />
                </DialogFooter>
            </Dialog>
        )
    }
}
