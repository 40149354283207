import { ChoiceGroup, FontIcon, IChoiceGroupOption, Image, Label, MessageBar, MessageBarType, Stack, Text } from "@fluentui/react"
import React from "react"
import { Component } from "react"
import { Dictionary, DocumentId } from "../global/TypeAliases"
import {
    DragDropContext,
    Draggable,
    DraggableLocation,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot,
    DropResult
} from "react-beautiful-dnd"
import { i18nKey, Ii18n } from "../global/i18n"
import { container } from "../DIContainer"
import { TYPES } from "../Types"
import { ICourseService } from "../services/CourseService"
import { LearningPlanEditorStyles } from "./LearningPlanEditorStyles"
import { ContentType, IContent } from "../models/IContent"
import { ISurveyService } from "../services/SurveyService"
import "./LearningPlanEditor.css"
import trainingIcon from "../images/content/training-icon.svg"
import surveyIcon from "../images/content/survey-icon.svg"
import { Theme } from "./Theme"
import { FontSize } from "./FontSize"

interface LearningPlanEditorProps {
    clientId: DocumentId
    initialContents: IContent[]
    contentsDidUpdate?: (updatedContents: IContent[]) => void
}

interface LearningPlanEditorState {
    surveys: IContent[]
    trainings: IContent[]
    errorMessage: string | null
    selectedContentType: ContentType
    selectedContents: IContent[]
    addButtonDisabled: boolean
}

const getItemStyle = (draggableStyle: any): Dictionary => ({
    background: "white",
    border: "1px solid #eff0f1",
    borderRadius: "10px",
    margin: "8px 0",
    padding: "8px 0",
    userSelect: "none",
    width: "100%",
    ...draggableStyle
})

const getListStyle = (snapshot: DroppableStateSnapshot): Dictionary => ({
    backgroundColor: snapshot.isDraggingOver ? Theme.color.secondary : "#eaeaea",
    borderRadius: "10px",
    margin: "20px",
    minHeight: "250px",
    minWidth: "350px",
    padding: "8px",
    transition: "background-color 0.2s ease"
})

export class LearningPlanEditor extends Component<LearningPlanEditorProps, LearningPlanEditorState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private courseService: ICourseService = container.get<ICourseService>(TYPES.ICourseService)
    private surveyService: ISurveyService = container.get<ISurveyService>(TYPES.ISurveyService)

    private droppableId1: string = "droppable1"
    private droppableId2: string = "droppable2"

    private radioButtonChoices: IChoiceGroupOption[] = [
        {
            text: this.i18n.get(i18nKey.training),
            imageSrc: trainingIcon,
            selectedImageSrc: trainingIcon,
            key: ContentType.course
        },
        {
            text: this.i18n.get(i18nKey.survey),
            imageSrc: surveyIcon,
            selectedImageSrc: surveyIcon,
            key: ContentType.survey
        }
    ]

    state: LearningPlanEditorState = {
        surveys: [],
        trainings: [],
        errorMessage: null,
        selectedContentType: ContentType.course,
        selectedContents: [],
        addButtonDisabled: true
    }

    componentDidMount() {
        this.loadAllContent()
    }

    render() {
        return (
            <Stack horizontalAlign="center">
                <Stack tokens={LearningPlanEditorStyles.stackTokens} styles={LearningPlanEditorStyles.searchStackStyle} horizontalAlign="center">
                    <Label>{this.i18n.get(i18nKey.selectTypeOfContentToSearch)}</Label>
                    <ChoiceGroup
                        id="choice-group"
                        selectedKey={this.state.selectedContentType}
                        options={this.radioButtonChoices}
                        onChange={this.choiceDidChange}
                    />
                </Stack>

                {this.state.errorMessage && this.createErrorBanner()}

                {!this.state.selectedContents.length && (
                    <Text id="learning-plan-empty-view" variant={FontSize.large}>
                        {this.i18n.get(i18nKey.learningPlanEditorEmpty)}
                    </Text>
                )}

                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Stack horizontal horizontalAlign="center">
                        <Stack horizontalAlign="center">
                            <Text variant={FontSize.xLarge}>Available Content</Text>
                            <Droppable droppableId={this.droppableId1}>
                                {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot)}>
                                        {this.createContentDraggables(
                                            this.state.selectedContentType === ContentType.course ? this.state.trainings : this.state.surveys
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </Stack>
                        <Stack horizontalAlign="center">
                            <Text variant={FontSize.xLarge}>Selected Content</Text>
                            <Droppable droppableId={this.droppableId2}>
                                {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot)}>
                                        {this.createContentDraggables(this.state.selectedContents)}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </Stack>
                    </Stack>
                </DragDropContext>
            </Stack>
        )
    }

    /**
     * Upon initial page loading, will gather all training and survey content
     */
    private loadAllContent = () => {
        this.setState({
            selectedContents: this.props.initialContents
        })

        this.loadAllTrainings()
        this.loadAllSurveys()
    }

    /**
     * Helper function to load all training content.
     */
    private loadAllTrainings = () => {
        this.courseService
            .coursesForClientId(this.props.clientId)
            .then((trainings) => {
                // remove existing selected contents from the loaded content
                this.props.initialContents.forEach((item) => {
                    trainings = trainings.filter((training) => training.documentId !== item.documentId)
                })

                this.setState({
                    trainings: trainings
                })
            })
            .catch((error) => {
                this.setState({
                    errorMessage: error.message
                })
            })
    }

    /**
     * Helper function to load all survey content.
     */
    private loadAllSurveys = () => {
        this.surveyService
            .surveysForClientId(this.props.clientId)
            .then((surveys) => {
                // remove existing selected contents from the loaded content
                this.props.initialContents.forEach((item) => {
                    surveys = surveys.filter((survey) => survey.documentId !== item.documentId)
                })

                this.setState({
                    surveys: surveys
                })
            })
            .catch((error) => {
                this.setState({
                    errorMessage: error.message
                })
            })
    }

    /**
     * Updates the selected content type when the content type has changed.
     * @param ev React form event object
     * @param option IChoiceGroupOption selected
     */
    choiceDidChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        this.setState({ selectedContentType: option?.key as ContentType })
    }

    /**
     * Dismisses an error banner.
     */
    dismissErrorBanner = () => {
        this.setState({
            errorMessage: null
        })
    }

    /**
     * Create an error banner if a service call has failed.
     */
    private createErrorBanner = () => {
        return (
            <MessageBar
                id="learning-plan-editor-error-banner"
                messageBarType={MessageBarType.error}
                dismissButtonAriaLabel="Close"
                onDismiss={this.dismissErrorBanner}
            >
                {this.state.errorMessage}
            </MessageBar>
        )
    }

    /**
     * Create a draggable components for a droppable container
     * @param contents IContent[] from which to create draggable components
     * @returns container of draggable components
     */
    private createContentDraggables = (contents: IContent[]) => {
        return contents.map((item, index) => (
            <Draggable key={item.documentId} draggableId={item.documentId} index={index}>
                {(providedDraggable: DraggableProvided, _: DraggableStateSnapshot) => (
                    <div
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                        style={getItemStyle(providedDraggable.draggableProps.style)}
                    >
                        <Stack horizontal>
                            <Stack.Item id="draggable-icon" styles={LearningPlanEditorStyles.stackItemStyle}>
                                <FontIcon iconName="NumberedListText" className={LearningPlanEditorStyles.moveIconClass.lightGray} />
                            </Stack.Item>
                            <Stack.Item styles={LearningPlanEditorStyles.stackItemStyle}>
                                {(item.contentType() === ContentType.course) && (
                                    <Image
                                        src={trainingIcon}
                                        alt="training-icon"
                                        className="content-icon"
                                        styles={LearningPlanEditorStyles.draggableIconImageStyle}
                                    />
                                )}
                                {item.contentType() === ContentType.survey && (
                                    <Image
                                        src={surveyIcon}
                                        alt="survey-icon"
                                        className="content-icon"
                                        styles={LearningPlanEditorStyles.draggableIconImageStyle}
                                    />
                                )}
                            </Stack.Item>
                            <Stack.Item id="content-name" styles={LearningPlanEditorStyles.stackItemStyle} grow disableShrink>
                                {item.name}
                            </Stack.Item>
                            {item.contentType() === ContentType.course && (
                                <Stack.Item id="content-description" styles={LearningPlanEditorStyles.stackItemStyle} grow disableShrink>
                                    {`\u00a0(${item.description})`}
                                </Stack.Item>
                            )}
                        </Stack>
                    </div>
                )}
            </Draggable>
        ))
    }

    /**
     * Handler after a course is dragged
     * @param result The result of a drag
     */
    onDragEnd = (result: DropResult) => {
        const { source, destination } = result

        // item dragged outside of a droppable container
        if (!destination) {
            return
        }

        // item dragged was dropped in the same location, no need to proceed
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return
        }

        const start = source.droppableId
        const finish = destination.droppableId

        if (start === finish) {
            /**
             * Reorder the content depending on source
             */
            if (source.droppableId === this.droppableId1) {
                switch (this.state.selectedContentType) {
                    case ContentType.course: {
                        this.setState({
                            trainings: this.reorder(this.state.trainings, source.index, destination.index)
                        })
                        break
                    }
                    case ContentType.survey: {
                        this.setState({
                            surveys: this.reorder(this.state.surveys, source.index, destination.index)
                        })
                        break
                    }
                }
            } else {
                let updatedContents = [...this.state.selectedContents]
                updatedContents = this.reorder(updatedContents, source.index, destination.index)
                this.save(updatedContents)
            }
        } else {
            /**
             * Update and reorder both droppable areas
             */
            this.updateSource(source, destination, result.draggableId)
            this.updateDestination(destination, result.draggableId)
        }
    }

    /**
     * Helper function to perform the adding of the new item to the appropriate droppable area.
     * @param source the source droppable object
     * @param documentId the document ID that is moving
     */
    private updateSource = (source: DraggableLocation, destination: DraggableLocation, documentId: DocumentId) => {
        let itemToAdd
        if (source.droppableId === this.droppableId1) {
            itemToAdd =
                this.state.selectedContentType === ContentType.course
                    ? this.state.trainings.find((item) => item.documentId === documentId)
                    : this.state.surveys.find((item) => item.documentId === documentId)
            if (itemToAdd) {
                const updatedContents = this.insert(this.state.selectedContents, itemToAdd, destination.index)
                this.save(updatedContents)
            }
        } else {
            // sourceId === "droppable2"
            itemToAdd = this.state.selectedContents.find((item) => item.documentId === documentId)
            if (itemToAdd) {
                switch (itemToAdd.contentType()) {
                    case ContentType.course: {
                        this.setState({
                            trainings: this.insert(this.state.trainings, itemToAdd, destination.index)
                        })
                        break
                    }
                    case ContentType.survey: {
                        this.setState({
                            surveys: this.insert(this.state.surveys, itemToAdd, destination.index)
                        })
                        break
                    }
                }
            }
        }
    }

    /**
     * Helper function to perform the removing of an item from the appropriate droppable area.
     * @param destination the destination droppable object
     * @param documentId the document ID that is moving
     */
    private updateDestination = (destination: DraggableLocation, documentId: DocumentId) => {
        let filteredContents
        if (destination.droppableId === this.droppableId1) {
            filteredContents = this.state.selectedContents.filter((item) => item.documentId !== documentId)
            this.save(filteredContents)
        } else {
            // destinationId === "droppable2"
            filteredContents =
                this.state.selectedContentType === ContentType.course
                    ? this.state.trainings.filter((item) => item.documentId !== documentId)
                    : this.state.surveys.filter((item) => item.documentId !== documentId)
            if (filteredContents) {
                switch (this.state.selectedContentType) {
                    case ContentType.course: {
                        this.setState({
                            trainings: filteredContents
                        })
                        break
                    }
                    case ContentType.survey: {
                        this.setState({
                            surveys: filteredContents
                        })
                        break
                    }
                }
            }
        }
    }

    /**
     * Reorder the content within a single droppable container
     * @param content The list of content
     * @param startIndex The index of the training that is being moved
     * @param endIndex The target index where the training will be moved to
     * @returns IContent[] containing the updated indexing
     */
    private reorder = (content: IContent[], startIndex: number, endIndex: number): IContent[] => {
        const result = [...content]
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }

    /**
     * Insert content from one droppable container to another
     * @param contents the contents list to insert into
     * @param contentToInsert the new content to insert
     * @param index the index at which to insert
     * @returns IContent[] containing the updated item and indexing
     */
    private insert = (contents: IContent[], contentToInsert: IContent, index: number): IContent[] => {
        const result = [...contents]
        result.splice(index, 0, contentToInsert)
        return result
    }

    /**
     * Save the new selected contents and notify the parent
     * @param newContents IContent[] of currently selected contents
     */
    private save = (newContents: IContent[]) => {
        this.setState({
            selectedContents: newContents
        })

        if (this.props.contentsDidUpdate) {
            this.props.contentsDidUpdate(newContents)
        }
    }
}
