import { log } from "../global/Logger"
import { injectable } from "inversify"
import queryString from "query-string"

export interface CleverTokenConfig {
    code: string
    redirectUri: string
}

export interface ICleverCodeParser {
    /**
     * Parse out the code query parameter of a Clever redirect URI
     * @param urlString The url string
     */
    getCode(urlString: string): CleverTokenConfig | null
}

@injectable()
export class CleverCodeParser {
    getCode(urlString: string): CleverTokenConfig | null {
        const components = urlString.split('?')

        if (components.length !== 2) {
            return null
        }

        const params = queryString.parse(components[1])
        const code = params['code'] as string
        const redirectUri = process.env.REACT_APP_ENV_CLEVER_REDIRECT_URL

        if (code && redirectUri) {
            log("Code is " + code)

            return {
                code: code,
                redirectUri: redirectUri
            }
        } else {
            log("Unable to extract code")
            return null
        }
    }
}