import { IDeadlinesHelper, DeadlinesHelper } from './utils/DeadlinesHelper';
import { ILearningPlanCompositionService, LearningPlanCompositionService } from './services/LearningPlanCompositionService'
import { ISurveyInteractionFactory, SurveyInteractionFactory }from './models/SurveyInteractionFactory';
import { ISurveyService, SurveyService } from './services/SurveyService'
import { ISurveyFactory, SurveyFactory } from './pages/admin/surveys/SurveyFactory'
import { IRouter, Router } from './Router';
import { IUserParser, UserParser } from './models/UserParser';
import { IClientParser, ClientParser } from './models/ClientParser';
import { CleverCompositionService, ICleverCompositionService } from './services/CleverCompositionService';
import { ICleverService, CleverService } from './services/CleverService';
import { IAPIKeyProvider, APIKeyProvider } from './services/APIKeyProvider';
import { IUserUploadValidator, UserUploadValidator } from './pages/admin/users/UserUploadValidator';
import { ILRSStatementParser, LRSStatementParser } from './models/LRSStatementParser';
import { ISearchFilter, SearchFilter } from './pages/admin/users/SearchFilter';
import { IAccessController, AccessController } from './services/AccessController';
import { Firebase, IFirebase } from './services/Firebase';
import { Auth, IAuth } from './auth/Auth';
import DataTableFactory, { IDataTableFactory } from './factories/DataTableFactory';
import ClientService, { IClientService } from './services/ClientService';
import { ICohortService, CohortService } from './services/CohortService';
import CourseParser, { ICourseParser } from './models/CourseParser';
import { Container } from 'inversify'
import { IUserService } from './services/UserService'
import { TYPES } from './Types'
import IMailService from './services/MailService'
import MailService from './services/MailService'
import UserService from './services/UserService'
import ICourseService from './services/CourseService'
import CourseService from './services/CourseService'
import IAuthService from './services/AuthService'
import AuthService from './services/AuthService'
import { IDateProvider, DateProvider } from './services/DateProvider'
import { IRegistrationTokenService, RegistrationTokenService } from './services/RegistrationTokenService';
import { UUID, UUIDGenerator } from './utils/UUIDGenerator';
import FileUploader, { IFileUploader } from './services/FileUploader';
import { IAppInitializerInformer, AppInitializerInformer } from './services/AppInitializerInformer';
import { ILRSParser, LRSParser } from './models/LRSParser';
import { ILRSService, LRSService } from './services/LRSService';
import { IServiceConfig, ServiceConfig } from './services/ServiceConfig';
import { IURLCreator, URLCreator } from './ui-kit/URLCreator';
import { IEmailValidator, EmailValidator } from './utils/EmailValidator';
import { IUserUploadCompositionService, UserUploadCompositionService } from './services/UserUploadCompositionService';
import { Ii18n, i18n } from './global/i18n';
import { MarketingIi18n, MarketingI18n } from './global/Marketing-i18n'
import { ICleverCodeParser, CleverCodeParser } from './auth/CleverCodeParser';
import { IBase64Encoder, Base64Encoder } from './utils/Base64Encoder';
import { IUserRepository, UserRepository } from './services/UserRepository';
import { ILearningPlanService, LearningPlanService } from './services/LearningPlanService';
import { ILearningPlanParser, LearningPlanParser } from './models/LearningPlanParser';
import { FeatureFlagService, IFeatureFlagService } from './services/FeatureFlagService';
import { ILocalStorage, LocalStorage } from './utils/LocalStorage';
import { IReportCompletionCompositionService, ReportCompletionCompositionService } from './services/ReportCompletionCompositionService';
import { IEventReportingService, EventReportingService } from './services/EventReportingService';
import { DashboardCompositionService, IDashboardCompositionService } from './services/DashboardCompositionService';
import { ILoadTimer, LoadTimer } from './utils/LoadTimer';
import { CoursesAdminCompositionService, ICoursesAdminCompositionService } from './services/CoursesAdminCompositionService';
import { ICohortsContentsPageCompositionService, CohortsContentsPageCompositionService } from './services/CohortsContentsPageCompositionService';
import { SurveyParser, ISurveyParser } from './models/SurveyParser';
import { IReportsHomePageCompositionService, ReportsHomePageCompositionService } from './services/ReportsHomePageCompositionService';
import { ISurveyServiceConfig, SurveyServiceConfig } from './services/SurveyServiceConfig';
import { ISurveyReportPageCompositionService, SurveyReportPageCompositionService } from './services/SurveyReportPageCompositionService';
import { ISurveyCompositionService, SurveyCompositionService } from './services/SurveyCompositionService';
import { ISurveyResumer, SurveyResumer } from './pages/admin/surveys/SurveyResumer';
import { IReportUserSurveyDetailsModalCompositionService, ReportUserSurveyDetailsModalCompositionService } from './services/ReportUserSurveyDetailsModalCompositionService';
import { ISurveyValidator, SurveyValidator } from './pages/admin/surveys/SurveyValidator';
import { ISurveyHelper, SurveyHelper } from './pages/admin/surveys/SurveyHelper';
import { ILearningPlanHelper, LearningPlanHelper } from './utils/LearningPlanHelper';
import {IDeadlineService, DeadlineService } from './services/DeadlineService';
import { IEmailRegistrationCompositionService, EmailRegistrationCompositionService,  } from './services/EmailRegistrationCompositionService';
import { IResetPasswordCompositionService, ResetPasswordCompositionService } from './services/ResetPasswordCompositionService';
import { DataTableFactoryUtils, IDataTableFactoryUtils } from './factories/DataTableFactoryUtils';
import { GroupIDService, IGroupIDService } from './services/GroupIdService';
import { ChartFactory, IChartFactory } from './factories/ChartFactory';
import { IInvitationCodeParser, InvitationCodeParser } from './services/InvitationCodeParser';
import { IInvitationCodeService, InvitationCodeService } from './services/InvitationCodeService';
import { GuestDashboardCompositionService, IGuestDashboardCompositionService } from './services/GuestDashboardCompositionService';
import { IPromiseHelper, PromiseHelper } from './services/PromiseHelper';
import { IUsersAdminCompositionService, UsersAdminCompositionService } from './services/UsersAdminCompositionService';
import { IStringHelpers, StringHelpers } from './utils/StringHelpers';
import SurveyReportDataTableFactory, { ISurveyReportDataTableFactory } from './factories/SurveyReportDataTableFactory';
import { IPasswordGenerator, PasswordGenerator } from './utils/PasswordGenerator';

const container = new Container()

/**
 * Singletons
 */
container.bind<IAuth>(TYPES.IAuth).to(Auth).inSingletonScope()
container.bind<IFirebase>(TYPES.IFirebase).to(Firebase).inSingletonScope()
container.bind<ILocalStorage>(TYPES.ILocalStorage).to(LocalStorage).inSingletonScope()

/**
 * Services
 */
container.bind<IMailService>(TYPES.IMailService).to(MailService)
container.bind<IUserService>(TYPES.IUserService).to(UserService)
container.bind<ICourseService>(TYPES.ICourseService).to(CourseService)
container.bind<IAuthService>(TYPES.IAuthService).to(AuthService)
container.bind<IClientService>(TYPES.IClientService).to(ClientService)
container.bind<ICohortService>(TYPES.ICohortService).to(CohortService)
container.bind<ILRSService>(TYPES.ILRSService).to(LRSService)
container.bind<IUserUploadCompositionService>(TYPES.IUserUploadCompositionService).to(UserUploadCompositionService)
container.bind<IAPIKeyProvider>(TYPES.IAPIKeyProvider).to(APIKeyProvider)
container.bind<ICleverCompositionService>(TYPES.ICleverCompositionService).to(CleverCompositionService)
container.bind<IUserRepository>(TYPES.IUserRepository).to(UserRepository).inSingletonScope()
container.bind<ILearningPlanService>(TYPES.ILearningPlanService).to(LearningPlanService)
container.bind<IDateProvider>(TYPES.IDateProvider).to(DateProvider)
container.bind<IReportCompletionCompositionService>(TYPES.IReportCompletionCompositionService).to(ReportCompletionCompositionService)
container.bind<IEventReportingService>(TYPES.IEventReportingService).to(EventReportingService)
container.bind<IDashboardCompositionService>(TYPES.IDashboardCompositionService).to(DashboardCompositionService)
container.bind<ICoursesAdminCompositionService>(TYPES.ICoursesAdminCompositionService).to(CoursesAdminCompositionService)
container.bind<ICohortsContentsPageCompositionService>(TYPES.ICohortsContentsPageCompositionService).to(CohortsContentsPageCompositionService)
container.bind<ISurveyService>(TYPES.ISurveyService).to(SurveyService)
container.bind<IReportsHomePageCompositionService>(TYPES.IReportsHomePageCompositionService).to(ReportsHomePageCompositionService)
container.bind<ISurveyServiceConfig>(TYPES.ISurveyServiceConfig).to(SurveyServiceConfig)
container.bind<ISurveyReportPageCompositionService>(TYPES.ISurveyReportPageCompositionService).to(SurveyReportPageCompositionService)
container.bind<ISurveyCompositionService>(TYPES.ISurveyCompositionService).to(SurveyCompositionService)
container.bind<IReportUserSurveyDetailsModalCompositionService>(TYPES.IReportUserSurveyDetailsModalCompositionService).to(ReportUserSurveyDetailsModalCompositionService)
container.bind<ILearningPlanCompositionService>(TYPES.ILearningPlanCompositionService).to(LearningPlanCompositionService)
container.bind<IDeadlineService>(TYPES.IDeadlineService).to(DeadlineService)
container.bind<IEmailRegistrationCompositionService>(TYPES.IEmailRegistrationCompositionService).to(EmailRegistrationCompositionService)
container.bind<IResetPasswordCompositionService>(TYPES.IResetPasswordCompositionService).to(ResetPasswordCompositionService)
container.bind<IGroupIDService>(TYPES.IGroupIDService).to(GroupIDService)
container.bind<IInvitationCodeParser>(TYPES.IInvitationCodeParser).to(InvitationCodeParser)
container.bind<IInvitationCodeService>(TYPES.IInvitationCodeService).to(InvitationCodeService)
container.bind<IGuestDashboardCompositionService>(TYPES.IGuestDashboardCompositionService).to(GuestDashboardCompositionService)
container.bind<IUsersAdminCompositionService>(TYPES.IUsersAdminCompositionService).to(UsersAdminCompositionService)

/**
 * Classes
 */
container.bind<ICourseParser>(TYPES.ICourseParser).to(CourseParser)
container.bind<UUID>(TYPES.UUID).to(UUIDGenerator)
container.bind<IFileUploader>(TYPES.IFileUploader).to(FileUploader)
container.bind<IAppInitializerInformer>(TYPES.IAppInitializerInformer).to(AppInitializerInformer)
container.bind<IAccessController>(TYPES.IAccessController).to(AccessController)
container.bind<ISearchFilter>(TYPES.ISearchFilter).to(SearchFilter)
container.bind<ILRSStatementParser>(TYPES.ILRSStatementParser).to(LRSStatementParser)
container.bind<ILRSParser>(TYPES.ILRSParser).to(LRSParser)
container.bind<ISurveyParser>(TYPES.ISurveyParser).to(SurveyParser)
container.bind<IServiceConfig>(TYPES.IServiceConfig).to(ServiceConfig)
container.bind<IURLCreator>(TYPES.IURLCreator).to(URLCreator)
container.bind<IEmailValidator>(TYPES.IEmailValidator).to(EmailValidator)
container.bind<IUserUploadValidator>(TYPES.IUserUploadValidator).to(UserUploadValidator)
container.bind<Ii18n>(TYPES.Ii18n).to(i18n)
container.bind<MarketingIi18n>(TYPES.MarketingIi18n).to(MarketingI18n)
container.bind<ICleverCodeParser>(TYPES.ICleverCodeParser).to(CleverCodeParser)
container.bind<ICleverService>(TYPES.ICleverService).to(CleverService)
container.bind<IBase64Encoder>(TYPES.IBase64Encoder).to(Base64Encoder)
container.bind<IClientParser>(TYPES.IClientParser).to(ClientParser)
container.bind<IUserParser>(TYPES.IUserParser).to(UserParser)
container.bind<IRegistrationTokenService>(TYPES.IRegistrationTokenService).to(RegistrationTokenService)
container.bind<ILearningPlanParser>(TYPES.ILearningPlanParser).to(LearningPlanParser)
container.bind<IFeatureFlagService>(TYPES.IFeatureFlagService).to(FeatureFlagService)
container.bind<IRouter>(TYPES.IRouter).to(Router)
container.bind<ILoadTimer>(TYPES.ILoadTimer).to(LoadTimer)
container.bind<ISurveyResumer>(TYPES.ISurveyResumer).to(SurveyResumer)
container.bind<ISurveyValidator>(TYPES.ISurveyValidator).to(SurveyValidator)
container.bind<ISurveyHelper>(TYPES.ISurveyHelper).to(SurveyHelper)
container.bind<ILearningPlanHelper>(TYPES.ILearningPlanHelper).to(LearningPlanHelper)
container.bind<IDeadlinesHelper>(TYPES.IDeadlinesHelper).to(DeadlinesHelper)
container.bind<IPromiseHelper>(TYPES.IPromiseHelper).to(PromiseHelper)
container.bind<IPasswordGenerator>(TYPES.IPasswordGenerator).to(PasswordGenerator)

/**
 * Factories
 */
container.bind<IDataTableFactory>(TYPES.IDataTableFactory).to(DataTableFactory)
container.bind<ISurveyFactory>(TYPES.ISurveyFactory).to(SurveyFactory)
container.bind<ISurveyInteractionFactory>(TYPES.ISurveyInteractionFactory).to(SurveyInteractionFactory)
container.bind<IChartFactory>(TYPES.IChartFactory).to(ChartFactory)
container.bind<ISurveyReportDataTableFactory>(TYPES.ISurveyReportDataTableFactory).to(SurveyReportDataTableFactory)

/**
 * Utils
 */
container.bind<IDataTableFactoryUtils>(TYPES.IDataTableFactoryUtils).to(DataTableFactoryUtils)
container.bind<IStringHelpers>(TYPES.IStringHelpers).to(StringHelpers)

export { container }
