import { Modal } from "reactstrap";

interface CMModalProps {
    isOpen: boolean;
    headerText: string;
    modalBodyContent: string | any;
    primaryButtonText?: string;
    cancelButtonText?: string;
    backdrop?: boolean | 'static' | undefined;
    toggle?: () => void;
    onPrimaryButtonClick?: () => void;
    onCancelButtonClick?: () => void;
    onCloseButtonClick?: () => void;
}

export const CMModal: React.FC<CMModalProps> = (props) => {
    const {
        isOpen,
        headerText,
        modalBodyContent,
        primaryButtonText,
        cancelButtonText,
        backdrop,
        toggle,
        onPrimaryButtonClick,
        onCancelButtonClick,
        onCloseButtonClick,
    } = props;

    return (
        <Modal isOpen={isOpen} backdrop={backdrop} toggle={toggle} data-testid="cm-modal">
            <div className="modal-header" data-testid="cm-modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                    {headerText}
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    data-testid="close-button"
                    onClick={onCloseButtonClick}
                    aria-label="Close"
                ></button>
            </div>
            <div className="modal-body" data-testid="cm-modal-body">
                {modalBodyContent}
            </div>
            {cancelButtonText && primaryButtonText && (
                <div className="modal-footer" data-testid="cm-modal-footer">
                    <button
                        type="button"
                        className="btn btn-light"
                        data-testid="cancel-button"
                        onClick={onCancelButtonClick}
                    >
                        {cancelButtonText}
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        data-testid="primary-button"
                        onClick={onPrimaryButtonClick}
                    >
                        {primaryButtonText}
                    </button>
                </div>
            )}
        </Modal>
    );
};
