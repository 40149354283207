import { CSSProperties } from 'react'
import { IStackStyles, ITextStyles, ITextFieldStyles, IMessageBarStyles } from '@fluentui/react'

import formBackground from '../images/marketing/marketing-form-background.jpg'
import formMobileBackground from '../images/marketing/marketing-form-background-mobile.jpg'

export class MarketingFormStyles {
    static outerStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    backgroundSize: 'contain',
                    backgroundImage: `url(${formMobileBackground})`,
                },
            },

            backgroundImage: `url(${formBackground})`,
            backgroundSize: 'cover',
        },
    }

    static contentStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    width: '100%',
                    paddingTop: '50vw',
                    paddingBottom: '40vw',
                },
            },

            width: '35%',
            paddingTop: '7vw',
            paddingLeft: '10vw',
        },
    }

    static formStackStyles: IStackStyles = {
        root: {
            selectors: {
                'button:last-child': {
                    marginTop: '2vw',
                },
                '@media(max-width: 600px)': {
                    width: '100%',
                    margin: 0,
                    paddingBottom: '15vw',

                    selectors: {
                        'button:last-child': {
                            marginTop: '8vw',
                        },
                    },
                },
            },

            padding: '2vw 5vw',
            borderRadius: '0.4vw',
            backgroundColor: '#FFFFFF',
            margin: '3vw 10vw 3vw 0vw',
            width: '30%',
        },
    }

    static privacyFormStackStyles: IStackStyles = {
        root: {
            selectors: {
                'button:last-child': {
                    marginTop: '2vw',
                },
                '@media(max-width: 600px)': {
                    width: '100%',
                    margin: 0,
                    paddingBottom: '15vw',

                    selectors: {
                        'button:last-child': {
                            marginTop: '8vw',
                        },
                    },
                },
            },

            padding: '2vw 5vw',
            borderRadius: '0.4vw',
            backgroundColor: '#FFFFFF',
            margin: '3vw 10vw 3vw 0vw',
            width: '100%',
        },
    }

    static contentHeaderTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '7vw',
                    paddingBottom: '4vw',
                },
            },

            fontFamily: 'Noto Sans JP, sans serif',
            fontSize: '3vw',
            color: '#FFFFFF',
            fontWeight: 900,
        },
    }

    static contentTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '5vw',
                    lineHeight: '8vw',
                },
            },

            fontFamily: 'Lato, sans serif',
            fontWeight: 500,
            color: '#FFFFFF',
            fontSize: '1.4vw',
            opacity: 0.8,
        },
    }

    static formHeaderTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '8vw',
                    paddingBottom: '5vw',
                },
            },

            fontFamily: 'Noto Sans JP, sans serif',
            fontSize: '1.8vw',
            fontWeight: 'bold',
        },
    }

    static textFieldStyles: Partial<ITextFieldStyles> = {
        root: {
            selectors: {
                '& label': {
                    fontFamily: 'Noto Sans JP, sans serif',
                    fontWeight: 'bold',
                    fontSize: '1vw',
                    color: '#000000',

                    selectors: {
                        '@media(max-width: 600px)': {
                            fontSize: '4vw',
                        },
                    },
                },
            },

            fontFamily: 'Noto Sans JP, sans serif',
            fontWeight: 'bold',
            paddingTop: '0.5vw',
        },

        fieldGroup: {
            selectors: {
                '@media(max-width: 600px)': {
                    height: '12vw',
                },
            },

            height: '2.5vw',
            borderColor: '#E7EBEF',
        },

        field: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '4vw',
                },
            },

            fontSize: '1vw',
        },
    }

    static messageBarStyles: IMessageBarStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    marginTop: '6vw !important',
                },

                '& button': {
                    alignSelf: 'flex-end',
                    padding: '0',
                    width: 'auto',
                    height: '2.5vw',
                    marginTop: '0 !important',

                    selectors: {
                        '@media(max-width: 600px)': {
                            height: '10vw',
                        },
                    },
                },

                '& button i': {
                    fontSize: '0.8vw !important',

                    selectors: {
                        '@media(max-width: 600px)': {
                            fontSize: '4vw !important',
                        },
                    },
                },
            },

            marginTop: '2vw !important',
        },

        innerText: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '4vw',
                    margin: '2vw',
                    lineHeight: '6vw',
                },
            },

            marginTop: '0.5vw',
            marginBottom: '0.5vw',
            fontFamily: 'Noto Sans JP, sans serif',
            fontSize: '0.9vw',
            lineHeight: '1.5vw',
        },

        icon: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '5vw',
                    marginTop: '3vw',
                },
            },

            margin: '0.8vw',
            fontSize: '1.2vw',
        },

        iconContainer: {
            margin: '0',
        },

        text: {
            margin: '0',
        },
    }

    static linkStyle: CSSProperties = {
        color: '#4AAAE7',
        textDecoration: 'none',
    }
}
