import React, { Component } from 'react'
import { Stack, Text } from '@fluentui/react'
import { MarketingIi18n, i18nKey } from "../global/Marketing-i18n"
import { container } from '../DIContainer'
import { TYPES } from '../Types'
import { LandingTestimonialStyles } from './LandingTestimonialStyles'
import { TestimonialCard } from './TestimonialCard'
import { MarketingButton } from './MarketingButton'
import { IRouter } from '../Router'

import BellevueSchool_Logo from '../images/marketing/testimonial-bellevueSchool-logo.jpg'
import Office365_Logo from '../images/marketing/testimonial-office365-logo.jpg'

interface LandingTestimonialsProps {}

interface LandingTestimonialsState {
    isMobileView: boolean
}

export class LandingTestimonials extends Component<LandingTestimonialsProps, LandingTestimonialsState> {
    private i18n = container.get<MarketingIi18n>(TYPES.MarketingIi18n)
    private router = container.get<IRouter>(TYPES.IRouter)

    state: LandingTestimonialsState = {
        isMobileView: false
    }

    componentDidMount() {
        window.addEventListener('resize', this.checkWindowChange)
        this.checkWindowChange()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkWindowChange)
    }

    render() {
        return (
            <Stack horizontal wrap styles={LandingTestimonialStyles.outerStackStyles}>
                <Stack styles={LandingTestimonialStyles.innerStackStyles}>
                    <Text styles={LandingTestimonialStyles.titleTextStyles} id='landing-testimonial-title'>
                        {this.i18n.get(i18nKey.landingTestimonialsTitle)}
                    </Text>
                    <Text styles={LandingTestimonialStyles.subTitleTextStyles} id='landing-testimonial-sub-title'>
                        {this.i18n.get(i18nKey.landingTestimonialsSubTitle)}
                    </Text>

                    {!this.state.isMobileView && (
                        <MarketingButton
                            text={this.i18n.get(i18nKey.seeMoreTestimonials).toUpperCase()}
                            width='18vw'
                            mobileWidth='64vw'
                            id='landing-testimonial-button'
                            onClick={this.seeMoreButtonClicked}
                        />
                    )}
                </Stack>

                <Stack horizontal wrap styles={LandingTestimonialStyles.innerStackStyles}>
                    <Stack styles={LandingTestimonialStyles.cardStackStyles}>
                        <TestimonialCard
                            Title={this.i18n.get(i18nKey.testimonialBellevueSchoolCardTitle1)}
                            Text={`"${this.i18n.get(i18nKey.testimonialBellevueSchoolCardText1)}"`}
                            Logo={BellevueSchool_Logo}
                        />
                    </Stack>

                    <Stack styles={LandingTestimonialStyles.cardStackStyles}>
                        <TestimonialCard
                            Title={this.i18n.get(i18nKey.testimonialMicrosoftCardTitle1)}
                            Text={`"${this.i18n.get(i18nKey.testimonialMicrosoftCardText1)}"`}
                            Logo={Office365_Logo}
                        />
                    </Stack>
                </Stack>

                {this.state.isMobileView && (
                    <Stack styles={LandingTestimonialStyles.buttonStyles}>
                        <MarketingButton
                            text={this.i18n.get(i18nKey.seeMoreTestimonials).toUpperCase()}
                            width='18vw'
                            mobileWidth='64vw'
                            id='landing-testimonial-button'
                            onClick={this.seeMoreButtonClicked}
                        />
                    </Stack>
                )}
            </Stack>
        )
    }

    seeMoreButtonClicked = () => {
        this.router.goToTestimonials()
    }

    checkWindowChange = () => {
        if (window.innerWidth <= 600) this.setState({ isMobileView: true })
        else this.setState({ isMobileView: false })
    }

}
