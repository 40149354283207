// Global
import { Component, FormEvent } from "react"
import { Dictionary } from "../../global/TypeAliases"
import { i18nKey, Ii18n } from "../../global/i18n"
import { log } from "../../global/Logger"
import { MarketingIi18n, i18nKey as Marketingi18nKey } from '../../global/Marketing-i18n'

// UI
import { GuestEnterCode } from "./GuestEnterCode"
import { GuestDashboardError } from "../../ui-kit/GuestDashboardError"
import GuestSelectionDropdown from "./GuestSelectionDropdown"
import { Loader } from "../../ui-kit/Loader"
import { MessageBar, MessageBarType, Stack, Image, Text, TextField } from "@fluentui/react"

import logoLarge from "../../images/dts-logo-with-black-text.png"
import rightArrow from "../../images/arrow-right.png"

// DI / Services
import { container } from "../../DIContainer"
import { TYPES } from "../../Types"
import { IGuestDashboardCompositionService } from "../../services/GuestDashboardCompositionService"
import { ILocalStorage } from "../../utils/LocalStorage"
import { IMailService } from "../../services/MailService"
import { IRouter } from "../../Router"

// Models
import Course from "../../models/Course"
import { Invitation } from "../../models/Invitation"

// Dashboard Components
import { GuestContentStyles } from "./GuestContentStyles"
import { GuestGrid } from "./GuestGrid"
import { FontSize } from "../../ui-kit/FontSize"
import { MarketingFormStyles } from "../../ui-kit/MarketingFormStyles"
import { MarketingButton } from "../../ui-kit/MarketingButton"
import { MarketingFooter } from "../../ui-kit/MarketingFooter"

import "./GuestDashboard.css"

export interface GuestDashboardProps {}

export interface GuestDashboardState {
    errorMessage: string | null // error message to display
    invitation: Invitation | undefined // invitation from returning a code
    isLoading: boolean // loading content
    selectedEntity: string | null // selected entity returned from the GuestSelectionDropdown callback

    courses: Course[]
    unlockedCourses: Course[]
    lockedCourses: Course[]

    name: string
    company: string
    email: string
    phone: string
    interests: string
    marketingErrorMessage: string | undefined
    marketingSuccessMessage: string | undefined
}

export class GuestDashboard extends Component<GuestDashboardProps, GuestDashboardState> {
    /**
     * Static Properties
     */
    static invitationKey = "invitationEntity"

    private guestCompositionService: IGuestDashboardCompositionService = container.get<IGuestDashboardCompositionService>(
        TYPES.IGuestDashboardCompositionService
    )
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private Marketingi18n = container.get<MarketingIi18n>(TYPES.MarketingIi18n)
    private router: IRouter = container.get<IRouter>(TYPES.IRouter)
    private storage = container.get<ILocalStorage>(TYPES.ILocalStorage)
    private mailService = container.get<IMailService>(TYPES.IMailService)

    state: GuestDashboardState = {
        errorMessage: null,
        invitation: undefined,
        isLoading: false,
        selectedEntity: null,

        courses: [],
        unlockedCourses: [],
        lockedCourses: [],

        name: "",
        company: "",
        email: "",
        phone: "",
        interests: "",
        marketingErrorMessage: undefined,
        marketingSuccessMessage: undefined
    }

    componentDidMount() {
        log("GuestDashboard - componentDidMount")
        document.getElementById('container')?.classList.add('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.add('hidden')
		document.getElementById('navigation-bar')?.classList.add('hidden')
        this.load()
    }

    render() {
        if (this.state.isLoading) {
            return <Loader text="Loading your content" />
        }

        const pageTitle: string = `Welcome, ${this.state.selectedEntity}!`

        return (
            <>
                <Stack id="guest-dashboard-container" styles={GuestContentStyles.stackStyles} tokens={GuestContentStyles.guestHeaderTokens}>
                    <Image id='guest-navigation-dts-logo' src={logoLarge} alt={this.i18n.get(i18nKey.altDtsLogo)} onClick={() => this.router.goToTestimonials()} />
                    <hr id="guest-demo-top-header-rule" style={GuestContentStyles.guestTopHeaderRuleStyle} />

                    {this.state.errorMessage && <GuestDashboardError message={this.state.errorMessage} />}

                    {!this.state.invitation && <GuestEnterCode onSubmitClick={this.guestEnterCodeOnClickHandler} />}

                    {!this.state.errorMessage && !this.state.selectedEntity && this.state.invitation && (
                        <GuestSelectionDropdown
                            onSubmitClick={this.guestSelectionDropdownOnClickHandler}
                            entities={this.state.invitation.entities}
                        />
                    )}

                    {this.state.selectedEntity && this.state.invitation && (
                        <>
                            <Stack horizontal styles={GuestContentStyles.guestPreviewStackStyles} tokens={GuestContentStyles.guestPreviewStackTokens}>
                                <Stack styles={GuestContentStyles.titleTextStackStyle} tokens={GuestContentStyles.guestPreviewTextStackTokens}>
                                    <Text id="guest-demo-page-title" variant={FontSize.xLarge}>
                                        {pageTitle}
                                    </Text>
                                    <Text id="guest-demo-marketing-advice-text" variant={FontSize.xLarge} styles={{ root: { fontWeight: "bold" } }}>
                                        {this.state.invitation.hookText}
                                    </Text>
                                    <Text id="guest-demo-action-text" variant={FontSize.xLarge}>
                                        {this.i18n.get(i18nKey.clickTheCardToPreviewTrainings)}
                                    </Text>
                                    <Stack.Item align="start">
                                        <Image id='click-right-arrow' src={rightArrow} width="10vw" />
                                    </Stack.Item>
                                </Stack>
                                <GuestGrid content={this.state.unlockedCourses} areGridItemsClickable onClick={this.courseClicked} />
                            </Stack>
                            <Stack
                                horizontal
                                horizontalAlign={"center"}
                                tokens={GuestContentStyles.guestHeaderTokens}
                                styles={GuestContentStyles.guestHeaderContainerStyle}
                            >
                                <hr id="guest-demo-left-side-header-rule" style={GuestContentStyles.guestSmallHeaderRuleStyle} />
                                <Text id="guest-demo-browse-through-text" styles={GuestContentStyles.browseHeaderTextStyle}>
                                    {this.i18n.get(i18nKey.browseThroughOurOtherTrainings)}
                                </Text>
                                <hr id="guest-demo-right-side-header-rule" style={GuestContentStyles.guestSmallHeaderRuleStyle} />
                            </Stack>
                            <Stack styles={GuestContentStyles.guestDashboardStackStyles} id="guest-content">
                                {this.state.courses && (
                                    <Stack styles={GuestContentStyles.lockedContentStyle}>
                                        <GuestGrid content={this.state.lockedCourses} areGridItemsClickable={false} onClick={this.courseClicked} />
                                    </Stack>
                                )}
                            </Stack>
                            <Stack id="request-a-demo" styles={GuestContentStyles.guestDashboardFormStackStyles}>
                                <Text id="header-line-1" styles={GuestContentStyles.formHeaderTextStyles}>
                                    Looking for how DTS can better support your organization?
                                </Text>
                                <Text id="header-line-2" styles={GuestContentStyles.formHeaderTextStyles}>
                                    Connect with a member of our team.
                                </Text>

                                <form style={GuestContentStyles.guestDashboardFormStyle} onSubmit={this.onFormSubmit}>
                                    <TextField
                                        label={this.i18n.get(i18nKey.name)}
                                        styles={GuestContentStyles.textFieldStyles}
                                        onChange={this.nameTextFieldChanged}
                                        value={this.state.name}
                                        required
                                        type='text'
                                    />
                                    <TextField
                                        label={this.Marketingi18n.get(Marketingi18nKey.company)}
                                        styles={GuestContentStyles.textFieldStyles}
                                        onChange={this.companyTextFieldChanged}
                                        value={this.state.company}
                                        required
                                        type='text'
                                    />
                                    <TextField
                                        label={this.Marketingi18n.get(Marketingi18nKey.email)}
                                        styles={GuestContentStyles.textFieldStyles}
                                        onChange={this.emailTextFieldChanged}
                                        value={this.state.email}
                                        required
                                        type='email'
                                    />
                                    <TextField
                                        label={this.Marketingi18n.get(Marketingi18nKey.phone)}
                                        styles={GuestContentStyles.textFieldStyles}
                                        onChange={this.phoneTextFieldChanged}
                                        value={this.state.phone}
                                        type='text'
                                    />
                                    <TextField
                                        label={this.Marketingi18n.get(Marketingi18nKey.interests)}
                                        styles={GuestContentStyles.textFieldStyles}
                                        onChange={this.interestsTextFieldChanged}
                                        value={this.state.interests}
                                        type='text'
                                    />

                                    {this.state.marketingErrorMessage !== undefined && (
                                        <MessageBar
                                            messageBarType={MessageBarType.error}
                                            dismissButtonAriaLabel="Close"
                                            onDismiss={this.dismissMarketingBanner}
                                            styles={MarketingFormStyles.messageBarStyles}
                                        >
                                            {this.state.marketingErrorMessage}
                                            <a href='mailto:hello@cyborgmobile.com'>
                                                {this.Marketingi18n.get(Marketingi18nKey.cyborgMobileContactEmail)}
                                            </a>
                                        </MessageBar>
                                    )}

                                    {this.state.marketingSuccessMessage !== undefined && (
                                        <MessageBar
                                            messageBarType={MessageBarType.success}
                                            dismissButtonAriaLabel="Close"
                                            onDismiss={this.dismissMarketingBanner}
                                            styles={MarketingFormStyles.messageBarStyles}
                                        >
                                            {this.state.marketingSuccessMessage}
                                        </MessageBar>
                                    )}

                                    <MarketingButton
                                        style={GuestContentStyles.guestDashboardFormSubmitStyle}
                                        id='guest-demo-form-submit-button'
                                        text={this.Marketingi18n.get(Marketingi18nKey.submit).toUpperCase()}
                                        type='submit'
                                        width='90%'
                                    />
                                </form>
                            </Stack>
                        </>
                    )}
                </Stack>
                <div id="guest-marketing-footer">
                    <MarketingFooter />
                </div>
            </>
        )
    }

    /**
     * Obtains a valid invitation given a valid URL code parameter or locally stored invitation entity.
     * Then loads guest content.
     */
    load = (): void => {
        if (this.isInvitationActive()) {
            this.setState({
                selectedEntity: this.storage.get(GuestDashboard.invitationKey)
            })
        }

        this.obtainGuestContent()
    }

    /**
     * Helper function to obtain an invitation given an invitation code.
     *
     * @param code the invitation code from which to obtain an invitation
     */
    private obtainGuestContent = (code?: string) => {
        this.setState({ isLoading: true })

        this.guestCompositionService
            .getGuestData(code)
            .then((result) => {
                this.setState({
                    courses: result.courses,
                    unlockedCourses: result.unlockedCourses,
                    lockedCourses: result.lockedCourses,
                    invitation: result.invitation,
                    errorMessage: null,
                    isLoading: false
                })
            })
            .catch((error) => {
                let errorMessage = this.i18n.get(i18nKey.guestDashboardError)

                if (error.message.toLowerCase() === this.i18n.get(i18nKey.invalidInvitation).toLowerCase()) {
                    // invalid invitation
                    errorMessage = error.message
                } else if (error.message.toLowerCase() === this.i18n.get(i18nKey.expiredCode).toLowerCase()) {
                    // expired invitation
                    errorMessage = error.message
                }

                this.setState({
                    errorMessage: errorMessage,
                    isLoading: false
                })
            })
    }

    /**
     * Handles the instructions for when the GuestEnterCode component is utilized
     * @param code an invitation code entered via the GuestEnterCode component
     */
    guestEnterCodeOnClickHandler = (code : string) => {
        this.obtainGuestContent(code)
    }

    /**
     * Handles the instructions for when the GuestSelectionDropdown component is utilized
     * @param entity the name of an entity selected via the GuestSelectionDropdown component
     */
    guestSelectionDropdownOnClickHandler = (entity : string) => {
        this.updateSelectedEntity(entity)
    }

    /**
     * Updates the selected entity when a dropdown selection is made.
     *
     * @param entity the entity name to update
     */
    updateSelectedEntity = (entity: string): void => {
        this.setState({ selectedEntity: entity })
        this.storage.save(GuestDashboard.invitationKey, entity)
    }

    /**
     * Checks if an invitation entity is stored locally or not.
     *
     * @returns true if an invitation entity is saved
     * false otherwise
     */
    private isInvitationActive(): boolean {
        return this.storage.get(GuestDashboard.invitationKey) !== null
    }

    /**
     * Dismisses the marketing banner.
     */
    dismissMarketingBanner = () => {
        this.setState({ marketingErrorMessage: undefined, marketingSuccessMessage: undefined })
    }

    onFormSubmit = (e: FormEvent) => {
        e.preventDefault()
        let formData: Dictionary = {
            name: this.state.name,
            company: this.state.company,
            email: this.state.email,
            phone: this.state.phone,
            interests: this.state.interests
        }

        this.mailService
            .sendMarketingDemoEmail(formData)
            .then(() => {
                this.setState({ 
                    name: '',
                    company: '', 
                    email: '', 
                    phone: '', 
                    interests: '', 
                    marketingErrorMessage: undefined,
                    marketingSuccessMessage: this.Marketingi18n.get(Marketingi18nKey.marketingFormSuccessMessage)
                })
            })
            .catch((_) => {
                this.setState({
                    marketingErrorMessage: this.Marketingi18n.get(Marketingi18nKey.marketingFormErrorMessage)
                })
            })
    }

    nameTextFieldChanged = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ name: newValue ? newValue : '' })
    }

    companyTextFieldChanged = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ company: newValue ? newValue : '' })
    }

    emailTextFieldChanged = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ email: newValue ? newValue : '' })
    }

    phoneTextFieldChanged = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ phone: newValue ? newValue : '' })
    }

    interestsTextFieldChanged = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ interests: newValue ? newValue : '' })
    }

    /**
     * Navigates to a particular course if available.
     *
     * @param e the React.MouseEvent to handle
     * @returns void or navigates to the course if available
     */
    courseClicked = (e: React.MouseEvent<HTMLDivElement>) => {
        const courseID = e.currentTarget.id
        const course = this.state.courses.find((course) => course.documentId === courseID)

        if (course !== undefined) {
            this.goToGuestCourse(course)
        }
    }

    /**
     * Helper function to navigate to an appropriate course.
     *
     * @param course the course to which to navigate
     */
    private goToGuestCourse(course: Course) {
        if (!course.locked) {
            const data = { course: course, entity: this.state.selectedEntity }
            this.router.goToGuestCourse(data)
        } else {
            this.scrollToForm()
        }
    }

    /**
     * Helper function navigate user to the marketing form.
     */
    private scrollToForm = () => {
        const form = document.getElementById("request-a-demo")

        if (form) {
            form.scrollIntoView({ behavior: "smooth" })
        }
    }
}
