import React, { Component, MouseEvent } from "react";
import IClientService from "../../../services/ClientService";
import {
	Stack,
	IStackTokens,
	Spinner,
	MessageBar,
	MessageBarType,
	Label,
	Checkbox,
} from "@fluentui/react";
import { TabPanelProps } from "../TabPanelProps";
import "./EditClientDetails.css";
import Client from "../../../models/Client";
import { TextField } from '../../../ui-kit/TextField'
import { PrimaryButton } from "../../../ui-kit/PrimaryButton"
import { container } from "../../../DIContainer";
import { TYPES } from "../../../Types";

interface EditClientDetailsProps extends TabPanelProps {
    documentId: string;
	client: Client
	clientUpdated: (message?: string) => void
}

interface EditClientDetailsState {
	name: string;
	description: string;
	districtId: string
	active: boolean
	updateButtonDisabled: boolean;
	showSpinner: boolean;
	errorMessage: string | undefined;
	successMessage: string | undefined;
}

const stackStyling: IStackTokens = {
	childrenGap: "20px",
};

export default class EditClientDetails extends Component<EditClientDetailsProps, EditClientDetailsState> {
	private clientService: IClientService = container.get<IClientService>(TYPES.IClientService)

	state: EditClientDetailsState = {
		updateButtonDisabled: true,
		showSpinner: false,
		name: this.props.client.name,
		description: this.props.client.description,
		districtId: this.props.client.cleverDistrictId,
		active: this.props.client.active,
		errorMessage: undefined,
		successMessage: undefined,
	};

	render() {
		let banner = null;
		if (this.state.errorMessage) {
			banner = (
				<MessageBar
					id="edit-client-error-banner"
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.errorMessage}
				</MessageBar>
			);
		} else if (this.state.successMessage) {
			banner = (
				<MessageBar
					id="edit-client-success-banner"
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			);
		}

		return (
			<div id="edit-client-modal-container">
				<Stack tokens={stackStyling}>
					<Label>Update the client name and description</Label>
					<TextField
						id="edit-client-name-textfield"
						label="Name"
						onChange={this.nameTextFieldChanged}
						value={this.state.name}
					/>
					<TextField
						id="edit-client-description-textfield"
						label="Description"
						onChange={this.descriptionTextFieldChanged}
						value={this.state.description}
					/>
					<TextField
						id="edit-client-district-id-textfield"
						label="Clever District Id"
						onChange={this.districtIdTextFieldChanged}
						value={this.state.districtId}
					/>

					<Checkbox 
						id="active-checkbox" 
						label="Active" 
						checked={this.state.active} 
						onChange={this.checkboxChanged} 
					/>

					{banner}

					{ !this.state.showSpinner &&
						<PrimaryButton
							id="edit-client-primary-button"
							text="Update"
							onClick={this.updateButtonClicked}
							disabled={this.state.updateButtonDisabled}
							hidden={this.state.showSpinner}
						/>						
					}

					{ this.state.showSpinner &&
						<Spinner
							id="edit-client-spinner"
							label="Updating client..."
							ariaLive="assertive"
							labelPosition="right"
						/>
					}
				</Stack>
			</div>
		);
	}

	dismissBanner = () => {
		this.setState({ errorMessage: undefined, successMessage: undefined });
	}

	updateButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: undefined,
			successMessage: undefined,
			showSpinner: true,
			updateButtonDisabled: true,
		});

		this.clientService
			.updateClient(
				this.props.documentId,
				this.state.name,
				this.state.description,
				this.state.districtId,
				this.state.active
			)
			.then(() => {
				const message = this.state.name + " has been updated";
				this.props.clientUpdated()
				this.setState({
					successMessage: message,
					showSpinner: false,
					updateButtonDisabled: false,
				});
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showSpinner: false,
					updateButtonDisabled: false,
				});
			});
	}

	nameTextFieldChanged = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return;
		}

		this.setState({ name: newValue }, () => {
			this.enableUpdateButtonIfNeeded();
		});
	}

	descriptionTextFieldChanged = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return;
		}

		this.setState({ description: newValue }, () => {
			this.enableUpdateButtonIfNeeded();
		});
	}

	districtIdTextFieldChanged = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return;
		}

		this.setState({ districtId: newValue }, () => {
			this.enableUpdateButtonIfNeeded();
		});
	}

	checkboxChanged = (
		_: React.FormEvent<HTMLElement> | HTMLInputElement | undefined, 
		isChecked: boolean | undefined) => {
		if (isChecked === undefined) {
			return
		}

		this.setState({ active: isChecked }, () => {
			this.enableUpdateButtonIfNeeded()
		})
	}

	enableUpdateButtonIfNeeded = () => {
		let disable = true;

		if (this.state.name.length > 0 || 
			this.state.description.length > 0 || 
			this.state.districtId.length > 0 ||
			this.state.active !== this.props.client.active) {
			disable = false;
		}

		this.setState({ updateButtonDisabled: disable });
	}
}
