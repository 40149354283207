import { IStackStyles, ITextStyles, IImageStyles, IButtonStyles, IModalStyles } from '@fluentui/react'

export class LandingVideoStyles {

    static descriptionStackStyles: IStackStyles = {
        root: {
            selectors: {
                "@media(max-width: 600px)": {
                    position: "relative"
                }
            },

            position: "absolute",
            paddingTop: "4vw",
            zIndex: 1
        }
    }

    static playIconStackStyles: IStackStyles = {
        root: {
            selectors: {
                "@media(max-width: 600px)": {
                    paddingTop: "85vw"
                }
            },

            position: "absolute",
            paddingTop: "20vw",
            zIndex: 1
        }
    }

    static imageStackStyles: IStackStyles = {
        root: {
            width: "100%"
        }
    }

    static headerTextStyles: ITextStyles = {
        root: {
            selectors: {
                "@media(max-width: 600px)": {
                    color: "#000000",
                    fontSize: "6vw"
                }
            },

            color: "#FFFFFF",
            fontFamily:"Noto Sans JP, sans-serif",
            fontWeight: 900,
            fontSize: "3.3vw",
            textAlign: "center",
            paddingBottom: "1.5vw"
        }
    }

    static descriptionTextStyles: ITextStyles = {
        root: {
            selectors: {
                "@media(max-width: 600px)": {
                    color: "#7C7C7C",
                    fontSize: "6.5vw",
                    width: "80%"
                }
            },

            color: "#FFFFFF",
            fontFamily:"Lato, sans-serif",
            fontWeight: 400,
            fontSize: "1.5vw",
            width: "55%",
            textAlign: "center",
            opacity: 0.8,
            paddingBottom: "6vw"
        }
    }

    static backgroundImageStyles: Partial<IImageStyles> = {
        image: {
            selectors: {
                "@media(max-width: 600px)": {
                    height: "85vw",
                    objectFit: "cover"
                }
            },
        }
    }

    static playIconStyles: Partial<IImageStyles> = {
        image : {
            selectors: {
                ":hover": {
                    cursor: "pointer"
                },
                "@media(max-width: 600px)": {
                    width: "25vw"
                }
            },

            width: "6vw"
        }
    }

    static cancelIconStyles: IButtonStyles = {
        root: {
            position:'relative',
            float: 'right',
            color: 'white !important'
        },
        rootHovered: {
            backgroundColor: "black",
            opacity: 0.95,
        },
        rootPressed: {
            backgroundColor: "black",
            opacity: 0.95,
        }
    }

    static modalStyles: Partial<IModalStyles> = {
        root: {
            selectors: {
                '& video': {
                    outline: "none"
                }
            },

            backgroundColor: "black",
        },
        main: {
            width: "65%",
            backgroundColor: "black",
        }
    }
}