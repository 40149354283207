import { injectable } from 'inversify'
import { container } from '../DIContainer'
import { TYPES } from '../Types'
import { Uid } from '../global/TypeAliases'
import { IEventReportingService } from './EventReportingService'
import { IFirebase } from './Firebase'
import { log } from '../global/Logger';

export interface IRegistrationTokenService {
	/**
	 * Create a token from user information
	 * @param firstName user first name
	 * @param lastName user last name
	 * @param emailAddress user email address
	 * @param clientId client id that added the user
     * @param timestamp time token is created
	 */
    createToken(firstName: string, lastName: string, emailAddress: string, clientId: string, timestamp: string): string

	/**
	 * Parse a given token to get user data 
	 * @param string registration token
	 */
    parseToken(token: string): RegistrationTokenData | null

    /**
     * Creates a new document in the registration-token collection
     * for a user e.g. 
     * {
     *     uid: 'uid123',
     *     token: 'abcdefg'
     * }
     * @param uid uid of the user
     * @param token registration token for the user
     */
    uploadRegistrationToken(uid: Uid, token: string): Promise<void> 

    /**
     * Deletes all registration token records associated with the uid.
     * There could be more than 1 if the client admin set registration 
     * reminders, or if the client admin has opted to resend the onboarding
     * email
     * @param uid uid of the user
     */
    deleteRegistrationTokenFor(uid: Uid): Promise<void> 
}

const tokenDelimiter = "*"

export class RegistrationTokenData {
    firstName: string
    lastName: string
    emailAddress: string
    clientId: string
    timestamp: string

    constructor(firstName: string, lastName: string, emailAddress: string, clientId: string, timestamp: string) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.emailAddress = emailAddress;
        this.clientId = clientId;
        this.timestamp = timestamp;
    }
}

@injectable()
export class RegistrationTokenService implements IRegistrationTokenService {
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)
    private firebase: IFirebase = container.get<IFirebase>(TYPES.IFirebase)

    createToken(firstName: string, lastName: string, emailAddress: string, clientId: string, timestamp: string) : string {
        let tokenValueString = [firstName.toLowerCase(), lastName.toLowerCase(), emailAddress.toLowerCase(), clientId, timestamp].join(tokenDelimiter)
        return Buffer.from(tokenValueString).toString("base64")
    }

    parseToken(token: string) : RegistrationTokenData | null {
		let asciiString = Buffer.from(token, "base64").toString("ascii")
		let values = asciiString.split(tokenDelimiter)
        if (values.length !== 5) {
            return null
        }
        return new RegistrationTokenData(this.capitalize(values[0]), this.capitalize(values[1]), values[2].toLowerCase(), values[3], values[4])
    }

    capitalize(value: string) : string {
        if (typeof value !== 'string') return ''
            return value.charAt(0).toUpperCase() + value.slice(1)
    }

    uploadRegistrationToken(uid: Uid, token: string): Promise<void> {
        return new Promise((resolve, reject) => {
            let postAuthToken = this.firebase.app.functions().httpsCallable("postAuthToken")
            const data = {
                uid: uid,
                token: token
            }

            postAuthToken(data).then(() => {
                log("RegistrationTokenService - uploadRegistrationToken() - Success")
                resolve()
            }).catch(error => {
                this.eventReportingService.warn(error.message, error)
                log("RegistrationTokenService - uploadRegistrationToken() - " + error)
                reject(error)
            })
        })
    }

    deleteRegistrationTokenFor(uid: Uid): Promise<void> {
        return new Promise((resolve, reject) => {
            this.firebase.db 
                .collection('registration-token')
                .doc(uid)
                .delete()
                .then(() => {
                    log("RegistrationTokenService - deleteRegistrationTokenFor() - Success ")
                    resolve()
                }).catch(error => {
                    const errorMessage = error.message + " - RegistrationTokenService - deleteRegistrationTokenFor() - Failure"
               		this.eventReportingService.error(errorMessage, error)
                    log("RegistrationTokenService - deleteRegistrationTokenFor() - " + error)
                    reject(error)
                })
        })
    }
}