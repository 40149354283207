import React, { Component, MouseEvent } from "react";
import { container } from "../../DIContainer";
import {
	Stack,
	IStackTokens,
	MessageBar,
	MessageBarType,
	Label,
} from "@fluentui/react";
import "./ReportUserDetailsModal.css";
import { TYPES } from "../../Types";
import Client from "../../models/Client";
import { TextField } from '../../ui-kit/TextField'
import { ILRSService } from "../../services/LRSService";
import Course from "../../models/Course";
import { Checkbox, Spinner } from "@fluentui/react";
import { PrimaryButton } from "../../ui-kit/PrimaryButton";
import { IFeatureFlagService } from "../../services/FeatureFlagService";
import { i18nKey, Ii18n } from "../../global/i18n";

interface ReportUserDetailsModalProps {
	userId: string;
	clientId: string;
	course: Course;
	details: { [key: string]: string };
}

interface ReportUserDetailsModalState {
	closeButtonDisabled: boolean;
	errorMessage: string | undefined;
	successMessage: string | undefined;
	selectedClient: Client | undefined;
	lessonActivityId: string
	numberOfAttempts: number
	progress: number


	// completion override
	checkboxChecked: boolean
	overrideButtonDisabled: boolean
	showSpinner: boolean
}

const stackStyling: IStackTokens = {
	childrenGap: "20px",
};

export class ReportUserDetailsModal extends Component<ReportUserDetailsModalProps, ReportUserDetailsModalState> {
	private lrsService: ILRSService = container.get<ILRSService>(TYPES.ILRSService)
	private featureFlagService: IFeatureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	state = {
		closeButtonDisabled: false,
		errorMessage: undefined,
		successMessage: undefined,
		selectedClient: undefined,
		lessonActivityId: this.i18n.get(i18nKey.notAvailableNA),
		numberOfAttempts: 0,
		progress: 0,

		//completion override
		checkboxChecked: false,
		overrideButtonDisabled: true,
		showSpinner: false
	};

	componentDidMount() {
		this.load()
	}

	render() {
		return (
			<div id="report-user-modal-container">
				<Stack id="report-user-details-stack" tokens={stackStyling}>
					<Label>
						Learner Report
					</Label>
					<TextField
						label="Name"
						value={this.props.details.name}
						readOnly={true}
					/>
					<TextField
						label="Email address"
						value={this.props.details.emailAddress}
						readOnly={true}
					/>
					<TextField
						label="Last Lesson Taken"
						value={this.state.lessonActivityId}
						readOnly={true}
					/>

					<TextField
						label="Number of Attempts"
						value={this.state.numberOfAttempts.toString()}
						readOnly={true}
					/>

					<TextField
						label="Completed Training?"
						value={this.props.details.completed}
						readOnly={true}
					/>

					<TextField
						label="Progress on Training"
						value={this.state.progress.toString() + "%"}
						readOnly={true}
					/>		

					{ this.featureFlagService.markAsComplete() && this.props.details.completed.toLowerCase() !== "yes" &&
						<>	
							<Label id="reportUserDetailsOverrideWarningLabel">
								{this.i18n.get(i18nKey.reportUserDetailsPostRequestCheckboxLabel)}
							</Label>

							<Checkbox
								id="activate-button-checkbox"
								label="Enable Override"
								checked={this.state.checkboxChecked}
								onChange={this.checkboxChanged} 
							/>


							{ !this.state.showSpinner &&
								<PrimaryButton
									id="mark-as-complete-button"
									text="Mark As Complete"
									onClick={this.overrideButtonClicked}
									disabled={this.state.overrideButtonDisabled}
									hidden={this.state.showSpinner} 
								/>
							}

							{ this.state.showSpinner &&
								<Spinner
									id="mark-as-complete-spinner"
									label="Processing..."
									ariaLive="assertive"
									labelPosition="right" 
								/>
							}
						</>
					}

						{this.createBanner()}
				</Stack>
			</div>
		);
	}
	
	private load = () => {
		if (this.props.details.completed === "Yes") {
			this.setState({progress: 100})
		} else {
			this.lrsService
			.getProgressionOnCourseForUser(this.props.userId, this.props.clientId, this.props.course.lrsId)
			.then(statements => {
				let highestProgress = statements

				if (highestProgress !== undefined && highestProgress.length > 0 && highestProgress[0].progress !== null) {
					let highestProgressPercentage: number = highestProgress[0].progress

					this.setState({
						progress: highestProgressPercentage
					})
				}
			})
			.catch(error => {
				let errorMessage = "Failed to retrieve your current progress."

				/**
				 * Code 66 means the client id doesn't exist in the LRS
				 */
				if (error.message.includes("code 66")) {
					errorMessage = error.message
				}

				this.setState(
					{
						errorMessage: errorMessage 
					}
				)
			})
		}

		this.lrsService
		.getLastLessonOnCourse(this.props.userId, this.props.clientId, this.props.course.lrsId)
		.then(statements => {
			if(statements.length > 0 && this.props.course.lessonNames[statements[0].activityId] !== undefined) {
				this.setState( {
					lessonActivityId: this.props.course.lessonNames[statements[0].activityId]
				})
		}
		}).catch(error => {
			let errorMessage = "Failed to load last lesson. Learner hasn't completed a lesson yet."

			/**
			 * Code 66 means the client id doesn't exist in the LRS
			 */
			if (error.message.includes("code 66")) {
				errorMessage = error.message
			}

			this.setState({
				errorMessage: errorMessage 
			})
		})

		this.lrsService
		.getNumberOfAttemptsOnCourse(this.props.userId, this.props.clientId, this.props.course.lrsId)
		.then(statements => {
			this.setState(
				{
					numberOfAttempts: statements.length
				}
			)
		}).catch(error => {
			let errorMessage = "Failed to load last lesson. Learner hasn't completed a lesson yet."

			/**
			 * Code 66 means the client id doesn't exist in the LRS
			 */
			if (error.message.includes("code 66")) {
				errorMessage = error.message
			}

			this.setState({
				errorMessage: errorMessage 
			})
		})
	}

	dismissBanner = () => {
		this.setState({ errorMessage: undefined, successMessage: undefined });
	};

	overrideButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: undefined,
			successMessage: undefined,
			showSpinner: true,
			overrideButtonDisabled: true,
		});

		this.lrsService
			.postCompletionOverride(
				this.props.userId,
				this.props.clientId,
				this.props.course.lrsId
			)
			.then(() => {
				const message = this.props.details.name + " has now completed the course!";
				this.setState({
					successMessage: message,
					showSpinner: false,
					checkboxChecked: false,
				});
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showSpinner: false,
					checkboxChecked: false,
				});
			});
	}

	checkboxChanged = (
		_: React.FormEvent<HTMLElement> | HTMLInputElement | undefined, 
		isChecked: boolean | undefined) => {
		if (isChecked === undefined) {
			return
		}

		this.setState({ checkboxChecked: isChecked }, () => {
			this.enableOverrideButtonIfNeeded()
		})
	}

	enableOverrideButtonIfNeeded = () => {
		let disabled = true;

		if (this.state.checkboxChecked) {
			disabled = false;
		}

		this.setState({ overrideButtonDisabled: disabled });
	}

	createBanner = () => {
		if (this.state.errorMessage) {
			return (
				<MessageBar
					id="report-user-details-modal-error-banner"
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.errorMessage}
				</MessageBar>
			);
		} else if (this.state.successMessage) {
			return (
				<MessageBar
					id="report-user-details-modal-success-banner"
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			);
		}

		return null;
	};
}