import { Component } from "react"
import { Pivot, PivotItem } from "@fluentui/react";
import "./EditLearningPlan.css";

import { LearningPlan } from "../../../models/LearningPlan";
import { EditLearningPlanDetails } from "./EditLearningPlanDetails"
import { AddCohortToLearningPlan } from "./AddCohortToLearningPlan"
import { RemoveCohortFromLearningPlan } from "./RemoveCohortFromLearningPlan"

import { container } from "../../../DIContainer";
import { TYPES } from "../../../Types";
import { i18nKey, Ii18n } from "../../../global/i18n"
import { DocumentId } from "../../../global/TypeAliases";
import PageTitle from "../../../ui-kit/PageTitle";

interface EditLearningPlanProps {
	documentId: DocumentId | undefined;
	clientId: DocumentId
	learningPlan: LearningPlan;
}

export class EditLearningPlan extends Component<EditLearningPlanProps> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	render() {
		if (this.props.documentId === undefined) {
			return null
		}

		return (
			<>
				<PageTitle title={this.i18n.get(i18nKey.editLearningPlan)} />
				<div className="container">
					<Pivot aria-label="edit learning plan admin panels">
						<PivotItem headerText={this.i18n.get(i18nKey.learningPlanEditDetails)}>
							<EditLearningPlanDetails
								title="Edit Details Panel"
								index={0}
								documentId={this.props.documentId}
								clientId={this.props.clientId}
								learningPlan={this.props.learningPlan}
							/>
						</PivotItem>
						<PivotItem headerText={this.i18n.get(i18nKey.learningPlanAddGroups)}>
							<AddCohortToLearningPlan
								title={`${this.i18n.get(i18nKey.addGroups)} Panel`}
								index={1}
								documentId={this.props.documentId}
							/>
						</PivotItem>
						<PivotItem headerText={this.i18n.get(i18nKey.learningPlanRemoveGroups)}>
							<RemoveCohortFromLearningPlan
								title={`${this.i18n.get(i18nKey.removeGroups)} Panel`}
								index={2}
								documentId={this.props.documentId}
							/>
						</PivotItem>
					</Pivot>
				</div>
			</>
		)
	}
}