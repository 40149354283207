import { Component } from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import PageTitle from "../../ui-kit/PageTitle";
import UsersAdmin from "./users/UsersAdmin";
import CohortsAdmin from "./cohorts/CohortsAdmin";
import { ClientContextProp } from "./WithClientContext";
import "./AdminPages.css"

interface LearnersPageProps extends ClientContextProp {}

interface LearnersPageState {}

export class LearnersPage extends Component<LearnersPageProps, LearnersPageState> {
	render() {
		return (
			<>
                <PageTitle title="Learners" />
                <div className="container">
                    <Pivot 
                        id="admin-panel-container" 
                        aria-label="admin panels" 
                    >
                        <PivotItem headerText="Learners">
                            <UsersAdmin title="Learners Admin Panel" index={1} clientId={this.props.clientId}/>
                        </PivotItem>
                        <PivotItem headerText="Groups">
                            <CohortsAdmin title="Groups Admin Panel" index={2} clientId={this.props.clientId}/>
                        </PivotItem>
                    </Pivot>
                </div>
			</>
		)
	}
}
