import { IIconStyles, IStackTokens, ITooltipHostStyles } from "@fluentui/react";
import { Theme } from "../../../../ui-kit/Theme";

export class MultichoicePropertiesPanelStyles {
    static hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'center' } };

    static randomizeChoicesCalloutProps = {
        gapSpace: 0,
        // If the tooltip should point to an absolutely-positioned element,
        // you must manually specify the callout target.
        target: `#randomize-choices-information-icon`,
    }

    static stackStyling: IStackTokens = {
        childrenGap: "10px",
    }

    static toolTipIconStyles: IIconStyles = {
        root: {
            color: Theme.color.primary,
            fontSize: 20,
            justifyContent: 'left',
            marginTop: "3px"
        },
    }
}