import { Dictionary } from '../global/TypeAliases';
import { TableColumn } from 'react-data-table-component';

export default class DTSTableData {
	columns: TableColumn<Dictionary>[]
    data: Dictionary[]

    constructor(columns: TableColumn<Dictionary>[], data: Dictionary[]) {
        this.columns = columns
        this.data = data
    }

    static create(): DTSTableData {
        return new DTSTableData([], [])
    }
}