import React, { Component } from "react";
import { container } from "../../DIContainer";
import { TYPES } from "../../Types";
import { Modal } from "reactstrap";
import { Ii18n, i18nKey } from "../../global/i18n";
import firebase from "firebase";
import UpdatePasswordForm  from "../authentication/UpdatePasswordForm";

interface ProfilePasswordUpdateV2Props {
    fbUser: firebase.User | null;
    isOpen: boolean;
    onClose: () => void;
}

export default class ProfilePasswordUpdateV2 extends Component<ProfilePasswordUpdateV2Props> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    render() {
        return (
            <>
                <div>
                    <Modal isOpen={this.props.isOpen} toggle={this.props.onClose} centered={true}>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0">{this.i18n.get(i18nKey.updatePassword)}</h5>
                            <button
                                type="button"
                                onClick={this.props.onClose}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <UpdatePasswordForm fbUser={this.props.fbUser} />
                        </div>
                    </Modal>
                </div>
            </>
        );
    }
}