import User from "./User"

export class LRSStatement {
    id: string
    createdDate: Date
    activityId: string
    clientId: string
    userId: string
    activityType: ActivityType
    experienceType: ExperienceType
    completed: boolean
    progress: number | null
    duration: number | null // given as seconds

    constructor(
        id: string, 
        createdDate: Date, 
        activityId: string, 
        clientId: string,
        userId: string,
        activityType: ActivityType,
        experienceType: ExperienceType,
        completed: boolean,
        progress: number | null,
        duration: number | null) {
        this.id = id
        this.createdDate = createdDate
        this.activityId = activityId
        this.clientId = clientId
        this.userId = userId
        this.activityType = activityType
        this.experienceType = experienceType
        this.completed = completed
        this.progress = progress
        this.duration = duration
    }
}

export class LRSCompletionStatement {
    user: User
    data: LRSStatement | undefined

    constructor(data: LRSStatement | undefined, user: User) {
        this.data = data
        this.user = user
    }
}

export enum ExperienceType {
    attempted = "attempted",
    progressed = "progressed",
    passed = "passed",
    experienced = "experienced",
    unhandled = "na"
}

export enum ActivityType {
    course = "course",
    cModule = "module",
    survey = "survey",
    unhandled = "na"
}