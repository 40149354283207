import { ISurveyParser } from './../models/SurveyParser'
import { LearningPlan } from './../models/LearningPlan';
import { DocumentId } from './../global/TypeAliases';
import { log } from '../global/Logger';
import { container } from '../DIContainer';
import { IFirebase } from './Firebase';
import { TYPES } from '../Types';
import { injectable } from 'inversify';
import { IAuth } from '../auth/Auth';
import { ILearningPlanParser } from '../models/LearningPlanParser';
import { ICourseParser } from '../models/CourseParser';
import Cohort from '../models/Cohort';
import { IEventReportingService } from '../services/EventReportingService'
import { ContentType, IContent } from '../models/IContent';
import Course from '../models/Course';
import { Survey } from '../models/Survey';

export interface ILearningPlanService {
    /**
     * Get learning plans associated with a client
     * @param clientId The client id
     */
	getLearningPlansFor(clientId: DocumentId): Promise<LearningPlan[]>

	/**
     * Get learning plans associated with a user
     */
	learningPlansForCurrentUser(): Promise<LearningPlan[]>
	
	/**
	 * Add new learning plan
	 * @param clientId The client document id
	 * @param name The learning plan name
	 * @param description The learning plan description
	 * @param contents An array of IContent e.g. Survey, Course, etc
	 */
	addLearningPlan(
		clientId: DocumentId,
		name: string, 
		description: string, 
		contents: IContent[]
	): Promise<void>

	/**
	 * Update learning plan details
     * @param documentId The document id
	 * @param name The learning plan name
	 * @param description The learning plan description
	 * @param contents An array of IContent e.g. Survey, Course, etc
	 */
	updateLearningPlan(
		documentId: DocumentId, 
		name: string, 
		description: string, 
		contents: IContent[]
	): Promise<void>

	/**
	 * Add cohorts to a learning plan
	 * @param cohortDocumentIds cohorts to add document ids
	 * @param learningPlanDocumentId The learning plan document id
	 */
	addCohortsToLearningPlan( cohortDocumentIds: DocumentId[], learningPlanDocumentId: DocumentId): Promise<void>

	/**
	 * Add cohort to a set of learning plans
	 * @param cohortDocumentIds cohort to add document id
	 * @param learningPlanDocumentId learning plan document ids to add
	 */
	addLearningPlansToCohort( cohortDocumentId: DocumentId, learningPlanDocumentIds: DocumentId[]): Promise<void>

	/**
	 * Remove cohorts from a learning plan
	 * @param cohortDocumentIds The cohort document ids
	 * @param learningPlanDocumentId The learning plan document id
	 */
	removeCohortsFromLearningPlan(
		cohortDocumentIds: DocumentId[],
		learningPlanDocumentId: DocumentId
	): Promise<void>

	/**
	 * Remove cohort from selected learning plan
	 * @param cohortDocumentId The cohort document id
	 * @param learningPlanDocumentIds The learning plan document ids
	 */
	removeLearningPlansFromCohort(
		cohortDocumentId: DocumentId,
		learningPlanDocumentIds: DocumentId[]
	): Promise<void>

	/**
	 * Delete the learning plan and removes the assigned cohorts from it.
	 * @param id The learning plan document id
	 */
	deleteLearningPlan(id: DocumentId): Promise<void>

	/**
	 * Get groups for a learning plan
	 * @param documentId The document id of the learning plan
	 */
	getGroupsForLearningPlan(documentId: DocumentId): Promise<Cohort[]>

	/**
	 * Get unique learning plans for a cohort document id
	 * @param cohortId cohort document id
	 */
	learningPlansForCohort(cohortId: string): Promise<LearningPlan[]>

	/**
	 * Gets a learning plan by document id
	 * @param documentId learning plan documentId
	 */
	getLearningPlanByDocumentId(documentId: DocumentId): Promise<LearningPlan>

	/**
	 * Updates the contents array of a learning plan with a current copy of the content
	 * @param contentLrsId The lrsId of the content to be updated
	 * @param clientId clientId of the content to be updated
	 */
	updateLearningPlanContentInfo(contentLrsId: string, clientId: string): Promise<void>
}

@injectable()
export class LearningPlanService implements ILearningPlanService {
	private firebase = container.get<IFirebase>(TYPES.IFirebase)
	private auth = container.get<IAuth>(TYPES.IAuth)
	private lpParser = container.get<ILearningPlanParser>(TYPES.ILearningPlanParser)
	private courseParser = container.get<ICourseParser>(TYPES.ICourseParser)
	private surveyParser = container.get<ISurveyParser>(TYPES.ISurveyParser)
    private learningPlanCollection = "learning-plans-v2"
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)

    getLearningPlansFor(clientId: DocumentId): Promise<LearningPlan[]> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection(this.learningPlanCollection)
				.withConverter(LearningPlan.Converter)
				.where(`clients.${clientId}`, "==", true)
				.get()
				.then((querySnapshot) => {
                    let result: LearningPlan[] = []
                    
					querySnapshot.forEach(doc => {
						let item: LearningPlan = doc.data()
						item.documentId = doc.id
						result.push(item)
					})

					log("LearningPlanService - getLearningPlansFor() - Success")
					resolve(result)
				})
				.catch(error => {
                	this.eventReportingService.error(error.message, error)
					log(`LearningPlanService - getLearningPlansFor() - Failed - ${error.message}`)
					reject(error)
				})
		})
	}

	/* istanbul ignore next */
	learningPlansForCurrentUser(): Promise<LearningPlan[]> {
		if (!this.auth.isLoggedIn()) {
			return Promise.reject("No user logged in")
		}

		return new Promise((resolve, reject) => {
			let get = this.firebase.app.functions().httpsCallable("learningPlansForUser")

			log("Getting learning plans for " + this.auth.uid())
			
			get({ uid: this.auth.uid() })
				.then((result) => {
					log("LearningPlanService - learningPlansForCurrentUser() - Success")
					const learningPlans = Object.keys(result.data).map((id) => {
						return this.lpParser.parse(id, result.data[id], this.courseParser, this.surveyParser)
					})

					learningPlans.sort((lhs, rhs) => {
						return lhs.name > rhs.name ? 1 : -1
					})
					resolve(learningPlans)
				})
				.catch(error => {
                	this.eventReportingService.error(error.message, error)
					log("LearningPlanService - learningPlansForCurrentUser() - Failed: " + error)
					reject(error)
				})
		})
	}
		
	addLearningPlan(
		clientId: DocumentId, 
		name: string, 
		description: string, 
		contents: IContent[]): Promise<void> {
		// eslint-disable-next-line
		const contentsData = contents.map(item => { 
			if (item.contentType() === ContentType.course) {
				return Course.Converter.toFirestore(item as Course)
			} else if (item.contentType() === ContentType.survey) {
				return Survey.Converter.toFirestore(item as Survey)
			}
		})

		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection(this.learningPlanCollection)
				.add({
					clients: { [clientId]: true },
					name: name,
					description: description,
					contents: contentsData
				})
				.then(() => {
					log("LearningPlanService - addLearningPlan() - Success")
					resolve()
				})
				.catch(error => {
                	this.eventReportingService.error(error.message, error)
					log(`LearningPlanService - addLearningPlan() - Failed - ${error.message}`)
					reject(error)
				})
		})
	}
		
	updateLearningPlan(
		documentId: DocumentId, 
		name: string, 
		description: string, 
		contents: IContent[]
	): Promise<void> {
		// eslint-disable-next-line
		const contentsData = contents.map(item => { 
			if (item.contentType() === ContentType.course) {
				return Course.Converter.toFirestore(item as Course)
			} else if (item.contentType() === ContentType.survey) {
				return Survey.Converter.toFirestore(item as Survey)
			}
		})

		return new Promise((resolve, reject) => {
			this.firebase.db
                .collection(this.learningPlanCollection)
                .doc(documentId)
				.update({
					name: name,
					description: description,
					contents: contentsData
				})
				.then(() => {
					log("LearningPlanService - updateLearningPlan() - Success")
					resolve()
				})
				.catch(error => {
                	this.eventReportingService.error(error.message, error)
					log(`LearningPlanService - updateLearningPlan() - Failed - ${error.message}`)
					reject(error)
				})
		})
	}

	/* istanbul ignore next */
	addCohortsToLearningPlan(
		cohortDocumentIds: DocumentId[],
		learningPlanDocumentId: DocumentId): Promise<void> {
		var cohortMap: { [key: string]: boolean } = {}

		cohortDocumentIds.forEach((documentId) => {
			cohortMap[documentId] = true
		})

		var learningPlanMap: { [key: string]: boolean } = {}
		learningPlanMap[learningPlanDocumentId] = true

		return new Promise((resolve, reject) => {
			let add = this.firebase.app
				.functions()
				.httpsCallable("addCohortsToLearningPlan")

			add({
				learningPlanDocumentId: learningPlanDocumentId,
				cohorts: cohortMap,
				learningPlans: learningPlanMap,
			})
			.then(function (result) {
				log("LearningPlanService - addCohortsToLearningPlan() - Success")
				resolve()
			})
			.catch(error => {
                this.eventReportingService.error(error.message, error)
				log("LearningPlanService - addCohortsToLearningPlan() - Failed: " + error)
				reject(error)
			})
		})
	}

	/* istanbul ignore next */
	async addLearningPlansToCohort(
		cohortDocumentId: DocumentId,
		learningPlanDocumentIds: DocumentId[]
	): Promise<void> {
		let addLearningPlansPromises = learningPlanDocumentIds.map((learningPlanDocumentId: DocumentId) => {
            return this.addCohortsToLearningPlan([cohortDocumentId], learningPlanDocumentId)
        })

        return await Promise.all(addLearningPlansPromises)
            .then((_) => {
				log("LearningPlanService - addLearningPlansToCohort() - Success!")
				return Promise.resolve()
            })
            .catch(error => {
                this.eventReportingService.error(error.message, error)
				log("LearningPlanService - addLearningPlansToCohort() - Failed: " + error)
				return Promise.reject(error)
            })
	}

	/* istanbul ignore next */
	removeCohortsFromLearningPlan(
		cohortDocumentIds: DocumentId[],
		learningPlanDocumentId: DocumentId
	): Promise<void> {
		var cohortMap: { [key: string]: boolean } = {}

		cohortDocumentIds.forEach((documentId) => {
			cohortMap[documentId] = true
		})

		return new Promise((resolve, reject) => {
			let remove = this.firebase.app
				.functions()
				.httpsCallable("removeCohortsFromLearningPlan")

			remove({ cohorts: cohortMap, learningPlanDocumentId: learningPlanDocumentId })
				.then(() => {
					log("LearningPlanService - removeCohortsFromLearningPlan - Success")
					resolve()
				})
				.catch(error => {
                	this.eventReportingService.error(error.message, error)
					log("LearningPlanService - removeCohortsFromLearningPlan - Failed: " + error)
					reject(error)
				})
		})
	}

	/* istanbul ignore next */
	async removeLearningPlansFromCohort(
		cohortDocumentId: DocumentId,
		learningPlanDocumentIds: DocumentId[]
	): Promise<void> {
		let removeLearningPlansPromises = learningPlanDocumentIds.map((learningPlanDocumentId: DocumentId) => {
            return this.removeCohortsFromLearningPlan([cohortDocumentId], learningPlanDocumentId)
        })

        return await Promise.all(removeLearningPlansPromises)
            .then((_) => {
				log("LearningPlanService - removeLearningPlansFromCohort() - Success!")
				return Promise.resolve()
            })
            .catch(error => {
                this.eventReportingService.error(error.message, error)
				log("LearningPlanService - removeLearningPlansFromCohort() - Failed: " + error)
				return Promise.reject(error)
            })
	}

	/* istanbul ignore next */
	deleteLearningPlan(id: DocumentId): Promise<void> {
		let deleteLearningPlan = this.firebase.app.functions().httpsCallable("deleteLearningPlan")
		return new Promise((resolve, reject) => {
			deleteLearningPlan({ documentId: id })
				.then((result) => {
					log("LearningPlanService - deleteLearningPlan - Success")
					resolve()
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log(`LearningPlanService - deleteLearningPlan - Failed - ${error}`)
					reject(error)
				})
		})
	}

	getGroupsForLearningPlan(documentId: DocumentId): Promise<Cohort[]> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection("cohort")
				.withConverter(Cohort.Converter)
				.where(`learningPlans.${documentId}`, "==", true)
				.get()
				.then((querySnapshot) => {
					let result: Cohort[] = []

					querySnapshot.forEach(doc => {
						let item: Cohort = doc.data()
						item.documentId = doc.id
						result.push(item)
					})

					log("LearningPlanService - getCohortsForLearningPlan - Success")
					resolve(result)
				})
				.catch(error => {
                	this.eventReportingService.error(error.message, error)
					log("LearningPlanService - getCohortsForLearningPlan - Failed: " + error)
					reject(error)
				})
		})
	}

	learningPlansForCohort(cohortId: string): Promise<LearningPlan[]> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection(this.learningPlanCollection)
				.withConverter(LearningPlan.Converter)
				.where(`cohorts.${cohortId}`, "==", true)
				.get()
				.then((querySnapshot) => {
					var result: LearningPlan[] = []
					querySnapshot.forEach(function (doc) {
						let item: LearningPlan = doc.data()
						item.documentId = doc.id
						result.push(item)
					})

					log("LearningPlanService - learningPlanForCohort() - Success")
					resolve(result)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("LearningPlanService - learningPlanForCohort() - Failed")
					reject(error)
				})
		})
	}

	getLearningPlanByDocumentId(documentId: DocumentId): Promise<LearningPlan> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection(this.learningPlanCollection)
				.doc(documentId)
				.withConverter(LearningPlan.Converter)
				.get()
				.then((documentSnapshot) => {					
					log("Success: LearningPlanService - getLearningPlanByDocumentId()")
					
					if (documentSnapshot.data() === undefined) {
						throw new Error("No learning plan in document snapshot")
					}

					const learningPlan = this.lpParser.parse(documentSnapshot.id, documentSnapshot.data() as LearningPlan, this.courseParser, this.surveyParser)

					resolve(learningPlan)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("LearningPlanService - getLearningPlanByDocumentId() - Failed")
					reject(error)
				})
		})
	}

	updateLearningPlanContentInfo(documentId: string, clientId: string): Promise<void> {
		let updateLearningPlanContentInfo = this.firebase.app.functions().httpsCallable("updateLearningPlanContentInfo")

		return new Promise((resolve, reject) => {
			updateLearningPlanContentInfo({documentId: documentId, clientId: clientId})
				.then(() => {
					log("LearningPlanService - updateLearningPlanContentInfo - Success")
					resolve()
				})
				.catch(error => {
					this.eventReportingService.error(error.message, error)
					log("LearningPlanService - updateLearningPlanContentInfo - Failed: " + error)
					reject(error)
				})
		})
	}
}