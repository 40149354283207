import { ISpinnerStyles } from "@fluentui/react"

export class UserUploadStyles {
    static labelStyles = {
        root: {
            paddingLeft: 50
        }
    }

    static stackTokens = {
        childrenGap: 30
    }

    static csvErrorStyle = {
        root: {
            backgroundColor: '#CC3D3D',
            color: '#ffffff',
            padding: '30px 50px',
            borderRadius: 5,
            margin: '30px 50px'
        }
    }

    static spinnerStyle: Partial<ISpinnerStyles> = {
        root: {
            justifyContent: "flex-start"
        }
    }
}