import { Component } from "react"
import { container } from "../../DIContainer"
import React from "react"
import Course, { CourseType } from "../../models/Course"
import { LearningPlan } from "../../models/LearningPlan"
import { TYPES } from "../../Types"
import { Stack } from "@fluentui/react"
import { log } from "../../global/Logger"
import { Dictionary, DocumentId } from "../../global/TypeAliases"
import { IFeatureFlagService } from "../../services/FeatureFlagService"
import { IRouter } from "../../Router"
import { IDashboardCompositionService } from "../../services/DashboardCompositionService"
import { i18nKey, Ii18n } from "../../global/i18n"
import { IUserService } from "../../services/UserService"
import { IAuth } from "../../auth/Auth"
import { Survey } from "../../models/Survey"
import { PathNames } from "../../PathNames"
import { DocumentDeletedOrNotFound } from "../../ui-kit/DocumentDeletedOrNotFound"
import CancelablePromise from "cancelable-promise"
import { IPromiseHelper } from "../../services/PromiseHelper"
import { IAuthService } from "../../services/AuthService"
import { TrainingGrid } from "./TrainingGrid"
import { LoaderV2 } from "../../ui-kit/LoaderV2"
import { UncontrolledAlert } from "reactstrap"
import { SurveyGridV2 } from "./SurveyGridV2"
import { LearningPlanGridV2 } from "./LeaningPlanGridV2"
import { CMModal } from "../../ui-kit/Modal"
import UpdatePasswordForm from "../authentication/UpdatePasswordForm"
import { ILocalStorage } from "../../utils/LocalStorage"
import User from "../../models/User"

interface DashboardPageProps {}

export interface DashboardPageState {
	trainings: Course[]
	learningPlans: LearningPlan[]
	surveys: Survey[]
	surveyCompletionData: Dictionary
	userClients: DocumentId[]
	errorMessage: string | undefined
	compositionServiceComplete: boolean
	deadlines: Dictionary
	learningPlanCompletionData: Dictionary
	clientIsActive: boolean | undefined
    updatePasswordReminderModalOpen: boolean
    updatePasswordModalOpen: boolean
	user: User | null
}

export class DashboardPageV2 extends Component<DashboardPageProps, DashboardPageState> {
	private featureFlagService: IFeatureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService)
	private router: IRouter = container.get<IRouter>(TYPES.IRouter)
	private compositionService: IDashboardCompositionService = container.get<IDashboardCompositionService>(TYPES.IDashboardCompositionService)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
	private userService: IUserService = container.get<IUserService>(TYPES.IUserService)
	private auth: IAuth = container.get<IAuth>(TYPES.IAuth)
	private authService: IAuthService = container.get<IAuthService>(TYPES.IAuthService)
	private promiseHelper: IPromiseHelper = container.get<IPromiseHelper>(TYPES.IPromiseHelper)
	private verificationPromise: CancelablePromise | undefined
	private storage: ILocalStorage = container.get<ILocalStorage>(TYPES.ILocalStorage)
	private showUpdatePWModalStorageKey = "showUpdatePWModal"

	state: DashboardPageState = {
		trainings: [],
		learningPlans: [],
		surveys: [],
		userClients: [],

		// error messages and service calls
		errorMessage: undefined,
		compositionServiceComplete: false,

		// deadlines
		deadlines: {},

		// completions
		surveyCompletionData: {},
		learningPlanCompletionData: {},

		//client is active
		clientIsActive: false,

		// update password states
		updatePasswordReminderModalOpen: false,
		updatePasswordModalOpen: false,
		user: null,
	}

	componentDidMount() {
		log('Dashboard - componentDidMount')

		if (this.auth.user?.cohorts) {
			this.verificationPromise = this.promiseHelper.makeCancelable(this.userService.verifyUserClientIsActive(this.auth.user?.cohorts, this.auth.user?.role))
			this.verificationPromise
				.then((isActive): Promise<any> => {
                    if (isActive) {
                        return this.compositionService.getData()
                    } else {
                        return Promise.resolve(false)
                    }
                }).then(result => {
                    if (typeof(result) === "boolean") {
                        this.setState({
                            clientIsActive: false,
                            compositionServiceComplete: true
                        })
                    } else {
                        this.setState({ 
                            trainings: result.courses, 
                            learningPlans: result.learningPlans, 
                            surveys: result.surveys,
                            surveyCompletionData: result.surveyCompletionData,
                            userClients: result.associatedClientsOfUser, 
                            deadlines: result.deadlines,
                            learningPlanCompletionData: result.learningPlanCompletions,
                            compositionServiceComplete: true,
                            clientIsActive: true,
							user: result.user
                        })

						if (this.featureFlagService.onboardingV2() && result.user && result.user.tempPassword && this.storage.get(this.showUpdatePWModalStorageKey) === "true") {
							this.setState({
								updatePasswordReminderModalOpen: true
							})
						}
                    }
                })
				.catch(error => {
                    let errorMessage = this.i18n.get(i18nKey.dashboardPageFailedToLoad)

                    /**
                     * Code 66 means the client id doesn't exist in the LRS
                     */
					if (error.message !== undefined) {		
						if (error.message.includes(this.i18n.get(i18nKey.code66))) {
							errorMessage = error.message
						} else if (error.message.includes(this.i18n.get(i18nKey.moreThanOneUserWithUidFoundError))) {
							errorMessage = this.i18n.get(i18nKey.unableToLoadDashboardNoUniqueUserError)
						} else if (error.message.includes(this.i18n.get(i18nKey.noUserWithUidFoundError))) {
							this.promiseHelper.makeCancelable(this.authService.logout())
								.then(_ => {
									this.router.goToLogin({reroutedToPageError: this.i18n.get(i18nKey.uidNotFoundError)})
								}).catch(_ => {
									/**
									 * Silent Fail
									*/
								})
						}
					}

                    this.setState({
                        errorMessage: errorMessage,
						clientIsActive: true,
                        compositionServiceComplete: true
                    })
                })
        } else {
			this.setState({
				clientIsActive: false,
				compositionServiceComplete: true
			})
		}
	}
	
	componentWillUnmount(): void {
		this.promiseHelper.cancel(this.verificationPromise)
	}

	trainingClicked = (e: React.MouseEvent<HTMLDivElement>) => {
		const trainingID = e.currentTarget.id
		const training = this.state.trainings.find(
			(training) => training.documentId === trainingID
		)

		if (training === undefined) {
			return
		}

		this.goToCourse(training)
	}

	goToCourse = (training: Course) => {
		const data = { course: training }
		
		switch (training.type) {
			case CourseType.training:
				this.router.goToCourse(data)
				break
			case CourseType.googleForm:
				this.router.goToGoogleFormTraining(data)
				break
		}
	}

	surveyClicked = (e: React.MouseEvent<HTMLDivElement>) => {
		const surveyID = e.currentTarget.id
		const survey = this.state.surveys.find(
			(survey) => survey.documentId === surveyID
		)

		if (survey === undefined) {
			return
		}

		this.goToSurvey(survey)
	}

	goToSurvey = (survey: Survey) => {
		const data = { 
			documentId: survey.documentId, 
			surveyCompleted: this.state.surveyCompletionData[survey.documentId],
			learnerViewMode: false
		}
		
		this.router.goToViewSurvey(data)
	}

	learningPlanClicked = (e: React.MouseEvent<HTMLDivElement>) => {
		log("Learning Plan clicked: " + e.currentTarget.id)

		const lpID = e.currentTarget.id
		const learningPlan = this.state.learningPlans.find(
			(learningPlan) => learningPlan.documentId === lpID
		)

		if (learningPlan === undefined) {
			return
		}

		const data = { 
			navigationItems: [
				{ text: 'Dashboard', key: 'Dashboard', href: PathNames.Dashboard },
				{ text: 'Learning Plans', key: 'LearningPlans', href: PathNames.LearningPlans, isCurrentItem: true }
			],
			learningPlanId: learningPlan.documentId,
			learningPlanName: learningPlan.name,
			userClientIds: this.state.userClients
		}
		
		this.router.goToLearningPlans(data)
	}

	onUpdatePasswordModalDismiss = () => {
		this.setState({
			updatePasswordReminderModalOpen: false
		})
		
		this.storage.delete(this.showUpdatePWModalStorageKey)
	}

	render() {
		if (!this.state.compositionServiceComplete) {
			return (
				<LoaderV2 text={this.i18n.get(i18nKey.loadingYourContent)}/>
			)
		} else {
			return (
				<div id="dashboard-page-container">
					{ this.state.errorMessage !== undefined && (
						<Stack styles={{ root: { paddingLeft: 50, paddingTop: 20, paddingRight: 20 }}}>
							<UncontrolledAlert id="dashboard-general-error-banner" color="danger">{this.state.errorMessage}</UncontrolledAlert>
						</Stack>
					)}
					{ this.state.learningPlans.length > 0 &&
						<div id="learning-plan-grid-container">
							<Stack styles={{root: {paddingLeft: 50, paddingTop: 20, paddingBottom: 30}}}>
								<h2 className="mt-2 mb-4">Learning Plans</h2>
								<LearningPlanGridV2
									learningPlans={this.state.learningPlans}
									completions={this.state.learningPlanCompletionData}
									deadlines={this.state.deadlines}
									onLearningPlanClick={this.learningPlanClicked}
								/>
							</Stack>
						</div>
					}
					{ (this.state.trainings.length > 0 || 
						(this.state.trainings.length === 0 && this.state.learningPlans.length === 0)) &&
						<div id="training-grid-container">
							<Stack styles={{ root: { paddingLeft: 50, paddingTop: 20, paddingBottom: 30 }}}>
								<h2 className="mt-2 mb-4">Trainings</h2>
								<TrainingGrid
									trainings={this.state.trainings}
									userClients={this.state.userClients}
									showProgressBar={true}
									shouldWrap={true}
									onTrainingClick={this.trainingClicked}
									deadlines={this.state.deadlines}
								/>
							</Stack>
						</div>
					}
					{ this.state.surveys.length > 0 && this.featureFlagService.surveysFeature() &&
						<div id="survey-grid-container">
							<Stack styles={{ root: { paddingLeft: 50, paddingTop: 20 }}}>
								<h2 className="mt-2 mb-4">Surveys</h2>
								<SurveyGridV2
									surveys={this.state.surveys}
									shouldWrap={true}
									onSurveyClick={this.surveyClicked}
									surveyCompletionData={this.state.surveyCompletionData}
									userClientIds={this.state.userClients}
								/>
							</Stack>
						</div>
					}
					{ !this.state.clientIsActive && this.state.compositionServiceComplete &&
						<>
							<Stack styles={{ root: { paddingLeft: 50, paddingTop: 20 }}}>
								<DocumentDeletedOrNotFound message={this.i18n.get(i18nKey.clientIsNotActive)}/>
							</Stack>
						</>
					}
					<CMModal 
						data-testid="update-pw-reminder-modal"
						isOpen={this.state.updatePasswordReminderModalOpen}
						toggle={() => { this.setState({updatePasswordReminderModalOpen: !this.state.updatePasswordReminderModalOpen})}}
						headerText={this.i18n.get(i18nKey.updatePasswordReminder)}
						modalBodyContent={this.i18n.get(i18nKey.updatePasswordReminderBody)}
						primaryButtonText={this.i18n.get(i18nKey.letsGo)}
						cancelButtonText={this.i18n.get(i18nKey.remindMeLater)}
						backdrop={'static'}
						onPrimaryButtonClick={() => {
							this.setState({
								updatePasswordReminderModalOpen: false,
								updatePasswordModalOpen: true,

							})
				
						}}
						onCancelButtonClick={() => {
							this.onUpdatePasswordModalDismiss()
						}}
						onCloseButtonClick={() => {
							this.onUpdatePasswordModalDismiss()
						}}
					/>
					<CMModal 
						data-testid="update-pw-modal"
						isOpen={this.state.updatePasswordModalOpen}
						headerText={this.i18n.get(i18nKey.updatePassword)}
						modalBodyContent={<UpdatePasswordForm fbUser={this.auth.firebaseUser()} />}
						backdrop={false}
						onCloseButtonClick={() => {
							this.setState({
								updatePasswordModalOpen: false
							})
						}}
					/>
				</div>
			)
		}
	}
}
