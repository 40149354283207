import { Dictionary } from './TypeAliases';
import i18nJson from '../global/i18n.json'
import { injectable } from 'inversify';

export enum i18nKey {
    client,
    login,
    signInToContinue,
    altDtsLogo,
    enterEmailAndPassword,
    stillNeedToVerifyEmail,
    sendEmailVerification,
    yourEmailAddress,
    password,
    confirmPassword,
    signIn,
    signInWithClever,
    notRegisteredQuestion,
    createAnAccount,
    forgotPassword,
    resetPassword,
    resetPasswordWithDTS,
    rememberIt,
    success,
    enterYourEmailToReset,
    verificationEmailSent,
    signUp,
    enterYourInfoToSignUp,
    firstName,
    lastName,
    emailAddress,
    alreadyHaveAnAccount,
    sendResetEmail,
    backToLogin,
    resetPasswordSentPrefix,
    resetPasswordSentExistingAccount,
    resetPasswordSentNewAccount,
    createPasswordToRegister,
    register,
    welcome,
    delete,
    completed,
    percentComplete,
    viewCourse,
    numberOfTrainings,
    noCourses,
    pleaseProvideUid,
    reportUserDetailsPostRequestCheckboxLabel,
    loadingAFewThings,
    exitSurvey,
    clientsAdminPageTitle,
    save,
    dismiss,
    content,
    removeSelected,
    activeLearners,
    close,
    yes,
    cancel,
    pleaseTryAgain,
    newPassword,
    changePassword,
    changePasswordSuccess,
    invalidActionCode,
    failedToResetPassword,
    requestNewResetLink,
    passwordsTooShort,
    passwordsDontMatch,
    pleaseSignInWithClever,
    name,
    description,
    title,
    add,
    addingGroup,
    hasBeenAdded,
    addNewGroup,
    failedToLoadGroups,
    unableToLoadGroupPleaseTryAgain,
    launchLocations,
    radioButtonProperties,
    checkboxProperties,
    openTextProperties,
    shortTextProperties,
    longTextProperties,
    addChoice,
    ratingProperties,
    lowRatingDescription,
    highRatingDescription,
    scale,
    firstChoice,
    secondChoice,
    thirdChoice,
    pleaseLogBackIn,
    loadingSurveyPleaseWait,
    selectImage,
    uploadImage,
    training,
    survey,
    selectTypeOfContentToSearch,
    addSelectedContent,
    backToListOfContents,
    searchForTrainings,
    searchForSurveys,
    searchForContent,
    loadingYourLearningPlan,
    loadingYourContent,
    helloEmailAddress,
    complete,
    saveNote,
    savingSurveyFailed,
    savingSurveySuccess,
    okay,
    completeSurveyFailed,
    completeSurveySuccess,
    dueIn,
    days,
    dueToday,
    overdue,
    dueInOneDay,
    datepickerPlaceholderText,
    sendDeadlineReminder,
    sendDeadlineReminderTooltipText,
    digitalTrainingSolutions,
    randomizeChoicesTooltipText,
    noLearningPlanContents,
    registrationReminderEmailsSuccess,
    registerNewAccountPrefix,
    registerNewAccountPostfix,
    noGroupAssigned,
    fromDate,
    toDate,
    processing,
    noMoreLearners,

    /**
     * Content Labels
     */
    startTraining,
    startSurvey,
    
    /**
     * Users Modal
     */
    deleteUserTitle,
    deleteUserMessage,

    /**
     * Clients Modal
     */
    licenseExpirationDate,
    editDetails,
    editDetailsPanel,
    licenseInfo,
    licenseInfoPanel,
    selectAnExpirationDate,
    numberOfSeatsOnTheLicense,
    notesAboutClientLicense,
    update,
    updatingClientEllipsesDots,

    /**
     * Groups modal
     */
    buttonGroupsAdd,
    placeholderGroupsSearch,
    labelGroupsAddSearch,
    labelTrainingsAddSearch,
    labelLearningPlansAddSearch,
    messageGroupAddedOne,
    messageGroupsAdded,
    learningPlansAssigned,
    trainingsAssigned,
    editGroup,
    editGroupDetails,
    addLearners,
    removeLearners,
    deleteGroupTitle,
    deleteGroupMessage,
    deleteGroupAffirmativeButton,
    deleteGroupDismissButton,
    addSelectedLearningPlansButton,
    addSelectedLearningPlansSpinner,
    addSelectedLearningPlansSearch,
    selectedLearningPlansAddedSingle,
    selectedLearningPlansAddedMultiple,
    selectedLearningPlansRemovedSingle,
    selectedLearningPlansRemovedMultiple,
    addSelectedTrainingsSearch,
    addSelectedTrainingsButton,
    addSelectedTrainingsSpinner,
    selectedTrainingsAddedSingle,
    selectedTrainingsAddedMultiple,
    selectedTrainingsRemovedSingle,
    selectedTrainingsRemovedMultiple,
    selectedSurveysAddedSingle,
    selectedSurveysAddedMultiple,
    selectedSurveysRemovedSingle,
    selectedSurveysRemovedMultiple,
    error,
    saving,
    saved,
    failedToSaveSurvey,
    clickOnInteractionToCreateSurvey,
    openText,
    shortText,
    longText,
    radioButtons,
    addSelectedSurveysButton,
    addSelectedSurveysSpinner,
    surveysAssigned,
    labelSurveysAddSearch,
    addSelectedSurveysSearch,
    choices,
    checkbox,
    rating,
    matrix,
    matrixProperties,
    columnOne,
    columnTwo,
    columnThree,
    rowOne,
    rowTwo,
    ranking,
    rankingProperties,
    theresAnIssueGettingCohortInfo,
    dropDown,
    dropDownProperties,
    true,
    false,
    hasOtherChoice,
    isRequired, 
    randomizeChoices,
    requiredErrorMessage,
    failedToDeleteSurvey,
    deleteSurvey, 
    deleteSurveyDialogMessage,
    confirmDeleteSurvey,
    viewAsLearner,
    learnerView,
    addGroups,
    addGroupsPanel,
    removeGroups,
    removeGroupsPanel,
    groupAdded,
    groupsAdded,
    addingGroups,
    updatingGroup,
    deletingGroup,
    failedToLoadGroup,
    editGroupDetailsPanelTitle,
    editGroupAddLearnerPanelTitle,
    editGroupRemoveLearnerPanelTitle,
    editGroupContentsPanelTitle,
    groupsCapitalized,
    
    /**
     * Course Modal
     */
     deleteCourseTitle,
     deleteCourseMessage,
     courseDeadlineTooltipText,
     courseDeadlineCheckboxText,
     trainingDatepickerPlaceholderText,

    /**
     * Spinner messages
     */
    spinnerSigningIn,
    spinnerCreatingYourAccount,
    spinnerRegisteringYourAccount,
    spinnerSearching,
    spinnerGroupsAdding,

    /**
     * Learning Plans
     */
    whichClientLearningPlan,
    selectAClient,
    loadingLearningPlans,
    learningPlanAddNew,
    learningPlanEditDetails,
    learningPlanAddGroups,
    learningPlanRemoveGroups,
    resumeLearningPlan,
    beginLearningPlan,
    editLearningPlan,
    updateLearningNameAndDescription,
    trainingsInPlan,
    noLearningPlansAssigned,
    learningPlanEditorSelectContent,
    comboBoxPlaceholder,

    /**
     * Learning Plan Editor
     */
    learningPlanEditorEmpty,
    deleteLearningPlanTitle,
    deleteLearningPlanMessage,
    deleteLearningPlanAffirmativeButton,
    deleteLearningPlanDismissButton,
    learningPlanDeadlineTooltipText,
    learningPlanDeadlineCheckboxText,
    learningPlanDatepickerPlaceholderText,
    contentsForLearningPlanParseError,

    /**
     * Surveys
     */
    viewSurvey,
    addInteractionPanelTitle,
    builderPanelTitle,
    settingsPanelTitle,
    surveySettingsButtonText,
    loadingSurveys,
    editingSurvey,
    newSurvey,
    addColumn,
    addRow,
    deleteInteraction,
    minSurveyDuration,
    maxSurveyDuration,
    resetSurvey,
    confirmResetSurvey,
    resetSurveyDialogMessage,
    duplicateInteraction,
    emptySurveyText, 
    failedToDuplicateSurvey,
    moveUp,
    moveDown,
    surveyInteractionPlaceholder,
    anonymousReporting,

    /**
     * Survey Reporting
     */
    questionsCapitalized,
    aggregatedSurveyResponseDataNotSupportedYet,
    generatingAggregatedResponsesLoading,
    answerChoice,
    responses,
    thereAreNoResponsesToDisplay,
    percentageOfTotal,
    numberOfResponses,
    notAnsweredSurveyData,
    total,
    totalRespondents,
    oneHundredPercentTableData,
    zeroPercentTableData,
    rank,
    rankOne,
    
    /**
     * Error messages
     */
    failedToLoadClientList,
    failedToLoadLearningPlans,
    reportCompletionFailedLoadReport,
    reportCompletionFailedLoadReportMissingClientId,
    code66,
    learningPlanDoesNotExist,
    emailIsInvalid,
    loginCredentialsFailed,
    somethingWentWrongRefresh,
    groupDoesNotExist,
    updateGroupNameAndDescription,
    resetPasswordError,
    clientIdNullErrorDialogTitle,
    clientIdNullErrorDialogMessage,
    enteredInvalidEmail,
    userUploadError,
    userUploadEmptyCSVError,
    userUpoaldMissingFields,
    userUploadDuplicateEmails,
    userUploadInvalidEmails,
    userUploadInvalidHeaderRow,
    failedToLoadSurveys,
    failedToLoadSurvey,
    failedToLoadReportsHomePageContent,
    emailImproperFormatError,
    emailNotFoundError,
    emailNotFoundReadableError,
    invalidSurveyInteractionChoiceValues,
    cleverInvalidGrantError,
    failedToSendNewAccountEmails,
    failedToSetRegistrationReminderEmails,
    failedToSendRegistrationReminerEmails,
    invalidRegistrationLink,
    invalidRegistrationLinkPasswordResetRedirect,
    moreThanOneUserWithUidFoundError,
    noUserWithUidFoundError,
    unableToLoadDashboardNoUniqueUserError,
    uidNotFoundError,
    inProgress,
    letsGetStarted,
    locked,
    resume,
    launch,
    
    /**
     * Table Search and Filter
     */
    search,
    searchForLearner,
    searchForClient,
    searchForGroup,
    searchForTraining,
    searchForLearningPlan,
    searchForSurvey,
    filterPlaceholder,
    notAvailableNA,

    /**
     * Clever SSO
     */
    singleSignOn,
    pleaseWaitWhileWeLogYouIn,
    establishingConnection,
    gatheringCleverInformation,
    syncingData,
    loggingIn,
    unableToLogYouInContactSysAdmin,

    /**
     * Global Admin Report Page
     */
    globalAdminPageTitle,
    searchForAnEntity,
    nameOfEntityToAdd,
    expiredSingleWord,
    selectAnInvitationCodeToView,

    /**
     * Report Completion Page
     */
    ratioCompletedMetricTitle,
    ratioAttemptedMetricTitle,
    averageDurationMetricTitle,
    medianDurationMetricTitle,
    reportsHomePageTitle,
    ratioSurveyCompletedMetricTitle,
    ratioSurveyAttemptedMetricTitle,
    learnerReport,
    numberOfAttempts,
    completedSurveyQuestionLabel,
    reportUserSurveyDetailsFailedToLoadAttempts,
    exportCSV,
    role,
    status,
    createdOn,

    /**
     * Charts
     */
    noDataForChartDisplay,

    /**
     * Dashboard Page
     */
    dashboardPageFailedToLoad,
    filterReportsButton,
    resetFilterButton,
    noSurveysLabel,
    clientIsNotActive,

    /**
     * Privacy Pages
     */
    submitPrivacyRequest,
    privacyEmailAddress,
    seeDataHeldCheckboxText,
    deleteDataHeldCheckboxText,
    optOutOfSaleCheckboxText,

    /**
     * Form Fields
     */
    administrator,
    submit,
    privacyFormSuccessMessage,
    privacyFormErrorMessage,

    /**
     * Select Client Context
     */
    selectClientDialogTitle,
    selectClientDialogDropdownMessage,
    selectClient,

    /**
     * Log In As
     */
    logInAsTitle,
    logInAsSearchPlaceholder,
    failedToGetLearners,
    endLogInAs,
    failedToEndLogInAs,
    failedToLogInAs,
    logInAsTheLearner,
    logInAsEmailAddress,
    logInAsFirstName,
    logInAsLastName,
    logInAsUid,
    searchLearnersBy,

    /**
     * User Uploads
     */
    chooseCSVForUpload,
    downloadCSVTemplateLabel,
    downloadCSVTemplateButtonText,
    downloadCSVTemplateError,
    uploadUsersButtonText,
    selectFile,
    fixUserUploadIssues,
    newLearnersAdded,
    existingLearnersAdded,
    registrationReminderCheckboxText,
    registrationReminderTooltipTextSingleAdd,
    registrationReminderTooltipTextMultipleAdd,
    addingMoreUsersThanAllowedError,

    /**
     * Learners Page
     */
    resendRegEmailsButtonText,
    resendRegEmailsDialogTitle,
    resendRegEmailsDialogMessage,
    resendRegEmailsDialogAffirmativeAction,
    addNewUser,

    /**
     * Guest Content
     */
    selectEntity,
    affiliation,
    affiliationPlaceholder,
    codePlaceholder,
    enterCode,
    expiredCode,
    invalidInvitation,
    guestDashboardError,
    selectOrganization,
    noGuestContent,
    letUsHelpYouMeetSB5227,
    letUsHelpYouMeetTrainingNeeds,
    clickTheCardToPreviewTrainings,
    browseThroughOurOtherTrainings,
    guestCode,
    addNewGuestCode,
    code,
    newCodeEntityHelperText,
    newCodeExpiredHelperText,
    entities,
    addEntitiesHelperText,
    addingNewGuestCodeSpinner,
    manageGuestCodes,
    selectAGuestCodeToViewAndEditItExclamation,
    codeSuccessfullyAddedExclamation,
    guestCodeHasBeenUpdatedExclamation,
    guestCodeHasFailedToUpdate,
    selectedEntitiesHaveBeenDeletedExclamation,
    codeHasBeenDeletedExclamation,
    deleteGuestCodeMessage,
    deleteGuestCodeTitle,

    /**
     * Profile
     */
    editProfile,
    updatePassword,
    editPhoto,
    profileUpdatedSuccessfully,
    profileFailedToUpdate,
    profileFailedToLoad,
    currentPassword,
    passwordUpdateError,
    uploading,

    /**
     * Learning Plan Timeline
     */
    congratulations,
    successfullyCompletedLearningPlan,
    yourLearningJourney,

    remindMeLater,
    letsGo,
    updatePasswordReminder,
    updatePasswordReminderBody
}   
export interface Ii18n {
    get(key: i18nKey): string
}

@injectable()
export class i18n implements Ii18n {
    get(key: i18nKey): string {
        return (i18nJson as Dictionary)[i18nKey[key]]
    }
}