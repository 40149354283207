import { IStackStyles } from '@fluentui/react'

export class TestimonialStyles {
    static outerStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    paddingLeft: '6vw',
                    paddingRight: '6vw',
                    paddingTop: '6vw',
                },
            },

            backgroundColor: 'white',
            backgroundPosition: 'top',
            padding: '2vw',
            paddingLeft: '16vw',
            paddingRight: '12vw',
        },
    }

    static innerStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    width: '100%',
                },
            },
        },
    }

    static cardStackStyles: IStackStyles = {
        root: {
            marginTop: '10vw !important',
            opacity: '0',
            transition: '500ms ease-in',
        },
    }

    static cardBigLogoStackStyles: IStackStyles = {
        root: {
            selectors: {
                '& img': {
                    width: '5vw',
                    selectors: {
                        '@media(max-width: 600px)': {
                            width: '25vw',
                        },
                    },
                },
            },
            marginTop: '10vw !important',
            opacity: '0',
            transition: '500ms ease-in',
        },
    }
}
