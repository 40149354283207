import React, { Component, ReactElement } from "react"
import { Stack, Image, Text } from "@fluentui/react"
import { ImageFit } from "@fluentui/react"
import { FontSize } from "../../ui-kit/FontSize"
import { GuestGridItemStyle } from "./GuestGridItemStyle"
import { IContent } from "../../models/IContent"
import lock_icon from "../../images/grey-lock-icon.svg"

interface GuestGridItemProps {
    content: IContent
    isClickable: boolean
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}

export class GuestGridItem extends Component<GuestGridItemProps> {
    render() {
        const contentName: string = `${this.props.content.name} ${this.props.content.contentType}`

        return (
            <div className="content-grid-item" id={this.props.content.documentId} onClick={this.onClickHandler}>
                <article>
                    <section>
                        <Stack id="content-grid-item-container-stack" styles={ GuestGridItemStyle.containerStyle }>
                            <Stack styles={GuestGridItemStyle.headerStyle}>
                                <Image
                                    id="content-image"
                                    src={this.props.content.imageUrl}
                                    alt={contentName}
                                    imageFit={ImageFit.centerCover}
                                    styles={GuestGridItemStyle.imageStyle}
                                />
                            </Stack>
                            <Stack tokens={GuestGridItemStyle.stackTokens} styles={GuestGridItemStyle.bodyStyle}>
                                <Text id="content-name" variant={FontSize.large}>
                                    {this.props.content.name}
                                </Text>
                            </Stack>

                            {this.footerElement()}
                        </Stack>
                    </section>
                    {!this.props.isClickable && 
                        <section>
                            <Stack>
                                <Image
                                    id="lockIcon"
                                    src={lock_icon}
                                    alt={contentName}
                                    width={"3.5em"}
                                    styles={GuestGridItemStyle.lockIcon}
                                />
                            </Stack>
                        </section>
                    }
                </article>
            </div>
        )
    }

    /**
     * Private Functions
     */

    private onClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.props.onClick(e)
    }

    private footerElement = (): ReactElement => {
        return (
            <Stack tokens={GuestGridItemStyle.stackTokens} styles={GuestGridItemStyle.footerStyle} horizontalAlign={"start"}>
                <Text id="survey-duration" variant={FontSize.medium}>{this.props.content.duration}</Text>
            </Stack>
        )
    }
}
