import { Card, CardBody, Col, Row } from "reactstrap";
import { container } from "../DIContainer";
import { TYPES } from "../Types";
import { Ii18n, i18nKey } from "../global/i18n";
import { IContent } from "../models/IContent";
import { ReactElement } from "react";

interface LearningPlanCardProps {
    content: IContent;
    contentType: string;
    imgSrc: string;
    isClickable: boolean;
    progress: number;
    isComplete: boolean;
    onContentClick: (e: React.MouseEvent<HTMLDivElement>, content: IContent) => void;
    isLeftJustified: boolean;
}

export const LearningPlanCard: React.FC<LearningPlanCardProps> = (props) => {
    const i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n);

    const { imgSrc, isComplete, progress, onContentClick, content, isClickable, isLeftJustified } = props;
    const onContentClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        onContentClick(e, content);
    };

    const renderCardFooter = () => {
        let cardFooter: ReactElement;

        if (isComplete) {
            cardFooter = (
                <h6 className="my-0 mt-2 text-secondary" data-testid="card-success-icon">
                    <i className="mdi mdi-check-all me-3"></i>
                    {i18n.get(i18nKey.completed)}
                </h6>
            );
        } else if (progress > 0) {
            cardFooter = (
                <>
                    <h6 className="my-0 mt-2 text-info" data-testid="card-in-progress-icon">
                        <i className="mdi mdi-bullseye-arrow me-3"></i>
                        {i18n.get(i18nKey.inProgress)}
                    </h6>
                    <div
                        onClick={isClickable ? onContentClickHandler : undefined}
                        data-testid="card-primary-btn-wrapper"
                    >
                        <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light mt-2"
                            data-testid="card-primary-btn"
                        >
                            {i18n.get(i18nKey.resume)}
                        </button>
                    </div>
                </>
            );
        } else if (progress === 0 && isClickable) {
            cardFooter = (
                <>
                    <h6 className="my-0 text-success" data-testid="card-not-started-icon">
                        <i className=" bx bx-run me-3"></i>
                        {i18n.get(i18nKey.letsGetStarted)}
                    </h6>
                    <div
                        onClick={isClickable ? onContentClickHandler : undefined}
                        data-testid="card-primary-btn-wrapper"
                    >
                        <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light mt-2"
                            data-testid="card-primary-btn"
                        >
                            {i18n.get(i18nKey.launch)}
                        </button>
                    </div>
                </>
            );
        } else {
            cardFooter = (
                <h6 className="my-0 mt-2 text-gray" data-testid="card-locked-icon">
                    <i className="bx bx-lock-alt me-3"></i>
                    {i18n.get(i18nKey.locked)}
                </h6>
            );
        }
        return cardFooter;
    };

    if (isLeftJustified) {
        return (
            <Col lg={12} sm={2}>
                <Card
                    onClick={isComplete && isClickable ? onContentClickHandler : undefined}
                    className="timeline-box"
                    style={{ padding: 0, width: "80%", height: "auto" }}
                    data-testid="course-card-left"
                >
                    <Row className="g-0 align-items-center">
                        <Col md={4}>
                            <img className="card-img img-fluid" data-testid="card-img" src={imgSrc} alt="" />
                        </Col>
                        <Col md={8}>
                            <CardBody>
                                <h5 className="card-title" data-testid="card-title">
                                    {content.name}
                                </h5>
                                <p className="card-text" data-testid="card-duration">
                                    <small className="text-muted">{content.duration}</small>
                                </p>
                                <div className="mt-4" data-testid="card-footer">
                                    {renderCardFooter()}
                                </div>
                            </CardBody>
                        </Col>
                    </Row>
                </Card>
            </Col>
        );
    } else {
        return (
            <Col lg={12} sm={2}>
                <Card
                    onClick={isComplete && isClickable ? onContentClickHandler : undefined}
                    className="timeline-box"
                    style={{ padding: 0, width: "80%", height: "auto" }}
                    data-testid="course-card-right"
                >
                    <Row className="g-0 align-items-center">
                        <Col md={8}>
                            <CardBody>
                                <h5 className="card-title" data-testid="card-title">
                                    {content.name}
                                </h5>
                                <p className="card-text" data-testid="card-duration">
                                    <small className="text-muted">{content.duration}</small>
                                </p>
                                <div className="mt-4" data-testid="card-footer">
                                    {renderCardFooter()}
                                </div>
                            </CardBody>
                        </Col>
                        <Col md={4}>
                            <img className="card-img img-fluid" data-testid="card-img" src={imgSrc} alt="" />
                        </Col>
                    </Row>
                </Card>
            </Col>
        );
    }
};
