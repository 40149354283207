import { IFeatureFlagService } from './FeatureFlagService'
import { IAuth } from './../auth/Auth';
import { TYPES } from '../Types';
import { container } from '../DIContainer';
import { RoleType } from '../models/RoleType';
import { injectable } from 'inversify';
import User, { UserStatus } from '../models/User';

export interface IAccessController {
    canAdministrate(): boolean
    isClientAdmin(): string | null
    canAddCourse(): boolean
    canAddClient(): boolean
    isGlobalAdmin(): boolean
    canAddAdminRole(): boolean
    canLogInAsUser(): boolean
    canSelectClientContext(): boolean
    canDeleteRegisteredUser(user: User): boolean
}

@injectable()
export class AccessController implements IAccessController {
    private auth: IAuth = container.get<IAuth>(TYPES.IAuth)
    private featureFlags: IFeatureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService)
    
    canAdministrate(): boolean {
        if (this.auth.user === undefined) {
            return false
        }

        switch (this.auth.user.role) {
            case RoleType.learner: 
                return false
            default:
                return true
        }
    }

    isClientAdmin(): string | null {
        if (this.auth.user === undefined) {
            return null
        }

        return this.auth.user.clientManaged === "" ? null : this.auth.user.clientManaged
    }

    isGlobalAdmin(): boolean {
        if (this.auth.user === undefined) {
            return false
        }

        return this.auth.user.role === RoleType.globalAdmin
    }

    canAddCourse(): boolean {
        if (this.auth.user === undefined) {
            return false
        }

        return this.auth.user.role === RoleType.globalAdmin
    }

    canAddClient(): boolean {
        if (this.auth.user === undefined) {
            return false
        }

        return this.auth.user.role === RoleType.globalAdmin
    }

    canAddAdminRole(): boolean {
        if (this.auth.user === undefined) {
            return false
        }

        return this.auth.user.role === RoleType.globalAdmin
    }

    canLogInAsUser(): boolean {
        if (this.auth.user === undefined) {
            return false
        }

        return this.auth.user.role === RoleType.globalAdmin
    }

    canSelectClientContext(): boolean {
        if (this.auth.user === undefined) {
            return false
        }

        if (!this.featureFlags.selectClientContext()) {
            return false
        }

        return this.auth.user.role === RoleType.globalAdmin
    }

    canDeleteRegisteredUser(user: User): boolean {
        if (this.auth.user === undefined) {
            return false
        }

        switch (this.auth.user.role) {
            case RoleType.clientAdmin:
                return user.status === UserStatus.Pending
            case RoleType.globalAdmin:
                return true
            default:
                return false
        }
    }
}