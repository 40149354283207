import { RouteProps, Route } from "react-router-dom"
import React from "react"
import { IAuth } from "./auth/Auth"
import { container } from "./DIContainer"
import { TYPES } from "./Types"
import { IAccessController } from "./services/AccessController"
import { IRouter } from "./Router"
import { log } from "./global/Logger"
import { PathNames } from "./PathNames"
import { Dictionary } from "./global/TypeAliases"

export interface ProtectedRouteProps extends RouteProps {
    adminRoute: boolean
}

export class ProtectedRoute extends Route<ProtectedRouteProps> {
    private auth: IAuth = container.get<IAuth>(TYPES.IAuth)
    private acl: IAccessController = container.get<IAccessController>(TYPES.IAccessController)
    private router: IRouter = container.get<IRouter>(TYPES.IRouter)

    public render() {
        log('route - logged in - ' + this.auth.isLoggedIn())
        
        const loggedIn = this.auth.isLoggedIn()
        
        if (!loggedIn) {
            this.router.goToLogin()
        }

        /**
         * If the path is to the course page
         * make sure to have a course object 
         */
        if (this.props.path === PathNames.Course 
            && !this.validCourseRoute()) {
            this.router.goToLogin()

            /**
             * render needs to return a route so we will just return
             * an empty Route component
             */
            return <Route />
        }

        if(this.props.path === PathNames.ReportCompletion && !this.validCourseRoute()) {
            this.router.goToLogin()

            /**
             * render needs to return a route so we will just return
             * an empty Route component
             */
            return <Route />
        }

        if (this.props.adminRoute) {
            if (this.acl.canAdministrate()) {
                return <Route {...this.props} />
            } else {
                this.router.goToDashboard()
            }
        }

        return <Route {...this.props}/>
    }

    private validCourseRoute(): boolean {
        const location = this.props.location

        if (!location || !location.state) {
            return false
        }

        const course = (location.state as Dictionary).course
        if (!course) {
            return false
        }

        return true
    }
}