import { ContentType, IContent } from './IContent'
import { Dictionary, LrsId } from '../global/TypeAliases'

export enum CourseType {
	training = "training",
	googleForm = "googleForm"
}

export default class Course implements IContent {
	documentId: string
	type: CourseType
	lrsId: LrsId
	name: string
	description: string
	imageUrl: string
	locationUrl: string
	cohorts: Dictionary
	clients: Dictionary
	duration: string
	folderUrl: string
	lessonNames: { [key: string]: string }
	locked?: boolean

	constructor(
		documentId: string,
		type: CourseType,
		lrsId: LrsId,
		name: string,
		description: string,
		imageUrl: string,
		locationUrl: string,
		cohorts: Dictionary,
		clients: Dictionary,
		duration: string,
		folderUrl: string,
		lessonNames: Dictionary

	) {
		this.documentId = documentId
		this.type = type
		this.lrsId = lrsId
		this.name = name
		this.description = description
		this.imageUrl = imageUrl
		this.locationUrl = locationUrl
		this.cohorts = cohorts
		this.clients = clients
		this.duration = duration
		this.folderUrl = folderUrl
		this.lessonNames = lessonNames
	}

	contentType(): ContentType {
		return ContentType.course
	}

	static Converter = {
		toFirestore: function (course: Course) {
			return {
				documentId: course.documentId,
				type: course.type,
				lrsId: course.lrsId,
				imageUrl: course.imageUrl,
				name: course.name,
				description: course.description,
				locationUrl: course.locationUrl,
				cohorts: course.cohorts,
				clients: course.clients,
				duration: course.duration,
				courseFolder: course.folderUrl, // the db calls it courseFolder
				lessonNames: course.lessonNames,
				contentType: ContentType.course
			}
		},
		fromFirestore: function (snapshot: any, options: any) {
			const data = snapshot.data(options)
			return new Course(
				data.documentId,
				data.type === undefined ? CourseType.training : data.type,
				data.lrsId === undefined ? "" : data.lrsId,
				data.name,
				data.description,
				data.imageUrl === undefined ? "" : data.imageUrl,
				data.locationUrl === undefined ? "" : data.locationUrl,
				data.cohorts === undefined ? {}: data.cohorts,
				data.clients === undefined ? {}: data.clients,
				data.duration === undefined ? "" : data.duration,
				data.courseFolder === undefined ? "" : data.courseFolder,
				data.lessonNames === undefined ? {} : data.lessonNames
			)
		},
	}
}
