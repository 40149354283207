import React, { Component, MouseEvent } from "react"
import IClientService from "../../../services/ClientService"
import {
	Stack,
	IStackTokens,
	Spinner,
	MessageBar,
	MessageBarType,
	Label,
	Checkbox
} from "@fluentui/react"
import "./NewClientModal.css"
import { TextField } from '../../../ui-kit/TextField'
import { PrimaryButton } from "../../../ui-kit/PrimaryButton"
import { TYPES } from "../../../Types"
import { container } from "../../../DIContainer"
import { ICohortService } from "../../../services/CohortService"

interface NewClientModalProps {
	clientAdded: (successMessage: string) => void
}

interface NewClientModalState {
	name: string
	description: string
	domain: string
	cleverDistrictId: string
	active: boolean
	addButtonDisabled: boolean
	showSpinner: boolean
	errorMessage: string | undefined
}

const stackStyling: IStackTokens = {
	childrenGap: "20px",
}

export default class NewClientModal extends Component<NewClientModalProps,NewClientModalState> {
	private clientService: IClientService = container.get<IClientService>(TYPES.IClientService)
	private cohortService: ICohortService = container.get<ICohortService>(TYPES.ICohortService)

	state: NewClientModalState = {
		addButtonDisabled: true,
		showSpinner: false,
		name: "",
		description: "",
		domain: "",
		active: true,
		cleverDistrictId: "",
		errorMessage: undefined,
	}

	render() {
		return (
			<div id="add-new-client-modal-container">
				<Stack tokens={stackStyling}>
					<TextField 
						label="Name" 
						onChange={this.nameTextFieldChanged} 
					/>
					<TextField
						label="Description"
						onChange={this.descriptionTextFieldChanged}
					/>
					<Label>The domain should be the domain used in a learners email address. For example the domain for a learner with the email address 'bob@cyborgmobile.com' will be 'cyborgmobile'.</Label>
					<TextField
						label="Domain"
						onChange={this.domainTextFieldChanged}
					/>

					<Label>If this client is a Clever partner please include the Clever District ID.</Label>
					<TextField
						label="Clever District ID"
						onChange={this.cleverDistrictIdTextFieldChanged}
					/>

					<Checkbox 
						id="active-checkbox" 
						label="Active" 
						defaultChecked 
						onChange={this.checkboxChanged} 
					/>

					{ this.state.errorMessage !== undefined && (
						<MessageBar
							messageBarType={MessageBarType.error}
							dismissButtonAriaLabel="Close"
							onDismiss={this.dismissErrorBanner}
						>
							{this.state.errorMessage}
						</MessageBar>
					)}

					{ !this.state.showSpinner && 
						<PrimaryButton
							id="new-client-modal-primary-button"
							text="Add"
							onClick={this.addButtonClicked}
							disabled={this.state.addButtonDisabled}
						/>
					}

					{ this.state.showSpinner && 
						<Spinner
							label="Adding client..."
							ariaLive="assertive"
							labelPosition="right"
						/>
					}
					
				</Stack>
			</div>
		)
	}

	dismissErrorBanner = () => {
		this.setState({ errorMessage: undefined })
	}

	addButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: undefined,
			showSpinner: true,
			addButtonDisabled: true,
		})

		let newClientId: string
		let newCohortId: string

		this.clientService
			.addClient(
				this.state.name, 
				this.state.description, 
				this.state.domain, 
				this.state.cleverDistrictId,
				this.state.active
			)
			.then(client => {
				newClientId = client.documentId

				const name = `Global - ${this.state.name}`
				const description = "A cohort that contains all learners."

				return this.cohortService.addCohort(name, description, newClientId)
			})
			.then((cohortDocumentId) => {
				newCohortId = cohortDocumentId

				const addCohort = this.clientService.addCohortsToClient([cohortDocumentId], newClientId)
				const assignDefault = this.clientService.assignCohortAsDefault(newCohortId, newClientId)

				return Promise.all([addCohort, assignDefault])
			})
			.then(() => {
				let message = this.state.name + " has been added"
				this.props.clientAdded(message)
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showSpinner: false,
					addButtonDisabled: false,
				})
			})
	}

	nameTextFieldChanged = (
		_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return
		}

		let val = newValue

		this.setState({ name: val }, () => {
			this.enableAddButtonIfNeeded()
		})
	}

	descriptionTextFieldChanged = (
		_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return
		}

		let val = newValue

		this.setState({ description: val }, () => {
			this.enableAddButtonIfNeeded()
		})
	}

	domainTextFieldChanged = (
		_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return
		}

		let val = newValue

		this.setState({ domain: val }, () => {
			this.enableAddButtonIfNeeded()
		})
	}

	cleverDistrictIdTextFieldChanged = (
		_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return
		}

		let val = newValue

		this.setState({ cleverDistrictId: val }, () => {
			this.enableAddButtonIfNeeded()
		})
	}

	checkboxChanged = (
		_: React.FormEvent<HTMLElement> | HTMLInputElement | undefined, 
		isChecked: boolean | undefined) => {
		if (isChecked === undefined) {
			return
		}

		this.setState({ active: isChecked })
	}

	enableAddButtonIfNeeded = () => {
		let disable = true

		if (this.state.name.length > 0 && 
			this.state.description.length > 0 &&
			this.state.domain.length > 0) {
			disable = false
		}

		this.setState({ addButtonDisabled: disable })
	}
}
