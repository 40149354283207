import { Stack, TextField, Text, Image, ImageFit, IImageStyles, StackItem, Checkbox } from "@fluentui/react";
import React, { Component, FormEvent } from "react";
import { container } from "../../../DIContainer";
import { Ii18n, i18nKey } from "../../../global/i18n";
import { Survey } from "../../../models/Survey";
import { IFeatureFlagService } from "../../../services/FeatureFlagService";
import { TYPES } from "../../../Types";
import { DurationSlider } from "../../../ui-kit/DurationSlider";
import { FontSize } from "../../../ui-kit/FontSize";
import { IURLCreator } from "../../../ui-kit/URLCreator";
import { SurveyPackage } from "./SurveyPage";

interface SurveySubPanelProps {
    didUpdateSurvey: (survey: Survey) => void
    didUpdatePreviewImage: (imageFile: File) => void
    surveyPackage: SurveyPackage
}

interface SurveySubPanelState {
    showSpinner: boolean
    isCheckboxMarked: boolean
    previewImageFile: File | null
}

export class SurveySubPanel extends Component<SurveySubPanelProps, SurveySubPanelState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private urlCreator: IURLCreator = container.get<IURLCreator>(TYPES.IURLCreator)
    private featureFlagService: IFeatureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService)

    private durationSliderMin = 0
	private durationSliderMax = 120
    private durationSliderStep = 1

    state: SurveySubPanelState = {
        showSpinner: false,
        isCheckboxMarked: this.props.surveyPackage.survey.anonymous,
        previewImageFile: null
    }

    private imageStyle: IImageStyles = {
        image: {},
        root: {
            height: 200,
            width: 300,
        }
    }
    
    render() {
        return (
            <Stack tokens={{childrenGap: 20}}>
                <Text 
                    id="title-label"
                    variant={FontSize.xLarge}>{this.i18n.get(i18nKey.settingsPanelTitle)}
                </Text>
                <TextField 
                    id="title-textfield"
                    type="text"
                    label={this.i18n.get(i18nKey.title)}
                    value={this.props.surveyPackage.survey.name}
                    onChange={this.titleTextFieldChanged}
                    required
                />
                <TextField
                    id="description-textfield"
                    type="text"
                    label={this.i18n.get(i18nKey.description)}
                    value={this.props.surveyPackage.survey.description}
                    onChange={this.descriptionTextFieldChanged}
                    required
                />
                <DurationSlider
                    useCustomStepValue
                    sliderMin={this.durationSliderMin}
                    sliderMax={this.durationSliderMax}
                    sliderStep={this.durationSliderStep}
                    initialMax={this.props.surveyPackage.survey.getDuration().max}
                    initialMin={this.props.surveyPackage.survey.getDuration().min}
                    minSliderLabel={this.i18n.get(i18nKey.minSurveyDuration)}
                    maxSliderLabel={this.i18n.get(i18nKey.maxSurveyDuration)}
                    durationValueChanged={this.durationSliderUpdated}
                />
                {this.featureFlagService.anonymousSurveyReporting() && 
                    <Checkbox 
                        id="survey-sub-panel-anonymous-survey-checkbox" 
                        label={this.i18n.get(i18nKey.anonymousReporting)}
                        checked={this.state.isCheckboxMarked}
                        onChange={this.anonymousCheckboxUpdated}
                    />
                }
                <Stack tokens={{childrenGap: 20}} horizontalAlign='center'>
                <Text variant={FontSize.large}>{this.i18n.get(i18nKey.uploadImage)}</Text>
                    <StackItem>
                        <label htmlFor="file-upload" className="inputFile">
                            {this.i18n.get(i18nKey.selectImage)}
                        </label>
                        <input
                            id="file-upload"
                            type="file"
                            accept="image/*"
                            onChange={this.fileSelectionChanged}
                            width="100%"
                        />
                    </StackItem>
                    { (this.state.previewImageFile || this.props.surveyPackage.survey.imageUrl.length > 0) &&
                        <StackItem>
                            <Image 
                                id="survey-preview-image"
                                src={this.getImageUrl()}
                                alt={this.props.surveyPackage.survey.name + " Survey"} 
                                imageFit={ImageFit.centerCover} 
                                styles={this.imageStyle}
                            />
                        </StackItem>
                    }
                </Stack>
            </Stack>    
        )
    }

    private getImageUrl = (): string => {
        if (this.state.previewImageFile) {
            return this.urlCreator.createFor(this.state.previewImageFile)
        }

        return this.props.surveyPackage.survey.imageUrl
    }

    /**
     * Public Functions
     */

    titleTextFieldChanged = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const survey = this.props.surveyPackage.survey
        survey.name = newValue ? newValue : ""

        this.props.didUpdateSurvey(survey)
	}

    descriptionTextFieldChanged = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const survey = this.props.surveyPackage.survey
        survey.description = newValue ? newValue : ""

        this.props.didUpdateSurvey(survey)
	}

    durationSliderUpdated = (newDuration: string) => {
        const survey = this.props.surveyPackage.survey
        survey.duration = newDuration ? newDuration : ""

        this.props.didUpdateSurvey(survey)
	}

    anonymousCheckboxUpdated = (_: React.FormEvent<HTMLElement> | HTMLInputElement | undefined, isChecked: boolean | undefined) => {
        if (isChecked === undefined) {
            return
        }

        const survey = this.props.surveyPackage.survey
        survey.anonymous = isChecked

        this.setState({isCheckboxMarked: isChecked})
        this.props.didUpdateSurvey(survey)
    }

    fileSelectionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault()

		if (!event.target.files || event.target.files.length === 0) {
			return
		}

		const file: File = event.target.files[0]
		this.setState({ previewImageFile: file  })

        this.props.didUpdatePreviewImage(file)
	}

}
