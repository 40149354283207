import { ActivityType } from "./LRSStatement"
import { LaunchLocations } from "./TrainingLaunchLocation"

export class LRSLaunchStatement {
    id: string
    createdDate: Date
    activityId: string
    clientId: string
    userId: string
    activityType: ActivityType
    location: LaunchLocations
    
    constructor(
        id: string, 
        createdDate: Date, 
        activityId: string, 
        clientId: string,
        userId: string,
        activityType: ActivityType,
        location: LaunchLocations) {
        this.id = id
        this.createdDate = createdDate
        this.activityId = activityId
        this.clientId = clientId
        this.userId = userId
        this.activityType = activityType
        this.location = location
    }
}