import { Component, ReactElement } from "react"
import { Icon, Image, ImageFit, ProgressIndicator, Stack, Text } from "@fluentui/react"
import { FontSize } from "../../../ui-kit/FontSize"
import { IContent } from "../../../models/IContent"
import { i18nKey, Ii18n } from "../../../global/i18n"
import { container } from "../../../DIContainer"
import { TYPES } from "../../../Types"
import { LearningPlanContentItemStyles } from "./LearningPlanContentItemStyles"
import progression_arrow from "../../../images/content/arrow-right-black.svg"
import './LearningPlanContentItem.css'


interface LearningPlanContentItemProps {
    content: IContent
    contentType: string
    contentImageSrc: string
    isClickable: boolean
    showProgressionArrow: boolean
    progress: number
    isComplete: boolean
    onContentClick: (e: React.MouseEvent<HTMLDivElement>, content: IContent) => void
}

interface LearningPlanContentItemState {}

export class LearningPlanContentItem extends Component<LearningPlanContentItemProps, LearningPlanContentItemState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    render() {
        return (
            <div 
                className="lp-content-item"
                onClick={ this.props.isClickable ? this.onContentClickHandler : undefined }
            >
                <Stack id="content-grid-item-container-stack" horizontal>
                    <div id='content-grid-item-progression-arrow-container'>
                        { this.props.showProgressionArrow &&
                            <Image
                                className="show-progression-arrow"
                                src={progression_arrow}
                                alt={"Content Progression Arrow"}
                                styles={this.props.isClickable ? 
                                    LearningPlanContentItemStyles.showGreenProgressionArrowImageStyle : LearningPlanContentItemStyles.showRedProgressionArrowImageStyle
                                }
                            />
                        }
                        { !this.props.showProgressionArrow &&
                            <Image
                                className="no-show-progression-arrow"
                                src={progression_arrow}
                                alt={"Proceed to first content in plan"}
                                styles={LearningPlanContentItemStyles.noShowProgressionArrowImageStyle}
                            />
                        }
                    </div>
                    <Stack 
                        id="lp-content-item-card-stack-container" 
                        styles={this.props.isClickable ? LearningPlanContentItemStyles.containerStyle : LearningPlanContentItemStyles.greyedOutContainerStyle}
                    >
                        <Stack styles={LearningPlanContentItemStyles.headerStyle}>
                            <Image 
                                id="lp-content-grid-item-image"
                                src={this.props.contentImageSrc}
                                alt={this.props.content.name + " content"} 
                                imageFit={ImageFit.centerCover} 
                                styles={LearningPlanContentItemStyles.imageStyle}
                            />
                        </Stack>
                        <Stack tokens={LearningPlanContentItemStyles.stackTokens} styles={LearningPlanContentItemStyles.bodyStyle}>
                            <Text id="lp-content-item-name" variant={FontSize.large}>
                                {this.props.content.name}
                            </Text>
                        </Stack>
                        {this.renderProgressBar()}
                        <Stack horizontal={true} tokens={LearningPlanContentItemStyles.stackTokens} styles={LearningPlanContentItemStyles.footerStyle}>
                            <Text id="lp-item-duration" variant={FontSize.medium}>{this.props.content.duration}</Text>
                        </Stack> 
                    </Stack>
                </Stack>
            </div>  
        )
    }

    private renderProgressBar = (): ReactElement | null => {
        let progressBar: ReactElement
        let progress = Math.trunc(this.props.progress)
    
        if (this.props.isComplete) {
            progressBar =   <Stack 
                                id="lp-item-completed-progress" 
                                tokens={LearningPlanContentItemStyles.stackTokens} 
                                styles={LearningPlanContentItemStyles.bodyStyle}
                            >
                                <ProgressIndicator percentComplete={100} barHeight={4}/>
                                <Stack styles={LearningPlanContentItemStyles.bodyStyle} horizontal={true}>
                                    <Icon id="completed-icon" iconName="Completed" styles={LearningPlanContentItemStyles.completedIcon} />
                                    <div id="completed-label" style={LearningPlanContentItemStyles.completedDiv}>
                                        {this.i18n.get(i18nKey.completed)}
                                    </div>
                                </Stack>
                            </Stack>
        } else {
            progressBar =   <Stack 
                                id="lp-item-progressed-progress" 
                                tokens={LearningPlanContentItemStyles.stackTokens} 
                                styles={LearningPlanContentItemStyles.footerStyle}
                            >
                                <ProgressIndicator 
                                    description={progress + this.i18n.get(i18nKey.percentComplete)} 
                                    percentComplete={progress/100} 
                                    barHeight={4}
                                />
                            </Stack>
        }

        return progressBar
    }

    onContentClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.props.onContentClick(e, this.props.content)
    }
}