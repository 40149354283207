import { Dictionary, DocumentId } from './../global/TypeAliases';
import Client from "./Client";
import { injectable } from "inversify";

export interface IClientParser {
	parse(documentId: DocumentId, data: Dictionary): Client;
}

@injectable()
export class ClientParser implements IClientParser {
	parse(documentId: DocumentId, data: Dictionary): Client {
		return new Client(
            documentId,
            data.name,
            data.description,
            data.imageUrl,
            data.domains === undefined ? {} : data.domains,
            data.defaultCohort,
            data.cleverDistrictId === undefined ? "" : data.cleverDistrictId,
            data.active === undefined ? false : data.active,
            data.licenseSeatCount,
            data.validUntilDate,
            data.licenseNotes
        )
	}
}
