import { IImageStyles, IStackStyles } from "@fluentui/react"

export class ImageGridStyles {
    static stackStyle: IStackStyles = {
        root: {
            height: '100%',
            width: '100%'
        },
    }

    /**
     * Default Image
     */

    static noImageStyle: Partial<IImageStyles> = {
        image: {
            width: 100,
            height: 100
        },
        root: {
            height: '100%',
            width: '100%',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px'
        }
    }

    /**
     * One Image
     */

    static oneImageStyle: Partial<IImageStyles> = {
        root: {
            height: '100%',
            width: '100%',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px'
        }
    }

    /**
     * Two Images
     */

    static twoTopImageStyle: Partial<IImageStyles> = {
        root: {
            height: '50%',
            width: '100%',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px'
        }
    }

    static twoBottomImageStyle: Partial<IImageStyles> = {
        root: {
            height: '50%',
            width: '100%'
        }
    }

    /**
     * Three Images
     */

    static twoHorizontalStackStyle: IStackStyles = {
        root: {
            height: '50%',
            width: '100%'
        },
    }

    static threeLeftImageStyle: Partial<IImageStyles> = {
        root: {
            height: '100%',
            width: '50%',
            borderTopLeftRadius: '10px'
        }
    }

    static threeRightImageStyle: Partial<IImageStyles> = {
        root: {
            height: '100%',
            width: '50%',
            borderTopRightRadius: '10px'
        }
    }

    static threeBottomImageStyle: Partial<IImageStyles> = {
        root: {
            height: '50%',
            width: '100%'
        }
    }

    /**
     * Four Images
     */

    static fourImageLeftStyle: Partial<IImageStyles> = {
        root: {
            height: '50%',
            width: '50%',
            borderTopLeftRadius: '10px'
        }
    }

    static fourImageRightStyle: Partial<IImageStyles> = {
        root: {
            height: '50%',
            width: '50%',
            borderTopRightRadius: '10px'
        }
    }

    static fourImageStyle: Partial<IImageStyles> = {
        root: {
            height: '50%',
            width: '50%'
        }
    }
}