import { Checkbox, IStackTokens, MessageBar, MessageBarType, Spinner, Stack, Text } from "@fluentui/react"
import React, { useEffect, useState } from "react"
import { container } from "../../DIContainer"
import { i18nKey, Ii18n } from "../../global/i18n"
import { Invitation } from "../../models/Invitation"
import { IInvitationCodeService } from "../../services/InvitationCodeService"
import { TYPES } from "../../Types"
import PageTitle from "../../ui-kit/PageTitle"
import { PrimaryButton } from "../../ui-kit/PrimaryButton"
import { TextField } from "../../ui-kit/TextField"
import { IStringHelpers } from "../../utils/StringHelpers"
import "./NewInvitationCodeModal.css"

interface NewInvitationCodeModalProps {
	invitationCodeAdded: (successMessage: string) => void
}

export const NewInvitationCodeModal: React.FC<NewInvitationCodeModalProps> = ({invitationCodeAdded}) => {
    const i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
	const invitationCodeService: IInvitationCodeService = container.get<IInvitationCodeService>(TYPES.IInvitationCodeService)
	const stringHelpers: IStringHelpers = container.get<IStringHelpers>(TYPES.IStringHelpers)

    const [addButtonDisabled, setAddButtonDisabled] = useState<boolean>(true)
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const [guestCodeName, setGuestCodeName] = useState<string>("")
    const [initialEntity, setInitialEntity] = useState<string>("")
    const [isExpired, setIsExpired] = useState<boolean>(false)
	const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

    const hookText = "Let us help you met your training needs!"
	const defaultTrainings = ["Workplace Inclusion"]

    const stackStyling: IStackTokens = {
        childrenGap: "20px",
    }


	const dismissErrorBanner = () => {
        setErrorMessage(undefined)
	}

	const addButtonClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
		setSuccessMessage(undefined)
        setErrorMessage(undefined)
        setShowSpinner(true)
        setAddButtonDisabled(true)

		const initialEntitiesToAdd: string[] = stringHelpers.getCommaDeliminatedValues(initialEntity)

		invitationCodeService.addInvitation(new Invitation(guestCodeName, isExpired, initialEntitiesToAdd, hookText, defaultTrainings))
			.then(() => {
				invitationCodeAdded(i18n.get(i18nKey.codeSuccessfullyAddedExclamation))
			}).catch(error => {
				setErrorMessage(error)
			}).finally(() => {
				setShowSpinner(false)
			})
	}

	const guestCodeNameTextFieldChanged = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return
		}

		let val = newValue

		setGuestCodeName(val)
	}

	const initialEntitiesTextFieldChanged = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return
		}

		let val = newValue

        setInitialEntity(val)
	}

    const checkboxChanged = (
		_: React.FormEvent<HTMLElement> | HTMLInputElement | undefined, 
		isChecked: boolean | undefined) => {
		if (isChecked === undefined) {
			return
		}

        setIsExpired(isChecked)
	}

	useEffect(() => {
		let disable = true

		if (guestCodeName.length > 0 && 
			initialEntity.length > 0) {
			disable = false
		}

        setAddButtonDisabled(disable)
	}, [guestCodeName, initialEntity])


    return (
        <div id="new-invitation-code-modal-container">
            <PageTitle title="Add New Guest Code"/>
            <Stack tokens={stackStyling}>
					<TextField
						id="new-guest-code-modal-code-name-textfield"
						label={i18n.get(i18nKey.code)}
						onChange={guestCodeNameTextFieldChanged} 
					/>
					<Text id="new-guest-code-entity-helper-text">
						{i18n.get(i18nKey.newCodeEntityHelperText)}
					</Text>
					<TextField
						id="new-guest-code-initial-entities-textfield"
						label={i18n.get(i18nKey.entities)}
						onChange={initialEntitiesTextFieldChanged}
					/>
					<Text id="new-guest-code-expired-helper-text">
						{i18n.get(i18nKey.newCodeExpiredHelperText)}
					</Text>
                    <Checkbox 
                        id="new-guest-code-expired-checkbox" 
                        label={i18n.get(i18nKey.expiredSingleWord)}
                        checked={isExpired} 
                        onChange={checkboxChanged} 
                    />
					{successMessage !== undefined && (
						<MessageBar
							id="new-guest-code-success-banner"
							messageBarType={MessageBarType.success}
							dismissButtonAriaLabel="Close"
							onDismiss={dismissErrorBanner}
						>
							{successMessage}
						</MessageBar>
					)}
					{errorMessage !== undefined && (
						<MessageBar
							id="new-guest-code-error-banner"
							messageBarType={MessageBarType.error}
							dismissButtonAriaLabel="Close"
							onDismiss={dismissErrorBanner}
						>
							{errorMessage}
						</MessageBar>
					)}
					<PrimaryButton
						id="new-guest-code-primary-button"
						text={i18n.get(i18nKey.add)}
						onClick={addButtonClicked}
						disabled={addButtonDisabled}
					/>
					<div id="new-guest-code-added-spinner-container" hidden={!showSpinner}>
						<Spinner
							id="new-guest-code-added-spinner"
							label={i18n.get(i18nKey.addingNewGuestCodeSpinner)}
							ariaLive="assertive"
							labelPosition="right"
						/>
					</div>
				</Stack>
        </div>
    )
} 
