import { Theme } from './../ui-kit/Theme'
import { IImageStyles, IMessageBarStyles, ISpinnerStyles, IStackStyles, IStackTokens } from "@fluentui/react";

export class CleverRedirectStyles {
    static heroImage: Partial<IImageStyles> = {
        image: {
            width: 500
        }
    }

    static spinnerStyle: Partial<ISpinnerStyles> = {
        circle: {
            width: 23,
            height: 23
        }
    }

    static statusImage: Partial<IImageStyles> = {
        image: {
            width: 25
        }
    }

    static ssoStackTokens: Partial<IStackTokens> = {
        childrenGap: 50,
    }

    static ssoStackStyle: Partial<IStackStyles> = {
        root: {
        }
    }

    static defaultStackTokens: Partial<IStackTokens> = {
        childrenGap: 30
    }
    
    static errorBannerStyles: Partial<IMessageBarStyles> = {
        root: { 
            width: 500, 
            borderRadius: 5
        }
    }

    static stepStackStyles: Partial<IStackStyles> = {
        root: {
            backgroundColor: '#ffffff',
            padding: 40,
            borderRadius: 10
        }
    }

    static spanStyleActive: React.CSSProperties = {
        color: Theme.color.fifth
    }

    static spanStyleInactive: React.CSSProperties = {
        color: Theme.accent.secondary
    }
}