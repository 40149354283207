export class Invitation {
    code: string
    expired: boolean
    entities: string[]
	hookText: string
	selectedTrainings: string[]

    constructor(code: string, expired: boolean, entities: string[], hookText: string, selectedTrainings: string[]) {
        this.code = code
        this.expired = expired
        this.entities = entities
		this.hookText =  hookText
		this.selectedTrainings = selectedTrainings
    }

    static Converter = {
		toFirestore: function (invitation: Invitation) {
			return {
                code: invitation.code,
				expired: invitation.expired,
				entities: invitation.entities,
				hookText: invitation.hookText,
				selectedTrainings: invitation.selectedTrainings
			}
		},
		fromFirestore: function (snapshot: any, options: any) {
			const data = snapshot.data(options)
			return new Invitation(
                data.code,
				data.expired,
				data.entities,
				data.hookText,
				data.selectedTrainings
			)
		},
	}
}