import { injectable } from "inversify";
import { container } from "../DIContainer";
import { log } from "../global/Logger";
import { Survey } from "../models/Survey";
import { TYPES } from "../Types";
import { IFirebase } from "./Firebase";
import { IEventReportingService } from '../services/EventReportingService'
import { ISurveyParser } from "../models/SurveyParser";
import { DocumentId } from "../global/TypeAliases";
import { IAuth } from "../auth/Auth";
import { ILearningPlanService } from "./LearningPlanService";

export interface ISurveyService {
	/**
	 * Get all surveys
	 * @return An array of Surveys
	 */
	surveys(): Promise<Survey[]>

	/**
	 * Get surveys assigned to a client
	 * @param clientId The id of the specified client
	 * @return An array of Surveys
	 */
	surveysForClientId(clientId: string): Promise<Survey[]>

	/**
	 * Get surveys assigned to a guest
	 * @return An array of Surveys
	 */
	surveysForGuest(): Promise<Survey[]>

	/**
	 * Get surveys assigned to a group
	 * @param groupId The id of the specified group
	 * @return An array of Surveys
	 */
	surveysForGroup(groupId: string): Promise<Survey[]>

	/**
	 * Get surveys assigned to the currently logged in user
	 * @return An array of Surveys
	 */
	surveysForCurrentUser(): Promise<Survey[]>

	/**
	 * We do not allow duplicate survey names so we check if the survey name is available to be used. 
	 * Returns TRUE if the survey name is available, FALSE if the survey name is not available
	 * @param name The survey name requested
	 */
	isSurveyNameAvailable(name: string): Promise<boolean>

	/**
	 * Creates and saves a new survey
	 * @param survey The survey
	 * @return The survey object with the firebase document id
	 */
	save(survey: Survey): Promise<Survey>
	
	/**
	 * Update the survey
	 * @param survey The survey
	 */
	update(survey: Survey): Promise<void>

	/**
	 * Delete the survey from the database
	 * @param surveyId documentId of the survey to delete
	 */
	deleteSurvey(surveyId: DocumentId): Promise<void>

	/**
	 * Get a survey by documentId 
	 * @param documentId The document Id
	 */
	surveyForDocumentId(documentId: DocumentId): Promise<Survey>

	/**
	 * Add group to a set of surveys
	 * @param groupDocumentIds group to add document id
	 * @param learningPlanDocumentId survey document ids to add
	 * @param clientId The id of the specified client
	 */
	addSurveysToGroup(
		groupDocumentId: DocumentId,
		surveyDocumentIds: DocumentId[],
		clientId: DocumentId
	): Promise<void>

	/**
	 * Add groups to a survey
	 * @param groupDocumentIds groups to add document ids
	 * @param surveyDocumentId The survey document id
	 * @param clientId The id of the specified client
	 */
	addGroupsToSurvey(
		groupDocumentIds: DocumentId[],
		surveyDocumentId: DocumentId,
		clientId: DocumentId
	): Promise<void>

	/**
	 * Remove group from selected survey
	 * @param groupDocumentId The group document id
	 * @param surveyDocumentIds The survey document ids
	 * @param clientId The id of the specified client
	 */
	removeSurveysFromGroup(
		groupDocumentId: DocumentId,
		surveyDocumentIds: DocumentId[],
		clientId: DocumentId
	): Promise<void>

	/**
	 * Remove groups from a survey
	 * @param groupDocumentIds The group document ids
	 * @param surveyDocumentId The survey document id
	 * @param clientId The id of the specified client
	 */
	removeGroupsFromSurvey(
		groupDocumentIds: DocumentId[],
		surveyDocumentId: DocumentId,
		clientId: DocumentId
	): Promise<void>
}

@injectable()
export class SurveyService implements ISurveyService {
	private firebase: IFirebase = container.get<IFirebase>(TYPES.IFirebase)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)
	private auth = container.get<IAuth>(TYPES.IAuth)
	private surveyParser = container.get<ISurveyParser>(TYPES.ISurveyParser)
	private learningPlanService = container.get<ILearningPlanService>(TYPES.ILearningPlanService)

	private surveyCollection = "survey"

	surveys(): Promise<Survey[]> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection(this.surveyCollection)
				.orderBy("name", "asc")
				.withConverter(Survey.Converter)
				.get()
				.then((querySnapshot) => {
					log("Success: SurveyService - surveys()")

					let surveys: Survey[] = []
					querySnapshot.forEach((doc) => {
						let survey = doc.data()
						survey.documentId = doc.id
						surveys.push(survey)
					})

					resolve(surveys)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("Error: SurveyService - surveys() - " + error)
					reject(error)
				})
		})
	}

	surveysForClientId(clientId: string): Promise<Survey[]> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection(this.surveyCollection)
				.withConverter(Survey.Converter)
				.where(`clients.${clientId}`, "==", true)
				.get()
				.then((querySnapshot) => {
					var result: Survey[] = []
					querySnapshot.forEach(function (doc) {
						let item: Survey = doc.data()
						item.documentId = doc.id
						result.push(item)
					})

					log("SurveyService - surveysForClientId() - Success")
					resolve(result)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("SurveyService - surveysForClientId() - Failed")
					reject(error)
				})
		})
	}

	surveysForGuest(): Promise<Survey[]> {
		const guestId = process.env.REACT_APP_ENV_GUEST_ID
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection(this.surveyCollection)
				.withConverter(Survey.Converter)
				.where(`clients.${guestId}`, "==", true)
				.get()
				.then((querySnapshot) => {
					var result: Survey[] = []
					querySnapshot.forEach(function (doc) {
						let item: Survey = doc.data()
						item.documentId = doc.id
						result.push(item)
					})

					log("SurveyService - surveysForGuest() - Success")
					resolve(result)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("SurveyService - surveysForGuest() - Failed")
					reject(error)
				})
		})
	}

	surveysForGroup(groupId: string): Promise<Survey[]> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection(this.surveyCollection)
				.withConverter(Survey.Converter)
				.where(`cohorts.${groupId}`, "==", true)
				.get()
				.then((querySnapshot) => {
					var result: Survey[] = []
					querySnapshot.forEach(function (doc) {
						let item: Survey = doc.data()
						item.documentId = doc.id
						result.push(item)
					})

					log("SurveyService - surveysForCohort() - Success")
					resolve(result)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("SurveyService - surveysForCohort() - Failed")
					reject(error)
				})
		})
	}

	surveysForCurrentUser(): Promise<Survey[]> {
		if (!this.auth.isLoggedIn()) {
			return Promise.reject(new Error("No user logged in"))
		}

		return new Promise((resolve, reject) => {
			let get = this.firebase.app.functions().httpsCallable("surveysForUser")

			log("Getting surveys for " + this.auth.uid())
			
			get({ uid: this.auth.uid() })
				.then((result) => {
					log("SurveyService - surveysForCurrentUser() - Success")
					const surveys = Object.keys(result.data).map((id) => {
						let survey = this.surveyParser.parse(id, result.data[id])
						survey.documentId = id
						return survey
					})

					surveys.sort((lhs, rhs) => {
						return lhs.name > rhs.name ? 1 : -1
					})
					resolve(surveys)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("SurveyService - surveysForCurrentUser() - Failed: " + error)
					reject(error)
				})
		})
	}

	isSurveyNameAvailable(name: string): Promise<boolean> {
		const canonicalName = name.trim().toLowerCase().replace(/\s/g,'')

		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection(this.surveyCollection)
				.where('canonicalName', "==", canonicalName)
				.get()
				.then(querySnapshot => {
					log("SurveyService - isSurveyNameAvailable() - Success")
					resolve(querySnapshot.docs.length === 0)
				}).catch(error => {
					this.eventReportingService.error(error.message, error)
					log("SurveyService - isSurveyNameAvailable() - Failed")
					reject(error)
				})
		})
	}

	save(survey: Survey): Promise<Survey> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection(this.surveyCollection)
				.add(Survey.toData(survey))
				.then(documentReference => {
					log("SurveyService - save() - Success")
					survey.documentId = documentReference.id
					resolve(survey)
				}).catch(error => {
					this.eventReportingService.error(error.message, error)
					log("SurveyService - save() - Failed")
					reject(error)
				})
		})
	}

	update(survey: Survey): Promise<void> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection(this.surveyCollection)
				.doc(survey.documentId)
				.update(Survey.toData(survey))
				.then(_ => {
					return this.learningPlanService.updateLearningPlanContentInfo(survey.documentId, Object.keys(survey.clients)[0])
				})
				.then(_ => {
					log("SurveyService - update() - Success")
					resolve()
				})
				.catch(error => {
					this.eventReportingService.error(error.message, error)
					log("SurveyService - update() - Failed")
					reject(error)
				})
		})
	}

	deleteSurvey(surveyId: DocumentId): Promise<void> {
		let deleteSurvey = this.firebase.app.functions().httpsCallable("deleteSurvey")
		return new Promise((resolve, reject) => {
			deleteSurvey({ documentId: surveyId })
				.then(_ => {
					log("SurveyService - deleteSurvey - Success")
					resolve()
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log(`SurveyService - deleteSurvey - Failed - ${error}`)
					reject(error)
				})
		})
	}

	surveyForDocumentId(documentId: DocumentId): Promise<Survey> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection(this.surveyCollection)
				.doc(documentId)
				.withConverter(Survey.Converter)
				.get()
				.then((documentSnapshot) => {					
					log("Success: SurveyService - surveyForDocumentId()")
					
					if (documentSnapshot.data() === undefined) {
						throw new Error("No survey in document snapshot")
					}

					const survey = this.surveyParser.parse(documentSnapshot.id, documentSnapshot.data() as Survey)

					resolve(survey)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("SurveyService - surveyForDocumentId() - Failed")
					reject(error)
				})
		})
	}

	addGroupsToSurvey(
		groupDocumentIds: DocumentId[],
		surveyDocumentId: DocumentId,
		clientId: DocumentId): Promise<void> {
		var groupMap: { [key: string]: boolean } = {}

		groupDocumentIds.forEach((documentId) => {
			groupMap[documentId] = true
		})

		var learningPlanMap: { [key: string]: boolean } = {}
		learningPlanMap[surveyDocumentId] = true

		return new Promise((resolve, reject) => {
			let add = this.firebase.app
				.functions()
				.httpsCallable("addGroupsToSurvey")

			add({
				surveyDocumentId: surveyDocumentId,
				groups: groupMap,
				surveys: learningPlanMap,
			})
			.then(_ => {
				return this.learningPlanService.updateLearningPlanContentInfo(surveyDocumentId, clientId)
			})
			.then(function (result) {
				log("SurveyService - addGroupsToSurvey() - Success")
				resolve()
			})
			.catch(error => {
                this.eventReportingService.error(error.message, error)
				log("SurveyService - addGroupsToSurvey() - Failed: " + error)
				reject(error)
			})
		})
	}

	async addSurveysToGroup(
		groupDocumentId: DocumentId,
		surveyDocumentIds: DocumentId[],
		clientId: DocumentId
	): Promise<void> {
		let addSurveysPromises = surveyDocumentIds.map((surveyDocumentId: DocumentId) => {
            return this.addGroupsToSurvey([groupDocumentId], surveyDocumentId, clientId)
        })

        return await Promise.all(addSurveysPromises)
            .then((_) => {
				log("SurveyService - addSurveysToGroup() - Success!")
				return Promise.resolve()
            })
            .catch(error => {
                this.eventReportingService.error(error.message, error)
				log("SurveyService - addSurveysToGroup() - Failed: " + error)
				return Promise.reject(error)
            })
	}

	removeGroupsFromSurvey(
		groupDocumentIds: DocumentId[],
		surveyDocumentId: DocumentId,
		clientId: DocumentId
	): Promise<void> {
		var groupMap: { [key: string]: boolean } = {}

		groupDocumentIds.forEach((documentId) => {
			groupMap[documentId] = true
		})

		return new Promise((resolve, reject) => {
			let remove = this.firebase.app
				.functions()
				.httpsCallable("removeGroupsFromSurvey")

			remove({ groups: groupMap, surveyDocumentId: surveyDocumentId })
				.then(() => {
					log("SurveyService - removeGroupsFromSurvey - Success")
					resolve()
				}).then(_ => {
					return this.learningPlanService.updateLearningPlanContentInfo(surveyDocumentId, clientId)
				})
				.catch(error => {
                	this.eventReportingService.error(error.message, error)
					log("SurveyService - removeGroupsFromSurvey - Failed: " + error)
					reject(error)
				})
		})
	}

	async removeSurveysFromGroup(
		groupDocumentId: DocumentId,
		surveyDocumentIds: DocumentId[],
		clientId: DocumentId
	): Promise<void> {
		let removeSurveysPromises = surveyDocumentIds.map((surveyDocumentId: DocumentId) => {
            return this.removeGroupsFromSurvey([groupDocumentId], surveyDocumentId, clientId)
        })

        return await Promise.all(removeSurveysPromises)
            .then((_) => {
				log("SurveyService - removeSurveysFromGroup() - Success!")
				return Promise.resolve()
            })
            .catch(error => {
                this.eventReportingService.error(error.message, error)
				log("SurveyService - removeSurveysFromGroup() - Failed: " + error)
				return Promise.reject(error)
            })
	}
}