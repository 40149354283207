import { Component, MouseEvent } from "react"
import { container } from "../../DIContainer"
import { TYPES } from "../../Types"
import { IDataTableFactory } from "../../factories/DataTableFactory"
import DTSTableData from "../../models/DTSTableData"
import Course from "../../models/Course"
import { Loader } from "../../ui-kit/Loader"
import DTSTable from "../../ui-kit/DTSTable"
import PageTitle from "../../ui-kit/PageTitle"
import { Stack, MessageBar, MessageBarType, Modal, PrimaryButton, Text } from "@fluentui/react"
import { ReportUserDetailsModal } from "./ReportUserDetailsModal"
import { ReportCSVExporter } from "../../services/ReportCSVExporter"
import { IReportCompletionCompositionService } from "../../services/ReportCompletionCompositionService"
import { i18nKey, Ii18n } from "../../global/i18n"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { IDateProvider } from "../../services/DateProvider"
import { DocumentId } from "../../global/TypeAliases"
import { ChartData } from "../../models/ChartData"
import { IChartFactory } from "../../factories/ChartFactory"
import { CMPieChart } from "../../ui-kit/CMPieChart"
import { ChartCard } from "../../ui-kit/ChartCard"
import { DurationReportMetric } from "../../ui-kit/DurationReportMetric"
import { FontSize } from "../../ui-kit/FontSize"

interface ReportCompletionPageProps {
    course: Course
    clientId: DocumentId
}

interface ReportCompletionPageState {
    tableData: DTSTableData | null
    errorMessage: string | null
    serviceComplete: boolean
    isAssignedToGroups: boolean

    //metric UI component states
    ratioMetric: ChartData[] | null
    attemptOnCourseMetric: ChartData[] | null
    avgDurationMetric: ChartData[] | null
    medianDurationMetric: ChartData[] | null

    //modal states
    isModalOpen: boolean
    selectedUserId: string | undefined
    selectedRow: { [key: string]: string } | undefined
    successMessage: string | undefined
    showErrorPage: boolean
    userTable: DTSTableData | null

    //filter reports by dates
    startDate: Date | undefined
    endDate: Date | undefined
}

export default class ReportCompletionPage extends Component<ReportCompletionPageProps, ReportCompletionPageState> {
    private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
    private compositionService: IReportCompletionCompositionService = container.get<IReportCompletionCompositionService>(
        TYPES.IReportCompletionCompositionService
    )
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private dateProvider: IDateProvider = container.get<IDateProvider>(TYPES.IDateProvider)
    private chartFactory: IChartFactory = container.get<IChartFactory>(TYPES.IChartFactory)

    state = {
        tableData: null,
        errorMessage: null,
        serviceComplete: false,
        isAssignedToGroups: false,

        //metric UI component states
        ratioMetric: null,
        avgDurationMetric: null,
        attemptOnCourseMetric: null,
        medianDurationMetric: null,

        //modal states
        isModalOpen: false,
        selectedUserId: undefined,
        selectedRow: undefined,
        successMessage: undefined,
        showErrorPage: false,
        users: [],
        userTable: null,

        //filter reports by dates
        startDate: undefined,
        endDate: undefined
    }

    componentDidMount() {
        this.load()
    }

    render() {
        if (!this.state.serviceComplete) {
            return <Loader />
        }

        const pageTitle = `Completion Report For ${this.props.course.name}`
        // Titles
        const ratioMetricTitle = this.i18n.get(i18nKey.ratioCompletedMetricTitle)
        const attemptsOnCourseMetricTitle = this.i18n.get(i18nKey.ratioAttemptedMetricTitle)
        const avgDurationMetricTitle = this.i18n.get(i18nKey.averageDurationMetricTitle)
        const medianDurationMetricTitle = this.i18n.get(i18nKey.medianDurationMetricTitle)

        // Metrics
        const ratioMetric = this.state.ratioMetric
        const attemptOnCourseMetric = this.state.attemptOnCourseMetric
        const avgDurationMetric = this.state.avgDurationMetric
        const medianDurationMetric = this.state.medianDurationMetric

        return (
            <>
                <PageTitle title={pageTitle} />
                {!this.state.errorMessage &&
                    ratioMetric &&
                    attemptOnCourseMetric &&
                    avgDurationMetric &&
                    medianDurationMetric && (
                        <div id="report-completion-statistics-container">
                            <Stack verticalAlign="space-evenly">
                                <Stack verticalAlign="space-evenly" horizontal horizontalAlign="center">
                                    <ChartCard
                                        id="ratio-completed-metric-chart"
                                        title={ratioMetricTitle}
                                        element={<CMPieChart data={ratioMetric} />}
                                    />
                                    <ChartCard
                                        id="ratio-attempted-metric-chart"
                                        title={attemptsOnCourseMetricTitle}
                                        element={<CMPieChart data={attemptOnCourseMetric} />}
                                    />
                                </Stack>
                                <Stack verticalAlign="space-evenly" horizontal horizontalAlign="center">
                                    <ChartCard
                                        id="average-duration-metric-chart"
                                        title={avgDurationMetricTitle}
                                        element={<DurationReportMetric data={avgDurationMetric} />}
                                    />
                                    <ChartCard
                                        id="median-duration-metric-chart"
                                        title={medianDurationMetricTitle}
                                        element={<DurationReportMetric data={medianDurationMetric} />}
                                    />
                                </Stack>
                            </Stack>
                        </div>
                    )}

                <div id="report-completion-container">
                    {!this.state.errorMessage && this.state.isAssignedToGroups && this.state.tableData && (
                        <Stack horizontalAlign="center" styles={{ root: { paddingLeft: 50, paddingRight: 50, marginTop: 20 } }}>
                            <Stack styles={{ root: { width: "75%", marginBottom: 20 } }} tokens={{ childrenGap: 50 }} horizontal>
                                <Stack tokens={{ childrenGap: 5 }} horizontal>
                                    <PrimaryButton id="report-completion-page-reset-filter-button" onClick={this.onResetDateFilterButtonClicked}>
                                        {this.i18n.get(i18nKey.resetFilterButton)}
                                    </PrimaryButton>
                                    <Stack>
                                        <DatePicker
                                            id="report-completion-page-start-date-picker"
                                            placeholderText={this.i18n.get(i18nKey.fromDate)}
                                            selected={this.state.startDate}
                                            onChange={this.onStartDateChange}
                                            selectsStart
                                            showMonthDropdown
                                            showYearDropdown
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            maxDate={this.dateProvider.getDate()}
                                        />
                                        <DatePicker
                                            id="report-completion-page-end-date-picker"
                                            placeholderText={this.i18n.get(i18nKey.toDate)}
                                            selected={this.state.endDate}
                                            onChange={this.onEndDateChange}
                                            selectsEnd
                                            showMonthDropdown
                                            showYearDropdown
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            minDate={this.state.startDate}
                                            maxDate={this.dateProvider.getDate()}
                                        />
                                    </Stack>
                                    <PrimaryButton
                                        id="report-completion-page-date-filter-button"
                                        onClick={this.onDateFilterButtonClicked}
                                        disabled={this.onDateFilterButtonDisabled()}
                                    >
                                        {this.i18n.get(i18nKey.filterReportsButton)}
                                    </PrimaryButton>
                                    <ReportCSVExporter tableData={this.state.tableData} fileName={this.props.course.name} isReportCompletionPageCSV={true}/>
                                </Stack>
                            </Stack>
                            <DTSTable
                                data={this.state.tableData}
                                aria-label="report-completion-data-table"
                                onRowClicked={this.onRowClicked}
                                pointerOnHover={true}
                                highlightOnHover={true}
                                pagination={true}
                                filter={true}
                                filterPlaceholder={this.i18n.get(i18nKey.searchForLearner)}
                            />

                            <Modal titleAriaId="user report modal" isOpen={this.state.isModalOpen} onDismiss={this.modalDismissed} isBlocking={false}>
                                {this.createModalElement()}
                            </Modal>
                        </Stack>
                    )}

                    { !this.state.isAssignedToGroups &&
                        <Stack id='report-completion-page-no-group-assigned' verticalAlign="space-evenly" horizontal horizontalAlign="center" styles={{ root: { paddingLeft: 50, paddingRight: 50, marginTop: 20 }}}>
                            <Text variant={FontSize.medium}>
                                {this.i18n.get(i18nKey.noGroupAssigned)}
                            </Text>
                        </Stack>
                    } 

                    {this.state.errorMessage && (
                        <MessageBar id="report-completion-error-banner" messageBarType={MessageBarType.error} dismissButtonAriaLabel="Close">
                            {this.state.errorMessage}
                        </MessageBar>
                    )}
                    {!this.state.tableData && (
                        <MessageBar id="report-completion-null-error-banner" messageBarType={MessageBarType.error} dismissButtonAriaLabel="Close">
                        {this.i18n.get(i18nKey.reportCompletionFailedLoadReport)}
                    </MessageBar>
                    )}
                </div>
            </>
        )
    }

    modalDismissed = () => {
        this.setState({ isModalOpen: false })
    }

    onRowClicked = (row: { [key: string]: string }) => {
        this.setState({
            selectedUserId: row.id,
            selectedRow: row,
            isModalOpen: true
        })
    }

    onStartDateChange = (date: Date) => {
        this.setState({
            startDate: date,
            endDate: undefined
        })
    }

    onEndDateChange = (date: Date) => {
        this.setState({
            endDate: date
        })

        this.onDateFilterButtonDisabled()
    }

    onDateFilterButtonDisabled = () => {
        if (this.state.startDate !== undefined && this.state.endDate !== undefined) {
            return false
        }

        return true
    }

    onDateFilterButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
        this.compositionService
            .getDateFilteredData(this.props.clientId, this.props.course, this.state.startDate, this.state.endDate)
            .then((result) => {
                const tableData = this.dataTableFactory.createFilteredCourseCompletionTableData(result.statements, result.cohorts)

                this.setState({
                    tableData: tableData,
                    serviceComplete: true,
                    errorMessage: null
                })
            })
            .catch((error) => {
                let errorMessage = this.i18n.get(i18nKey.reportCompletionFailedLoadReport)

                /**
                 * Code 66 means the client id doesn't exist in the LRS
                 */
                if (error.message.includes(this.i18n.get(i18nKey.code66))) {
                    errorMessage = error.message
                }

                this.setState({
                    serviceComplete: true,
                    errorMessage: errorMessage
                })
            })
    }

    onResetDateFilterButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
        this.setState({
            startDate: undefined,
            endDate: undefined
        })

        this.load()
    }

    createModalElement = () => {
        if (!this.state.isModalOpen) {
            return null
        }

        if (this.state.selectedUserId !== undefined && this.state.selectedRow !== undefined && this.props.clientId !== undefined) {
            return (
                <ReportUserDetailsModal
                    userId={this.state.selectedUserId!}
                    details={this.state.selectedRow!}
                    clientId={this.props.clientId!}
                    course={this.props.course}
                />
            )
        }
    }

    private load = () => {
        this.compositionService
            .getData(this.props.clientId, this.props.course)
            .then((result) => {
                const tableData = this.dataTableFactory.createCourseCompletionTableData(result.statements, result.cohorts, result.attemptStatements)
                const ratioMetric = this.chartFactory.ratioCompleted(result.statements)
                const attemptsOnCourseMetric = this.chartFactory.ratioAttempted(result.attemptStatements)
                const averageDurationMetric = this.chartFactory.averageCourseDuration(result.statements)
                const medianDurationMetric = this.chartFactory.medianCourseDuration(result.statements)

                this.setState({
                    tableData: tableData,
                    ratioMetric: ratioMetric,
                    avgDurationMetric: averageDurationMetric,
                    attemptOnCourseMetric: attemptsOnCourseMetric,
                    medianDurationMetric: medianDurationMetric,
                    serviceComplete: true,
                    errorMessage: null,
                    isAssignedToGroups: result.isAssignedToGroups
                })
            })
            .catch((error) => {
                    let errorMessage: string | null = this.i18n.get(i18nKey.reportCompletionFailedLoadReport)

                    /**
                     * Code 66 means the client id doesn't exist in the LRS
                     */
                    if (error.message.includes(this.i18n.get(i18nKey.code66))) {
                        errorMessage = error.message
                    }

                    if (error.message === this.i18n.get(i18nKey.noGroupAssigned)) {
                        errorMessage = null
                    }
    
                    this.setState({
                        serviceComplete: true,
                        errorMessage: errorMessage
                    })
                })
    }
}
