import { Icon, IconButton, Label, Stack, StackItem, Text, TooltipHost } from "@fluentui/react";
import { Component, FormEvent } from "react";
import { ItemValue, Question, QuestionMatrixModel } from "survey-react";
import { container } from "../../../../DIContainer";
import { Ii18n, i18nKey } from "../../../../global/i18n";
import { Dictionary } from "../../../../global/TypeAliases";
import { Survey } from "../../../../models/Survey";
import { TYPES } from "../../../../Types";
import { FontSize } from "../../../../ui-kit/FontSize";
import { SecondaryButton } from "../../../../ui-kit/SecondaryButton";
import { TextField } from "../../../../ui-kit/TextField";
import { ToggleControl } from "../../../../ui-kit/ToggleControl";
import { UUID } from "../../../../utils/UUIDGenerator";
import { SurveyChoicesOrder } from "../SurveyChoicesOrder";
import { MatrixPropertiesPanelStyles } from "./MatrixPropertiesPanelStyles";

interface MatrixPropertiesPanelProps {   
    survey: Survey
    model: QuestionMatrixModel
    didUpdateSurvey: (survey: Survey) => void
    toggleRequireInteraction: (survey: Survey, model: Question, checked: boolean) => void
}

interface MatrixPropertiesPanelState {}

enum TYPE {
    column,
    row
}

export class MatrixPropertiesPanel extends Component<MatrixPropertiesPanelProps, MatrixPropertiesPanelState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private uuidGenerator: UUID = container.get<UUID>(TYPES.UUID)

    render() {
        return (
            <Stack tokens={{childrenGap: 20}}>
                <Text 
                    id="title-label"
                    variant={FontSize.xLarge}>
                        {this.i18n.get(i18nKey.matrixProperties)}
                </Text>
                <TextField 
                    id="title-textfield"
                    type="text"
                    label={this.i18n.get(i18nKey.title)}
                    placeholder={this.i18n.get(i18nKey.surveyInteractionPlaceholder)}
                    onChange={this.titleTextFieldChanged}
                    required
                />
                <TextField 
                    id="description-textfield"
                    type="text"
                    label={this.i18n.get(i18nKey.description)}
                    value={this.props.model.description || ""}
                    onChange={this.descriptionTextFieldChanged}
                />
                <ToggleControl
                    id="required-toggle"
                    label={this.i18n.get(i18nKey.isRequired)}
                    checked={this.props.model.isRequired}
                    onChange={(_, checked) => this.requiredToggleChanged(checked)}
                />

                { this.props.model.columns.length > 0 &&
                    <>
                        <Label>Columns</Label>
                        <Stack id="column-choices" tokens={{childrenGap: 10}}>
                            { this.renderColumns() }
                        </Stack>
                    </>
                }
                <SecondaryButton 
                    id="add-column-button" 
                    text={this.i18n.get(i18nKey.addColumn)} 
                    onClick={() => this.addNewChoice(TYPE.column)} 
                />
                { this.props.model.rows.length > 0 &&
                    <>
                        <Label>Rows</Label>
                        <Stack id="row-choices" tokens={{childrenGap: 10}}>
                            { this.renderRows() }

                            <TooltipHost
                                content={this.i18n.get(i18nKey.randomizeChoicesTooltipText)}
                                hostClassName="randomize-choices-tooltip"
                                styles={MatrixPropertiesPanelStyles.hostStyles}
                                calloutProps={MatrixPropertiesPanelStyles.randomizeChoicesCalloutProps}
                            >
                                <Stack horizontal tokens={MatrixPropertiesPanelStyles.stackStyling}>
                                    <ToggleControl
                                        id="randomize-choices-toggle"
                                        label={this.i18n.get(i18nKey.randomizeChoices)}
                                        checked={this.props.model.rowsOrder === SurveyChoicesOrder.random}
                                        onChange={(_, checked) => this.onRandomizeToggleChanged(checked)}
                                    />
                                    <Icon id="randomize-choices-information-icon" iconName="Info" styles={MatrixPropertiesPanelStyles.toolTipIconStyles}/>
                                </Stack>
                            </TooltipHost>
                        </Stack>
                    </>
                } 
                <SecondaryButton 
                    id="add-row-button" 
                    text={this.i18n.get(i18nKey.addRow)} 
                    onClick={() => this.addNewChoice(TYPE.row)} 
                />
            </Stack>
        )
    }

    onRandomizeToggleChanged = (checked?: boolean) => {
        if (checked !== undefined) {
            let rowsOrder: string
            if (checked) {
                rowsOrder = SurveyChoicesOrder.random
            } else {
                rowsOrder = SurveyChoicesOrder.initial
            }

            this.props.model.rowsOrder = rowsOrder
            this.props.didUpdateSurvey(this.props.survey)
        }
    }

    /**
     * Private Functions
     */
    private titleTextFieldChanged = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.props.model.title = newValue? newValue : ""
        this.props.didUpdateSurvey(this.props.survey)
	}

    private descriptionTextFieldChanged = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.props.model.description = newValue? newValue : ""
        this.props.didUpdateSurvey(this.props.survey)
	}

    private textFieldOnChange = (type: TYPE, e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, choice: Dictionary, newValue?: string) => {
        let item: any

        switch(type) {
            case TYPE.column: 
                item = this.props.model.columns.find(item => item.value === choice.value)
                break
            case TYPE.row:
                item = this.props.model.rows.find(item => item.value === choice.value)
                break
        }

        if (item) {
            let value = newValue ? newValue : ''
            
            /**
             * SurveyJS will never render string with 0 length so we need to do this 
             * weird hack. If value is '' then we use ' ' and then when the user types any letters
             * then we remove the space.
             */
            const SPACE = ' '
            if (value === '') {
                value = SPACE
            }

            if (value.length > 1 && value.charAt(0) === SPACE) {
                value = value.substring(1)
            }

            item.value = value
        }

        this.props.didUpdateSurvey(this.props.survey)
    }

    private renderColumns = () => {
        return this.props.model.columns.map((column, index) => {
            const lastIndex = index === this.props.model.columns.length-1
            const firstIndex = index === 0
            return <Stack key={index} id={`choice-row-${index}`} horizontal tokens={{childrenGap: 10}}>
                    <StackItem>
                        <IconButton
                            id={`delete-col-${column.value}`} 
                            iconProps={{ iconName: 'Delete' }} 
                            title={this.i18n.get(i18nKey.delete)}
                            ariaLabel="delete"
                            onClick={() => this.removeChoice(TYPE.column, column)}
                        />
                    </StackItem>
                    <StackItem grow>
                        <TextField
                            id={column.value}
                            value={column.text} 
                            onChange={(e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => { 
                                this.textFieldOnChange(TYPE.column, e, column, newValue) 
                            }} 
                        />
                    </StackItem>
                    <Stack styles={{ root: { width: 75, flexFlow: "row-reverse" }}} disableShrink={true} horizontal>
                        { !firstIndex &&
                            <IconButton
                                id={`move-col-left-${index}`} 
                                iconProps={{ iconName: 'ChevronLeft' }} 
                                title="move-col-left" 
                                ariaLabel="move-col-left"
                                onClick={() => this.moveUpClicked(TYPE.column, index)}
                            />
                        }
                        { !lastIndex &&
                            <IconButton
                                id={`move-col-right-${index}`} 
                                iconProps={{ iconName: 'ChevronRight' }} 
                                title="move-col-right" 
                                ariaLabel="move-col-right"
                                onClick={() => this.moveDownClicked(TYPE.column, index)}
                            />
                        }
                    </Stack>
                </Stack>
        })
    }

    private renderRows = () => {
        return this.props.model.rows.map((row, index) => {
            const lastIndex = index === this.props.model.rows.length-1
            const firstIndex = index === 0
            return <Stack key={index} id={`choice-row-${index}`} horizontal tokens={{childrenGap: 10}}>
                    <StackItem>
                        <IconButton
                            id={`delete-row-${row.value}`} 
                            iconProps={{ iconName: 'Delete' }} 
                            title={this.i18n.get(i18nKey.delete)}
                            ariaLabel="delete"
                            onClick={() => this.removeChoice(TYPE.row, row)}
                        />
                    </StackItem>
                    <StackItem grow>
                        <TextField
                            id={row.value}
                            value={row.text} 
                            onChange={(e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => { 
                                this.textFieldOnChange(TYPE.row, e, row, newValue) 
                            }} 
                        />
                    </StackItem>
                    <Stack styles={{ root: { width: 75, flexFlow: "row-reverse" }}} disableShrink={true} horizontal>
                        { !firstIndex &&
                            <IconButton
                                id={`move-row-up-${index}`} 
                                iconProps={{ iconName: 'Up' }} 
                                title="move-row-up" 
                                ariaLabel="move-row-up"
                                onClick={() => this.moveUpClicked(TYPE.row, index)}
                            />
                        }
                        { !lastIndex &&
                            <IconButton
                                id={`move-row-down-${index}`} 
                                iconProps={{ iconName: 'Down' }} 
                                title="move-row-down" 
                                ariaLabel="move-row-down"
                                onClick={() => this.moveDownClicked(TYPE.row, index)}
                            />
                        }
                    </Stack>
                </Stack>
        })
    }

    private addNewChoice = (type: TYPE) => {
        if (type === TYPE.column) {
            let columns = this.props.model.columns.map(item => item.text)

            columns.push(this.uuidGenerator.uuid())
            this.props.model.columns = columns
        } else if (type === TYPE.row) {
            let rows = this.props.model.rows.map(item => item.text)

            rows.push(this.uuidGenerator.uuid())
            this.props.model.rows = rows
        }
        
        this.props.didUpdateSurvey(this.props.survey)
    }

    private removeChoice = (type: TYPE, itemToRemove: Dictionary) => {
        if (type === TYPE.column) {
            let index = this.props.model.columns.findIndex(item => item.value === itemToRemove.value)
            
            if (index > -1) {
                this.props.model.columns.splice(index, 1)
            }
        } else if (type === TYPE.row) {
            let index = this.props.model.rows.findIndex(item => item.value === itemToRemove.value)
            
            if (index > -1) {
                this.props.model.rows.splice(index, 1)
            }
        }

        this.props.didUpdateSurvey(this.props.survey)
    }

    /**
     * Given a source index, if type is a row, moves interaction choice UP.
     * If type is a column, moves interaction to the RIGHT
     */
    private moveUpClicked = (type: TYPE, sourceIndex: number) => {
        if (type === TYPE.column) {
            const updatedColumns = this.reorder(this.props.model.columns, sourceIndex, sourceIndex-1)
            /**
             * Save the new ordered column choices
             */
            this.props.model.columns = updatedColumns.map(item => item.text)
        } else if (type === TYPE.row) {
            const updatedRows = this.reorder(this.props.model.rows, sourceIndex, sourceIndex-1)
            /**
             * Save the new ordered row choices
             */
            this.props.model.rows = updatedRows.map(item => item.text)
        }

        this.props.didUpdateSurvey(this.props.survey)
    }
   
    /**
     * Given a source index, if type is a row, moves interaction choice DOWN.
     * If type is a column, moves interaction to the LEFT
     */
    private moveDownClicked = (type: TYPE, sourceIndex: number) => {
        if (type === TYPE.column) {
            const updatedColumns = this.reorder(this.props.model.columns, sourceIndex, sourceIndex+1)
            /**
            * Save the new ordered choices
            */
            this.props.model.columns = updatedColumns.map(item => item.text)
        } else if (type === TYPE.row) {
            const updatedRows = this.reorder(this.props.model.rows, sourceIndex, sourceIndex+1)
            /**
            * Save the new ordered choices
            */
            this.props.model.rows = updatedRows.map(item => item.text)
        }

        this.props.didUpdateSurvey(this.props.survey)
    }

    /**
     * Reorder the column/row choices
     * @param choices The list of chioces
     * @param startIndex The index of the choice that is being moved
     * @param endIndex The target index where the choice will be moved to
     */
    private reorder = (choices: ItemValue[], startIndex: number, endIndex: number): ItemValue[] => {
        const result = [...choices]

        /**
         * Remove the element at the start index
         */
        const [removed] = result.splice(startIndex, 1)
        
        /**
         * Place the choice at the target index
         */
        result.splice(endIndex, 0, removed)

        return result
    }

    requiredToggleChanged = (checked?: boolean) => {
        if (checked !== undefined) {
            this.props.toggleRequireInteraction(this.props.survey, this.props.model, checked)
        }
    }
}