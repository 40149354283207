import { Dictionary } from "../global/TypeAliases"

export class CourseUploadInfo {
    courseUrl: string
    imageUrl: string
	lrsId: string
	courseFolder: string
	lessonNames?: Dictionary

    constructor(courseUrl: string, imageUrl: string, lrsId: string, courseFolder: string, lessonNames?: Dictionary) {
        this.courseUrl = courseUrl
        this.imageUrl = imageUrl
		this.lrsId = lrsId
		this.courseFolder = courseFolder

		if (lessonNames !== undefined) {
			this.lessonNames = lessonNames
		}
    }

	public debugLog(): string {
		return `courseUrl: ${this.courseUrl}, 
				imageUrl: ${this.imageUrl}, 
				lrsId: ${this.lrsId}, 
				courseFolder: ${this.courseFolder}`
	}
}