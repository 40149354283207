import { getTheme, loadTheme } from '@fluentui/react';

export class Theme {
    static color = {
        primary: "#0071bc", // royal blue
        secondary: "#29abe2", // baby blue
        tertiary: "#0bcece", // turquoise-ish
        fourth: "#ffffff", // white
        fifth: "#313537", // dark gray
    }

    static destructiveColor = {
        primary: '#c70000',
        secondary: '#970000',
        tertiary: '#eea7a7',
    }

    static accent = {
        primary: "#f5f5f5", // super light gray
        secondary: '#c8c8c8', // light gray
        tertiary: '#b32572' // magenta-ish
    }

    static control = {
        textFieldBorder: "#eff0f1", // light grayish
        headerBackground: "#2f3c4e"
    }

    static shadow = {
        light: "#ededed"
    }

    /**
     * According to https://developer.microsoft.com/en-us/fluentui#/styles/web/elevation,
     * getTheme() is the recommended way for accessing the elevation property for the boxShadow effect.
     */
    static cardStyle = {
        root: {
            width: 400,
            margin: 20,
            boxShadow: getTheme().effects.elevation8
        }
    }

    static cardTitleStyle = {
        root: {
            color: Theme.color.primary,
            padding: 20,
            textAlign: "center"
        }
    }

    static singleDigitMetricStyle = {
        color: Theme.color.secondary,
        fontSize: 20,
        fontWeight: "bold",
        marginBottom: 20
    }

    static load() {
        loadTheme({
            defaultFontStyle: { fontFamily: 'Montserrat', fontWeight: '400' },
            fonts: {
                small: {
                    fontSize: '11px',
                },
                medium: {
                    fontSize: '13px',
                },
                large: {
                    fontSize: '20px',
                    fontWeight: '500',
                },
                xLarge: {
                    fontSize: '22px',
                    fontWeight: '600',
                }
            },
            palette: {
              themePrimary: Theme.color.primary,
              themeTertiary: Theme.color.tertiary,
              themeSecondary: Theme.color.secondary,

              neutralLighter: Theme.accent.primary, // Hover
              neutralLight: Theme.accent.secondary, // Pressed
              
              black: '#1d1d1d',
              white: '#ffffff',
            },
          });
    }
}
