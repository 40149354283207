import { i18nKey, Ii18n } from './../global/i18n'
import { UUID } from '../utils/UUIDGenerator'
import { 
    PageModel, 
    QuestionCheckboxModel, 
    QuestionCommentModel, 
    QuestionRadiogroupModel, 
    QuestionRatingModel,
    QuestionMatrixModel,
    QuestionRankingModel,
    QuestionDropdownModel,
    QuestionTextModel
} from "survey-react"
import { container } from '../DIContainer'
import { TYPES } from '../Types'
import { injectable } from 'inversify'

export interface ISurveyInteractionFactory {
    page(): PageModel
    shortText(title: string): QuestionTextModel
    longText(title: string): QuestionCommentModel
    radioButtons(title: string): QuestionRadiogroupModel
    checkboxes(title: string): QuestionCheckboxModel
    rating(title: string): QuestionRatingModel
    matrix(title: string): QuestionMatrixModel
    ranking(title: string): QuestionRankingModel
    dropDown(title: string): QuestionDropdownModel
}

@injectable()
export class SurveyInteractionFactory implements ISurveyInteractionFactory {
    private uuidGenerator: UUID = container.get<UUID>(TYPES.UUID)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    static RATE_MIN = 1
    static RATE_MAX = 5

    public page(): PageModel {
        const page = new PageModel(this.uuidGenerator.uuid())
        return page
    }

    public longText(title: string): QuestionCommentModel {
        const question = new QuestionCommentModel(this.uuidGenerator.uuid())
        question.title = title

        return question
    }

    public shortText(title: string): QuestionTextModel {
        const question = new QuestionTextModel(this.uuidGenerator.uuid())
        question.title = title

        return question
    }

    public radioButtons(title: string): QuestionRadiogroupModel {
        const question = new QuestionRadiogroupModel(this.uuidGenerator.uuid())
        question.title = title
        question.choices = [
            this.i18n.get(i18nKey.firstChoice),
            this.i18n.get(i18nKey.secondChoice),
            this.i18n.get(i18nKey.thirdChoice)
        ]

        return question
    }
    
    public checkboxes(title: string): QuestionCheckboxModel {
        const question = new QuestionCheckboxModel(this.uuidGenerator.uuid())
        question.title = title
        question.choices = [
            this.i18n.get(i18nKey.firstChoice),
            this.i18n.get(i18nKey.secondChoice),
            this.i18n.get(i18nKey.thirdChoice)
        ]

        return question
    }

    public rating(title: string): QuestionRatingModel {
        const question = new QuestionRatingModel(this.uuidGenerator.uuid())
        question.title = title
        question.rateMin = SurveyInteractionFactory.RATE_MIN
        question.rateMax = SurveyInteractionFactory.RATE_MAX
        
        return question
    }

    public matrix(title: string): QuestionMatrixModel {
        const question = new QuestionMatrixModel(this.uuidGenerator.uuid())
        question.title = title
        question.columns = [
            this.i18n.get(i18nKey.columnOne),
            this.i18n.get(i18nKey.columnTwo),
            this.i18n.get(i18nKey.columnThree),
        ]
        question.rows = [
            this.i18n.get(i18nKey.rowOne),
            this.i18n.get(i18nKey.rowTwo),
        ]

        return question
    }

    public ranking(title: string): QuestionRankingModel {
        const question = new QuestionRankingModel(this.uuidGenerator.uuid())
        question.title = title
        question.choices = [
            this.i18n.get(i18nKey.firstChoice),
            this.i18n.get(i18nKey.secondChoice),
            this.i18n.get(i18nKey.thirdChoice)
        ]

        return question
    }

    public dropDown(title: string): QuestionDropdownModel {
        const question = new QuestionDropdownModel(this.uuidGenerator.uuid())
        question.title = title
        question.choices = [
            this.i18n.get(i18nKey.firstChoice),
            this.i18n.get(i18nKey.secondChoice),
            this.i18n.get(i18nKey.thirdChoice)
        ]

        return question
    }
}