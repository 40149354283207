import React, { Component, ReactElement } from "react";
import DataTable, { ConditionalStyles, TableStyles } from "react-data-table-component";
import DTSTableData from "../models/DTSTableData";
import './DTSTable.css'
import { SearchBox, ISearchBoxStyles, Stack } from "@fluentui/react";
import { log } from "../global/Logger";
import { Dictionary } from "../global/TypeAliases";
import { Theme } from "./Theme";
import { SecondaryButton } from "./SecondaryButton";
import { container } from "../DIContainer";
import { i18nKey, Ii18n } from "../global/i18n";
import { TYPES } from "../Types";

interface DTSTableProps {
	clearSelectedRows?: boolean | false
	conditionalRowStyles?: ConditionalStyles<any>[]
    customStyles?: TableStyles;
	data: DTSTableData;
	filter?: boolean
	filterPlaceholder?: string
	highlightOnHover?: boolean
	onResendRegistrationButtonClicked?: () => void
	onRowClicked?: (row: { [key: string]: string }) => void
	onSelectedRowsChange?: (selectedRowState: {
		allSelected: boolean
		selectedCount: number
		selectedRows: {}[]
	}) => void | undefined
	pagination?: boolean
	pointerOnHover?: boolean
	resendRegistrationButton?: boolean
	selectableRowsSingle?: boolean
}

interface DTSTableState {
	filterText: string | null
	filteredData: Dictionary[] | null
}

export default class DTSTable extends Component<DTSTableProps> {
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	state: DTSTableState = {
		filterText: null,
		filteredData: null
	}

	private searchBoxStyle: Partial<ISearchBoxStyles> = {
        root: {
            width: "50%",
            height: "50px",
			borderColor: Theme.accent.primary,
			margin: "10px 15px"
		}
	}

	render() {
		let table: ReactElement
		let data = this.state.filteredData !== null ? this.state.filteredData : this.props.data.data

		if (this.props.onSelectedRowsChange !== undefined) {
			table = (
				<DataTable
					columns={this.props.data.columns}
					data={data}
					noHeader={true}
					pointerOnHover={this.props.pointerOnHover}
					highlightOnHover={this.props.highlightOnHover}
					onRowClicked={this.props.onRowClicked}
					onSelectedRowsChange={this.props.onSelectedRowsChange}
					clearSelectedRows={this.props.clearSelectedRows}
					selectableRowsSingle={this.props.selectableRowsSingle}
					selectableRows
					pagination={this.props.pagination}
					conditionalRowStyles={this.props.conditionalRowStyles}
					customStyles={this.props.customStyles}
				/>
			);
		} else {
			table = (
				<DataTable
					columns={this.props.data.columns}
					data={data}
					noHeader={true}
					pointerOnHover={this.props.pointerOnHover}
					highlightOnHover={this.props.highlightOnHover}
					onRowClicked={this.props.onRowClicked}
					pagination={this.props.pagination}
					conditionalRowStyles={this.props.conditionalRowStyles}
					customStyles={this.props.customStyles}
				/>
			);
		}

		return (
			<div className="cm-data-table">
				<Stack 
					id="searchbox-stack"
					tokens={{ childrenGap: 20 }} 
					horizontal 
				>
					{  this.props.filter && 
						<SearchBox
							id="dts-table-filter"
							styles={this.searchBoxStyle}
							placeholder={this.props.filterPlaceholder ?? this.i18n.get(i18nKey.filterPlaceholder)}
							onEscape={(ev) => {
								log("Custom onEscape Called");
							}}
							onClear={(ev) => {
								log("Custom onClear Called");
							}}
							onChange={this.onChange}
							onSearch={(newValue) => log("onSearch Called")}
						/>
					}
					{ this.props.resendRegistrationButton &&
						<SecondaryButton 
							id="resend-registration-emails"
							text={this.i18n.get(i18nKey.resendRegEmailsButtonText)}
							onClick={this.props.onResendRegistrationButtonClicked}
						/>
					}
				</Stack>

				{table}
			</div>
		)
	}

	private onChange = (_?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
		log("SearchBox onChange fired: " + newValue)
		const filterText = newValue?.trim()

		if (filterText === undefined) {
			this.setState({ filterText: null, filteredData: null })
			return
		}

		if (filterText?.length === 0) {
			this.setState({ filterText: null, filteredData: null })
			return
		}

		const filteredItems = this.props.data.data.filter(item => {
			return this.find(filterText, item)
		})

		this.setState({ filterText: filterText, filteredData: filteredItems })
	}

	private find(filterText: string, item: Dictionary): boolean {
		const values = Object.values(item)

		let valid = false
		values.forEach((val: any, index: number) => {
			/**
			 * The first index is the id so we don't want to filter on the id
			 */
			if (valid !== false || index === 0) {
				return
			} 

			if (typeof val === "string") {
				valid = val.trim().toLowerCase().includes(filterText.trim().toLowerCase())
			}
		})

		return valid
	}
}
