import { CSSProperties } from "react"
import { IStackStyles, ITextStyles, IImageStyles } from '@fluentui/react'
import { Theme } from "./Theme"

export class MarketingFooterStyles {

    static outerStackStyles: IStackStyles = { 
        root: { 
            selectors: {
                '@media(max-width: 600px)': {
                    padding: "5vw",
                    paddingTop: "15vw",
                    paddingBottom: "15vw"
                }
            },

            background: "#1C1D1E", 
            padding: "4vw",
            paddingLeft: "18vw",
            paddingRight: "18vw",
        }
    }

    static innerStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    paddingTop: "5vw",
                    paddingBottom: "10vw",
                    fontSize: "3vw",
                }
            },

            color: "#CCCCCC",
            fontFamily:"Rubik, sans-serif",
            fontWeight: 400,
            fontSize: "0.7vw",
        }
    }

    static textStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: "3vw",
                    maxWidth: "100%",
                    width: "100%"
                },
            },
            color: Theme.color.fourth,
            fontFamily:"Rubik, sans-serif",
            fontWeight: 400,
            fontSize: "0.7vw",
            maxWidth: "20vw",
        }
    }

    static logoStyles: Partial<IImageStyles> = {
        image: {
            selectors: {
                '@media(max-width: 600px)': {
                    width: "100%"
                },
            },

            width: "15vw"
        }
    }

    static linkStyle: CSSProperties = {
        color:"#4AAAE7",
        textDecoration: "none"
    }
}