import { Component } from "react";
import { 
    Breadcrumb as FluentBreadcrumb,
    IBreadcrumbProps 
} from '@fluentui/react';


export class Breadcrumb extends Component<IBreadcrumbProps> {
    render() {
        return (
            <FluentBreadcrumb
                items={this.props.items}
            />
        )
    }
}