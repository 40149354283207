/* istanbul ignore file */
import queryString from 'query-string'

/**
 * A custom logger
 * @param message The message to log to the console
 * @param forceLog A flag to indicate a forced log in the console regardless of environment
 */
 var log = (message: string, forceLog?: boolean) => {
    const accessKey = 'k9NXq1mFD4fd3v'

    const urlComponents = document.URL.split('?')
    
    if (urlComponents.length === 2) {
        const params = queryString.parse(urlComponents[1])
        const forceValue = params['useTheForce'] as string
        const code = params['fid'] as string

        if (code === accessKey && forceValue === "true") {
            forceLog = true
        }
    }

    if ((process.env.REACT_APP_ENV !== "production" && process.env.REACT_APP_ENV !== "test") || forceLog) {
        console.log(message)
    }
}

export { log }