import { IMessageBarStyles, ITextStyles } from "@fluentui/react"

export class UsersAdminStyles {
    static learnerCountStyle: ITextStyles = {
         root: { 
             display: "flex", 
             alignItems: "center", 
             justifyContent: "center"
        }
    }

    static learnerUploadStyle: ITextStyles = {
        root: { 
            display: "flex",
            paddingTop: "10px"
       }
    }

    static learnerErrorStyle: IMessageBarStyles = {
        root: { 
            marginBottom: "50px",
            width: '75%'
       }
    }
}