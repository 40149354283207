import { injectable } from "inversify"
import { Dictionary, DocumentId } from "../global/TypeAliases"
import Cohort from "../models/Cohort"
import { LRSCompletionStatement } from "../models/LRSStatement"
import { Ii18n, i18nKey } from "../global/i18n"
import { container } from "../DIContainer"
import { TYPES } from "../Types"


export interface IDataTableFactoryUtils {
    mapGroupNames(statement: LRSCompletionStatement, groups: Cohort[]): string
    sortByMinute: (rowA: Dictionary, rowB: Dictionary) => number
	mapGroupIdsToGroupNames(groupIds: DocumentId[], groups: Cohort[]): string 
}

@injectable()
export class DataTableFactoryUtils implements IDataTableFactoryUtils {
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    mapGroupNames(statement: LRSCompletionStatement, groups: Cohort[]): string {
		let groupName: string = ""

		if (groups.length > 0 && statement.user.cohorts !== undefined) {

			let groupDocumentIds = Object.keys(statement.user.cohorts)

			groupDocumentIds.forEach((targetDocumentId, index) => {
				let targetCohort = groups.find(group => {
					return group.documentId === targetDocumentId
				})

				if (targetCohort !== undefined) {
					if (groupName.length === 0) {
						groupName += targetCohort.name
					} else {
						groupName += ", " + targetCohort.name
					}
				}
			})
		}

		return groupName.length > 0 ? groupName : this.i18n.get(i18nKey.notAvailableNA)
	}

	mapGroupIdsToGroupNames(groupIds: DocumentId[], groups: Cohort[]): string {
		let groupName: string = ""

		if (groups.length > 0) {

			groupIds.forEach((targetDocumentId) => {
				let targetCohort = groups.find(group => {
					return group.documentId === targetDocumentId
				})

				if (targetCohort !== undefined) {
					if (groupName.length === 0) {
						groupName += targetCohort.name
					} else {
						groupName += ", " + targetCohort.name
					}
				}
			})
		}

		return groupName.length > 0 ? groupName : this.i18n.get(i18nKey.notAvailableNA)
	}

	sortByMinute = (rowA: Dictionary, rowB: Dictionary) => {
		const a = '' === rowA.duration.replace(/\D/g,'') ? -1 : parseInt(rowA.duration.replace(/\D/g,''))
		const b = '' === rowB.duration.replace(/\D/g,'') ? -1 : parseInt(rowB.duration.replace(/\D/g,''))
	
		if (a > b) {
			return 1;
		}
	
		if (b > a) {
			return -1;
		}
	
		return 0;
	}
}