import { Stack } from "@fluentui/react";
import React from "react";
import { ImageGridStyles } from "./ImageGridStyles";
import defaultImage from '../images/dts-logo-red.png'
import "./ImageGridV2.css"

interface ImageGridProps {
    imageProps: LearningPlanImageProps[]
}

export interface LearningPlanImageProps {
    alt: string
    src: string
}

export const ImageGridV2: React.FC<ImageGridProps> = ({imageProps}) => {
    const defaultReplacement = (sourceString: string) => {
        if (sourceString === "") {
            return defaultImage
        } else {
            return sourceString
        }
    }
    
    const objectFitStyle = (sourceString: string)  => {
        if (sourceString === "") {
            return 'contain'
        } else {
            return 'cover'
        }
    }

    return (
        <div id="image-grid-container">
        { imageProps.length === 0 &&
            <img
                className="image-grid-one-image-style"
                src={defaultImage} 
                alt="default-learning-plan-cover" 
                style={{
                    objectFit: objectFitStyle("")
                }}
            />
        }

        { imageProps.length === 1 &&
            <img
                className="image-grid-one-image-style"
                src={defaultReplacement(imageProps[0].src)} 
                alt={imageProps[0].alt}
                style={{
                    objectFit: objectFitStyle(imageProps[0].src)
                }} 
            />
        }

        { imageProps.length === 2 &&
            <Stack styles={ImageGridStyles.stackStyle}>
                <img 
                    className="image-grid-image-full-top-style"
                    src={defaultReplacement(imageProps[0].src)} 
                    alt={imageProps[0].alt}
                    style={{
                        objectFit: objectFitStyle(imageProps[0].src)
                    }}  
                />
                <img 
                    className="image-grid-image-full-bottom-style"
                    src={defaultReplacement(imageProps[1].src)} 
                    alt={imageProps[1].alt}
                    style={{
                        objectFit: objectFitStyle(imageProps[1].src)
                    }}  
                />
            </Stack>
            
        }

        { imageProps.length === 3 &&
            <Stack styles={ImageGridStyles.stackStyle}>
                <Stack styles={ImageGridStyles.twoHorizontalStackStyle} horizontal>
                    <img 
                        className="image-grid-image-quarter-style"
                        src={defaultReplacement(imageProps[0].src)} 
                        alt={imageProps[0].alt}
                        style={{
                            objectFit: objectFitStyle(imageProps[0].src)
                        }}  
                    />
                    <img 
                        className="image-grid-image-quarter-style"
                        src={defaultReplacement(imageProps[1].src)} 
                        alt={imageProps[1].alt} 
                        style={{
                            objectFit: objectFitStyle(imageProps[1].src)
                        }} 
                    />
                </Stack>
                <img 
                    className="image-grid-image-full-bottom-style"
                    src={defaultReplacement(imageProps[2].src)} 
                    alt={imageProps[2].alt} 
                    style={{
                        objectFit: objectFitStyle(imageProps[2].src)
                    }} 
                />
            </Stack>
        }

        { imageProps.length > 3 &&
           <Stack styles={ImageGridStyles.stackStyle} wrap horizontal>
                <img 
                    className="image-grid-image-quarter-style"
                    src={defaultReplacement(imageProps[0].src)} 
                    alt={imageProps[0].alt}
                    style={{
                        objectFit: objectFitStyle(imageProps[0].src)
                    }} 
                />
                <img 
                    className="image-grid-image-quarter-style"
                    src={defaultReplacement(imageProps[1].src)} 
                    alt={imageProps[1].alt}
                    style={{
                        objectFit: objectFitStyle(imageProps[1].src)
                    }} 
                />
                <img 
                    className="image-grid-image-quarter-style"
                    src={defaultReplacement(imageProps[2].src)} 
                    alt={imageProps[2].alt}
                    style={{
                        objectFit: objectFitStyle(imageProps[2].src)
                    }} 
                />
                <img 
                    className="image-grid-image-quarter-style"
                    src={defaultReplacement(imageProps[3].src)} 
                    alt={imageProps[3].alt}
                    style={{
                        objectFit: objectFitStyle(imageProps[3].src)
                    }} 
                />
            </Stack>
        }
    </div>   
    )
}