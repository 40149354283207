import { IImageStyles, mergeStyles, mergeStyleSets } from "@fluentui/react"
import { Theme } from "./Theme"
export class LearningPlanEditorStyles {
    static stackItemStyle = {
        root: {
            margin: "auto"
        }
    }

    static iconClass = mergeStyles({
        fontSize: 20,
        width: 50,
        marginRight: '25px',
        marginLeft: '10px'
    })

    static moveIconClass = mergeStyleSets({
        lightGray: [{ color: '#b5b5b5' }, LearningPlanEditorStyles.iconClass]
    })

    static searchStackStyle = {
        root: { 
            marginBottom: 20
        }
    }

    static searchBoxStyle = { 
        root: { 
            height: 50, 
            width: "100%", 
            borderColor: Theme.accent.primary
        }
    }

    static stackTokens = { 
        childrenGap: 20 
    }

    static searchResultsStyle = { 
        root: {
            width: "100%"
        } 
    }

    static draggableIconImageStyle: IImageStyles = {
        root: {
            width: '30px',
            height: '30px',
            marginRight: '25px',
            marginLeft: '10px'
        },
        image: {
            width: '30px',
            height: '30px'
        }
    }
}