import React, { Component } from 'react'
import { Stack, Text } from '@fluentui/react'
import { MarketingIi18n, i18nKey } from '../global/Marketing-i18n'
import { container } from '../DIContainer'
import { TYPES } from '../Types'
import { FeaturesHeroStyles } from './FeaturesHeroStyles'

export class FeaturesHero extends Component {
    private i18n = container.get<MarketingIi18n>(TYPES.MarketingIi18n)

    render() {
        return (
            <Stack styles={FeaturesHeroStyles.stackStyles}>
                <Text styles={FeaturesHeroStyles.headerTextStyles}>
                    {this.i18n.get(i18nKey.featuresHeroHeader)}
                </Text>
                <Text styles={FeaturesHeroStyles.subHeaderTextStyles}>
                    {this.i18n.get(i18nKey.featuresHeroSubHeader)}
                </Text>
            </Stack>
        )
    }
}
