import Rollbar from "rollbar"
import { injectable } from 'inversify'
import { Dictionary } from '../global/TypeAliases'

export interface IEventReportingService {
  log(msg: string, extra? : Dictionary) : void
  debug(msg: string, extra? : Dictionary) : void
  info(msg: string, extra? : Dictionary) : void
  warn(msg: string, extra?: Dictionary) : void
  error(msg: string, error?: Error) : void
  critical(msg: string, error?: Error) : void
  captureEvent(metadata: object, level: EventLevel) : void 
}

export enum EventLevel {
  debug = "debug",
  info = "info",
  warning = "warning",
  error = "error",
  critical = "critical"
}

const ignoreEnvs = ["local", "test"]
const ignoredMessages = ["Missing or insufficient permissions."]

@injectable()
export class EventReportingService implements IEventReportingService {
    private rollbar: Rollbar
    private environment: string | undefined

    constructor() {
      this.environment = process.env.REACT_APP_ENV
      this.rollbar = new Rollbar({
        accessToken: '74c3f5cba7f24883b20ce99099df8b09',
        autoInstrument: true,
        captureUncaught: true,
        captureUnhandledRejections: true,
        environment: process.env.REACT_APP_ENV,
        enabled: this.shouldEnable(),
        ignoredMessages: ignoredMessages
      })
    }

    private shouldEnable() : boolean {
      return this.environment !== undefined && !ignoreEnvs.includes(this.environment)
    }

    public log(msg: string, extra?: Dictionary) : void {
        this.rollbar.log(msg, extra)
    }

    public debug(msg: string, extra?: Dictionary) : void {
        this.rollbar.debug(msg, extra)
    }

    public info(msg: string, extra?: Dictionary) : void {
        this.rollbar.info(msg, extra)
    }

    public warn(msg: string, extra?: Dictionary) : void {
        this.rollbar.warn(msg, extra)
    }

    public error(msg: string, error?: Error) : void {
        this.rollbar.error(msg, error)
    }

    public critical(msg: string, error?: Error) : void {
        this.rollbar.critical(msg, error)
    }

    public captureEvent(metadata: object, level: EventLevel) : void {
        this.rollbar.captureEvent(metadata, level)
    }
}