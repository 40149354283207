import { MessageBar, MessageBarType, Spinner, Stack } from "@fluentui/react"
import CancelablePromise from "cancelable-promise"
import { Component } from "react"
import { container } from "../../../DIContainer"
import { IDataTableFactory } from "../../../factories/DataTableFactory"
import { i18nKey, Ii18n } from "../../../global/i18n"
import { DocumentId } from "../../../global/TypeAliases"
import DTSTableData from "../../../models/DTSTableData"
import { LearningPlan } from "../../../models/LearningPlan"
import { IRouter } from "../../../Router"
import { ILearningPlanService } from "../../../services/LearningPlanService"
import { IPromiseHelper } from "../../../services/PromiseHelper"
import { TYPES } from "../../../Types"
import DTSTable from "../../../ui-kit/DTSTable"
import { Loader } from "../../../ui-kit/Loader"
import { SecondaryButton } from "../../../ui-kit/SecondaryButton"
import { TabPanelProps } from "../TabPanelProps"

enum ModalType {
    NEW = 0,
    EDIT,
}

interface LearningPlansProps extends TabPanelProps {
    clientId: DocumentId
}

interface LearningPlansState {
    showPageLoader: boolean
    showSpinner: boolean
	selectedClientId: DocumentId | null
    errorMessage: string | null
    successMessage: string | null
    tableData: DTSTableData | null
    learningPlans: LearningPlan[]
    isModalOpen: boolean
    modalType: ModalType
	selectedDocumentId: string | undefined
}

export class LearningPlans extends Component<LearningPlansProps, LearningPlansState> {
    private learningPlanService: ILearningPlanService = container.get<ILearningPlanService>(TYPES.ILearningPlanService)
    private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private router: IRouter = container.get<IRouter>(TYPES.IRouter)
    private promiseHelper: IPromiseHelper = container.get<IPromiseHelper>(TYPES.IPromiseHelper)
	private dataPromise: CancelablePromise | undefined

    state: LearningPlansState = {
        showPageLoader: true,
        showSpinner: false,
		selectedClientId: null,
        errorMessage: null,
        successMessage: null,
        tableData: null,
        learningPlans: [],
        isModalOpen: false,
        modalType: ModalType.NEW,
	    selectedDocumentId: undefined
    }

    componentDidMount() {
        this.loadLearningPlans()
    }

    componentWillUnmount(): void {
		this.promiseHelper.cancel(this.dataPromise)
	}

    render() {
        if (this.state.showPageLoader) {
			return <Loader />
        }

        return (
            <>
                <Stack tokens={{ childrenGap: 20 }}>
                    { this.state.showSpinner &&
                        <Spinner
                            label={this.i18n.get(i18nKey.loadingLearningPlans)}
                            ariaLive="assertive"
                            labelPosition="right"
                        />
                    }

                    { this.state.errorMessage &&
                        <MessageBar
                            id="lp-error-banner"
                            messageBarType={MessageBarType.error}
                            dismissButtonAriaLabel="Close"
                            onDismiss={this.dismissBanner}
                        >
                            {this.state.errorMessage}
                        </MessageBar>
                    }

                    { this.state.successMessage &&
                        <MessageBar
                            id="lp-success-banner"
                            messageBarType={MessageBarType.success}
                            dismissButtonAriaLabel="Close"
                            onDismiss={this.dismissBanner}
                        >
                            {this.state.successMessage}
                        </MessageBar>
                    }
                
                    { this.state.tableData &&
                        <>
                            <Stack 
                                className="admin-command-bar" 
                                horizontal={true} 
                                tokens={{ childrenGap: 20}}
                            >
                                <SecondaryButton
                                    id="add-learning-plan-button"
                                    iconProps={{ iconName: "AddFriend" }}
                                    text={this.i18n.get(i18nKey.learningPlanAddNew)}
                                    onClick={this.addNewLearningPlanClicked}
                                />
                            </Stack>
                            <DTSTable
                                data={this.state.tableData!}
                                aria-label="learning plan table"
                                onRowClicked={this.onRowClicked}
                                pointerOnHover={true}
                                highlightOnHover={true}
                                pagination={true}
                                filter={true}
                                filterPlaceholder={this.i18n.get(i18nKey.searchForLearningPlan)}
                            />
                        </>
                    }
                </Stack>
            </>  
        )
    }

    private loadLearningPlans = () => {
        this.setState({ selectedClientId: this.props.clientId, showPageLoader: false, showSpinner: true})
        this.getLearningPlansFor(this.props.clientId)
    }

    private getLearningPlansFor = (documentId: DocumentId) => {
        this.dataPromise = this.promiseHelper.makeCancelable(this.learningPlanService.getLearningPlansFor(documentId))
        this.dataPromise
            .then(plans => {
                const tableData = this.dataTableFactory.createLearningPlansTableData(plans)
                this.setState({ 
                    learningPlans: plans, 
                    tableData: tableData,
                    showSpinner: false 
                })
            })
            .catch(_ => {
                this.setState({ 
                    errorMessage: this.i18n.get(i18nKey.failedToLoadLearningPlans),
                    showSpinner: false 
                })
            })
    }
    
    addNewLearningPlanClicked = () => {
        this.router.goToAddNewLearningPlan({ clientId: this.state.selectedClientId })
    }

    onRowClicked = (row: { [key: string]: string }) => {
        const learningPlanId: DocumentId = row.id

        const learningPlan = this.state.learningPlans.find(
            (learningPlan) => learningPlan.documentId === learningPlanId
        )

        if (learningPlan !== undefined && this.state.selectedClientId) {
            const data = {
                clientId: this.state.selectedClientId,
                documentId: learningPlanId,
                learningPlan: learningPlan,
            }
            this.router.goToEditLearningPlan(data)
        } else {
            const message = "Unable to load the learning plan. Please try again."
            this.setState({ errorMessage: message })
        }        
    }
    
    learningPlanAdded = (message: string) => {
        this.setState({ isModalOpen: false, successMessage: message })

        if (this.state.selectedClientId) {
            this.getLearningPlansFor(this.state.selectedClientId)
        }
    }

    dismissBanner = () => {
		this.setState({ errorMessage: null, successMessage: null })
    }
}
