import { i18nKey, Ii18n } from './../global/i18n'
import { IMailService } from './MailService'
import { DocumentId, CleverUserId } from './../global/TypeAliases';
import { CleverUser } from './../models/CleverUser';
import { Dictionary } from '../global/TypeAliases';
import { RoleType } from "../models/RoleType";
import { IFirebase, ENVIRONMENT } from "./Firebase";
import { injectable } from "inversify";
import { container } from "../DIContainer";
import { TYPES } from "../Types";
import { log } from '../global/Logger';
import Client from '../models/Client';
import User from '../models/User';
import { IEventReportingService } from '../services/EventReportingService'

export interface IAuthService {
	/**
	 * Get firebase user credentials
	 * @param email The email address
	 * @param currentPassword The current password
	 */
	userCredentials(email: string, currentPassword: string): firebase.auth.AuthCredential;
	
	/**
	 * Send a password reset
	 * @param email The email address
	 */
	resetPasswordRequest(email: string): Promise<void>;
	
	/**
	 * Log the user out
	 */
	logout(): Promise<void>;

	/**
	 * Login a user
	 * @param username The username
	 * @param password The password
	 */
	login(username: string, password: string): Promise<firebase.User>;

	/**
	 * Login a user with a custom token
	 * @param token One time auth token which expires in 1 hour
	 */
	loginWithAuthToken(token: string): Promise<firebase.User>

	/**
	 * Send an email verification
	 * @param user The firebase user
	 */
	sendEmailVerification(user: firebase.User): Promise<firebase.User>

	/**
	 * Get a one time auth token to use for signing a Clever user in
	 * @param cleverId The clever id
	 */
	getAuthToken(cleverId: CleverUserId): Promise<string>

	/**
	 * Manually override and set the user.emailVerified property
	 * @param user The firebase user
	 */
	verifyEmail(user: firebase.User): Promise<void>

	/**
	 * Create a new account
	 * @param firstName The first name
	 * @param lastName The last name
	 * @param emailAddress The email address
	 * @param password The password
	 * @param role The role
	 * @param clientManaged The client they will manage, if any 
	 * @param defaultCohortId The default cohort to add this learner to 
	 */
	signUp(
		firstName: string,
		lastName: string,
		emailAddress: string,
		password: string,
		role: RoleType,
		clientManaged: string | undefined,
		defaultCohortId: string | undefined
	): Promise<string>;

	/**
	 * Create a new account using a Clever user
	 * @param cleverUser The clever user
	 * @param client The client to associate the Clever user with
	 * @return The document ID of the user in Firebase
	 */
	createCleverUser(cleverUser: CleverUser, client: Client): Promise<DocumentId>

	/**
	 * Create association between a Clever SSO and an existing Athena user
	 * @param cleverUser The clever user 
	 * @param user The user
	 */
	createAssociation(cleverUser: CleverUser, user: User): Promise<void>
	
	/**
	 * Upload a collection of users
	 * @param data The collection of users to add
	 * @param defaultCohort The default cohort all users will be added to
	 * @param role The role all users will be assigned to
	 * @param clientManaged The client users will manage
	 * @param domain The domain the users will be a part of
	 */
	uploadUsers(data: Dictionary[], defaultCohort: string, role: RoleType, clientManaged: string| null, domain: string): Promise<string[]>

	/**
	 * Onboarding V2
	 * Create new firebase authentication user accounts
	 * @param userData The user data that contains uid, emailAddress and password
	 * @returns A dictionary with errors, if any
	 */
	createUsers(userData: Dictionary[]): Promise<Dictionary>

	/**
	 * Onboarding V2
	 * Create new user documents in firebase
	 * @param userData The user data
	 * @param defaultCohort The default cohort all users will be added to
	 * @param role The role all users will be assigned to
	 * @param clientManaged The client users will manage
	 * @param domain The domain the users will be a part of
	 */
	createUserDocuments(usersData: Dictionary[], defaultCohort: string, role: RoleType, clientManaged: string | null, domain: string): Promise<DocumentId[]>
	
	/**
	 * Verify the action code (contained in the url) sent in the reset password email 
	 * @param actionCode The oob (out of band) code that needs to be verified
	 */
	verifyOobCode(actionCode: string): Promise<void>

	/**
	 * Set a new password for a user
	 * @param actionCode The oob (out of band) code that needs to be verified
	 * @param newPassword The new password set by the user
	 */
	handleResetPassword(actionCode: string, newPassword: string): Promise<void>
}

enum EmailVerificationUrl {
	local = "http://localhost:3000",
	dev = "https://dev.cm-dts.com/",
	prod = "https://cm-dts.com/"
}

@injectable()
export default class AuthService implements IAuthService {
	private firebase: IFirebase = container.get<IFirebase>(TYPES.IFirebase)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)
    private mailService: IMailService = container.get<IMailService>(TYPES.IMailService)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
	
	userCredentials(email: string, currentPassword: string): firebase.auth.AuthCredential {
		return this.firebase.userCredentials(email, currentPassword)
	}

	/* istanbul ignore next */
	resetPasswordRequest(email: string): Promise<void> {
		let createEmailLink = this.firebase.app.functions().httpsCallable("createPasswordResetEmailLink")

		return new Promise((resolve, reject) => {
			createEmailLink({ emailAddress: email })
			.then(result => {
				return this.mailService.sendPasswordResetEmail(email, result.data.passwordResetLink)
			})
			.then(() => {
				log("AuthService - resetPasswordRequest - Success")
				resolve()
			})
			.catch(error => {
				let errorCode = error.code
				let errorMessage: string

				if (error.message === "EMAIL_NOT_FOUND" || error.message.includes("EMAIL_NOT_FOUND"))  {
					log("AuthService - resetPasswordRequest - Failed: " + error)
					resolve(error)
				} else if (errorCode === "invalid-argument") {
					errorMessage = this.i18n.get(i18nKey.enteredInvalidEmail)
					log("AuthService - resetPasswordRequest - Failed: " + error)
					this.eventReportingService.warn(error.message, error)
					reject(new Error(errorMessage))
				} else {
					errorMessage = this.i18n.get(i18nKey.resetPasswordError) + " " + this.i18n.get(i18nKey.helloEmailAddress)
					log("AuthService - resetPasswordRequest - Failed: " + error)
					this.eventReportingService.error(error.message, error)
					reject(new Error(errorMessage))
				}
			})		
		})
	}

	login(username: string, password: string): Promise<firebase.User> {
		return new Promise((resolve, reject) => {
			this.firebase.auth
				.signInWithEmailAndPassword(username, password)
				.then((credential) => {
					log("AuthService - Login - Success: Email verification is " + credential.user?.emailVerified);
					
					if(credential.user) {
						resolve(credential.user);
					} else {
						let error = new Error("No user in credential object")
                		this.eventReportingService.warn(error.message, error)
						reject(error)
					}
				})
				.catch(error => {
					var errorCode = error.code
					var errorMessage = error.message

					switch (errorCode) {
						case "auth/invalid-email":
							errorMessage = this.i18n.get(i18nKey.emailIsInvalid)
							break
						case "auth/user-not-found":
						case "auth/wrong-password":
							errorMessage = this.i18n.get(i18nKey.loginCredentialsFailed)
							break
						default:
							this.eventReportingService.warn(errorMessage, error)
					}

					log("AuthService - Login - Failed: " + error)
					reject(new Error(errorMessage))
				});
		});
	}

	loginWithAuthToken(token: string): Promise<firebase.User> {
		return new Promise((resolve, reject) => {
			this.firebase.auth
				.signInWithCustomToken(token)
				.then((credential) => {		
					if(credential.user) {
						log(`AuthService - loginWithAuthToken - Succeeded - ${credential.user.uid}`)
						resolve(credential.user)
					} else {
						log(`AuthService - loginWithAuthToken - Rejecting - No user in credential object`)
						let error = new Error("No user in credential object")
                		this.eventReportingService.warn(error.message, error)
						reject(error)
					}
				})
				.catch(error => {
					log(`AuthService - loginWithAuthToken - Failed: ${error}`)
                	this.eventReportingService.error(error.message, error)
					reject(error)
				})
		})
	}

	sendEmailVerification(user: firebase.User): Promise<firebase.User> {
		let url : string 
        switch (process.env.REACT_APP_ENV) {
            case ENVIRONMENT.prod:
				url = EmailVerificationUrl.prod 
				break
			case  ENVIRONMENT.dev:
				url = EmailVerificationUrl.dev
				break
			default:
				url = EmailVerificationUrl.local
		}
		
		var actionCodeSettings = {
			url: url,
			handleCodeInApp: true,
		}

		return new Promise((resolve, reject) => {
			user.sendEmailVerification(actionCodeSettings).then(() => {
				log("AuthService - sendEmailVerification - Success");
				resolve(user)
			}).catch(error => {
                this.eventReportingService.error(error.message, error)
				log("AuthService - sendEmailVerification - Failed");
				reject(error)
			})
		})
	}

	/* istanbul ignore next */
	getAuthToken(cleverId: CleverUserId): Promise<string> {
		let createAuthToken = this.firebase.app.functions().httpsCallable("createAuthToken")

		return new Promise((resolve, reject) => {
			createAuthToken({ uid: cleverId })
				.then((result) => {
					log("AuthService - getAuthToken - Success")
					resolve(result.data.token)
				})
				.catch(error => {
                	this.eventReportingService.error(error.message, error)
					log("AuthService - getAuthToken - " + error)
					reject(error)
				})
		})
	}

	/* istanbul ignore next */
	verifyEmail(user: firebase.User): Promise<void> {
		let verifyEmail = this.firebase.app.functions().httpsCallable("verifyEmail")

		return new Promise((resolve, reject) => {
			verifyEmail({ uid: user.uid })
				.then(_ => {
					log("AuthService - verifyEmail - Success")
					resolve()
				})
				.catch(error => {
                	this.eventReportingService.error(error.message, error)
					log("AuthService - verifyEmail - " + error)
					reject(error)
				})
		})

	}

	logout(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.firebase.auth
				.signOut()
				.then(() => {
					log("User logged out");
					resolve();
				})
				.catch(error => {
                	this.eventReportingService.error(error.message, error)
					log("User log out failed: " + error);
					reject(error);
				});
		});
	}

	/* istanbul ignore next */
	signUp(
		firstName: string,
		lastName: string,
		emailAddress: string,
		password: string,
		role: RoleType = RoleType.learner,
		clientManaged: string | undefined,
		defaultCohortId: string | undefined
	): Promise<string> {
		let userData = {
			firstName: firstName,
			lastName: lastName,
			email: emailAddress,
			password: password,
			role: role,
			clientManaged: clientManaged,
			defaultCohortId: defaultCohortId
		};

		return new Promise((resolve, reject) => {
			let createUser = this.firebase.app.functions().httpsCallable("createUser");
			
			createUser({ user: userData })
				.then((result) => {
					log("AuthService - Sign Up - Success");
					resolve(result.data.documentId);
				})
				.catch(error => {

					switch (error.message) {
						case this.i18n.get(i18nKey.emailNotFoundError): 
							log("AuthService - Sign Up - " + error)
							reject(new Error(this.i18n.get(i18nKey.emailNotFoundReadableError)))
							break
						case this.i18n.get(i18nKey.emailImproperFormatError):
							log("AuthService - Sign Up - Failed: " + error)
							reject(new Error(this.i18n.get(i18nKey.emailImproperFormatError)))
							break
						default:
							this.eventReportingService.error(error.message, error)
							log("AuthService - Sign Up - " + error);
							reject(error);
							break
						
					}
				});
		});
	}

	/* istanbul ignore next */
	createCleverUser(cleverUser: CleverUser, client: Client): Promise<DocumentId> {
		let cohorts: Dictionary = {}
		if (client.defaultCohort) {
			cohorts[client.defaultCohort] = true
		}

		const domain = {
			[this.domainFromEmail(cleverUser.emailAddress)]: true
		}
		
		return new Promise((resolve, reject) => {
			let createCleverUser = this.firebase.app.functions().httpsCallable("createCleverUser")
			
			createCleverUser({ cleverUser: cleverUser, cohorts: cohorts, domains: domain })
				.then((result) => {
					log("AuthService - createCleverUser - Success")
					resolve(result.data.documentId)
				})
				.catch(error => {
                	this.eventReportingService.error(error.message, error)
					log(`AuthService - createCleverUser - Failed - ${error}`)
					reject(error)
				})
		});
	}

	/* istanbul ignore next */
	createAssociation(cleverUser: CleverUser, user: User): Promise<void> {
		let create = this.firebase.app.functions().httpsCallable('createAssociation');

		let data: Dictionary = {
			cleverUserId: cleverUser.id,
			cleverDistrictId: cleverUser.districtId,
			userDocumentId: user.documentId
		}

		return new Promise((resolve, reject) => {
			create(data).then(() => {
				log("AuthService - createAssociation - Success")
				resolve()
			}).catch(error => {
                this.eventReportingService.error(error.message, error)
				log(`AuthService - createAssociation - Failed - ${error}`)
				reject(error)
			})
		})
	}

	/* istanbul ignore next */
	uploadUsers(data: Dictionary[], defaultCohort: string, role: RoleType, clientManaged: string | null, domain: string): Promise<string[]> {
		let bulkAdd = this.firebase.app.functions().httpsCallable('uploadUsers');

		let userData: Dictionary = {
			users: data,
			defaultCohort: defaultCohort,
			role: role,
			clientManaged: clientManaged,
			domain: domain
		}

		return new Promise((resolve, reject) => {
			bulkAdd(userData).then(function (result) {
				log("AuthService - uploadUsers() - Success")
				resolve(result.data.documentIds)
			}).catch(error => {
                this.eventReportingService.error(error.message, error)
				log("AuthService - uploadUsers() - Failed: " + error)
				reject(error)
			});
		})
	}

	/* istanbul ignore next */
	createUsers(userData: Dictionary[]): Promise<Dictionary> {
		let bulkCreateUsers = this.firebase.app.functions().httpsCallable('bulkCreateUsers');

		let data: Dictionary = { users: userData }

		return new Promise((resolve, reject) => {
			bulkCreateUsers(data).then(function (result) {
				log("AuthService - createUsers() - Success")
				resolve(result.data)
			}).catch(error => {
                this.eventReportingService.error(error.message, error)
				log("AuthService - createUsers() - Failed: " + error)
				reject(error)
			});
		})
	}

	/* istanbul ignore next */
	createUserDocuments(usersData: Dictionary[], defaultCohort: string, role: RoleType, clientManaged: string | null, domain: string): Promise<DocumentId[]> {
		let bulkAdd = this.firebase.app.functions().httpsCallable('createUserDocuments');

		let data: Dictionary = {
			users: usersData,
			defaultCohort: defaultCohort,
			role: role,
			clientManaged: clientManaged,
			domain: domain
		}

		return new Promise((resolve, reject) => {
			bulkAdd(data).then(function (result) {
				log("AuthService - createUserDocuments() - Success")
				resolve(result.data.documentIds)
			}).catch(error => {
                this.eventReportingService.error(error.message, error)
				log("AuthService - createUserDocuments() - Failed: " + error)
				reject(error)
			});
		})
	}

	private domainFromEmail(email: string): string {
		const emailComponents = email.split("@")
		const domainLocation = emailComponents.length - 1
		const domain = emailComponents[domainLocation]
		const domainSubstring = domain.split(".")[0].toLowerCase()

		return domainSubstring
	}

	verifyOobCode(actionCode: string): Promise<void> {
		return new Promise((resolve, reject) => {
			this.firebase
				.auth
				.verifyPasswordResetCode(actionCode)
				.then((email) => {
					log("AuthService - verifyOobCode() - Success")
					resolve()
				}).catch(error => {
					switch(error.code) {
						case "auth/expired-action-code":
							error = "Expired action code"
							break
						case "auth/invalid-action-code":
							error = "Invalid action code"
							break
						case "auth/user-disabled":
							error = "User has been disabled"
							break
						case "auth/user-not-found":
							error = "User not found"
							break
						default:
							error = "Something went wrong!"
					}
					
					this.eventReportingService.warn(error.message, error)
					log("AuthService - verifyOobCode() - Failed: " + error)
					reject(error)
				})
		})
	}

	handleResetPassword(actionCode: string, newPassword: string): Promise<void> {
		return new Promise((resolve, reject) => {
			this.firebase.auth.confirmPasswordReset(actionCode, newPassword).then(() => {
				log("AuthService - handleResetPassword() - Success")
				resolve()
			}).catch((error) => {
				switch(error.code) {
					case "auth/expired-action-code":
						error = "Expired action code"
						break
					case "auth/invalid-action-code":
						error = "Invalid action code"
						break
					case "auth/user-disabled":
						error = "User has been disabled"
						break
					case "auth/user-not-found":
						error = "User not found"
						break
					case "auth/weak-password":
						error = "Password is too weak"
						break
					default:
						error = "Something went wrong!"
				}
				this.eventReportingService.warn(error.message, error)
				log("AuthService - handleResetPassword() - Failed: " + error)
				reject(error)
			})
		})
	}
}
