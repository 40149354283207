import { Component } from "react"
import { Stack, Text } from "@fluentui/react"
import { FontSize } from "./FontSize"
import { Theme } from "./Theme"

export interface ChartCardProps {
    id: string
    title: string
    element: JSX.Element
}

export class ChartCard extends Component<ChartCardProps> {
    render() {
        return (
            <Stack id={this.props.id} styles={Theme.cardStyle} verticalAlign="center" horizontalAlign="center">
                <Text id="chart-card-title" variant={FontSize.xLarge} styles={Theme.cardTitleStyle}>
                    {this.props.title}
                </Text>
                {this.props.element}
            </Stack>
        )
    }
}
