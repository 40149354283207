import React, { Component, MouseEvent } from "react";
import { ICohortService } from "../../../services/CohortService";
import {
	Stack,
	IStackTokens,
	Spinner,
	MessageBar,
	MessageBarType,
	Label,
} from "@fluentui/react";
import { TabPanelProps } from "../TabPanelProps";
import "./EditCohortDetails.css";
import Cohort from "../../../models/Cohort";
import { TextField } from '../../../ui-kit/TextField'
import { PrimaryButton } from "../../../ui-kit/PrimaryButton"
import { container } from "../../../DIContainer";
import { TYPES } from "../../../Types";
import { i18nKey, Ii18n } from "../../../global/i18n";
import { DocumentDeletedOrNotFound } from "../../../ui-kit/DocumentDeletedOrNotFound";
import { ConfirmationDialog } from "../../../ui-kit/ConfirmationDialog";
import { DestructiveIconButton } from "../../../ui-kit/DestructiveIconButton";

interface EditCohortDetailsProps extends TabPanelProps {
    documentId: string;
	cohort: Cohort
}

interface EditCohortDetailsState {
	name: string;
	description: string;
	deleteButtonDisabled: boolean;
	updateButtonDisabled: boolean;
	showSpinner: boolean;
	didCohortDelete: boolean;
	showDeleteCohortSpinner: boolean;
	errorMessage: string | undefined;
	successMessage: string | undefined;
	deleteDialogIsHidden: boolean;
	didCohortLoad: boolean;
}

const stackStyling: IStackTokens = {
	childrenGap: "20px",
};

export default class EditCohortDetails extends Component<EditCohortDetailsProps, EditCohortDetailsState> {
	private cohortService: ICohortService = container.get<ICohortService>(TYPES.ICohortService);
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	state: EditCohortDetailsState = {
		deleteButtonDisabled: false,
		updateButtonDisabled: true,
		showDeleteCohortSpinner: false,
		showSpinner: false,
		didCohortDelete: false,
		name: this.props.cohort.name,
		description: this.props.cohort.description,
		errorMessage: undefined,
		successMessage: undefined,
		deleteDialogIsHidden: true,
		didCohortLoad: false
	}

	componentDidMount() {
		this.checkIfCohortExists()
	}

	render() {
		let banner = null;
		if (this.state.errorMessage) {
			banner = (
				<MessageBar
					id="edit-cohort-error-banner"
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.errorMessage}
				</MessageBar>
			);
		} else if (this.state.successMessage) {
			banner = (
				<MessageBar
					id="edit-cohort-success-banner"
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			);
		}

		return (
			<div id="edit-cohort-modal-container">
				<Stack id="edit-learning-plan-details-stack" tokens={stackStyling}>
					{ !this.state.didCohortLoad && this.state.didCohortDelete &&
						<>
							<DocumentDeletedOrNotFound message={this.i18n.get(i18nKey.groupDoesNotExist)}/>
						</>
					}

					{ this.state.didCohortLoad &&
					<>
					<Label>{this.i18n.get(i18nKey.updateGroupNameAndDescription)}</Label>
					<TextField
						id="edit-cohort-name-textfield"
						label="Name"
						onChange={this.nameTextFieldChanged}
						value={this.state.name}
						disabled={this.props.cohort.isDefault}
					/>
					<TextField
						id="edit-cohort-description-textfield"
						label="Description"
						onChange={this.descriptionTextFieldChanged}
						value={this.state.description}
						disabled={this.props.cohort.isDefault}
					/>
					{ !this.props.cohort.isDefault &&
						<PrimaryButton
							id="edit-cohort-primary-button"
							text="Update"
							onClick={this.updateButtonClicked}
							disabled={this.state.updateButtonDisabled}
							hidden={this.state.showSpinner}
						/>
					}
					{ this.state.showSpinner &&
						<Spinner
							id="edit-cohort-spinner"
							label={this.i18n.get(i18nKey.updatingGroup)}
							ariaLive="assertive"
							labelPosition="right"
						/>
					}

				{ this.state.showDeleteCohortSpinner && 
					<Spinner
						id="edit-cohort-delete-spinner"
						label={this.i18n.get(i18nKey.deletingGroup)}
						ariaLive="assertive"
						labelPosition="right"
					/>
				}
				{ !this.state.showDeleteCohortSpinner && !this.props.cohort.isDefault &&
					<DestructiveIconButton
						id="edit-cohort-delete-button"
						onClick={this.deleteButtonClicked}
						disabled={false}
					/> 
				}
				{ !this.state.deleteDialogIsHidden && 
					<ConfirmationDialog
						title={this.i18n.get(i18nKey.deleteGroupTitle)}
						dialogMessage={this.i18n.get(i18nKey.deleteGroupMessage)}
						affirmativeButtonText={this.i18n.get(i18nKey.deleteGroupAffirmativeButton)}
						dismissButtonText={this.i18n.get(i18nKey.deleteGroupDismissButton)}
						affirmativeActionHandler={this.deleteButtonDestructiveAction}
						dismissActionHandler={this.deleteDialogDismissed}
						isDestructive={true}
					/>
				}
				</>
				}
				{banner}	
				</Stack>
			</div>
		);
	}

	dismissBanner = () => {
		this.setState({ errorMessage: undefined, successMessage: undefined });
	}

	deleteButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({deleteDialogIsHidden: false})
	}
	
	deleteDialogDismissed = () => {
		this.setState({deleteDialogIsHidden: true})
	}

	deleteButtonDestructiveAction = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: undefined,
			successMessage: undefined,
			showDeleteCohortSpinner: true,
			updateButtonDisabled: true,
			deleteButtonDisabled: true,
		});
		this.cohortService
			.deleteCohort(this.props.documentId)
			.then(() => {
				this.setState({
					successMessage: "Group " + this.props.cohort.name + " has been deleted.",
					showDeleteCohortSpinner: false,
					didCohortDelete: true,
					didCohortLoad: false
				})
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showDeleteCohortSpinner: false,
					updateButtonDisabled: false,
					deleteButtonDisabled: false,
				});
			})
	}

	updateButtonClicked = (e: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: undefined,
			successMessage: undefined,
			showSpinner: true,
			updateButtonDisabled: true,
		});

		this.cohortService
			.updateCohortDetails(
				this.props.documentId,
				this.state.name,
				this.state.description
			)
			.then(() => {
				const message = this.state.name + " has been updated";
				this.setState({
					successMessage: message,
					showSpinner: false,
					updateButtonDisabled: false,
				});
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showSpinner: false,
					updateButtonDisabled: false,
				});
			});
	}

	private checkIfCohortExists = () => {
		this.setState({ showSpinner: true })

		this.cohortService
			.searchCohorts(this.props.cohort.name)
			.then(result => {
				if (result.length >= 1) {
					this.setState({ 
						errorMessage: undefined,
						showSpinner: false,
						didCohortLoad: true  
					})
				}
				else {
					throw new Error()
				}
			})
			.catch(error => {
				this.setState({ 
					errorMessage: this.i18n.get(i18nKey.failedToLoadGroup),
					showSpinner: false,
					didCohortDelete: true  
				})
			})
	}

	nameTextFieldChanged = (
		_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return;
		}

		this.setState({ name: newValue }, () => {
			this.enableUpdateButtonIfNeeded();
		});
	}

	descriptionTextFieldChanged = (
		_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return;
		}

		this.setState({ description: newValue }, () => {
			this.enableUpdateButtonIfNeeded();
		});
	}

	enableUpdateButtonIfNeeded = () => {
		let disable = true;

		if (this.state.name.length > 0 && this.state.description.length > 0) {
			disable = false;
		}

		this.setState({ updateButtonDisabled: disable });
	}
}
