import React, { Component } from "react";
import { Stack, Slider, IStackStyles, IStackTokens } from "@fluentui/react";

type onChangedEvent = (event: MouseEvent | TouchEvent | KeyboardEvent, value: number) => void

export interface RangedScaleSliderProps {
    label: string
    min: number
    max: number
    sliderOnChanged?: onChangedEvent
    scaleValueChanged: (value: number, range: [number, number]) => void
}

export interface RangedScaleSliderState {}

export class RangedScaleSlider extends Component<RangedScaleSliderProps, RangedScaleSliderState> {
    private sliderMin = 0
    private sliderMax = 10
    private sliderStep = 1
    private defaultLowerValue = 1
    private defaultValue = 5
    
    private stackStyling: IStackTokens = { childrenGap: "20px" }
    private sliderStackStyles: Partial<IStackStyles> = { root: { maxWidth: 460 } }

    render() {
        return (
            <Stack tokens={this.stackStyling}>
                <Slider 
                    ranged
                    className="ranged-slider"
                    styles={this.sliderStackStyles}
                    label={this.props.label}
                    min={this.sliderMin} 
                    max={this.sliderMax} 
                    step={this.sliderStep}
                    lowerValue={this.props.min || this.defaultLowerValue}
                    value={this.props.max || this.defaultValue}
                    onChange={this.sliderOnChanged}
                    showValue 
                />
            </Stack>
        )
    }

	sliderOnChanged = (value: number, range?: [number, number]) => {
		if (range !== undefined) {
            this.props.scaleValueChanged(value, range)
        }
	}
}