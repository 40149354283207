import React, { Component } from "react"
import {
	Modal,
	MessageBarType,
	MessageBar,
} from "@fluentui/react"

import { TabPanelProps } from "../TabPanelProps"
import DTSTable from "../../../ui-kit/DTSTable"
import IDataTableFactory from "../../../factories/DataTableFactory"
import DTSTableData from "../../../models/DTSTableData"
import { Loader } from "../../../ui-kit/Loader"
import Cohort from "../../../models/Cohort"
import { ICohortService } from "../../../services/CohortService"
import NewCohortModal from "./NewCohortModal"
import { SecondaryButton } from '../../../ui-kit/SecondaryButton'
import { container } from "../../../DIContainer"
import { TYPES } from "../../../Types"
import { log } from "../../../global/Logger"
import { i18nKey, Ii18n } from "../../../global/i18n"
import { IRouter } from "../../../Router"
import { DocumentId } from "../../../global/TypeAliases"

interface CohortsAdminProps extends TabPanelProps {
	clientId: DocumentId
}

interface CohortsAdminState {
	isLoading: boolean
	cohorts: Cohort[]
	tableData: DTSTableData | undefined
	isModalOpen: boolean
	successMessage: string | null
	errorMessage: string | null
}

export default class CohortsAdmin extends Component<CohortsAdminProps, CohortsAdminState> {
	private cohortService: ICohortService = container.get<ICohortService>(TYPES.ICohortService)
	private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
	private router: IRouter = container.get<IRouter>(TYPES.IRouter)

	state: CohortsAdminState = {
		isLoading: true,
		cohorts: [],
		tableData: undefined,
		isModalOpen: false,
		successMessage: null,
		errorMessage: null,
	}

	componentDidMount() {
		this.loadData()
	}

	render() {
		if (this.state.isLoading) {
			return <Loader />
		}

		return (
			<div
				className="admin-tab-panel"
				id={`admin-tab-panel-${this.props.index}`}
				aria-labelledby={`admin-tab-${this.props.index}`}
			>
				{ this.showBannerIfNeeded()}

				{ this.state.tableData !== undefined &&
					<>
						<div className="admin-command-bar">
							<SecondaryButton
								id="add-group-button"
								iconProps={{ iconName: "WebAppBuilderFragmentCreate" }}
								text={this.i18n.get(i18nKey.addNewGroup)}
								onClick={this.addCohortButtonClicked}
							/>
						</div>

						<DTSTable 
							data={this.state.tableData} 
							aria-label="cohort table" 
							onRowClicked={this.onRowClicked} 
							pointerOnHover={true}
							highlightOnHover={true}
							pagination={true}
							filter={true}
							filterPlaceholder={this.i18n.get(i18nKey.searchForGroup)}
						/>
					</>
				}

				<Modal
					titleAriaId="cohort modal"
					isOpen={this.state.isModalOpen}
					onDismiss={this.modalDismissed}
					isBlocking={false}
				>
					<NewCohortModal clientId={this.props.clientId} cohortAdded={this.reloadData} />
				</Modal>
			</div>
		)
	}

	private loadData = () => {
		this.loadCohortsForClientId(this.props.clientId)
	}

	private loadCohortsForClientId = (clientId: string) => {
		log("Loading cohorts for client id")

		this.cohortService
			.cohortsForClient(clientId)
			.then(cohorts => {
				let tableData = this.dataTableFactory.createCohortsTableData(cohorts)

				this.setState({
					isLoading: false,
					cohorts: cohorts, 
					tableData: tableData 
				})
			})
			.catch(_ => {
				this.setState({ 
					isLoading: false,
					errorMessage: this.i18n.get(i18nKey.failedToLoadGroups)
				})
			})
	}

	private addCohortButtonClicked = () => {
		this.setState({ isModalOpen: true })
	}

	private onRowClicked = (row: {[key: string]: string}) => {
		const cohortId: DocumentId = row.id

        const cohort = this.state.cohorts.find(
            (cohort) => cohort.documentId === cohortId
        )

		if (cohort === undefined) {
            this.setState({ errorMessage: this.i18n.get(i18nKey.unableToLoadGroupPleaseTryAgain) })
			return
		}

		const data = {
			documentId: cohortId,
			cohort: cohort,
		}

		this.router.goToEditGroupDetails(data)
	}

	private reloadData = (successMessage: string) => {
		this.modalDismissed()
		this.loadData()
		this.setState({ successMessage: successMessage })
	}

	private modalDismissed = () => {
		this.setState({ isModalOpen: false })
	}

	private showBannerIfNeeded = () => {
		let banner: React.ReactNode | null = null

		if (this.state.successMessage) {
			banner = <MessageBar
						id="success-banner"
						messageBarType={MessageBarType.success}
						dismissButtonAriaLabel="Close"
						onDismiss={this.dismissBanner}
					>
						{this.state.successMessage}
					</MessageBar>
		} else if (this.state.errorMessage) {
			banner = <MessageBar
						id="error-banner"
						messageBarType={MessageBarType.error}
						dismissButtonAriaLabel="Close"
						onDismiss={this.dismissBanner}
					>
						{this.state.errorMessage}
					</MessageBar>
		}
			
		return banner
	}

	dismissBanner = () => {
		this.setState({ successMessage: null, errorMessage: null })
	}
}
