import { IStackTokens, Label, MessageBar, MessageBarType, PrimaryButton, SearchBox, Spinner, Stack } from "@fluentui/react";
import React, { Component, ReactElement, MouseEvent } from "react";
import { container } from "../../../DIContainer";
import { i18nKey, Ii18n } from "../../../global/i18n";
import { log } from "../../../global/Logger";
import { DocumentId } from "../../../global/TypeAliases";
import DTSTableData from "../../../models/DTSTableData";
import { Survey } from "../../../models/Survey";
import { TYPES } from "../../../Types";
import { DestructiveButton } from "../../../ui-kit/DestructiveButton";
import DTSTable from "../../../ui-kit/DTSTable";
import PageTitle from "../../../ui-kit/PageTitle";
import { AdminUIStyles } from "../users/AdminUI";

interface CohortSurveyContentsProps {
    surveysTableData: DTSTableData
    surveySearchTableData: DTSTableData | undefined
    searchForSurvey: (value: string) => void
    searchButtonClicked: (selectedSurveysToAdd: string[]) => Promise<string>
    removeButtonClicked: (selectedSurveysToRemove: string[]) => Promise<string>
}

interface CohortSurveyContentsState {
    successMessage: string | undefined;
    errorMessage: string | null;
    
    // remove survey states
    clearSelectedSurveyRows: boolean;
    selectedSurveys: DocumentId[];
    selectedSurveysPrimaryButtonDisabled: boolean;
    showRemoveSurveySpinner: boolean;

    // search/add surveys states
    showSurveySearchSpinner: boolean
    showSurveyAddSpinner: boolean
    surveySearchResults: Survey[];
    addSurveysPrimaryButtonDisabled: boolean
    selectedSurveysToAdd: DocumentId[];
}

const stackStyling: IStackTokens = {
	childrenGap: "20px",
}

export class CohortSurveyContents extends Component<CohortSurveyContentsProps, CohortSurveyContentsState> {
    private i18n = container.get<Ii18n>(TYPES.Ii18n)

    state: CohortSurveyContentsState = {
        successMessage: undefined,
		errorMessage: null,

        // remove learning plan states
        clearSelectedSurveyRows: false,
        selectedSurveys: [],
        selectedSurveysPrimaryButtonDisabled: true,
        showRemoveSurveySpinner: false,

        // search/add learning plan states
        showSurveySearchSpinner: false,
        showSurveyAddSpinner: false,
        surveySearchResults: [],
        addSurveysPrimaryButtonDisabled: true,
        selectedSurveysToAdd: [],
	}
    
    render() {
        let surveysData = this.props.surveysTableData;

        let banner = null
		if (this.state.errorMessage) {
			banner = (
                <MessageBar
                    id="cohort-contents-page-error-banner"
                    messageBarType={MessageBarType.error}
                    dismissButtonAriaLabel="Close"
                    onDismiss={this.dismissErrorBanner}
                >
                    {this.state.errorMessage}
                </MessageBar>
			)
		} else if (this.state.successMessage) {
			banner = (
				<MessageBar
					id="cohort-contents-page-success-banner"
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissSuccessBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			)
		}

        let surveySearchResult: ReactElement | undefined
		if (this.props.surveySearchTableData !== undefined && !this.state.showSurveySearchSpinner) {
			surveySearchResult = (
				<div id="add-cohort-to-survey-table">
					<Stack tokens={stackStyling} styles={{ root: { marginBottom: 25 }}}>
						<DTSTable
							data={this.props.surveySearchTableData}
                            pagination={true}
                            onSelectedRowsChange={this.onSelectedSurveySearchRowChange}
						/>
						<PrimaryButton
							id="add-cohort-to-survey-primary-button"
							text={this.i18n.get(i18nKey.addSelectedSurveysButton)}
							onClick={this.searchSurveyPrimaryButtonClicked}
							disabled={this.state.addSurveysPrimaryButtonDisabled}
							hidden={this.state.showSurveySearchSpinner}
						/>
						{ this.state.showSurveyAddSpinner &&
							<Spinner
								label={this.i18n.get(i18nKey.addSelectedSurveysSpinner)}
								ariaLive="assertive"
								labelPosition="right"
							/>
						}
					</Stack>
				</div>
			)
        }
        return(
            <Stack tokens={stackStyling} styles={{ root: { marginBottom: 25 }}}>
                {banner}
                <PageTitle title={this.i18n.get(i18nKey.surveysAssigned)} isEditGroupPageSubtitle={true}/>
                    <DTSTable
                        data={surveysData} 
                        aria-label="survey table"
                        highlightOnHover={true}
                        pagination={true}
                        filter={true}
                        filterPlaceholder={this.i18n.get(i18nKey.searchForSurvey)}
                        onSelectedRowsChange={this.onSelectedSurveyRowsChange}
                        clearSelectedRows={this.state.clearSelectedSurveyRows}
                    />
                    <Label>{this.i18n.get(i18nKey.labelSurveysAddSearch)}</Label>
					<SearchBox
						id="add-cohort-to-survey-search-box"
						styles={AdminUIStyles.searchBoxStyle}
						placeholder={this.i18n.get(i18nKey.addSelectedSurveysSearch)}
						onEscape={(ev) => {
							log("Custom onEscape Called")
						}}
						onClear={(ev) => {
							log("Custom onClear Called")
						}}
						onChange={(_, newValue) =>
							log("SearchBox onChange fired: " + newValue)
						}
						onSearch={(newValue) => this.props.searchForSurvey(newValue)}
					/>

					{ this.state.showSurveySearchSpinner && 
						<Spinner
							id="add-cohort-to-survey-search-spinner"
							label={this.i18n.get(i18nKey.spinnerSearching)}
							ariaLive="assertive"
							labelPosition="right"
						/>
					}
                    {surveySearchResult}
                    <DestructiveButton
                        id="remove-survey-primary-button"
                        text={this.i18n.get(i18nKey.removeSelected)}
                        onClick={this.removeSurveysPrimaryButtonClicked}
                        disabled={this.state.selectedSurveysPrimaryButtonDisabled}
                        hidden={this.state.showRemoveSurveySpinner}
                    />

                    { this.state.showRemoveSurveySpinner && 
                        <Spinner
                            id="remove-survey-spinner"
                            label="Removing"
                            ariaLive="assertive"
                            labelPosition="right"
                        />
                    }
            </Stack>
        )
    }

    private dismissErrorBanner = () => {
		this.setState({ errorMessage: null });
	}

    private dismissSuccessBanner = () => {
		this.setState({ successMessage: undefined })
	}

    onSelectedSurveyRowsChange = (selectedRowState: {
		allSelected: boolean
		selectedCount: number
		selectedRows: { [key: string]: any }[]
	}) => {
        if (selectedRowState.selectedCount === 0) {
			this.setState({ selectedSurveys: [] }, () => {
				this.enableRemoveSurveyPrimaryButtonIfNeeded()
			})
			return
		}

        const selectedIds = selectedRowState.selectedRows.map((row) => {
			return row.id
		})

		log(selectedIds.length + " surveys selected")

		this.setState({ selectedSurveys: selectedIds }, () => {
			this.enableRemoveSurveyPrimaryButtonIfNeeded()
		})
	}

    onSelectedSurveySearchRowChange = (selectedRowState: {
        allSelected: boolean
        selectedCount: number
        selectedRows: { [key: string]: any }[]
    }) => {
        if (selectedRowState.selectedCount === 0) {
			this.setState({ selectedSurveysToAdd: [] }, () => {
				this.enableSearchSurveyPrimaryButtonIfNeeded()
			})
			return
		}

        const selectedIds = selectedRowState.selectedRows.map((row) => {
			return row.id
		})

		log(selectedIds.length + " surveys selected to add")

		this.setState({ selectedSurveysToAdd: selectedIds }, () => {
            this.enableSearchSurveyPrimaryButtonIfNeeded()
		})
    }

    enableRemoveSurveyPrimaryButtonIfNeeded = () => {
		let disable = true

		if (this.state.selectedSurveys.length > 0) {
			disable = false
		}

		this.setState({ selectedSurveysPrimaryButtonDisabled: disable })
	}

    enableSearchSurveyPrimaryButtonIfNeeded = () => {
		this.setState({
            addSurveysPrimaryButtonDisabled: !(this.state.selectedSurveysToAdd.length > 0)
		})
	}

    searchSurveyPrimaryButtonClicked = async (_: MouseEvent<HTMLElement>) => {
		this.setState({
            errorMessage: null,
			successMessage: undefined,
			showSurveyAddSpinner: true,
			addSurveysPrimaryButtonDisabled: true
		})

        this.props.searchButtonClicked(this.state.selectedSurveysToAdd)
            .then(message => {
                this.setState({
                    successMessage: message,
                    showSurveyAddSpinner: false,
                    addSurveysPrimaryButtonDisabled: true,
                    selectedSurveysToAdd: []
                })
            })
            .catch(error => {
                this.setState({
                    errorMessage: error.message,
                    showSurveyAddSpinner: false,
                    selectedSurveysPrimaryButtonDisabled: false
                })
            })   
	}

    removeSurveysPrimaryButtonClicked = async (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: null,
			successMessage: undefined,
            selectedSurveysPrimaryButtonDisabled: true,
            showRemoveSurveySpinner: true
		})

        this.props.removeButtonClicked(this.state.selectedSurveys)
            .then(message => {
                this.setState({
                    successMessage: message,
                    showRemoveSurveySpinner: false,
                    selectedSurveysPrimaryButtonDisabled: true,
                    selectedSurveys: [],
                    clearSelectedSurveyRows: true
                })
            })
            .catch(error => {
                this.setState({
                    errorMessage: error.message,
                    showRemoveSurveySpinner: false,
                    selectedSurveysPrimaryButtonDisabled: false
                })
            })
    }
}