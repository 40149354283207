import React, { Component } from "react";

// Import Icons and Images
import dashboardIcon from "../images/navigation-bar/icon-dashboard.svg"
import learnersIcon from "../images/navigation-bar/icon-user.svg"
import contentsIcon from "../images/navigation-bar/icon-compass.svg"
import profileIcon from "../images/navigation-bar/icon-profile.svg"
import logoutIcon from "../images/navigation-bar/icon-follow.svg"
import reportIcon from "../images/navigation-bar/icon-report.svg"
import clientsIcon from "../images/navigation-bar/icon-clients.svg"
import globalStatsIcon from "../images/navigation-bar/icon-global-stats.svg"
import logInAsIcon from "../images/navigation-bar/icon-log-in-as.svg"
import manageInvitationsIcon from "../images/navigation-bar/icon-manage-invitations.svg"
import defaultProfileImage from "../images/default-profile.png"
import logoLarge from "../images/logo-large-pink.svg"

// Import Scrollbar
import SimpleBar from "simplebar-react";

import { NavLink } from "react-router-dom";
import { PathNames } from "../PathNames";
import { Stack } from "@fluentui/react"
import { IAccessController } from "../services/AccessController";
import { container } from "../DIContainer";
import { TYPES } from "../Types";
import { IAuth } from "../auth/Auth";
import { IRouter } from "../Router";
import { Ii18n, i18nKey } from "../global/i18n";
import User from "../models/User";
import { DocumentId } from "../global/TypeAliases";
import { ClientContextSelector } from "./ClientContextSelector";
import { IAuthService } from "../services/AuthService";
import { Subject } from "rxjs";
import { log } from "../global/Logger";
import { ErrorDialogV2 } from "./ErrorDialogV2";
import { FooterV2 } from "./FooterV2";

interface HeaderProps {
    firebaseUserObservable: Subject<firebase.User | null>
    userObservable: Subject<User | null>
}

interface HeaderState {
    user: User | undefined
    firebaseUser: firebase.User | null
    clientId: DocumentId | null
    loggedIn: boolean
    showErrorDialog: boolean
    isMenuOpen: boolean
    isMobileView: boolean
}

export default class HeaderV2 extends Component<HeaderProps, HeaderState> {
    private acl: IAccessController = container.get<IAccessController>(TYPES.IAccessController)
    private auth: IAuth = container.get<IAuth>(TYPES.IAuth)
    private authService: IAuthService = container.get<IAuthService>(TYPES.IAuthService)
    private router: IRouter = container.get<IRouter>(TYPES.IRouter)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private MOBILE_SCREEN_WIDTH = 991.98

    state: HeaderState = {
        user: this.auth.user,
        firebaseUser: this.auth.firebaseUser(),
        clientId: this.auth.getClientContext(),
        loggedIn: this.auth.isLoggedIn(),
        showErrorDialog: false,
        isMobileView: window.innerWidth <= this.MOBILE_SCREEN_WIDTH ? true : false,
        isMenuOpen: false
    }

    onAuthStateChange = (isLoggedIn: boolean) => {
        log(`Header - isLoggedIn - ${isLoggedIn}`)        
        this.setState({
            loggedIn: isLoggedIn,
            user: this.auth.user,
            firebaseUser: this.auth.firebaseUser()
        })
    }

    checkWindowChange = () => {
        if (window.innerWidth <= this.MOBILE_SCREEN_WIDTH) {
            this.setState({ isMobileView: true })
        } else {
            this.mobileToggle(false)
            this.setState({ 
                isMobileView: false
            })
        }
    }

    onLogoutClick = () => {
        this.authService.logout().then(() => {
            this.router.goToLogin()
        }).catch(_ => {
            /**
             * Silent fail
             */
        })
    }

    mobileToggle = (menuOpen: boolean) => {
        var body = document.body;
        if (menuOpen === true) {
            body.classList.add("sidebar-enable");
            document.body.setAttribute("data-sidebar-size", "sm");
        } else {
            body.classList.remove("sidebar-enable");
            document.body.setAttribute("data-sidebar-size", "lg");
        }

        this.setState({
            isMenuOpen: menuOpen
        })
    }

    errorDialogOnClick = () => {
        this.setState({ showErrorDialog: false })
    }

    endLogInAsClicked = () => {
        this.auth
            .turnOffLogInAs()
            .then(() => {
                this.router.refreshPage()
            }).catch(_ => {
                this.setState({ showErrorDialog: true })
            })
    }

    componentDidMount() {
        window.addEventListener('resize', this.checkWindowChange)

        this.auth.addAuthObserver(this)
    
        this.props.firebaseUserObservable.subscribe(firebaseUser => {
            this.setState({ firebaseUser: firebaseUser })
        })
        
        this.props.userObservable.subscribe(user => {
            if (user !== null) {
                this.setState({ user: user })
            }
        })
    }

    render() {
        if (this.state.user === undefined) {
            return null
        }
    
        let navigationLinks
    
        if (this.state.loggedIn && this.acl.canAdministrate()) {
            navigationLinks = (
                <>
                    <li id="dashboard-link">
                        <NavLink to={PathNames.Dashboard}>
                            <img src={dashboardIcon} alt="dashboard-icon" className="nav-icon" />
                            Dashboard
                        </NavLink>
                    </li>  
                    <li id="profile-link">
                        <NavLink to={PathNames.Profile}>
                            <img src={profileIcon} alt="profile-icon" className="nav-icon" />
                            Profile
                        </NavLink>
                    </li>
                    <li className="menu-title">{"Admin"} </li>
                    <li id="learners-page-link">
                        <NavLink to={PathNames.Learners}>
                            <img src={learnersIcon} alt="learners-icon" className="nav-icon" />
                            Learners
                        </NavLink>
                    </li>
                    <li id="contents-page-link">
                        <NavLink to={PathNames.Contents}>
                            <img src={contentsIcon} alt="contents-icon" className="nav-icon" />
                            Content
                        </NavLink>
                    </li>
                    <li id="reports-page-link">
                        <NavLink to={PathNames.Reports}>
                            <img src={reportIcon} alt="reports-icon" className="nav-icon" />
                            Reports
                        </NavLink>
                    </li>
    
                    { this.acl.isGlobalAdmin() &&
                        <> 
                            <li className="menu-title">{"Global Admin"} </li>
                            <li id="clients-page-link">
                                <NavLink to={PathNames.Clients}>
                                    <img src={clientsIcon} alt="learners-icon" className="admin-nav-icon" />
                                    Clients
                                </NavLink>
                            </li> 
                            <li id="global-stats-page-link">
                                <NavLink to={PathNames.GlobalStats}>
                                    <img src={globalStatsIcon} alt="reports-icon" className="admin-nav-icon" />
                                    Global Stats
                                </NavLink>
                            </li> 
                            <li id="guest-codes-page-link">
                                <NavLink to={PathNames.ManageInvitations}>
                                    <img src={manageInvitationsIcon} alt="reports-icon" className="admin-nav-icon" />
                                    Guest Codes
                                </NavLink>
                            </li> 
                        </>
                    }
    
                    { this.acl.canLogInAsUser() &&
                        <li id="log-in-as-page-link">
                            <NavLink to="/logInAs">
                                <img src={logInAsIcon} alt="logInAs-icon" className="admin-nav-icon" />
                                Log In As
                            </NavLink>
                        </li> 
                    }
                    
                    <li>
                        <NavLink id="logout-link" to={PathNames.Login} onClick={this.onLogoutClick}>
                            <img src={logoutIcon} alt="logout-icon" className="nav-icon" />
                            Logout
                        </NavLink>
                    </li> 
                </>
            )
        } else if (this.state.loggedIn && !this.acl.canAdministrate()) {
            navigationLinks = (
                <>
                    <li id="dashboard-link">
                        <NavLink to="/dashboard">
                            <img src={dashboardIcon} alt="dashboard-icon" className="nav-icon" />
                            Dashboard
                        </NavLink>
                    </li>
                    <li id="profile-link">
                        <NavLink to="/profile">
                            <img src={profileIcon} alt="profile-icon" className="nav-icon" />Profile
                        </NavLink>
                    </li>
                    <li id="logout-link">
                        <NavLink to={PathNames.Login} onClick={this.onLogoutClick}>
                            <img src={logoutIcon} alt="logout-icon" className="nav-icon" />
                            Logout
                        </NavLink>
                    </li>
                </>
            )
        } else {
            navigationLinks = (
                <li>
                    <NavLink to="/login">
                        <img src={logoutIcon} alt="login-icon" className="nav-icon" />
                        Login
                    </NavLink>
                </li>
            )
        }
    
        let profileImageURLString = defaultProfileImage
        const fbUser = this.state.firebaseUser
        if (fbUser !== null && fbUser.photoURL !== null) {
            profileImageURLString = fbUser.photoURL
        }
    
        return (
            <React.Fragment>
                {this.state.isMobileView && 
                    <button
                        onClick={() => {this.mobileToggle(!this.state.isMenuOpen)}}
                        type="button"
                        className="btn btn-sm px-3 font-size-16 header-item"
                        id="vertical-menu-btn"
                        style={{paddingLeft: this.state.isMenuOpen ? 240 : 0}}
                    >
                        <i className="fa fa-fw fa-bars" style={{paddingLeft: this.state.isMenuOpen ? 240 : 0}}></i>
                    </button>
                }
                <div className="vertical-menu">
                    <div data-simplebar className="h-100">
                        <SimpleBar style={{ maxHeight: "100%" }}>
                        <div id="sidebar-menu">
                            <Stack horizontalAlign="center" tokens={{ childrenGap: 30 }} styles={{root: {marginBottom: "3rem"}}}>
                                <Stack id="navigation-logo-stack">
                                    <img id="header-logo-image" src={logoLarge} width={150} height={100} alt="DTS Logo" className="mx-auto"/>
                                </Stack>
                                <NavLink to="/profile" id="profile-image-and-name-navlink">
                                    <div className="text-center d-grid gap-3">
                                        <img id="header-profile-image" src={profileImageURLString} className="rounded-circle mx-auto avatar-xl" alt=""/>
                                        <div className="fs-4">{this.state.user.firstName}</div>
                                        { !this.acl.canSelectClientContext() && 
                                            <div id="role-name-label" className="fs-6">{this.state.user.roleName}</div>
                                        }
                                    </div>
                                </NavLink>
                                { this.acl.canSelectClientContext() &&
                                    <ClientContextSelector clientId={this.state.clientId} />
                                }
                            </Stack>
                            <ul className="list-unstyled" id="side-menu">
                                {navigationLinks}
                            </ul>
                                    
                            { this.auth.isLogInAsActive() &&
                                <Stack horizontalAlign="center" tokens={{ childrenGap: 30 }}>
                                    <button id="endLogInAsButton" className="btn btn-danger mt-2" onClick={this.endLogInAsClicked}>
                                        {this.i18n.get(i18nKey.endLogInAs)}
                                    </button>
                                </Stack>
                            }
                        
                            { this.state.showErrorDialog &&
                                <ErrorDialogV2 
                                    title={this.i18n.get(i18nKey.error)} 
                                    message={this.i18n.get(i18nKey.failedToEndLogInAs)} 
                                    onClick={this.errorDialogOnClick} 
                                />
                            }
    
                            <FooterV2/>
                        </div>
                        </SimpleBar>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
