
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"
import React from "react";
import { container } from "../DIContainer";
import { i18nKey, Ii18n } from "../global/i18n";
import { TYPES } from "../Types";

interface ErrorDialogProps {
    title: string
    message: string
    onClick: () => void
}


export const ErrorDialogV2: React.FC<ErrorDialogProps> = ({title, message, onClick}) => {
    const i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    
        return (
            <Modal isOpen={true}>
                <ModalHeader id="error-dialog-header">{title}</ModalHeader>
                    <ModalBody id="error-dialog-body">
                        {message}
                    </ModalBody>
                <ModalFooter id="error-dialog-footer">
                    <Button id="error-dialog-close-button" color="primary" onClick={onClick}>
                        {i18n.get(i18nKey.close)}
                    </Button>
                </ModalFooter>
            </Modal>
        )
}
