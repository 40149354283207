import { Component } from "react"
import { log } from "../../global/Logger"
import { IDropdownOption, Stack } from "@fluentui/react"
import { Dropdown } from "../../ui-kit/Dropdown"
import { PrimaryButton } from "../../ui-kit/PrimaryButton"
import { TextField } from "../../ui-kit/TextField"
import { container } from "../../DIContainer"
import { TYPES } from "../../Types"
import { i18nKey, Ii18n } from "../../global/i18n"
import { GuestContentStyles } from "./GuestContentStyles"
import "./GuestSelectionDropdown.css"

export interface GuestSelectionDropdownProps {
    onSubmitClick: (entity: string) => void
    entities: string[]
}

export interface GuestSelectionDropdownState {
    entities: IDropdownOption[]
    otherEntityText: string
    primaryButtonDisabled: boolean
    selectedEntity: string
}

export default class GuestSelectionDropdown extends Component<GuestSelectionDropdownProps, GuestSelectionDropdownState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    componentDidMount() {
        this.setEntitiesState()
    }

    state: GuestSelectionDropdownState = {
        entities: [],
        otherEntityText: "",
        primaryButtonDisabled: true,
        selectedEntity: ""
    }

    render() {
        return (
            <div id="guest-entity-container">
                <Stack 
                    id="guest-entity-stack" 
                    tokens={GuestContentStyles.guestHeaderTokens} 
                >
                    <Dropdown
                        id="guest-entity-dropdown"
                        label={this.i18n.get(i18nKey.selectEntity)}
                        placeholder={this.i18n.get(i18nKey.selectOrganization)}
                        options={this.state.entities}
                        onChange={this.entityChanged}
                        required
                    />

                    <TextField
                        id="other-entity-textfield"
                        label={this.i18n.get(i18nKey.affiliation)}
                        placeholder={this.i18n.get(i18nKey.affiliationPlaceholder)}
                        onChange={this.otherTextFieldChanged}
                    />

                    <PrimaryButton
                        id="guest-entity-primary-button"
                        disabled={this.state.primaryButtonDisabled}
                        text={this.i18n.get(i18nKey.submit)}
                        onClick={this.primaryButtonClicked}
                        width="150px"
                    />
                </Stack>
            </div>
        )
    }

    /**
     * Evaluates if the text field should be displayed when "Other" is selected as an entity.
     *
     * @returns true if the selected entity is "Other"
     * false otherwise
     */
    displayOtherTextField = (): boolean => {
        return this.state.selectedEntity.toLowerCase() === "other"
    }

    /**
     * Updates the selected entity state when a dropdown selection is made.
     *
     * @param event the event object from a dropdown
     * @param option an IDropdownOption that contains the value of the dropdown selection
     * @param index the index associated with the dropdown selection
     */
    entityChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        log("Entity selected: " + option?.data)
        const selectedEntity = option?.data as string
        this.setState(
            {
                selectedEntity: selectedEntity
            },
            () => {
                this.enablePrimaryButtonIfNeeded()
            }
        )
    }

    /**
     * Updates the other entity text state when a textfield is modified
     *
     * @param event the event object from a textfield
     * @param newValue the string value from a textfield
     */
    otherTextFieldChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue) {
            this.setState({ otherEntityText: newValue }, () => {
                this.enablePrimaryButtonIfNeeded()
            })
        }
    }

    /**
     * Triggers the prop callback to send the entity to its parent component.
     */
    primaryButtonClicked = () => {
        const data: string = this.state.otherEntityText ? this.state.otherEntityText : this.state.selectedEntity
        this.props.onSubmitClick(data)
    }

    /**
     * Converts a collection of string entities to IDropdownOption collection for the dropdown object
     */
    private setEntitiesState = (): void => {
        const entities = this.props.entities.sort((a, b) => a.localeCompare(b))
        let entityOptions: IDropdownOption[] = entities.map((entity) => {
            return {
                key: entity,
                text: entity,
                data: entity
            }
        })

        entityOptions.push({
            key: "Other",
            text: "Other",
            data: "Other"
        })

        this.setState({ entities: entityOptions })
    }

    /**
     * Evaluates if the primary button should be enabled or not.
     * Enabled if:
     *      - selectedEntity exists AND it is not "Other" or
     *      - otherEntityText exists
     * Otherwise disabled
     */
    private enablePrimaryButtonIfNeeded = () => {
        let disable = true

        if ((this.state.selectedEntity && this.state.selectedEntity.toLowerCase() !== "other") || this.state.otherEntityText) {
            disable = false
        }

        this.setState({ primaryButtonDisabled: disable })
    }
}
