import { IStackStyles } from "@fluentui/react"

export class LearningPlanDetailModalStyle {

    static courseGridStackStyle: IStackStyles = {
        root: 
            {
                padding: "30px 50px", 
                overflow: "auto"
            }
    }
}