import { injectable } from "inversify";
import { container } from "../DIContainer";
import { DocumentId } from "../global/TypeAliases";
import Cohort from "../models/Cohort";
import Course from "../models/Course";
import { LRSCompletionStatement } from "../models/LRSStatement";
import User from "../models/User";
import { TYPES } from "../Types";
import { ICohortService } from "./CohortService";
import { ILRSService } from "./LRSService";
import { IUserService } from "./UserService";
import { IEventReportingService } from '../services/EventReportingService'
import { LRSLaunchStatement } from "../models/LRSLaunchStatement";
import { IGroupIDService } from "./GroupIdService";
import { i18nKey, Ii18n } from "../global/i18n";

export interface IReportCompletionCompositionService {
    getData(clientId: DocumentId, course: Course): Promise<IReportCompletionData>
    getDateFilteredData(clientId: string, course: Course, startDate?: Date, endDate?: Date): Promise<IFilteredReportCompletionData>
}

export interface IReportCompletionData {
    users: User[]
    cohorts: Cohort[]
    statements: LRSCompletionStatement[]
    attemptStatements: LRSCompletionStatement[]
    launchLocationStatements: LRSLaunchStatement[]
    isAssignedToGroups: boolean
}

export interface IFilteredReportCompletionData {
    statements: LRSCompletionStatement[]
    cohorts: Cohort[]
}

@injectable()
export class ReportCompletionCompositionService implements IReportCompletionCompositionService {
    private userService: IUserService = container.get<IUserService>(TYPES.IUserService)
    private lrsService: ILRSService = container.get<ILRSService>(TYPES.ILRSService)
    private cohortService: ICohortService = container.get<ICohortService>(TYPES.ICohortService)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)
    private groupIdService: IGroupIDService = container.get<IGroupIDService>(TYPES.IGroupIDService)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    getData(clientId: string, course: Course): Promise<IReportCompletionData> {
        return new Promise((resolve, reject) => {
                    return Promise.all([
                        Promise.resolve(course),
                        this.groupIdService.getGroupIdsForTraining(course, clientId)
                    ]).then(result => {
                        const course: Course = result[0]
                        const groupIds: Set<DocumentId> = result[1]

                        if (groupIds.size === 0) {
                            throw new Error(this.i18n.get(i18nKey.noGroupAssigned))
                        }

                        const promises: Promise<any>[] = [    
                                this.userService.usersForClient(clientId),
                                this.lrsService.getCourseCompletions(course, clientId, groupIds),
                                this.cohortService.cohortsForClient(clientId),
                                this.lrsService.getCourseAttempts(course, clientId, groupIds),
                                this.lrsService.getTrainingLaunchLocation(clientId, course.lrsId),
                        ]
    
                        return Promise.all(promises)
                    }).then(result => {
                            const data: IReportCompletionData = {
                                users: result[0], 
                                statements: result[1], 
                                cohorts: result[2], 
                                attemptStatements: result[3],
                                launchLocationStatements: result[4],
                                isAssignedToGroups: true
                            }

                        resolve(data)
                    }).catch(error => {
                        if (error.message !== this.i18n.get(i18nKey.noGroupAssigned)) {
                            this.eventReportingService.error(error.message, error)
                        }
                        reject(error)
                    })
        })
    }

    getDateFilteredData(clientId: string, course: Course, startDate: Date, endDate: Date): Promise<IFilteredReportCompletionData> {
        return new Promise((resolve, reject) => {
            Promise.all([
                Promise.resolve(course),
                this.groupIdService.getGroupIdsForTraining(course, clientId)
            ]).then(result => {
                const course: Course = result[0]
                const groupIds: Set<DocumentId> = result[1]

                if (groupIds.size === 0) {
                    throw new Error(this.i18n.get(i18nKey.noGroupAssigned))
                }

                const promises: Promise<any>[] = [    
                    this.lrsService.getFilteredCourseCompletions(course, clientId, groupIds, startDate, endDate),
                    this.cohortService.cohortsForClient(clientId)
                ]

                return Promise.all(promises)
            }).then(result => {
                resolve({ statements: result[0], cohorts: result[1] })
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                reject(error)
            })
        })
    }
}