/* istanbul ignore file */
export interface TrainingLaunchLocation {
    data: LaunchLocationData[]
}

export interface LaunchLocationData {
    location: LaunchLocations
    data: number
}

export enum LaunchLocations {
    IndividualTraining = "courses",
    LearningPlan = "learning-plan"
}

