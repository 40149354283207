import { Card, CardBody, CardHeader, Col, Row, Container, Alert, UncontrolledAlert, Spinner } from "reactstrap";
import { LearningPlanCard } from "../../../ui-kit/LearningPlanCard";
import { CSSModule } from "reactstrap/types/lib/utils";
import { Dictionary, DocumentId } from "../../../global/TypeAliases";
import { container } from "../../../DIContainer";
import { TYPES } from "../../../Types";
import { ILearningPlanCompositionService } from "../../../services/LearningPlanCompositionService";
import { ReactElement, useEffect, useState } from "react";
import { ContentType, IContent } from "../../../models/IContent";
import { ILearningPlanHelper } from "../../../utils/LearningPlanHelper";
import { IAuth } from "../../../auth/Auth";
import Course, { CourseType } from "../../../models/Course";
import { LaunchLocations } from "../../../models/TrainingLaunchLocation";
import { IRouter } from "../../../Router";
import { ILRSService } from "../../../services/LRSService";
import { Survey } from "../../../models/Survey";
import { Ii18n, i18nKey } from "../../../global/i18n";

interface LearningPlansPageV2Props {
    userClientIds: DocumentId[];
    learningPlanId: DocumentId;
    learningPlanName: string;
}

export const LearningPlansPageV2: React.FC<LearningPlansPageV2Props> = (props) => {
    const compositionService: ILearningPlanCompositionService = container.get<ILearningPlanCompositionService>(
        TYPES.ILearningPlanCompositionService
    );
    const learningPlanHelper: ILearningPlanHelper = container.get<ILearningPlanHelper>(TYPES.ILearningPlanHelper);
    const auth: IAuth = container.get<IAuth>(TYPES.IAuth);
    const router: IRouter = container.get<IRouter>(TYPES.IRouter);
    const lrsService: ILRSService = container.get<ILRSService>(TYPES.ILRSService);
    const i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n);

    const { userClientIds, learningPlanId, learningPlanName } = props;

    const [errorMessage, setErrorMessage] = useState<boolean | undefined>(false);
    const [showLoader, setShowLoader] = useState<boolean>(true);
    const [contents, setContents] = useState<IContent[] | null>(null);
    const [progressedContentIds, setProgressedContentIds] = useState<Dictionary>({});
    const [completedContentIds, setCompletedContentIds] = useState<Dictionary>({});

    const learningPlanCardStyles: CSSModule = { backgroundColor: "transparent", border: "none" };

    useEffect(() => {
        compositionService
            .getLearningPlanData(learningPlanId, userClientIds)
            .then((data) => {
                setContents(data.contents);
                setProgressedContentIds(data.progressedContentIds);
                setCompletedContentIds(data.completedContentIds);
                setShowLoader(false);
            })
            .catch((_) => {
                setShowLoader(false);
                setErrorMessage(true);
            });
        // eslint-disable-next-line
    }, [userClientIds, learningPlanId]);

    const getTimelineIconFrom = (contentType: ContentType): string => {
        return contentType === "course" ? "mdi mdi-weight-lifter" : "fas fa-clipboard-list";
    };

    const renderLearningPlanCard = (
        id: string,
        imgSrc: string,
        clickableState: Dictionary,
        content: IContent,
        isLeftJustified: boolean
    ): ReactElement => {
        const timelineIcon = getTimelineIconFrom(content.contentType());
        let learningPlanCard: ReactElement;
        if (isLeftJustified) {
            learningPlanCard = (
                <Row className="timeline-left">
                    <Col md={6} className="d-md-none d-block">
                        <div className="timeline-icon">
                            <i className={`${timelineIcon} text-primary h2 mb-0`}></i>
                        </div>
                    </Col>
                    <Col lg={6} md={4} data-testid="lp-card">
                        <LearningPlanCard
                            key={content.documentId}
                            content={content}
                            contentType={content.contentType()}
                            imgSrc={imgSrc}
                            isClickable={clickableState[id]}
                            progress={progressedContentIds[id]}
                            isComplete={completedContentIds[id]}
                            onContentClick={contentInLearningPlanClicked}
                            isLeftJustified={true}
                        />
                    </Col>
                    <Col md={6} className="d-md-block d-none">
                        <div className="timeline-icon">
                            <i className={`${timelineIcon} text-primary h2 mb-0`}></i>
                        </div>
                    </Col>
                </Row>
            );
        } else {
            learningPlanCard = (
                <div className="row timeline-right">
                    <Col md={6}>
                        <div className="border-danger timeline-icon">
                            <i className={`${timelineIcon} text-primary h2 mb-0`}></i>
                        </div>
                    </Col>
                    <Col md={6} data-testid="lp-card">
                        <LearningPlanCard
                            key={content.documentId}
                            content={content}
                            contentType={content.contentType()}
                            imgSrc={imgSrc}
                            isClickable={clickableState[id]}
                            progress={progressedContentIds[id]}
                            isComplete={completedContentIds[id]}
                            onContentClick={contentInLearningPlanClicked}
                            isLeftJustified={false}
                        />
                    </Col>
                </div>
            );
        }

        return learningPlanCard;
    };

    const renderContents = (contents: IContent[]) => {
        const clickableState = learningPlanHelper.getClickableState(contents, completedContentIds);

        if (contents.length === 0) {
            return (
                <Alert color="warning" className="mb-0 text-center" data-testid="no-lp-contents">
                    <i className="bx bx-book-open d-block display-4 mt-2 mb-3 text-warning"></i>
                    <h5 className="text-warning">{i18n.get(i18nKey.noLearningPlanContents)}</h5>
                    <p>{i18n.get(i18nKey.learningPlanEditorEmpty)}</p>
                </Alert>
            );
        }
        return (
            <div className="timeline">
                <div className="timeline-container">
                    <div className="timeline-end" data-testid="learning-plan-start">
                        <p>Start</p>
                    </div>
                    <div className="timeline-continue" data-testid="learning-plan-timeline-body">
                        {contents.map((content, idx) => {
                            if (idx % 2 === 0) {
                                return renderLearningPlanCard(
                                    content.documentId,
                                    content.imageUrl,
                                    clickableState,
                                    content,
                                    true
                                );
                            } else {
                                return renderLearningPlanCard(
                                    content.documentId,
                                    content.imageUrl,
                                    clickableState,
                                    content,
                                    false
                                );
                            }
                        })}
                    </div>
                    <div className="timeline-start" data-testid="learning-plan-end">
                        <p>End</p>
                    </div>
                    <div className="timeline timeline-launch" data-testid="learning-plan-end-message">
                        <div className="timeline-box" style={{ backgroundColor: "#ffffff" }}>
                            <div className="timeline-text">
                                <h3 className="font-size-18">{i18n.get(i18nKey.congratulations)}</h3>
                                <p className="text-muted mb-0">{i18n.get(i18nKey.successfullyCompletedLearningPlan)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const postTrainingLaunchLocationFor = (content: IContent) => {
        const user = auth.user;
        if (!user) {
            return;
        }

        const sharedClientIds = userClientIds;
        sharedClientIds.forEach((client) => {
            lrsService.postTrainingLaunchLocation(user.uid, client, content.lrsId, LaunchLocations.LearningPlan);
        });
    };

    const contentInLearningPlanClicked = (e: React.MouseEvent<HTMLDivElement>, content: IContent): void => {
        postTrainingLaunchLocationFor(content);
        switch (content.contentType()) {
            case ContentType.course:
                const course = content as Course;
                goToCourse(course);
                break;
            case ContentType.survey:
                const survey = content as Survey;
                goToSurvey(survey);
                break;
            default:
                break;
        }
    };

    const goToCourse = (course: Course) => {
        const data = { course: course };

        switch (course.type) {
            case CourseType.training:
                router.goToCourse(data);
                break;
            case CourseType.googleForm:
                router.goToGoogleFormTraining(data);
                break;
        }
    };

    const goToSurvey = (survey: Survey) => {
        const data = {
            documentId: survey.documentId,
            surveyCompleted: completedContentIds[survey.documentId],
            learnerViewMode: false,
        };
        router.goToViewSurvey(data);
    };

    return (
        <div className="page-content">
            <Container fluid>
                {errorMessage && (
                    <UncontrolledAlert className="mx-4" color="danger" data-testid="error-banner">
                        <i className="mdi mdi-block-helper me-2"></i>
                        {i18n.get(i18nKey.failedToLoadLearningPlans)}
                    </UncontrolledAlert>
                )}
                {!errorMessage && (
                    <Row>
                        <Col xl={12} lg={10} md={10} sm={8}>
                            <Card style={learningPlanCardStyles}>
                                <CardHeader style={learningPlanCardStyles}>
                                    <h1 data-testid="learning-plan-title">{learningPlanName}</h1>
                                    <h4 className="card-title" data-testid="learning-plan-subtitle">
                                        {i18n.get(i18nKey.yourLearningJourney)}
                                    </h4>
                                </CardHeader>
                                <CardBody lg={12} md={10} sm={8}>
                                    <Row className="justify-content-center">
                                        <Col xl={12} lg={10} sm={8}>
                                            {showLoader && (
                                                <div className="mb-0 text-center">
                                                    <h3>{i18n.get(i18nKey.loadingYourLearningPlan)}</h3>
                                                    <Spinner
                                                        type="border"
                                                        className="ms-2"
                                                        color="secondary"
                                                        data-testid="loader"
                                                    />
                                                </div>
                                            )}
                                            {contents && <> {renderContents(contents)}</>}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
            </Container>
        </div>
    );
};
