import React, { Component } from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import PageTitle from "../../ui-kit/PageTitle";
import CoursesAdmin from "./courses/CoursesAdmin";

import "./AdminPages.css"
import { LearningPlans } from "./learning_plan/LearningPlans";
import { container } from "../../DIContainer";
import { TYPES } from "../../Types";
import { SurveysContentPage } from "./surveys/SurveysContentPage";
import { log } from "../../global/Logger";
import { IRouter } from "../../Router";
import queryString from "query-string"
import { DocumentId } from "../../global/TypeAliases";
import { IAccessController } from "../../services/AccessController";
import { ClientContextProp } from "./WithClientContext";

interface ContentsPageProps extends ClientContextProp {}
interface ContentsPageState {
    surveysOn: boolean
    selectedTab: ContentsTabKey
    selectedClientId: DocumentId | null
    showClientContextDialog: boolean
}

export enum ContentsTabKey {
    course = "course",
    learningPlan = "lp",
    survey = "survey",
}

export class ContentsPage extends Component<ContentsPageProps, ContentsPageState> {
    private router: IRouter = container.get<IRouter>(TYPES.IRouter)
    private acl: IAccessController = container.get<IAccessController>(TYPES.IAccessController)

    state: ContentsPageState = {
        surveysOn: this.acl.isGlobalAdmin(),
        selectedTab: ContentsTabKey.course,
        selectedClientId: null,
        showClientContextDialog: false
    }

    componentDidMount() {
        this.preSelectTabIfNeeded()
    }

	render() {
		return (
			<>
                <PageTitle title="Content" />
                <div className="container">
                    <Pivot 
                        id="admin-panel-container" 
                        aria-label="admin panels" 
                        onLinkClick={this.onLinkClicked}
                        selectedKey={this.state.selectedTab}
                    >
                        <PivotItem id="course-panel" itemKey={ContentsTabKey.course} headerText="Courses">
                            <CoursesAdmin title="Courses Admin Panel" index={0}  clientId={this.props.clientId}/>
                        </PivotItem>

                        <PivotItem id="lp-panel" itemKey={ContentsTabKey.learningPlan} headerText="Learning Plans">
                            <LearningPlans 
                                title="Learning Plans Panel" 
                                index={1} 
                                clientId={this.props.clientId} 
                            />
                        </PivotItem>

                        { this.state.surveysOn &&
                            <PivotItem id="survey-panel" itemKey={ContentsTabKey.survey} headerText="Surveys">
                                <SurveysContentPage title="Surveys Panel" index={2} clientId={this.props.clientId}/>
                            </PivotItem>
                        }   
                    </Pivot>
                </div>
			</>
		)
    }

    onLinkClicked = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
        log("Clicked: " + item?.props.itemKey)

        const tab = item?.props.itemKey as ContentsTabKey

        if (tab === undefined) {
            return
        }

        switch (tab) {
            case ContentsTabKey.course:
                this.router.goToContentsCourseTab()
                break
            case ContentsTabKey.learningPlan:
                this.router.goToContentsLearningPlanTab(this.props.clientId)
                break
            case ContentsTabKey.survey:
                this.router.goToContentsSurveyTab()
                break
            default:
                break
        }

        this.setState({ selectedTab: tab })
    }

    preSelectTabIfNeeded = () => {
        const href = this.router.location()
        const path = href.substring(href.indexOf("?")+1)
        const params = queryString.parse(path)
        const tab = params['t'] as ContentsTabKey

        if (tab === undefined) {
            return
        }

        log("Attempting to resume " + tab)

        switch (tab) {
            case ContentsTabKey.course:
            case ContentsTabKey.learningPlan:
            case ContentsTabKey.survey:
                this.setState({ selectedTab: tab, selectedClientId: this.props.clientId })
                break
            default:
                break
        }
    }
}
