import React from "react";
import { 
    Image,
    IImageStyles
} from '@fluentui/react';
import { PrimaryButton } from "./PrimaryButton";

export class PrimaryButtonWithImage extends PrimaryButton {
    imageWidthHeight = "25px"

    render() {
        const imageStyle: Partial<IImageStyles> = {
            root: {
                position: "absolute",
                paddingRight: "90%"
            }
        }

        return (
            <PrimaryButton {...this.props}>
                { this.props.src &&
                    <Image 
                        id="primaryButtonImage" 
                        alt="logo" 
                        src={this.props.src} 
                        styles={imageStyle} 
                        width={this.imageWidthHeight} 
                        height={this.imageWidthHeight} 
                    />
                }
            </PrimaryButton>
        )
    }
}
