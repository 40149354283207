import React, { Component } from "react";
import {
	IconButton,
	Modal,
	Spinner,
	Stack,
} from "@fluentui/react";
import { ProfileUIStyles, ProfileUITokens, ProfileUIIcons } from "./ProfileUI";
import { FileUploaderPath, IFileUploader } from "../../services/FileUploader";
import { container } from "../../DIContainer";
import { TYPES } from "../../Types";
import ProfileImage from "../../ui-kit/ProfileImage";
import { PrimaryButton } from "../../ui-kit/PrimaryButton"
import { IUserRepository } from "../../services/UserRepository";
import "./ProfileUploadPic.css"
import { IURLCreator } from "../../ui-kit/URLCreator";
import { MessageBar, MessageBarType } from "@fluentui/react";

interface ProfileUploadPicProps {
    onProfileUpdate: (user: firebase.User) => void
	user: firebase.User;
}

export interface ProfileUploadPicState {
	isOpen: boolean;
	showSpinner: boolean;
	updateButtonDisabled: boolean;
	imageFile: File | undefined;
	errorMessage: string | null
}

export class ProfileUploadPic extends Component<ProfileUploadPicProps, ProfileUploadPicState> {
	private fileUploader: IFileUploader = container.get<IFileUploader>(TYPES.IFileUploader)
    private userRepository: IUserRepository = container.get<IUserRepository>(TYPES.IUserRepository)
    private urlCreator: IURLCreator = container.get<IURLCreator>(TYPES.IURLCreator)

	state = {
		isOpen: false,
		showSpinner: false,
		updateButtonDisabled: true,
		imageFile: undefined,
		errorMessage: null
	};

	render() {
		return (
			<Modal
				isBlocking={false}
				isOpen={this.state.isOpen}
                onDismiss={this.hide}
                styles={{main: { padding: '10px 50px '}}}
			>
				<div className="profile-edit-modal">
					<div>
						<IconButton
							styles={ProfileUIStyles.editProfileCloseButton}
							ariaLabel="Close edit profile modal"
							iconProps={ProfileUIIcons.cancel}
							onClick={this.hide}
						/>
					</div>
					<Stack
						styles={ProfileUIStyles.editProfileModalStackStyles}
						tokens={ProfileUITokens.verticalGapStackTokens} horizontalAlign="center">
						<label htmlFor="file-upload" className="inputFile">
							Upload a profile image
						</label>
						<input
							id="file-upload"
							type="file"
							accept="image/*"
							onChange={this.fileSelectionChanged}
							width="300px"
						/>

						{this.state.imageFile !== undefined && (
							<div id="previewImage">
								<ProfileImage
									urlString={this.urlCreator.createFor(this.state.imageFile!)}
								/>
							</div>
						)}

                        { this.state.imageFile && !this.state.showSpinner &&                   
                            <PrimaryButton
								id="profile-upload-primary-button"
                                disabled={this.state.updateButtonDisabled}
                                text="Update"
                                onClick={this.primaryButtonClicked}
                                width="150px"
                                hidden={!this.state.showSpinner}
                            />
                        }

                        { this.state.showSpinner && 
                            <Spinner
                                label="Updating..."
                                ariaLive="assertive"
                                labelPosition="right"
                            />
                        }

						{ this.state.errorMessage &&
							<MessageBar
								id="profile-upload-error-banner"
								messageBarType={MessageBarType.error}
								dismissButtonAriaLabel="Close"
								onDismiss={this.dismissBanner}
							>
								{this.state.errorMessage}
							</MessageBar>
						}
					</Stack>
				</div>
			</Modal>
		);
	}

	hide = () => {
		this.setState({
			isOpen: false,
		})
	}

	show = () => {
		this.setState({ 
            isOpen: true,
            showSpinner: false,
            updateButtonDisabled: true,
            imageFile: undefined,
        })
    }

	primaryButtonClicked = () => {
		this.setState({
			showSpinner: true,
			updateButtonDisabled: true,
			errorMessage: null
		})

        this.fileUploader
            .upload(this.state.imageFile!, FileUploaderPath.profileImage)
            .then((imageUrl) => {
                return this.userRepository.updateProfilePicture(this.props.user, imageUrl)
            })
            .then((user) => {
                this.hide()
                this.props.onProfileUpdate(user)
            })
            .catch(_ => {
                this.setState({
                    showSpinner: false,
					updateButtonDisabled: false,
					errorMessage: "Failed to update profile picture. Please try again."
                })
            })
	}

	fileSelectionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault()

		if (!event.target.files || event.target.files.length === 0) {
			return
		}

		const file: File = event.target.files[0]
		this.setState({ imageFile: file, updateButtonDisabled: false })
	}

	dismissBanner = () => {
		this.setState({ errorMessage: null })
	}
}
