import { IImageStyles, IStackStyles, mergeStyles } from '@fluentui/react'
export class MobileHeaderStyles {
    static mobileLinkFontSize = '1em'
    static mobileLinkLineHeight = '1.75em'
    static mobileLinkPaddingRight = '1em'

    static outerStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    justifyContent: 'space-between',
                },
                button: {
                    marginBottom: '1vw',
                }
            }
        }
    }

    static mobileMenuStackStyles: IStackStyles = {
        root: {
            width: '100%',
            position: 'fixed',
            zIndex: '2',
            height: 0
        },
    }

    static mobileLinkStackStyles: IStackStyles = {
        root: {
            selectors: {
                '& .mobile-header-nav-link': {
                    textDecoration: 'none',
                    color: '#ffffff',
                    fontWeight: '400',
                    textAlign: 'right',
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    lineHeight: MobileHeaderStyles.mobileLinkLineHeight,
                    fontSize: MobileHeaderStyles.mobileLinkFontSize,
                    paddingRight: MobileHeaderStyles.mobileLinkPaddingRight
                },
                '& .mobile-header-top-nav-link': {
                    textDecoration: 'none',
                    color: '#ffffff',
                    fontWeight: '400',
                    textAlign: 'right',
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    lineHeight: MobileHeaderStyles.mobileLinkLineHeight,
                    fontSize: MobileHeaderStyles.mobileLinkFontSize,
                    paddingRight: MobileHeaderStyles.mobileLinkPaddingRight,
                    marginTop: '18vw'
                },
            },
            display: 'none',
        }
    }

    static activeMobileLinkClass = mergeStyles({
        color: '#ffffff !important',
        fontSize: MobileHeaderStyles.mobileLinkFontSize
    })

    static scrollClass = mergeStyles({
        opacity: '1.00',
        backgroundColor: 'transparent',
        marginTop: '-0.8vw',
    })

    static openMobileNavigationLinksClass = mergeStyles({
        display: 'inline',
        marginTop: '6vw',
    })

    static openMobileMenuClass = mergeStyles({
        zIndex: 0
    })

    static mobileIconStyles: Partial<IImageStyles> = {
        root: {
            display: 'block',
            position: 'absolute',
            top: '8.5vw',
            right: '4vw',
        },
    }

    static clientContextSelector = mergeStyles({
        float: 'right',
        paddingRight: '3vw',
        paddingTop: '2vw',
    })
}
