import { injectable } from "inversify";
import { container } from "../DIContainer";
import { Dictionary, DocumentId } from "../global/TypeAliases";
import Cohort from "../models/Cohort";
import { LRSCompletionStatement } from "../models/LRSStatement";
import User from "../models/User";
import { TYPES } from "../Types";
import { ICohortService } from "./CohortService";
import { ILRSService } from "./LRSService";
import { IUserService } from "./UserService";
import { Survey } from "../models/Survey";
import { ISurveyService } from "./SurveyService";
import { IGroupIDService } from "./GroupIdService";
import { i18nKey, Ii18n } from "../global/i18n";
import { ISurveyCompositionService } from "./SurveyCompositionService";

export interface ISurveyReportPageCompositionService {
    getData(clientId: DocumentId, surveyId: DocumentId): Promise<ISurveyReportPageData>
}

export interface ISurveyReportPageData {
    survey: Survey
    users: User[]
    cohorts: Cohort[]
    statements: LRSCompletionStatement[]
    attemptStatements: LRSCompletionStatement[]
    surveyStateData: Dictionary[]
}

@injectable()
export class SurveyReportPageCompositionService implements ISurveyReportPageCompositionService {
    private userService: IUserService = container.get<IUserService>(TYPES.IUserService)
    private lrsService: ILRSService = container.get<ILRSService>(TYPES.ILRSService)
    private cohortService: ICohortService = container.get<ICohortService>(TYPES.ICohortService)
    private surveyService: ISurveyService = container.get<ISurveyService>(TYPES.ISurveyService)
    private surveyStateService: ISurveyCompositionService = container.get<ISurveyCompositionService>(TYPES.ISurveyCompositionService)
    private groupIdService: IGroupIDService = container.get<IGroupIDService>(TYPES.IGroupIDService)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    getData(clientId: DocumentId, surveyId: DocumentId): Promise<ISurveyReportPageData> {
        return new Promise((resolve, reject) => {
            this.surveyService
                .surveyForDocumentId(surveyId)
                .then(survey => {
                    return Promise.all([
                        Promise.resolve(survey),
                        this.groupIdService.getGroupIdsForSurveyId(survey.documentId, clientId),
                    ])
                })
                .then(result => {
                    const survey: Survey = result[0]
                    const groupIds: Set<DocumentId> = result[1]

                    if (groupIds.size === 0) {
                        throw new Error(this.i18n.get(i18nKey.noGroupAssigned))
                    }
                    
                    const promises: Promise<any>[] = [
                        Promise.resolve(survey),
                        Promise.resolve(groupIds),
                        this.userService.usersForClient(clientId),
                    ]

                    return Promise.all(promises)
                })
                .then(result => {
                    const survey: Survey = result[0]
                    const groupIds: Set<DocumentId> = result[1]
                    const users: User[] = result[2]

                    const anonymousReporting: boolean = survey.anonymous ? survey.anonymous : false
                    
                    const promises: Promise<any>[] = [
                        Promise.resolve(survey),
                        Promise.resolve(users),
                        this.lrsService.getSurveyCompletions(survey.lrsId, clientId, groupIds, anonymousReporting),
                        this.cohortService.cohortsForClient(clientId),
                        this.lrsService.getSurveyAttempts(survey.lrsId, clientId, groupIds, anonymousReporting),
                        this.surveyStateService.getSurveyResponseStateData(users, clientId, survey.lrsId)
                    ]

                    return Promise.all(promises)
                })
                .then(result => {
                    const data: ISurveyReportPageData = { 
                        survey: result[0],
                        users: result[1], 
                        statements: result[2], 
                        cohorts: result[3], 
                        attemptStatements: result[4],
                        surveyStateData: result[5]
                    }

                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}