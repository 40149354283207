import { injectable } from "inversify";
import { container } from "../DIContainer";
import { DocumentId } from "../global/TypeAliases";
import Course from "../models/Course";
import { TYPES } from "../Types";
import { ICourseService } from "./CourseService";
import { Survey } from "../models/Survey";
import { ISurveyService } from "./SurveyService";

export interface IReportsHomePageCompositionService {
    getData(clientId: DocumentId): Promise<IReportsHomePageData>
}

export interface IReportsHomePageData {
    courses: Course[]
    surveys: Survey[]
}

@injectable()
export class ReportsHomePageCompositionService implements IReportsHomePageCompositionService {
    private courseService: ICourseService = container.get<ICourseService>(TYPES.ICourseService)
    private surveyService: ISurveyService = container.get<ISurveyService>(TYPES.ISurveyService)

    getData(clientId: string): Promise<IReportsHomePageData> {
        let promiseArray: Promise<any>[] = []

        promiseArray.push(this.courseService.coursesForClientId(clientId))
        promiseArray.push(this.surveyService.surveysForClientId(clientId))

        return new Promise((resolve, reject) => {
            Promise.all(promiseArray)
                    .then(result => {
                        resolve({ 
                            courses: result[0],
                            surveys: result[1]
                        })
                    })
                    .catch(error => {
                        reject(error)
                    })
        })
    }
}