import React, { Component } from 'react'
import { Text, Stack, ActionButton } from '@fluentui/react';
import { ProfileUITokens } from "./ProfileUI"
import defaultProfileImage from "../../images/default-profile.png";
import ProfileImage from '../../ui-kit/ProfileImage';
import { FontSize } from '../../ui-kit/FontSize';
import { ProfileUploadPic } from './ProfileUploadPic';

interface ProfileUserProps {
    user: firebase.User;
}

export interface ProfileUserState {
    user: firebase.User
}

export class ProfileUser extends Component <ProfileUserProps, ProfileUserState> {
    private uploadPicRef = React.createRef<ProfileUploadPic>();

    state = {
        user: this.props.user
    }

    render() {
		let profileImageURLString = defaultProfileImage;
		if (this.props.user.photoURL !== null) {
			profileImageURLString = this.props.user.photoURL;
		}

        return (
            <div id="profile-user-container">
                <Stack id="profile-image-container" 
                        horizontalAlign="center" 
                        tokens={ProfileUITokens.verticalGapStackTokens}>
                    <ProfileImage urlString={profileImageURLString} />
                    <ActionButton text="Edit Picture" onClick={this.updatePictureClicked} />
                    <Text variant={FontSize.xLarge}>{this.props.user.displayName}</Text>
                </Stack>

                <ProfileUploadPic
                    user={this.props.user}
                    ref={this.uploadPicRef}
                    onProfileUpdate={this.refreshProfile}
                />
            </div>
        )
    }

    refreshProfile = (user: firebase.User) => {
        this.setState({ user: user })
    }

    updatePictureClicked = () => {
        this.uploadPicRef.current?.show()
    }
}
