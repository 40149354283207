import { injectable } from "inversify"
import { ChartData } from "../models/ChartData"
import { LRSCompletionStatement } from "../models/LRSStatement"

export interface IChartFactory {
    /**
     * Determine the amount of learners that have completed a course compared to the total who have taken it.
     *
     * @param {LRSCompletionStatement[]} statements collection of learner completion statements
     * @return {ChartData[]} Metric information consisting of number of incomplete and completed entries
     */
    ratioCompleted(statements: LRSCompletionStatement[]): ChartData[]

    /**
     * Determine the amount of learners that have attempted a course compared to the total who have taken it.
     *
     * @param {LRSCompletionStatement[]} statements collection of learner attempted statements
     * @return {ChartData[]} Metric information consisting of learners that have attempted and not attempted
     */
    ratioAttempted(statements: LRSCompletionStatement[]): ChartData[]

    /**
     * Determine the average time it takes for learners to complete a course.
     * Only computes average time for completions, not attempts.
     *
     * @param {LRSCompletionStatement[]} statements collection of learner completion statements
     * @return {ChartData[]} Metric information consisting of the average duration calculated
     */
    averageCourseDuration(statements: LRSCompletionStatement[]): ChartData[]

    /**
     * Determines the median time it takes for learners to complete a course.
     * Only computes the median time for completions, not attempts
     *
     * @param {LRSCompletionStatement[]} statements collection of learner completion statements
     * @return {ChartData[]} Metric information consisting of the median duration calcuated
     */
    medianCourseDuration(statements: LRSCompletionStatement[]): ChartData[]
}

@injectable()
export class ChartFactory implements IChartFactory {
    ratioCompleted(statements: LRSCompletionStatement[]) {
        const total = statements.length
        const completed = getNumberCompleted(statements)
        const incomplete = total - completed

        const completeEntry: ChartData = new ChartData("Completed", completed)
        const incompleteEntry: ChartData = new ChartData("Incomplete", incomplete)

        return [completeEntry, incompleteEntry]
    }

    ratioAttempted(statements: LRSCompletionStatement[]) {
        let attempted = 0
        statements.forEach((statement) => {
            if (statement.data !== undefined) {
                attempted += 1
            }
        })

        const totalAttempted: number = statements.length
        const notAttempted: number = totalAttempted - attempted

        const attemptedEntry: ChartData = new ChartData("Attempted", attempted)
        const notAttemptedEntry: ChartData = new ChartData("Not Attempted", notAttempted)

        return [attemptedEntry, notAttemptedEntry]
    }

    //note: returned duration is measured in seconds
    averageCourseDuration(statements: LRSCompletionStatement[]) {
        let total = 0
        let duration = 0

        statements.forEach((statement) => {
            if (statement.data?.completed) {
                if (statement.data.duration) {
                    duration += statement.data.duration
                    total += 1
                }
            }
        })

        total === 0 ? (duration = 0) : (duration = duration / total)
        const durationEntry: ChartData = new ChartData("Average Duration", duration)

        return [durationEntry]
    }

    medianCourseDuration(statements: LRSCompletionStatement[]) {
        let allCompletionTimes: number[] = []
        statements.forEach((statement) => {
            if (statement.data?.completed) {
                if (statement.data.duration) {
                    allCompletionTimes.push(statement.data.duration)
                }
            }
        })

        allCompletionTimes.sort((a, b) => a - b)

        /**
         * If there are no entries, we display 0 min.
         * Else we give either the middle value for odd length arrays or the (middle - 1) value for even length arrays.
         */
        const length = allCompletionTimes.length

        let median: number = 0
        if (length) {
            let index = Math.floor(length / 2)
            if (length % 2 === 0) {
                index -= 1
            }
            median = allCompletionTimes[index]
        }

        const medianEntry: ChartData = new ChartData("Median Duration", median)

        return [medianEntry]
    }
}

/**
 * Helper function to retrieve total number of completed entries for the given data.
 *
 * @param {LRSCompletionStatement[]} statements collection of learner completion statements
 * @returns {number} total number of completed entries
 */
function getNumberCompleted(statements: LRSCompletionStatement[]): number {
    let completed = 0

    statements.forEach((statement) => {
        if (statement.data?.completed) {
            completed += 1
        }
    })

    return completed
}
