import { Component, ReactElement } from "react";
import {
	Modal,
	MessageBarType,
	MessageBar,
} from "@fluentui/react";

import { TabPanelProps } from "../TabPanelProps";
import DTSTable from "../../../ui-kit/DTSTable";
import IDataTableFactory from "../../../factories/DataTableFactory";
import DTSTableData from "../../../models/DTSTableData";
import { Loader } from "../../../ui-kit/Loader";
import Course from "../../../models/Course";
import NewCourseModal from "./NewCourseModal";
import EditCourse from "./EditCourse";
import { SecondaryButton } from '../../../ui-kit/SecondaryButton'
import { container } from "../../../DIContainer";
import { TYPES } from "../../../Types";
import { IAccessController } from "../../../services/AccessController";
import { log } from "../../../global/Logger";
import { i18nKey, Ii18n } from "../../../global/i18n";
import { ICoursesAdminCompositionService } from "../../../services/CoursesAdminCompositionService";
import { DocumentId } from "../../../global/TypeAliases";
import CancelablePromise from "cancelable-promise";
import { IPromiseHelper } from "../../../services/PromiseHelper";

enum ModalType {
	NEW = 0,
	EDIT,
}

interface CoursesAdminProps extends TabPanelProps {
	clientId: DocumentId
}

interface CoursesAdminState {
	courses: Course[];
	tableData: DTSTableData | undefined;
	isModalOpen: boolean;
	successMessage: string | undefined;
	errorMessage: string | undefined;
	selectedDocumentId: string | undefined;
	modalType: ModalType;
}

export default class CoursesAdmin extends Component<CoursesAdminProps, CoursesAdminState> {
	private compositionService: ICoursesAdminCompositionService = container.get<ICoursesAdminCompositionService>(TYPES.ICoursesAdminCompositionService)
	private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
	private acl: IAccessController = container.get<IAccessController>(TYPES.IAccessController)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
	private promiseHelper: IPromiseHelper = container.get<IPromiseHelper>(TYPES.IPromiseHelper)
	private dataPromise: CancelablePromise | undefined

	state: CoursesAdminState = {
		courses: [],
		tableData: undefined,
		isModalOpen: false,
		successMessage: undefined,
		errorMessage: undefined,
		modalType: ModalType.NEW,
		selectedDocumentId: undefined
	}

	componentDidMount() {
		this.loadData()
	}

	componentWillUnmount(): void {
		this.promiseHelper.cancel(this.dataPromise)
	}
	
	render() {
		if (this.state.tableData === undefined) {
			return <Loader />;
		}

		let data = this.state.tableData;

		let modalElement: ReactElement = <NewCourseModal clientId={this.props.clientId} courseAdded={this.reloadData} />

		if (this.state.isModalOpen) {
			switch (this.state.modalType) {
				case ModalType.NEW:
					modalElement = <NewCourseModal clientId={this.props.clientId} courseAdded={this.reloadData} />;
					break;
				case ModalType.EDIT:
					const course = this.state.courses.find(
						(course) => course.documentId === this.state.selectedDocumentId
					);
					if (course !== undefined) {
						modalElement = (
							<EditCourse
								documentId={this.state.selectedDocumentId}
								course={course}
								clientId={this.props.clientId}
								courseUpdated={this.loadData}
								courseDeletedHandler={this.courseDeleted}
							/>
						);
					}

					break;
			}
		}

		return (
			<div
				className="admin-tab-panel"
				id={`admin-tab-panel-${this.props.index}`}
				aria-labelledby={`admin-tab-${this.props.index}`}
			>
				{this.state.successMessage !== undefined && (
					<MessageBar
						id="courses-admin-success-banner"
						messageBarType={MessageBarType.success}
						dismissButtonAriaLabel="Close"
						onDismiss={this.dismissSuccessBanner}
					>
						{this.state.successMessage}
					</MessageBar>
				)}

				{this.state.errorMessage !== undefined && (
					<MessageBar
						id="courses-admin-error-banner"
						messageBarType={MessageBarType.error}
						dismissButtonAriaLabel="Close"
						onDismiss={this.dismissErrorBanner}
					>
						{this.state.errorMessage}
					</MessageBar>
				)}

				{ this.acl.canAddCourse() && 
					<div className="admin-command-bar">
						<SecondaryButton
							iconProps={{ iconName: "Album" }}
							text="Add New Course"
							onClick={this.addCourseButtonClicked}
						/>
					</div>
				}

				<DTSTable 
					data={data} 
					aria-label="course table" 
					onRowClicked={this.onRowClicked} 
					pointerOnHover={true}
					highlightOnHover={true}
					pagination={true}
					filter={true}
					filterPlaceholder={this.i18n.get(i18nKey.searchForTraining)}
				/>
				
				<Modal
					titleAriaId="add new course modal"
					isOpen={this.state.isModalOpen}
					onDismiss={this.modalDismissed}
					isBlocking={false}
				>
					{modalElement}
				</Modal>
			</div>
		);
	}

	private loadData = () => {
		log(`Loading courses for ${this.props.clientId}`);

		this.dataPromise = this.promiseHelper.makeCancelable(this.compositionService.loadCoursesForClientId(this.props.clientId))
		this.dataPromise
			.then((result) => {
				const tableData = this.dataTableFactory.createCoursesTableData(result.courses, result.clients)
				this.setState({ courses: result.courses, tableData: tableData });
			})
			.catch(_ => {
				const tableData = this.dataTableFactory.createCoursesTableData([], [])
				this.setState({
					errorMessage: "Something went wrong. Refresh the page or try again later.", 
					tableData: tableData
				})
			})	
	}

	private addCourseButtonClicked = () => {
		this.setState({ isModalOpen: true });
	}

	private onRowClicked = (row: {[key: string]: string}) => {
		this.setState({ selectedDocumentId: row.id, isModalOpen: true, modalType: ModalType.EDIT })
	}

	private reloadData = (successMessage: string) => {
		this.modalDismissed();
		this.loadData();
		this.setState({ successMessage: successMessage })
	}

	private modalDismissed = () => {
		this.setState({ isModalOpen: false, selectedDocumentId: undefined });
	}

	private dismissSuccessBanner = () => {
		this.setState({ successMessage: undefined });
	}

	private dismissErrorBanner = () => {
		this.setState({ errorMessage: undefined });
	}

	courseDeleted = (message: string) => {
		this.reloadData(message)
	}
}
