import { injectable } from "inversify";
import { container } from "../DIContainer";
import Course from "../models/Course";
import { TYPES } from "../Types";
import { IEventReportingService } from '../services/EventReportingService'
import Client from "../models/Client";
import { ICourseService } from "./CourseService";
import { IClientService } from "./ClientService";

export interface ICoursesAdminCompositionService {
    loadCourses(): Promise<ICoursesAdminData>
    loadCoursesForClientId(clientId: string): Promise<ICoursesAdminData>
}

export interface ICoursesAdminData {
    clients: Client[]
    courses: Course[]
}

@injectable()
export class CoursesAdminCompositionService implements ICoursesAdminCompositionService {
    private courseService: ICourseService = container.get<ICourseService>(TYPES.ICourseService)
    private clientService: IClientService = container.get<IClientService>(TYPES.IClientService)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)

    loadCourses(): Promise<ICoursesAdminData> {
        let promiseArray: Promise<any>[] = []

        promiseArray.push(this.clientService.clients())
        promiseArray.push(this.courseService.courses())

        return new Promise((resolve, reject) => {
            Promise.all(promiseArray)
                    .then(result => {
                        resolve({ clients: result[0], courses: result[1] })
                    })
                    .catch(error => {
                        this.eventReportingService.error(error.message, error)
                        reject(error)
                    })
        })
    }

    loadCoursesForClientId(clientId: string): Promise<ICoursesAdminData> {
        let promiseArray: Promise<any>[] = []

        promiseArray.push(this.clientService.clientForId(clientId))
        promiseArray.push(this.courseService.coursesForClientId(clientId))

        return new Promise((resolve, reject) => {
            Promise.all(promiseArray)
                    .then(result => {
                        resolve({ 
                            clients: [result[0]], 
                            courses: result[1] 
                        })
                    })
                    .catch(error => {
                        reject(error)
                    })
        })
    }
}