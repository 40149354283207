import DTSTableData from "../models/DTSTableData"
import User from "../models/User"
import Cohort from "../models/Cohort"
import Course from "../models/Course"
import Client from "../models/Client"
import { injectable } from "inversify"
import { LRSCompletionStatement } from "../models/LRSStatement"
import { Dictionary, DocumentId } from "../global/TypeAliases"
import { LearningPlan } from "../models/LearningPlan"
import { Survey } from "../models/Survey"
import { TableColumn } from "react-data-table-component"
import { IDataTableFactoryUtils } from "./DataTableFactoryUtils"
import { container } from "../DIContainer"
import { TYPES } from "../Types"
import { i18nKey, Ii18n } from "../global/i18n"

export interface IDataTableFactory {
	// Admin pages
	createUsersTableData(users: User[]): DTSTableData
	createLearnersTableData(users: User[], cohorts: Cohort[]): DTSTableData
	createCohortsTableData(cohorts: Cohort[]): DTSTableData
	createCoursesForLearningPlanEditorTableData(courses: Course[]): DTSTableData
	createSurveysForLearningPlanEditorTableData(surveys: Survey[]): DTSTableData
	createCoursesTableData(courses: Course[], clients: Client[]): DTSTableData
	createClientsTableData(clients: Client[]): DTSTableData
	createUserUploadPreviewTableData(data: Dictionary[]): DTSTableData
	createLearningPlansTableData(learningPlans: LearningPlan[]): DTSTableData
	createSurveysTableData(surveys: Survey[]): DTSTableData
	
	// Search
	createCohortSearchTableData(users: User[]): DTSTableData

	// Report
	createCourseCompletionTableData(statements: LRSCompletionStatement[], cohorts: Cohort[], attemptStatements: LRSCompletionStatement[]): DTSTableData
	createFilteredCourseCompletionTableData(statements: LRSCompletionStatement[], cohorts: Cohort[]): DTSTableData
	createSurveyCompletionTableData(statements: LRSCompletionStatement[], cohorts: Cohort[], attemptStatements: LRSCompletionStatement[]): DTSTableData
}

@injectable()
export default class DataTableFactory implements IDataTableFactory {
    private dataTableFactoryUtils: IDataTableFactoryUtils = container.get<IDataTableFactoryUtils>(TYPES.IDataTableFactoryUtils)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	createUsersTableData(users: User[]): DTSTableData {
		let columns: TableColumn<Dictionary>[] = [
			{
				name: this.i18n.get(i18nKey.name),
				selector: (row: any) => row.name,
				sortable: true,
			},
			{
				name: this.i18n.get(i18nKey.emailAddress),
				selector: (row: any) => row.emailAddress,
				sortable: true,
			},
			{
				name: this.i18n.get(i18nKey.role),
				selector: (row: any) => row.role,
				sortable: true,
			},
			{
				name: this.i18n.get(i18nKey.status),
				selector: (row: any) => row.status,
				sortable: true,
			},
			{
				name: this.i18n.get(i18nKey.createdOn),
				selector: (row: any) => row.createdAt,
				sortable: true
			}
		]

		let data = users.map((user) => {			
			return {
				id: user.documentId,
				uid: user.uid,
				name: user.fullName,
				emailAddress: user.email,
				role: user.roleName,
				status: user._status,
				createdAt: user.createdAt
			}
		})

		return new DTSTableData(columns, data)
	}

	createLearnersTableData(users: User[], cohorts: Cohort[]): DTSTableData {
		let columns: TableColumn<Dictionary>[] = [
			{
				name: this.i18n.get(i18nKey.name),
				selector: (row: any) => row.name,
				sortable: true,
				wrap: true
			},
			{
				name: this.i18n.get(i18nKey.emailAddress),
				selector: (row: any) => row.emailAddress,
				sortable: true,
				wrap: true
			},
			{
				name: this.i18n.get(i18nKey.groupsCapitalized),
				selector: (row: any) => row.group,
				sortable: true,
				wrap: true,
				width: "20%"
			},
			{
				name: this.i18n.get(i18nKey.role),
				selector: (row: any) => row.role,
				sortable: true,
			},
			{
				name: this.i18n.get(i18nKey.status),
				selector: (row: any) => row.status,
				sortable: true,
			},
			{
				name: this.i18n.get(i18nKey.createdOn),
				selector: (row: any) => row.createdAt,
				sortable: true,
				sortFunction: (rowA,rowB) => {
					const pastDate = new Date("January 1, 2000")
					const a = (rowA.createdAt === this.i18n.get(i18nKey.notAvailableNA)) ? pastDate : new Date(rowA.createdAt)
					const b = (rowB.createdAt === this.i18n.get(i18nKey.notAvailableNA)) ? pastDate : new Date(rowB.createdAt)

					if (a > b) {
						return 1
					} else if (b > a) {
						return -1
					} else {
						return 0
					}
				}
			}
		]

		let data = users.map((user) => {		
			const groupIds: DocumentId[] = Object.keys(user.cohorts ?? {})
			const groupNames = this.dataTableFactoryUtils.mapGroupIdsToGroupNames(groupIds, cohorts)	
			return {
				id: user.documentId,
				name: user.fullName,
				emailAddress: user.email,
				group: groupNames,
				role: user.roleName,
				status: user._status,
				createdAt: user.createdAt
			}
		})

		return new DTSTableData(columns, data)
	}

	createCohortsTableData(cohorts: Cohort[]): DTSTableData {
		let columns: TableColumn<Dictionary>[] = [
			{
				name: "Name",
				selector: (row: any) => row.name,
				sortable: true,
			},
			{
				name: "Description",
				selector: (row: any) => row.description,
				sortable: true,
			},
			{
				name: "# of learners",
				selector: (row: any) => row.learnerCount,
				sortable: true,
			},
		]

		let data = cohorts.map((cohort) => {
			return {
				id: cohort.documentId,
				name: cohort.name,
				description: cohort.description,
				learnerCount: Object.keys(cohort.learners).length | 0,
			}
		})

		return new DTSTableData(columns, data)
	}

	createCoursesForLearningPlanEditorTableData(courses: Course[]): DTSTableData {
		let columns: TableColumn<Dictionary>[] = [
			{
				name: "Name",
				selector: (row: any) => row.name,
				sortable: true,
				wrap: true,
				width: "30%"
			},
			{
				name: "Description",
				selector: (row: any) => row.description,
				sortable: true,
				wrap: true,
				width: "70%"
			},
		]

		let data = courses.map((course) => {
			return {
				id: course.documentId,
				name: course.name,
				description: course.description,
			}
		})

		return new DTSTableData(columns, data)
	}
	
	createSurveysForLearningPlanEditorTableData(surveys: Survey[]): DTSTableData {
		let columns: TableColumn<Dictionary>[] = [
			{
				name: "Name",
				selector: (row: any) => row.name,
				sortable: true,
				wrap: true,
				width: "30%"
			},
			{
				name: "Description",
				selector: (row: any) => row.description,
				sortable: true,
				wrap: true,
				width: "70%"
			},
		]

		let data = surveys.map((survey) => {
			return {
				id: survey.documentId,
				name: survey.name,
				description: survey.description,
			}
		})

		return new DTSTableData(columns, data)
	}

	createCoursesTableData(courses: Course[], clients: Client[]): DTSTableData {
		let columns: TableColumn<Dictionary>[] = [
			{
				name: "Name",
				selector: (row: any) => row.name,
				sortable: true,
				wrap: true,
				width: "25%"
			},
			{
				name: "Client",
				selector: (row: any) => row.client,
				sortable: true,
				wrap: true,
				width: "10%"
			},
			{
				name: "Description",
				selector: (row: any) => row.description,
				sortable: true,
				wrap: true,
				width: "65%"
			},
		]

		let data = courses.map((course) => {
			let clientName
			if (clients.length > 0) {
				clientName = clients.find(element => 
					element.documentId === Object.keys(course.clients)[0]
				)
			}
			return {
				id: course.documentId,
				name: course.name,
				client: clientName ? clientName?.name : this.i18n.get(i18nKey.notAvailableNA),
				description: course.description,
			}
		})

		return new DTSTableData(columns, data)
	}

	createClientsTableData(clients: Client[]): DTSTableData {
		let columns: TableColumn<Dictionary>[] = [
			{
				name: "Name",
				selector: (row: any) => row.name,
				sortable: true,
			},
			{
				name: "Description",
				selector: (row: any) => row.description,
				sortable: true,
			},
		]

		let data = clients.map((client) => {
			return {
				id: client.documentId,
				name: client.name,
				description: client.description,
			}
		})

		return new DTSTableData(columns, data)
	}

	createUserUploadPreviewTableData(data: Dictionary[]): DTSTableData {
		let columns: TableColumn<Dictionary>[] = [
			{
				name: "First Name",
				selector: (row: any) => row.firstName,
				sortable: true,
			},
			{
				name: "Last Name",
				selector: (row: any) => row.lastName,
				sortable: true,
			},
			{
				name: "Email Address",
				selector: (row: any) => row.emailAddress,
				sortable: true,
			}
		]

		let tableData = data.map((dictionary) => {
			return {
				firstName: dictionary.firstName,
				lastName: dictionary.lastName,
				emailAddress: dictionary.emailAddress
			}
		})

		return new DTSTableData(columns, tableData)
	}

	createCohortSearchTableData(users: User[]): DTSTableData {
		let columns: TableColumn<Dictionary>[] = [
			{
				name: "Name",
				selector: (row: any) => row.name,
				sortable: true,
			},
			{
				name: "Email address",
				selector: (row: any) => row.emailAddress,
				sortable: true,
			},
			{
				name: "Role",
				selector: (row: any) => row.role,
				sortable: true,
				width: "20%"
			},
		]

		let data = users.map((user) => {
			return {
				id: user.documentId,
				name: user.fullName,
				emailAddress: user.email,
				role: user.roleName,
			}
		})

		return new DTSTableData(columns, data)
	}

	createCourseCompletionTableData(statements: LRSCompletionStatement[], cohorts: Cohort[], attemptStatements: LRSCompletionStatement[]): DTSTableData {
		let columns: TableColumn<Dictionary>[] = [
			{
				name: "Name",
				selector: (row: any) => row.name,
				sortable: true,
			},
			{
				name: "Email address",
				selector: (row: any) => row.emailAddress,
				sortable: true,
			},
			{
				name: "Groups",
				selector: (row: any) => row.group,
				sortable: true,
				wrap: true,
				width: "20%"
			},
			{
				name: "Attempted",
				selector: (row: any) => row.attempted,
				sortable: true,
				width: "10%"
			},
			{
				name: "Completed",
				selector: (row: any) => row.completed,
				sortable: true,
				width: "10%"
			},
			{
				name: "Time To Complete Course",
				selector: (row: any) => row.duration,
				sortable: true,
				sortFunction: this.dataTableFactoryUtils.sortByMinute,
				width: "20%"
			},
		]

		let statementsToParse: LRSCompletionStatement[]
		statements.length ? statementsToParse = statements : statementsToParse = attemptStatements

		let data = statementsToParse.map((statement) => {
			let completed = false
			let didAttempt = false

			if (statement.data !== undefined && statement.data.completed) {
				completed = true
			}

			didAttempt = attemptStatements.some(attemptStatement => attemptStatement.user.uid === statement.user.uid && attemptStatement.data !== undefined)
			

			let groupName = this.dataTableFactoryUtils.mapGroupNames(statement, cohorts)

			return {
				id: statement.user.uid,
				name: statement.user.fullName,
				emailAddress: statement.user.email,
				group: groupName,
				attempted: didAttempt ? "Yes" : "No",
				completed: completed ? "Yes" : "No",
				duration: completed ? Math.floor(statement.data?.duration!/60).toString() + " Min" : " "

			}
		})

		return new DTSTableData(columns, data)
	}

	createFilteredCourseCompletionTableData(statements: LRSCompletionStatement[], cohorts: Cohort[]): DTSTableData {
		let columns: TableColumn<Dictionary>[] = [
			{
				name: "Name",
				selector: (row: any) => row.name,
				sortable: true,
			},
			{
				name: "Email address",
				selector: (row: any) => row.emailAddress,
				sortable: true,
			},
			{
				name: "Groups",
				selector: (row: any) => row.group,
				sortable: true,
				wrap: true,
				width: "20%"
			},
			{
				name: "Attempted",
				selector: (row: any) => row.attempted,
				sortable: true,
				width: "10%"
			},
			{
				name: "Completed",
				selector: (row: any) => row.completed,
				sortable: true,
				width: "10%"
			},
			{
				name: "Time To Complete Course",
				selector: (row: any) => row.duration,
				sortable: true,
				sortFunction: this.dataTableFactoryUtils.sortByMinute,
				width: "20%"
			},
		]

		let data = statements.map((statement) => {
			let groupName = this.dataTableFactoryUtils.mapGroupNames(statement, cohorts)
			let duration = ""

			if (statement.data !== undefined && statement.data.duration !== null) {
				duration = Math.floor(statement.data.duration/60).toString() + " Min"
			}
			  
			return {
				id: statement.user.uid,
				name: statement.user.fullName,
				emailAddress: statement.user.email,
				group: groupName,
				attempted: "Yes",
				completed: "Yes",
				duration: duration
			}
		})

		return new DTSTableData(columns, data)
	}

	createLearningPlansTableData(learningPlans: LearningPlan[]): DTSTableData {
		let columns: TableColumn<Dictionary>[] = [
			{
				name: "Name",
				selector: (row: any) => row.name,
				sortable: true,
			},
			{
				name: "Description",
				selector: (row: any) => row.description,
				sortable: true,
			},
		]

		let data = learningPlans.map((plan) => {
			return {
				id: plan.documentId,
				name: plan.name,
				description: plan.description,
			}
		})

		return new DTSTableData(columns, data)
	}

	createSurveysTableData(surveys: Survey[]): DTSTableData {
		let columns: TableColumn<Dictionary>[] = [
			{
				name: "Name",
				selector: (row: any) => row.name,
				sortable: true,
			},
			{
				name: "Description",
				selector: (row: any) => row.description,
				sortable: true,
			},
		]

		let data = surveys.map((survey) => {
			return {
				id: survey.documentId,
				name: survey.name,
				description: survey.description,
			}
		})

		return new DTSTableData(columns, data)
	}

	createSurveyCompletionTableData(statements: LRSCompletionStatement[], cohorts: Cohort[], attemptStatements: LRSCompletionStatement[]): DTSTableData {
		let columns: TableColumn<Dictionary>[] = [
			{
				name: "Name",
				selector: (row: any) => row.name,
				sortable: true,
			},
			{
				name: "Email address",
				selector: (row: any) => row.emailAddress,
				sortable: true,
			},
			{
				name: "Groups",
				selector: (row: any) => row.group,
				sortable: true,
				wrap: true,
				width: "20%"
			},
			{
				name: "Attempted",
				selector: (row: any) => row.attempted,
				sortable: true,
				width: "10%"
			},
			{
				name: "Completed",
				selector: (row: any) => row.completed,
				sortable: true,
				width: "10%"
			}
		]

		let data = statements.map((statement) => {
			let completed = false
			let didAttempt = false

			if (statement.data !== undefined && statement.data.completed) {
				completed = true
			}

			didAttempt = attemptStatements.some(attemptStatement => attemptStatement.user.uid === statement.user.uid && attemptStatement.data !== undefined)
			

			let groupName = this.dataTableFactoryUtils.mapGroupNames(statement, cohorts)

			return {
				id: statement.user.uid,
				name: statement.user.fullName,
				emailAddress: statement.user.email,
				group: groupName,
				attempted: didAttempt ? "Yes" : "No",
				completed: completed ? "Yes" : "No",
			}
		})

		return new DTSTableData(columns, data)
	}
}
