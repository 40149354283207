import React, { Component } from "react";
import { 
    DefaultButton as FluentUISecondaryButton, 
    IButtonProps,
    IButtonStyles
} from '@fluentui/react';

export class SecondaryButton extends Component<IButtonProps> {
    height = 40
    cornerRadius = 5

    render() {
        let style: {[key: string]: any} = {
            root: {
                height: `${this.height}px`,
                borderRadius: `${this.cornerRadius}px`
            }
        }

        if (this.props.width) {
            style.root['width'] = this.props.width
        }

        const buttonStyle: Partial<IButtonStyles> = style

        return (
            <FluentUISecondaryButton 
            {...this.props} 
            styles={buttonStyle}
        />
        )
    }
}
