import { injectable } from "inversify"
import { container } from "../DIContainer"
import { TYPES } from "../Types"
import { ICourseService } from "./CourseService"
import Course from "../models/Course"
import { IInvitationCodeService } from "./InvitationCodeService"
import { Invitation } from "../models/Invitation"
import { ILocalStorage } from "../utils/LocalStorage"
import { i18nKey, Ii18n } from "../global/i18n"
import { IInvitationCodeParser } from "./InvitationCodeParser"
import { IRouter } from "../Router"
import { Dictionary } from "../global/TypeAliases"

/**
 * Obtains the guest content of unlocked and locked courses, and the invitation
 *
 * @param code an optional invitation code from which to find an invitation
 * @returns Guest Dashboard Data promise
 */
export interface IGuestDashboardCompositionService {
    getGuestData(code?: string): Promise<IGuestDashboardData>
}

export interface IGuestDashboardData {
    courses: Course[]
    unlockedCourses: Course[]
    lockedCourses: Course[]
    invitation: Invitation | undefined
}

@injectable()
export class GuestDashboardCompositionService implements IGuestDashboardCompositionService {
    /**
     * Static Properties
     */
    static invitationCode = "invitationCode"

    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private courseService: ICourseService = container.get<ICourseService>(TYPES.ICourseService)
    private invitationParser: IInvitationCodeParser = container.get<IInvitationCodeParser>(TYPES.IInvitationCodeParser)
    private invitationService: IInvitationCodeService = container.get<IInvitationCodeService>(TYPES.IInvitationCodeService)
    private router: IRouter = container.get<IRouter>(TYPES.IRouter)
    private storage = container.get<ILocalStorage>(TYPES.ILocalStorage)

    getGuestData(code?: string): Promise<IGuestDashboardData> {
        let promiseArray: Promise<any>[] = []
        let saveCodeToStorage = code

        if (code) {
            // code passed in as parameter
            promiseArray.push(this.invitationService.getInvitation(code))
        } else {
            // no code in parameter - check for codes in storage or URL
            const invitationCode = this.obtainInvitationCode()
            if (invitationCode) {
                saveCodeToStorage = invitationCode
                promiseArray.push(this.invitationService.getInvitation(invitationCode))
            } else {
                // no code available
                return Promise.resolve({
                    courses: [],
                    unlockedCourses: [],
                    lockedCourses: [],
                    invitation: undefined
                })
            }
        }

        promiseArray.push(this.courseService.coursesForGuest())

        return new Promise((resolve, reject) => {
            Promise.all(promiseArray)
                .then((result) => {
                    return {
                        invitation: result[0],
                        courses: result[1]
                    }
                })
                .then((data) => {
                    // check for invalid or expired invitation
                    const invitation: Invitation = data.invitation
                    if (invitation === undefined) {
                        this.storage.delete(GuestDashboardCompositionService.invitationCode)
                        throw new Error(this.i18n.get(i18nKey.invalidInvitation))
                    } else if (invitation.expired) {
                        this.storage.delete(GuestDashboardCompositionService.invitationCode)
                        throw new Error(this.i18n.get(i18nKey.expiredCode))
                    }
                    // save valid code to storage
                    if (saveCodeToStorage) {
                        this.storage.save(GuestDashboardCompositionService.invitationCode, saveCodeToStorage)
                    }

                    // process courses
                    const processedCourses: Dictionary = this.unlockCourses(data.courses, invitation)
                    resolve({
                        courses: data.courses,
                        unlockedCourses: processedCourses.unlockedCourses,
                        lockedCourses: processedCourses.lockedCourses,
                        invitation: data.invitation
                    })
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    /**
     * Attempts to retrieve an invitation code either cached or from the browser.
     *
     * @returns the invitation code or null if unable to obtain
     */
    private obtainInvitationCode = (): string | null => {
        let invitationCode = this.storage.get(GuestDashboardCompositionService.invitationCode)
        if (!invitationCode) {
            invitationCode = this.invitationParser.getCode(this.router.location())
        }

        return invitationCode
    }

    /**
     * Unlocks the courses per the invitation.
     *
     * @param courses the collection of all courses to evaluate
     * @param invitation the invitation from which to unlock courses
     * @returns a collection of unlocked and locked courses
     */
    private unlockCourses = (courses: Course[], invitation: Invitation): Dictionary => {
        let unlockedCourses: Course[] = []
        let lockedCourses: Course[] = []

        courses.forEach((course) => {
            invitation.selectedTrainings.includes(course.name) ? unlockedCourses.push(course) : lockedCourses.push(course)
        })

        unlockedCourses.forEach((course: Course) => {
            course.locked = false
        })

        return {
            unlockedCourses: unlockedCourses,
            lockedCourses: lockedCourses
        }
    }
}
