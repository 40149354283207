import { IEmailValidator } from './../../../utils/EmailValidator';
import { Dictionary } from '../../../global/TypeAliases';
import { container } from '../../../DIContainer';
import { TYPES } from '../../../Types';
import { injectable } from 'inversify';
import { i18nKey, Ii18n } from "../../../global/i18n";

export enum UploadUserError {
    empty = 0,
    duplicateEmail,
    missingRequiredFields,
    invalidEmailAddress,
    invalidHeaderRow,
    compositionServiceError
}

export interface UploadValidatorError {
    error: UploadUserError
    message: string
}

export interface UserUploadResult {
    errors: UploadValidatorError[] | null
    data: Dictionary[]
}

export interface IUserUploadValidator {
    validate(data: Dictionary[]): UserUploadResult
}

@injectable()
export class UserUploadValidator implements IUserUploadValidator {
    private emailValidator: IEmailValidator = container.get<IEmailValidator>(TYPES.IEmailValidator)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    validate(data: Dictionary[]): UserUploadResult {
        if (data.length === 0) {
            const errors = [
                {
                    error: UploadUserError.empty,
                    message: this.i18n.get(i18nKey.userUploadEmptyCSVError)
                }
            ]
            return { errors: errors, data: [] }
        }

        let errors: UploadValidatorError[] = []

        const validateHeaderRow = this.validateHeaderRow(data)
        if (validateHeaderRow !== null) {
            errors.push(validateHeaderRow)
        }

        const duplicateError = this.duplicateCheck(data)
        if (duplicateError !== null) {
            errors.push(duplicateError)
        }

        const dataIntegrityCheck = this.dataIntegrityCheck(data)
        if (dataIntegrityCheck !== null) {
            errors.push(dataIntegrityCheck)
        }

        const validateEmail = this.validateEmail(data)
        if (validateEmail !== null) {
            errors.push(validateEmail)
        }

        return { errors: errors.length === 0 ? null : errors, data: data }
    }

    private validateHeaderRow(data: Dictionary[]): UploadValidatorError | null {        
        if (data.length <= 0) {
            return {
                error: UploadUserError.empty,
                message: this.i18n.get(i18nKey.userUploadEmptyCSVError)
            }
        }

        let message = ""

        /**
         * If any one of the headers is incorrect, show error message.
         * This is not checking for camelCase here because
         * before we parse, we transform the header row to be 
         * all lowercase. Nor is this checking for header row item order.
         * This is checking for proper naming and spelling, though.
         */
        if (data[0]["firstName"] === undefined || data[0]["lastName"] === undefined || data[0]["emailAddress"] === undefined) {
            message += this.i18n.get(i18nKey.userUploadInvalidHeaderRow)
        } 

        /**
         * All headers are valid
        */
        if (message === "") {
            return null
        }

        return {
            error: UploadUserError.invalidHeaderRow,
            message: message
        }
    }

    private dataIntegrityCheck(data: Dictionary[]): UploadValidatorError | null {
        let invalidRows: number[] = []

        data.forEach((entry, index) => {
            const firstName = entry.firstName
            const lastName = entry.lastName
            const emailAddress = entry.emailAddress

            const rowIndex = index+2
            
            if (firstName === "" || lastName === "" || emailAddress === "") {
                invalidRows.push(rowIndex)
            } else if (firstName === undefined ||  firstName === null 
                || lastName === undefined || lastName === null 
                || emailAddress === undefined || emailAddress === null
            ) {
                invalidRows.push(rowIndex)
            } 
        })

        /**
         * All rows have valid data
         */
        if (invalidRows.length === 0) {
            return null
        }

        /**
         * Invalid rows found. 
         * Create a friendly error message.
         */
        let message = this.i18n.get(i18nKey.userUpoaldMissingFields)

        invalidRows.forEach((rowNumber, index) => {
            if (index === 0) {
                message += ` Row ${rowNumber}`
            } else {
                message += `, Row ${rowNumber}`
            }
        })

        return {
            error: UploadUserError.missingRequiredFields,
            message: message
        }
    }

    private duplicateCheck(data: Dictionary[]): UploadValidatorError | null {
        let uniqueEmails: Set<string> = new Set()
        let duplicateEmails: Set<string> = new Set()

        /**
         * Find duplicate emails
         */
        data.forEach(entry => {
            let email = entry.emailAddress 
            if (email === undefined || email === null || email.length === 0) {
                return
            }

            /**
             * Once each email is initially validated, set to lower case to prevent
             * duplicates from being added that differ only in letter case
             */
            email = email.toLowerCase()

            if (uniqueEmails.has(email)) {
                duplicateEmails.add(email)
            } else {
                uniqueEmails.add(email)
            }
        })

        /**
         * No duplicates found
         */
        if (duplicateEmails.size === 0) {
            return null
        }

        /**
         * Duplicate emails found. 
         * Create a friendly error message.
         */
        let message = this.i18n.get(i18nKey.userUploadDuplicateEmails)
        const iterator = duplicateEmails.values()

        for (let i = 0; i < duplicateEmails.size; i++) {
            const email = iterator.next().value
            if (i === 0) {
                message += ` ${email}`
            } else {
                message += `, ${email}`
            }
        }

        return {
            error: UploadUserError.duplicateEmail,
            message: message
        }
    }

    private validateEmail(data: Dictionary[]): UploadValidatorError | null {
        let invalidRows: number[] = []

        data.forEach((entry, index) => {
            const emailAddress = entry.emailAddress
            const rowIndex = index+2 // We add two because it's zero based and we skip the header row

            if (emailAddress === undefined || emailAddress === null) {
                invalidRows.push(rowIndex)
                return
            }

            if (!this.emailValidator.validate(emailAddress)) {
                invalidRows.push(rowIndex)
            }
        })

        /**
         * All rows have valid data
         */
        if (invalidRows.length === 0) {
            return null
        }

        /**
         * Invalid rows found. 
         * Create a friendly error message.
         */
        let message = this.i18n.get(i18nKey.userUploadInvalidEmails)

        invalidRows.forEach((rowNumber, index) => {
            if (index === 0) {
                message += ` Row ${rowNumber}`
            } else {
                message += `, Row ${rowNumber}`
            }
        })

        return {
            error: UploadUserError.invalidEmailAddress,
            message: message
        }
    }
}
