import React, { Component } from "react";
import { container } from "../../DIContainer";
import { TYPES } from "../../Types";
import Course from "../../models/Course";
import { Loader } from "../../ui-kit/Loader";
import { Stack, MessageBar, MessageBarType, Spinner } from "@fluentui/react";
import CourseGrid from "../dashboard/CourseGrid";
import PageTitle from "../../ui-kit/PageTitle";
import { log } from "../../global/Logger";
import { IRouter } from "../../Router";
import { i18nKey, Ii18n } from "../../global/i18n";
import { ReportsSurveyGrid } from "./ReportsSurveyGrid";
import { IReportsHomePageCompositionService } from "../../services/ReportsHomePageCompositionService";
import { Survey } from "../../models/Survey";
import { ClientContextProp } from "../admin/WithClientContext";

interface ReportsHomePageProps extends ClientContextProp {}

interface ReactHomePageState {
    showPageLoader: boolean
    showSpinner: boolean
    courses: Course[]
    surveys: Survey[]
    errorMessage: string | null
    showClientContextDialog: boolean
}

export class ReportsHomePage extends Component<ReportsHomePageProps, ReactHomePageState> {
    private router = container.get<IRouter>(TYPES.IRouter)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private compositionService = container.get<IReportsHomePageCompositionService>(TYPES.IReportsHomePageCompositionService)

    state: ReactHomePageState = {
        showPageLoader: true,
        showSpinner: false,
        courses: [],
        surveys: [],
        errorMessage: null,
        showClientContextDialog: false
    }

    componentDidMount() {
        this.loadPageData()
    }

    render() {
        if (this.state.showPageLoader) {
			return <Loader />;
        }

        return (
            <>
            <PageTitle title={this.i18n.get(i18nKey.reportsHomePageTitle)}/>
            <Stack tokens={{ childrenGap: 20 }}>
                <Stack tokens={{ childrenGap: 20 }}>
                    { this.state.showSpinner &&
                        <Spinner
                            label="Loading courses"
                            ariaLive="assertive"
                            labelPosition="right"
                        />
                    }
                </Stack>

                <div id="course-grid-container">
                    {this.state.errorMessage && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            dismissButtonAriaLabel="Close"
                            onDismiss={this.dismissBanner}
                        >
                            {this.state.errorMessage}
                        </MessageBar>
                    )}

                    { !this.state.errorMessage &&
                        <Stack>
                            <Stack styles={{ root: { paddingLeft: 50, paddingTop: 20 , paddingBottom: 20}}}>
                                <PageTitle title={"Trainings"}/>
                                <CourseGrid
                                    courses={this.state.courses}
                                    showProgressBar={false}
                                    shouldWrap={true}
                                    onCourseClick={this.courseClicked}
                                />
                            </Stack>
                            <Stack styles={{ root: { paddingLeft: 50, paddingTop: 20 , paddingBottom: 20}}}>
                                <PageTitle title={"Surveys"}/>
                                <ReportsSurveyGrid 
                                    surveys={this.state.surveys}
                                    onSurveyClick={this.surveyClicked}
                                    shouldWrap={true}
                                />
                            </Stack>
                        </Stack>
                    }
                </div>
            </Stack>
			
            </>
        )
    }

    /**
     * Private Functions
     */

    private loadPageData() {
        this.compositionService.getData(this.props.clientId)
            .then(result => {
                this.setState({
                    courses: result.courses,
                    surveys: result.surveys,
                    errorMessage: null,
                    showPageLoader: false,
                    showSpinner: false
                })
            })
            .catch(_ => {
                this.setState({
                    courses: [],
                    errorMessage: this.i18n.get(i18nKey.failedToLoadReportsHomePageContent),
                    showPageLoader: false,
                    showSpinner: false
                })
            })
    }

    private dismissBanner() {
		this.setState({ errorMessage: null })
    }
    
    private courseClicked = (e: React.MouseEvent<HTMLDivElement>) => {
        log("Course clicked: " + e.currentTarget.id)
        
		const courseID = e.currentTarget.id;
		const course = this.state.courses.find(
			(course) => course.documentId === courseID
		)

		if (course === undefined) {
			return
		}

        this.router.goToReportCompletion({
            course: course,
            clientId: this.props.clientId
        })
    }

    private surveyClicked = (e: React.MouseEvent<HTMLDivElement>) => {
        log("Survey clicked: " + e.currentTarget.id)
        
		const surveyID = e.currentTarget.id;
		const survey = this.state.surveys.find(
			(survey) => survey.documentId === surveyID
		)

		if (survey === undefined) {
			return
		}

        this.router.goToSurveyReport({
            surveyId: survey.documentId,
            clientId: this.props.clientId
        })
    }
}
