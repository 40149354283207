import { IStackStyles, ITextStyles, IStackTokens, IModalStyles } from "@fluentui/react"
import { Theme } from "../../ui-kit/Theme"

export class SurveyContainerPageStyles {
    static surveyStackStyling: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    margin: '-6vh 0 0 0',
                    padding: '3vh 0 0 0'
                },
            },
            margin: "100px 20%",
            padding: 50,
            borderRadius: 10,
            backgroundColor: '#ffffff'
        }
    }

    static surveyTitleStyling: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    padding: '1em 1em 1em 1em',
                    textAlign: 'center'
                },
            },
        }
    }

    static actionButtonStackStyling: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    paddingTop: 50
                },
            },
            paddingTop: 100
        }
    }

    static noteStyling: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    padding: '20px 20px 20px 20px',
                    textAlign: 'center',
                    width: '90vw'
                },
            },
            paddingTop: 50, 
            color: Theme.accent.secondary
        }
    }

    static stackTokens: IStackTokens = {
        childrenGap: 20
    }

    static modalStyles: Partial<IModalStyles> = {
        main: {
            width: 400,
        }
    }
}