import { injectable } from 'inversify'

export interface ILocalStorage {
    /**
     * Save an object to local storage
     * @param key The key 
     * @param value The string value
     */
    save(key: string, value: string): void

    /**
     * Delete an object from local storage
     * @param key The key
     */
    delete(key: string): void

    /**
     * Get an object from local storage. Returns null if the object
     * does not exist.
     * @param key The key
     */
    get(key: string): string | null
}

@injectable()
export class LocalStorage implements ILocalStorage {
    /**
     * Private properties
     */

    private storage = localStorage

    /**
     * Public functions
     */

    save(key: string, value: string) {
        this.storage.setItem(key, value)
    }

    delete(key: string) {
        this.storage.removeItem(key)
    }

    get(key: string): string | null {
        return this.storage.getItem(key)
    }
}