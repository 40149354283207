import { injectable } from 'inversify'
import { Dictionary, DocumentId } from './global/TypeAliases'
import history from './History'
import { PathNames } from './PathNames'

export interface IRouter {
    location(): string
    goBack(): void
    refreshPage(): void
    goToReports(): void
    goToReportCompletion(data: Dictionary): void
    goToSurveyReport(data: Dictionary): void
    goToCourse(data: Dictionary): void
    goToGuestCourse(data: Dictionary): void
    goToViewSurvey(data: Dictionary): void
    goToEmailVerify(data: Dictionary): void
    goToDashboard(): void
    goToUserUpload(): void
    goToLearnersPage(): void
    goToLogin(data?: Dictionary): void
    goToTestimonials(): void
    goToFeatures(): void
    goToPasswordResetSent(data: Dictionary): void    
    goToAddNewLearningPlan(data: Dictionary): void
    goToEditLearningPlan(data: Dictionary): void
    goToContentsCourseTab(): void
    goToContentsLearningPlanTab(clientId: DocumentId | null): void
    goToContentsSurveyTab(): void
    goToGoogleFormTraining(data: Dictionary): void
    goToEditGroupDetails(data: Dictionary): void
    goToCleverLogin(): void
    goToNewSurvey(data: Dictionary): void
    goToEditSurvey(data: Dictionary): void
    goToLearningPlans(data: Dictionary): void
    goToGuestDashboard(data: Dictionary): void
}

@injectable()
export class Router implements IRouter {
    location(): string {
        return window.location.href
    }

    goBack() {
        history.goBack()
    }

    refreshPage() {
        window.location.reload()
    }

    goToReports() {
        history.push(PathNames.Reports)
    }

    goToReportCompletion(data: Dictionary) {
        history.push(PathNames.ReportCompletion, data)
    }

    goToSurveyReport(data: Dictionary) {
        history.push(PathNames.SurveyReport, data)
    }

    goToCourse(data: Dictionary) {
        history.push(PathNames.Course, data)
    }
    
    goToGuestCourse(data: Dictionary) {
        history.push(PathNames.GuestCourse, data)
    }

    goToViewSurvey(data: Dictionary) {
        history.push(PathNames.ViewSurvey, data)
    }

    goToEmailVerify(data: Dictionary) {
        history.push(PathNames.EmailVerify, data)
    }

    goToDashboard() {
        history.push(PathNames.Dashboard)
    }

    goToUserUpload() {
        history.push(PathNames.UploadUsers)
    }

    goToLearnersPage() {
        history.push(PathNames.Learners)
    }

    goToLogin(data?: Dictionary) {
        history.push(PathNames.Login, data)
    }

    goToTestimonials() {
        history.push(PathNames.Testimonials)        
    }

    goToFeatures() {
        history.push(PathNames.Features) 
    }
    
    goToPasswordResetSent(data: Dictionary) {
        history.push(PathNames.PasswordResetSent, data)
    }

    goToAddNewLearningPlan(data: Dictionary) {
        history.push(PathNames.AddNewLearningPlan, data)
    }

    goToEditLearningPlan(data: Dictionary) {
        history.push(PathNames.EditLearningPlan, data)
    }

    goToEditGroupDetails(data: Dictionary) {
        history.push(PathNames.EditGroupDetails, data)
    }

    goToContentsCourseTab() {
        history.replace(PathNames.ContentsCourseTab)
    }

    goToContentsLearningPlanTab(clientId: DocumentId | null) {
        let path = PathNames.ContentsLearningPlanTab as string

        if (clientId) {
            path += "&c=" + clientId
        }

        history.replace(path)
    }

    goToContentsSurveyTab() {
        history.replace("/contents?t=survey")
    }

    goToGoogleFormTraining(data: Dictionary) {
        history.push(PathNames.GoogleFormTraining, data)
    }

    goToNewSurvey(data: Dictionary): void {
        history.push(PathNames.Survey, data)
    }

    goToEditSurvey(data: Dictionary): void {
        history.push(PathNames.Survey, data)
    }
    
    goToCleverLogin() {
        window.location.href = "https://clever.com/oauth/district-picker"
    }

    goToLearningPlans(data: Dictionary): void {
        history.push(PathNames.LearningPlans, data)
    }

    goToGuestDashboard(data: Dictionary): void {
        history.push(PathNames.GuestDashboard, data)
    }
}
