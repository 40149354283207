import { Component } from "react";
import { Survey } from "../../../models/Survey";
import { Stack, StackItem, Text } from "@fluentui/react";
import { FontSize } from "../../../ui-kit/FontSize";
import { container } from "../../../DIContainer";
import { Ii18n, i18nKey } from "../../../global/i18n";
import { TYPES } from "../../../Types";
import { Survey as SurveyRenderer, SurveyModel } from 'survey-react'
import { Dictionary } from "../../../global/TypeAliases";
import { ISurveyHelper } from "./SurveyHelper";
import './survey.css';

interface SurveyLayoutPanelProps {
    survey: Survey
    interactionFocused: (id: string) => void
    focusedInteractionId: string
}
 
interface SurveyLayoutPanelState {}

export class SurveyLayoutPanel extends Component<SurveyLayoutPanelProps, SurveyLayoutPanelState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private surveyHelper: ISurveyHelper = container.get<ISurveyHelper>(TYPES.ISurveyHelper)

    private surveyStyles = {
        root: { 
            backgroundColor: '#ffffff', 
            padding: 25, 
            borderRadius: 10 
        }
    }

    render() { 
        return (
            <Stack tokens={{childrenGap: 20}}>
                <Text 
                    id="title-label"
                    variant={FontSize.xLarge}>
                        {this.i18n.get(i18nKey.builderPanelTitle)}
                </Text>
                
                { this.props.survey.pages.length > 0 &&
                    <StackItem styles={this.surveyStyles}>
                        <SurveyRenderer 
                            surveyLocalization={this.surveyHelper.getCustomSurveyStrings()} 
                            json={this.props.survey.pages[0].toJSON()} 
                            mode="display" 
                            onAfterRenderQuestion={this.onAfterRenderQuestion} 
                            onUpdateQuestionCssClasses={this.onUpdateQuestionCssClasses}
                        />
                    </StackItem>
                }
            </Stack>
        )
    }

    
    /**
     * Public Functions 
     */

    questionClicked = (sender: Dictionary) => {
        this.onInteractionFocusedClicked(sender.currentTarget.attributes.name.value)
        this.addHighlightStyles(sender.currentTarget.id)
    }

    onUpdateQuestionCssClasses = (_: Dictionary, options: any) => {
        const classes = options.cssClasses
        const focusedInteractionId = options.question.name
   
        if (focusedInteractionId === this.props.focusedInteractionId) {
            classes.mainRoot += " selected"  
        }
    }

    /**
     * Private Functions
     */
    private onAfterRenderQuestion = (_: SurveyModel, data: any) => {
        data.htmlElement.addEventListener('click', this.questionClicked, false)
        data.htmlElement.addEventListener('mouseover', this.addHoverStyles, false)
        data.htmlElement.addEventListener('mouseleave', this.removeHoverStyles, false)
    }

    private onInteractionFocusedClicked = (id: string) => {
        this.props.interactionFocused(id)
    }

    private addHighlightStyles = (id: string) => {
        const currentElement = document.getElementById(id)
        const allElements = document.querySelectorAll('.sv_qstn')

        allElements.forEach((elem) => {
            if (elem.classList.contains('selected')) {
                elem.classList.remove('selected')
            }
        })

        if (currentElement) {
            currentElement.classList.add('selected')
        } 
    }

    private addHoverStyles = (sender: Dictionary) => {
        const currentElement = document.getElementById(sender.currentTarget.id)
        if (currentElement) {
            currentElement.classList.add('hover')
        }
    }

    private removeHoverStyles = (sender: Dictionary) => {
        const currentElement = document.getElementById(sender.currentTarget.id)
        if (currentElement) {
            currentElement.classList.remove('hover')
        }
    }
}