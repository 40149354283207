import React, { Component, MouseEvent } from "react"
import { container } from "../../../DIContainer"
import {
	Stack,
	IStackTokens,
	Spinner,
	MessageBar,
	MessageBarType,
	IDropdownOption,
	TooltipHost,
	Checkbox,
} from "@fluentui/react"
import "./NewUserModal.css"
import { TYPES } from "../../../Types"
import { RoleType } from "../../../models/RoleType"
import Client from "../../../models/Client"
import { TextField } from '../../../ui-kit/TextField'
import { PrimaryButton } from "../../../ui-kit/PrimaryButton"
import { Dropdown } from "../../../ui-kit/Dropdown"
import { IAccessController } from "../../../services/AccessController"
import { log } from "../../../global/Logger"
import { IUserUploadCompositionService } from "../../../services/UserUploadCompositionService"
import { IClientService } from "../../../services/ClientService"
import { DocumentId } from "../../../global/TypeAliases"
import { ITooltipHostStyles } from "@fluentui/react"
import { IFeatureFlagService } from "../../../services/FeatureFlagService"
import { i18nKey, Ii18n } from "../../../global/i18n"

interface NewUserModalProps {
	userAdded: (successMessage: string) => void
	clientId: DocumentId
}

interface NewUserModalState {
	firstName: string
	lastName: string
	emailAddress: string
	password: string
	addUserButtonDisabled: boolean
	showSpinner: boolean
	errorMessage: string | undefined
	selectedRole: RoleType
	gettingClientList: boolean
	clientListOptions: IDropdownOption[]
	selectedClient: Client | null
	registrationReminderIsChecked: boolean
}

const stackStyling: IStackTokens = {
	childrenGap: "20px",
}

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'center' } };
const calloutProps = {
    gapSpace: 0,
    // If the tooltip should point to an absolutely-positioned element,
    // you must manually specify the callout target.
    target: `#registration-reminder-checkbox`,
}

export default class NewUserModal extends Component<
	NewUserModalProps,
	NewUserModalState
> {
    private acl: IAccessController = container.get<IAccessController>(TYPES.IAccessController)
    private compositionService: IUserUploadCompositionService = container.get<IUserUploadCompositionService>(TYPES.IUserUploadCompositionService)
    private clientService: IClientService = container.get<IClientService>(TYPES.IClientService)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
	private featureFlagService: IFeatureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService)

	state = {
		addUserButtonDisabled: true,
		showSpinner: false,
		firstName: "",
		lastName: "",
		emailAddress: "",
		password: "",
		errorMessage: undefined,
		selectedRole: RoleType.learner,
		gettingClientList: false,
		clientListOptions: [],
		selectedClient: null,
		registrationReminderIsChecked: true
	}

	options: IDropdownOption[] = [
		{
			key: RoleType.learner,
			text: "Learner",
			data: RoleType.learner,
			selected: true,
		},
		{
			key: RoleType.clientAdmin,
			text: "Client Admin",
			data: RoleType.clientAdmin,
		},
		{
			key: RoleType.globalAdmin,
			text: "Global Admin",
			data: RoleType.globalAdmin,
		},
	]

	componentDidMount() {
		this.clientService
			.clientForId(this.props.clientId)
			.then(client => {
				this.setState({ selectedClient: client })
			})
	}

	render() {
		return (
			<div id="add-new-user-modal-container">
				<Stack id="new-user-stack" tokens={stackStyling}>
					<TextField
						label="First name"
						onChange={this.firstNameTextFieldChanged}
					/>
					<TextField
						label="Last name"
						onChange={this.lastNameTextFieldChanged}
					/>
					<TextField
						label="Email address"
						onChange={this.emailAddressTextFieldChanged}
					/>

					{ this.acl.canAddAdminRole() && 
						<Dropdown
							id="role-dropdown"
							placeholder="Select a role"
							label="Role"
							options={this.options}
							onChange={this.roleChanged}
						/>
					}
					{ this.featureFlagService.onboardingRegistrationReminder() &&
						<TooltipHost
							content={this.i18n.get(i18nKey.registrationReminderTooltipTextSingleAdd)}
							hostClassName="add-new-user-registration-reminder-tooltip"
							styles={hostStyles}
							calloutProps={calloutProps}
						>
							<Checkbox 
								id={"registration-reminder-checkbox"}
								label={this.i18n.get(i18nKey.registrationReminderCheckboxText)} 
								checked={this.state.registrationReminderIsChecked} 
								onChange={this.registrationReminderCheckboxOnChange} 
								boxSide={"end"}
							/>
						</TooltipHost>
					}

					{ this.state.errorMessage !== undefined &&
						<MessageBar
							messageBarType={MessageBarType.error}
							dismissButtonAriaLabel="Close"
							onDismiss={this.dismissErrorBanner}
						>
							{this.state.errorMessage}
						</MessageBar>
					}

					{ !this.state.showSpinner &&
						<PrimaryButton
							id="new-user-primary-button"
							text="Add User"
							onClick={this.addUserButtonClicked}
							disabled={this.state.addUserButtonDisabled}
						/>
					}

					{ this.state.showSpinner &&
						<Spinner
							label="Adding user..."
							ariaLive="assertive"
							labelPosition="right"
						/>
					}
				</Stack>
			</div>
		)
	}

	dismissErrorBanner = () => {
		this.setState({ errorMessage: undefined })
	}

	addUserButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		const client = this.state.selectedClient
		if (client === null) {
			this.setState({
				errorMessage: "No client selected.",
				showSpinner: false,
				addUserButtonDisabled: false,
			})
			
			return
		}

		this.setState({
			errorMessage: undefined,
			showSpinner: true,
			addUserButtonDisabled: true,
		})

		const role = this.state.selectedRole === undefined
						? RoleType.learner
						: this.state.selectedRole

		const userData = {
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			emailAddress: this.state.emailAddress.trim().toLowerCase()
		}

		if (this.featureFlagService.onboardingV2()) {
			this.compositionService
				.uploadSingleUser(userData, client, this.state.registrationReminderIsChecked, role)
				.then(() => {
					let message = this.state.firstName + " " + this.state.lastName + " has been added"
					this.props.userAdded(message)	
				})
				.catch(error => {
					this.setState({
						errorMessage: error.message,
						showSpinner: false,
						addUserButtonDisabled: false,
					})
				})
		} else {
			this.compositionService
			.uploadUsersForClient(client, [userData], this.state.registrationReminderIsChecked, role)
			.then(() => {
				let message = this.state.firstName + " " + this.state.lastName + " has been added"
				this.props.userAdded(message)
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showSpinner: false,
					addUserButtonDisabled: false,
				})
			})
		}
	}

	firstNameTextFieldChanged = (
		_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return
		}

		let val = newValue

		this.setState({ firstName: val }, () => {
			this.enableAddUserButtonIfNeeded()
		})
	}

	lastNameTextFieldChanged = (
		_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return
		}

		let val = newValue

		this.setState({ lastName: val }, () => {
			this.enableAddUserButtonIfNeeded()
		})
	}

	emailAddressTextFieldChanged = (
		_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return
		}

		let val = newValue

		this.setState({ emailAddress: val }, () => {
			this.enableAddUserButtonIfNeeded()
		})
	}

	roleChanged = (
		event: React.FormEvent<HTMLDivElement>,
		option?: IDropdownOption,
		index?: number
	) => {
		log("Role selected: " + option?.data)
		this.setState({ selectedRole: option?.data }, () => {
			this.enableAddUserButtonIfNeeded()
		})
	}

	enableAddUserButtonIfNeeded = () => {
		let disable = true

		if (
			this.state.firstName.length > 0 &&
			this.state.lastName.length > 0 &&
			this.state.emailAddress.length > 0 && 
			this.state.selectedClient !== null
		) {
			disable = false
		}

		this.setState({ addUserButtonDisabled: disable })
	}

	registrationReminderCheckboxOnChange = () => {
		this.setState({registrationReminderIsChecked: !this.state.registrationReminderIsChecked})
	}
}
