import Course from "../../../models/Course"
import React, { Component, ReactElement } from "react"
import { Stack, Image, Text, Icon } from "@fluentui/react"
import { ImageFit, ProgressIndicator } from "@fluentui/react"
import { FontSize } from "../../../ui-kit/FontSize"
import { CourseGridItemStyle } from "./CourseGridItemStyle"
import { ILRSService } from "../../../services/LRSService"
import { TYPES } from "../../../Types"
import { container } from "../../../DIContainer"
import { IAuth } from "../../../auth/Auth"
import { LRSStatement } from "../../../models/LRSStatement"
import { DocumentId } from "../../../global/TypeAliases"
import { Ii18n, i18nKey } from "../../../global/i18n"
import { IFeatureFlagService } from "../../../services/FeatureFlagService"
import { log } from "../../../global/Logger"
import { IDeadlinesHelper } from "../../../utils/DeadlinesHelper"

interface CourseGridItemProps {
    course: Course
    userClients?: DocumentId[]
    onCourseClick: (e: React.MouseEvent<HTMLDivElement>) => void
    showProgressBar: boolean
    deadline?: string
}

interface CourseGridItemState {
    progress: number
    foundPassedStatement: boolean
}

export default class CourseGridItem extends Component<CourseGridItemProps, CourseGridItemState> {
    private lrsService: ILRSService = container.get<ILRSService>(TYPES.ILRSService)
    private auth = container.get<IAuth>(TYPES.IAuth)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private featureFlagService: IFeatureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService)
	private deadlinesHelper: IDeadlinesHelper = container.get<IDeadlinesHelper>(TYPES.IDeadlinesHelper)

    state: CourseGridItemState = {
        progress: 0,
        foundPassedStatement: false
    }

    componentDidMount() {
        if (this.props.showProgressBar && this.auth.user !== undefined) {
            const sharedClientIds = this.mapCourseClientsToUserClients()
            let highestProgress: LRSStatement[]
            let highestProgressPercentage: number = 0
            let user = this.auth.user
    
             sharedClientIds.forEach(client => {
                this.lrsService
                .getOldestPassForCourse(user.uid, client, this.props.course.lrsId)
                .then((statements) => {
                    if (statements.length >= 1) {
                        highestProgressPercentage = 100
                        this.setState({
                            progress: highestProgressPercentage,
                            foundPassedStatement: true
                        })
                    }

                    else {
                        this.lrsService
                        .getProgressionOnCourseForUser(user.uid, client, this.props.course.lrsId)
                        .then((statements) => {
                            highestProgress = statements

                            if (highestProgress.length > 0 
                                && highestProgress[0].progress !== null 
                                && highestProgress[0].progress > this.state.progress) {
                                    this.setState({ 
                                        progress: highestProgress[0].progress
                                    })
                                }
                        })
                        .catch(_ => {
                            log("Failed to get progress statements")
                        })
                    }
                })
               .catch(_ => {
                    log("Failed to get passed statements")
                })
            })
        }
    }

    render() {
        let progressBar = null
        let startCourse = ""

        if (this.props.showProgressBar) {
            if (this.state.progress === 0) {
                startCourse = "Start Course"
            }

            if (this.state.progress === 100) {
                progressBar =   <Stack id="course-grid-item-completed-progress" tokens={CourseGridItemStyle.stackTokens} styles={CourseGridItemStyle.bodyStyle}>
                                    <ProgressIndicator percentComplete={this.state.progress/100} barHeight={4}/>
                                    <Stack styles={CourseGridItemStyle.bodyStyle} horizontal={true}>
                                            <Icon id="completedIcon" iconName="Completed" styles={CourseGridItemStyle.completedIcon} />
                                            <div id="completedLabel" style={CourseGridItemStyle.completedDiv}>
                                                {this.i18n.get(i18nKey.completed)}
                                            </div>
                                    </Stack>
                                </Stack>
            }

            else {
                progressBar =   <Stack id="course-grid-item-progressed-progress" tokens={CourseGridItemStyle.stackTokens} styles={CourseGridItemStyle.footerStyle}>
                                    <ProgressIndicator className="progress-indicator-component-progressed" description={this.state.progress + this.i18n.get(i18nKey.percentComplete)} percentComplete={this.state.progress/100} barHeight={4}/>
                                </Stack>
            }
        }

        return (
            <div className='course-grid-item' id={this.props.course.documentId} onClick={this.onCourseClickHandler}>
                <Stack styles={CourseGridItemStyle.containerStyle}>
                    <Stack styles={CourseGridItemStyle.headerStyle}>
                        <Image 
                            id="course-grid-item-course-image"
                            src={this.props.course.imageUrl}
                            alt={this.props.course.name + " course"} 
                            imageFit={ImageFit.centerCover} 
                            styles={CourseGridItemStyle.imageStyle}
                        />
                    </Stack>
                    <Stack tokens={CourseGridItemStyle.stackTokens} styles={CourseGridItemStyle.bodyStyle}>
                        <Text id="course-grid-item-course-name" variant={FontSize.large}>{this.props.course.name}</Text>
                    </Stack>
                    {progressBar}
                    <Stack horizontal={true} tokens={CourseGridItemStyle.stackTokens} styles={CourseGridItemStyle.footerStyle}>
                    { startCourse !== "" && 
                        <Text id="course-grid-item-start-course-indicator" variant={FontSize.medium} styles={CourseGridItemStyle.startCourseText}>{startCourse}</Text>
                    }
                    { this.renderCardFooter() }
                    </Stack>
                </Stack>
            </div>
        )
    }

    onCourseClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (this.props.showProgressBar && this.auth.user !== undefined) {
            const launchLocation = "courses"
            
            let user = this.auth.user
            let sharedClientIds = this.mapCourseClientsToUserClients()
            sharedClientIds.forEach(client => {
                this.lrsService
                    .postTrainingLaunchLocation(user.uid, client, this.props.course.lrsId, launchLocation)
                    .then(() => {
                        log("postTrainingLaunchLocation success")
                    }).catch(error => {
                        log("postTrainingLaunchLocation failed: " + error)
                    })
            })
        }
        
        this.props.onCourseClick(e)
    }

    private mapCourseClientsToUserClients(): DocumentId[] {
		let sharedClientIds: DocumentId[] = []

        if (!this.props.userClients) {
            return sharedClientIds
        }

		this.props.userClients.forEach(potentialClient => {
			if (this.props.course.clients[potentialClient] !== undefined) {
				sharedClientIds.push(potentialClient)
			}
		});

		return sharedClientIds
	}

    private renderCardFooter(): ReactElement {
        let deadline;
        if (this.featureFlagService.deadline() && this.props.deadline && this.state.progress < 100) {
            deadline = this.props.deadline
            let deadlineCopy = this.deadlinesHelper.renderTrainingDeadline(deadline)
            if (deadlineCopy !== "") {
                return  <Text 
                            id="course-grid-item-course-deadline" 
                            variant={FontSize.medium} 
                            styles={CourseGridItemStyle.deadlineStyle}
                        >
                            {deadlineCopy}
                        </Text>
            }
        }
         
        return <Text id="course-grid-item-course-duration" variant={FontSize.medium}>{this.props.course.duration}</Text>
    }
}