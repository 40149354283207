import { UserStatus } from './../models/User';
import { IFirebase } from "./Firebase"
import User, { UserDocumentKey } from "../models/User"
import { injectable } from "inversify"
import { RoleType } from "../models/RoleType"
import { container } from "../DIContainer"
import { TYPES } from "../Types"
import Client from "../models/Client"
import { log } from "../global/Logger"
import { UserParser } from "../models/UserParser"
import { Dictionary, DocumentId } from "../global/TypeAliases"
import { IEventReportingService } from '../services/EventReportingService'
import firebase from 'firebase'

export interface IUserService {
	/**
	 * Get user document for a user
	 * @param uid Firebase uid
	 */
	getUser(uid: string): Promise<User>

	/**
	 * Checks both firebase authentication and firestore for an account with the 
	 * email provided
	 * @param email The email
	 */
	emailInUse(email: string): Promise<boolean>
	
	/**
	 * Get all users
	 * @return An array of Users
	 */
	users(): Promise<User[]>

	/**
	 * Get all users for a client
	 * @return An array of Users
	 */
	usersForClient(clientId: string): Promise<User[]>

	/**
	 * Update user details from admin panel
	 * @param documentId The user document id
	 * @param role The user role
	 * @param clientManaged The client the user will manage, if available
	 */
	updateUser(
		documentId: string,
		role: RoleType,
		clientManaged: string | undefined
	): Promise<void>

	/**
	 * Delete a user (soft delete)
	 * @param email Email of user to delete
	 */
	deleteUser(email: string): Promise<void>

	/**
	 * Update properties for a user
	 * @param firebaseUser The firebase user
	 * @param user The user document
	 * @param firstName The first name
	 * @param lastName The last name
	 */
	updateCurrentUser(
		firebaseUser: firebase.User,
		user: User,
		firstName: string,
		lastName: string
	): Promise<void>

	/**
     * Update the profile picture
     * @param firebaseUser The firebase user logged in
     * @param imageUrlString The url string of the new image
     */
	updateProfilePicture(firebaseUser: firebase.User, imageUrlString: string): Promise<firebase.User>

	/**
	 * Search users
	 * @param searchTerm The search term
     * @param userDocumentKey The user document key to search on
	 * @return An array of Users
	 */
	searchUsers(searchTerm: string, userDocumentKey: UserDocumentKey): Promise<User[]>

	/**
	 * Search users within a client 
	 * @return An array of Users
	 */
	searchUsersWithin(clientId: string): Promise<User[]>

	/**
	 * Get a user document by email address
	 * @param email The email address
	 */
	getUserWithEmail(email: string): Promise<User | null>

	/**
	 * Get clients associated with a particular user
	 * @param cohorts A dictionary of cohorts the user belongs to
	 */
	getClientsForUser(cohorts: Dictionary): Promise<string[]>

	 /**
	  * Check and see if the user record with the email is a clever user
	  * @param email The email address
	  */
	 isCleverUser(email: string): Promise<boolean>

	 /**
	  * Gets all users that belong to a specific client and domain that all
	  * have the same account status
	  * @param domain The client domain
	  * @param status The user account status 
	  */

	 getUsersByStatusForDomain(domain: string, status: UserStatus): Promise<User[]> 

	/**
	* Verifies that the user is part of an active client
	* If the client is active, returns true. Otherwise, it returns false.
	* @return A boolean representing if user is part of an active client
	*/
	verifyUserClientIsActive(userCohorts: Dictionary, role: RoleType): Promise<boolean> 

	/**
	 * Updates the attribute on the user document, `tempPassword` to false,
	 * indicating the user has updated their password from the temporary one
	 * assigned at account creation
	 * @param documentId users document Id
	 */
	updateUserTempPasswordStatusFor(documentId: DocumentId): Promise<void>
}

@injectable()
export default class UserService implements IUserService {
	private firebase = container.get<IFirebase>(TYPES.IFirebase)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)

	getUser(uid: string): Promise<User> {
		let noUserFoundError = new Error("No user found")
	
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection("user")
				.where("uid", "==", uid)
				.withConverter(User.Converter)
				.get()
				.then(documentSnapshots => {
					if (documentSnapshots.docs.length === 0) {
						log('UserService - getUser - no user found for ' + uid)
						throw noUserFoundError
					}

					let user = documentSnapshots.docs[0].data()
					user.documentId = documentSnapshots.docs[0].id
					resolve(user)
				})
				.catch(error => {
					if (error !== noUserFoundError) {
						this.eventReportingService.error(`${error.message} - ${uid}`, error)
					}

					log("Error: UserService - getCurrentUser() - " + error)
					reject(error)
				})
		})
	}

	emailInUse(email: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const promises: Promise<any>[] = [
				this.firebase.db.collection("user").where("email", "==", email).withConverter(User.Converter).get(),
				this.firebase.auth.fetchSignInMethodsForEmail(email)
			]

			Promise.all(promises).then(result => {
				const userQuery = result[0]
				const signInMethods = result[1]
				
				const exists = userQuery.docs.length > 0 || signInMethods.length > 0
				resolve(exists)
			}).catch(error => {
				reject(error)
			})
		})
	}

	updateUser(
		documentId: string,
		role: RoleType,
		clientManaged: string | undefined
	): Promise<void> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection("user")
				.doc(documentId)
				.update({
					role: role,
					clientManaged: clientManaged === undefined ? null : clientManaged,
				})
				.then(() => {
					log("Success: UserService - updateUser()")
					resolve()
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("Error: UserService - updateUser() - " + error)
					reject(error)
				})
		})
	}

	/* istanbul ignore next */
	deleteUser(email: string): Promise<void> {
		let deleteUser = this.firebase.app.functions().httpsCallable("deleteUser")
		return new Promise((resolve, reject) => {
			deleteUser({ email: email })
				.then((result) => {
					log("UserService - deleteUser - Success")
					resolve()
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log(`UserService - deleteUser - Failed - ${error}`)
					reject(error)
				})
		})
	}

	updateCurrentUser(
		firebaseUser: firebase.User,
		user: User,
		firstName: string,
		lastName: string
	): Promise<void> {
		return new Promise((resolve, reject) => {

			const fbUserPromise = firebaseUser.updateProfile({
				displayName: `${firstName} ${lastName}`,
			})

			const userPromise = this.firebase.db
									.collection('user')
									.doc(user.documentId)
									.update({
										firstName: firstName,
										lastName: lastName,
									})

			Promise.all([fbUserPromise, userPromise]).then(() => {
				resolve()
			}).catch(error => {
                this.eventReportingService.error(error.message, error)
				reject(error)
			})
		})
	}

	users(): Promise<User[]> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection("user")
				.withConverter(User.Converter)
				.get()
				.then((querySnapshot) => {
					let users: User[] = []
					querySnapshot.forEach((doc) => {
						let user = doc.data()
						user.documentId = doc.id
						users.push(user)
					})

					resolve(users)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("UserService - users() - Failed - " + error)
					reject(error)
				})
		})
	}

	usersForClient(clientId: string): Promise<User[]> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection('client')
				.doc(clientId)
				.withConverter(Client.Converter)
				.get()
				.then(documentSnapshot => {
					const client: Client | undefined = documentSnapshot.data()

					if (client === undefined) {
						throw new Error("No client in document snapshot")
					}

					const domain = client.domain()
					if (domain === null) {
						throw new Error("No domain listed for client")
					}
					
					return this.firebase.db
								.collection('user')
								.where(`domains.${domain}`, '==', true)
								.withConverter(User.Converter)
								.get()
				})
				.then(querySnapshot => {
					let users: User[] = []

					querySnapshot.forEach(doc => {
						let user = doc.data()
						user.documentId = doc.id
						users.push(user)
					})

					resolve(users)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log(`UserService - UsersForClient - Failed: ${error.message}`)
					reject(error)
				})
		})
	}

	updateProfilePicture(firebaseUser: firebase.User, imageUrlString: string): Promise<firebase.User> {
		return new Promise((resolve, reject) => {
			firebaseUser
			.updateProfile({ photoURL: imageUrlString })
			.then(() => {
				resolve(firebaseUser)
			}).catch(error => {
                this.eventReportingService.error(error.message, error)
				reject(error)
			})
		})
	}

	searchUsers(searchTerm: string, userDocumentKey: UserDocumentKey): Promise<User[]> {
		if (userDocumentKey === UserDocumentKey.Email) {
            searchTerm = searchTerm.toLowerCase()
        }

		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection("user")
				.withConverter(User.Converter)
				.where(userDocumentKey, "==", searchTerm)
				.get()
				.then((querySnapshot) => {
					let users: User[] = []

					querySnapshot.forEach((doc) => {
						let user = doc.data()
						user.documentId = doc.id
						users.push(user)
					})

					log("UserService - searchUsers() - Success")
					resolve(users)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("UserService - searchUsers() - Failed - " + error)
					reject(error)
				})
		})
	}

	searchUsersWithin(clientId: string): Promise<User[]> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection('client')
				.doc(clientId)
				.withConverter(Client.Converter)
				.get()
				.then(documentSnapshot => {
					const client: Client | undefined = documentSnapshot.data()

					if (client === undefined) {
						throw new Error("No client in document snapshot")
					}

					const domain = client.domain()

					if (domain === null) {
						throw new Error("No domain listed for client")
					}
					
					return this.firebase.db
								.collection('user')
								.where(`domains.${domain}`, '==', true)
								.withConverter(User.Converter)
								.get()
				})
				.then(querySnapshot => {
					let users: User[] = []

					querySnapshot.forEach(doc => {
						let user = doc.data()
						user.documentId = doc.id
						users.push(user)
					})

					resolve(users)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					reject(error)
				})
		})
	}

	/* istanbul ignore next */
	getUserWithEmail(email: string): Promise<User | null> {
		let getUser = this.firebase.app.functions().httpsCallable("getUserWithEmail")

		return new Promise((resolve, reject) => {
			getUser({ email: email })
				.then((result) => {
					log("UserService - getUserWithEmail - Success")
					const data = result.data.documentData
					const documentId = result.data.documentId

					if (!data) {
						resolve(null)
					} else {
						// Firebase returns the timestamp as a generic Object, so we are recasting it as a proper
        				// Firestore.Timestamp in order to use the built in functions.
                        const timestamp = data.createdAt === undefined ? undefined : new firebase.firestore.Timestamp(data.createdAt._seconds, data.createdAt._nanoseconds)
                        data.createdAt = timestamp
						const parser = new UserParser()
						const user = parser.parse(documentId, data)
						resolve(user)
					}

				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log(`UserService - getUserWithEmail - Failed - ${error}`)
					reject(error)
				})
		})
	}

	/* istanbul ignore next */
	getClientsForUser(cohorts: Dictionary): Promise<string[]> {
		let getClients = this.firebase.app.functions().httpsCallable("clientsForUser")
		
		const keys = Object.keys(cohorts)

		if (keys.length === 0) {
			return Promise.resolve([])
		}

		/**
		 * Filter out any 'null' keys
		 */
		const cleanedKeys = keys.filter(key => key !== 'null')

		if (cleanedKeys.length === 0) {
			return Promise.resolve([])
		}

		return new Promise((resolve, reject) => {
			getClients({cohorts : cleanedKeys})
			.then((result) => {
				log("UserService - getClientsForUser - Success")
				const data = result.data
				
				if (!data) {
					resolve([])
				} 
				
				else {
					resolve(data)
				}
			})
			.catch(error => {
                this.eventReportingService.error(error.message, error)
				log(`UserService - getClientsForUser - Failed - ${error}`)
				reject(error)
			})
		})

	}

	/* istanbul ignore next */
	isCleverUser(email: string): Promise<boolean> {
		let isCleverUser = this.firebase.app.functions().httpsCallable("isCleverUser")

		return new Promise((resolve, reject) => {
			isCleverUser({ email: email })
				.then(result => {
					log("UserService - isCleverUser - Success")
					resolve(result.data.isCleverUser)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log(`UserService - isCleverUser - Failed - ${error}`)
					reject(error)
				})
		})
	}

	getUsersByStatusForDomain(domain: string, status: UserStatus): Promise<User[]> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection('user')
				.where(`domains.${domain}`, '==', true)
				.where(`status`, '==', status)
				.withConverter(User.Converter)
				.get()
				.then((querySnapshots) => {
					let users: User[] = []

					if (querySnapshots.docs.length === 0) {
						throw new Error("No users found")
					}

					querySnapshots.docs.forEach(doc => { 
						let user = doc.data()
						user.documentId = doc.id						
						users.push(user)
					})
		
					resolve(users)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					reject(error)
				})
		})
	}

	verifyUserClientIsActive(userCohorts: Dictionary, role: RoleType): Promise<boolean> {
		if (role === RoleType.globalAdmin) {
			return Promise.resolve(true)
		}

		const cohorts = Object.keys(userCohorts)

		if (cohorts.length === 0) {
			return Promise.resolve(false)
		}

		/**
		 * Filter out any 'null' keys
		 */
		const cleanedKeys = cohorts.filter(key => key !== 'null')

		if (cleanedKeys.length === 0) {
			return Promise.resolve(false)
		}

		return new Promise ((resolve, reject) => {
			const collectionPath = this.firebase.db.collection('client')
			const batches = [];

			while(cohorts.length) {
				const batch = cohorts.splice(0,10)

				batches.push(
					collectionPath
						.where(`defaultCohort`, 'in', [...batch])
						.withConverter(Client.Converter)
						.get()
						.then(results => results.docs.map(result => ({...result.data()})))
				)
			}

			Promise.all(batches)
				.then((result) => {
					const clients = result.flat()
					

					if (clients.length === 0) {
						throw new Error("No client found - verifyUserClientIsActive()")
					}

					clients.forEach(client => {
						if (client.active) {
							resolve(true)
						}
					})

					resolve(false)
				}).catch(error => {
					this.eventReportingService.error(error.message, error)
					reject(error)
				})
		})
	}

	updateUserTempPasswordStatusFor(documentId: DocumentId): Promise<void> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection("user")
				.doc(documentId)
				.update({
					tempPassword: false
				})
				.then(() => {
					log("Success: UserService - updateUserTempPasswordStatusFor()")
					resolve()
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("Error: UserService - updateUserTempPasswordStatusFor() - " + error)
					reject(error)
				})
		})
	}
}