import { injectable } from "inversify";
import { v4 } from "uuid"

export interface UUID {
    uuid(): string

    /**
     * Generate a uuid v4 format string.
     * This is used to generate the id in custom LRS Statements.
     */
    uuidv4(): string
}

@injectable()
export class UUIDGenerator implements UUID {
    uuid(): string {
        return '_' + Math.random().toString(36).substring(2, 9)
    }

    uuidv4(): string {
        return v4()
    }
}