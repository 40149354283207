import React, { Component } from "react";
import {
	Stack,
	MessageBar,
	MessageBarType,
	IStackStyles,
	Text
} from "@fluentui/react";
import "./ReportUserSurveyDetailsModal.css";
import Client from "../../models/Client";
import { TextField } from '../../ui-kit/TextField'
import { Survey } from "../../models/Survey";
import { container } from "../../DIContainer";
import { TYPES } from "../../Types";
import { i18nKey, Ii18n } from "../../global/i18n";
import { Dictionary, DocumentId, Uid } from "../../global/TypeAliases";
import { IReportUserSurveyDetailsModalCompositionService } from "../../services/ReportUserSurveyDetailsModalCompositionService";
import { Survey as SurveyRenderer } from 'survey-react'
import { FontSize } from "../../ui-kit/FontSize";
import PageTitle from "../../ui-kit/PageTitle";

interface ReportUserSurveyDetailsModalProps {
	userId: Uid;
	clientId: DocumentId;
	survey: Survey;
	details: { [key: string]: string };
}

interface ReportUserSurveyDetailsModalState {
	closeButtonDisabled: boolean;
	errorMessage: string | undefined;
	successMessage: string | undefined;
	selectedClient: Client | undefined;
	numberOfAttempts: number
	loadedSurvey: Survey | null
    loadedSurveyState: Dictionary
}

export class ReportUserSurveyDetailsModal extends Component<ReportUserSurveyDetailsModalProps, ReportUserSurveyDetailsModalState> {
	private compositionService: IReportUserSurveyDetailsModalCompositionService = container.get<IReportUserSurveyDetailsModalCompositionService>(TYPES.IReportUserSurveyDetailsModalCompositionService)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    
	state: ReportUserSurveyDetailsModalState = {
		closeButtonDisabled: false,
		errorMessage: undefined,
		successMessage: undefined,
		selectedClient: undefined,
		numberOfAttempts: 0,
		loadedSurvey: null,
		loadedSurveyState: {}
	};

	private stackStyling: IStackStyles = {
        root: {
            margin: "10%",
            paddingTop: 50,
            borderRadius: 10,
            backgroundColor: '#ffffff'
        }
    }

	private outerStackStyling: IStackStyles = {
		root: {
			paddingLeft: 50,
			paddingRight: 50,
			paddingBottom: 50,
			width: "100%",
		}
	}

    componentDidMount() {
        this.load()
    }

	render() {
		
		return (
			<div id="report-user-survey-details-modal-container">
				<PageTitle title={this.i18n.get(i18nKey.learnerReport)} />
				<Stack id="report-user-survey-details-stack" styles={this.outerStackStyling}>

                    {this.state.errorMessage === undefined &&
                        <>
                            <TextField
                                label={this.i18n.get(i18nKey.name)}
                                value={this.props.details.name}
                                readOnly={true}
                            />
                            <TextField
                                label={this.i18n.get(i18nKey.emailAddress)}
                                value={this.props.details.emailAddress}
                                readOnly={true}
                            />
                            <TextField
                                label={this.i18n.get(i18nKey.numberOfAttempts)}
                                value={this.state.numberOfAttempts.toString()}
                                readOnly={true}
                            />
                            <TextField
                                label={this.i18n.get(i18nKey.completedSurveyQuestionLabel)}
                                value={this.props.details.completed}
                                readOnly={true}
                            />

							{ this.state.loadedSurvey && this.props.details.completed.toLowerCase() !== "no" && this.state.loadedSurvey.pages.length > 0 && this.props.survey.anonymous !== true &&
								<Stack id="survey-container" horizontalAlign="center" styles={this.stackStyling}>
									<Text variant={FontSize.xLarge}>{this.state.loadedSurvey.name}</Text>
									<SurveyRenderer 
										id="survey-renderer"
										json={this.state.loadedSurvey.pages[0].toJSON()} 
										mode={"display"}
									/>
								</Stack>
                			} 
                        </>
                    }
					{this.createBanner()}
				</Stack>
			</div>
		);
	}

    private load = () => {
		this.compositionService
			.getData(this.props.userId, this.props.clientId, this.props.survey)
			.then(data => {
				this.setState(
					{
						numberOfAttempts: data.numberOfAttempts,
						loadedSurvey: data.survey,
						loadedSurveyState: data.surveyState
					}
				)
			}).catch(error => {
                let errorMessage = this.i18n.get(i18nKey.reportUserSurveyDetailsFailedToLoadAttempts)

                /**
                 * Code 66 means the client id doesn't exist in the LRS
                 */
                if (error.message.includes("code 66")) {
                    errorMessage = error.message
                }

                this.setState({
                    errorMessage: errorMessage 
                })
            })
    }

	dismissBanner = () => {
		this.setState({ errorMessage: undefined, successMessage: undefined });
	};

	createBanner = () => {
		if (this.state.errorMessage) {
			return (
				<MessageBar
					id="report-user-survey-details-modal-error-banner"
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.errorMessage}
				</MessageBar>
			);
		} else if (this.state.successMessage) {
			return (
				<MessageBar
					id="report-user-survey-details-modal-success-banner"
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			);
		}

		return null;
	};
}