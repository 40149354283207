import { IAuth } from '../auth/Auth';
import { IUserService } from './UserService';
import { Subject } from "rxjs";
import User from "../models/User";
import { container } from '../DIContainer';
import { TYPES } from '../Types';
import { injectable } from 'inversify';
import { IEventReportingService } from '../services/EventReportingService'

export interface IUserRepository {
    /**
     * Observable to subcribe for updates to user document
     */
    user: Subject<User | null>

    /**
     * Observable to subscribe to for updates to firebase.User
     */
    firebaseUser: Subject<firebase.User | null>
    
    /**
     * A proxy to UserService.updateCurrentUser with the extra responsibility of 
     * pumping our observables with the latest user and firebase.user
     * @param firebaseUser The firebase user
     * @param user The user document
     * @param firstName The first name
     * @param lastName The last name
     */
    updateCurrentUser(
		firebaseUser: firebase.User,
		user: User,
		firstName: string,
		lastName: string
    ): Promise<void>
    
    /**
     * Update the profile picture
     * @param firebaseUser The firebase user logged in
     * @param imageUrlString The url string of the new image
     */
    updateProfilePicture(firebaseUser: firebase.User, imageUrlString: string): Promise<firebase.User>
}

@injectable()
export class UserRepository implements IUserRepository {
    private userService: IUserService = container.get<IUserService>(TYPES.IUserService)
    private auth: IAuth = container.get<IAuth>(TYPES.IAuth)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)

    user: Subject<User | null> = new Subject()
    firebaseUser: Subject<firebase.User | null> = new Subject()

    updateCurrentUser(
		firebaseUser: firebase.User,
		user: User,
		firstName: string,
		lastName: string
	): Promise<void> {
        return new Promise((resolve, reject) => {
            this.userService
                .updateCurrentUser(firebaseUser, user, firstName, lastName)
                .then(() => {
                    const reloadFirebaseUser = firebaseUser.reload()
                    const reloadUser = this.userService.getUser(firebaseUser.uid)

                    return Promise.all([reloadFirebaseUser, reloadUser])
                })
                .then(result => {
                    const user = result[1]

                    this.firebaseUser.next(this.auth.firebaseUser())

                    if (user !== undefined) {
                        this.user.next(user)
                    }

                    resolve()
                })
                .catch(error => {
                    this.eventReportingService.error(error.message, error)
                    reject(error)
                })
        })
    }

    updateProfilePicture(firebaseUser: firebase.User, imageUrlString: string): Promise<firebase.User> {
        return new Promise((resolve, reject) => {
            this.userService
                .updateProfilePicture(firebaseUser, imageUrlString)
                .then(_ => {
                    const reloadFirebaseUser = firebaseUser.reload()
                    const reloadUser = this.userService.getUser(firebaseUser.uid)

                    return Promise.all([reloadFirebaseUser, reloadUser])
                })
                .then(result => {
                    const user = result[1]

                    this.firebaseUser.next(this.auth.firebaseUser())

                    if (user !== undefined) {
                        this.user.next(user)
                    }

                    resolve(firebaseUser)
                })
                .catch(error => {
                    this.eventReportingService.error(error.message, error)
                    reject(error)
                })
        })
    }
}