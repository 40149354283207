import React, { Component, FormEvent } from 'react'
import { 
    Stack,
    Text, 
    TextField, 
    MessageBar,
    MessageBarType, 
    Checkbox
} from '@fluentui/react'
import { container } from '../../DIContainer'
import { TYPES } from '../../Types'
import { MarketingFormStyles } from '../../ui-kit/MarketingFormStyles'
import { IMailService } from '../../services/MailService'
import { MarketingButton } from '../../ui-kit/MarketingButton'
import { Dictionary } from '../../global/TypeAliases'
import { i18nKey, Ii18n } from '../../global/i18n'

interface PrivacyRequestFormProps {}
interface PrivacyRequestFormState {
    firstName: string
    lastName: string
    administrator: string
    email: string
    seeDataRequest: boolean | undefined
    deleteDataRequest: boolean | undefined
    optOutOfSaleRequest: boolean | undefined
    errorMessage: string | undefined
    successMessage: string | undefined
}

const stackTokens = { childrenGap: 10, padding: 10 };

export class PrivacyRequestForm extends Component<PrivacyRequestFormProps, PrivacyRequestFormState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private mailService: IMailService = container.get<IMailService>(TYPES.IMailService)

    private mailTo = `mailto:${this.i18n.get(i18nKey.privacyEmailAddress)}`

    state: PrivacyRequestFormState = {
        firstName: '',
        lastName: '',
        administrator: '',
        email: '',
        seeDataRequest: false,
        deleteDataRequest: false,
        optOutOfSaleRequest: false,
        errorMessage: undefined,
        successMessage: undefined
    }

    render() {
        return (
            <Stack
                id='privacy-request-form'
                horizontal
                horizontalAlign='space-around'
            >
                <Stack styles={MarketingFormStyles.privacyFormStackStyles}>
                    <Text styles={MarketingFormStyles.formHeaderTextStyles}>
                        {this.i18n.get(i18nKey.submitPrivacyRequest)}
                    </Text>
                    
                    <form onSubmit={this.onFormSubmit}>
                        <TextField
                            label={this.i18n.get(i18nKey.firstName)}
                            styles={MarketingFormStyles.textFieldStyles}
                            onChange={this.firstNameTextFieldChanged}
                            value={this.state.firstName}
                            required
                            type='text'
                        />
                        <TextField
                            label={this.i18n.get(i18nKey.lastName)}
                            styles={MarketingFormStyles.textFieldStyles}
                            onChange={this.lastNameTextFieldChanged}
                            value={this.state.lastName}
                            required
                            type='text'
                        />
                        <TextField
                            label={this.i18n.get(i18nKey.emailAddress)}
                            styles={MarketingFormStyles.textFieldStyles}
                            onChange={this.emailTextFieldChanged}
                            value={this.state.email}
                            required
                            type='email'
                        />
                        <TextField
                            label={this.i18n.get(i18nKey.administrator)}
                            styles={MarketingFormStyles.textFieldStyles}
                            onChange={this.administratorTextFieldChanged}
                            value={this.state.administrator}
                            type='text'
                        />

                        <Stack tokens={stackTokens}>
                            <Checkbox 
                                label={this.i18n.get(i18nKey.seeDataHeldCheckboxText)} 
                                checked={this.state.seeDataRequest} 
                                onChange={this.seeDataRequestFieldChanged}
                            />
                            <Checkbox 
                                label={this.i18n.get(i18nKey.deleteDataHeldCheckboxText)}
                                checked={this.state.deleteDataRequest} 
                                onChange={this.deleteDataRequestFieldChanged}
                            />
                            <Checkbox 
                                label={this.i18n.get(i18nKey.optOutOfSaleCheckboxText)} 
                                checked={this.state.optOutOfSaleRequest} 
                                onChange={this.optOutDataRequestFieldChanged}
                            />
                        </Stack>

                        { this.state.errorMessage !== undefined && (
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                dismissButtonAriaLabel="Close"
                                onDismiss={this.dismissBanner}
                                styles= {MarketingFormStyles.messageBarStyles}
                            >
                                {this.state.errorMessage}
                                <a href={this.mailTo}>
                                    {this.i18n.get(i18nKey.privacyEmailAddress)}
                                </a>
                            </MessageBar>
                        )}

                        { this.state.successMessage !== undefined && (
                            <MessageBar
                                messageBarType={MessageBarType.success}
                                dismissButtonAriaLabel="Close"
                                onDismiss={this.dismissBanner}
                                styles= {MarketingFormStyles.messageBarStyles}
                            >
                                {this.state.successMessage}
                            </MessageBar>
                        )}
                        
                        <MarketingButton
                            id="privacy-form-submit-button"
                            text={this.i18n.get(i18nKey.submit).toUpperCase()}
                            type='submit'
                            width='100%'
                        />
                    </form>
                </Stack>
            </Stack>
        )
    }

    dismissBanner = () => {
		this.setState({ errorMessage: undefined, successMessage: undefined })
	}

    onFormSubmit = (e: FormEvent) => {
        e.preventDefault()
        let formData: Dictionary = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            administrator: this.state.administrator,
            seeDataRequest: this.state.seeDataRequest,
            optOutOfSaleRequest: this.state.optOutOfSaleRequest,
            deleteDataRequest: this.state.deleteDataRequest
        }

        this.mailService
            .sendPrivacyRequestEmail(formData)
            .then(() => {
                this.setState({ 
                    firstName: '',
                    lastName: '',
                    email: '',  
                    administrator: '',
                    seeDataRequest: false,
                    optOutOfSaleRequest: false,
                    deleteDataRequest: false,
                    errorMessage: undefined,
                    successMessage: this.i18n.get(i18nKey.privacyFormSuccessMessage)
                })
            })
            .catch(_ => {
                this.setState({errorMessage: this.i18n.get(i18nKey.privacyFormErrorMessage)})
            })
    }

    firstNameTextFieldChanged = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ firstName: newValue ? newValue : '' })
    }

    lastNameTextFieldChanged = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({lastName: newValue ? newValue : '' })
    }

    administratorTextFieldChanged = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ administrator: newValue ? newValue : '' })
    }

    emailTextFieldChanged = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ email: newValue ? newValue : '' })
    }

    seeDataRequestFieldChanged = (ev?: FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean): void => {
        this.setState({ seeDataRequest: checked })
    }

    deleteDataRequestFieldChanged = (ev?: FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean): void => {
        this.setState({ deleteDataRequest: checked })
    }

    optOutDataRequestFieldChanged = (ev?: FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean): void => {
        this.setState({ optOutOfSaleRequest: checked })
    }
}

