import { generate as createPassword }  from 'generate-password'
import { injectable } from 'inversify'
import * as crypto from 'crypto'

export interface IPasswordGenerator {
    generate(): PasswordData
}

export interface PasswordData {
    original: string
    hash: string
}

@injectable()
export class PasswordGenerator implements IPasswordGenerator {
    generate(): PasswordData {
        const rules = {
            length: 16, 
            numbers: true
        }

        const password = createPassword(rules)
        const sha = crypto.createHmac("sha256", "cm-dts")
        const hash = sha.update(password).digest("hex")
        
        return {
            original: password,
            hash: hash
        }
    }
}