import React, { Component } from 'react'
import { Stack, Text } from '@fluentui/react'
import { MarketingIi18n, i18nKey } from '../global/Marketing-i18n'
import { container } from '../DIContainer'
import { TYPES } from '../Types'
import { FeaturesDifferentiatorsStyles } from './FeaturesDifferentiatorsStyles'

export class FeaturesDifferentiators extends Component {
    private i18n = container.get<MarketingIi18n>(TYPES.MarketingIi18n)

    render() {
        return (
            <Stack styles={FeaturesDifferentiatorsStyles.outerStackStyles}>

                <Text styles={FeaturesDifferentiatorsStyles.sectionNameTextStyles}>
                    {this.i18n.get(i18nKey.featuresDifferentiatorsSectionName).toUpperCase()}
                </Text>

                <Stack horizontal wrap horizontalAlign="space-between">

                    <Stack styles={FeaturesDifferentiatorsStyles.innerLeftStackStyles}>
                        <Text styles={FeaturesDifferentiatorsStyles.headerTextStyles}>
                            {this.i18n.get(i18nKey.featuresDifferentiatorsHeader)}
                        </Text>

                        <Text styles={FeaturesDifferentiatorsStyles.descriptionTextStyles}>
                            {this.i18n.get(i18nKey.featuresDifferentiatorsParagraph1)}
                        </Text>

                        <Text styles={FeaturesDifferentiatorsStyles.descriptionTextStyles}>
                            {this.i18n.get(i18nKey.featuresDifferentiatorsParagraph2)}
                        </Text>
                    </Stack>

                    <Stack styles={FeaturesDifferentiatorsStyles.innerRightStackStyles}>

                        <Text styles={FeaturesDifferentiatorsStyles.subHeaderTextStyles}>
                            {this.i18n.get(i18nKey.featuresDifferentiatorsSubHeader).toUpperCase()}
                        </Text>

                        <Stack styles={FeaturesDifferentiatorsStyles.listStackStyles}>
                            <li>
                                <strong>{this.i18n.get(i18nKey.featuresDifferentiatorsBullet1Title)}</strong>
                                {this.i18n.get(i18nKey.featuresDifferentiatorsBullet1Text)}
                            </li>
                            
                            <li>
                                <strong>{this.i18n.get(i18nKey.featuresDifferentiatorsBullet2Title)}</strong>
                                {this.i18n.get(i18nKey.featuresDifferentiatorsBullet2Text)}
                            </li>

                            <li>
                                <strong>{this.i18n.get(i18nKey.featuresDifferentiatorsBullet3Title)}</strong>
                                {this.i18n.get(i18nKey.featuresDifferentiatorsBullet3Text)}
                            </li>

                            <li>
                                <strong>{this.i18n.get(i18nKey.featuresDifferentiatorsBullet4Title)}</strong>
                                {this.i18n.get(i18nKey.featuresDifferentiatorsBullet4Text)}
                            </li>
                        </Stack>

                    </Stack>

                </Stack>
            </Stack>
        )
    }
}
