import { Stack, Text } from "@fluentui/react";
import { Component, FormEvent } from "react";
import { Question, QuestionTextModel } from "survey-react";
import { container } from "../../../../DIContainer";
import { Ii18n, i18nKey } from "../../../../global/i18n";
import { Survey } from "../../../../models/Survey";
import { TYPES } from "../../../../Types";
import { FontSize } from "../../../../ui-kit/FontSize";
import { TextField } from "../../../../ui-kit/TextField";
import { ToggleControl } from "../../../../ui-kit/ToggleControl";

interface ShortTextPropertiesPanelProps {   
    survey: Survey
    model: QuestionTextModel
    didUpdateSurvey: (survey: Survey) => void
    toggleRequireInteraction: (survey: Survey, model: Question, checked: boolean) => void
}

interface ShortTextPropertiesPanelState {}

export class ShortTextPropertiesPanel extends Component<ShortTextPropertiesPanelProps, ShortTextPropertiesPanelState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    render() {
        return (
            <Stack tokens={{childrenGap: 20}}>
                <Text 
                    id="title-label"
                    variant={FontSize.xLarge}>
                        {this.i18n.get(i18nKey.shortTextProperties)}
                </Text>
                <TextField 
                    id="title-textfield"
                    type="text"
                    label={this.i18n.get(i18nKey.title)}
                    placeholder={this.i18n.get(i18nKey.surveyInteractionPlaceholder)}
                    onChange={this.titleTextFieldChanged}
                    required
                />
                <ToggleControl
                    id="required-toggle"
                    label={this.i18n.get(i18nKey.isRequired)}
                    checked={this.props.model.isRequired}
                    onChange={(_, checked) => this.requiredToggleChanged(checked)}
                />
            </Stack>
        )
    }

    /**
     * Private Functions
     */

    private titleTextFieldChanged = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.props.model.title = newValue? newValue : ""
        this.props.didUpdateSurvey(this.props.survey)
	}

    requiredToggleChanged = (checked?: boolean) => {
        if (checked !== undefined) {
            this.props.toggleRequireInteraction(this.props.survey, this.props.model, checked)
        }
    }
}