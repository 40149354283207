import { IStackStyles, ITextStyles } from '@fluentui/react'
import BackgroundImage from '../images/marketing/landing-hero-background.png'
import LaptopImage from '../images/marketing/landing-hero-laptop.png'

export class LandingHeroStyles {
    static outerStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    backgroundSize: 'auto 60%',
                },
            },
            backgroundImage: `url(${BackgroundImage})`,
            backgroundRepeat: 'no-repeat',
            paddingTop: '10vw',
            paddingBottom: '17vw',
            backgroundSize: 'contain',
        },
    }

    static leftContentStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    width: '100%',
                    paddingLeft: '8vw',
                    paddingRight: '9vw',
                    paddingBottom: '40vw',
                },
            },
            paddingTop: '3vw',
            paddingLeft: '12vw',
            width: '40%',
        },
    }

    static rightContentStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    height: '100vw',
                },
                '& video': {
                    height: '74%',
                    width: '100%',
                    objectFit: 'cover',
                    marginTop: '2.5vw',
                    marginLeft: '12vw',
                    selectors: {
                        '@media(max-width: 600px)': {
                            marginLeft: '28.5vw',
                            marginTop: '5.3vw',
                        },
                    },
                },
            },

            backgroundImage: `url(${LaptopImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            marginBottom: '-13vw',
            width: '60%',
            overflow: 'hidden',
        },
    }

    static headerStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '9vw',
                    marginBottom: '10vw',
                    marginTop: '10vw',
                },
            },
            fontFamily: 'Noto Sans JP, sans-serif',
            fontSize: '3vw',
            color: '#FFFFFF',
            fontWeight: '900',
        },
    }

    static subHeaderStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '5.5vw',
                    lineHeight: '8vw',
                    marginBottom: '10vw',
                    marginRight: '6vw',
                },
            },
            fontFamily: 'Lato, sans-serif',
            fontSize: '1.5vw',
            color: '#FFFFFF',
            opacity: '80%',
            lineHeight: '2vw',
            marginRight: '-4vw',
        },
    }
}
