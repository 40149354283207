/* istanbul ignore file */
import { injectable } from 'inversify'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/remote-config'
import 'firebase/storage'
import { RemoteDefaultConfig } from './FeatureFlagService'

const firebaseLocalConfig  = {
    apiKey: "AIzaSyB-hDNmIF1IEIkbc376JlVuJMXlmKBemZU",
    authDomain: "cm-lrs.firebaseapp.com",
    databaseURL: "https://cm-lrs.firebaseio.com",
    projectId: "cm-lrs",
    storageBucket: "cm-lrs.appspot.com",
    messagingSenderId: "241639198709",
    appId: "1:241639198709:web:af7b50ee1a0a4997325abc"
};

const firebaseDevConfig = {
    apiKey: "AIzaSyDPkVa5iYW8x_8GvUDAk84kTrB8EYOvHQQ",
    authDomain: "cm-dts-dev.firebaseapp.com",
    databaseURL: "https://cm-dts-dev.firebaseio.com",
    projectId: "cm-dts-dev",
    storageBucket: "cm-dts-dev.appspot.com",
    messagingSenderId: "558568738695",
    appId: "1:558568738695:web:d6e8d1549017cb8ea58d22",
    measurementId: "G-EEEW34VHQG"
};

const firebaseProdConfig = {
    apiKey: "AIzaSyCSfp-6Tv5jXmtDzl-QA_X8zEiGpIuAOSY",
    authDomain: "cm-dts-88915.firebaseapp.com",
    databaseURL: "https://cm-dts-88915.firebaseio.com",
    projectId: "cm-dts-88915",
    storageBucket: "cm-dts-88915.appspot.com",
    messagingSenderId: "108210588235",
    appId: "1:108210588235:web:2efb7214f0a9bbbf5adbce",
    measurementId: "G-1STFNYD7XH"
};

export interface IFirebase {
    app: firebase.app.App
    db: firebase.firestore.Firestore
    auth: firebase.auth.Auth
    storage: firebase.storage.Storage
    remoteConfig: firebase.remoteConfig.RemoteConfig

    userCredentials(email: string, currentPassword: string): firebase.auth.AuthCredential
}

export enum ENVIRONMENT {
    local = 'local',
    dev = 'development',
    prod = 'production',
    test = 'test'
}

@injectable()
export class Firebase implements IFirebase {
    app: firebase.app.App
    db: firebase.firestore.Firestore;
    auth: firebase.auth.Auth;
    storage: firebase.storage.Storage;
    remoteConfig: firebase.remoteConfig.RemoteConfig;

    constructor() {
        switch (process.env.REACT_APP_ENV) {
            case ENVIRONMENT.prod:
                this.app = firebase.initializeApp(firebaseProdConfig)
                break
            case ENVIRONMENT.dev:
                this.app = firebase.initializeApp(firebaseDevConfig)
                break
            default:
                this.app = firebase.initializeApp(firebaseLocalConfig)
        }

        this.db = firebase.firestore()
        this.auth = firebase.auth()
        this.storage = firebase.storage()
        this.remoteConfig = firebase.remoteConfig()

        switch (process.env.REACT_APP_ENV) {
            case ENVIRONMENT.prod:
                this.remoteConfig.defaultConfig = RemoteDefaultConfig.prod
                break
            case ENVIRONMENT.dev:
                this.remoteConfig.defaultConfig = RemoteDefaultConfig.dev
                break
            default:
                this.remoteConfig.settings.minimumFetchIntervalMillis = 3600;
                this.remoteConfig.defaultConfig = RemoteDefaultConfig.local
        }
        this.remoteConfig.fetchAndActivate()
        .then(() => {})
        .catch((err) => {})
    }

    public userCredentials(email: string, currentPassword: string): firebase.auth.AuthCredential {
        return firebase.auth.EmailAuthProvider.credential(email, currentPassword);
    }
}