import { injectable } from "inversify"
import queryString from "query-string"
import { log } from "../global/Logger"

export interface IInvitationCodeParser {
    /**
     * Parse out the code query parameter of an invitation URL
     * @param urlString The url string
     */
     getCode(urlString: string): string | null
}

@injectable()
export class InvitationCodeParser implements IInvitationCodeParser  {
    getCode(urlString: string): string | null {
        const components = urlString.split('?')

        if (components.length !== 2) {
            return null
        }

        const params = queryString.parse(components[1])
        const code = params['code']

        if (!code) {
            log("Unable to extract invitation code")
            return null
        }

        log("Invitation code: " + code)

        return code as string
    }
}
