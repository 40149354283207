import { injectable } from 'inversify';
import { UUID } from './../utils/UUIDGenerator';
import { IFirebase } from "./Firebase";
import { container } from "../DIContainer"
import { TYPES } from '../Types';
import { log } from '../global/Logger';
import { IEventReportingService } from '../services/EventReportingService'

export interface IFileUploader {
    upload(file: File, path: FileUploaderPath): Promise<string>
    uploadSurveyImage(file: File, name: string): Promise<string>
}

export enum FileUploaderPath {
    profileImage = 'profileImages',
    surveyImage = 'surveyImage'
}

@injectable()
export default class FileUploader implements IFileUploader {
    private firebase = container.get<IFirebase>(TYPES.IFirebase)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)

    private uuidGenerator =  container.get<UUID>(TYPES.UUID)
    
    upload(file: File, path: FileUploaderPath): Promise<string> {
        return new Promise((resolve, reject) => {
            let storageRef = this.firebase.storage.ref()
            const fileName = this.uuidGenerator.uuid() + "." + file.name.split('.').pop()
            const imageRef = storageRef.child(`${path}/${fileName}`)

            log("Uploading file to: " + imageRef.fullPath);
            
            imageRef.put(file).then(snapshot => {
                log("FileUploader - upload - Success");
                return snapshot.ref.getDownloadURL()
            }).then(downloadURL => {
                log("FileUploader - upload - Download Url: " + downloadURL);
                resolve(downloadURL)
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                log("FileUploader - upload - Failed: " + error);
                reject(error)
            })
		});
    }

    uploadSurveyImage(file: File, surveyLrsId: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let storageRef = this.firebase.storage.ref()
            let lrsId = surveyLrsId.split('/').pop()
            const fileName = lrsId + "." + file.name.split('.').pop()
            const imageRef = storageRef.child(`${FileUploaderPath.surveyImage}/${fileName}`)

            log("Uploading file to: " + imageRef.fullPath);
            
            imageRef.put(file).then(snapshot => {
                log("FileUploader - upload - Success");
                return snapshot.ref.getDownloadURL()
            }).then(downloadURL => {
                log("FileUploader - upload - Download Url: " + downloadURL);
                resolve(downloadURL)
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                log("FileUploader - upload - Failed: " + error);
                reject(error)
            })
		});
    }
} 