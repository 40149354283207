import { IconButton, Label, Stack, StackItem, TextField, Text, Icon, TooltipHost } from "@fluentui/react";
import { Component, FormEvent } from "react";
import { Question, ItemValue} from "survey-react";
import { container } from "../../../../DIContainer";
import { i18nKey, Ii18n } from "../../../../global/i18n";
import { Dictionary } from "../../../../global/TypeAliases";
import { Survey } from "../../../../models/Survey";
import { TYPES } from "../../../../Types";
import { FontSize } from "../../../../ui-kit/FontSize";
import { SecondaryButton } from "../../../../ui-kit/SecondaryButton";
import { ToggleControl } from "../../../../ui-kit/ToggleControl";
import { UUID } from "../../../../utils/UUIDGenerator";
import { SurveyChoicesOrder } from "../SurveyChoicesOrder";
import { MultichoicePropertiesPanelStyles } from "./MultichoicePropertiesPanelStyles";

interface MultichoicePropertiesPanelProps {
    panelTitle: string
    hasOtherChoice: boolean
    survey: Survey
    model: Question
    didUpdateSurvey: (survey: Survey) => void
    toggleRequireInteraction: (survey: Survey, model: Question, checked: boolean) => void
}

export class MultichoicePropertiesPanel extends Component<MultichoicePropertiesPanelProps> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private uuidGenerator: UUID = container.get<UUID>(TYPES.UUID)

    render() {
        return (
            <>
                <Text id="title-label" variant={FontSize.xLarge}>{this.props.panelTitle}</Text>
                <Stack tokens={{childrenGap: 20}}>
                    <TextField 
                        id="title-textfield"
                        type="text"
                        label={this.i18n.get(i18nKey.title)}
                        placeholder={this.i18n.get(i18nKey.surveyInteractionPlaceholder)}
                        onChange={this.titleTextFieldChanged}
                        required
                    />
                    <TextField 
                        id="description-textfield"
                        type="text"
                        label={this.i18n.get(i18nKey.description)}
                        value={this.props.model.description || ""}
                        onChange={this.descriptionTextFieldChanged}
                    />
                    <ToggleControl
                        id="required-toggle"
                        label={this.i18n.get(i18nKey.isRequired)}
                        checked={this.props.model.isRequired}
                        onChange={(_, checked) => this.requiredToggleChanged(checked)}
                    />
                    { this.props.model.choices.length > 0 &&
                        <>
                            <Label>{this.i18n.get(i18nKey.choices)}</Label>
                            <Stack id="multiple-choices-stack" tokens={{childrenGap: 10}}>
                                { this.renderChoices() }

                                { this.props.hasOtherChoice && 
                                    <ToggleControl
                                        id="other-choice-toggle"
                                        label={this.i18n.get(i18nKey.hasOtherChoice)}
                                        onText={this.i18n.get(i18nKey.true)}
                                        offText={this.i18n.get(i18nKey.false)}
                                        checked={this.props.model.hasOther}
                                        onChange={(_, checked) => this.onToggleChange(checked)}
                                    />
                                }
                                <TooltipHost
                                    content={this.i18n.get(i18nKey.randomizeChoicesTooltipText)}
                                    hostClassName="randomize-choices-tooltip"
                                    styles={MultichoicePropertiesPanelStyles.hostStyles}
                                    calloutProps={MultichoicePropertiesPanelStyles.randomizeChoicesCalloutProps}
                                >
                                    <Stack horizontal tokens={MultichoicePropertiesPanelStyles.stackStyling}>
                                        <ToggleControl
                                            id="randomize-choices-toggle"
                                            label={this.i18n.get(i18nKey.randomizeChoices)}
                                            checked={this.props.model.choicesOrder === SurveyChoicesOrder.random}
                                            onChange={(_, checked) => this.onRandomizeToggleChanged(checked)}
                                        />
                                        <Icon id="randomize-choices-information-icon" iconName="Info" styles={MultichoicePropertiesPanelStyles.toolTipIconStyles}/>
                                    </Stack>
                                </TooltipHost>
                            </Stack>
                        </>
                    }
                    <SecondaryButton 
                        id="add-choice-button" 
                        text={this.i18n.get(i18nKey.addChoice)} 
                        onClick={this.addNewChoice} 
                    />
                </Stack>
            </>
        )
    }

    requiredToggleChanged = (checked?: boolean) => {
        if (checked !== undefined) {
            this.props.toggleRequireInteraction(this.props.survey, this.props.model, checked)
        }
    }

    onToggleChange = (checked?: boolean) => {
        if (checked !== undefined) {
            this.props.model.hasOther = checked
            this.props.didUpdateSurvey(this.props.survey)
        }
    }

    onRandomizeToggleChanged = (checked?: boolean) => {
        if (checked !== undefined) {
            let choiceOrder: string
            if (checked) {
                choiceOrder = SurveyChoicesOrder.random
            } else {
                choiceOrder = SurveyChoicesOrder.none
            }

            this.props.model.choicesOrder = choiceOrder
            this.props.didUpdateSurvey(this.props.survey)
        }
    }

    private titleTextFieldChanged = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.props.model.title = newValue? newValue : ""
        this.props.didUpdateSurvey(this.props.survey)
    }

    private descriptionTextFieldChanged = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.props.model.description = newValue? newValue : ""
        this.props.didUpdateSurvey(this.props.survey)
    }

    private renderChoices = () => {
        return this.props.model.choices.map((choice: ItemValue, index: number) => {
            const lastIndex = index === this.props.model.choices.length-1
            const firstIndex = index === 0

            return <Stack key={index} id={`choice-row-${index}`} horizontal tokens={{childrenGap: 10}}>
                <StackItem>
                    <IconButton
                        id={`delete-${choice.value}`} 
                        iconProps={{ iconName: 'Delete' }} 
                        title="delete" 
                        ariaLabel="delete"
                        onClick={() => this.removeChoice(choice)}
                    />
                </StackItem>
                <StackItem grow>
                    <TextField
                        id={choice.value}
                        value={choice.text} 
                        onChange={(e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => { 
                            this.textFieldOnChange(e, choice, newValue) 
                        }} 
                    />
                </StackItem>
                <Stack styles={{ root: { width: 75, flexFlow: "row-reverse" }}} disableShrink={true} horizontal>
                    { !firstIndex &&
                        <IconButton
                            id={`move-up-${index}`} 
                            iconProps={{ iconName: 'Up' }} 
                            title="move-up" 
                            ariaLabel="move-up"
                            onClick={() => this.moveUpClicked(index)}
                        />
                    }

                    { !lastIndex &&
                        <IconButton
                            id={`move-down-${index}`} 
                            iconProps={{ iconName: 'Down' }} 
                            title="move-down" 
                            ariaLabel="move-down"
                            onClick={() => this.moveDownClicked(index)}
                        />
                    }
                </Stack>
            </Stack>
        })
    }

    private moveUpClicked = (sourceIndex: number) => {
        const updatedChoices = this.reorder(this.props.model.choices, sourceIndex, sourceIndex-1)
        /**
         * Save the new ordered choices
         */

        this.props.model.choices = updatedChoices.map(item => item.text)
        this.props.didUpdateSurvey(this.props.survey)
    }

    private moveDownClicked = (sourceIndex: number) => {
        const updatedChoices = this.reorder(this.props.model.choices, sourceIndex, sourceIndex+1)
        
        /**
         * Save the new ordered choices
         */
        this.props.model.choices = updatedChoices.map(item => item.text)
        this.props.didUpdateSurvey(this.props.survey)
    }

    private textFieldOnChange = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, choice: Dictionary, newValue?: string) => {
        let item = this.props.model.choices.find((item: ItemValue) => item.value === choice.value)

        if (item) {
            let value = newValue ? newValue : ''
            
            /**
             * SurveyJS will never render string with 0 length so we need to do this 
             * weird hack. If value is '' then we use ' ' and then when the user types any letters
             * then we remove the space.
             */
            const SPACE = ' '
            if (value === '') {
                value = SPACE
            }

            if (value.length > 1 && value.charAt(0) === SPACE) {
                value = value.substring(1)
            }

            item.value = value
        }

        this.props.didUpdateSurvey(this.props.survey)
    }

    /**
     * Reorder the choices
     * @param choices The list of chioces
     * @param startIndex The index of the choice that is being moved
     * @param endIndex The target index where the choice will be moved to
     */
    private reorder = (choices: ItemValue[], startIndex: number, endIndex: number): ItemValue[] => {

        const result = [...choices]
        /**
         * Remove the element at the start index
         */
        const [removed] = result.splice(startIndex, 1)
        
        /**
         * Place the choice at the target index
         */
        result.splice(endIndex, 0, removed)

        return result
    }

    private removeChoice = (choice: Dictionary) => {
        let index = this.props.model.choices.findIndex((item: ItemValue) => item.value === choice.value)

        if (index > -1) {
            this.props.model.choices.splice(index, 1)
            this.props.didUpdateSurvey(this.props.survey)
        }
    }

    private addNewChoice = () => {
        let choices = this.props.model.choices.map((item: ItemValue) => item.text)
        choices.push(this.uuidGenerator.uuid())

        this.props.model.choices = choices
        this.props.didUpdateSurvey(this.props.survey)
    }
}
