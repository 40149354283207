import { SSOStep } from './../auth/CleverRedirect'
import { CleverUser } from './../models/CleverUser'
import { CleverMe } from './../models/CleverMe'
import { injectable } from 'inversify';
import { IClientService } from './ClientService';
import { IAuthService } from './AuthService';
import { IUserService } from './UserService';
import { TYPES } from '../Types';
import { ICleverService } from './CleverService';
import { CleverTokenConfig } from '../auth/CleverCodeParser';
import { container } from '../DIContainer';
import { log } from '../global/Logger';
import { Uid } from '../global/TypeAliases';
import { ICohortService } from './CohortService';

export interface ICleverCompositionServiceDelegate {
    didPerform(step: SSOStep): void
}

export interface ICleverCompositionService {
    /**
     * Clever SSO will go through the following sequence:
     * 1. Get a bearer token with the url code from Clever
     * 2. Get the user id from Clever
     * 3. Get the entire Clever user info with the user id and bearer token
     * 4. Check Athena if the user exists
     *  4a. If the user does not exist we attempt to get the Client with the users district id
     *      If no client is found we will create a new client and mark it as inactive
     *  4b. If the user exists we will associate the user with the Clever user e.g. set cleverId property on user
     * 5. Get a one time auth token to login the user
     * 6. If it's a new user let's mark their email verified
     * 7. If all requests succeed we resolve the promise
     * @param codeConfig The config containing the Clever code and redirect uri
     */
    startSSO(codeConfig: CleverTokenConfig, delegate: ICleverCompositionServiceDelegate): Promise<void>
}

@injectable()
export class CleverCompositionService implements ICleverCompositionService {
    private cleverService: ICleverService = container.get<ICleverService>(TYPES.ICleverService)
    private userService: IUserService = container.get<IUserService>(TYPES.IUserService)
    private authService: IAuthService = container.get<IAuthService>(TYPES.IAuthService)
    private clientService: IClientService = container.get<IClientService>(TYPES.IClientService)
    private cohortService: ICohortService = container.get<ICohortService>(TYPES.ICohortService)
    
    private currentStep: SSOStep = SSOStep.connectionStarted

    startSSO(codeConfig: CleverTokenConfig, delegate: ICleverCompositionServiceDelegate): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                /**
                 * Start the sequence to obtain a Clever user
                 */
                log("CleverCompositionService - getBearerToken...")
                const bearerToken = await this.cleverService.getBearerToken(codeConfig.code, codeConfig.redirectUri)
                log("CleverCompositionService - getBearerToken - Success")
                
                log("CleverCompositionService - getUserIdFromBearerToken...")
                const cleverMe: CleverMe = await this.cleverService.getUserIdFromBearerToken(bearerToken)
                log("CleverCompositionService - getUserIdFromBearerToken - Success")
                log(`CleverCompositionService - user type - ${cleverMe.type}`)

                this.currentStep = SSOStep.connectionSucceeded
                delegate.didPerform(this.currentStep)

                this.currentStep = SSOStep.gatheringStarted
                delegate.didPerform(this.currentStep)

                let cleverUser: CleverUser
                if (cleverMe.type === "school_admin") {
                    log("CleverCompositionService - getSchoolAdmin...")
                    cleverUser = await this.cleverService.getSchoolAdmin(cleverMe.id, bearerToken)
                    log("CleverCompositionService - getSchoolAdmin - Success")
                } else if (cleverMe.type === "district_admin") {
                    log("CleverCompositionService - getDistrictAdmin...")
                    cleverUser = await this.cleverService.getDistrictAdmin(cleverMe.id, bearerToken)
                    log("CleverCompositionService - getDistrictAdmin - Success")
                } else if (cleverMe.type === "teacher") {
                    log("CleverCompositionService - getTeacher...")
                    cleverUser = await this.cleverService.getTeacher(cleverMe.id, bearerToken)
                    log("CleverCompositionService - getTeacher - Success")
                } else {
                    log(`CleverCompositionService - Error - Unsupported user type: ${cleverMe.type}`)
                    throw new Error("Unsupported user type: " + cleverMe.type)
                }

                this.currentStep = SSOStep.gatheringSucceeded
                delegate.didPerform(this.currentStep)

                /**
                 * If user doesn't exist create a new account and assign a client to it.
                 * Else if user exists but doesn't have Clever properties yet then create
                 * association.
                 */
                this.currentStep = SSOStep.syncingStarted
                delegate.didPerform(this.currentStep)

                let existingUser = await this.userService.getUserWithEmail(cleverUser.emailAddress)
                if (!existingUser) {
                    let client = await this.clientService.clientWithCleverDistrictId(cleverUser.districtId)
                            
                    if (!client) {
                        /**
                         * This is a new client that we don't have an existing contract with. We'll create
                         * a new client so the user can successfully SSO.
                         */             
                        const emailComponents = cleverUser.emailAddress.split("@")
                        const domainLocation = emailComponents.length - 1
                        const domain = emailComponents[domainLocation]
                        const domainSubstring = domain.split(".")[0].toLowerCase()
                   
                        client = await this.clientService.addClient("", "", domainSubstring, cleverUser.districtId, false)
                    }

                    const userDocumentId = await this.authService.createCleverUser(cleverUser, client)
                    if (client.defaultCohort) {
                        log("CleverCompositionService - Adding new learner to global group.")
                        await this.cohortService.addLearnersToCohort([userDocumentId], client.defaultCohort)
                        log("CleverCompositionService - Adding new learner to global group complete.")
                    }
                } else {
                    log("CleverCompositionService - Existing Athena user. Creating association between the user and Clever user.")
                    await this.authService.createAssociation(cleverUser, existingUser)
                    log("CleverCompositionService - Creating association complete.")
                } 
                
                this.currentStep = SSOStep.syncingSucceeded
                delegate.didPerform(this.currentStep)

                this.currentStep = SSOStep.loggingInStarted
                delegate.didPerform(this.currentStep)

                /**
                 * Login the user using a one time auth token
                 */
                const uid: Uid = existingUser ? existingUser.uid : cleverUser.id

                log("CleverCompositionService - Creating auth token")
                const authToken = await this.authService.getAuthToken(uid)
                log("CleverCompositionService - Creating auth token success")
                log("CleverCompositionService - Logging in")
                const firebaseUser = await this.authService.loginWithAuthToken(authToken)
                log("CleverCompositionService - Logged in")
                
                /**
                 * After successfully logging in we need to mark their account as email verified, if not already
                 */
                if (!firebaseUser.emailVerified) {
                    log("CleverCompositionService - New Athena user. Verify Email.")
                    await this.authService.verifyEmail(firebaseUser)
                    log("CleverCompositionService - Verify email complete")
                }

                log("CleverCompositionService - SSO complete")

                this.currentStep = SSOStep.loggingInSucceeded
                delegate.didPerform(this.currentStep)

                /**
                 * SSO is complete
                 */
                resolve()
            } catch (error) {                
                log(`CleverCompositionService - Failed - ${this.valueForStep(this.currentStep)} - ${error}`)

                switch (this.currentStep) {
                    case SSOStep.connectionStarted:
                        delegate.didPerform(SSOStep.connectionFailed)
                        break
                    case SSOStep.gatheringStarted:
                        delegate.didPerform(SSOStep.gatheringFailed)
                        break
                    case SSOStep.syncingStarted:
                        delegate.didPerform(SSOStep.syncingFailed)
                        break
                    case SSOStep.loggingInStarted:
                        delegate.didPerform(SSOStep.loggingInFailed)
                        break
                }

                reject(error)
            }
        })
    }

    /**
     * Private Functions
     */

    private valueForStep(step: SSOStep): string {
        let val = ""

        switch (step) {
            case SSOStep.connectionStarted:
                val = "Connection Started"
                break
            case SSOStep.gatheringStarted:
                val = "Gathering Started"
                break
            case SSOStep.syncingStarted:
                val = "Syncing Started"
                break
            case SSOStep.loggingInStarted:
                val = "Logging In Started"
                break
        }

        return val
    }
}