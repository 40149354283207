import { ICourseParser } from './../models/CourseParser'
import { IFirebase } from "./Firebase"
import Course, { CourseType } from "../models/Course"
import Cohort from "../models/Cohort"
import { IAuth } from "../auth/Auth"
import { injectable } from 'inversify'
import axios from "axios"
import { container } from '../DIContainer'
import { TYPES } from '../Types'
import { IServiceConfig } from './ServiceConfig';
import { log } from '../global/Logger'
import { DocumentId } from '../global/TypeAliases'
import { IEventReportingService } from '../services/EventReportingService'
import { CourseUploadInfo } from '../models/CourseUploadInfo'
import { ILearningPlanService } from './LearningPlanService'
import { ContentType } from '../models/IContent'

export interface ICourseService {
	/**
	 * Get all courses
	 * @return An array of Courses
	 */
	courses(): Promise<Course[]>

	/**
	 * Get courses assigned to a client
	 * @return An array of Courses
	 */
	coursesForClientId(clientId: string): Promise<Course[]>

	/**
	 * Get courses assigned to a guest
	 * @return An array of Courses
	 */
	coursesForGuest(): Promise<Course[]>

	/**
	 * Get courses for a user
	 */
	coursesForCurrentUser(): Promise<Course[]>

	/**
	 * Get unique courses for a cohort document id
	 * @param cohortId cohort document id
	 */
	coursesForCohort(cohortId: string): Promise<Course[]>

	/**
	 * Add a new course
	 * @param name The course name
	 * @param description The course description
	 * @param clientId The client id to assign this course to
	 * @param zipFile The zipped course
	 * @param duration The estimated time to complete the course
	 */
	addCourse(
		name: string, 
		description: string, 
		clientId: string,
		zipFile: File,
		imageFile: File,
		duration: string
	): Promise<void>

	/**
	 * Update course details
	 * @param documentId The course document id
	 * @param name The course name
	 * @param description The course description
	 * @param duration The course duration
	 */
	updateCourse(
		documentId: string,
		name: string,
		description: string,
		clientId: string,
		zipFile: File,
		imageFile: File,
		duration: string,
		courseFolder: string,
	): Promise<void>

	/**
	 * Delete the course from the database
	 * @param courseName Name of the course to delete
	 */
	deleteCourse(courseId: DocumentId): Promise<void>

	/**
	 * Add cohorts to a course
	 * @param {*} cohortDocumentIds The cohort document ids
	 * @param {*} courseDocumentId The course document id
	 */
	addCohortsToCourse(
		cohortDocumentIds: DocumentId[],
		courseDocumentId: DocumentId,
		clientId: DocumentId
	): Promise<void>

	/**
	 * Add cohorts to a course
	 * @param {*} cohortDocumentId The cohort document id
	 * @param {*} courseDocumentIds The course document ids
	 */
	addCoursesToCohort(
		cohortDocumentIds: DocumentId,
		courseDocumentId: DocumentId[],
		clientId: DocumentId
	): Promise<void>

	/**
	 * Remove cohort from selected courses
	 * @param {*} cohortDocumentIds The cohort document id
	 * @param {*} courseDocumentId The course document ids
	 */
	removeCoursesFromCohort(
		cohortDocumentIds: DocumentId,
		courseDocumentId: DocumentId[],
		clientId: DocumentId
	): Promise<void>

	/**
	 * Remove cohorts from a course
	 * @param {*} cohortDocumentIds The cohort document ids
	 * @param {*} courseDocumentId The course document id
	 */
	removeCohortsFromCourse(
		cohortDocumentIds: DocumentId[],
		courseDocumentId: DocumentId,
		clientId: DocumentId
	): Promise<void>
	

	/**
	 * Get all cohorts belonging to a course document id
	 * @param clientDocumentId The client document id
	 */
	getCohortsForCourse(courseDocumentId: DocumentId): Promise<Cohort[]>
}

@injectable()
export default class CourseService implements ICourseService {
	private firebase = container.get<IFirebase>(TYPES.IFirebase)
    private auth = container.get<IAuth>(TYPES.IAuth)
	private parser = container.get<ICourseParser>(TYPES.ICourseParser)
	private serviceConfig = container.get<IServiceConfig>(TYPES.IServiceConfig)
	private learningPlanService = container.get<ILearningPlanService>(TYPES.ILearningPlanService)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)

	courses(): Promise<Course[]> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection("course")
				.orderBy("name", "asc")
				.withConverter(Course.Converter)
				.get()
				.then((querySnapshot) => {
					log("Success: CourseService - courses()")

					let courses: Course[] = []
					querySnapshot.forEach((doc) => {
						let course = doc.data()
						course.documentId = doc.id
						courses.push(course)
					})

					resolve(courses)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("Error: CourseService - courses() - " + error)
					reject(error)
				})
		})
	}

	coursesForClientId(clientId: string): Promise<Course[]> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection("course")
				.withConverter(Course.Converter)
				.where(`clients.${clientId}`, "==", true)
				.get()
				.then((querySnapshot) => {
					var result: Course[] = []
					querySnapshot.forEach(function (doc) {
						let item: Course = doc.data()
						item.documentId = doc.id
						result.push(item)
					})

					log("ClientService - coursesForClientId() - Success")
					resolve(result)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("ClientService - coursesForClientId() - Failed")
					reject(error)
				})
		})
	}

	/* istanbul ignore next */
	coursesForGuest(): Promise<Course[]> {
		const guestId = process.env.REACT_APP_ENV_GUEST_ID

		return new Promise((resolve, reject) => {
			let get = this.firebase.app.functions().httpsCallable("coursesForGuest")
			
			get({ clientId: guestId })
				.then((result) => {
					
					log("CourseService - coursesForGuest() - Success")
					const courses = Object.keys(result.data).map((id) => {
						const course = this.parser.parse(id, result.data[id])
						course.locked = true
						return course
					})

					resolve(courses)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("CourseService - coursesForGuest() - Failed: " + error)
					reject(error)
				})
		})
	}

	/* istanbul ignore next */
	coursesForCurrentUser(): Promise<Course[]> {
		if (!this.auth.isLoggedIn()) {
			return Promise.reject("No user logged in")
		}

		return new Promise((resolve, reject) => {
			let get = this.firebase.app.functions().httpsCallable("coursesForUser")

			log("Getting courses for " + this.auth.uid())
			
			get({ uid: this.auth.uid() })
				.then((result) => {
					log("CourseService - coursesForCurrentUser() - Success")
					const courses = Object.keys(result.data).map((id) => {
						return this.parser.parse(id, result.data[id])
					})

					courses.sort((lhs, rhs) => {
						return lhs.name > rhs.name ? 1 : -1
					})
					resolve(courses)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("CourseService - coursesForCurrentUser() - Failed: " + error)
					reject(error)
				})
		})
	}

	coursesForCohort(cohortId: string): Promise<Course[]> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection("course")
				.withConverter(Course.Converter)
				.where(`cohorts.${cohortId}`, "==", true)
				.get()
				.then((querySnapshot) => {
					var result: Course[] = []
					querySnapshot.forEach(function (doc) {
						let item: Course = doc.data()
						item.documentId = doc.id
						result.push(item)
					})

					log("CourseService - coursesForCohort() - Success")
					resolve(result)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("CourseService - coursesForCohort() - Failed")
					reject(error)
				})
		})
	}

	addCourse(
		name: string, 
		description: string, 
		clientId: string, 
		zipFile: File, 
		imageFile: File,
		duration: string): Promise<void> {
		var clientMap: { [key: string]: boolean } = {}
		clientMap[clientId] = true

		return new Promise((resolve, reject) => {
			this.uploadCourse(zipFile, imageFile, clientId)
				.then((courseInfo) => {
					log(courseInfo.debugLog())

					return this.firebase.db
						.collection("course")
						.add({
							name: name,
							type: CourseType.training,
							contentType: ContentType.course,
							description: description,
							clients: clientMap,
							locationUrl: courseInfo.courseUrl,
							imageUrl: courseInfo.imageUrl,
							duration: duration,
							lrsId: courseInfo.lrsId,
							courseFolder: courseInfo.courseFolder,
							lessonNames: courseInfo.lessonNames
						})
				})
				.then(() => {
					log("Success: CourseService - addCourse()")
					resolve()
				})
				.catch(error => {
					this.eventReportingService.error(error.message, error)
					log("Error: CourseService - addCourse() - " + error)
					reject(error)
				})
		})
	}

	updateCourse(
		documentId: string,
		name: string,
		description: string,
		clientId: string,
		zipFile: File,
		imageFile: File,
		duration: string,
		courseFolder: string,
	): Promise<void> {
		return new Promise((resolve, reject) => {
			this.updateCourseFiles(zipFile, imageFile, courseFolder, clientId)
				.then((courseInfo) => {
					log(courseInfo.debugLog())

					if (courseInfo.lessonNames !== undefined) {
						return this.firebase.db
							.collection("course")
							.doc(documentId)
							.update({
								name: name,
								description: description,
								duration: duration,
								imageUrl: courseInfo.imageUrl,
								lessonNames: courseInfo.lessonNames,
								documentId: documentId
							})
					}

					else {
						return this.firebase.db
							.collection("course")
							.doc(documentId)
							.update({
								name: name,
								description: description,
								duration: duration,
								imageUrl: courseInfo.imageUrl,
								documentId: documentId
							})
					}

				})
				.then(_ => {
					return this.learningPlanService.updateLearningPlanContentInfo(documentId, clientId)
				})
				.then(() => {
					log("Success: CourseService - updateCourse()")
					resolve()
				})
				.catch(error => {
					this.eventReportingService.error(error.message, error)
					log("Error: CourseService - updateCourse() - " + error)
					reject(error)
				})
		})
	}

	/* istanbul ignore next */
	deleteCourse(courseId: DocumentId): Promise<void> {
		let deleteCourse = this.firebase.app.functions().httpsCallable("deleteCourse")
		return new Promise((resolve, reject) => {
			deleteCourse({ documentId: courseId })
				.then((result) => {
					log("CourseService - deleteCourse - Success")
					// make Azure call here

					if (!result.data.courseFolder) {
						this.eventReportingService.warn("CourseService - deleteCourse() - Did not find course folder property on Firebase document")
						log("CourseService - deleteCourse() - Did not find course folder property on Firebase document")
						resolve()
						return
					}

					let options = this.serviceConfig.getCourseDeleteConfig(result.data.courseFolder)

					axios.post(options.url, options.config.data, options.config.headers)
						.then(_ => {
							log("CourseService - deleteCourse - Success on Azure")
							resolve()
						})
						.catch(error => {
							this.eventReportingService.error(error.message, error)
							log("CourseService - deleteCourse - Failed: " + error)
							reject(error)
						})
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log(`CourseService - deleteCourse - Failed - ${error}`)
					reject(error)
				})
		})
	}

	/* istanbul ignore next */
	addCohortsToCourse(
		cohortDocumentIds: DocumentId[],
		courseDocumentId: DocumentId,
		clientId: DocumentId
	): Promise<void> {
		var cohortMap: { [key: string]: boolean } = {}

		cohortDocumentIds.forEach((documentId) => {
			cohortMap[documentId] = true
		})

		var courseMap: { [key: string]: boolean } = {}
		courseMap[courseDocumentId] = true

		return new Promise((resolve, reject) => {
			let add = this.firebase.app
				.functions()
				.httpsCallable("addCohortsToCourse")

			add({
				courseDocumentId: courseDocumentId,
				cohorts: cohortMap,
				courses: courseMap,
			}).then(_ => {
				return this.learningPlanService.updateLearningPlanContentInfo(courseDocumentId, clientId)
			})
			.then(function (result) {
				log("CourseService - addCohortsToCourse() - Success")
				resolve()
			})
			.catch(error => {
                this.eventReportingService.error(error.message, error)
				log("CourseService - addCohortsToCourse() - Failed: " + error)
				reject(error)
			})
		})
	}

	/* istanbul ignore next */
	async addCoursesToCohort(
		cohortDocumentId: DocumentId,
		courseDocumentIds: DocumentId[],
		clientId: DocumentId
	): Promise<void> {
		let addCoursesPromises = courseDocumentIds.map((courseDocumentId: DocumentId) => {
            return this.addCohortsToCourse([cohortDocumentId], courseDocumentId, clientId)
        })

        return await Promise.all(addCoursesPromises)
            .then((_) => {
				log("CourseService - addCoursesToCohort() - Success!")
				return Promise.resolve()
            })
            .catch(error => {
                this.eventReportingService.error(error.message, error)
				log("CourseService - addCoursesToCohort() - Failed: " + error)
				return Promise.reject(error)
            })
	}

	/* istanbul ignore next */
	removeCohortsFromCourse(
		cohortDocumentIds: DocumentId[],
		courseDocumentId: DocumentId,
		clientId: DocumentId
	): Promise<void> {
		var cohortMap: { [key: string]: boolean } = {}

		cohortDocumentIds.forEach((documentId) => {
			cohortMap[documentId] = true
		})

		return new Promise((resolve, reject) => {
			let remove = this.firebase.app
				.functions()
				.httpsCallable("removeCohortsFromCourse")

			remove({ cohorts: cohortMap, courseDocumentId: courseDocumentId })
				.then(_ => {
					return this.learningPlanService.updateLearningPlanContentInfo(courseDocumentId, clientId)
				})
				.then(function (result) {
					log("CourseService - removeCohortsFromCourse() - Success")
					resolve()
				})
				.catch(error => {
                	this.eventReportingService.error(error.message, error)
					log("CourseService - removeCohortsFromCourse() - Failed: " + error)
					reject(error)
				})
		})
	}

	/* istanbul ignore next */
	async removeCoursesFromCohort(
		cohortDocumentId: DocumentId,
		courseDocumentIds: DocumentId[],
		clientId: DocumentId
	): Promise<void> {

		let removeCoursePromises = courseDocumentIds.map((courseDocumentId: DocumentId) => {
            return this.removeCohortsFromCourse([cohortDocumentId], courseDocumentId, clientId)
        })

        return await Promise.all(removeCoursePromises)
            .then((_) => {
				log("CourseService - removeCoursesFromCohort() - Success!")
				return Promise.resolve()
            })
            .catch(error => {
                this.eventReportingService.error(error.message, error)
				log("CourseService - removeCoursesFromCohort() - Failed: " + error)
				return Promise.reject(error)
            })
	}

	getCohortsForCourse(courseDocumentId: DocumentId): Promise<Cohort[]> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection("cohort")
				.withConverter(Cohort.Converter)
				.where(`courses.${courseDocumentId}`, "==", true)
				.get()
				.then((querySnapshot) => {
					var result: Cohort[] = []
					querySnapshot.forEach(function (doc) {
						let item: Cohort = doc.data()
						item.documentId = doc.id
						result.push(item)
					})
					log("ClientService - getCohortsForCourse() - Success")
					resolve(result)
				})
				.catch(error => {
                	this.eventReportingService.error(error.message, error)
					log("ClientService - getCohortsForCourse() - Failed: " + error)
					reject(error)
				})
		})
	}

	private updateCourseFiles(
		zipFile: File,
		imageFile: File,
		courseFolder: string,
		clientId: string,
	) : Promise<CourseUploadInfo> {
		let options = this.serviceConfig.getCourseUpdateConfig(zipFile, imageFile, courseFolder, clientId)

		return new Promise((resolve, reject) => {
			axios.post(options.url, options.config.data, options.config.headers)
			.then(response => {
				log("CourseService - updateCourse - Success")
				if (zipFile !== undefined) {
					resolve(new CourseUploadInfo(
						response.data.courseUrl, response.data.imageUrl, response.data.lrsId, response.data.courseFolder, response.data.lessonNames))
				}

				else {
					resolve(new CourseUploadInfo(
						response.data.courseUrl, response.data.imageUrl, response.data.lrsId, response.data.courseFolder))
				}
			})
			.catch(error => {
                this.eventReportingService.error(error.message, error)
				log("CourseService - updateCourse - Failed: " + error)
                reject(error)
			})
		})
	}

	private uploadCourse(
		zipFile: File, 
		imageFile: File,
		clientId: string,
	) : Promise<CourseUploadInfo> {
		let options = this.serviceConfig.getCourseUploadConfig(zipFile, imageFile, clientId)

		return new Promise((resolve, reject) => {
			axios.post(options.url, options.config.data, options.config.headers)
				.then(response => {
					log("CourseService - uploadCourse - Success")
					resolve(new CourseUploadInfo(
						response.data.courseUrl, response.data.imageUrl, response.data.lrsId, response.data.courseFolder, response.data.lessonNames))
				})
				.catch(error => {
					this.eventReportingService.error(error.message, error)
					log("CourseService - uploadCourse - Failed: " + error)
					reject(error)
				})
		})
	}
}
