import React, { Component } from "react";
import { Stack } from "@fluentui/react";
import { CourseGridStyle } from "./CourseGridStyle";
import { Dictionary, DocumentId } from "../../global/TypeAliases";
import { i18nKey, Ii18n } from "../../global/i18n";
import { container } from "../../DIContainer";
import { TYPES } from "../../Types";
import { LearningPlanContentGridItem } from "../admin/courses/LearningPlanContentGridItem";
import { IContent } from "../../models/IContent";
import { ILearningPlanHelper } from "../../utils/LearningPlanHelper";

interface LearningPlanContentGridProps {
	contents: IContent[];
	userClientIds: DocumentId[]
	showProgressBar: boolean
	shouldWrap: boolean

	/**
	 * Map of completed content document ids e.g. { 'docId': true }
	 */
	completedContentsMap: Dictionary
	onContentClick: (e: React.MouseEvent<HTMLDivElement>, content: IContent) => void
}

export class LearningPlanContentGrid extends Component<LearningPlanContentGridProps> {
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
	private learningPlanHelper: ILearningPlanHelper = container.get<ILearningPlanHelper>(TYPES.ILearningPlanHelper)

	render() {
		if (this.props.contents.length === 0) {
			return <div id="course-grid-no-courses-displayed">{this.i18n.get(i18nKey.noCourses)}</div>
		}
		
		return (
			<div id="course-grid">
				<Stack 
					horizontal={true}
					wrap={this.props.shouldWrap} 
					tokens={CourseGridStyle.stackTokens}
				>
					{this.renderContentGridItems()}
				</Stack>
			</div>
		);
	}

	/**
	 * Private Functions
	 */

	private renderContentGridItems = () => {
		const clickableState = this.learningPlanHelper.getClickableState(this.props.contents, this.props.completedContentsMap)

		return this.props.contents.map(content => {
			let showProgressionArrow = true
			if (this.props.contents[0] === content) {
				showProgressionArrow = false
			}
			return <LearningPlanContentGridItem
				content={content} 
				key={content.documentId}
				userClientIds={this.props.userClientIds} 
				showProgressBar={this.props.showProgressBar} 
				showProgressionArrow={showProgressionArrow}
				isClickable={clickableState[content.documentId]}
				isComplete={this.props.completedContentsMap[content.documentId]}
				onContentClick={this.props.onContentClick} 
			/> 
		})
	}
}