import { IStackStyles, ITextStyles, IImageStyles } from '@fluentui/react'

import backgroundImage from '../images/marketing/testimonials-background.jpg'

export class FeaturesApproachStyles {
    static outerStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    padding: '5vw 5vw 10vw 9vw',
                },
            },

            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'contain',
            padding: '5vw 5vw 5vw 15vw',
        },
    }

    static innerLeftStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    width: '100%',
                },
            },

            paddingTop: '6vw',
            width: '50%',
        },
    }

    static innerRightStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    width: '100%',
                    paddingTop: '10vw',
                },
            },

            width: '50%',
        },
    }

    static sectionNameTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '4.5vw',
                    paddingBottom: '5vw',
                },
            },

            fontFamily: 'Noto Sans JP, sans-serif',
            color: '#0F72AC',
            fontWeight: 700,
            fontSize: '1vw',
            paddingBottom: '1vw',
        },
    }

    static headerTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '11vw',
                    paddingBottom: '5vw',
                    margin: '0',
                },
            },

            color: '#000000',
            fontFamily: 'Noto Sans JP, sans-serif',
            fontWeight: 700,
            fontSize: '2.8vw',
            marginRight: '5vw',
        },
    }

    static descriptionTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '4.5vw',
                    lineHeight: '8vw',
                    paddingTop: '6vw',
                    marginRight: '5vw',
                },
            },

            color: '#7C7C7C',
            fontFamily: 'Lato, sans-serif',
            fontWeight: '600',
            fontSize: '1vw',
            lineHeight: '2vw',
            paddingTop: '1vw',
            marginRight: '12vw',
        },
    }

    static imageStyles: Partial<IImageStyles> = {
        image: {
            width: '100%',
            boxShadow: '0 0 2vw 0 rgba(0,0,0,0.1)',
        },
    }
}
