import React, { Component } from "react"
import { Stack, Spinner, Text, MessageBar, MessageBarType, Label, IDropdownOption, TooltipHost, ITooltipHostStyles, Checkbox } from "@fluentui/react"
import { PrimaryButton } from "../../../ui-kit/PrimaryButton"
import CSVReader, { IFileInfo } from 'react-csv-reader'
import './UserUploadPage.css'
import { Dictionary } from "../../../global/TypeAliases"
import { IDataTableFactory } from "../../../factories/DataTableFactory"
import { container } from "../../../DIContainer"
import { TYPES } from "../../../Types"
import DTSTableData from "../../../models/DTSTableData"
import DTSTable from "../../../ui-kit/DTSTable"
import { FontSize } from "../../../ui-kit/FontSize"
import PageTitle from "../../../ui-kit/PageTitle"
import { IUserUploadValidator, UploadValidatorError } from "./UserUploadValidator"
import { UserUploadStyles } from "./UserUploadStyles"
import { IUserUploadCompositionService } from "../../../services/UserUploadCompositionService"
import { Dropdown } from "../../../ui-kit/Dropdown"
import { log } from "../../../global/Logger"
import Client from "../../../models/Client"
import Cohort from "../../../models/Cohort"
import { i18nKey, Ii18n } from "../../../global/i18n";
import { UsersAdminStyles } from "./UsersAdminStyles"
import { IFeatureFlagService } from "../../../services/FeatureFlagService"
import { ClientContextProp } from "../WithClientContext"
import { RoleType } from "../../../models/RoleType"

interface UserUploadPageProps extends ClientContextProp {}

interface UserUploadPageState {
    file: File | null
    showSpinner: boolean
    primaryButtonDisabled: boolean
    tableData: DTSTableData | null
    usersToUpload: Dictionary[]
    successMessage: string | null
    errorMessage: string | null
    csvErrors: UploadValidatorError[] | null
    gettingClientList: boolean
    clientListOptions: IDropdownOption[]
    client: Client | null
    cohortListOptions: IDropdownOption[]
    cohort: Cohort | null
    newLearnersCount: number
    existingLearnersCount: number
    showClientContextDialog: boolean
    registrationReminderIsChecked: boolean
}

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'center' } };
const calloutProps = {
    gapSpace: 10,
    // If the tooltip should point to an absolutely-positioned element,
    // you must manually specify the callout target.
    target: `#bulk-upload-registration-reminder-checkbox`,
}

export default class UserUploadPage extends Component<UserUploadPageProps, UserUploadPageState> {
    private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
    private dataValidator: IUserUploadValidator = container.get<IUserUploadValidator>(TYPES.IUserUploadValidator)
    private compositionService: IUserUploadCompositionService = container.get<IUserUploadCompositionService>(TYPES.IUserUploadCompositionService)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private featureFlagService: IFeatureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService)

    state: UserUploadPageState = {
        file: null,
        showSpinner: false,
        primaryButtonDisabled: false,
        tableData: null,
        usersToUpload: [],
        successMessage: null,
        errorMessage: null,
        csvErrors: null,
        gettingClientList: false,
        clientListOptions: [],
        client: null,
        cohortListOptions: [],
        cohort: null,
        newLearnersCount: 0,
        existingLearnersCount: 0,
        showClientContextDialog: false,
        registrationReminderIsChecked: true
    }

    private parserOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true
    }

    render() {        
        return (
            <div id="user-upload-container">
                <PageTitle title="Upload New Learners" />

                <Stack horizontalAlign="start">
                    <Label id="csvLabel" styles={UserUploadStyles.labelStyles}>{this.i18n.get(i18nKey.chooseCSVForUpload)}</Label>
                    <CSVReader
                        inputId="csv-reader"
                        cssClass="csv-input"
                        label={this.i18n.get(i18nKey.selectFile)}
                        cssLabelClass="csv-label"
                        onFileLoaded={this.fileLoaded}
                        parserOptions={this.parserOptions}
                    />
                    <Label id="csvTemplateLabel" styles={UserUploadStyles.labelStyles}>{this.i18n.get(i18nKey.downloadCSVTemplateLabel)}</Label>
                    <PrimaryButton
                        id="download-csv-template-primary-button"
                        text={this.i18n.get(i18nKey.downloadCSVTemplateButtonText)}
                        onClick={this.onDownloadCSVTemplateButtonClicked}
                    />
                </Stack>

                { this.state.csvErrors !== null && 
                    <Stack id="csv-error-stack" styles={UserUploadStyles.csvErrorStyle} tokens={UserUploadStyles.stackTokens}>
                        <Stack>
                            <Text variant={FontSize.large}><strong>{this.i18n.get(i18nKey.userUploadError)}</strong></Text>
                        </Stack>

                        <Stack>
                            { this.state.csvErrors.map((value, index) => {
                                return <Text key={index} variant={FontSize.large}>{++index}. {value.message}</Text>
                            })}
                        </Stack>
                        
                        
                        <Stack>
                            <Text variant={FontSize.large}><strong>{this.i18n.get(i18nKey.fixUserUploadIssues)}</strong></Text>
                        </Stack>
                    </Stack>
                }

                { this.state.tableData !== null && 
                    <Stack 
                        className="user-upload-preview-container" 
                        tokens={UserUploadStyles.stackTokens}
                    >
                        <Text variant={FontSize.large}>Upload Preview 
                            <Text 
                                id="user-upload-learner-count"
                                variant={FontSize.medium} 
                                styles={ UsersAdminStyles.learnerUploadStyle }
                            >
                                {this.state.newLearnersCount} {this.i18n.get(i18nKey.newLearnersAdded)} {this.state.existingLearnersCount} {this.i18n.get(i18nKey.existingLearnersAdded)}
                            </Text>
                        </Text>
                        
                        <DTSTable data={this.state.tableData} pagination={true}/>

                        { this.state.gettingClientList &&
                            <Spinner
                                id="user-upload-client-spinner"
                                label="Loading"
                                ariaLive="assertive"
                                labelPosition="right"
                            />
                        }

                        <Stack tokens={UserUploadStyles.stackTokens}>

                            { this.state.cohortListOptions.length > 0 &&
                                <>
                                <Text variant={FontSize.large}>And The Cohort?</Text>
                                <Dropdown
                                    id="user-upload-cohort-dropdown"
                                    placeholder="Select a cohort"
                                    label="Note: The global cohort will be selected by default"
                                    options={this.state.cohortListOptions}
                                    onChange={this.cohortChanged}
                                />
                                </>
                            }
                        </Stack>
                        
                        { this.featureFlagService.onboardingRegistrationReminder() &&
                            <TooltipHost
                                content={this.i18n.get(i18nKey.registrationReminderTooltipTextMultipleAdd)}
                                hostClassName="add-new-bulk-user-registration-reminder-tooltip"
                                styles={hostStyles}
                                calloutProps={calloutProps}
                            >
                                <Checkbox 
                                    id={"bulk-upload-registration-reminder-checkbox"}
                                    label={this.i18n.get(i18nKey.registrationReminderCheckboxText)} 
                                    checked={this.state.registrationReminderIsChecked} 
                                    onChange={this.registrationReminderCheckboxOnChange} 
                                    boxSide={"end"}
                                />
						    </TooltipHost>
					    }

                        { !this.state.showSpinner && 
                            <PrimaryButton
                                id="user-upload-primary-button"
                                disabled={this.state.primaryButtonDisabled}
                                text={this.i18n.get(i18nKey.uploadUsersButtonText)}
                                onClick={this.primaryButtonClicked}
                                width="150px"
                            />
                        }

                        { this.state.showSpinner && 
                            <Spinner
                                id="user-upload-spinner"
                                label="Uploading..."
                                ariaLive="assertive"
                                labelPosition="right"
                                styles={UserUploadStyles.spinnerStyle}
                            />
                        }

                        {this.showBannerIfNeeded()}
                    </Stack>          
                }
            </div>
        )
    }

    fileLoaded = (data: Array<Dictionary>, _: IFileInfo) => {
        const validationErrors = this.dataValidator.validate(data)

        if (validationErrors.errors !== null) {
            this.setState({ 
                tableData: null, 
                usersToUpload: [],
                csvErrors: validationErrors.errors
            })

            return
        }

        this.compositionService
            .processCSVData(data, this.props.clientId)
            .then(result => {
                this.setState({
                    tableData: this.dataTableFactory.createUserUploadPreviewTableData(data), 
                    csvErrors: null,
                    errorMessage: null,
                    client: result.client,
                    cohortListOptions: result.cohortListOptions,
                    cohort: result.selectedCohort,
                    usersToUpload: result.newLearnersData,
                    newLearnersCount: result.newLearnersCount,
                    existingLearnersCount: result.duplicateLearnersCount
                }, () => {
                    this.enablePrimaryButtonIfNeeded()
                })
            }).catch(error => {
                this.setState({ 
                    tableData: null, 
                    usersToUpload: [],
                    csvErrors: error.errors
                })
            })

        /**
         * This is a hack to clear out the input of the csv file or else it won't take multiple uploads
         * Link to issue: https://github.com/nzambello/react-csv-reader/issues/45
         */
        const csvReader = document.getElementById('csv-reader') as HTMLInputElement
        if (csvReader !== undefined && csvReader !== null) {
            csvReader.value = '';
        }
    }

    /**
     * Public Functions
     */
    
    primaryButtonClicked = () => {
		this.setState({
			showSpinner: true,
			primaryButtonDisabled: true,
            errorMessage: null
        })

        const client = this.state.client
        const selectedCohort = this.state.cohort

        if (client && selectedCohort) {

            /**
             * We only want to pass in the selected cohort if it's different than the clients default cohort
             * because uploadUsersForClient() will, by default, add all users to the default cohort already
             */
            const cohort = selectedCohort.documentId === client.defaultCohort 
                                ? undefined 
                                : selectedCohort

            if (this.featureFlagService.onboardingV2()) {
                this.compositionService.uploadUsers(this.state.usersToUpload, client, this.state.registrationReminderIsChecked, RoleType.learner, cohort)
                    .then(() => {
                        this.setState({ 
                            successMessage: "Uploaded all new learners.", 
                            errorMessage: null 
                        })
                    })
                    .catch(error => {
                        this.setState({                     
                            errorMessage: error.message, 
                            successMessage: null 
                        })
                    })
                    .finally(() => {
                        this.setState({
                            showSpinner: false,
                            primaryButtonDisabled: false
                        })
                    })
            } else {
                this.compositionService
                .uploadUsersForClient(client, this.state.usersToUpload, this.state.registrationReminderIsChecked, undefined, cohort)
                .then(() => {
                    const message = "Uploaded all new learners."
                    this.setState({
                        showSpinner: false,
                        primaryButtonDisabled: false,
                        successMessage: message,
                        errorMessage: null
                    })
                }).catch(error => {
                    this.setState({                     
                        showSpinner: false,
                        primaryButtonDisabled: false,
                        errorMessage: error.message, 
                        successMessage: null 
                    })
                })
            }
        } else {
            // TODO: Handle when we don't have a client to assign the users to
        }
    }

    dismissBanner = () => {
		this.setState({ errorMessage: null, successMessage: null })
    }

    cohortChanged = (
		event: React.FormEvent<HTMLDivElement>,
		option?: IDropdownOption,
		index?: number
	) => {
		log("Cohort selected: " + option?.data.name)
		const selectedCohort = option?.data
		this.setState({ cohort: selectedCohort }, () => {
			this.enablePrimaryButtonIfNeeded()
		})
    }
    
    /**
     * Private Functions
     */

    private onDownloadCSVTemplateButtonClicked = () => {
        if (process.env.REACT_APP_ENV_CSV_TEMPLATE_DOWNLOAD_URL) {
            window.location.assign(process.env.REACT_APP_ENV_CSV_TEMPLATE_DOWNLOAD_URL)
        } else {
            this.setState({errorMessage: this.i18n.get(i18nKey.downloadCSVTemplateError)})
        }
    }

    private showBannerIfNeeded = () => {
        let banner = null

        if (this.state.errorMessage) {
			banner = (
				<MessageBar
                    id="user-upload-error-banner"
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.errorMessage}
				</MessageBar>
			)
		} else if (this.state.successMessage) {
			banner = (
				<MessageBar
                    id="user-upload-success-banner"
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			)
        }

        return banner
    }

    private enablePrimaryButtonIfNeeded = () => {
		let disable = false

        if (this.state.client === null || this.state.cohort === null || this.state.usersToUpload.length === 0) {
            disable = true
        }

		this.setState({ primaryButtonDisabled: disable })
    }

    private registrationReminderCheckboxOnChange = () => {
		this.setState({registrationReminderIsChecked: !this.state.registrationReminderIsChecked})
	}

}
