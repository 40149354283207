import React, { Component } from "react";
import { 
    TextField as FluentUITextField, 
    ITextFieldStyleProps,
    ITextFieldStyles,
    ITextFieldProps
} from '@fluentui/react';
import { Theme } from '../ui-kit/Theme'

export class TextField extends Component<ITextFieldProps> {
    textFieldStyles = (props: ITextFieldStyleProps): Partial<ITextFieldStyles> => ({
        fieldGroup: {
            height: "35px",
            border: `${Theme.control.textFieldBorder} solid 1px`,
            borderRadius: '.25rem'
        }
    })

    render() {
        return (
            <FluentUITextField 
                {...this.props} 
                styles={this.textFieldStyles} 
                borderless={true} 
            />
        )
    }
}
