import { IDropdownStyles, IOverlayStyles, ISearchBoxStyles, IStackStyles, IStackTokens, ITextStyles } from "@fluentui/react";

export class LogInAsStyles {
    static tokens: Partial<IStackTokens> = {
        childrenGap: 40
    }

    static defaultTokens: Partial<IStackTokens> = {
        childrenGap: 20
    }

    static stackStyles: Partial<IStackStyles> = {
        root: { 
            paddingLeft: 50, 
            paddingRight: 50,
            marginTop: 20,
            alignItems: 'center'
        }
    }

    static searchByTextStyles: Partial<ITextStyles> = {
        root: {
            width: 300,
            textAlign: "right"
        }
    }

    static searchStack: Partial<IStackStyles> = {
        root: { 
            width: '75%',
            margin: 'auto',
            alignItems: 'center',
        }
    }

    static searchBoxStyles: Partial<ISearchBoxStyles> = {
        root: { 
            width: '100%', 
            margin: 'auto',
            height: 50,
        }
    }

    static dropDownStyles: Partial<IDropdownStyles> = {
        root: { 
            width: 200, 
        }
    }

    static modalOverlayStyles: Partial<IOverlayStyles> = {
        root: {
            backgroundColor: 'rgb(255, 255, 255, 0.80)'
        }
    }

    static modalStackStyles: Partial<IStackStyles> = {
        root: {
            width: 400,
            padding: 40,
            alignItems: 'center',
            textAlign: 'center'
        }
    }
}