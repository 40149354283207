import { Component } from "react";
import { Dialog, DialogFooter, PrimaryButton, DefaultButton, DialogType } from "@fluentui/react"
import { DestructiveButton } from "./DestructiveButton";

interface ConfirmationDialogProps {
    title: string
    dialogMessage: string
    affirmativeButtonText: string
    dismissButtonText?: string
    isDestructive?: boolean
    affirmativeActionHandler: (arg?: any) => void
    dismissActionHandler: (arg?: any) => void
}

interface ConfirmationDialogState {
    hideDialog: boolean
}

export class ConfirmationDialog extends Component<ConfirmationDialogProps, ConfirmationDialogState> {
    state: ConfirmationDialogState = {
        hideDialog: true
    }
    
    private dialogContentProps = {
        type: DialogType.largeHeader,
        title: this.props.title,
        closeButtonAriaLabel: 'Close',
        subText: this.props.dialogMessage,
    }

    private modalProps = {
        titleAriaId: 'confirmationDialogLabel',
        subtitleAriaId: 'subTextLabel',
        isBlocking: false,
    }

    componentDidMount() {
        this.setState({hideDialog: false})
    }

    render() {
        const dismissButtonText = this.props.dismissButtonText ? this.props.dismissButtonText : "Close"

        const buttonAttributes = {
            id: "confirmation-dialog-affirmative-button",
            onClick: this.onAffirmativeClicked,
            text: this.props.affirmativeButtonText
        }

        return(
            <div id="confirmation-dialog-container">
                <Dialog
                    hidden={this.state.hideDialog}
                    onDismiss={this.onDismissClicked}
                    dialogContentProps={this.dialogContentProps}
                    modalProps={this.modalProps}
                    minWidth={500}
                >
                    <DialogFooter>
                        {this.props.isDestructive ? <DestructiveButton {...buttonAttributes} /> : <PrimaryButton {...buttonAttributes} />}
                        <DefaultButton 
                            id="confirmation-dialog-dismiss-button" 
                            onClick={this.onDismissClicked} 
                            text={dismissButtonText}
                        />
                    </DialogFooter>
                </Dialog>
            </div>  
        ) 
    }

    onAffirmativeClicked = () => {
        this.props.affirmativeActionHandler()
        this.onDismissClicked()
    }

    onDismissClicked = () => {
        this.setState({hideDialog: true})
        this.props.dismissActionHandler()
    }
}