import { injectable } from "inversify";
import { container } from "../DIContainer";
import { DocumentId } from "../global/TypeAliases";
import Course from "../models/Course";
import { IContent } from "../models/IContent";
import { LearningPlan } from "../models/LearningPlan";
import { Survey } from "../models/Survey";
import { TYPES } from "../Types";
import { ILearningPlanService } from "./LearningPlanService";
import { ISurveyService } from "./SurveyService";

export interface IGroupIDService {
    /**
     * Get the group ids who are assigned the survey
     * @param surveyId The survey id
     * @param clientId The client id
     */
    getGroupIdsForSurveyId(surveyId: DocumentId, clientId: DocumentId): Promise<Set<DocumentId>>

    /**
     * Get the group ids who are assigned the training
     * @param training The training object
     * @param clientId The client id
     * @return A promise that resolves a set of DocumentIds of associated groups. If it rejects, an error will be thrown.
     */
    getGroupIdsForTraining(training: Course, clientId: DocumentId): Promise<Set<DocumentId>>
}

@injectable()
export class GroupIDService implements IGroupIDService {
    private learningPlanService: ILearningPlanService = container.get<ILearningPlanService>(TYPES.ILearningPlanService)
    private surveyService: ISurveyService = container.get<ISurveyService>(TYPES.ISurveyService)

    /** 
     * Public Functions
     */

    getGroupIdsForSurveyId(surveyId: DocumentId, clientId: DocumentId): Promise<Set<DocumentId>> {
        return new Promise((resolve, reject) => {
            const promises: Promise<any>[] = [
                this.learningPlanService.getLearningPlansFor(clientId),
                this.surveyService.surveyForDocumentId(surveyId)
            ]

            Promise
                .all(promises)
                .then(result => {
                    const learningPlans: LearningPlan[] = result[0]
                    const survey: Survey = result[1]
                    const groupIds: Set<DocumentId> = this.getGroupIdsFrom(learningPlans, survey)

                    resolve(groupIds)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    getGroupIdsForTraining(training: Course, clientId: DocumentId): Promise<Set<DocumentId>> {
        return new Promise((resolve, reject) => {

            this.learningPlanService.getLearningPlansFor(clientId)
                .then(result => {
                    const learningPlans: LearningPlan[] = result
                    const groupIds: Set<DocumentId> = this.getGroupIdsFrom(learningPlans, training)

                    resolve(groupIds)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * Private Functions
     */

    /**
     * Get group ids that are assigned the survey. If the survey a part of a learning plan
     * then get those group ids as well.
     * @param learningPlans The learning plans
     * @param content A content object
     * @returns A set of unique group ids that are in both the survey and learning plans
     */
    private getGroupIdsFrom(learningPlans: LearningPlan[], content: IContent): Set<DocumentId> {
        const contentGroupIds: DocumentId[] = Object.keys(content.cohorts)
        const learningPlanGroupIds: DocumentId[] = this.getMatchingGroupIdsFrom(learningPlans, content.documentId)

        return new Set([...contentGroupIds, ...learningPlanGroupIds])
    }

    /**
     * Find the groups within a learning plan that have the survey assigned
     * @param learningPlans The learning plans
     * @param contentId The content id
     * @returns An array of group ids that are assigned the survey
     */
    private getMatchingGroupIdsFrom(learningPlans: LearningPlan[], contentId: DocumentId): DocumentId[] {
        let groupIds: DocumentId[][] = []

        learningPlans.forEach(learningPlan => {
            const contentIds: DocumentId[] = learningPlan.contents.map(content => {
                return content.documentId
            }).flat()

            if (contentIds.includes(contentId)) {
                groupIds.push(Object.keys(learningPlan.cohorts))
            }
        })

        return groupIds.flat()
    }
}