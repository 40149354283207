import React, { Component } from 'react'
import { Stack, Image } from '@fluentui/react'
import { container } from '../DIContainer'
import { TYPES } from '../Types'
import { NavLink } from 'react-router-dom'
import { MobileHeaderStyles } from './MobileHeaderStyles'
import { IRouter } from '../Router'
import { PathNames } from '../PathNames'

import './MobileHeader.css'
import mobileMenuOpenIcon from '../images/navigation-bar/mobile-menu-icon-black.svg'
import mobileMenuCloseIcon from '../images/navigation-bar/mobile-menu-close-icon-white.svg'
import { IAccessController } from '../services/AccessController'
import { IAuthService } from '../services/AuthService'
import { ClientContextSelector } from './ClientContextSelector'
import { DocumentId } from '../global/TypeAliases'
import { Footer } from './Footer'

interface MobileHeaderProps {
    clientId: DocumentId | null
}

interface MobileHeaderState {
    isMenuOpen: boolean
    menuStyleClass: string | undefined
}

export class MobileHeader extends Component<MobileHeaderProps, MobileHeaderState> {
    private router: IRouter = container.get<IRouter>(TYPES.IRouter)
    private acl: IAccessController = container.get<IAccessController>(TYPES.IAccessController)
    private authService: IAuthService = container.get<IAuthService>( TYPES.IAuthService)

    state: MobileHeaderState = {
        isMenuOpen: false,
        menuStyleClass: undefined,
    }

    componentDidMount() {
        document.addEventListener('scroll', this.handleScroll)
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll)
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    render() {
        let navigationLinks

        if (this.acl.canAdministrate()) {
            navigationLinks = (
                <Stack id='mobile-menu-container-header' styles={MobileHeaderStyles.mobileMenuStackStyles}>
                    <NavLink 
                        to='#' 
                        id='mobile-menu-icon' 
                        onClick={this.state.isMenuOpen ? this.closeMobileMenu : this.openMobileMenu}
                    >
                        <Image 
                            src={this.state.isMenuOpen ? mobileMenuCloseIcon : mobileMenuOpenIcon} 
                            width='5vw' 
                            styles={MobileHeaderStyles.mobileIconStyles} 
                        />
                    </NavLink>
                    <Stack 
                        id='mobile-navigation-links-header' 
                        styles={MobileHeaderStyles.mobileLinkStackStyles} 
                        className={this.state.menuStyleClass}
                    >
                        <NavLink
                            className="mobile-header-top-nav-link" 
                            exact to={PathNames.Dashboard} 
                            onClick={this.closeMobileMenu}
                            activeClassName={MobileHeaderStyles.activeMobileLinkClass}
                        >
                            Dashboard
                        </NavLink>
                        <NavLink
                            className="mobile-header-nav-link"  
                            exact to={PathNames.Profile} 
                            onClick={this.closeMobileMenu}
                            activeClassName={MobileHeaderStyles.activeMobileLinkClass}
                        >
                            Profile
                        </NavLink>
                        <NavLink
                            className="mobile-header-nav-link" 
                            exact to={PathNames.Learners} 
                            onClick={this.closeMobileMenu}
                            activeClassName={MobileHeaderStyles.activeMobileLinkClass}
                        >
                            Learners
                        </NavLink>
                        <NavLink 
                            className="mobile-header-nav-link" 
                            exact to={PathNames.Contents} 
                            onClick={this.closeMobileMenu}
                            activeClassName={MobileHeaderStyles.activeMobileLinkClass}
                        >
                            Content
                        </NavLink>
                        <NavLink
                            className="mobile-header-nav-link"  
                            exact to={PathNames.Reports} 
                            onClick={this.closeMobileMenu}
                            activeClassName={MobileHeaderStyles.activeMobileLinkClass}
                        >
                            Reports
                        </NavLink>

                        { this.acl.canLogInAsUser() && 
                            <NavLink
                                className="mobile-header-nav-link"  
                                exact to={PathNames.LogInAs} 
                                onClick={this.closeMobileMenu}
                                activeClassName={MobileHeaderStyles.activeMobileLinkClass}
                            >
                                Log In As
                            </NavLink>
                        }

                        { this.acl.isGlobalAdmin() &&
                            <>
                                <NavLink
                                    className="mobile-header-nav-link"  
                                    exact to={PathNames.Clients} 
                                    onClick={this.closeMobileMenu}
                                    activeClassName={MobileHeaderStyles.activeMobileLinkClass}
                                >
                                    Clients
                                </NavLink>
                                <NavLink
                                    className="mobile-header-nav-link"  
                                    exact to={PathNames.GlobalStats} 
                                    onClick={this.closeMobileMenu}
                                    activeClassName={MobileHeaderStyles.activeMobileLinkClass}
                                >
                                    Global Stats
                                </NavLink>
                                <div className={MobileHeaderStyles.clientContextSelector}>
                                    { this.acl.canSelectClientContext() &&
                                        <ClientContextSelector 
                                            clientId={this.props.clientId} 
                                        />
                                    }
                                </div>
                            </>
                        }

                        <NavLink className="mobile-header-nav-link" id="logout-link" to={PathNames.Login} onClick={this.onLogoutClick} activeClassName={MobileHeaderStyles.activeMobileLinkClass}>
                            Logout
                        </NavLink>
                        <Footer />
                    </Stack>
                </Stack>
            )
        } else if (!this.acl.canAdministrate()) {
            navigationLinks = (
                <>
                    <Stack id='mobile-menu-container-header' styles={MobileHeaderStyles.mobileMenuStackStyles}>
                        <NavLink 
                            to='#' 
                            id='mobile-menu-icon' 
                            onClick={this.state.isMenuOpen ? this.closeMobileMenu : this.openMobileMenu}
                        >
                            <Image 
                                src={this.state.isMenuOpen ? mobileMenuCloseIcon : mobileMenuOpenIcon} 
                                width='5vw' 
                                styles={MobileHeaderStyles.mobileIconStyles} 
                            />
                        </NavLink>
                        <Stack 
                            id='mobile-navigation-links-header' 
                            styles={MobileHeaderStyles.mobileLinkStackStyles} 
                            className={this.state.menuStyleClass}
                        >
                            <NavLink
                                className="mobile-header-top-nav-link"  
                                exact to={PathNames.Dashboard} 
                                onClick={this.closeMobileMenu}
                                activeClassName={MobileHeaderStyles.activeMobileLinkClass}
                            >
                                Dashboard
                            </NavLink>
                            <NavLink
                                className="mobile-header-nav-link"  
                                exact to={PathNames.Profile} 
                                onClick={this.closeMobileMenu}
                                activeClassName={MobileHeaderStyles.activeMobileLinkClass}
                            >
                                Profile
                            </NavLink>
                            <NavLink
                                className="mobile-header-nav-link"  
                                id="logout-link" 
                                to={PathNames.Login} 
                                onClick={this.onLogoutClick} 
                                activeClassName={MobileHeaderStyles.activeMobileLinkClass}
                            >
                                Logout
                            </NavLink>
                            <Footer />
                        </Stack>
                    </Stack>
                </>
            )
        } else {
            navigationLinks = (
                <NavLink to={PathNames.Login} onClick={this.closeMobileMenu}>
                    Log In
                </NavLink>
            )
        }

        return (
            <Stack 
                id='mobile-navigation-bar' 
                horizontal 
                horizontalAlign='space-evenly' 
                verticalAlign='center' 
                styles={MobileHeaderStyles.outerStackStyles}
            >
                {navigationLinks}
            </Stack>
        )
    }

    onLogoutClick = () => {
        this.authService.logout().then(() => {
            this.router.goToLogin()
        })
    }

    handleScroll = () => {
        const navigationBar = document.getElementById('mobile-navigation-bar')

        if (navigationBar) {
            window.scrollY > 10 ? navigationBar.classList.add(MobileHeaderStyles.scrollClass) : navigationBar.classList.remove(MobileHeaderStyles.scrollClass)
        }
    }

    handleClickOutside = (e: MouseEvent) => {
        let container = document.getElementById('mobile-menu-container-header')

        if (container && !container.contains(e.target as Node) && this.state.isMenuOpen) {
            this.closeMobileMenu()
        }
    }

    openMobileMenu = () => {
        this.setState({ 
            menuStyleClass: MobileHeaderStyles.openMobileNavigationLinksClass, 
            isMenuOpen: true 
        })

        let navigationBar = document.getElementById('mobile-navigation-bar')

        if (navigationBar) {
            navigationBar.classList.toggle(MobileHeaderStyles.openMobileMenuClass)
        }
    }

    closeMobileMenu = () => {
        this.setState({ 
            menuStyleClass: undefined, 
            isMenuOpen: false 
        })

        let navigationBar = document.getElementById('mobile-navigation-bar')

        if (navigationBar) {
            navigationBar.classList.toggle(MobileHeaderStyles.openMobileMenuClass)
        }
    }
}
