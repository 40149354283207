import { Component } from "react";
import { i18nKey, Ii18n } from "../../../global/i18n";
import { container } from "../../../DIContainer";
import { TYPES } from "../../../Types";
import { Stack, Text, Image } from "@fluentui/react";
import { FontSize } from "../../../ui-kit/FontSize";
import { InteractionType } from "./SurveyPage";
import shortTextIcon from "../../../images/survey/icon-short-text.svg"
import longTextIcon from "../../../images/survey/icon-open-text.svg"
import checkboxIcon from "../../../images/survey/icon-checkbox.svg"
import radioButtonIcon from "../../../images/survey/icon-radio-button.svg"
import ratingIcon from "../../../images/survey/icon-rating.svg"
import matrixIcon from "../../../images/survey/icon-matrix.svg"
import rankingIcon from "../../../images/survey/icon-ranking.svg"
import dropDownIcon from "../../../images/survey/icon-dropdown.svg"
import "./SurveyInteractionPanel.css"
import { SurveyInteractionPanelStyles } from "./SurveyInteractionPanelStyles";

interface SurveyInteractionPanelProps {
    interactionClicked: (interactionType: InteractionType) => void
}

export class SurveyInteractionPanel extends Component<SurveyInteractionPanelProps> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    render() {
        return (
            <div>
                <Stack id="interaction-stack" tokens={SurveyInteractionPanelStyles.defaultStackTokens}>
                    <Text 
                        id="title-label"
                        variant={FontSize.xLarge}>
                            {this.i18n.get(i18nKey.addInteractionPanelTitle)}
                    </Text>
                    <Stack 
                        id="interaction-container"
                        styles={SurveyInteractionPanelStyles.surveyStyles} 
                        tokens={SurveyInteractionPanelStyles.defaultStackTokens}
                    >
                        { this.createInteraction(InteractionType.shortText, this.i18n.get(i18nKey.shortText), shortTextIcon) }
                        { this.createInteraction(InteractionType.longText, this.i18n.get(i18nKey.longText), longTextIcon) }
                        { this.createInteraction(InteractionType.radioButtons, this.i18n.get(i18nKey.radioButtons), radioButtonIcon) }
                        { this.createInteraction(InteractionType.checkbox, this.i18n.get(i18nKey.checkbox), checkboxIcon) }
                        { this.createInteraction(InteractionType.rating, this.i18n.get(i18nKey.rating), ratingIcon) }
                        { this.createInteraction(InteractionType.matrix, this.i18n.get(i18nKey.matrix), matrixIcon) }
                        { this.createInteraction(InteractionType.ranking, this.i18n.get(i18nKey.ranking), rankingIcon) }
                        { this.createInteraction(InteractionType.dropDown, this.i18n.get(i18nKey.dropDown), dropDownIcon) }
                    </Stack>
                </Stack>
            </div> 
        )
    }

    /**
     * Private Functions
     */

    private interactionClicked = (interactionType: InteractionType) => {
        this.props.interactionClicked(interactionType)
    }

    private createInteraction = (type: InteractionType, title: string, icon: any) => {
        return <Stack 
                    id={`${type}-interaction`}
                    className="survey-interaction" 
                    tokens={SurveyInteractionPanelStyles.surveyInteractionTokens} 
                    onClick={() => this.interactionClicked(type)} 
                    horizontal
                >
                    <Image src={icon} styles={SurveyInteractionPanelStyles.imageDimensions} />
                    <Text variant={FontSize.medium}>{title}</Text>
                </Stack>
    }
}
