import Course from "../../../models/Course"
import React, { ReactElement, useEffect, useState } from "react"
import { Stack } from "@fluentui/react"
import { ILRSService } from "../../../services/LRSService"
import { TYPES } from "../../../Types"
import { container } from "../../../DIContainer"
import { IAuth } from "../../../auth/Auth"
import { LRSStatement } from "../../../models/LRSStatement"
import { DocumentId } from "../../../global/TypeAliases"
import { Ii18n, i18nKey } from "../../../global/i18n"
import { IFeatureFlagService } from "../../../services/FeatureFlagService"
import { log } from "../../../global/Logger"
import { IDeadlinesHelper } from "../../../utils/DeadlinesHelper"
import { Card, CardBody, CardFooter, Col, Progress } from "reactstrap"
import logoLarge from "../../../images/dts-logo-with-black-text.png"

interface TrainingGridItemProps {
    training: Course
    userClients?: DocumentId[]
    onCourseClick: (e: React.MouseEvent<HTMLDivElement>) => void
    showProgressBar: boolean
    deadline?: string
}

interface TrainingGridItemState {
    progress: number
}

export const TrainingGridItem: React.FC<TrainingGridItemProps> = ({training, userClients, onCourseClick, showProgressBar, deadline}) => {
    const lrsService: ILRSService = container.get<ILRSService>(TYPES.ILRSService)
    const auth = container.get<IAuth>(TYPES.IAuth)
    const i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    const featureFlagService: IFeatureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService)
	const deadlinesHelper: IDeadlinesHelper = container.get<IDeadlinesHelper>(TYPES.IDeadlinesHelper)

    const [progress, setProgress] = useState<TrainingGridItemState['progress']>(0)

    useEffect(() => {
        if (showProgressBar && auth.user !== undefined) {
            const sharedClientIds = mapCourseClientsToUserClients()
            let highestProgress: LRSStatement[]
            let highestProgressPercentage: number = 0
            const user = auth.user
    
            sharedClientIds.forEach(client => {
                lrsService
                    .getOldestPassForCourse(user.uid, client, training.lrsId)
                    .then((statements) => {
                        if (statements.length >= 1) {
                            highestProgressPercentage = 100
                            setProgress(highestProgressPercentage)
                        }

                        else {
                            lrsService
                                .getProgressionOnCourseForUser(user.uid, client, training.lrsId)
                                .then((statements) => {
                                    highestProgress = statements

                                    if (highestProgress.length > 0 
                                        && highestProgress[0].progress !== null 
                                        && highestProgress[0].progress > progress) {
                                            setProgress(highestProgress[0].progress)
                                        }
                                })
                                .catch(_ => {
                                    log("Failed to get progress statements")
                                })
                        }
                    })
                    .catch(_ => {
                        log("Failed to get passed statements")
                    })
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const mapCourseClientsToUserClients = (): DocumentId[] => {
		let sharedClientIds: DocumentId[] = []

        if (!userClients) {
            return sharedClientIds
        }

		userClients.forEach(potentialClient => {
			if (training.clients[potentialClient] !== undefined) {
				sharedClientIds.push(potentialClient)
			}
		});

		return sharedClientIds
	}

    const onCourseClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (showProgressBar && auth.user !== undefined) {
            const launchLocation = "trainings"
            
            const user = auth.user
            let sharedClientIds = mapCourseClientsToUserClients()
            sharedClientIds.forEach(client => {
                lrsService
                    .postTrainingLaunchLocation(user.uid, client, training.lrsId, launchLocation)
                    .then(() => {
                        log("postTrainingLaunchLocation success")
                    }).catch(error => {
                        log("postTrainingLaunchLocation failed: " + error)
                    })
            })
        }
        
        onCourseClick(e)
    }

    const renderCardFooter = (): ReactElement => {
        if (featureFlagService.deadline() && deadline && progress < 100) {
            let deadlineCopy = deadlinesHelper.renderTrainingDeadline(deadline)
            if (deadlineCopy !== "") {
                return <p id="training-grid-item-deadline-label" className="card-text text-danger font-size-16">{deadlineCopy}</p>
            }
        }

        return <p id="training-grid-item-duration-label" className="card-text font-size-16">{training.duration}</p>
    }

    /**
     * Begin code to render the component
     */

    let progressBar = null
    let startTraining = ""

    if (showProgressBar) {
        if (progress === 0) {
            startTraining = i18n.get(i18nKey.startTraining)
        }

        if (progress === 100) {
            progressBar =   
                <div id="training-grid-item-completed-progress-bar-container" className="animated-progess mb-3">
                    <Progress id="training-grid-item-progress-bar" value={progress} color="primary" className="progress-sm"></Progress>
                    <br/>
                    <Stack horizontal={true}>
                        <i id="training-grid-item-completed-icon" className="bx bxs-badge-check text-success font-size-16 align-middle me-2"></i>
                        <h5 id="training-grid-item-completed-label" className="card-text font-size-16 text-success">
                            {i18n.get(i18nKey.completed)}
                        </h5>
                    </Stack>
                </div>
        }

        else {
            progressBar =
                <div id="training-grid-item-progress-bar-container" className="animated-progess mb-3">
                    <Progress id="training-grid-item-progress-bar" value={progress} color="primary" className="progress-sm"></Progress>
                    <br/>
                    <h5 id="training-grid-item-progress-percentage" className="card-text font-size-16">{progress + i18n.get(i18nKey.percentComplete)}</h5>
                </div>
        }
    }

    const imageShown = (training.imageUrl !== undefined && training.imageUrl !== null && training.imageUrl !== "") ? training.imageUrl : logoLarge
    const objectFitStyle = (training.imageUrl !== undefined && training.imageUrl !== null && training.imageUrl !== "") ? 'cover' : 'contain'

    return ( 
        <div className='training-grid-item-container' id={training.documentId} onClick={onCourseClickHandler}>
            <Col md={6} xl={2}>
                <Card 
                    style={{
                        width: '300px', 
                        height: '355px',
                        borderRadius: '10px',
                        cursor: "pointer",
                        backgroundColor: "white", 
                        border: "none"
                    }}
                >
                    <img 
                        id="training-grid-item-card-image"
                        className="card-img-top img-fluid rounded" 
                        src={imageShown} 
                        style={{
                            height: '10rem',
                            width: '100%',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            objectFit: objectFitStyle
                        }}
                        alt="training cover" 
                    />
                    <CardBody style={{height: "25%"}}>
                        <h1 id="training-grid-item-card-title-name" className="card-title">{training.name}</h1>
                        <p id="training-grid-item-card-description-text" className="card-text">{training.description}</p>
                    </CardBody>
                    <CardFooter style={{height: "33.5%"}}>
                        {progressBar}
                        <div 
                            style={{            
                                height: '20%',
                                justifyContent: "space-between",
                                display: "flex"
                            }}
                        >
                            { startTraining !== "" && 
                                <p id="training-grid-item-start-training-label" className="card-text text-primary font-size-16" color="primary">
                                    {startTraining}
                                </p>
                            }
                            {renderCardFooter()}
                        </div>
                    </CardFooter>
                </Card>
            </Col>
        </div>
    )
}