import React, { Component } from "react"
import { Stack, Text } from '@fluentui/react'
import { MarketingIi18n, i18nKey } from "../global/Marketing-i18n"
import { container } from "../DIContainer"
import { TYPES } from "../Types"
import { LandingFeaturesStyles } from "./LandingFeaturesStyles"
import { MarketingButton } from './MarketingButton'
import { IRouter } from '../Router'

export class LandingFeatures extends Component {
    private i18n = container.get<MarketingIi18n>(TYPES.MarketingIi18n)
    private router = container.get<IRouter>(TYPES.IRouter)

    render() {
        return (
            <Stack horizontalAlign="center" styles={LandingFeaturesStyles.outerStackStyles} tokens={{childrenGap: "4vw"}}>
                <Stack horizontal wrap>
                    <Stack styles={LandingFeaturesStyles.innerStackStyles}>
                        <Text styles={LandingFeaturesStyles.sectionNameTextStyles}>
                            {this.i18n.get(i18nKey.digital).toUpperCase()}
                        </Text>
                        <Text styles={LandingFeaturesStyles.sectionSubHeaderTextStyles}>
                            {this.i18n.get(i18nKey.digitalSubHeader)}
                        </Text>
                        <Text styles={LandingFeaturesStyles.sectionInfoTextStyles}>
                            {this.i18n.get(i18nKey.digitalInfo)}
                        </Text>
                    </Stack>
                    <Stack styles={LandingFeaturesStyles.innerStackStyles}>
                        <Text styles={LandingFeaturesStyles.sectionNameTextStyles}>
                            {this.i18n.get(i18nKey.training).toUpperCase()}
                        </Text>
                        <Text styles={LandingFeaturesStyles.sectionSubHeaderTextStyles}>
                            {this.i18n.get(i18nKey.trainingSubHeader)}
                        </Text>
                        <Text styles={LandingFeaturesStyles.sectionInfoTextStyles}>
                            {this.i18n.get(i18nKey.trainingInfo)}
                        </Text>
                    </Stack>
                    <Stack styles={LandingFeaturesStyles.innerStackStyles}>
                        <Text styles={LandingFeaturesStyles.sectionNameTextStyles}>
                            {this.i18n.get(i18nKey.solutions).toUpperCase()}
                        </Text>
                        <Text styles={LandingFeaturesStyles.sectionSubHeaderTextStyles}>
                            {this.i18n.get(i18nKey.solutionsSubHeader)}
                        </Text>
                        <Text styles={LandingFeaturesStyles.sectionInfoTextStyles}>
                            {this.i18n.get(i18nKey.solutionsInfo)}
                        </Text>
                    </Stack>
                </Stack>
                <MarketingButton
                    id="features-discover-button"
                    text={this.i18n.get(i18nKey.discoverMore).toUpperCase()}
                    onClick={this.discoverMoreButtonClicked}
                    width="15vw"
                    mobileWidth="50vw"
                />
            </Stack>
        )
    }

    discoverMoreButtonClicked = () => {
        this.router.goToFeatures()
    }
}