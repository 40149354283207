import React, { Component } from 'react'
import { Stack, Text } from '@fluentui/react'
import { MarketingIi18n, i18nKey } from '../global/Marketing-i18n'
import { container } from '../DIContainer'
import { TYPES } from '../Types'
import { TestimonialsHeroStyles } from "./TestimonialsHeroStyles"

export class TestimonialsHero extends Component {
    private i18n = container.get<MarketingIi18n>(TYPES.MarketingIi18n)

    render() {
        return (
            <Stack styles={TestimonialsHeroStyles.stackStyles}>
                <Text styles={TestimonialsHeroStyles.headerTextStyles}>
                    {this.i18n.get(i18nKey.testimonialsHeroHeader)}
                </Text>
                <Text styles={TestimonialsHeroStyles.subHeaderTextStyles}>
                    {this.i18n.get(i18nKey.testimonialsHeroSubHeader)}
                </Text>
            </Stack>
        )
    }
}
