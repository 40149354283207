import { IStackStyles, ITextStyles, IImageStyles } from '@fluentui/react'

export class TestimonialCardStyles {
    static outerStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    maxWidth: '100%',
                    borderRadius: '1.5vw',
                    marginLeft: '1vw',
                    marginBottom: '8vw',
                    paddingRight: '12vw',
                    paddingLeft: '8vw',
                    paddingBottom: '10vw',
                    paddingTop: '6vw',
                },
            },

            background: '#FFFFFF',
            maxWidth: '19vw',
            borderRadius: '.3vw',
            paddingRight: '2.5vw',
            paddingLeft: '1.5vw',
            paddingBottom: '2vw',
            paddingTop: '1.5vw',
            marginLeft: '3vw',
            boxShadow: '0 2px 7px 0 rgba(0,0,0,0.1)',
        },
    }

    static titleTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '3.5vw',
                    maxWidth: '50vw',
                },
            },

            fontFamily: 'Lato, sans serif',
            color: '#000000',
            fontWeight: 'bold',
            fontSize: '.9vw',
            maxWidth: '20vw',
        },
    }

    static primaryTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '3.5vw',
                    maxWidth: '100%',
                    lineHeight: '7vw',
                    marginBottom: '4vw',
                },
            },

            fontFamily: 'Lato, sans serif',
            color: '#7c7c7c',
            fontWeight: 600,
            fontSize: '.9vw',
            maxWidth: '15vw',
            marginBottom: '2vw',
            paddingTop: '2vw',
            lineHeight: '1.5vw',
        },
    }

    static logoStyles: Partial<IImageStyles> = {
        image: {
            selectors: {
                '@media(max-width: 600px)': {
                    width: '40vw',
                    paddingBottom: '4vw',
                },
            },
            width: '9vw',
        },
    }
}
