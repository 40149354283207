import React, { Component } from 'react'
import { Image, Stack, Text } from '@fluentui/react'

import { TestimonialCardStyles } from './TestimonialCardStyles'

interface TestimonialProps {
    Text: string
    Title: string
    Logo: string
}

export class TestimonialCard extends Component<TestimonialProps> {
    render() {
        return (
            <Stack styles={TestimonialCardStyles.outerStackStyles}>
                <Stack>
                    <Image id='testimonial-company-logo' src={this.props.Logo} styles={TestimonialCardStyles.logoStyles} />
                </Stack>
                <Stack>
                    <Text id='testimonial-text' styles={TestimonialCardStyles.primaryTextStyles}>
                        {this.props.Text}
                    </Text>
                </Stack>
                <Stack>
                    <Text id='testimonial-title' styles={TestimonialCardStyles.titleTextStyles}>
                        {this.props.Title}
                    </Text>
                </Stack>
            </Stack>
        )
    }
}
