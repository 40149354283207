import { Dictionary } from '../global/TypeAliases';

export default class Client {
    documentId: string
    name: string
    description: string
    imageUrl: string | undefined
    domains: Dictionary
    defaultCohort: string | undefined
    cleverDistrictId: string
    active: boolean
    licenseSeatCount: number | undefined
    validUntilDate: Date | undefined
    licenseNotes: string | undefined

    domain(): string | null {
        /**
         *	Assumption is user should only have 1 domain they belong to.
         */ 
        const keys = Object.keys(this.domains)

        if (keys.length > 0) {
            return keys[0]
        }

        return null
    }

    constructor(
        documentId: string, 
        name: string, 
        description: string, 
        imageUrl: string | undefined, 
        domains: Dictionary, 
        defaultCohort: string | undefined,
        cleverDistrictId: string,
        active: boolean,
        licenseSeatCount: number | undefined,
        validUntilDate: Date | undefined,
        licenseNotes: string | undefined) {
        this.documentId = documentId
        this.name = name
        this.description = description
        this.imageUrl = imageUrl
        this.domains = domains
        this.defaultCohort = defaultCohort
        this.cleverDistrictId = cleverDistrictId
        this.active = active
        this.licenseSeatCount = licenseSeatCount
        this.validUntilDate = validUntilDate
        this.licenseNotes = licenseNotes
    }

    static Converter = {
        toFirestore: function(client: Client) {
            return {
                documentId: client.documentId,
                imageUrl: client.imageUrl,
                name: client.name,
                description: client.description,
                domains: client.domains,
                defaultCohort: client.defaultCohort,
                cleverDistrictId: client.cleverDistrictId,
                active: client.active,
                licenseSeatCount: client.licenseSeatCount,
                validUntilDate: client.validUntilDate,
                licenseNotes: client.licenseNotes
            }
        },
        fromFirestore: function(snapshot: any, options: any) {
            const data = snapshot.data(options);
            return new Client(
                data.documentId,
                data.name,
                data.description,
                data.imageUrl,
                data.domains === undefined ? {} : data.domains,
                data.defaultCohort,
                data.cleverDistrictId === undefined ? "" : data.cleverDistrictId,
                data.active === undefined ? false : data.active,
                data.licenseSeatCount,
                data.validUntilDate === undefined ? undefined : data.validUntilDate.toDate(),
                data.licenseNotes
            )
        }
    }
}

