import { Component } from "react"
import { IAuth } from "../../auth/Auth"
import { container } from "../../DIContainer"
import { i18nKey, Ii18n } from "../../global/i18n"
import { DocumentId } from "../../global/TypeAliases"
import { IRouter } from "../../Router"
import { IAccessController } from "../../services/AccessController"
import { TYPES } from "../../Types"
import { ErrorDialog } from "../../ui-kit/ErrorDialog"
import { SelectClientDialog } from "../../ui-kit/SelectClientDialog"

export interface ClientContextProp {
    clientId: DocumentId
}

export const withClientContext = <P extends object>(ChildComponent: React.ComponentType<P>) => {
    return class RequireClientContext extends Component {
        private auth: IAuth = container.get<IAuth>(TYPES.IAuth)
        private acl: IAccessController = container.get<IAccessController>(TYPES.IAccessController)
        private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
        private router: IRouter = container.get<IRouter>(TYPES.IRouter)

        render() {
            const clientId = this.auth.getClientContext()

            if (clientId) {
                return <ChildComponent clientId={clientId} {...this.props as P} />
            }

            if (this.acl.isGlobalAdmin()) {
                return (
                    <SelectClientDialog
                        target="admin-page"
                        onDismiss={() => {}}
                        onSelectClientClicked={() => {}}
                        clientId={this.auth.getClientContext()}
                    />
                ) 
            }

            return(
                <ErrorDialog
                    title={this.i18n.get(i18nKey.clientIdNullErrorDialogTitle)}
                    message={this.i18n.get(i18nKey.clientIdNullErrorDialogMessage)}
                    onClick={this.errorDialogOnClick}
                />
            )
        }

        private errorDialogOnClick = () => {
            this.router.goToDashboard()
        }
    }
}