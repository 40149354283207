import React, { Component } from "react";
import { Text, Stack, IStackTokens } from "@fluentui/react";
import { FontSize } from "./FontSize";
import { NavLink } from "react-router-dom";
import { ENVIRONMENT } from "../services/Firebase";
import { Theme } from "./Theme";
import { PathNames } from "../PathNames";
import { container } from "../DIContainer";
import { i18nKey, Ii18n } from "../global/i18n";
import { TYPES } from "../Types";

interface FooterProps {
    ssoFooter?: boolean
}

interface FooterState {}

export class Footer extends Component<FooterProps, FooterState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    private navLinkStyles: React.CSSProperties = {
        color: this.props.ssoFooter ? Theme.color.fifth : Theme.color.fourth
    }

    private footerStyles = {
        root: { 
            color: this.props.ssoFooter ? Theme.color.fifth : Theme.color.fourth, 
            paddingBottom: 10
        }
    }

    private stackStyling: IStackTokens = {
        childrenGap: 10,
    }

    render() {
        let cssStyle: React.CSSProperties = {}

        if (this.props.ssoFooter) {
            cssStyle = {
                position: "fixed",
                bottom: 0,
                width: '100%'
            }
        }

        let env = process.env.REACT_APP_ENV === ENVIRONMENT.prod ? "" : " - " + process.env.REACT_APP_ENV
        return (
            <Stack id="footer" horizontalAlign={"center"} tokens={this.stackStyling} styles={this.footerStyles} style={cssStyle}>
                <Text variant={FontSize.small} styles={this.footerStyles}>{this.i18n.get(i18nKey.digitalTrainingSolutions)}{env}</Text>
                <Stack horizontal={true} horizontalAlign={"center"} tokens={this.stackStyling} styles={this.footerStyles}>
                    {/* <NavLink to="/help" style={this.navLinkStyles}><Text variant={FontSize.small}>Help</Text></NavLink>
                    <NavLink to="/sendFeedback" style={this.navLinkStyles}><Text variant={FontSize.small}>Send Feedback</Text></NavLink> */}
                    <NavLink to={PathNames.PrivacyPolicy} style={this.navLinkStyles} id="privacyLink"><Text variant={FontSize.small} styles={this.footerStyles}>Privacy Policy</Text></NavLink>
                </Stack>
            </Stack>
        )
    }
}
