import React, { Component, FormEvent, MouseEvent } from "react"
import { NavLink } from "react-router-dom"
import { 
	Image,
	MessageBar, 
	MessageBarType,
	Text,
	Stack, 
} from '@fluentui/react'
import { TextField } from '../../ui-kit/TextField'

import { AuthenticationUIStyles, AuthenticationUITokens } from "./AuthenticationUI"
import logoLarge from "../../images/logo-large.svg"
import cleverLogo from '../../images/clever-logo.png'

import { IAuthService } from "../../services/AuthService"
import { IAuth, IAuthObserver } from "../../auth/Auth"
import { container } from "../../DIContainer"
import { TYPES } from "../../Types"
import { SecondaryButton } from "../../ui-kit/SecondaryButton"
import { Spinner } from "@fluentui/react"
import { Ii18n, i18nKey } from "../../global/i18n"
import { IRouter } from "../../Router"
import { PrimaryButtonWithImage } from "../../ui-kit/PrimaryButtonWithImage"
import { PrimaryButton } from "../../ui-kit/PrimaryButton"
import { IFeatureFlagService } from "../../services/FeatureFlagService"
import { PathNames } from "../../PathNames"
import { IUserService } from "../../services/UserService"

interface LoginPageProps {
	initialErrorMessage?: string
}
interface LoginPageState {
	signInButtonDisabled: boolean
	spinnerActive: boolean
	emailAddress: string
	password: string
	errorMessage: string | null
	infoMessage: string | null
	showVerifyLink: boolean,
	verificationEmailSent: string | null
}

export default class LoginPage extends Component<LoginPageProps, LoginPageState> implements IAuthObserver {
	private authService: IAuthService = container.get<IAuthService>(TYPES.IAuthService)
	private auth: IAuth = container.get<IAuth>(TYPES.IAuth)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
	private router: IRouter = container.get<IRouter>(TYPES.IRouter)
	private featureFlags: IFeatureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService)
	private userService: IUserService = container.get<IUserService>(TYPES.IUserService)

	state: LoginPageState = {
		signInButtonDisabled: true,
		spinnerActive: false,
		emailAddress: "",
		password: "",
		errorMessage: null,
		infoMessage: null,
		showVerifyLink: false,
		verificationEmailSent: null
	}

	componentDidMount() {
		if (this.auth.isLoggedIn()) {
			this.router.goToDashboard()
		} else if (this.props.initialErrorMessage) {
			this.setState({errorMessage: this.props.initialErrorMessage})
		}

        document.getElementById('container')?.classList.add('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.add('hidden')
		document.getElementById('navigation-bar')?.classList.add('hidden')
		
		this.auth.addAuthObserver(this)
    }

    componentWillUnmount() {
        document.getElementById('container')?.classList.remove('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.remove('hidden')
        document.getElementById('navigation-bar')?.classList.remove('hidden')
    }

	render() {		
		return (
			<Stack horizontalAlign="center" styles={AuthenticationUIStyles.containerStyle}>
				<Stack horizontalAlign="center" styles={AuthenticationUIStyles.logoStyle}>
					<Image 
						id="dts-logo" 
						alt={this.i18n.get(i18nKey.altDtsLogo)} 
						src={logoLarge} 
						width={230} 
						height={100} 
					/>
				</Stack>
				<Stack tokens={AuthenticationUITokens.verticalGapStackTokens} styles={AuthenticationUIStyles.formStackStyles}>
					{ this.state.errorMessage != null && 
						<MessageBar
							id="login-error-banner"
							messageBarType={MessageBarType.error}
							isMultiline={true}
							onDismiss={this.dismissBanner}>
							{this.state.errorMessage}
						</MessageBar>
					}

					{ this.state.infoMessage != null && 
						<MessageBar
							id="info-banner"
							messageBarType={MessageBarType.info}
							isMultiline={true}
							onDismiss={this.dismissBanner}>
							{this.state.infoMessage}
						</MessageBar>
					}

					<Stack horizontalAlign="center" tokens={{childrenGap: 10}}>
						<Text variant="xxLarge"><strong>{this.i18n.get(i18nKey.login).toUpperCase()}</strong></Text>
						<Text variant="medium">{this.i18n.get(i18nKey.enterEmailAndPassword)}</Text>
					</Stack>

					{ this.state.showVerifyLink &&
						<Stack id="verifyEmailLink" styles={AuthenticationUIStyles.emailVerificationStyle}>
							<h5>{this.i18n.get(i18nKey.stillNeedToVerifyEmail)}</h5>
							<SecondaryButton
								id="sendEmailVerificationButton"
								text={this.i18n.get(i18nKey.sendEmailVerification)}
								onClick={this.sendEmailVerification}
							/>
						</Stack>
					}

					{ this.state.verificationEmailSent != null && 
						<MessageBar
							id="email-verification-sent-banner"
							messageBarType={MessageBarType.success}
							isMultiline={true}
							onDismiss={this.dismissBanner}>
							{this.state.verificationEmailSent}
						</MessageBar>
					}

					<Stack tokens={AuthenticationUITokens.formFieldGapStackTokens}>
						<TextField
							id="usernameTextField"
							label={this.i18n.get(i18nKey.yourEmailAddress)}
							type="email"
							onChange={this.emailAddressTextFieldChanged}
							autoComplete="username"
							onKeyDown={this.handleEnterKey}
						/>
						<TextField
							id="passwordTextField"
							label={this.i18n.get(i18nKey.password)}
							type="password"
							onChange={this.passwordTextFieldChanged}
							autoComplete="current-password"
							onKeyDown={this.handleEnterKey}
						/>

						{ !this.state.spinnerActive &&
							<Stack tokens={AuthenticationUITokens.formFieldGapStackTokens}>
								<PrimaryButton
									id="primaryButton"
									text={this.i18n.get(i18nKey.signIn)}
									onClick={this.submitButtonClicked}
									disabled={this.state.signInButtonDisabled}
								/>

								{ this.featureFlags.signInWithClever() && 
									<PrimaryButtonWithImage
										id="signInWithCleverButton"
										src={cleverLogo}
										text={this.i18n.get(i18nKey.signInWithClever)}
										onClick={this.signInWithCleverButtonClicked}
									/>
								}
							</Stack>
						}

						{ this.state.spinnerActive &&
							<Spinner
								id="login-spinner"
								label={this.i18n.get(i18nKey.spinnerSigningIn)}
								ariaLive="assertive"
								labelPosition="right"
							/>
						}

					</Stack>
					<Stack horizontalAlign="center">
						<Text variant="medium">
							{this.i18n.get(i18nKey.forgotPassword)}{" "}
							<NavLink 
								to={PathNames.ResetPasswordRequest} 
								id="forgotPasswordLink">
								{this.i18n.get(i18nKey.resetPassword)}
							</NavLink>
						</Text>
					</Stack>
				</Stack>
			</Stack>
		)
	}

	sendEmailVerification = (_: MouseEvent<HTMLButtonElement>) => {
		const user = this.auth.firebaseUser()

		if (user === null) {
			// TODO: Try to get a user again
			return
		}

		this.setState({ 
			errorMessage: null, 
			showVerifyLink: false, 
			verificationEmailSent: null 
		})

		this.authService
			.sendEmailVerification(user)
			.then(() => {
				this.setState({ verificationEmailSent: this.i18n.get(i18nKey.verificationEmailSent) })
			}).catch(error => {
				this.setState({ errorMessage: error.message, verificationEmailSent: null })
			})
	}

	submitButtonClicked = () => {
		this.setState({ errorMessage: null, spinnerActive: true, signInButtonDisabled: true })

		this.userService
			.isCleverUser(this.state.emailAddress)
			.then(isCleverUser => {
				if (isCleverUser) {
					this.setState({ 
						infoMessage: this.i18n.get(i18nKey.pleaseSignInWithClever),
						spinnerActive: false,
						signInButtonDisabled: false
					})

					return Promise.resolve(null)
				}

				return this.authService.login(this.state.emailAddress, this.state.password)
			}).then(user => {
				if (!user) {
					return
				}

				this.setState({
					showVerifyLink: !user.emailVerified,
					signInButtonDisabled: false,
					spinnerActive: user.emailVerified
				})
			}).catch(error => {
				this.setState({ 
					errorMessage: error.message,
					spinnerActive: false,
					signInButtonDisabled: false
				})
			})
	}

	signInWithCleverButtonClicked = () => {
		this.router.goToCleverLogin()
	}

	emailAddressTextFieldChanged = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({ emailAddress: newValue? newValue : ""}, () => {
			this.disableSignInButtonIfNeeded()
		})
	}

	passwordTextFieldChanged = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({ password: newValue? newValue : ""}, () => {
			this.disableSignInButtonIfNeeded()
		})
	}

	dismissBanner = () => {
		this.setState({ 
			errorMessage: null, 
			infoMessage: null, 
			verificationEmailSent: null 
		})
	}

	disableSignInButtonIfNeeded = () => {
		var disable = true

		if (this.state.emailAddress.length > 0 
			&& this.state.password.length > 0) {
			disable = false
		}

		this.setState({ signInButtonDisabled: disable })
	}

	handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (event.key === 'Enter') {
			this.submitButtonClicked()
		}
	}

	 /**
     * IAuthObserver delegate
     */
    onAuthStateChange(isLoggedIn: boolean): void {
        if (isLoggedIn) {
			this.router.goToDashboard()
		}
    }
}
