import { MessageBar, MessageBarType, Modal, Stack, Text } from "@fluentui/react";
import { Component } from "react";
import { container } from "../../DIContainer";
import { IChartFactory } from "../../factories/ChartFactory";
import { IDataTableFactory } from "../../factories/DataTableFactory";
import { i18nKey, Ii18n } from "../../global/i18n";
import { Dictionary, DocumentId } from "../../global/TypeAliases";
import { ChartData } from "../../models/ChartData";
import DTSTableData from "../../models/DTSTableData";
import { Survey } from "../../models/Survey";
import { SurveyReportCSVExporter } from "../../services/SurveyReportCSVExporter";
import { ISurveyReportPageCompositionService } from "../../services/SurveyReportPageCompositionService";
import { TYPES } from "../../Types";
import { ChartCard } from "../../ui-kit/ChartCard";
import { CMPieChart } from "../../ui-kit/CMPieChart";
import DTSTable from "../../ui-kit/DTSTable";
import { FontSize } from "../../ui-kit/FontSize";
import { Loader } from "../../ui-kit/Loader";
import PageTitle from "../../ui-kit/PageTitle";
import { ReportUserSurveyDetailsModal } from "./ReportUserSurveyDetailsModal";
import { SurveyAggregatedReportDetailsContainer } from "./SurveyAggregatedReportDetailsContainer";

interface SurveyReportPageProps {
    surveyId: DocumentId
    clientId: DocumentId
}

interface SurveyReportPageState {
    selectedSurvey: Survey | null
    tableData: DTSTableData | null
    ratioCompletedMetric: ChartData[] | null
    ratioAttemptedMetric: ChartData[] | null
    serviceComplete: boolean
    isModalOpen: boolean
    selectedUserId: string | undefined
    selectedRow: { [key: string]: string } | undefined
    errorMessage: string | null
    isAssignedToGroups: boolean
    surveyStateData: Dictionary[]
}

export class SurveyReportPage extends Component<SurveyReportPageProps, SurveyReportPageState> {
    private compositionService: ISurveyReportPageCompositionService = container.get<ISurveyReportPageCompositionService>(TYPES.ISurveyReportPageCompositionService)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
    private chartFactory: IChartFactory = container.get<IChartFactory>(TYPES.IChartFactory)
    
    state: SurveyReportPageState = {
        selectedSurvey: null,
        tableData: null,
        ratioCompletedMetric: null,
        ratioAttemptedMetric: null,
        serviceComplete: false,
        isModalOpen: false,
        selectedUserId: undefined,
        selectedRow: undefined,
        errorMessage: null,
        isAssignedToGroups: false,
        surveyStateData: []
    }

    componentDidMount() {
        this.load()
    }
    
    render() {
        if (!this.state.serviceComplete) {
            return <Loader />
        }

        const survey = this.state.selectedSurvey

        const pageTitle = survey ? `Survey Report For ${survey.name}` : `Survey Report`

        // Titles
        const ratioCompletedMetricTitle = this.i18n.get(i18nKey.ratioSurveyCompletedMetricTitle)
        const ratioAttemptedMetricTitle = this.i18n.get(i18nKey.ratioSurveyAttemptedMetricTitle)

        // Metrics
        const ratioCompletedMetric = this.state.ratioCompletedMetric
        const ratioAttemptedMetric = this.state.ratioAttemptedMetric
    
        return (
            <>
                <PageTitle title={pageTitle} />

                { !this.state.errorMessage && this.state.isAssignedToGroups && ratioCompletedMetric && ratioAttemptedMetric && 
                    <div id="report-completion-statistics-container">
                        <Stack 
                            verticalAlign="center" 
                            horizontal 
                            horizontalAlign="center" 
                            wrap
                            tokens={{childrenGap: "1rem"}}
                        >
                            <ChartCard
                                id="ratio-completed-metric-chart"
                                title={ratioCompletedMetricTitle}
                                element={<CMPieChart data={ratioCompletedMetric} />}
                            />
                            <ChartCard
                                id="ratio-attempted-metric-chart"
                                title={ratioAttemptedMetricTitle}
                                element={<CMPieChart data={ratioAttemptedMetric} />}
                            />
                            { this.state.selectedSurvey !== null && 
                                <SurveyAggregatedReportDetailsContainer
                                    survey={this.state.selectedSurvey}
                                    surveyResponseData={this.state.surveyStateData}
                                    clientId={this.props.clientId}
                                />
                            }
                        </Stack>
                    </div>
                }

                <div id="survey-report-page-container">
                    { !this.state.errorMessage && this.state.isAssignedToGroups && this.state.selectedSurvey !== null && this.state.tableData !== null &&
                        <Stack horizontalAlign="center" styles={{ root: { paddingLeft: 50, paddingRight: 50, marginTop: 20 }}}>
                            <Stack styles={{ root: { width: "75%", marginBottom: 20 } }} tokens={{ childrenGap: 50 }} horizontal>
                                <SurveyReportCSVExporter survey={this.state.selectedSurvey} surveyData={this.state.surveyStateData} fileName={this.state.selectedSurvey!.name} />
                            </Stack>
                            <DTSTable
                                data={this.state.tableData}
                                aria-label="survey-report-page-data-table"
                                onRowClicked={this.onRowClicked}
                                pointerOnHover={true}
                                highlightOnHover={true}
                                filter={true}
                                pagination={true}
                                filterPlaceholder={this.i18n.get(i18nKey.searchForLearner)}
                            />     
                            <Modal
                                titleAriaId="user report modal"
                                isOpen={this.state.isModalOpen}
                                onDismiss={this.modalDismissed}
                                isBlocking={false} >
                                {this.createModalElement()}
				            </Modal>  
                        </Stack>
                    } 

                    { !this.state.isAssignedToGroups &&
                        <Stack id='no-group-assigned' verticalAlign="space-evenly" horizontal horizontalAlign="center" styles={{ root: { paddingLeft: 50, paddingRight: 50, marginTop: 20 }}}>
                            <Text variant={FontSize.medium}>
                                {this.i18n.get(i18nKey.noGroupAssigned)}
                            </Text>
                        </Stack>
                    } 

                    { this.state.errorMessage &&
                        <MessageBar
                            id="survey-report-page-error-banner"
							messageBarType={MessageBarType.error}
							dismissButtonAriaLabel="Close"
						>
							{this.state.errorMessage}
                        </MessageBar>
                    }
                </div>
            </>
        )
    }

    modalDismissed = () => {
		this.setState({ isModalOpen: false })
    }

    onRowClicked = (row: { [key: string]: string }) => {
		this.setState({
            selectedUserId: row.id,
            selectedRow: row,
            isModalOpen: true,
		})
    }

    createModalElement = () => {
		if (!this.state.isModalOpen) {
			return null
        }
        
        if(this.state.selectedUserId !== undefined && this.state.selectedRow !== undefined && this.props.clientId !== undefined && this.state.selectedSurvey) {
            return (
                <ReportUserSurveyDetailsModal
                    userId={this.state.selectedUserId!}
                    details={this.state.selectedRow!}
                    clientId={this.props.clientId!}
                    survey={this.state.selectedSurvey!}
                />
                )
        }
	}

    private load = () => {
        this.compositionService
            .getData(this.props.clientId, this.props.surveyId)
            .then(result => {
                const tableData = this.dataTableFactory.createSurveyCompletionTableData(result.statements, result.cohorts, result.attemptStatements)
                const ratioCompletedMetric = this.chartFactory.ratioCompleted(result.statements)
                const ratioAttemptedMetric = this.chartFactory.ratioAttempted(result.attemptStatements)

                this.setState({
                    selectedSurvey: result.survey,
                    tableData: tableData,
                    ratioCompletedMetric: ratioCompletedMetric,
                    ratioAttemptedMetric: ratioAttemptedMetric,
                    serviceComplete: true,
                    errorMessage: null,
                    isAssignedToGroups: true,
                    surveyStateData: result.surveyStateData
                })
            }).catch(error => {
                let errorMessage: string | null = this.i18n.get(i18nKey.reportCompletionFailedLoadReport)
    
                /**
                 * Code 66 means the client id doesn't exist in the LRS
                 */
                if (error.message.includes(this.i18n.get(i18nKey.code66))) {
                    errorMessage = error.message
                }

                if (error.message === this.i18n.get(i18nKey.noGroupAssigned)) {
                    errorMessage = null
                }
    
                this.setState({
                    serviceComplete: true,
                    errorMessage: errorMessage
                })
            })
    }

}