import { Component, MouseEvent } from 'react';
import { container } from '../DIContainer';
import { i18nKey, Ii18n } from '../global/i18n';
import { Dictionary } from '../global/TypeAliases';
import DTSTableData from '../models/DTSTableData';
import { TYPES } from '../Types';
import { PrimaryButton } from '../ui-kit/PrimaryButton';
import { CSVFileNameCreator } from '../utils/CSVHelperFunctions';

interface ReportCSVErrorState {
  primaryButtonDisabled: boolean
}

interface ReportCSVExporterProps {
  tableData: DTSTableData
  fileName: string
  isReportCompletionPageCSV?: boolean 
}

export class ReportCSVExporter extends Component<ReportCSVExporterProps, ReportCSVErrorState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    state: ReportCSVErrorState = {
      primaryButtonDisabled: true
    }

    componentDidMount() {
      if (this.props.tableData.data.length !== 0) {
        this.setState({
          primaryButtonDisabled: false
        })
      }
    }

    render() {
      return(
        <PrimaryButton
          id="export-csv-primary-button"
          onClick={this.onClickExportButton}
          text={this.i18n.get(i18nKey.exportCSV)}
          disabled={this.state.primaryButtonDisabled}
        />
      )
    }

    onClickExportButton = (_: MouseEvent<HTMLButtonElement>) => {
      this.downloadCSV(this.props.tableData.data)
    }

    private downloadCSV(array: Dictionary[]) {
      const link = document.createElement('a')
      let csv = this.convertArrayOfObjectsToCSV(array)
      if (csv === null) return
      
      const filename = CSVFileNameCreator(this.props.fileName, this.props.isReportCompletionPageCSV)
    
      if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`
      }
    
      link.setAttribute('href', encodeURI(csv))
      link.setAttribute('download', filename)
      link.click()
    }

    private convertArrayOfObjectsToCSV(array: Dictionary[]) {
      let result: string
    
      const columnDelimiter = ','
      const lineDelimiter = '\n'
      const keys = Object.keys(this.props.tableData.data[0])
      keys.shift()    //removes ID column from the output
    
      result = ''
      result += keys.join(columnDelimiter)
      result += lineDelimiter
    
      array.forEach(item => {
        let column = 0
        keys.forEach(key => {
          if (column > 0) {
            result += columnDelimiter
          }
    
          result += item[key]
          column++
        });
        result += lineDelimiter
      });
    
      return result
    }
}