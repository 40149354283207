import React from "react";
import { Spinner } from "reactstrap";

export interface LoaderProps {
	text?: string
}

export const LoaderV2: React.FC<LoaderProps> = ({text}) => {
    let message: string = text ? text : "Loading"

    return (
        <div id="loading-container" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem'}}>
            {message}
            <Spinner id="loader-spinner-component" className="ms-2" color="primary" />
        </div>
    );
}