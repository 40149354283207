import React, { Component, MouseEvent } from "react"
import ICourseService from "../../../services/CourseService"
import {
	Stack,
	IStackTokens,
	Spinner,
	MessageBar,
	MessageBarType,
	Label,
    Icon,
	IStackStyles
} from "@fluentui/react"
import ProfileImage from "../../../ui-kit/ProfileImage";
import { IURLCreator } from "../../../ui-kit/URLCreator";
import { TabPanelProps } from "../TabPanelProps"
import "./EditCourseDetails.css"
import Course from "../../../models/Course"
import { TextField } from '../../../ui-kit/TextField'
import { PrimaryButton } from "../../../ui-kit/PrimaryButton"
import { container } from "../../../DIContainer"
import { TYPES } from "../../../Types"
import { DurationSlider } from "../../../ui-kit/DurationSlider"
import { Checkbox, IIconStyles, ITooltipHostStyles, TooltipHost } from "@fluentui/react";
import { ConfirmationDialog } from "../../../ui-kit/ConfirmationDialog";
import { i18nKey, Ii18n } from "../../../global/i18n";
import { IFeatureFlagService } from "../../../services/FeatureFlagService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IDateProvider } from "../../../services/DateProvider";
import { DestructiveIconButton } from "../../../ui-kit/DestructiveIconButton";

interface EditCourseDetailsProps extends TabPanelProps {
    documentId: string
	course: Course
	courseUpdated: () => void
	courseDeletedHandler: (message: string) => void;
}

interface EditCourseDetailsState {
	name: string
	description: string
	zipFile: File | undefined
	imageFile: File | undefined
	duration: string
	clientId: string
	courseFolder: string
	updateButtonDisabled: boolean
	deleteButtonDisabled: boolean
	showSpinner: boolean
	showDeleteTrainingSpinner: boolean;
	errorMessage: string | undefined
	successMessage: string | undefined
	deleteDialogIsHidden: boolean

	//deadline states
	deadlineDate: Date | undefined
	addDeadlineIsChecked: boolean
	sendDeadlineReminderIsChecked: boolean
}

const stackStyling: IStackTokens = {
	childrenGap: "20px",
}

const deadlineStackStyling: IStackStyles = {
	root: {
		minWidth: 250
	}
}

const deleteIconStyles: IIconStyles = {
	root: {
		color: '#0071bc',
		fontSize: 20,
		justifyContent: 'center',
	},
}

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'center' } };
const addDeadlineCalloutProps = {
    gapSpace: 0,
    // If the tooltip should point to an absolutely-positioned element,
    // you must manually specify the callout target.
    target: `#add-deadline-information-icon`,
}

const sendDeadlineCalloutProps = {
	gapSpace: 0,
    // If the tooltip should point to an absolutely-positioned element,
    // you must manually specify the callout target.
    target: `#send-deadline-information-icon`,
}

export default class EditCourseDetails extends Component<EditCourseDetailsProps, EditCourseDetailsState> {
	private courseService: ICourseService = container.get<ICourseService>(TYPES.ICourseService)
    private urlCreator: IURLCreator = container.get<IURLCreator>(TYPES.IURLCreator)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
	private featureFlagService: IFeatureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService)
	private dateProvider: IDateProvider = container.get<IDateProvider>(TYPES.IDateProvider)


	state: EditCourseDetailsState = {
		name: this.props.course.name,
		description: this.props.course.description,
		duration: this.props.course.duration,
		// Assumes that there is only one client per course
		clientId: Object.keys(this.props.course.clients)[0],
		zipFile: undefined,
		imageFile: undefined,
		courseFolder: this.props.course.folderUrl,
		updateButtonDisabled: true,
		deleteButtonDisabled: false,
		showSpinner: false,
		showDeleteTrainingSpinner: false,
		errorMessage: undefined,
		successMessage: undefined,
		deleteDialogIsHidden: true,
		addDeadlineIsChecked: false,
		deadlineDate: undefined,
		sendDeadlineReminderIsChecked: false
	}

	render() {
		let banner = null
		if (this.state.errorMessage) {
			banner = (
				<MessageBar
					id="edit-course-details-error-banner"
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.errorMessage}
				</MessageBar>
			)
		} else if (this.state.successMessage) {
			banner = (
				<MessageBar
					id="edit-course-details-success-banner"
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			)
		}

		let zipFileText = "Select course zip file";
		if (this.state.zipFile !== undefined) {
			zipFileText = this.state.zipFile.name
		}

		let imageFileText = "Select course image file";
		if (this.state.imageFile !== undefined) {
			imageFileText = this.state.imageFile.name
		}

		return (
			<div id="edit-course-modal-container">
				<Stack tokens={stackStyling}>
					<Label>Update the course name and description</Label>
					<TextField
						id="edit-course-detail-name-textfield"
						label="Name"
						onChange={this.nameTextFieldChanged}
						value={this.state.name}
					/>
					<TextField
						id="edit-course-detail-description-textfield"
						label="Description"
						onChange={this.descriptionTextFieldChanged}
						value={this.state.description}
					/>
					<label htmlFor="zip-file-upload" className="inputFile">
						{zipFileText}
					</label>
					<input
						id="zip-file-upload"
						type="file"
						accept=".zip"
						onChange={this.zipFileSelectionChanged}
						width="300px"
					/>
					<label htmlFor="image-file-upload" className="inputFile">
						{imageFileText}
					</label>
					<input
						id="image-file-upload"
						type="file"
						accept="image/*"
						onChange={this.imageFileSelectionChanged}
						width="300px"
					/>
					{this.state.imageFile !== undefined && (
						<div id="previewImage">
							<ProfileImage
								urlString={this.urlCreator.createFor(this.state.imageFile!)}
							/>
						</div>
					)}
					{ this.featureFlagService.deadline() &&
						<Stack id= "training-deadline-stack" tokens={stackStyling}>
							<Stack id="tooltip-stack" horizontal tokens={stackStyling}>
									<TooltipHost
										content={this.i18n.get(i18nKey.courseDeadlineTooltipText)}
										hostClassName="training-add-deadline-tooltip"
										styles={hostStyles}
										calloutProps={addDeadlineCalloutProps}
									>
										<Stack horizontal styles={deadlineStackStyling} tokens={stackStyling}>
											<Icon id="add-deadline-information-icon" iconName="Info" styles={deleteIconStyles}/>
											<Checkbox 
												id="add-deadline-checkbox"
												label={this.i18n.get(i18nKey.courseDeadlineCheckboxText)} 
												checked={this.state.addDeadlineIsChecked} 
												onChange={this.addDeadlineCheckboxOnChange}
												styles={{root: {maxWidth: "100%"}}} 
												boxSide="end"
											/>
										</Stack>
									</TooltipHost>
								{ this.state.addDeadlineIsChecked &&
									<DatePicker
										id="training-deadline-date-picker"
										selected={this.state.deadlineDate}
										onChange={this.onDeadlineDateChange}
										selectsStart
										showMonthDropdown
										showYearDropdown
										showTimeSelect
										dateFormat="MMMM d, yyyy h:mm aa"
										startDate={this.dateProvider.getDate()} 
										minDate={this.dateProvider.getDate()}
										placeholderText={this.i18n.get(i18nKey.trainingDatepickerPlaceholderText)}
									/>
								}
							</Stack>
							{ this.state.addDeadlineIsChecked &&
								<TooltipHost
									content={this.i18n.get(i18nKey.sendDeadlineReminderTooltipText)}
									hostClassName="training-send-deadline-reminder-tooltip"
									styles={hostStyles}
									calloutProps={sendDeadlineCalloutProps}
								>
									<Stack horizontal tokens={stackStyling}>
										<Icon id="send-deadline-information-icon" iconName="Info" styles={deleteIconStyles}/>
										<Checkbox 
											id="send-deadline-reminder-checkbox"
											label={this.i18n.get(i18nKey.sendDeadlineReminder)} 
											checked={this.state.sendDeadlineReminderIsChecked} 
											onChange={this.sendDeadlineReminderCheckboxOnChange} 
											boxSide="end"
										/>
									</Stack>
								</TooltipHost>
							}
						</Stack>
					}
					<DurationSlider
						initialMax={this.parseDuration(this.props.course.duration).max}
						initialMin={this.parseDuration(this.props.course.duration).min}
						durationValueChanged={this.durationSliderUpdated}
					/>
					{banner}
					<PrimaryButton
						id="edit-course-detail-primary-button"
						text="Update"
						onClick={this.updateButtonClicked}
						disabled={this.state.updateButtonDisabled}
						hidden={this.state.showSpinner}
					/>
					{ this.state.showSpinner &&
						<Spinner
							id="edit-course-details-spinner"
							label="Saving"
							ariaLive="assertive"
							labelPosition="right"
						/>
					}

					{ this.state.showDeleteTrainingSpinner && 
						<Spinner
							id="edit-course-details-delete-spinner"
							label="Deleting Training..."
							ariaLive="assertive"
							labelPosition="right"
						/>
					}
					{ !this.state.showDeleteTrainingSpinner && 
						<DestructiveIconButton
							id="edit-course-details-delete-button"
							onClick={this.deleteButtonClicked}
							disabled={false}
						/> 
					}
					{ !this.state.deleteDialogIsHidden && 
					<ConfirmationDialog
						title={this.i18n.get(i18nKey.deleteCourseTitle)}
						dialogMessage={this.i18n.get(i18nKey.deleteCourseMessage)}
						affirmativeButtonText={this.i18n.get(i18nKey.delete)}
						dismissButtonText={this.i18n.get(i18nKey.dismiss)}
						affirmativeActionHandler={this.deleteButtonDestructiveAction}
						dismissActionHandler={this.deleteDialogDismissed}
						isDestructive={true}
					/>
					}
				</Stack>
			</div>
		)
	}

	dismissBanner = () => {
		this.setState({ errorMessage: undefined, successMessage: undefined })
	}

	updateButtonClicked = (e: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: undefined,
			successMessage: undefined,
			showSpinner: true,
			updateButtonDisabled: true,
		})

		// TO-DO: Store deadline data in appropriate data store
		// Undetermined if this is in the Firebase model itself or some other backend process

		this.courseService
			.updateCourse(
				this.props.documentId,
				this.state.name,
				this.state.description,
				this.state.clientId,
				this.state.zipFile!,
				this.state.imageFile!,
				this.state.duration,
				this.state.courseFolder
			)
			.then(() => {
				const message = this.state.name + " has been updated"
				this.setState({
					successMessage: message,
					showSpinner: false,
					updateButtonDisabled: false,
				})
				
				this.props.courseUpdated()
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showSpinner: false,
					updateButtonDisabled: false,
				})
			})
	}

	deleteButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({deleteDialogIsHidden: false})
	}
	
	deleteDialogDismissed = () => {
		this.setState({deleteDialogIsHidden: true})
	}

	deleteButtonDestructiveAction = () => {
		this.setState({
			errorMessage: undefined,
			successMessage: undefined,
			showDeleteTrainingSpinner: true,
			updateButtonDisabled: true,
			deleteButtonDisabled: true,
		});
		this.courseService
			.deleteCourse(this.props.course.documentId)
			.then(() => {
				this.props.courseDeletedHandler(this.props.course.name + " has been deleted")
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showDeleteTrainingSpinner: false,
					updateButtonDisabled: false,
					deleteButtonDisabled: false,
				});
			})
	}

	nameTextFieldChanged = (
		_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return
		}

		this.setState({ name: newValue }, () => {
			this.enableUpdateButtonIfNeeded()
		})
	}

	descriptionTextFieldChanged = (
		_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return
		}

		this.setState({ description: newValue }, () => {
			this.enableUpdateButtonIfNeeded()
		})
	}

	private zipFileSelectionChanged = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (!event.target.files || event.target.files.length === 0) {
			return
		}

		const file: File = event.target.files[0]
		this.setState({ zipFile: file }, () => {
			this.enableUpdateButtonIfNeeded()
		})
	}

	private imageFileSelectionChanged = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (!event.target.files || event.target.files.length === 0) {
			return
		}

		const file: File = event.target.files[0]
		this.setState({ imageFile: file }, () => {
			this.enableUpdateButtonIfNeeded()
		})
	}

	durationSliderUpdated = (newDuration: string) => {
		this.setState({ duration: newDuration })
		this.enableUpdateButtonIfNeeded()
	}

	enableUpdateButtonIfNeeded = () => {
		let disable = true

		if (this.state.name.length > 0 && this.state.description.length > 0) {
			disable = false
		}

		this.setState({ updateButtonDisabled: disable })
	}

	private parseDuration = (duration: string) => {
		/**
		 * Empty duration means a duration has not been set on the course
		 */
		if (duration === "") {
			return {
				min: 0,
				max: 0
			}
		}

		let min, max

		if (duration.includes("-")) {
			const components = duration.split(' ')

			min = components[0]
			max = components[2]	
		} else {
			const components = duration.split(' ')

			min = components[0]
			max = components[0]
		}

		return {
			min: parseInt(min),
			max: parseInt(max)
		}
	}

	addDeadlineCheckboxOnChange = () => {
		this.setState({addDeadlineIsChecked: !this.state.addDeadlineIsChecked})
	}

	sendDeadlineReminderCheckboxOnChange = () => {
		this.setState({sendDeadlineReminderIsChecked: !this.state.sendDeadlineReminderIsChecked})
	}

	onDeadlineDateChange = (date: Date) => {
        this.setState({
            deadlineDate: date
        })
    }
}
