import Course from "../../models/Course";
import React from "react";
import { Stack } from "@fluentui/react";
import { CourseGridStyle } from "./CourseGridStyle";
import { Dictionary, DocumentId } from "../../global/TypeAliases";
import { i18nKey, Ii18n } from "../../global/i18n";
import { container } from "../../DIContainer";
import { TYPES } from "../../Types";
import { TrainingGridItem } from "../admin/courses/TrainingGridItem";


interface TrainingGridProps {
	trainings: Course[];
	userClients?: DocumentId[]
	showProgressBar: boolean
	onTrainingClick: (e: React.MouseEvent<HTMLDivElement>) => void
	shouldWrap: boolean
	deadlines?: Dictionary
}


export const TrainingGrid: React.FC<TrainingGridProps> = ({trainings, userClients, showProgressBar, onTrainingClick, shouldWrap, deadlines}) => { 
    const i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    if (trainings.length === 0) {
        return <h2 id="training-grid-no-training-displayed">{i18n.get(i18nKey.noCourses)}</h2>
    }

    const gridItems = trainings.map((training) => {
        let deadline: string | undefined;
        if (deadlines) {
            deadline = deadlines[training.documentId]
        }

        if (showProgressBar) {
            return <TrainingGridItem
                        training={training} 
                        key={training.documentId} 
                        userClients={userClients!} 
                        showProgressBar={showProgressBar} 
                        onCourseClick={onTrainingClick} 
                        deadline={deadline}
                    />;
        }

        else {
            return <TrainingGridItem
                        training={training} 
                        key={training.documentId} 
                        showProgressBar={showProgressBar} 
                        onCourseClick={onTrainingClick} 
                        deadline={deadline}
                    />;
        }
    })
    
    return (
        <div id="training-card-grid">
            <Stack 
                horizontal={true}
                wrap={shouldWrap} 
                tokens={CourseGridStyle.stackTokens}
            >
                {gridItems}
            </Stack>
        </div>
    )
}