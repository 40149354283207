import React, { Component } from 'react'
import { Stack, Image } from '@fluentui/react'
import { MarketingButton } from './MarketingButton'
import { MarketingIi18n, i18nKey } from '../global/Marketing-i18n'
import { container } from '../DIContainer'
import { TYPES } from '../Types'
import { NavLink } from 'react-router-dom'
import { MarketingHeaderStyles } from './MarketingHeaderStyles'
import { IRouter } from '../Router'
import { PathNames } from '../PathNames'

import logoLarge from '../images/logo-dts-landing.svg'
import mobileMenuOpenIcon from '../images/navigation-bar/mobile-menu-icon.svg'
import mobileMenuCloseIcon from '../images/navigation-bar/mobile-menu-close-icon.svg'
import { IAuth } from '../auth/Auth'
import './MarketingHeader.css'

interface MarketingHeaderProps {}

interface MarketingHeaderState {
    isMobileView: boolean
    isMenuOpen: boolean
    menuStyleClass: string | undefined
}
const MOBILE_SCREEN_WIDTH = 600

export class MarketingHeader extends Component<MarketingHeaderProps, MarketingHeaderState> {
    private i18n = container.get<MarketingIi18n>(TYPES.MarketingIi18n)
    private router = container.get<IRouter>(TYPES.IRouter)
    private auth = container.get<IAuth>(TYPES.IAuth)

    state: MarketingHeaderState = {
        isMobileView: window.innerWidth <= MOBILE_SCREEN_WIDTH ? true : false,
        isMenuOpen: false,
        menuStyleClass: undefined,
    }

    componentDidMount() {
        document.addEventListener('scroll', this.handleScroll)
        document.addEventListener('mousedown', this.handleClickOutside)
        window.addEventListener('resize', this.checkWindowChange)
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll)
        document.removeEventListener('mousedown', this.handleClickOutside)
        window.removeEventListener('resize', this.checkWindowChange)
    }

    render() {
        let navigationLinks
        let logo

        if (this.state.isMobileView) {
            const loginNavLinkProps = {
                text: this.auth.isLoggedIn() ? this.i18n.get(i18nKey.goToDashboard) : this.i18n.get(i18nKey.login),
                path: this.auth.isLoggedIn() ? PathNames.Dashboard : PathNames.Login
            }

            navigationLinks = (
                <Stack id='mobile-menu-container' styles={MarketingHeaderStyles.mobileMenuStackStyles}>

                    <NavLink to='#' id='mobile-menu-icon' onClick={this.state.isMenuOpen ? this.closeMobileMenu : this.openMobileMenu}>
                        <Image src={this.state.isMenuOpen ? mobileMenuCloseIcon : mobileMenuOpenIcon} width='5vw' styles={MarketingHeaderStyles.mobileIconStyles} />
                    </NavLink>
                    
                    <Stack id='mobile-navigation-links' styles={MarketingHeaderStyles.mobileLinkStackStyles} className={this.state.menuStyleClass}>

                        <NavLink exact to={PathNames.Home} activeClassName={MarketingHeaderStyles.activeMobileLinkClass}>
                            {this.i18n.get(i18nKey.home)}
                        </NavLink>
                        <NavLink exact to={PathNames.Features} activeClassName={MarketingHeaderStyles.activeMobileLinkClass}>
                            {this.i18n.get(i18nKey.features)}
                        </NavLink>
                        <NavLink exact to={PathNames.Testimonials} activeClassName={MarketingHeaderStyles.activeMobileLinkClass}>
                            {this.i18n.get(i18nKey.testimonials)}
                        </NavLink>
                        <NavLink exact to={PathNames.ContactUs} activeClassName={MarketingHeaderStyles.activeMobileLinkClass}>
                            {this.i18n.get(i18nKey.contactUs)}
                        </NavLink>
                        <NavLink exact to={loginNavLinkProps.path}>
                            {loginNavLinkProps.text}
                        </NavLink>
                    </Stack>
                </Stack>
            )
        } else {
            const marketingButtonProps = {
                text: this.auth.isLoggedIn() ? this.i18n.get(i18nKey.goToDashboard).toUpperCase() : this.i18n.get(i18nKey.login).toUpperCase(),
                width: this.auth.isLoggedIn() ? '15vw' : '10vw'
            }

            navigationLinks = (
                <>
                    <Stack id='marketing-navigation-links' horizontal horizontalAlign='space-around' styles={MarketingHeaderStyles.navigationLinkStackStyles}>

                        <NavLink exact to={PathNames.Home} activeClassName={MarketingHeaderStyles.activeLinkClass}>
                            {this.i18n.get(i18nKey.home).toUpperCase()}
                        </NavLink>
                        <NavLink exact to={PathNames.Features} activeClassName={MarketingHeaderStyles.activeLinkClass}>
                            {this.i18n.get(i18nKey.features).toUpperCase()}
                        </NavLink>
                        <NavLink exact to={PathNames.Testimonials} activeClassName={MarketingHeaderStyles.activeLinkClass}>
                            {this.i18n.get(i18nKey.testimonials).toUpperCase()}
                        </NavLink>

                    </Stack>

                    <MarketingButton 
                        id='marketing-login-button' 
                        text={marketingButtonProps.text} 
                        onClick={this.loginButtonClicked} 
                        width={marketingButtonProps.width}
                    /> 
                </>
            )
        }

        if (this.state.isMenuOpen) {
            logo = undefined
        } else {
            logo = (
                <NavLink to={PathNames.Home} onClick={this.logoClicked}>
                    <Image 
                        id='navigation-dts-logo' 
                        src={logoLarge} 
                        alt={this.i18n.get(i18nKey.altDtsLogo)} 
                        styles={MarketingHeaderStyles.logoStyles} 
                    />
                </NavLink>
            )
        }

        return (
            <Stack 
                id='marketing-navigation-bar' 
                horizontal 
                horizontalAlign='space-evenly' 
                verticalAlign='center' 
                styles={MarketingHeaderStyles.outerStackStyles}
            >
                {logo}
                {navigationLinks}
            </Stack>
        )
    }

    checkWindowChange = () => {
        if (window.innerWidth <= MOBILE_SCREEN_WIDTH) {
            this.setState({ isMobileView: true })
        } else {
            this.setState({ isMobileView: false })
        }
    }
    
    loginButtonClicked = () => {
        if (this.auth.isLoggedIn()) {
            this.router.goToDashboard()
        } else {
            this.router.goToLogin()
        }
    }

    logoClicked = () => {
        window.scrollTo(0, 0)
    }

    handleScroll = () => {
        const navigationBar = document.getElementById('marketing-navigation-bar')

        if (navigationBar) {
            window.scrollY > 10 ? navigationBar.classList.add(MarketingHeaderStyles.scrollClass) : navigationBar.classList.remove(MarketingHeaderStyles.scrollClass)
        }
    }

    handleClickOutside = (e: MouseEvent) => {
        let container = document.getElementById('mobile-menu-container')

        if (container && !container.contains(e.target as Node) && this.state.isMenuOpen) {
            this.closeMobileMenu()
        }
    }

    openMobileMenu = () => {
        this.setState({ menuStyleClass: MarketingHeaderStyles.openMobileNavigationLinksClass, isMenuOpen: true })

        let navigationBar = document.getElementById('marketing-navigation-bar')

        if (navigationBar) {
            navigationBar.classList.toggle(MarketingHeaderStyles.openMobileMenuClass)
        }
    }

    closeMobileMenu = () => {
        this.setState({ menuStyleClass: undefined, isMenuOpen: false })

        let navigationBar = document.getElementById('marketing-navigation-bar')

        if (navigationBar) {
            navigationBar.classList.toggle(MarketingHeaderStyles.openMobileMenuClass)
        }
    }
}
