import React, { Component } from "react";
import { LearningPlansPageStyles } from "./LearningPlansPageStyles";
import { IBreadcrumbItem, MessageBar, MessageBarType, Stack } from "@fluentui/react";
import PageTitle from "../../../ui-kit/PageTitle";
import { ContentType, IContent } from "../../../models/IContent";
import { PrimaryButton } from "../../../ui-kit/PrimaryButton";
import { Breadcrumb } from "../../../ui-kit/Breadcrumb";
import { LearningPlanContentItem } from "./LearningPlanContentItem";
import { container } from "../../../DIContainer";
import { i18nKey, Ii18n } from "../../../global/i18n";
import { TYPES } from "../../../Types";
import { ILearningPlanCompositionService } from "../../../services/LearningPlanCompositionService";
import { Dictionary, DocumentId } from "../../../global/TypeAliases";
import { Loader } from "../../../ui-kit/Loader";
import { ILearningPlanHelper } from "../../../utils/LearningPlanHelper";
import Course, { CourseType } from "../../../models/Course";
import { Survey } from "../../../models/Survey";
import { IRouter } from "../../../Router";
import { IAuth } from "../../../auth/Auth";
import { LaunchLocations } from "../../../models/TrainingLaunchLocation";
import { ILRSService } from "../../../services/LRSService";

interface LearningPlansPageProps {
    navigationItems: IBreadcrumbItem[]
    userClientIds: DocumentId[]
    learningPlanId: DocumentId
    learningPlanName: string
}

interface LearningPlansPageState {
    contents: IContent[] | null
    errorMessage: string | undefined
    showLoader: boolean
    progressedContentIds: Dictionary
    completedContentIds: Dictionary
    isResumingLearningPlan: boolean
    /**
     * This is the content to load when user hits the resume/start button
     */ 
    nextContentInProgress: IContent | null
}

export class LearningPlansPage extends Component<LearningPlansPageProps, LearningPlansPageState> {
    private compositionService: ILearningPlanCompositionService = container.get<ILearningPlanCompositionService>(TYPES.ILearningPlanCompositionService)
    private learningPlanHelper: ILearningPlanHelper = container.get<ILearningPlanHelper>(TYPES.ILearningPlanHelper)
    private router: IRouter = container.get<IRouter>(TYPES.IRouter)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private auth: IAuth = container.get<IAuth>(TYPES.IAuth)
    private lrsService: ILRSService = container.get<ILRSService>(TYPES.ILRSService)

    state: LearningPlansPageState = {
        contents: null,
        errorMessage: undefined,
        showLoader: true,
        progressedContentIds: {},
        completedContentIds: {},
        isResumingLearningPlan: false,
        nextContentInProgress: null
    }

    componentDidMount() {
        this.compositionService
            .getLearningPlanData(this.props.learningPlanId, this.props.userClientIds)
            .then(data => {
                this.setState({
                    showLoader: false,
                    contents: data.contents,
                    progressedContentIds: data.progressedContentIds,
                    completedContentIds: data.completedContentIds,
                    isResumingLearningPlan: data.isResumingLearningPlan,
                    nextContentInProgress: data.nextContentToResume,
                })
        }).catch(_ => {
            this.setState({ 
                errorMessage: this.i18n.get(i18nKey.failedToLoadLearningPlans),
                showLoader: false
            })
        })
    }

    render() {
        if (this.state.showLoader) {
            return <Loader text={this.i18n.get(i18nKey.loadingYourLearningPlan)} />
        }

        return (
            <>
                <Breadcrumb 
                    id="lp-breadcrumb-nav"
                    items={this.props.navigationItems}
                />
                { this.state.contents &&
                    <div id="lp-content-grid">
                        <Stack 
                            horizontal={true} 
                            tokens={LearningPlansPageStyles.stackTokens} 
                            styles={LearningPlansPageStyles.headerStackStyles}
                        >
                            <PageTitle title={this.props.learningPlanName} />
                            { this.state.contents.length > 0 &&
                                <div id="resume-button-div" onClick={this.onResumeLearningPlanClickHandler}>
                                    <PrimaryButton
                                        id="lp-primary-button"
                                        text={this.primaryButtonText()}
                                        onClick={this.onResumeLearningPlanClickHandler}
                                    />
                                </div>
                            }
                        </Stack>
                        <Stack 
                            horizontalAlign="start"
                            horizontal={true} 
                            wrap={true}
                            tokens={LearningPlansPageStyles.stackTokens}
                            styles={LearningPlansPageStyles.stackGridStyles}
                        >
                            {this.renderLearningPlanContents(this.state.contents)}
                        </Stack>
                    </div>
                }
                {this.createErrorBanner()}
            </>
        )
    }

    /**
     * Private functions
     */
    private renderLearningPlanContents = (contents: IContent[]) => {
        const clickableState = this.learningPlanHelper.getClickableState(contents, this.state.completedContentIds)

        if (contents.length === 0) {
            return (
                <div id="lp-grid-no-contents-displayed">{this.i18n.get(i18nKey.noLearningPlanContents)}</div>
            )
        }
        
        return contents.map((content => {
            let showProgressionArrow = true
			if (contents[0] === content) {
				showProgressionArrow = false
			}
            return  <LearningPlanContentItem 
                        key={content.documentId}
                        content={content}
                        contentType={content.contentType()}
                        contentImageSrc={content.imageUrl}
                        isClickable={clickableState[content.documentId]}
                        showProgressionArrow={showProgressionArrow}
                        progress={this.state.progressedContentIds[content.documentId]}
                        isComplete={this.state.completedContentIds[content.documentId]}
                        onContentClick={this.contentInLearningPlanClicked}
                    />
        })
    )}

    private onResumeLearningPlanClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (this.state.nextContentInProgress){
            this.contentInLearningPlanClicked(e, this.state.nextContentInProgress)
        }
    }

    private primaryButtonText = (): string => {
        return this.state.isResumingLearningPlan 
                ? this.i18n.get(i18nKey.resumeLearningPlan) 
                : this.i18n.get(i18nKey.beginLearningPlan)
    }

    private goToCourse(course: Course) {
		const data = { course: course }
		
		switch (course.type) {
			case CourseType.training:
				this.router.goToCourse(data)
				break
			case CourseType.googleForm:
				this.router.goToGoogleFormTraining(data)
				break
		}
	}

    private goToSurvey(survey: Survey) {
		const data = { 
			documentId: survey.documentId, 
			surveyCompleted: this.state.completedContentIds[survey.documentId],
			learnerViewMode: false
		}
		this.router.goToViewSurvey(data)
	}

    private createErrorBanner = () => {
		if (this.state.errorMessage) {
			return (
				<MessageBar
                    id="general-error-banner"
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissErrorBanner}
				>
					{this.state.errorMessage}
				</MessageBar>
			)
		} 

		return null
    }

    private postTrainingLaunchLocationFor = (content: IContent) => {
        const user = this.auth.user
        if (!user) {
            return
        }

        const sharedClientIds = this.props.userClientIds
        sharedClientIds.forEach(client => {
            this.lrsService
                .postTrainingLaunchLocation(
                    user.uid, 
                    client, 
                    content.lrsId, 
                    LaunchLocations.LearningPlan
                )
        })
    }

    contentInLearningPlanClicked = (e: React.MouseEvent<HTMLDivElement>, content: IContent): void => {
        this.postTrainingLaunchLocationFor(content)

        switch (content.contentType()) {
			case ContentType.course:
				const course = content as Course
				this.goToCourse(course)
				break
			case ContentType.survey:
				const survey = content as Survey
				this.goToSurvey(survey)
				break
			default:
				break
		}	
	}

    dismissErrorBanner = () => {
		this.setState({ errorMessage: undefined }) 
    }
}