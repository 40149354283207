import { Component } from "react";
import { Stack, Text, Slider, IStackStyles, IStackTokens } from "@fluentui/react";
import { FontSize } from "./FontSize";

type onChangedEvent = (event: MouseEvent | TouchEvent | KeyboardEvent, value: number) => void

export interface DurationSliderProps {
    initialMin?: number
    initialMax?: number
    minSliderLabel?: string
    maxSliderLabel?: string
    sliderMin?: number
    sliderMax?: number
    useCustomStepValue?: boolean
    sliderStep?: number
    minSliderOnChanged?: onChangedEvent
    maxSliderOnChanged?: onChangedEvent
    minSliderOnChange?: (value: number) => void
    maxSliderOnChange?: (value: number) => void
    durationValueChanged?: (value: string) => void
}

export interface DurationSliderState {
	minDuration: number
	maxDuration: number
    durationString: string
    sliderStep?: number
}

export class DurationSlider extends Component<DurationSliderProps, DurationSliderState> {
    private sliderAriaValueText = (value: number) => `${value} minutes`
    private sliderValueFormat = (value: number) => `${value} minutes`
    private defaultDuration = 30
    private defaultStep = 5
    private defaultSliderMax = this.props.sliderMax ? this.props.sliderMax : 120
    /**
     * Setting slider min this way because +0 is falsy and therefore would not correctly set the slider min if the 
     * passed in prop was exactly +0
     */
    private defaultSliderMin =  this.props.sliderMin === 0 ? 0
                                : this.props.sliderMin ? this.props.sliderMin
                                : 5

    private stackStyling: IStackTokens = {
        childrenGap: "20px",
    }
    
    private sliderStackStyles: Partial<IStackStyles> = { 
        root: { maxWidth: 360 } 
    }
    
    state: DurationSliderState = {
		minDuration: this.props.initialMin ? this.props.initialMin : this.defaultDuration,
		maxDuration: this.props.initialMax ? this.props.initialMax : this.defaultDuration,
        durationString: `${this.defaultDuration} minutes`,
        sliderStep: this.props.sliderStep ? this.props.sliderStep : this.defaultStep
    }

    componentDidMount() {
        let durationString = this.state.durationString
        if (this.props.initialMin && this.props.initialMax 
            && this.props.initialMax > 0 && this.props.initialMin > 0) {
            durationString = this.createDurationString(this.props.initialMin, this.props.initialMax)
        }

        this.setState({ durationString: durationString})
    }

    render() {
        return (
            <Stack tokens={this.stackStyling}>
                <Slider 
                    className="duration-slider-min"
                    styles={this.sliderStackStyles}
                    label={this.props.minSliderLabel}
                    min={this.defaultSliderMin} 
                    max={this.defaultSliderMax} 
                    step={this.state.sliderStep}
                    value={this.state.minDuration}
                    ariaValueText={this.sliderAriaValueText}
                    valueFormat={this.sliderValueFormat}
                    onChanged={this.minSliderOnChanged}
                    onChange={this.minSliderOnChange}
                    showValue 
                />
                <Slider 
                    className="duration-slider-max"
                    styles={this.sliderStackStyles}
                    label={this.props.maxSliderLabel}
                    min={this.props.sliderMin} 
                    max={this.defaultSliderMax}
                    step={this.state.sliderStep}
                    value={this.state.maxDuration}
                    ariaValueText={this.sliderAriaValueText}
                    valueFormat={this.sliderValueFormat}
                    onChanged={this.maxSliderOnChanged}
                    onChange={this.maxSliderOnChange}
                    showValue 
                />
                <Text 
                    id="duration-slider-text" 
                    variant={FontSize.small}
                >
                        {this.state.durationString}
                </Text>
            </Stack>
        )
    }

    minSliderOnChange = (value: number) => {
        if (this.props.useCustomStepValue) {
            const sliderStepValue = this.adjustSliderStep(value)

            this.setState({ minDuration: value, sliderStep: sliderStepValue })
        } else {
            this.setState({ minDuration: value })

            if (this.props.minSliderOnChange) { 
                this.props.minSliderOnChange(value)
            }
        }        
	}

	minSliderOnChanged = (event: MouseEvent | TouchEvent | KeyboardEvent, value: number) => {
        if (this.defaultSliderMin === 0) {
            value = this.checkForZeroValue(value)
        }

		if (value > this.state.maxDuration) {
			this.setState({ minDuration: value, maxDuration: value })
		} else {
			this.setState({ minDuration: value })
        }
        
        this.setDurationString()
        
        if (this.props.minSliderOnChanged) { 
            this.props.minSliderOnChanged(event, value)
        }
	}

	maxSliderOnChange = (value: number) => {
        if (this.props.useCustomStepValue) {
            const sliderStepValue = this.adjustSliderStep(value)
            
            this.setState({ maxDuration: value, sliderStep: sliderStepValue })
        } else {
            this.setState({ maxDuration: value })

            if (this.props.maxSliderOnChange) { 
                this.props.maxSliderOnChange(value)
            }
        }
	}

	maxSliderOnChanged = (event: MouseEvent | TouchEvent | KeyboardEvent, value: number) => {
        if (this.defaultSliderMin === 0) {
            value = this.checkForZeroValue(value)
        }

		if (value < this.state.minDuration) {
			this.setState({ maxDuration: value, minDuration: value })
		} else {
			this.setState({ maxDuration: value })
        }
        
        this.setDurationString()
        
        if (this.props.maxSliderOnChanged) { 
            this.props.maxSliderOnChanged(event, value)
        }
    }
    
    private adjustSliderStep = (value: number) => {
        let sliderStep: number = value >= 10 ? this.defaultStep : 1
        
        return sliderStep
    }

    private setDurationString = () => {
        let durationString = this.createDurationString(this.state.minDuration, this.state.maxDuration)
        
        this.setState({ durationString: durationString })

        if (this.props.durationValueChanged) {
            this.props.durationValueChanged(durationString)
        }
    }
    
    private createDurationString = (min: number, max: number) => {
        let durationString

        if (min === max) {
			durationString = min + " minutes"
		} else {
			durationString = min + " - " + max + " minutes"
        }

        return durationString
    }

    private checkForZeroValue = (value: number) => {
        /**
         * A hacky workaround to ensure that a duration of 0 minutes
         * is never shown to the user. We have to have a slider minimum of
         * 0 so that the step increments are even. If value is 0, set 
         * value to 1, else just return the value.
         */
        return value === 0 ? 1 : value
    }
}