import { Component } from "react"
import { container } from "../DIContainer"
import { TYPES } from "../Types"
import { ICleverCodeParser } from "./CleverCodeParser"
import { Text, Stack, Spinner, Image, MessageBar, MessageBarType } from "@fluentui/react"
import { FontSize } from "../ui-kit/FontSize"
import { ICleverCompositionService, ICleverCompositionServiceDelegate } from "../services/CleverCompositionService"
import { IAuth, IAuthObserver } from "./Auth"
import { IFeatureFlagService } from "../services/FeatureFlagService"
import { IRouter, Router } from "../Router"
import { log } from "../global/Logger"

import hero from '../images/sso/sso-hero.png'
import errorImage from '../images/sso/sso-error.png'
import successImage from '../images/sso/sso-success.png'
import notStartedImage from '../images/sso/sso-not-started.png'
import { CleverRedirectStyles } from "./CleverRedirectStyles"
import { Theme } from "../ui-kit/Theme"
import { Footer } from "../ui-kit/Footer"
import PageTitle from "../ui-kit/PageTitle"
import { i18nKey, Ii18n } from "../global/i18n"

interface CleverRedirectProps {}

interface CleverRedirectState {
    cleverSSOOn: boolean
    isLoggedIn: boolean
    ssoComplete: boolean
    ssoFailed: boolean
    errorMessage: string | null

    /**
     * States
     */
    currentStep: SSOStep
    showConnectionSpinner: boolean
    showConnectionSuccess: boolean
    showConnectionError: boolean
    showGatheringSpinner: boolean
    showGatheringSuccess: boolean
    showGatheringError: boolean
    showSyncingSpinner: boolean
    showSyncingSuccess: boolean
    showSyncingError: boolean
    showLoggingInSpinner: boolean
    showLoggingInSuccess: boolean
    showLoggingInError: boolean
}

export enum SSOStep {
    connectionStarted,
    connectionSucceeded,
    connectionFailed,
    gatheringStarted,
    gatheringSucceeded,
    gatheringFailed,
    syncingStarted,
    syncingSucceeded,
    syncingFailed,
    loggingInStarted,
    loggingInSucceeded,
    loggingInFailed
}

enum SSOText {
    connection,
    gathering,
    syncing,
    loggingIn
}

export class CleverRedirect extends Component<CleverRedirectProps, CleverRedirectState> implements IAuthObserver, ICleverCompositionServiceDelegate {
    private codeExtractor: ICleverCodeParser = container.get<ICleverCodeParser>(TYPES.ICleverCodeParser)
    private compositionService: ICleverCompositionService = container.get<ICleverCompositionService>(TYPES.ICleverCompositionService)
    private auth: IAuth = container.get<IAuth>(TYPES.IAuth)
	private featureFlagService: IFeatureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService)
    private router: Router = container.get<IRouter>(TYPES.IRouter)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    private INVALID_GRANT = "invalid_grant"

    state: CleverRedirectState = {
        cleverSSOOn: this.featureFlagService.cleverSSO(),
        isLoggedIn: false,
        ssoComplete: false,
        ssoFailed: false,
        errorMessage: null,

        currentStep: SSOStep.connectionStarted,
        showConnectionSpinner: true,
        showConnectionSuccess: false,
        showConnectionError: false,
        showGatheringSpinner: false,
        showGatheringSuccess: false,
        showGatheringError: false,
        showSyncingSpinner: false,
        showSyncingSuccess: false,
        showSyncingError: false,
        showLoggingInSpinner: false,
        showLoggingInSuccess: false,
        showLoggingInError: false,
    }
    
    componentDidMount() {
        document.getElementById('container')?.classList.add('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.add('hidden')
        document.getElementById('navigation-bar')?.classList.add('hidden')
        
        log('Clever Redirect - addAuthObserver')

        this.auth.addAuthObserver(this)

        if (this.state.cleverSSOOn) {
            this.attemptToAuthenticate()
        }
    }

    componentWillUnmount() {
        document.getElementById('container')?.classList.remove('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.remove('hidden')
        document.getElementById('navigation-bar')?.classList.remove('hidden')
	}

    render() {
        return (
            <>
                <Stack 
                    id="sso-container" 
                    horizontalAlign="center" 
                    tokens={CleverRedirectStyles.ssoStackTokens}
                    styles={CleverRedirectStyles.ssoStackStyle}
                >
                    <PageTitle title={this.i18n.get(i18nKey.singleSignOn)} isSSO={true} />

                    <Image src={hero} styles={CleverRedirectStyles.heroImage} />

                    <Text variant={FontSize.medium}>{this.i18n.get(i18nKey.pleaseWaitWhileWeLogYouIn)}</Text>
                    { this.state.ssoFailed && this.state.errorMessage &&
						<MessageBar
                            id="sso-error-banner"
							messageBarType={MessageBarType.error}
							dismissButtonAriaLabel="Close"
                            styles={CleverRedirectStyles.errorBannerStyles}
                        >
							{this.state.errorMessage}
						</MessageBar>
					}
                    <Stack 
                        id="step-stack" 
                        tokens={CleverRedirectStyles.defaultStackTokens} 
                        styles={CleverRedirectStyles.stepStackStyles}>
                        <Stack id="connection-stack" tokens={CleverRedirectStyles.defaultStackTokens} horizontal>
                            { this.state.showConnectionSpinner &&
                                <Spinner
                                    id="connection-spinner"
                                    ariaLive="assertive"
                                    styles={CleverRedirectStyles.spinnerStyle}
                                />
                            }

                            { this.state.showConnectionError &&
                                <Image id="connection-error-image" src={errorImage} styles={CleverRedirectStyles.statusImage} />
                            }

                            { this.state.showConnectionSuccess &&
                                <Image id="connection-success-image" src={successImage} styles={CleverRedirectStyles.statusImage} />
                            }
                            
                            <Text variant={FontSize.large}><span style={this.getSpanStyleFor(SSOText.connection)}>{this.i18n.get(i18nKey.establishingConnection)}</span></Text>
                        </Stack>
                        <Stack id="gathering-stack" tokens={CleverRedirectStyles.defaultStackTokens} horizontal>
                            { this.state.showGatheringSpinner &&
                                <Spinner
                                    id="gathering-spinner"
                                    ariaLive="assertive"
                                    styles={CleverRedirectStyles.spinnerStyle}
                                />
                            }

                            { !this.state.showGatheringSpinner 
                                && !this.state.showGatheringError 
                                && !this.state.showGatheringSuccess &&
                                <Image id="gathering-not-started-image" src={notStartedImage} styles={CleverRedirectStyles.statusImage} />
                            }

                            { this.state.showGatheringError &&
                                <Image id="gathering-error-image" src={errorImage} styles={CleverRedirectStyles.statusImage} />
                            }

                            { this.state.showGatheringSuccess &&
                                <Image id="gathering-success-image" src={successImage} styles={CleverRedirectStyles.statusImage} />
                            }

                            <Text variant={FontSize.large}><span style={this.getSpanStyleFor(SSOText.gathering)}>{this.i18n.get(i18nKey.gatheringCleverInformation)}</span></Text>
                        </Stack>
                        <Stack id="syncing-stack" tokens={CleverRedirectStyles.defaultStackTokens} horizontal>
                            { this.state.showSyncingSpinner &&
                                <Spinner
                                    id="syncing-spinner"
                                    ariaLive="assertive"
                                    styles={CleverRedirectStyles.spinnerStyle}
                                />
                            }

                            { !this.state.showSyncingSpinner 
                                && !this.state.showSyncingError 
                                && !this.state.showSyncingSuccess &&
                                <Image id="syncing-not-started-image" src={notStartedImage} styles={CleverRedirectStyles.statusImage} />
                            }

                            { this.state.showSyncingError &&
                                <Image id="syncing-error-image" src={errorImage} styles={CleverRedirectStyles.statusImage} />
                            }

                            { this.state.showSyncingSuccess &&
                                <Image id="syncing-success-image" src={successImage} styles={CleverRedirectStyles.statusImage} />
                            }

                            <Text variant={FontSize.large} color={Theme.accent.primary}>
                            <span style={this.getSpanStyleFor(SSOText.syncing)}>{this.i18n.get(i18nKey.syncingData)}</span>
                            </Text>
                        </Stack>
                        <Stack id="logging-in-stack" tokens={CleverRedirectStyles.defaultStackTokens} horizontal>
                            { this.state.showLoggingInSpinner &&
                                <Spinner
                                    id="logging-in-spinner"
                                    ariaLive="assertive"
                                    styles={CleverRedirectStyles.spinnerStyle}
                                />
                            }

                            { !this.state.showLoggingInSpinner 
                                && !this.state.showLoggingInError 
                                && !this.state.showLoggingInSuccess &&
                                <Image id="logging-in-not-started-image" src={notStartedImage} styles={CleverRedirectStyles.statusImage} />
                            }

                            { this.state.showLoggingInError &&
                                <Image id="logging-in-error-image" src={errorImage} styles={CleverRedirectStyles.statusImage} />
                            }

                            { this.state.showLoggingInSuccess &&
                                <Image id="logging-in-success-image" src={successImage} styles={CleverRedirectStyles.statusImage} />
                            }

                            <Text variant={FontSize.large}><span style={this.getSpanStyleFor(SSOText.loggingIn)}>{this.i18n.get(i18nKey.loggingIn)}</span></Text>
                        </Stack>
                    </Stack>
                </Stack>
                <Footer ssoFooter={true} />
            </>
        )
    }

    private attemptToAuthenticate = () => {
        const codeConfig = this.codeExtractor.getCode(document.URL)

        if (!codeConfig) {
            this.setState({ 
                showConnectionError: true, 
                showConnectionSpinner: false 
            })
            
            return
        }

        this.compositionService.startSSO(codeConfig, this).then(() => {
            this.setState({ 
                ssoComplete: true, 
                ssoFailed: false, 
                errorMessage: null
            })

            const authLoggedIn = this.auth.isLoggedIn()

            const consoleData = {
                'isLoggedIn': this.state.isLoggedIn,
                'authIsLoggedIn': authLoggedIn
            }

            log(`Clever Redirect - startSSO success - ${consoleData.isLoggedIn} - ${consoleData.authIsLoggedIn}`)

            if (this.state.isLoggedIn || authLoggedIn) {
                log(`Clever Redirect - Routing to Dashboard`)
                this.router.goToDashboard()
            } else {
                /**
                 * Get the user info
                 */
                log(`Clever Redirect - Getting user`)
                const fbUser = this.auth.firebaseUser()

                if (fbUser) {
                    this.auth.getAndSetUser(fbUser.uid, false).then(() => {
                        log(`Clever Redirect - Getting user success`)
                    }).catch(error => {
                        log(`Clever Redirect - Getting user failed - ${error}`)
                    })
                } else {
                    log(`Clever Redirect - No fbUser`)
                }    
            }
            
        }).catch(error => {
            log(`Clever Redirect - startSSO failed - ${error}`)
            this.setState({ 
                ssoComplete: true, 
                ssoFailed: true,
                errorMessage: this.errorMessageFrom(error)
            })
        })
    }

    private getSpanStyleFor = (text: SSOText) => {
        const currentStep = this.state.currentStep

        if (text === SSOText.connection 
            && (currentStep === SSOStep.connectionStarted 
                || currentStep === SSOStep.connectionFailed)) {
            return CleverRedirectStyles.spanStyleActive
        } else if (text === SSOText.gathering 
            && (currentStep === SSOStep.gatheringStarted 
                || currentStep === SSOStep.gatheringFailed)) {
            return CleverRedirectStyles.spanStyleActive
        } else if (text === SSOText.syncing
            && (currentStep === SSOStep.syncingStarted 
                || currentStep === SSOStep.syncingFailed)) {
            return CleverRedirectStyles.spanStyleActive
        } else if (text === SSOText.loggingIn 
            && (currentStep === SSOStep.loggingInStarted 
                || currentStep === SSOStep.loggingInFailed)) {
            return CleverRedirectStyles.spanStyleActive
        }

        return CleverRedirectStyles.spanStyleInactive
    }

    private errorMessageFrom(error: any): string {
        let message: string = this.i18n.get(i18nKey.unableToLogYouInContactSysAdmin)

        if (error.response 
            && error.response.data
            && error.response.data.error 
            && error.response.data.error === this.INVALID_GRANT) {
            message = this.i18n.get(i18nKey.cleverInvalidGrantError)
        }

        return message
     }

    /**
     * Delegate Functions
     */

    /**
     * IAuthObserver delegate
     */
    onAuthStateChange(isLoggedIn: boolean): void {
        log(`Clever Redirect - Setting logged in to - ${isLoggedIn}`)
        this.setState({ isLoggedIn: isLoggedIn })

        if (this.state.ssoComplete && isLoggedIn) {
            log(`Clever Redirect - Routing to Dashboard`)
            this.router.goToDashboard()
        }
    }

    /**
     * CleverCompositionServiceDelegate
     */
    didPerform(step: SSOStep): void {
        switch (step) {
            case SSOStep.connectionStarted:
                this.setState({ 
                    currentStep: step,
                    showConnectionSpinner: true,
                    showConnectionError: false,
                    showConnectionSuccess: false
                })

                break
            case SSOStep.connectionSucceeded:
                this.setState({ 
                    currentStep: step,
                    showConnectionSpinner: false,
                    showConnectionSuccess: true,
                    showConnectionError: false
                })

                break
            case SSOStep.connectionFailed:
                this.setState({ 
                    currentStep: step,
                    showConnectionSpinner: false,
                    showConnectionSuccess: false,
                    showConnectionError: true
                })

                break
            case SSOStep.gatheringStarted:
                this.setState({ 
                    currentStep: step,
                    showGatheringSpinner: true,
                    showGatheringError: false,
                    showGatheringSuccess: false
                })

                break
            case SSOStep.gatheringSucceeded:
                this.setState({ 
                    currentStep: step,
                    showGatheringSpinner: false,
                    showGatheringSuccess: true,
                    showGatheringError: false
                })

                break
            case SSOStep.gatheringFailed:
                this.setState({ 
                    currentStep: step,
                    showGatheringSpinner: false,
                    showGatheringError: true,
                    showGatheringSuccess: false
                })

                break
            case SSOStep.syncingStarted:
                this.setState({ 
                    currentStep: step,
                    showSyncingSpinner: true,
                    showSyncingSuccess: false,
                    showSyncingError: false
                })

                break
            case SSOStep.syncingSucceeded:
                this.setState({ 
                    currentStep: step,
                    showSyncingSpinner: false,
                    showSyncingSuccess: true,
                    showSyncingError: false
                })

                break
            case SSOStep.syncingFailed:
                this.setState({ 
                    currentStep: step,
                    showSyncingSpinner: false,
                    showSyncingError: true,
                    showSyncingSuccess: false
                })

                break
            case SSOStep.loggingInStarted:
                this.setState({ 
                    currentStep: step,
                    showLoggingInSpinner: true,
                    showLoggingInSuccess: false,
                    showLoggingInError: false
                })

                break
            case SSOStep.loggingInFailed:
                this.setState({ 
                    currentStep: step,
                    showLoggingInSpinner: false,
                    showLoggingInError: true,
                    showLoggingInSuccess: false
                })

                break
            case SSOStep.loggingInSucceeded:
                this.setState({ 
                    currentStep: step,
                    showLoggingInSpinner: false,
                    showLoggingInSuccess: true,
                    showLoggingInError: false
                })

                break
        }
    }
}
