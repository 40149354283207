import React, { Component } from "react"
import { container } from "../../DIContainer"
import {
	Stack,
	MessageBar,
	MessageBarType,
    Label,
} from "@fluentui/react"
import { TYPES } from "../../Types"
import { LearningPlan } from "../../models/LearningPlan"
import { i18nKey, Ii18n } from "../../global/i18n"
import { LearningPlanContentGrid } from "./LearningPlanContentGrid"
import PageTitle from "../../ui-kit/PageTitle"
import { DocumentId } from "../../global/TypeAliases"
import "./LearningPlanDetailModal.css"
import { LearningPlanDetailModalStyle } from "./LearningPlanDetailModalStyle"
import { Loader } from "../../ui-kit/Loader"
import { PrimaryButton } from "../../ui-kit/PrimaryButton"
import { IContent } from "../../models/IContent"
import { ILearningPlanCompositionService } from "../../services/LearningPlanCompositionService"

interface LearningPlanDetailModalProps {
    learningPlan: LearningPlan
    showProgressBar: boolean
    userClientIds: DocumentId[]
    onContentClick: (e: React.MouseEvent<HTMLDivElement>, content: IContent) => void
}

interface LearningPlanDetailModalState {
    serviceRequestCompleted: boolean
    showLoader: boolean
    contents: IContent[]
    errorMessage: string | null
    successMessage: string | null

    /**
     * Key: The document id of the content
     * Value: TRUE if the content has been completed, FALSE if it is not complete
     * e.g. { 'contentDocumentId': true }
     */
    completedContentsMap: {[key: string]: boolean}
    /**
     * This is the content to load when user hits the resume/start button
     */ 
    nextContentInProgress: IContent | null

    isResumingLearningPlan: boolean
}

export class LearningPlanDetailModal extends Component<LearningPlanDetailModalProps, LearningPlanDetailModalState> {
    private compositionService: ILearningPlanCompositionService = container.get<ILearningPlanCompositionService>(TYPES.ILearningPlanCompositionService)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    state: LearningPlanDetailModalState = {
        contents: [],
        serviceRequestCompleted: false,
        showLoader: true,
        errorMessage: null,
        successMessage: null,
        completedContentsMap: {},
        nextContentInProgress: null,
        isResumingLearningPlan: false
    }

    componentDidMount() {
        this.compositionService
            .getLearningPlanData(this.props.learningPlan.documentId, this.props.userClientIds)
            .then(data => {
                this.setState({
                    serviceRequestCompleted: true,
                    showLoader: false,
                    contents: data.contents,
                    completedContentsMap: data.completedContentIds,
                    nextContentInProgress: data.nextContentToResume,
                    isResumingLearningPlan: data.isResumingLearningPlan
                })
        }).catch(_ => {
            this.setState({
                errorMessage: this.i18n.get(i18nKey.failedToLoadLearningPlans),
                showLoader: false
            })
        })
    }

    render() {
        if (this.state.showLoader) {
            return <Loader text={this.i18n.get(i18nKey.loadingYourLearningPlan)} />
        }
        
        return (
            <div id="learning-plan-detail-modal-container">
                { this.state.serviceRequestCompleted && 
                    <Stack id="learning-plan-detail-stack">
                        <PageTitle title={"Trainings in " + this.props.learningPlan.name}/>
                        <Label id="number-of-trainings-label" styles={{root: {paddingLeft:50}}} >
                            {this.i18n.get(i18nKey.numberOfTrainings)}{this.state.contents.length}
                        </Label>
                        <Stack 
                            id="learning-plan-detail-modal-courses" 
                            horizontal={true} 
                            styles={LearningPlanDetailModalStyle.courseGridStackStyle}
                        >
                            <LearningPlanContentGrid
                                contents={this.state.contents}
                                completedContentsMap={this.state.completedContentsMap}
                                showProgressBar={this.props.showProgressBar}
                                userClientIds={this.props.userClientIds}
                                shouldWrap={false}
                                onContentClick={this.props.onContentClick}
                            />
                        </Stack>
                        { this.state.contents.length > 0 &&
                            <div id="resume-button-div" onClick={this.onResumeLearningPlanClickHandler}>
                                <PrimaryButton
                                    classID="resume-button"
                                    text={this.primaryButtonText()}
                                    onClick={this.onResumeLearningPlanClickHandler}
                                />
                            </div>
                        }
                    </Stack>
                }

                {this.createBanner()}
            </div>
        )
    }

    /**
     * Public Functions
     */

    dismissBanner = () => {
		this.setState({ errorMessage: null, successMessage: null })
    }

    /**
     * Private Functions
     */

    private onResumeLearningPlanClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (this.state.nextContentInProgress){
            this.props.onContentClick(e, this.state.nextContentInProgress)
        }
    }

    private primaryButtonText = (): string => {
        return this.state.isResumingLearningPlan 
                ? this.i18n.get(i18nKey.resumeLearningPlan) 
                : this.i18n.get(i18nKey.beginLearningPlan)
    }

    private createBanner = () => {
		if (this.state.errorMessage) {
			return (
				<MessageBar
                    id="general-error-banner"
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.errorMessage}
				</MessageBar>
			)
		} else if (this.state.successMessage) {
			return (
				<MessageBar
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			)
		}

		return null
    }
}