import { injectable } from "inversify";

export interface IURLCreator {
    createFor(file: File): string
}

@injectable()
export class URLCreator implements IURLCreator {
    createFor(file: File): string {
        return URL.createObjectURL(file)
    }
}