import React, { Component } from "react";
import Course from "../../models/Course";
import Iframe from "react-iframe"
import './iFrameContainerPage.css'
import { IAuth } from "../../auth/Auth";
import { TYPES } from "../../Types";
import { container } from "../../DIContainer";
import { Loader } from "../../ui-kit/Loader";
import { IRouter } from "../../Router";
import { i18nKey, Ii18n } from "../../global/i18n";
import { ILRSService } from "../../services/LRSService";
import { DocumentId } from "../../global/TypeAliases";
import { PrimaryButton } from "../../ui-kit/PrimaryButton";

interface GoogleFormContainerPageProps {
    course: Course
}

interface GoogleFormContainerPageState {
    loaded: boolean
}

export class GoogleFormContainerPage extends Component<GoogleFormContainerPageProps, GoogleFormContainerPageState> {
    private auth: IAuth = container.get<IAuth>(TYPES.IAuth)
    private router: IRouter = container.get<IRouter>(TYPES.IRouter)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private lrsService: ILRSService = container.get<ILRSService>(TYPES.ILRSService)

    state: GoogleFormContainerPageState = {
        loaded: false
    }

    private frameBorder = 0

    componentDidMount() {
        this.makeFullScreen(true)
        this.routeToLoginIfNeeded()
    }

    componentWillUnmount() {
        this.makeFullScreen(false)
    }

    render() {
        const dimension = this.state.loaded ? "100%" : "0%"

        return (
            <>
                { !this.state.loaded && 
                    <Loader text={this.i18n.get(i18nKey.loadingAFewThings)} />
                }
                
                <Iframe 
                    id="course-iframe"
                    url={this.props.course.locationUrl} 
                    width={dimension} 
                    height={dimension} 
                    frameBorder={this.frameBorder}
                    onLoad={this.onLoad}
                />

                { this.state.loaded && 
                    <PrimaryButton 
                        id="primary-button"
                        text={this.i18n.get(i18nKey.exitSurvey)} 
                        onClick={this.primaryButtonClicked}
                    />
                }
            </>
        )
    }

    /**
     * iFrame Loaded
     */
    onLoad = () => {
        this.setState({ loaded: true })
        this.markAsComplete()        
    }

    private primaryButtonClicked = () => {
        this.router.goBack()
    }

    private markAsComplete = () => {
        const uid = this.auth.uid()
        const clientId = this.clientId()

        if (uid === undefined || !clientId) {
            return
        }

        this.lrsService
            .postCompletionOverride(uid, clientId, this.props.course.lrsId)
            .catch(error => {
                // TODO: Log to rollbar
            })
    }

    private routeToLoginIfNeeded = () => {
        const uid = this.auth.uid()

        if (!this.auth.isLoggedIn() || !uid) {
            this.router.goToLogin()
        }
    }

    private makeFullScreen = (fullScreen: boolean) => {
        /**
         * documentElement is the <html> element
         */
        if (fullScreen) {
            document.documentElement.classList.add('auto-overflow')
            document.getElementById('container')?.classList.add('no-padding', 'no-margin')
            document.getElementById('content')?.classList.add('full-height', 'no-padding')
            document.getElementById('navigation-bar-container')?.classList.add('hidden')
            document.getElementById('navigation-bar')?.classList.add('hidden')    
        } else {
            document.documentElement.classList.remove('auto-overflow')
            document.getElementById('container')?.classList.remove('no-padding', 'no-margin')
            document.getElementById('content')?.classList.remove('full-height', 'no-padding')
            document.getElementById('navigation-bar-container')?.classList.remove('hidden')
            document.getElementById('navigation-bar')?.classList.remove('hidden')    
        }
    }

    private clientId = (): DocumentId | null => {
        const ids = Object.keys(this.props.course.clients)

        if (ids.length === 0) {
            return null
        }

        return ids[0]
    }
}