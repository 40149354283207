import React, { Component } from "react"
import { Stack, Image, Text} from '@fluentui/react'
import { MarketingIi18n, i18nKey } from "../global/Marketing-i18n"
import { container } from "../DIContainer"
import { TYPES } from "../Types"
import { MarketingFooterStyles } from "./MarketingFooterStyles"

import footerLogo from "../images/marketing/landing-footer-logo.svg"

export class MarketingFooter extends Component {
    private i18n = container.get<MarketingIi18n>(TYPES.MarketingIi18n)

    render() {
        return (
            <Stack 
                horizontal wrap grow
                horizontalAlign="space-between" 
                styles={MarketingFooterStyles.outerStackStyles} 
            >
                <Image src={footerLogo} styles={MarketingFooterStyles.logoStyles}/>
                <Stack styles={MarketingFooterStyles.innerStackStyles}>
                    <Text styles={MarketingFooterStyles.textStyles}>
                        {this.i18n.get(i18nKey.marketingFooterInfo)} 
                    </Text>
                </Stack>
                <Stack styles={MarketingFooterStyles.innerStackStyles}>
                    <Text styles={MarketingFooterStyles.textStyles}>
                        {this.i18n.get(i18nKey.digitalTrainingSolutions)}
                    </Text>
                    <Text styles={MarketingFooterStyles.textStyles}>
                        {this.i18n.get(i18nKey.by)}
                        <a href="https://www.cyborgmobile.com/" style={MarketingFooterStyles.linkStyle}>
                            {this.i18n.get(i18nKey.cyborgMobile)}
                        </a>
                    </Text>
                    <Text styles={MarketingFooterStyles.textStyles}>
                        {this.i18n.get(i18nKey.allRightsReserved)}
                    </Text>
                </Stack> 
            </Stack>
        )
    }
}