import React, { Component, ReactElement } from "react"
import { Icon, Stack, Text } from "@fluentui/react"
import { FontSize } from "../../../ui-kit/FontSize"
import { LearningPlan } from "../../../models/LearningPlan"
import { LearningPlanGridItemStyle } from "./LearningPlanGridItemStyle"
import { container } from "../../../DIContainer"
import { TYPES } from "../../../Types"
import "./LearningPlanGridItem.css"
import { i18nKey, Ii18n } from "../../../global/i18n"
import { ImageGrid, LearningPlanImageProps } from "../../../ui-kit/ImageGrid"
import { IFeatureFlagService } from "../../../services/FeatureFlagService"
import { IDeadlinesHelper } from "../../../utils/DeadlinesHelper"

interface LearningPlanGridItemProps {
    learningPlan: LearningPlan
    isComplete?: boolean
    deadline?: string
    onLearningPlanClick: (e: React.MouseEvent<HTMLDivElement>) => void
}

interface LearningPlanGridItemState {
    errorMessage: string | undefined
    contentCount: number
    imageProps: LearningPlanImageProps[]
}

export class LearningPlanGridItem extends Component<LearningPlanGridItemProps, LearningPlanGridItemState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private featureFlagService: IFeatureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService)
	private deadlinesHelper: IDeadlinesHelper = container.get<IDeadlinesHelper>(TYPES.IDeadlinesHelper)

    state: LearningPlanGridItemState = {
        contentCount: 0,
        errorMessage: undefined,
        imageProps: []
    }

    componentDidMount() {
        const imageProps: LearningPlanImageProps[] = this.props.learningPlan.contents.map(content => {
            return {
                src: content.imageUrl,
                alt: content.name
            }
        });
        this.setState({
            contentCount: this.props.learningPlan.contents.length,
            imageProps: imageProps
        })
    }

    render() {
        return (
            <div 
                className='learning-plan-grid-item' 
                id={this.props.learningPlan.documentId} 
                onClick={this.props.onLearningPlanClick}
            >
                <Stack styles={LearningPlanGridItemStyle.containerStyle}>   
                    <Stack styles={LearningPlanGridItemStyle.headerStyle}>
                        <ImageGrid imageProps={this.state.imageProps} />
                    </Stack>
                    <Stack tokens={LearningPlanGridItemStyle.stackTokens} styles={LearningPlanGridItemStyle.bodyStyle}>
                        <Text id="learning-plan-grid-item-course-name" variant={FontSize.large}>
                            {this.props.learningPlan.name}
                        </Text>
                    </Stack>
                    <Stack horizontal={true} tokens={LearningPlanGridItemStyle.stackTokens} styles={LearningPlanGridItemStyle.footerStyle}>
                        { this.renderCardFooter() }                      
                    </Stack>
                </Stack>
            </div>  
        )}

    private renderCardFooter(): ReactElement {
        if (this.featureFlagService.deadline() && this.props.deadline && !this.props.isComplete) {
            const deadline = this.props.deadline
            const deadlineCopy = this.deadlinesHelper.renderTrainingDeadline(deadline)
            if (deadlineCopy !== "") {
                return (
                    <>
                        <Text id="learning-plan-grid-item-content-count" variant={FontSize.medium}>{this.i18n.get(i18nKey.trainingsInPlan) + this.state.contentCount}</Text>
                        <Text 
                            id="learning-plan-grid-item-deadline" 
                            variant={FontSize.medium} 
                            styles={LearningPlanGridItemStyle.deadlineStyle}
                        >
                            {deadlineCopy}
                        </Text>
                    </>
                )
            } 
        }

        if (this.props.isComplete) {
            return (
                <Stack styles={LearningPlanGridItemStyle.bodyStyle} horizontal={true}>
                    <Icon id="completed-icon" iconName="Completed" styles={LearningPlanGridItemStyle.completedIcon} />
                    <div id="completed-label" style={LearningPlanGridItemStyle.completedDiv}>
                        {this.i18n.get(i18nKey.completed)}
                    </div>
                </Stack>
            )
        } else {
            return <Text 
                        id="learning-plan-grid-item-content-count" 
                        variant={FontSize.medium}
                    >
                            {this.i18n.get(i18nKey.trainingsInPlan) + this.state.contentCount}
                    </Text>
        }
    }
}
