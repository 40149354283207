import React from "react";

interface ProfileImageV2Props {
    source: string;
    width?: number;
    height?: number;
    className?: string;
}
export const ProfileImageV2: React.FC<ProfileImageV2Props> = (props) => {
    return (
        <img
            id="profile-img"
            src={props.source}
            alt="profile avatar"
            className={props.className}
            data-testid="profile-photo"
            height={props.height ? props.height : 150}
            width={props.width ? props.width : 150}
            {...props}
        />
    );
};
