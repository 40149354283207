import { Component, MouseEvent, ReactElement } from "react"
import IClientService from "../../../services/ClientService"
import {
	Stack,
	IStackTokens,
	Spinner,
	MessageBar,
	MessageBarType,
	Label,
	SearchBox,
} from "@fluentui/react"
import { TabPanelProps } from "../TabPanelProps";
import "./AddCohortToClient.css"
import DTSTable from "../../../ui-kit/DTSTable"
import Cohort from "../../../models/Cohort"
import DTSTableData from "../../../models/DTSTableData"
import { ICohortService } from "../../../services/CohortService"
import IDataTableFactory from "../../../factories/DataTableFactory"
import { PrimaryButton } from "../../../ui-kit/PrimaryButton"
import { AdminUIStyles } from "../users/AdminUI"
import { container } from "../../../DIContainer"
import { TYPES } from "../../../Types"
import { ISearchFilter } from "../users/SearchFilter"
import { log } from "../../../global/Logger"
import { Dictionary } from "../../../global/TypeAliases"
import { i18nKey, Ii18n } from "../../../global/i18n";

interface AddCohortToClientProps extends TabPanelProps {
	documentId: string
}

interface AddCohortToClientState {
	searchResults: Cohort[]
	tableData: DTSTableData | undefined
	primaryButtonDisabled: boolean
	showSpinner: boolean
	showSearchSpinner: boolean
	errorMessage: string | undefined
	successMessage: string | undefined
	selectedCohorts: string[]
}

const stackStyling: IStackTokens = {
	childrenGap: "20px",
}

export default class AddCohortToClient extends Component<AddCohortToClientProps, AddCohortToClientState> {
	private cohortService: ICohortService = container.get<ICohortService>(TYPES.ICohortService)
	private searchFilter: ISearchFilter = container.get<ISearchFilter>(TYPES.ISearchFilter)
	private clientService: IClientService = container.get<IClientService>(TYPES.IClientService)
	private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	state: AddCohortToClientState = {
		searchResults: [],
		tableData: undefined,
		primaryButtonDisabled: true,
		showSpinner: false,
		showSearchSpinner: false,
		errorMessage: undefined,
		successMessage: undefined,
		selectedCohorts: [],
	}

	render() {
		let banner = null
		if (this.state.errorMessage) {
			banner = (
				<MessageBar
					id="add-cohort-to-client-error-banner"
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.errorMessage}
				</MessageBar>
			)
		} else if (this.state.successMessage) {
			banner = (
				<MessageBar
					id="add-cohort-to-client-success-banner"
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			)
		}

		let searchResult: ReactElement | undefined
		if (this.state.tableData !== undefined && !this.state.showSearchSpinner) {
			searchResult = (
				<div id="add-cohort-to-client-table">
					<Stack tokens={stackStyling}>
						<DTSTable
							data={this.state.tableData}
							pagination={true}
                            onSelectedRowsChange={this.onSelectedRowChange}
						/>
						
						<PrimaryButton
							id="add-cohort-to-client-primary-button"
							text={this.i18n.get(i18nKey.addGroups)}
							onClick={this.primaryButtonClicked}
							disabled={this.state.primaryButtonDisabled}
							hidden={this.state.showSpinner}
						/>

						{ this.state.showSpinner &&
							<Spinner
								label={this.i18n.get(i18nKey.addingGroups)}
								ariaLive="assertive"
								labelPosition="right"
							/>
						}

					</Stack>
				</div>
			)
		}

		return (
			<div id="add-cohort-to-client-modal-container">
				<Stack tokens={stackStyling} horizontalAlign="center">
					<Label>{this.i18n.get(i18nKey.placeholderGroupsSearch)}</Label>
					<SearchBox
						id="add-cohort-to-client-search-box"
						styles={AdminUIStyles.searchBoxStyle}
						placeholder={this.i18n.get(i18nKey.placeholderGroupsSearch)}
						onEscape={(ev) => {
							log("Custom onEscape Called")
						}}
						onClear={(ev) => {
							log("Custom onClear Called")
						}}
						onChange={(_, newValue) =>
							log("SearchBox onChange fired: " + newValue)
						}
						onSearch={(newValue) => this.search(newValue)}
					/>

					{ this.state.showSearchSpinner && 
						<Spinner
							id="add-cohort-to-client-search-spinner"
							label="Searching"
							ariaLive="assertive"
							labelPosition="right"
						/>
					}

					{searchResult}
					{banner}
				</Stack>
			</div>
		)
    }

	onSelectedRowChange = (selectedRowState: {
		allSelected: boolean
		selectedCount: number
		selectedRows: Dictionary[]
	}) => {
		if (selectedRowState.selectedCount === 0) {
			this.setState({ selectedCohorts: [] }, () => {
				this.enablePrimaryButtonIfNeeded()
			})
			return
		}

        const selectedIds = selectedRowState.selectedRows.map((row) => {
			return row.id
		})

		log(selectedIds.length + " cohorts selected")

		this.setState({ selectedCohorts: selectedIds }, () => {
			this.enablePrimaryButtonIfNeeded()
		})
	}

	dismissBanner = () => {
		this.setState({ errorMessage: undefined, successMessage: undefined })
	}

	private primaryButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: undefined,
			successMessage: undefined,
			showSpinner: true,
			primaryButtonDisabled: true,
		})

		this.clientService
			.addCohortsToClient(this.state.selectedCohorts, this.props.documentId)
			.then(() => {

                let message: string
                if (this.state.selectedCohorts.length === 1) {
                    message = "1 " + this.i18n.get(i18nKey.groupAdded)
                } else {
                    message = this.state.selectedCohorts.length + " " + this.i18n.get(i18nKey.groupsAdded)
                }

				this.setState({
					successMessage: message,
					showSpinner: false,
					primaryButtonDisabled: false,
					selectedCohorts: [],
				})
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showSpinner: false,
					primaryButtonDisabled: false,
				})
			})
	}

	search = (searchTerm: string) => {
		this.setState({ showSearchSpinner: true })
		/**
		  The search is actually just a filter on all cohorts in the db. Obviously this doesn't scale. once we have a large set of users but is a solution until we get search index in Firebase.

		  Blame Firebase: `Cloud Firestore doesn't support native indexing or search for text fields in documents.`
		  https://firebase.google.com/docs/firestore/solutions/search
		 */
		this.cohortService
			.cohorts()
			.then(cohorts => {
				const filtered = this.searchFilter.cohortsForSearchTerm(searchTerm, cohorts)
				const data = this.dataTableFactory.createCohortsTableData(filtered)

				this.setState({ showSearchSpinner: false, searchResults: filtered, tableData: data })
			})
			.catch(error => {
				this.setState({ showSearchSpinner: false, errorMessage: error.message })
			})

	}

	private enablePrimaryButtonIfNeeded = () => {
		this.setState({
			primaryButtonDisabled: !(this.state.selectedCohorts.length > 0),
		})
	}
}
