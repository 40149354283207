import { ChartType, IChartData } from "../models/IChartData"

export class ChartData implements IChartData {
    name: string
    value: number

    constructor(name: string, value: number) {
        this.name = name
        this.value = value
    }

    chartType(): ChartType {
        return ChartType.pieChart
    }
}
