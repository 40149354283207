/* istanbul ignore file */
export enum PathNames {
    Home = "/",
    Dashboard = "/dashboard",
    Admin = "/admin",
    Contents = "/contents",
    Learners = "/learners",
    Profile = "/profile",
    Reports = "/reports",
    GlobalStats = "/global-stats",
    Clients = "/clients",
    UploadUsers = "/uploadUsers",
    Course = "/course",
    GuestCourse = "/guestCourse",
    Survey = "/survey",
    GoogleFormTraining = "/googleForm",
    ReportCompletion = "/reportCompletion",
    SurveyReport = "/surveyReport",
    Login = "/login",
    SignUp = "/signup",
    ResetPasswordRequest = "/reset_password_request",
    PrivacyPolicy = "/privacyPolicy",
    PasswordResetSent = "/password_reset_sent",
    Clever = "/clever",
    Registration = "/registration/:token",
    EmailVerify = "/emailVerify",
    Testimonials = "/testimonials",
    Features = "/features",
    ContactUs = "/contact-us",
    AddNewLearningPlan = "/addLearningPlan",
    EditLearningPlan = "/editLearningPlan",
    ContentsCourseTab = "/contents?t=course",
    ContentsLearningPlanTab = "/contents?t=lp",
    ContentsSurveyTab = "/contents?t=survey",
    EditGroupDetails = "/editGroupDetails",
    LogInAs = "/logInAs",
    ResetPassword = "/reset_password",
    ViewSurvey = "/viewSurvey",
    LearningPlans = "/learningPlans",
    GuestDashboard = "/guest",
    ManageInvitations = "/manage-guest-codes"
}
