import { injectable } from "inversify";
import { container } from "../DIContainer";
import { Dictionary, DocumentId, Uid } from "../global/TypeAliases";
import { TYPES } from "../Types";
import { ILRSService } from "./LRSService";
import { IEventReportingService } from '../services/EventReportingService'
import { Survey } from "../models/Survey";
import { ISurveyCompositionService } from "./SurveyCompositionService";
import { ISurveyResumer } from "../pages/admin/surveys/SurveyResumer";

export interface IReportUserSurveyDetailsModalCompositionService {
    getData(userId: Uid, clientId: DocumentId, survey: Survey): Promise<IReportUserSurveyDetailsData>
}

export interface IReportUserSurveyDetailsData {
    numberOfAttempts: number
    survey: Survey
    surveyState: Dictionary
}

@injectable()
export class ReportUserSurveyDetailsModalCompositionService implements IReportUserSurveyDetailsModalCompositionService {
    private lrsService: ILRSService = container.get<ILRSService>(TYPES.ILRSService)
    private surveyCompositionService: ISurveyCompositionService = container.get<ISurveyCompositionService>(TYPES.ISurveyCompositionService)
    private surveyResumer: ISurveyResumer = container.get<ISurveyResumer>(TYPES.ISurveyResumer)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)

    getData(userId: Uid, clientId: string, survey: Survey): Promise<IReportUserSurveyDetailsData> {
        let promiseArray: Promise<any>[] = []

        promiseArray.push(this.lrsService.getNumberOfAttemptsOnCourse(userId, clientId, survey.lrsId))
        promiseArray.push(this.surveyCompositionService.loadSurveyData(userId, survey.documentId))

        return new Promise((resolve, reject) => {
            Promise.all(promiseArray)
                    .then(result => {
                        resolve({ 
                            numberOfAttempts: result[0].length,
                            survey: this.surveyResumer.resume(result[1].survey, result[1].stateData),
                            surveyState: result[1].stateData
                        })
                    })
                    .catch(error => {
                        reject(error)
                    })
        })
    }
}