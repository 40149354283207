
import React, { Component } from 'react'
import {
    IconButton,
    MessageBar,
    MessageBarType,
    Modal,
    Spinner,
    Stack,
    Text,
} from "@fluentui/react"
import { ProfileUIStyles, ProfileUITokens, ProfileUIIcons } from './ProfileUI'
import { container } from "../../DIContainer"
import { TYPES } from "../../Types"
import IAuthService from "../../services/AuthService"
import "./ProfilePasswordUpdate.css"
import { TextField } from '../../ui-kit/TextField'
import { PrimaryButton } from "../../ui-kit/PrimaryButton"
import { IEventReportingService } from '../../services/EventReportingService'

interface ProfilePasswordUpdateProps {
    OnPasswordUpdate: () => void
    user: firebase.User
}

interface ProfilePasswordUpdateState {
    isOpen: boolean
    showSpinner: boolean
    errorMessage: string | undefined
    successMessage: string | undefined
    updateButtonDisabled: boolean
    currentPassword: string 
    newPassword: string
    newPasswordConfirmation: string 
}

export class ProfilePasswordUpdate extends Component <ProfilePasswordUpdateProps, ProfilePasswordUpdateState>{
    private authService = container.get<IAuthService>( TYPES.IAuthService)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)

    state = {
        isOpen: false,
        showSpinner: false,
        errorMessage: undefined,
        successMessage: undefined,
        updateButtonDisabled: true,
        currentPassword: "",
        newPassword: "",
        newPasswordConfirmation: "",
    }

    render() {
		let banner = null
		if (this.state.errorMessage) {
			banner = (
				<MessageBar
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.errorMessage}
				</MessageBar>
			)
		} else if (this.state.successMessage) {
			banner = (
				<MessageBar
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			)
        }
        return (
            <Modal 
                isBlocking={false} 
                isOpen={this.state.isOpen} 
                onDismiss={this.modalDismissed}>
                <div className="profile-update-password-modal">
                    <div>
                        <IconButton
                            styles={ProfileUIStyles.editProfileCloseButton}
                            ariaLabel="Close edit profile modal"
                            iconProps={ProfileUIIcons.cancel}
                            onClick={this.Hide}
                        />
                    </div>
                    <Stack styles={ProfileUIStyles.editProfileModalStackStyles} tokens={ProfileUITokens.verticalGapStackTokens} id="update-password-stack">
                        <Text variant="xLarge">Update Password</Text>
                        <TextField
                            id="current-password-textfield"
                            label="Current Password"
                            defaultValue={this.state.currentPassword}
                            type="password"
                            onChange={this.currentPasswordTextFieldChanged}
                            autoComplete="current-password"
                        />
                        <TextField
                            id="new-password-textfield"
                            label="New Password"
                            defaultValue={this.state.newPassword}
                            type="password"
                            onChange={this.newPasswordTextFieldChanged}
                            autoComplete="new-password"
                        />
                        <TextField
                            id="confirm-password-textfield"
                            label="Confirm Password"
                            defaultValue={this.state.newPasswordConfirmation}
                            type="password"
                            onChange={this.newPasswordConfirmationTextFieldChanged}
                            autoComplete="new-password"
                        />
                        {banner}
                        <PrimaryButton
                            id="profile-password-update-button"
                            disabled={this.state.updateButtonDisabled}
                            text="Update password"
                            onClick={this.onProfileUpdate}
                        />
                        <div hidden={!this.state.showSpinner}>
                            <Spinner
                                label="Updating password..."
                                ariaLive="assertive"
                                labelPosition="right"
                            />
                        </div>
                    </Stack>
                </div>
            </Modal>
        )
    }

    Hide = () => {
        this.setState({
            isOpen: false,
            errorMessage: undefined,
            successMessage: undefined,
            showSpinner: false,
            updateButtonDisabled: false,
        })
        this.props.OnPasswordUpdate()
    }

    Show = () => {
        this.setState({isOpen: true})
    }

    onProfileUpdate = () => {
        this.setState({
            errorMessage: undefined,
            successMessage: undefined,
            showSpinner: true,
            updateButtonDisabled: true,
        })
        if (this.state.newPassword !== "" && this.props.user.email !== null) {
            let credential = this.authService.userCredentials(this.props.user.email, this.state.currentPassword)
            this.props.user.reauthenticateWithCredential(credential)
            .then(() => {
                this.props.user.updatePassword( this.state.newPassword )
                .then(() => {
                    this.setState({
                        successMessage: "Password has been updated",
                        showSpinner: false,
                        updateButtonDisabled: false
                    })
                })
                .catch(error => {
                    this.eventReportingService.error(error.message, error)
                    this.setState({
                        errorMessage: error.Message,
                        showSpinner: false,
                        updateButtonDisabled: false,
                    })
                })
            })
            .catch(error => {
                this.eventReportingService.error(error.message, error)
                this.setState({
                    errorMessage: "Invalid current password",
                    showSpinner: false,
                    updateButtonDisabled: false,
                })
            })
        }
    }

	currentPasswordTextFieldChanged = ( _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({ currentPassword: newValue? newValue : ""}, () => {
            this.enableUpdateButtonIfNeeded()
        })
    }

	newPasswordTextFieldChanged = ( _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({ newPassword: newValue? newValue : ""}, () => {
            this.enableUpdateButtonIfNeeded()
        })
    }

	newPasswordConfirmationTextFieldChanged = ( _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({ newPasswordConfirmation: newValue? newValue : ""}, () => {
            this.enableUpdateButtonIfNeeded()
        })
    }

	enableUpdateButtonIfNeeded() {
		let disable = true
		if (
			this.state.currentPassword.length > 0 &&
			this.state.newPassword.length > 0 &&
			this.state.newPasswordConfirmation.length > 0 &&
			this.state.newPassword === this.state.newPasswordConfirmation
		) {
			disable = false
		}
		this.setState({ updateButtonDisabled: disable })
	}

    dismissBanner = () => {
		this.setState({ errorMessage: undefined, successMessage: undefined })
    }

	modalDismissed = () => {
        this.Hide()
	}
}
