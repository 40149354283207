import { Component } from "react";
import { Pivot, PivotItem } from "@fluentui/react";

import EditClientDetails from "./EditClientDetails";
import AddCohortToClient from "./AddCohortToClient";

import "./EditClient.css";
import Client from "../../../models/Client";
import RemoveCohortFromClient from "./RemoveCohortFromClient";
import { container } from "../../../DIContainer";
import { i18nKey, Ii18n } from "../../../global/i18n";
import { TYPES } from "../../../Types";
import { EditClientLicenseInfo } from "./EditClientLicenseInfo";

interface EditClientProps {
	documentId: string | undefined;
	client: Client
	clientUpdated: (successMessage?: string) => void
}

export class EditClient extends Component<EditClientProps> {
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	render() {
		if (this.props.documentId === undefined) {
			return null
		}

		return (
			<div id="modal-container-client">
				<Pivot aria-label="edit client admin panels">
					<PivotItem headerText={this.i18n.get(i18nKey.editDetails)}>
						<EditClientDetails
							title={this.i18n.get(i18nKey.editDetailsPanel)}
							index={0}
							documentId={this.props.documentId}
							client={this.props.client}
							clientUpdated={this.props.clientUpdated}
						/>
					</PivotItem>
					<PivotItem headerText={this.i18n.get(i18nKey.licenseInfo)}>
						<EditClientLicenseInfo
							title={this.i18n.get(i18nKey.licenseInfoPanel)}
							index={1}
							documentId={this.props.documentId}
							client={this.props.client}
							clientUpdated={this.props.clientUpdated}
						/>
					</PivotItem>
					<PivotItem headerText={this.i18n.get(i18nKey.addGroups)}>
						<AddCohortToClient
							title={this.i18n.get(i18nKey.addGroupsPanel)}
							index={2}
							documentId={this.props.documentId}
						/>
					</PivotItem>
					<PivotItem headerText={this.i18n.get(i18nKey.removeGroups)}>
						<RemoveCohortFromClient
							title={this.i18n.get(i18nKey.removeGroupsPanel)}
							index={3}
							documentId={this.props.documentId}
						/>
					</PivotItem>
				</Pivot>
			</div>
		);
	}
}
