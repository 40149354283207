import { Dictionary, DocumentId } from "./../global/TypeAliases"
import { injectable } from "inversify"
import { container } from "../DIContainer"
import { log } from "../global/Logger"
import { Invitation } from "../models/Invitation"
import { TYPES } from "../Types"
import { IEventReportingService } from "./EventReportingService"
import { IFirebase } from "./Firebase"
import { i18nKey, Ii18n } from "../global/i18n"

const INVITATION_CODES_COLLECTION = "invitation-codes"

export interface IInvitationCodeService {
    /**
     * Gets the invitation via the code. The code for invitations is also the document id.
     * @param code The invitation code. This is also a document id.
     */
    getInvitation(code: string): Promise<Invitation>

    /**
     * Updates the invitation via the code. The code for invitations is also the document id.
     * @param invitationCode The invitation code. This is also a document id of the respective invitation code.
     * @param entities The new list of entities to update the invitation code with.
     * @param isExpired A boolean representing whether the invitation code is marked as 'expired'. True is yes, false is no.
     * @return Void
     */
    updateInvitationDetails(invitationCode: string, entities: string[], isExpired: boolean): Promise<void>
    
    /**
     * Retrieve all invitations
     * @return An array of Invitations
     */
    invitations(): Promise<Invitation[]>

    /**
     * Add new invitation. Does not check if item already exists.
     * @param invitation the new invitation to add
     */
    addInvitation(invitation: Invitation): Promise<void>

    /**
     * Update invitation details
     * @param code the invitation code for which to update
     * @param entities the updated list of entities associated with this invitation
     * @param expired the updated expiration state associated with this invitation
     * @param hookText the page text that displays when the user accesses an invitation
     * @param selectedTrainings unlocked content for the user to access via an invitation
     */
    updateInvitation(code: string, entities: string[], expired: boolean, hookText: string, selectedTrainings: string[]): Promise<void>

    /**
     * Delete an invitation
     * @param code the invitation code for which to delete
     */
    deleteInvitation(code: string): Promise<void>
}

@injectable()
export class InvitationCodeService implements IInvitationCodeService {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private firebase: IFirebase = container.get<IFirebase>(TYPES.IFirebase)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)

    /* istanbul ignore next */
    getInvitation(code: DocumentId): Promise<Invitation> {
        return new Promise((resolve, reject) => {
            let get = this.firebase.app.functions().httpsCallable("getInvitation")

            get({ documentId: code })
                .then((result) => {
                    const data = result.data[code]
                    if (!data) {
                        throw new Error(this.i18n.get(i18nKey.invalidInvitation))
                    }

                    const invitation = new Invitation(code, data.expired, data.entities, data.hookText, data.selectedTrainings)
                    resolve(invitation)
                })
                .catch((error) => {
                    this.eventReportingService.error(error.message, error)
                    reject(error)
                })
        })
    }

    invitations(): Promise<Invitation[]> {
        return new Promise((resolve, reject) => {
            this.firebase.db
                .collection(INVITATION_CODES_COLLECTION)
                .withConverter(Invitation.Converter)
                .get()
                .then((querySnapshot) => {
                    let codes: Invitation[] = []
                    querySnapshot.forEach((doc) => {
                        let code = doc.data()
                        code.code = doc.id
                        codes.push(code)
                    })

                    resolve(codes)
                })
                .catch((error) => {
                    this.eventReportingService.error(error.message, error)
                    log("InvitationCodeService - invitations() - Failed - " + error)
                    reject(error)
                })
        })
    }

    updateInvitationDetails(invitationCode: string, entities: string[], isExpired: boolean): Promise<void> {
        return new Promise((resolve, reject) => {
            const db = this.firebase.db

            db.collection("invitation-codes")
                .doc(invitationCode)
                .update({
                    entities: entities,
                    expired: isExpired
                })
                .then(() => {
                    log("InvitationCodeService - updateInvitationDetails - Success!")
                    resolve()
                })
                .catch(error => {
                    this.eventReportingService.error(error.message, error)
					log(`InvitationCodeService - updateInvitationDetails - Failed! - ${error.message}`)
					reject(error)
                })

        })
    }

    addInvitation(invitation: Invitation): Promise<void> {
        const documentId: string = invitation.code
        const item: Dictionary = {
            entities: invitation.entities,
            expired: invitation.expired,
            hookText: invitation.hookText,
            selectedTrainings: invitation.selectedTrainings
        }

        return new Promise((resolve, reject) => {
            this.firebase.db
                .collection(INVITATION_CODES_COLLECTION)
                .doc(documentId)
                .set(item)
                .then(() => {
                    log("Success: InvitationCodeService - addInvitation()")
                    resolve()
                })
                .catch((error) => {
                    this.eventReportingService.error(error.message, error)
                    log("Error: InvitationCodeService - addInvitation() - " + error)
                    reject(error)
                })
        })
    }

    updateInvitation(code: DocumentId, entities: string[], expired: boolean, hookText: string, selectedTrainings: string[]): Promise<void> {
        return new Promise((resolve, reject) => {
            this.firebase.db
                .collection(INVITATION_CODES_COLLECTION)
                .doc(code)
                .update({
                    entities: entities,
                    expired: expired,
                    hookText: hookText,
                    selectedTrainings: selectedTrainings
                })
                .then(() => {
                    log("Success: InvitationCodeService - updateInvitation()")
                    resolve()
                })
                .catch((error) => {
                    this.eventReportingService.error(error.message, error)
                    log("Error: InvitationCodeService - updateInvitation() - " + error)
                    reject(error)
                })
        })
    }

    /* istanbul ignore next */
    deleteInvitation(code: DocumentId): Promise<void> {
        return new Promise((resolve, reject) => {
            let deleteCode = this.firebase.app.functions().httpsCallable("deleteInvitation")

            deleteCode({ documentId: code })
                .then(() => {
                    log("InvitationCodeService - deleteInvitation - Success")
                    resolve()
                })
                .catch((error) => {
                    this.eventReportingService.error(error.message, error)
                    log(`InvitationCodeService - deleteInvitation - Failed - ${error}`)
                    reject(error)
                })
        })
    }
}
