import { injectable } from "inversify";
import { container } from "../DIContainer";
import { i18nKey, Ii18n, } from "../global/i18n";
import { TYPES } from "../Types";
import firebase from "firebase"

export interface IDateProvider {
    /**
	 * Get a Date object
	 * @return A JavaScript Date object
	 */
    getDate(): Date;

    /**
	 * Get the current date as an ISO 8601 string
	 * @return string in ISO 8601 date format
	 */
    getDateAsISOString(): string;

    /**
     * Gets the number of days from miliseconds
     * @param ms a date represented in miliseconds
     */
    getDaysFromMiliseconds(ms: number): number;

    /**
     * Returns the appropriate copy based on the content deadline
     * difference 
     * @param deadlineDiff the number of days between
     * the deadline and current date
     */
    getDeadlineCopy(deadlineDiff: number): string;

    /**
     * Parses a Firebase Firestore Timestamp from { seconds, nanoseconds } to
     * Month Day, Year
     * e.g.: January 1, 2000
     *
     * @param timestamp the Firebase Firestore Timestamp object to parse
     * @returns the parsed Timestamp as string in Month Day, Year format; e.g. January 1, 2000
     */
    parseFirebaseTimestampToStringDate(timestamp: any): string

    /**
     * Generates a new Firebase Firestore Timestamp given a string formatted date
     *
     * @param date a string formatted date such as "January 1, 2000"
     * @returns new Firebase Firestore Timestamp object representing the given string date
     */
    parseStringDateToTimestamp(date: string): firebase.firestore.Timestamp
}

@injectable()
export class DateProvider implements IDateProvider {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    getDate(): Date {
        return new Date()
    }

    getDateAsISOString(): string {
        return new Date().toISOString()
    }

    getDaysFromMiliseconds(ms: number): number {
        let days, hours, mins, secs;
        secs = Math.floor(ms / 1000);
        mins = Math.floor(secs / 60);
        secs = secs % 60;
        hours = Math.floor(mins / 60);
        mins = mins % 60;
        days = Math.floor(hours / 24);
        hours = hours % 24;
        return days;
    }

    getDeadlineCopy(deadlineDiff: number): string {
        if (deadlineDiff === 0) {
            return `${this.i18n.get(i18nKey.dueToday)}` 
        } else if (deadlineDiff === 1) {
            return `${this.i18n.get(i18nKey.dueInOneDay)}` 
        } else if (deadlineDiff >= 2 && deadlineDiff <= 7) {
            return `${this.i18n.get(i18nKey.dueIn)}${deadlineDiff}${this.i18n.get(i18nKey.days)}`
        } else if (deadlineDiff < 0) {
            return `${this.i18n.get(i18nKey.overdue)}`
        } else {
            return ""
        }
    }

    parseFirebaseTimestampToStringDate(timestamp: any): string {
        // Firebase returns the timestamp as a generic Object, so we are recasting it as a proper
        // Firestore.Timestamp in order to use the built in functions.
        const actualTimestamp = new firebase.firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds)
        const date = actualTimestamp.toDate()

        const monthNumber: number = date.getMonth()
        const day = date.getDate()
        const year = date.getFullYear()

        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getMonth
        // Date.getMonth() returns 0 for January
        const monthString: string = months[monthNumber]
        return monthString + " " + day + ", " + year
    }

    parseStringDateToTimestamp(date: string): firebase.firestore.Timestamp {
        // throws `IllegalArgumentException` if unsuccessful
        const myDate = new Date(date)
        return firebase.firestore.Timestamp.fromDate(myDate)
    }
}
