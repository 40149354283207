import { MessageBar, MessageBarType, Stack, StackItem } from "@fluentui/react";
import PageTitle from "../../../ui-kit/PageTitle";
import { Component } from "react";
import { SurveyInteractionPanel } from "./SurveyInteractionPanel";
import { SurveyLayoutPanel } from "./SurveyLayoutPanel";
import { SurveyPropertiesPanel } from "./SurveyPropertiesPanel";
import { Survey } from "../../../models/Survey";
import { ISurveyFactory, SurveyInteractionData } from "./SurveyFactory";
import { container } from "../../../DIContainer";
import { TYPES } from "../../../Types";
import { SurveyCommandBar } from "./SurveyCommandBar";
import { DocumentId } from "../../../global/TypeAliases";
import { ISurveyService } from "../../../services/SurveyService";
import { Loader } from "../../../ui-kit/Loader";
import { i18nKey, Ii18n } from "../../../global/i18n";

interface SurveyPageProps {
    surveyDocumentId: DocumentId | null
    clientId: DocumentId
}

interface SurveyPageState {
    surveyPackage: SurveyPackage | null
    requestComplete: boolean
    focusedInteractionId: string
    pageTitle: string 
    errorMessage: string | null
}

export interface SurveyPackage {
    survey: Survey
    previewCoverImage: File | null
}

export enum InteractionType {
    shortText = "text",
    longText = "comment",
    radioButtons = "radiogroup",
    checkbox = "checkbox",
    rating = "rating",
    matrix = "matrix",
    ranking = "ranking",
    dropDown = "dropdown"
}

export class SurveyPage extends Component<SurveyPageProps, SurveyPageState> {
    private surveyFactory: ISurveyFactory = container.get<ISurveyFactory>(TYPES.ISurveyFactory)
    private surveyService: ISurveyService = container.get<ISurveyService>(TYPES.ISurveyService)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    state: SurveyPageState = {
        surveyPackage: null,
        requestComplete: false,
        focusedInteractionId: "",
        pageTitle: this.i18n.get(i18nKey.newSurvey),
        errorMessage: null
    }

    componentDidMount() {
        if (this.props.surveyDocumentId) {
            this.surveyService
                .surveyForDocumentId(this.props.surveyDocumentId)
                .then(survey => {
                    this.setState({ 
                        surveyPackage: { survey: survey, previewCoverImage: null }, 
                        pageTitle: survey.name,
                        requestComplete: true 
                    })
                }).catch(_ =>{
                    this.setState({ 
                        errorMessage: this.i18n.get(i18nKey.failedToLoadSurvey), 
                        requestComplete: true 
                    })
                })
        } else {
            this.setState({ 
                surveyPackage: { survey: this.surveyFactory.create(this.props.clientId), previewCoverImage: null },
                requestComplete: true
            })
        }
    }

    render() {
        if (!this.state.requestComplete) {
            return <Loader />
        }

        return(
            <>
                <PageTitle title={this.state.pageTitle} />
                <Stack
                    id="survey-panel-stack"
                    tokens={{ childrenGap: 20}}
                    styles={{ root: { marginLeft: 50, marginRight: 50}}}
                >
                    { this.state.surveyPackage &&
                        <>
                            <Stack id="survey-command-bar" horizontal>
                                <SurveyCommandBar 
                                    surveyPackage={this.state.surveyPackage} 
                                    didUpdateSurvey={this.didUpdateSurvey} 
                                    interactionFocused={this.interactionFocused}
                                />
                            </Stack>
                            <Stack id="survey-panel-content" tokens={{ childrenGap: 50}} horizontal>
                                <StackItem styles={{ root: { width: '20%' }}}>
                                    <SurveyInteractionPanel 
                                        interactionClicked={this.interactionClicked}
                                    />
                                </StackItem>
                                <StackItem styles={{ root: { width: '50%' }}}>
                                    <SurveyLayoutPanel 
                                        survey={this.state.surveyPackage.survey} 
                                        interactionFocused={this.interactionFocused} 
                                        focusedInteractionId={this.state.focusedInteractionId}
                                    />
                                </StackItem>
                                <StackItem styles={{ root: { width: '30%' }}}>
                                    <SurveyPropertiesPanel 
                                        surveyPackage={this.state.surveyPackage} 
                                        didUpdateSurvey={this.didUpdateSurvey} 
                                        didUpdatePreviewImage={this.didUpdatePreviewImage}
                                        focusedInteractionId={this.state.focusedInteractionId}
                                        interactionFocused={this.interactionFocused}
                                    />
                                </StackItem>
                            </Stack>
                        </>
                    }

                    { this.state.errorMessage &&
                        <MessageBar
                            id="survey-error-banner"
                            messageBarType={MessageBarType.error}
                            dismissButtonAriaLabel="Close">
                                {this.state.errorMessage}
                        </MessageBar>
                    }
                </Stack>
            </>
        )
    }

    /**
     * Public Functions
     */

    interactionClicked = (interactionType: InteractionType) => {
        if (!this.state.surveyPackage) {
            return 
        }

        let interactionData: SurveyInteractionData | null = null

        switch(interactionType) {
            case InteractionType.longText:
                interactionData = this.surveyFactory.addLongTextInteraction(this.state.surveyPackage.survey)
                break
            case InteractionType.shortText:
                interactionData = this.surveyFactory.addShortTextInteraction(this.state.surveyPackage.survey)
                break
            case InteractionType.radioButtons:
                interactionData = this.surveyFactory.addRadioButtonsInteraction(this.state.surveyPackage.survey)
                break
            case InteractionType.checkbox:
                interactionData = this.surveyFactory.addCheckboxInteraction(this.state.surveyPackage.survey)
                break
            case InteractionType.rating:
                interactionData = this.surveyFactory.addRatingInteraction(this.state.surveyPackage.survey)
                break
            case InteractionType.matrix:
                interactionData = this.surveyFactory.addMatrixInteraction(this.state.surveyPackage.survey)
                break
            case InteractionType.ranking:
                interactionData = this.surveyFactory.addRankingInteraction(this.state.surveyPackage.survey)
                break
            case InteractionType.dropDown:
                interactionData = this.surveyFactory.addDropDownInteraction(this.state.surveyPackage.survey)
                break
            default:
                break
        }

        if (interactionData !== null) {
            this.setState({ 
                surveyPackage: this.updateSurveyPackageWithSurvey(interactionData.survey), 
                focusedInteractionId: interactionData.interactionId
            })
        }
    }
      
    interactionFocused = (id: string) => {
       this.setState({ 
           focusedInteractionId: id
        })
    }

    /**
     * Private Functions
     */

    private didUpdateSurvey = (survey: Survey) => {
        this.setState({ surveyPackage: this.updateSurveyPackageWithSurvey(survey) })
    }

    private didUpdatePreviewImage = (imageFile: File) => {
        this.setState({ surveyPackage: this.updateSurveyPackageWithPreviewImage(imageFile)})
    }

    /**
     * Helper function to recreate a survey package with an updated survey object
     * @param survey The new survey
     * @returns A survey package
     */
    private updateSurveyPackageWithSurvey(survey: Survey): SurveyPackage | null {
        if (!this.state.surveyPackage) {
            return null
        }

        return {
            survey: survey,
            previewCoverImage: this.state.surveyPackage.previewCoverImage
        }
    }
    
    /**
     * Helper function to recreate a survey package with an updated survey cover image
     * @param survey The new survey
     * @returns A survey package
     */
    private updateSurveyPackageWithPreviewImage(imageFile: File): SurveyPackage | null {
        if (!this.state.surveyPackage) {
            return null
        }

        return {
            survey: this.state.surveyPackage.survey,
            previewCoverImage: imageFile
        }
    }
}
