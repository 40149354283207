import { Component, MouseEvent } from "react"
import {
	Stack,
	IStackTokens,
} from "@fluentui/react"
import { TabPanelProps } from "../TabPanelProps"
import DTSTableData from "../../../models/DTSTableData"
import { ILearningPlanService } from "../../../services/LearningPlanService"
import { container } from "../../../DIContainer"
import { IDataTableFactory } from "../../../factories/DataTableFactory"
import { TYPES } from "../../../Types"
import { log } from "../../../global/Logger"
import { MessageBar, MessageBarType, Spinner } from "@fluentui/react"
import DTSTable from "../../../ui-kit/DTSTable"
import { Loader } from "../../../ui-kit/Loader"
import { i18nKey, Ii18n } from "../../../global/i18n"
import { DestructiveButton } from "../../../ui-kit/DestructiveButton"

interface RemoveCohortFromLearningPlanProps extends TabPanelProps {
	documentId: string
}

interface RemoveCohortFromLearningPlanState {
	loadingData: boolean
	tableData: DTSTableData | null
	primaryButtonDisabled: boolean
	showSpinner: boolean
	errorMessage: string | null
	successMessage: string | null
    selectedGroups: string[]
    clearSelectedRows: boolean
}

const stackTokens: IStackTokens = {
	childrenGap: "20px",
}

const stackStyling = {
	root: {
		padding: "30px 10px"
	}
}

export class RemoveCohortFromLearningPlan extends Component<RemoveCohortFromLearningPlanProps,RemoveCohortFromLearningPlanState> {
	private learningPlanService: ILearningPlanService = container.get<ILearningPlanService>(TYPES.ILearningPlanService)
	private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	state: RemoveCohortFromLearningPlanState = {
		loadingData: false,
		tableData: null,
		primaryButtonDisabled: true,
		showSpinner: false,
		errorMessage: null,
		successMessage: null,
		selectedGroups: [],
		clearSelectedRows: false
	}

	componentDidMount() {
		this.loadData()
	}
	
	render() {
		if (this.state.loadingData) {
			return <Loader />
		}
		
		return (
			<Stack id="remove-cohort-from-learning-plan-container" 
				tokens={stackTokens} 
				styles={stackStyling}>
				{ this.state.tableData && (
					<div id="remove-cohort-from-lp-table">
						<Stack tokens={stackTokens}>
							<DTSTable
								data={this.state.tableData}
								pagination={true}
								onSelectedRowsChange={this.onSelectedRowsChange}
								clearSelectedRows={this.state.clearSelectedRows}
							/>
							
							{ !this.state.showSpinner &&
								<DestructiveButton
									id="remove-cohort-primary-button"
									text={this.i18n.get(i18nKey.removeGroups)}
									onClick={this.primaryButtonClicked}
									disabled={this.state.primaryButtonDisabled}
									hidden={this.state.showSpinner}
								/>
							}

							{ this.state.showSpinner && 
								<Spinner
									id="remove-cohort-spinner"
									label="Removing"
									ariaLive="assertive"
									labelPosition="right"
								/>
							}
						</Stack>
					</div>
				)}
				
				{this.showBannerIfNeeded()}            
			</Stack>
		)
	}
	
	private loadData = () => {
		this.setState({ loadingData: true })

		this.learningPlanService
			.getGroupsForLearningPlan(this.props.documentId)
			.then((groups) => {
				const data = this.dataTableFactory.createCohortsTableData(groups)
				this.setState({ 
					loadingData: false, 
					tableData: data, 
					clearSelectedRows: false 
				})
			})
			.catch(error => {
				this.setState({ loadingData: false, errorMessage: error.message })
			})
	}
	
	onSelectedRowsChange = (selectedRowState: {
		allSelected: boolean
		selectedCount: number
		selectedRows: { [key: string]: any }[]
	}) => {
		if (selectedRowState.selectedCount === 0) {
			this.setState({ selectedGroups: [] }, () => {
				this.enablePrimaryButtonIfNeeded()
			})
			return
		}

        const selectedIds = selectedRowState.selectedRows.map((row) => {
			return row.id
		})

		log(selectedIds.length + " groups selected")

		this.setState({ selectedGroups: selectedIds }, () => {
			this.enablePrimaryButtonIfNeeded()
		})
	}

	dismissBanner = () => {
		this.setState({ 
			errorMessage: null, 
			successMessage: null 
		})
	}

	primaryButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: null,
			successMessage: null,
			showSpinner: true,
			primaryButtonDisabled: true,
		})

		this.learningPlanService
			.removeCohortsFromLearningPlan(this.state.selectedGroups, this.props.documentId)
			.then(() => {
				let message: string
                if (this.state.selectedGroups.length === 1) {
                    message = "1 group removed"
                } else {
                    message = this.state.selectedGroups.length + " groups removed"
                }

				this.setState({
					successMessage: message,
					showSpinner: false,
					primaryButtonDisabled: true,
                    selectedGroups: [],
                    clearSelectedRows: true
				})

				this.loadData()
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showSpinner: false,
					primaryButtonDisabled: false,
				})
			})
	}

	enablePrimaryButtonIfNeeded = () => {
		let disable = true

		if (this.state.selectedGroups.length > 0) {
			disable = false
		}

		this.setState({ primaryButtonDisabled: disable })
	}

	private showBannerIfNeeded = () => {
		let banner = null
		
		if (this.state.errorMessage) {
			banner = (
				<MessageBar
					id="remove-cohort-error-banner"
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.errorMessage}
				</MessageBar>
			)
		} else if (this.state.successMessage) {
			banner = (
				<MessageBar
					id="remove-cohort-success-banner"
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			)
		}

		return banner
	}
}