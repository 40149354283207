import React, { Component, ReactElement } from "react"
import { Stack, Image, Text, Icon} from "@fluentui/react"
import { ImageFit, ProgressIndicator } from "@fluentui/react"
import { FontSize } from "../../../ui-kit/FontSize"
import { CourseGridItemStyle } from "./CourseGridItemStyle"
import { ILRSService } from "../../../services/LRSService"
import { TYPES } from "../../../Types"
import { container } from "../../../DIContainer"
import { IAuth } from "../../../auth/Auth"
import { DocumentId, Uid } from "../../../global/TypeAliases"
import { Ii18n, i18nKey } from "../../../global/i18n"
import "./LearningPlanContentGridItem.css"
import { log } from "../../../global/Logger"
import { ContentType, IContent } from "../../../models/IContent"
import User from "../../../models/User"
import { LaunchLocations } from "../../../models/TrainingLaunchLocation"
import progression_arrow from "../../../images/content/arrow-right-black.svg"
import { LearningPlanContentGridItemStyles } from "./LearningPlanContentGridItemStyles"

interface LearningPlanContentGridItemProps {
    content: IContent
    userClientIds?: DocumentId[]
    onContentClick: (e: React.MouseEvent<HTMLDivElement>, content: IContent) => void
    showProgressBar: boolean
    showProgressionArrow: boolean
    isClickable: boolean
    isComplete: boolean
}

interface LearningPlanContentGridItemState {
    progress: number
    isClickable: boolean
}

export class LearningPlanContentGridItem extends Component<LearningPlanContentGridItemProps, LearningPlanContentGridItemState> {
    private lrsService: ILRSService = container.get<ILRSService>(TYPES.ILRSService)
    private auth: IAuth = container.get<IAuth>(TYPES.IAuth)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    
    state: LearningPlanContentGridItemState = {
        progress: 0,
        isClickable: this.props.isClickable
    }

    componentDidMount() {
        this.setContentProgressIfNeeded()
    }

    render() {
        return (
            <div 
                className='content-grid-item-container' 
                id={this.props.content.documentId} 
                onClick={ this.state.isClickable ? this.onContentClickHandler : undefined }
            >
            <Stack id="content-grid-item-container-stack" horizontal>
                    <div id='content-grid-item-progression-arrow-container'>
                        { this.props.showProgressionArrow &&
                            <Image
                                className="show-progression-arrow"
                                src={progression_arrow}
                                alt={"Content Progression Arrow"}
                                styles={this.state.isClickable ? 
                                    LearningPlanContentGridItemStyles.showGreenProgressionArrowImageStyle : LearningPlanContentGridItemStyles.showRedProgressionArrowImageStyle
                                }
                            />
                        }
                        { !this.props.showProgressionArrow &&
                            <Image
                                className="no-show-progression-arrow"
                                src={progression_arrow}
                                alt={"Proceed to first content in plan"}
                                styles={LearningPlanContentGridItemStyles.noShowProgressionArrowImageStyle}
                            />
                        }
                    </div>
                <Stack id="content-grid-item-card-stack-container" styles={this.state.isClickable ? CourseGridItemStyle.containerStyle : CourseGridItemStyle.greyedOutContainerStyle}>
                    <Stack styles={CourseGridItemStyle.headerStyle}>
                        <Image 
                            id="content-grid-item-image"
                            src={this.props.content.imageUrl}
                            alt={this.props.content.name + " content"} 
                            imageFit={ImageFit.centerCover} 
                            styles={CourseGridItemStyle.imageStyle}
                        />
                    </Stack>
                    <Stack tokens={CourseGridItemStyle.stackTokens} styles={CourseGridItemStyle.bodyStyle}>
                        <Text id="content-grid-item-name" variant={FontSize.large}>{this.props.content.name}</Text>
                    </Stack>
                    {this.renderProgressBar()}
                    <Stack horizontal={true} tokens={CourseGridItemStyle.stackTokens} styles={CourseGridItemStyle.footerStyle}>
                        <Text id="content-grid-item-duration" variant={FontSize.medium}>{this.props.content.duration}</Text>
                    </Stack>
                </Stack>
            </Stack>
            </div>
        )
    }

    onContentClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const user = this.auth.user
        if (!user) {
            return
        }

        const sharedClientIds = this.mapCourseClientsToUserClients()
        sharedClientIds.forEach(client => {
            this.lrsService
                .postTrainingLaunchLocation(
                    user.uid, 
                    client, 
                    this.props.content.lrsId, 
                    LaunchLocations.LearningPlan
                )
        })

        this.props.onContentClick(e, this.props.content)
    }

    /**
     * Private Functions
     */

    private renderProgressBar = (): ReactElement | null => {
        if (!this.props.showProgressBar) {
            return null
        }

        let progressBar: ReactElement

        if (this.state.progress === 100) {
            progressBar = <Stack id="content-grid-item-completed-progress" tokens={CourseGridItemStyle.stackTokens} styles={CourseGridItemStyle.bodyStyle}>
                                <ProgressIndicator percentComplete={this.state.progress/100} barHeight={4}/>
                                <Stack styles={CourseGridItemStyle.bodyStyle} horizontal={true}>
                                        <Icon id="completedIcon" iconName="Completed" styles={CourseGridItemStyle.completedIcon} />
                                        <div id="completedLabel" style={CourseGridItemStyle.completedDiv}>
                                            {this.i18n.get(i18nKey.completed)}
                                        </div>
                                </Stack>
                            </Stack>
        } else {
            progressBar = <Stack id="content-grid-item-progressed-progress" tokens={CourseGridItemStyle.stackTokens} styles={CourseGridItemStyle.footerStyle}>
                                <ProgressIndicator className="progress-indicator-component-progressed" description={this.state.progress + this.i18n.get(i18nKey.percentComplete)} percentComplete={this.state.progress/100} barHeight={4}/>
                            </Stack>
        }

        return progressBar
    }

    private setContentProgressIfNeeded() {
        if (!this.props.showProgressBar) {
            return
        }

        const user = this.auth.user
        if (!user) {
            return
        }

        if (this.props.isComplete) {
            this.setState({ progress: 100 })
            return
        }

        const clientIds = this.mapCourseClientsToUserClients()
        clientIds.forEach(clientId => {
            switch (this.props.content.contentType()) {
                case ContentType.course:
                    this.setCourseProgress(user.uid, clientId)
                    break
                case ContentType.survey:
                    this.setSurveyProgress(user, clientId)
                    break
            }
        })
    }

    private setCourseProgress(uid: Uid, clientId: DocumentId) {
        this.lrsService
            .getProgressionOnCourseForUser(uid, clientId, this.props.content.lrsId)
            .then(statements => {
                /**
                 * The first element in the array always guarantees us the highest progress
                 */
                if (statements.length > 0 
                    && statements[0].progress
                    && statements[0].progress > this.state.progress) {
                        this.setState({ 
                            progress: statements[0].progress
                        })
                    }
            }).catch(error => {
                log("Failed to get course passed statements - " + error)
            })
    }

    private setSurveyProgress(user: User, clientId: DocumentId) {        
        this.lrsService
            .getSurveyProgress(user.uid, clientId, this.props.content.lrsId)
            .then(progress => {
                this.setState({ progress: progress })
            }).catch(error => {
                log("Failed to get survey passed statements - " + error)
            })
    }

    private mapCourseClientsToUserClients(): DocumentId[] {
        if (!this.props.userClientIds) {
            return []
        }

        const contentsClientIds = Object.keys(this.props.content.clients)
		let sharedClientIds: DocumentId[] = []

        this.props.userClientIds.forEach(usersClientId => {
            if (contentsClientIds.includes(usersClientId)) {
                sharedClientIds.push(usersClientId)
            }
        })

		return sharedClientIds
	}
}