import { Dictionary } from './../global/TypeAliases';
import { injectable } from "inversify";

export interface IDeadlineService {
    deadlines(): Promise<Dictionary>
}

@injectable()
export class DeadlineService implements IDeadlineService {
    deadlines(): Promise<Dictionary> {
        // mock deadline data for now
        return Promise.resolve(
            {"lG9XWEt1Qx4lh9sqQusl": "2021-11-24T00:00:00", // RSD - CRP
            "uSpObDVHJ0ku4Q9tF2IT": "2021-11-23T00:00:00", // LP Training 2
            "JvVakY5mELHvM7mDiH84": "2021-11-29T00:00:00", // CM Emp Onboarding
            "SVyWSvX8700cAeUWA6Vj": "2021-12-16T00:00:00",  // Survey Test
            "Ik2rg9d6TNfLuqGLqvxj": "2022-03-04T00:00:00",
            "aMUsG2KrVddzyUcReOxF": "2022-03-10T00:00:00",
            "aODi03bYMIo4piRBHDPc": "2021-12-16T00:00:00"
            }
        )
    }
}