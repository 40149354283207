import { Component, MouseEvent } from 'react';
import { container } from '../DIContainer';
import { i18nKey, Ii18n } from '../global/i18n';
import { Dictionary } from '../global/TypeAliases';
import { Survey } from '../models/Survey';
import { TYPES } from '../Types';
import { PrimaryButton } from '../ui-kit/PrimaryButton';
import { CSVFileNameCreator } from '../utils/CSVHelperFunctions';

interface SurveyReportCSVErrorState {
    primaryButtonDisabled: boolean
}

interface SurveyReportCSVExporterProps {
    survey: Survey
    surveyData: Dictionary[]
    fileName: string
}

export class SurveyReportCSVExporter extends Component<SurveyReportCSVExporterProps, SurveyReportCSVErrorState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    state: SurveyReportCSVErrorState = {
      primaryButtonDisabled: true
    }

    componentDidMount() {
      if (this.props.surveyData.length !== 0) {
        this.setState({
          primaryButtonDisabled: false
        })
      }
    }

    render() {
      return(
        <PrimaryButton
          id="survey-report-export-csv-primary-button"
          onClick={this.onClickExportButton}
          text={this.i18n.get(i18nKey.exportCSV)}
          disabled={this.state.primaryButtonDisabled}
        />
      )
    }

    onClickExportButton = (_: MouseEvent<HTMLButtonElement>) => {
      this.downloadCSV(this.props.surveyData)
    }

    private downloadCSV(array: Dictionary[]) {
      const link = document.createElement('a')
      
      
      const filteredResults: Dictionary[] = array.filter((response) => {
        return Object.keys(response.data).length > 0
      })

      let csv = this.convertArrayOfObjectsToCSV(filteredResults)
      if (csv === null) return
      
      const filename = CSVFileNameCreator(this.props.fileName, true)
    
      if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`
      }
    
      link.setAttribute('href', encodeURI(csv))
      link.setAttribute('download', filename)
      link.click()
    }

    private convertArrayOfObjectsToCSV(array: Dictionary[]) {
        let result: string
        
        const columnDelimiter = ','
        const lineDelimiter = '\n'
        const keyNames = this.props.survey.pages[0].elements.map((element: any) => {
            if (element.title) {
                return element.title
            } else {
                return element.name
            }
        })

        const uniqueKeys = this.props.survey.pages[0].elements.map((element: any) => {
            return element.name
        })
        
        
        result = ''
        result += keyNames.join(columnDelimiter)
        result += lineDelimiter
        
        array.forEach(response => {
            let column = 0

            uniqueKeys.forEach(key => {
                const responseValue = response.data[key]
                if (column > 0) {
                    result += columnDelimiter
                }

                if(Array.isArray(responseValue)) {
                    result += responseValue.join(' | ')
                } else if (typeof responseValue === "object") {
                    let responseAsString = JSON.stringify(responseValue)
                    responseAsString = responseAsString.replace('{', '')
                    responseAsString = responseAsString.replace('}', '')
                    responseAsString = responseAsString.replaceAll(',', ' | ')
                    
                    result += responseAsString
                } else {
                    if (responseValue === undefined) {
                        result += "Not Answered"
                    } else {
                        result += responseValue.toString()
                    }
                }
            
                column++
            })

            result += lineDelimiter
        });
        
        return result
    }
}