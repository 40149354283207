import { IStackStyles, ITextStyles } from '@fluentui/react'

import Testimonials_Background from '../images/marketing/testimonials-background.jpg'

export class LandingTestimonialStyles {
    static outerStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    paddingLeft: '6vw',
                    paddingRight: '10vw',
                    paddingTop: '14vw',
                },
            },

            backgroundImage: `url(${Testimonials_Background})`,
            backgroundPosition: 'top',
            padding: '8vw',
            paddingLeft: '16vw',
            paddingRight: '12vw',
        },
    }

    static innerStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    width: '100%',
                },
            },

            marginLeft: '2.5vw !important',
        },
    }

    static cardStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    width: '100%',
                },
            },
        },
    }

    static titleTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    maxWidth: '100%',
                    fontSize: '6vw',
                    marginBottom: '5vw',
                },
            },

            fontFamily: 'Noto Sans JP, sans serif',
            fontWeight: 'bold',
            fontSize: '2.5vw',
            maxWidth: '16vw',
            marginBottom: '2vw',
        },
    }

    static subTitleTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    maxWidth: '100%',
                    fontSize: '4vw',
                    lineHeight: '8vw',
                    marginBottom: '10vw',
                },
            },

            fontFamily: 'Lato, sans serif',
            color: '#7c7c7c',
            fontWeight: 600,
            fontSize: '1vw',
            maxWidth: '16vw',
            marginBottom: '1.8vw',
            lineHeight: '1.5vw',
        },
    }

    static buttonStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    marginLeft: '14vw',
                    marginTop: '8vw',
                    marginBottom: '4vw',
                },
            },
        },
    }
}
