import { 
    ISearchBoxStyles, 
} from '@fluentui/react';
import { Theme } from '../../../ui-kit/Theme';

export class AdminUIStyles {
    static searchBoxStyle: Partial<ISearchBoxStyles> = {
        root: {
            width: "400px",
            height: "50px",
            borderColor: Theme.accent.primary
        }
    }
}