import { injectable } from "inversify";
import { container } from "../DIContainer";
import { DocumentId } from "../global/TypeAliases";
import Client from "../models/Client";
import Cohort from "../models/Cohort";
import User from "../models/User";
import { TYPES } from "../Types";
import { IClientService } from "./ClientService";
import { ICohortService } from "./CohortService";
import { IEventReportingService } from "./EventReportingService";
import { IUserService } from "./UserService";


export interface IUsersAdminCompositionService {
    getData(clientId: DocumentId): Promise<UsersAdminCompositionServiceData>
}

export interface UsersAdminCompositionServiceData {
    users: User[],
    cohorts: Cohort[],
    client: Client
}

@injectable()
export class UsersAdminCompositionService implements IUsersAdminCompositionService {
    private userService: IUserService = container.get<IUserService>(TYPES.IUserService)
    private cohortService: ICohortService = container.get<ICohortService>(TYPES.ICohortService)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)
    private clientService: IClientService = container.get<IClientService>(TYPES.IClientService)

    getData(clientId: DocumentId): Promise<UsersAdminCompositionServiceData> {
        return new Promise((resolve, reject) => {
            const promises: Promise<any>[] = [
                this.userService.usersForClient(clientId),
                this.cohortService.cohortsForClient(clientId),
                this.clientService.clientForId(clientId)
            ]

            return Promise.all(promises)
                .then(result => {
                    resolve({
                        users: result[0],
                        cohorts: result[1],
                        client: result[2]
                    })
                }).catch(error => {
                    this.eventReportingService.error(error.message, error)
                    reject(error)
                })
        })
    }
}