import React, { Component } from "react";
import { Stack } from "@fluentui/react";
import { i18nKey, Ii18n } from "../../global/i18n";
import { container } from "../../DIContainer";
import { TYPES } from "../../Types";
import { SurveyGridItem } from "../admin/surveys/SurveyGridItem";
import { Survey } from "../../models/Survey";
import { CourseGridStyle } from "../dashboard/CourseGridStyle";

interface ReportsSurveyGridProps {
	surveys: Survey[];
	onSurveyClick: (e: React.MouseEvent<HTMLDivElement>) => void
	shouldWrap: boolean
}

export class ReportsSurveyGrid extends Component<ReportsSurveyGridProps> {
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	render() {
		if (this.props.surveys.length === 0) {
			return <div id="reports-survey-grid-no-surveys-displayed">{this.i18n.get(i18nKey.noSurveysLabel)}</div>
		}

		const gridItems = this.props.surveys.map((survey) => {
			return <SurveyGridItem
						key={survey.documentId}
                        survey={survey} 
                        onSurveyClick={this.props.onSurveyClick} 
                    />;
		});
		
		return (
			<div id="reports-survey-grid">
				<Stack 
					horizontal={true}
					wrap={this.props.shouldWrap} 
					tokens={CourseGridStyle.stackTokens}
				>
					{gridItems}
				</Stack>
			</div>
		);
	}
}