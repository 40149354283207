import React, { Component, ReactElement } from "react"
import {
	Modal,
	MessageBarType,
	MessageBar,
} from "@fluentui/react"
import DTSTable from "../../../ui-kit/DTSTable"
import IDataTableFactory from "../../../factories/DataTableFactory"
import DTSTableData from "../../../models/DTSTableData"
import { Loader } from "../../../ui-kit/Loader"
import IClientService from "../../../services/ClientService"
import Client from "../../../models/Client"
import NewClientModal from "./NewClientModal"
import { EditClient } from "./EditClient"
import { SecondaryButton } from "../../../ui-kit/SecondaryButton"
import { TYPES } from "../../../Types"
import { container } from "../../../DIContainer"
import { log } from "../../../global/Logger"
import { i18nKey, Ii18n } from "../../../global/i18n"
import PageTitle from "../../../ui-kit/PageTitle"
import { Stack } from "@fluentui/react"

enum ModalType {
	NEW = 0,
	EDIT,
}

interface ClientsAdminProps {}

interface ClientsAdminState {
	clients: Client[]
	tableData: DTSTableData | undefined
	isModalOpen: boolean
	successMessage: string | undefined
	selectedDocumentId: string | undefined
	modalType: ModalType
}

export default class ClientsAdmin extends Component<ClientsAdminProps, ClientsAdminState> {
	private clientService: IClientService = container.get<IClientService>(TYPES.IClientService)
	private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
	private i18n = container.get<Ii18n>(TYPES.Ii18n)

	state: ClientsAdminState = {
		clients: [],
		tableData: undefined,
		isModalOpen: false,
		successMessage: undefined,
		modalType: ModalType.NEW,
		selectedDocumentId: undefined,
	}

	componentDidMount() {
		this.loadClients()
	}

	render() {
		const pageTitle = this.i18n.get(i18nKey.clientsAdminPageTitle)

		if (this.state.tableData === undefined) {
			return <Loader />
		}

		let data = this.state.tableData

		let modalElement: ReactElement = <NewClientModal clientAdded={this.reloadData} />

		if (this.state.isModalOpen) {
			switch (this.state.modalType) {
				case ModalType.NEW:
					modalElement = <NewClientModal clientAdded={this.reloadData} />
					break
				case ModalType.EDIT:
					const client = this.state.clients.find(
						(client) => client.documentId === this.state.selectedDocumentId
					)
					if (client !== undefined) {
						modalElement = (
							<EditClient
								documentId={this.state.selectedDocumentId}
								client={client}
								clientUpdated={this.loadClients}
							/>
						)
					}

					break
			}
		}

		return (
			<div
				id={`clients-admin-page-container`}
			>
				<PageTitle title={pageTitle} />

				{this.state.successMessage !== undefined && (
					<MessageBar
						messageBarType={MessageBarType.success}
						dismissButtonAriaLabel="Close"
						onDismiss={this.dismissSuccessBanner}
					>
						{this.state.successMessage}
					</MessageBar>
				)}

				<Stack styles={{ root: { paddingLeft: 50, paddingRight: 50, marginTop: 20 }}}>
					<div className="admin-command-bar">
						<SecondaryButton
							iconProps={{ iconName: "Education" }}
							text="Add New Client"
							onClick={this.addClientButtonClicked}
						/>
					</div>
					
					<DTSTable 
						data={data} 
						aria-label="client table" 
						onRowClicked={this.onRowClicked} 
						pointerOnHover={true}
						highlightOnHover={true}
						pagination={true}
						filter={true}
						filterPlaceholder={this.i18n.get(i18nKey.searchForClient)}
					/>
				</Stack>

				<Modal
					titleAriaId="add new client modal"
					isOpen={this.state.isModalOpen}
					onDismiss={this.modalDismissed}
					isBlocking={false}
				>
					{modalElement}
				</Modal>
			</div>
		)
	}

	private loadClients = () => {
		log("Loading clients")

		this.clientService
			.clients()
			.then((clients) => {
				let tableData = this.dataTableFactory.createClientsTableData(clients)
				this.setState({ clients: clients, tableData: tableData })
			})
			.catch(error => {
				// TODO: Show error
			})
	}

	private addClientButtonClicked = () => {
		this.setState({ isModalOpen: true })
	}

	private onRowClicked = (row: {[key: string]: string}) => {
		this.setState(
			{ 
				selectedDocumentId: row.id, 
				isModalOpen: true, 
				modalType: ModalType.EDIT 
			}
		)
	}

	private reloadData = (successMessage?: string) => {
		this.modalDismissed()
		this.loadClients()

		if (successMessage) {
			this.setState({ successMessage: successMessage })
		}
	}

	private modalDismissed = () => {
		this.setState({ isModalOpen: false, selectedDocumentId: undefined })
	}

	private dismissSuccessBanner = () => {
		this.setState({ successMessage: undefined })
	}
}
