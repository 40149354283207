import React, { Component } from "react";
import { Modal, UncontrolledAlert } from "reactstrap";
import { container } from "../../DIContainer";
import { TYPES } from "../../Types";
import { FileUploaderPath, IFileUploader } from "../../services/FileUploader";
import { IUserRepository } from "../../services/UserRepository";
import { IURLCreator } from "../../ui-kit/URLCreator";
import { ProfileImageV2 } from "../../ui-kit/ProfileImageV2";
import { Ii18n, i18nKey } from "../../global/i18n";

interface ProfileUploadPicV2Props {
    profileImageURLString: string | null;
    fbUser: firebase.User | null;
    isOpen: boolean;
    onClose: () => void;
    onProfileUpdate: (user: firebase.User) => void;
}

interface ProfileUploadPicV2State {
    imageFile: File | undefined
    imagePreview: string
    errorMessage: string | undefined
    spinnerActive: boolean
}

export default class ProfileUploadPicV2 extends Component<ProfileUploadPicV2Props, ProfileUploadPicV2State> {
    private fileUploader: IFileUploader = container.get<IFileUploader>(TYPES.IFileUploader);
    private userRepository: IUserRepository = container.get<IUserRepository>(TYPES.IUserRepository);
    private urlCreator: IURLCreator = container.get<IURLCreator>(TYPES.IURLCreator);
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n);

    state = {
        imageFile: undefined,
        imagePreview: "",
        errorMessage: undefined,
        spinnerActive: false
    }

    componentDidMount(): void {
        if (this.props.profileImageURLString !== null) {
            this.setState({
                imagePreview: this.props.profileImageURLString
            })
        }

        if (this.state.imageFile !== undefined) {
            this.setState({ 
                imagePreview: this.urlCreator.createFor(this.state.imageFile!)
            })
        }
    }


    primaryButtonClicked = () => {
        this.setState({
            spinnerActive: true,
            errorMessage: undefined
        })

        if (this.props.fbUser && this.state.imageFile !== undefined) {
            this.fileUploader
                .upload(this.state.imageFile, FileUploaderPath.profileImage)
                .then((imageUrl) => {
                    if (this.props.fbUser) {
                        return this.userRepository.updateProfilePicture(this.props.fbUser, imageUrl);
                    }
                })
                .then((user) => {
                    this.setState({
                        spinnerActive: false
                    })
                    if (user) {
                        this.props.onProfileUpdate(user);
                    }
                })
                .catch((_) => {
                    this.setState({
                        errorMessage: this.i18n.get(i18nKey.profileFailedToUpdate),
                        spinnerActive: false
                    })
    
                });
        }
    };

    fileSelectionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (!event.target.files || event.target.files.length === 0) {
            return;
        }

        const file: File = event.target.files[0];
      
        this.setState({
            imageFile: file,
            imagePreview: this.urlCreator.createFor(file)
      
        })
    };

    render() {
        return (
            <>
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.props.onClose} centered={true} data-testid="upload-img-modal">
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Upload a profile image</h5>
                        <button
                            type="button"
                            onClick={this.props.onClose}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.state.errorMessage && (
                            <UncontrolledAlert color="danger" data-testid="error-banner">
                                {this.state.errorMessage}
                            </UncontrolledAlert>
                        )}
                        <div className="d-flex vstack align-items-center mb-3">
                            <ProfileImageV2
                                className="rounded-circle profile-photo-img mb-3"
                                source={this.state.imagePreview}
                                height={150}
                            />
                            <label
                                htmlFor="file-upload"
                                className="inputFile btn btn-primary profile-button"
                                data-testid="select-photo-btn"
                            >
                                {this.i18n.get(i18nKey.selectImage)}
                            </label>
                            <input
                                id="file-upload"
                                data-testid="file-upload"
                                type="file"
                                accept="image/*"
                                onChange={this.fileSelectionChanged}
                                width="300px"
                            />
                            {!this.state.spinnerActive && (
                                <button
                                    className="btn btn-secondary profile-button"
                                    data-testid="upload-img-btn"
                                    onClick={this.primaryButtonClicked}
                                >
                                    {this.i18n.get(i18nKey.uploadImage)}
                                </button>
                            )}
                            {this.state.spinnerActive && (
                                <>
                                    <button
                                        type="button"
                                        className="btn btn-light waves-effect"
                                        data-testid="spinner-button"
                                    >
                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                                        {this.i18n.get(i18nKey.uploading)}
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </Modal>
            </div>
        </>
        )
    }
}