import { injectable } from 'inversify';
import { Dictionary } from '../global/TypeAliases';
import { IContent } from '../models/IContent'

export interface ILearningPlanHelper {
    /**
     * Returns a map of which content ids should be clickable based on progression within
     * a learning plan.
     * @param contents The contents
     * @param completedIdsMap The map of completed document ids e.g. { 'doc1': true }
     */
    getClickableState(contents: IContent[], completedIdsMap: Dictionary): Dictionary
}

@injectable()
export class LearningPlanHelper implements ILearningPlanHelper {
    getClickableState(contents: IContent[], completedIdsMap: Dictionary): Dictionary {
        const contentIds = contents.map(content => content.documentId)
        let data: Dictionary = {}
        let foundFirstIncomplete = false

        contentIds.forEach((id, index) => {
            /**
             * Always make the first content clickable
             */
            if (index === 0) {
                data[id] = true
                return
            }
        
            /**
             * If the content is completed always make it clickable
             */
            const isComplete = completedIdsMap[id]
            if (isComplete) {
                data[id] = true
                return
            }

            /**
             * Make unclickable if we've found the first 
             * incomplete content
             */
            if (foundFirstIncomplete) {
                data[id] = false
                return
            }
        
            /**
             * If the previous content has been completed then
             * make the current content clickable
             */
            const previousContentId = contentIds[index-1]
            const previousContentIsComplete = completedIdsMap[previousContentId]
            data[id] = previousContentIsComplete

            foundFirstIncomplete = true
        })
        
        return data
    }
}