/* istanbul ignore file */
import { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { Header } from "./ui-kit/Header";

import "./App.css";

// Import scss

import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";

import { RegistrationPage } from "./pages/authentication/RegistrationPage";
import ResetPasswordRequestPage from "./pages/authentication/ResetPasswordRequestPage";
import { ResetPasswordSentPage } from "./pages/authentication/ResetPasswordSentPage";
import LoginPage from "./pages/authentication/LoginPage";
import DashboardPage from "./pages/dashboard/Dashboard";
import Profile from "./pages/profile/Profile";
import { LandingPage } from "./pages/marketing/LandingPage";
import { CourseContainerPage } from "./pages/dashboard/CourseContainerPage";
import { EmailVerificationSentPage } from "./pages/authentication/EmailVerificationSentPage";
import ReportCompletionPage from "./pages/report/ReportCompletionPage";
import { ReportsHomePage } from "./pages/report/ReportsHomePage";
import UserUploadPage from "./pages/admin/users/UserUploadPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import { Footer } from "./ui-kit/Footer";
import { Stack } from "@fluentui/react";
import { ProtectedRoute } from "./ProtectedRoute";
import { CleverRedirect } from "./auth/CleverRedirect";
import { IUserRepository } from "./services/UserRepository";
import { container } from "./DIContainer";
import { TYPES } from "./Types";
import { ContentsPage } from "./pages/admin/ContentsPage";
import { LearnersPage } from "./pages/admin/LearnersPage";
import { TestimonialsPage } from "./pages/marketing/TestimonialsPage";
import { FeaturesPage } from "./pages/marketing/FeaturesPage";
import { ContactUsPage } from "./pages/marketing/ContactUsPage";
import { PathNames } from "./PathNames";
import { Dictionary } from "./global/TypeAliases";
import { AddNewLearningPlan } from "./pages/admin/learning_plan/AddNewLearningPlan";
import { EditLearningPlan } from "./pages/admin/learning_plan/EditLearningPlan";
import { GlobalAdminReportHomePage } from "./pages/global_admin/GlobalAdminReportHomePage";
import { GoogleFormContainerPage } from "./pages/dashboard/GoogleFormContainerPage";
import ClientsAdmin from "./pages/admin/clients/ClientsAdmin";
import EditCohort from "./pages/admin/cohorts/EditCohort";
import { LogInAsPage } from "./pages/admin/logInAs/LogInAsPage";
import ResetPasswordPage from "./pages/authentication/ResetPasswordPage";
import { SurveyPage } from "./pages/admin/surveys/SurveyPage";
import { SurveyContainerPage } from "./pages/dashboard/SurveyContainerPage";
import { SurveyReportPage } from "./pages/report/SurveyReportPage";
import { withClientContext } from "./pages/admin/WithClientContext";
import { LearningPlansPage } from "./pages/admin/learning_plan/LearningPlansPage";
import { GuestDashboard } from "./pages/guests/GuestDashboard";
import { GuestCourseContainerPage } from "./pages/dashboard/GuestCourseContainerPage";
import { ManageInvitationsHomePage } from "./pages/global_admin/ManageInvitationsHomePage";
import HeaderV2  from "./ui-kit/HeaderV2";
import { IFeatureFlagService } from "./services/FeatureFlagService";
import { LoginPageV2 } from "./pages/authentication/LoginPageV2";
import ProfileV2  from "./pages/profile/ProfileV2";
import { ResetPasswordRequestPageV2 } from "./pages/authentication/ResetPasswordRequestPageV2";
import { DashboardPageV2 } from "./pages/dashboard/DashboardV2";
import { LearningPlansPageV2 } from "./pages/admin/learning_plan/LearningPlansPageV2";

export default class App extends Component {
  private userRepository: IUserRepository = container.get<IUserRepository>(TYPES.IUserRepository);
  private featureFlagService: IFeatureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService);

  render() {
    const enablePhxRedesign = this.featureFlagService.phoenixRedesign() === true;

    return (
        <div id="main">
            {enablePhxRedesign && (
                <div id="navigation-bar-container" data-bs-name="light">
                    <HeaderV2
                        firebaseUserObservable={this.userRepository.firebaseUser}
                        userObservable={this.userRepository.user}
                    />
                </div>
            )}
            {!enablePhxRedesign && (
                <Stack verticalFill={true}>
                    <div id="navigation-bar-container">
                        <Header
                            firebaseUserObservable={this.userRepository.firebaseUser}
                            userObservable={this.userRepository.user}
                        />
                        <Footer />
                    </div>
                </Stack>
            )}
            <div id="container">
                <div id="content">
                    <Switch>
                        <Route exact path={PathNames.Home} component={LandingPage} />
                        { enablePhxRedesign && 
                          <ProtectedRoute 
                            path={PathNames.Dashboard}
                            component={DashboardPageV2} 
                            adminRoute={false}
                          />
                        }
                        { !enablePhxRedesign && 
                          <ProtectedRoute 
                            path={PathNames.Dashboard}
                            component={DashboardPage} 
                            adminRoute={false}
                          />
                        }
                        <ProtectedRoute
                            path={PathNames.Contents}
                            component={withClientContext(ContentsPage)}
                            adminRoute={true}
                        />
                        <ProtectedRoute
                            path={PathNames.Learners}
                            component={withClientContext(LearnersPage)}
                            adminRoute={true}
                        />
                        <ProtectedRoute
                            path={PathNames.Profile}
                            render={() => {
                                if (enablePhxRedesign) {
                                    return <ProfileV2 />;
                                }
                                return <Profile />;
                            }}
                            adminRoute={false}
                        />
                        <ProtectedRoute
                            path={PathNames.Reports}
                            component={withClientContext(ReportsHomePage)}
                            adminRoute={true}
                        />
                        <ProtectedRoute
                            path={PathNames.GlobalStats}
                            component={GlobalAdminReportHomePage}
                            adminRoute={true}
                        />
                        <ProtectedRoute
                            path={PathNames.ManageInvitations}
                            component={ManageInvitationsHomePage}
                            adminRoute={true}
                        />
                        <ProtectedRoute path={PathNames.Clients} component={ClientsAdmin} adminRoute={true} />
                        <ProtectedRoute
                            path={PathNames.UploadUsers}
                            component={withClientContext(UserUploadPage)}
                            adminRoute={true}
                        />
                        <ProtectedRoute path={PathNames.LogInAs} component={LogInAsPage} adminRoute={true} />
                        <ProtectedRoute
                            path={PathNames.Survey}
                            render={(props: Dictionary) => (
                                <SurveyPage
                                    surveyDocumentId={props.location.state.surveyId}
                                    clientId={props.location.state.clientId}
                                />
                            )}
                            adminRoute={true}
                        />
                        <ProtectedRoute
                            path={PathNames.Course}
                            render={(props: Dictionary) => (
                                <CourseContainerPage course={props.location.state.course} />
                            )}
                            adminRoute={false}
                        />
                        <ProtectedRoute
                            path={PathNames.ViewSurvey}
                            render={(props: Dictionary) => (
                                <SurveyContainerPage
                                    documentId={props.location.state.documentId}
                                    surveyCompleted={props.location.state.surveyCompleted}
                                    learnerViewMode={props.location.state.learnerViewMode}
                                />
                            )}
                            adminRoute={false}
                        />

                        <ProtectedRoute
                            path={PathNames.GoogleFormTraining}
                            render={(props: { [key: string]: any }) => (
                                <GoogleFormContainerPage course={props.location.state.course} />
                            )}
                            adminRoute={false}
                        />

                        <ProtectedRoute
                            path={PathNames.ReportCompletion}
                            render={(props: { [key: string]: any }) => (
                                <ReportCompletionPage
                                    course={props.location.state.course}
                                    clientId={props.location.state.clientId}
                                />
                            )}
                            adminRoute={true}
                        />

                        <ProtectedRoute
                            path={PathNames.SurveyReport}
                            render={(props: { [key: string]: any }) => (
                                <SurveyReportPage
                                    surveyId={props.location.state.surveyId}
                                    clientId={props.location.state.clientId}
                                />
                            )}
                            adminRoute={true}
                        />
                        
                        <ProtectedRoute
                            path={PathNames.LearningPlans}
                            adminRoute={false}
                            render={(props: Dictionary) => {
                                if (enablePhxRedesign) {
                                    return (
                                        <LearningPlansPageV2
                                            learningPlanId={props.location.state.learningPlanId}
                                            learningPlanName={props.location.state.learningPlanName}
                                            userClientIds={props.location.state.userClientIds}
                                        />
                                    );
                                }
                                return (
                                    <LearningPlansPage
                                        navigationItems={props.location.state.navigationItems}
                                        learningPlanId={props.location.state.learningPlanId}
                                        learningPlanName={props.location.state.learningPlanName}
                                        userClientIds={props.location.state.userClientIds}
                                    />
                                );
                            }}
                        />
                        <ProtectedRoute
                            path={PathNames.AddNewLearningPlan}
                            render={(props: Dictionary) => (
                                <AddNewLearningPlan clientId={props.location.state.clientId} />
                            )}
                            adminRoute={true}
                        />

                        <ProtectedRoute
                            path={PathNames.EditLearningPlan}
                            render={(props: Dictionary) => (
                                <EditLearningPlan
                                    clientId={props.location.state.clientId}
                                    documentId={props.location.state.documentId}
                                    learningPlan={props.location.state.learningPlan}
                                />
                            )}
                            adminRoute={true}
                        />

                        <ProtectedRoute
                            path={PathNames.EditGroupDetails}
                            render={(props: Dictionary) => (
                                <EditCohort
                                    cohort={props.location.state.cohort}
                                    documentId={props.location.state.documentId}
                                />
                            )}
                            adminRoute={true}
                        />

                        <Route
                            path={PathNames.Login}
                            render={(props: Dictionary) => {
                                if (
                                    props.location.state === undefined ||
                                    props.location.state?.reroutedToPageError === undefined ||
                                    props.location.state?.reroutedToPageError === null
                                ) {
                                    if (enablePhxRedesign) {
                                        return <LoginPageV2 />;
                                    }
                                    return <LoginPage />;
                                } else {
                                    if (enablePhxRedesign) {
                                        return (
                                            <LoginPageV2
                                                initialErrorMessage={props.location.state.reroutedToPageError}
                                            />
                                        );
                                    }
                                    return (
                                        <LoginPage initialErrorMessage={props.location.state.reroutedToPageError} />
                                    );
                                }
                            }}
                        />
                        <Route
                            path={PathNames.ResetPasswordRequest}
                            render={() => {
                                if (enablePhxRedesign) {
                                    return <ResetPasswordRequestPageV2 />;
                                }
                                return <ResetPasswordRequestPage />;
                            }}
                        />
                        <Route
                            path={PathNames.PasswordResetSent}
                            render={(props: Dictionary) => (
                                <ResetPasswordSentPage email={props.location.state.email} />
                            )}
                        />
                        <Route path={PathNames.PrivacyPolicy} component={PrivacyPolicyPage} />
                        <Route path={PathNames.Clever} component={CleverRedirect} />
                        <Route path={PathNames.Registration} component={RegistrationPage} />
                        <Route
                            path={PathNames.EmailVerify}
                            render={(props: { [key: string]: any }) => (
                                <EmailVerificationSentPage email={props.location.state.email} />
                            )}
                        />
                        <Route path={PathNames.Testimonials} component={TestimonialsPage} />
                        <Route path={PathNames.Features} component={FeaturesPage} />
                        <Route path={PathNames.ContactUs} component={ContactUsPage} />
                        <Route path={PathNames.ResetPassword} component={ResetPasswordPage} />
                        <Route path={PathNames.GuestDashboard} component={GuestDashboard} />
                        <Route
                            path={PathNames.GuestCourse}
                            render={(props: Dictionary) => {
                                if (
                                    props.location.state === undefined ||
                                    props.location.state.course === undefined
                                ) {
                                    return <GuestDashboard />;
                                } else {
                                    return (
                                        <GuestCourseContainerPage
                                            course={props.location.state.course}
                                            guestId={props.location.state.entity}
                                        />
                                    );
                                }
                            }}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    );
  }
}