import { IImageStyles, IStackStyles, mergeStyles } from '@fluentui/react'

export class MarketingHeaderStyles {
    static outerStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    justifyContent: 'space-between',
                },
                button: {
                    marginBottom: '1vw',
                },
            },

            paddingTop: '1vw',
            paddingLeft: '6vw',
        },
    }

    static navigationLinkStackStyles: IStackStyles = {
        root: {
            selectors: {
                '& a': {
                    textDecoration: 'none',
                    margin: '2vw',
                    color: '#DEDDDD',
                    fontWeight: 'bold',
                    fontSize: '1vw',
                    marginRight: '5vw',
                },
                'a:hover': {
                    color: '#FFFFFF',
                },
            },
        },
    }

    static mobileMenuStackStyles: IStackStyles = {
        root: {
            width: '100%',
        },
    }

    static mobileLinkStackStyles: IStackStyles = {
        root: {
            selectors: {
                '& a': {
                    textDecoration: 'none',
                    color: '#818181',
                    fontWeight: '400',
                    textAlign: 'right',
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    lineHeight: '15vw',
                    fontSize: '6vw',
                    paddingRight: '4vw',
                    marginTop: '18vw',
                },
            },

            display: 'none',
        },
    }

    static activeLinkClass = mergeStyles({
        color: '#FFFFFF !important',
        borderBottom: '0.3vw solid #FFFFFF',
        paddingBottom: '0.2vw',
    })

    static activeMobileLinkClass = mergeStyles({
        color: '#000000 !important',
    })

    static scrollClass = mergeStyles({
        opacity: '1.00',
        backgroundColor: '#000000',
        marginTop: '-0.8vw',
    })

    static openMobileNavigationLinksClass = mergeStyles({
        display: 'inline',
        marginTop: '6vw',
    })

    static openMobileMenuClass = mergeStyles({
        opacity: '0.98',
        backgroundColor: '#FFFFFF',
    })

    static logoStyles: Partial<IImageStyles> = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    width: '60vw',
                    marginTop: '7vw',
                    marginBottom: '7vw',
                },
            },

            width: '25vw',
        },
    }

    static mobileIconStyles: Partial<IImageStyles> = {
        root: {
            display: 'block',
            position: 'absolute',
            top: '8.5vw',
            right: '4vw',
        },
    }
}
