import { IStackStyles, ITextStyles} from '@fluentui/react'

export class LandingFeaturesStyles {

    static outerStackStyles: IStackStyles = {
        root: {
            selectors: {
                "@media(max-width: 600px)": {
                    paddingLeft: "6vw",
                    paddingRight: "6vw",
                    paddingTop: "10vw",
                    paddingBottom: "10vw"
                }
            },
            
            paddingLeft: "4vw",
            paddingRight: "4vw",
            paddingBottom: "8vw",
            textAlign: "center"
        }
    }

    static innerStackStyles: IStackStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    width: "100%",
                    alignItems: "center",
                    padding: 0,
                    paddingBottom: "10vw",
                }
            },

            paddingLeft: "1vw",
            paddingRight: "1vw"
        }
    }

    static headerTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: "9vw",
                    paddingBottom: "6vw"
                }
            },

            color: "#000000",
            fontFamily:"Noto Sans JP, sans-serif",
            fontWeight: 900,
            fontSize: "3.2vw"
        }
    }

    static sectionNameTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: "4vw",
                    paddingBottom: "5vw"
                }
            },

            fontFamily:"Noto Sans JP, sans-serif",
            color: "#0F72AC",
            fontWeight: 700,
            fontSize: "1vw",
            paddingBottom: "1vw"
        }
    }

    static sectionSubHeaderTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: "7vw",
                    width: "75%"
                }
            },

            color: "#000000",
            fontFamily:"Noto Sans JP, sans-serif",
            fontWeight: 700,
            fontSize: "2.6vw",
            width: "25vw"
        }
    }

    static sectionInfoTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: "5.2vw",
                    width: "100%",
                    lineHeight: "10vw"
                }
            },

            color: "#7C7C7C",
            fontSize: "1vw",
            fontWeight: 600,
            fontFamily:"Lato, sans-serif",
            padding: "2vw",
            width: "22vw",
            lineHeight: "2vw",
        }
    }
}