import { Component } from "react";
import './iFrameContainerPage.css'
import { IAuth } from "../../auth/Auth";
import { TYPES } from "../../Types";
import { container } from "../../DIContainer";
import { Loader } from "../../ui-kit/Loader";
import { IRouter } from "../../Router";
import { Survey } from "../../models/Survey";
import { Survey as SurveyRenderer, SurveyModel } from 'survey-react'
import { Dictionary, DocumentId, Uid } from "../../global/TypeAliases";
import { i18nKey, Ii18n } from "../../global/i18n";
import { Link, MessageBar, MessageBarType, Stack, Text } from "@fluentui/react";
import { FontSize } from "../../ui-kit/FontSize";
import { ILRSService } from "../../services/LRSService";
import { log } from "../../global/Logger";
import { ISurveyCompositionService } from "../../services/SurveyCompositionService";
import { ISurveyResumer } from "../admin/surveys/SurveyResumer";
import { PrimaryButton } from "../../ui-kit/PrimaryButton";
import { SecondaryButton } from "../../ui-kit/SecondaryButton";
import { SurveyContainerPageStyles as Styles } from "./SurveyContainerPageStyles";
import { ISurveyHelper } from "../admin/surveys/SurveyHelper";
import { GeneralDialog } from "../../ui-kit/GeneralDialog";
import './SurveyContainerPage.css'

interface SurveyContainerPageProps {
    documentId: DocumentId
    surveyCompleted: boolean
    learnerViewMode: boolean
}

export interface SurveyContainerPageState {
    requestDidComplete: boolean
    survey: Survey | null
    answers: Dictionary
    errorMessage: string | null
    showSavedDialog: boolean
    showCompletedDialog: boolean
}

interface RequiredIds {
    uid: Uid
    clientId: DocumentId
    lrsId: string
}

enum SurveyMode {
    Edit = "edit",
    ReadOnly = "display"
}

export class SurveyContainerPage extends Component<SurveyContainerPageProps, SurveyContainerPageState> {
    private auth: IAuth = container.get<IAuth>(TYPES.IAuth)
    private router: IRouter = container.get<IRouter>(TYPES.IRouter)
    private surveyCompositionService: ISurveyCompositionService = container.get<ISurveyCompositionService>(TYPES.ISurveyCompositionService)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private lrsService: ILRSService = container.get<ILRSService>(TYPES.ILRSService)
    private surveyResumer: ISurveyResumer = container.get<ISurveyResumer>(TYPES.ISurveyResumer)
    private surveyHelper: ISurveyHelper = container.get<ISurveyHelper>(TYPES.ISurveyHelper)

    state: SurveyContainerPageState = {
        requestDidComplete: false,
        survey: null,
        answers: {},
        errorMessage: null,
        showSavedDialog: false,
        showCompletedDialog: false
    }

    componentDidMount() {
        // documentElement is the <html> element
        document.documentElement.classList.add('auto-overflow')
        document.getElementById('container')?.classList.add('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.add('hidden')
        document.getElementById('navigation-bar')?.classList.add('hidden')

        const uid = this.auth.uid()

        if (!uid) {
            this.router.goToLogin()
            return
        }
        
        this.loadSurveyWith(this.props.documentId)
    }

    componentWillUnmount() {
        document.documentElement.classList.remove('auto-overflow')
        document.getElementById('container')?.classList.remove('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.remove('hidden')
        document.getElementById('navigation-bar')?.classList.remove('hidden')
    }

    render() {
        if (!this.state.requestDidComplete) {
            return <Loader text={this.i18n.get(i18nKey.loadingSurveyPleaseWait)} />
        }

        return (
            <>
                <Stack tokens={Styles.stackTokens} styles={Styles.surveyStackStyling}>
                    <Link id="exit-survey-link" className="basic-link" onClick={this.router.goBack}>{this.i18n.get(i18nKey.exitSurvey)}</Link>
                    
                    { this.state.errorMessage && 
                        <MessageBar
                            id="error-banner"
                            messageBarType={MessageBarType.error}
                            dismissButtonAriaLabel="Close"
                            onDismiss={this.dismissBanner}
                        >
                            {this.state.errorMessage}
                        </MessageBar>
                    }
                    
                    { this.state.survey && this.state.survey.pages.length > 0 &&
                        <Stack horizontalAlign="center">
                            <Text id="survey-title" variant={FontSize.xLarge} styles={Styles.surveyTitleStyling}>{this.title()}</Text>
                            <Text variant={FontSize.medium}>{this.state.survey.description}</Text>
                            <SurveyRenderer 
                                id="survey-renderer"
                                json={this.state.survey.pages[0].toJSON()} 
                                mode={this.props.surveyCompleted ? SurveyMode.ReadOnly : SurveyMode.Edit}
                                showNavigationButtons={false}
                                onAfterRenderSurvey={this.onAfterRenderSurvey}
                                onValueChanged={this.onValueChanged}
                            />

                            { (!this.props.learnerViewMode && !this.props.surveyCompleted) &&
                                <>
                                    <Stack 
                                        id="action-button-stack" 
                                        tokens={Styles.stackTokens} 
                                        styles={Styles.actionButtonStackStyling} 
                                        horizontal
                                    >
                                        <SecondaryButton
                                            id="save-button"
                                            text={this.i18n.get(i18nKey.save)}
                                            onClick={this.saveButtonTapped}
                                        />
                                        <PrimaryButton
                                            id="complete-button"
                                            text={this.i18n.get(i18nKey.complete)}
                                            onClick={this.completeButtonTapped}
                                        />
                                    </Stack>
                                    <Text id="save-note" variant={FontSize.small} styles={Styles.noteStyling}>
                                        {this.i18n.get(i18nKey.saveNote)}
                                    </Text>
                                </>
                            }
                        </Stack>
                    }
                </Stack>

                { this.state.showSavedDialog &&
                    <GeneralDialog
                        className="save-dialog"
                        title={this.i18n.get(i18nKey.saved)}
                        message={this.i18n.get(i18nKey.savingSurveySuccess)}
                        onClick={this.generalDialogOnClick} 
                    />
                }

                { this.state.showCompletedDialog &&
                    <GeneralDialog 
                        className="completed-dialog"
                        title={this.i18n.get(i18nKey.completed)}
                        message={this.i18n.get(i18nKey.completeSurveySuccess)}
                        onClick={this.generalDialogOnClick} 
                    />
                }
            </>
        )
    }

    /**
     * Public Functions
     */

    onValueChanged = (_: any, data: Dictionary) => {
        if (!this.state.survey) { return }

        let answers = this.state.answers
        answers[data.name] = data.value

        this.setState({ answers: answers })
    }

    dismissBanner = () => {
	    this.setState({ errorMessage: null });
	}

    generalDialogOnClick = () => {
        this.setState({ showSavedDialog: false, showCompletedDialog: false }, () => {
            this.router.goToDashboard()
        })
    }

    /**
     * Private Functions
     */

    private completeButtonTapped = () => {
        const ids = this.requiredIds()

        if (!ids) {
            return
        }

        this.surveyCompositionService
            .completeSurvey(ids.uid, ids.clientId, ids.lrsId, this.state.answers)
            .then(() => {
                this.setState({ showCompletedDialog: true })
            }).catch(_ => {
                this.setState({ errorMessage: this.i18n.get(i18nKey.completeSurveyFailed)})
            })
    }

    private saveButtonTapped = () => {
        if (!this.state.survey) { return }

        const ids = this.requiredIds()
        if (!ids) { return }

        const progress = this.surveyHelper.getProgress(this.state.survey, this.state.answers)

        this.surveyCompositionService
            .progressSurvey(ids.uid, ids.clientId, ids.lrsId, this.state.answers, progress)
            .then(_ => {
                this.setState({ showSavedDialog: true })
            }).catch(_ => {
                this.setState({ errorMessage: this.i18n.get(i18nKey.savingSurveyFailed)})
            })
    }

    /**
     * Get the survey object
     * @param documentId The document id
     */
    private loadSurveyWith = (documentId: DocumentId) => {
        const uid = this.auth.uid()
        if (uid === undefined) {
            this.setState({ 
                errorMessage: this.i18n.get(i18nKey.pleaseLogBackIn),
                requestDidComplete: true 
            })
            return
        }

        this.surveyCompositionService
            .loadSurveyData(uid, documentId)
            .then(data => {
                const restoredSurvey = this.surveyResumer.resume(data.survey, data.stateData)

                this.setState({ 
                    survey: restoredSurvey,
                    answers: data.stateData.data,
                    requestDidComplete: true 
                })
            }).catch(_ => {
                this.setState({ 
                    errorMessage: this.i18n.get(i18nKey.failedToLoadSurvey), 
                    requestDidComplete: true 
                })
            })
    }

    /**
     * Is fired once the survey has been rendered on the page
     * @param surveyModel The survey object that fires the event
     * @param options A root HTML element bound to the survey object
     */
    private onAfterRenderSurvey = (surveyModel: SurveyModel, options: any) => {
        const ids = this.requiredIds()

        if (!ids) {
            return
        }

        if (this.props.surveyCompleted) {
            return
        }

        this.lrsService
            .postSurveyAttempt(ids.uid, ids.clientId, ids.lrsId)
            .then(() => {
                log('Survey Attempted')
            }).catch(_ => {
                log('Survey Attempted Failed To Post')
            })
    }

    /**
     * Helper method to get all the ids required for LRSService
     * @returns The ids or null if one or more are not present
     */
    private requiredIds(): RequiredIds | null {
        if (!this.state.survey) {
            return null
        }

        const uid = this.auth.uid()
        if (uid === undefined) {
            return null
        }

        const clientIds = Object.keys(this.state.survey.clients)
        if (clientIds.length === 0) {
            return null
        }

        return {
            uid: uid,
            clientId: clientIds[0],
            lrsId: this.state.survey.lrsId
        }
    }

    private title = (): string => {     
        if (!this.state.survey) {
            return ""
        }

        let title = this.state.survey.name

        if (this.props.learnerViewMode) {
            title = `${this.state.survey.name} ${this.i18n.get(i18nKey.learnerView)}` 
        }

        return title
    }
}