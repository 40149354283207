import React, { useEffect, useState } from "react"
import { Stack } from "@fluentui/react"
import { Survey } from "../../../models/Survey"
import { container } from "../../../DIContainer"
import { Ii18n, i18nKey } from "../../../global/i18n"
import { TYPES } from "../../../Types"
import { ILRSService } from "../../../services/LRSService"
import { IAuth } from "../../../auth/Auth"
import { DocumentId, Dictionary } from "../../../global/TypeAliases"
import { Card, CardBody, CardFooter, Col, Progress } from "reactstrap"
import logoLarge from "../../../images/dts-logo-with-black-text.png"

interface SurveyGridItemProps {
    survey: Survey
    userClientIds?: DocumentId[]
    onSurveyClick: (e: React.MouseEvent<HTMLDivElement>) => void
    isComplete: boolean
}

interface SurveyGridItemState {
    progress: number
}

export const SurveyGridItemV2: React.FC<SurveyGridItemProps> = ({survey, userClientIds, onSurveyClick, isComplete}) => {
    const lrsService: ILRSService = container.get<ILRSService>(TYPES.ILRSService)
    const auth = container.get<IAuth>(TYPES.IAuth)
    const i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    const [progress, setProgress] = useState<SurveyGridItemState['progress']>(0)

    useEffect(() => {
        let isSubscribed = true

        if (isComplete) {
            return
        }

        const user = auth.user
        if (!user || !userClientIds) {
            return
        }

        const clientIds = getCommonClientIds(userClientIds, survey.clients)

        const promises = clientIds.map(id => {
            return lrsService.getSurveyProgress(user.uid, id, survey.lrsId)
        })

        Promise.all(promises).then(result => {
            if (isSubscribed) {
                setProgress(Math.max(...result))
            }
        }).catch(_ => {
            setProgress(0)
        })

        return () => {
            isSubscribed = false
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSurveyClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        onSurveyClick(e)
    }

    /**
     * Return the client ids that both the user and learning plan are a part of
     * @param userClientIds The clients the user belongs to
     * @param surveyClientIdsMap The clients the survey belongs to
     * @returns 
     */
    const getCommonClientIds = (userClientIds: DocumentId[], surveyClientIdsMap: Dictionary): DocumentId[] => {
        const surveyClientIds: DocumentId[] = Object.keys(surveyClientIdsMap)
		return userClientIds.filter(id => surveyClientIds.includes(id))
	}

    /**
     * Begin code to render the component
     */

    let progressBar = null
    let startSurvey = ""

    if (progress === 0 && !isComplete) {
        startSurvey = i18n.get(i18nKey.startSurvey)
    }

    if (progress === 100 || isComplete) {
        const displayedProgress = 100
        progressBar =   <div id="survey-grid-item-progress-bar-container" className="animated-progess mb-3">
                            <Progress value={displayedProgress} color="primary" className="progress-sm"></Progress>
                            <br/>
                            <Stack horizontal={true}>
                                <i id="survey-grid-item-completed-icon" className="bx bxs-badge-check text-success font-size-16 align-middle me-2"></i>
                                <h5 id="survey-grid-item-completed-label" className="card-text font-size-16 text-success">
                                    {i18n.get(i18nKey.completed)}
                                </h5>
                            </Stack>
                        </div>
    } else {
        progressBar =
            <div id="survey-grid-item-progress-bar-container" className="animated-progess mb-3">
                <Progress value={progress} color="primary" className="progress-sm"></Progress>
                <br/>
                <h5 id="survey-grid-item-progress-percent-complete-label" className="card-text font-size-16">{progress + i18n.get(i18nKey.percentComplete)}</h5>
            </div>
    }

    const imageShown = (survey.imageUrl !== undefined && survey.imageUrl !== null && survey.imageUrl !== "") ? survey.imageUrl : logoLarge
    const objectFitStyle = (survey.imageUrl !== undefined && survey.imageUrl !== null && survey.imageUrl !== "") ? 'cover' : 'contain' 
    
    return ( 
        <div className='survey-grid-item' id={survey.documentId} onClick={onSurveyClickHandler}>
            <Col md={6} xl={2}>
                <Card 
                    style={{
                        width: '300px', 
                        height: '355px',
                        borderRadius: '10px',
                        cursor: "pointer",
                        backgroundColor: "white", 
                        border: "none"
                    }}
                >
                    <img 
                        className="card-img-top img-fluid rounded"
                        id="survey-grid-item-image"  
                        src={imageShown} 
                        style={{
                            height: '10rem',
                            width: '100%',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            objectFit: objectFitStyle,
                        }}
                        alt="survey cover" 
                    />
                    <CardBody style={{height: "25%"}}>
                        <h1 className="card-title" id="survey-grid-item-title">{survey.name}</h1>
                        <p className="card-text" id="survey-grid-item-description">{survey.description}</p>
                    </CardBody>
                    <CardFooter style={{height: "33.5%"}}>
                        {progressBar}
                        <div 
                            style={{            
                                height: '20%',
                                justifyContent: "space-between",
                                display: "flex"
                            }}
                        >
                            { startSurvey !== "" && 
                                <p id="survey-start-label" className="card-text text-primary font-size-16" color="primary">
                                    {startSurvey}
                                </p>
                            }
                            <p id="survey-grid-item-duration" className="card-text font-size-16">{survey.duration}</p>
                        </div>
                    </CardFooter>
                </Card>
            </Col>
        </div>
    )
}