import React from "react";
import { Stack } from "@fluentui/react";
import { LearningPlanGridStyle } from "./LearningPlanGridStyle";
import { LearningPlan } from "../../models/LearningPlan";
import { Dictionary } from "../../global/TypeAliases";
import { LearningPlanGridItemV2 } from "../admin/learning_plan/LearningPlanGridItemV2";

interface LearningPlanGridProps {
	learningPlans: LearningPlan[]
	completions?: Dictionary
	deadlines?: Dictionary
	onLearningPlanClick: (e: React.MouseEvent<HTMLDivElement>) => void
}

export const LearningPlanGridV2: React.FC<LearningPlanGridProps> = ({learningPlans, completions, deadlines, onLearningPlanClick}) => {
    const gridItems = learningPlans.map((learningPlan) => {
        let deadline: string | undefined;
        let isComplete: boolean | undefined; 

        if (deadlines) {
            deadline = deadlines[learningPlan.documentId]
        }

        if (completions) {
            isComplete = completions[learningPlan.documentId]
        }

        return (
            <LearningPlanGridItemV2 
                    learningPlan={learningPlan} 
                    key={learningPlan.documentId} 
                    isComplete={isComplete}
                    deadline={deadline}
                    onLearningPlanClick={onLearningPlanClick} 
            />
        )
    });

    return (
        <div id="learning-plan-grid">
            <Stack 
                horizontal={true} 
                wrap={true}
                tokens={LearningPlanGridStyle.stackTokens}
            >
                {gridItems}
            </Stack>
        </div>
    )
}