import { Stack, Image, ImageFit } from "@fluentui/react";
import React, { Component } from "react";
import { ImageGridStyles } from "./ImageGridStyles";
import defaultImage from '../images/dts-logo-red.png'

interface ImageGridProps {
    imageProps: LearningPlanImageProps[]
}

export interface LearningPlanImageProps {
    alt: string
    src: string
}

export class ImageGrid extends Component<ImageGridProps> {
    render() {
        return (
            <div id="image-grid-container">
                { this.props.imageProps.length === 0 &&
                    <Image 
                        src={defaultImage} 
                        alt="default-learning-plan-cover-image" 
                        imageFit={ImageFit.centerCover} 
                        styles={ImageGridStyles.noImageStyle} 
                    />
                }

                { this.props.imageProps.length === 1 &&
                    <Image 
                        src={this.props.imageProps[0].src} 
                        alt={this.props.imageProps[0].alt} 
                        imageFit={ImageFit.centerCover} 
                        styles={ImageGridStyles.oneImageStyle} 
                    />
                }

                { this.props.imageProps.length === 2 &&
                    <Stack styles={ImageGridStyles.stackStyle}>
                        <Image 
                            src={this.props.imageProps[0].src} 
                            alt={this.props.imageProps[0].alt} 
                            imageFit={ImageFit.centerCover}  
                            styles={ImageGridStyles.twoTopImageStyle} 
                        />
                        <Image 
                            src={this.props.imageProps[1].src} 
                            alt={this.props.imageProps[1].alt} 
                            imageFit={ImageFit.centerCover}  
                            styles={ImageGridStyles.twoBottomImageStyle}
                        />
                    </Stack>
                }

                { this.props.imageProps.length === 3 &&
                    <Stack styles={ImageGridStyles.stackStyle}>
                        <Stack styles={ImageGridStyles.twoHorizontalStackStyle} horizontal>
                            <Image 
                                src={this.props.imageProps[0].src} 
                                alt={this.props.imageProps[0].alt} 
                                imageFit={ImageFit.centerCover}  
                                styles={ImageGridStyles.threeLeftImageStyle}
                            />
                            <Image 
                                src={this.props.imageProps[1].src} 
                                alt={this.props.imageProps[1].alt} 
                                imageFit={ImageFit.centerCover} 
                                styles={ImageGridStyles.threeRightImageStyle}
                            />
                        </Stack>
                        <Image 
                            src={this.props.imageProps[2].src} 
                            alt={this.props.imageProps[2].alt} 
                            imageFit={ImageFit.centerCover}  
                            styles={ImageGridStyles.threeBottomImageStyle}
                        />
                    </Stack>
                }

                { this.props.imageProps.length > 3 &&
                    <Stack styles={ImageGridStyles.stackStyle} wrap horizontal>
                        <Image 
                            src={this.props.imageProps[0].src} 
                            alt={this.props.imageProps[0].alt} 
                            imageFit={ImageFit.centerCover}  
                            styles={ImageGridStyles.fourImageLeftStyle}
                        />
                        <Image 
                            src={this.props.imageProps[1].src} 
                            alt={this.props.imageProps[1].alt} 
                            imageFit={ImageFit.centerCover}  
                            styles={ImageGridStyles.fourImageRightStyle}
                        />
                        <Image 
                            src={this.props.imageProps[2].src} 
                            alt={this.props.imageProps[2].alt} 
                            imageFit={ImageFit.centerCover}  
                            styles={ImageGridStyles.fourImageStyle}
                        />
                        <Image 
                            src={this.props.imageProps[3].src}
                            alt={this.props.imageProps[3].alt}  
                            imageFit={ImageFit.centerCover}  
                            styles={ImageGridStyles.fourImageStyle}
                        />
                    </Stack>
                }
            </div>   
        )
    }
}