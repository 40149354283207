import { IEventReportingService } from './EventReportingService';
import { UserStatus } from './../models/User';
import User from "../models/User"
import { IUserService } from './UserService';
import { injectable } from "inversify"
import { container } from "../DIContainer"
import { TYPES } from "../Types"
import { DocumentId, Uid } from "../global/TypeAliases"
import { IClientService } from './ClientService';
import { IRegistrationTokenService } from './RegistrationTokenService';
import { IFirebase } from './Firebase';
import { log } from '../global/Logger';
export interface IEmailRegistrationCompositionService {
    /**
     * Gets all users that belong to a specific client and domain that all
     * have the same account status
     * @param clientId The id of the client
     * @param status The user account status 
     */
    getUsersByStatusForClient(clientId: DocumentId, status: UserStatus): Promise<User[]>

    /**
     * Queries the registration-token collection on firebase and attempts
     * to get the document that matches the passed in uid and token
     * @param uid The uid of the user
     * @param token The registration token associated with the user
     */
    isRegistrationLinkValid(uid: Uid, token: string): Promise<boolean>
     /**
      * Creates a new record in the firebase collection and stores the 
      * registration token along with the users uid
      * e.g. { 'uid1': 'abcdefg1234' }
      * @param email email address of user
      * @param token registration token associated with user
      */
     getUidAndUploadToken(email: string, token: string): Promise<void>
}

@injectable()
export class EmailRegistrationCompositionService implements IEmailRegistrationCompositionService {
    private userService: IUserService = container.get<IUserService>(TYPES.IUserService)
	private clientService: IClientService = container.get<IClientService>(TYPES.IClientService)
    private registrationTokenService: IRegistrationTokenService = container.get<IRegistrationTokenService>(TYPES.IRegistrationTokenService)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)
    private firebase: IFirebase = container.get<IFirebase>(TYPES.IFirebase)

	getUsersByStatusForClient(clientId: DocumentId, status: UserStatus): Promise<User[]> {
        return new Promise((resolve, reject) => {
            this.clientService
            .clientForId(clientId)
            .then(client => {
                const domain = client.domain()
                if (domain === null) {
                    throw new Error("No domain listed for client")
                }

                return this.userService.getUsersByStatusForDomain(domain, status)     
            }).then(users => {
                resolve(users)
            }).catch(error => {
                reject(error)
            })
        })
	}

    getUidAndUploadToken(email: string, token: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.userService
            .getUserWithEmail(email)
            .then(user => {
                if (!user) {
                    throw new Error("Learner does not exist")
                }

                return this.registrationTokenService.uploadRegistrationToken(user.uid, token) 
            })
            .then(() => {
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
	}    
                
    isRegistrationLinkValid(uid: Uid, token: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let verifyAuthToken = this.firebase.app.functions().httpsCallable("verifyAuthToken")

            let data = {
                uid: uid,
                token: token
            }
            
            verifyAuthToken(data).then((result) => {
                log("EmailRegistrationCompositionService - isRegistrationLinkValid() - Success")
                if (result.data.token !== null && result.data.token === token) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            }).catch(error => {
                this.eventReportingService.warn(error.message, error)
                log("EmailRegistrationCompositionService - isRegistrationLinkValid() - " + error)
                reject(error)
            })
        })
    }
}