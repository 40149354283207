import React, { Component, MouseEvent, ReactElement } from "react"
import {
	IStackTokens,
	Label,
	MessageBar,
	MessageBarType,
	Spinner,
	Stack,
} from "@fluentui/react"
import { PrimaryButton } from "../../../ui-kit/PrimaryButton"
import { TabPanelProps } from "../TabPanelProps"
import { AdminUIStyles } from "../users/AdminUI"
import { SearchBox } from "@fluentui/react"
import { ICohortService } from "../../../services/CohortService"
import { ILearningPlanService } from "../../../services/LearningPlanService"
import DTSTable from "../../../ui-kit/DTSTable"
import Cohort from "../../../models/Cohort"
import DTSTableData from "../../../models/DTSTableData"
import IDataTableFactory from "../../../factories/DataTableFactory"
import { TYPES } from "../../../Types"
import { container } from "../../../DIContainer"
import { ISearchFilter } from "../users/SearchFilter"
import { log } from "../../../global/Logger"
import { i18nKey, Ii18n } from "../../../global/i18n"
import "./AddCohortToLearningPlan.css"

interface AddCohortToLearningPlanProps extends TabPanelProps {
	documentId: string
}

interface AddCohortToLearningPlanState {
	searchResults: Cohort[]
	tableData: DTSTableData | undefined
	primaryButtonDisabled: boolean
	showSpinner: boolean
	showSearchSpinner: boolean
	errorMessage: string | undefined
	successMessage: string | undefined
	selectedCohorts: string[]
}

const stackStyling: IStackTokens = {
	childrenGap: "20px",
}

export class AddCohortToLearningPlan extends Component<
	AddCohortToLearningPlanProps,
	AddCohortToLearningPlanState
> {

	private cohortService: ICohortService = container.get<ICohortService>(TYPES.ICohortService)
	private searchFilter: ISearchFilter = container.get<ISearchFilter>(TYPES.ISearchFilter)
	private learningPlanService: ILearningPlanService = container.get<ILearningPlanService>(TYPES.ILearningPlanService)
	private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	state: AddCohortToLearningPlanState = {
		searchResults: [],
		tableData: undefined,
		primaryButtonDisabled: true,
		showSpinner: false,
		showSearchSpinner: false,
		errorMessage: undefined,
		successMessage: undefined,
		selectedCohorts: []
	}

	render() {
		let banner = null
		if (this.state.errorMessage) {
			banner = (
				<MessageBar
					id="add-cohort-to-learning-plan-error-banner"
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.errorMessage}
				</MessageBar>
			)
		} else if (this.state.successMessage) {
			banner = (
				<MessageBar
					id="add-cohort-to-learning-plan-success-banner"
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			)
		}

		let searchResult: ReactElement | undefined
		if (this.state.tableData !== undefined && !this.state.showSearchSpinner) {
			searchResult = (
				<div id="add-cohort-to-learning-plan-table">
					<Stack tokens={stackStyling}>
						<DTSTable
							data={this.state.tableData}
							pagination={true}
                            onSelectedRowsChange={this.onSelectedRowChange}
						/>
						<PrimaryButton
							id="add-cohort-to-learning-plan-primary-button"
							text={this.i18n.get(i18nKey.buttonGroupsAdd)}
							onClick={this.primaryButtonClicked}
							disabled={this.state.primaryButtonDisabled}
							hidden={this.state.showSpinner}
						/>
						{ this.state.showSpinner &&
							<Spinner
								label={this.i18n.get(i18nKey.spinnerGroupsAdding)}
								ariaLive="assertive"
								labelPosition="right"
							/>
						}
					</Stack>
				</div>
			)
		}
		return (
			<div id="add-cohort-to-learning-plan-container">
				<Stack tokens={stackStyling} horizontalAlign="center">
					<Label>{this.i18n.get(i18nKey.labelGroupsAddSearch)}</Label>
					<SearchBox
						id="add-cohort-to-learning-plan-search-box"
						styles={AdminUIStyles.searchBoxStyle}
						placeholder={this.i18n.get(i18nKey.placeholderGroupsSearch)}
						onEscape={(ev) => {
							log("Custom onEscape Called")
						}}
						onClear={(ev) => {
							log("Custom onClear Called")
						}}
						onChange={(_, newValue) =>
							log("SearchBox onChange fired: " + newValue)
						}
						onSearch={(newValue) => this.search(newValue)}
					/>

					{ this.state.showSearchSpinner && 
						<Spinner
							id="add-cohort-to-learning-plan-search-spinner"
							label={this.i18n.get(i18nKey.spinnerSearching)}
							ariaLive="assertive"
							labelPosition="right"
						/>
					}

					{searchResult}
					{banner}
				</Stack>
			</div>
		)
	}

	search = (searchTerm: string) => {
		this.cohortService
			.cohorts()
			.then(cohorts => {
				const filtered = this.searchFilter.cohortsForSearchTerm(searchTerm, cohorts)
				const data = this.dataTableFactory.createCohortsTableData(filtered)

				this.setState({ showSearchSpinner: false, searchResults: filtered, tableData: data })
			})
			.catch(error => {
				this.setState({ showSearchSpinner: false, errorMessage: error.message })
			})
	}

	onSelectedRowChange = (selectedRowState: {
		allSelected: boolean
		selectedCount: number
		selectedRows: { [key: string]: any }[]
	}) => {
		if (selectedRowState.selectedCount === 0) {
			this.setState({ selectedCohorts: [] }, () => {
				this.enablePrimaryButtonIfNeeded()
			})
			return
		}

        const selectedIds = selectedRowState.selectedRows.map((row) => {
			return row.id
		})

		log(selectedIds.length + " cohorts selected")

		this.setState({ selectedCohorts: selectedIds }, () => {
			this.enablePrimaryButtonIfNeeded()
		})
	}

	primaryButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: undefined,
			successMessage: undefined,
			showSpinner: true,
			primaryButtonDisabled: true,
		})

		this.learningPlanService
			.addCohortsToLearningPlan(this.state.selectedCohorts, this.props.documentId)
			.then(() => {
                let message: string
                if (this.state.selectedCohorts.length === 1) {
                    message = this.i18n.get(i18nKey.messageGroupAddedOne)
                } else {
                    message = this.state.selectedCohorts.length + " " + this.i18n.get(i18nKey.messageGroupsAdded)
                }

				this.setState({
					successMessage: message,
					showSpinner: false,
					primaryButtonDisabled: false,
					selectedCohorts: [],
				})
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showSpinner: false,
					primaryButtonDisabled: false,
				})
			})
	}

	enablePrimaryButtonIfNeeded = () => {
		this.setState({
			primaryButtonDisabled: !(this.state.selectedCohorts.length > 0),
		})
	}

	dismissBanner = () => {
		this.setState({ errorMessage: undefined, successMessage: undefined })
	}
}