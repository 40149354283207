import React, { Component, ReactElement } from "react"
import { Stack, Image, Text, ProgressIndicator, Spinner } from "@fluentui/react"
import { Icon, ImageFit } from "@fluentui/react"
import { FontSize } from "../../../ui-kit/FontSize"
import { SurveyGridItemStyle } from "./SurveyGridItemStyle"
import { Survey } from "../../../models/Survey"
import { container } from "../../../DIContainer"
import { Ii18n, i18nKey } from "../../../global/i18n"
import { TYPES } from "../../../Types"
import { ILRSService } from "../../../services/LRSService"
import { IAuth } from "../../../auth/Auth"
import { DocumentId, Dictionary } from "../../../global/TypeAliases"

interface SurveyGridItemProps {
    survey: Survey
    userClientIds?: DocumentId[]
    onSurveyClick: (e: React.MouseEvent<HTMLDivElement>) => void
    isComplete?: boolean
}

interface SurveyGridItemState {
    requestComplete: boolean
    progress: number | null
}

export class SurveyGridItem extends Component<SurveyGridItemProps, SurveyGridItemState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private lrsService: ILRSService = container.get<ILRSService>(TYPES.ILRSService)
    private auth: IAuth = container.get<IAuth>(TYPES.IAuth)

    state: SurveyGridItemState = {
        requestComplete: this.props.isComplete ?? false,
        progress: this.props.isComplete ? 100 : null
    }

    componentDidMount() {
        if (this.props.isComplete) {
            return
        }

        this.load()
    }

    render() {
        return (
            <div className='survey-grid-item' id={this.props.survey.documentId} onClick={this.onSurveyClickHandler}>
                <Stack styles={SurveyGridItemStyle.containerStyle}>
                    <Stack styles={SurveyGridItemStyle.headerStyle}>
                        <Image 
                            id="survey-image"
                            src={this.props.survey.imageUrl}
                            alt={this.props.survey.name + " Survey"} 
                            imageFit={ImageFit.centerCover} 
                            styles={SurveyGridItemStyle.imageStyle}
                        />
                    </Stack>
                    <Stack tokens={SurveyGridItemStyle.stackTokens} styles={SurveyGridItemStyle.bodyStyle}>
                        <Text id="survey-name" variant={FontSize.large}>{this.props.survey.name}</Text>
                    </Stack>

                    {this.progressBarElement()}

                    {this.footerElement()}
                </Stack>
            </div>
        )
    }

    /**
     * Private Functions
     */

    private load = () => {
        const user = this.auth.user
        if (!user) {
            return
        }

        const userClientIds = this.props.userClientIds
        if (!userClientIds) {
            return
        }

        const clientIds = this.getCommonClientIds(userClientIds, this.props.survey.clients)

        const promises = clientIds.map(id => {
            return this.lrsService.getSurveyProgress(user.uid, id, this.props.survey.lrsId)
        })

        Promise.all(promises).then(result => {
            this.setState({ progress: Math.max(...result), requestComplete: true })
        }).catch(_ => {
            this.setState({ progress: 100, requestComplete: true })
        })
    }

    private onSurveyClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.props.onSurveyClick(e)
    }

    private progressBarElement = (): ReactElement | null => {
        if (this.props.isComplete === undefined) {
            return null
        }

        if (!this.state.requestComplete) {
            return <Spinner />
        }

        const progress = this.state.progress ?? 0

        if (progress === 100) {
            return (
                <Stack tokens={SurveyGridItemStyle.stackTokens} styles={SurveyGridItemStyle.bodyStyle}>
                    <ProgressIndicator
                        percentComplete={progress/100} 
                        barHeight={4}
                        description={this.getProgressBarDescription()}
                    />
                    <Stack styles={SurveyGridItemStyle.bodyStyle} horizontal>
                        <Icon 
                            id="complete-icon" 
                            iconName="Completed" 
                            styles={SurveyGridItemStyle.completedIcon} 
                        />
                        <div id="complete-label" style={SurveyGridItemStyle.completedDiv}>
                            {this.i18n.get(i18nKey.completed)}
                        </div>
                    </Stack>
                </Stack>
            )
        } else {
            return (
                <Stack tokens={SurveyGridItemStyle.stackTokens} styles={SurveyGridItemStyle.footerStyle}>
                    <ProgressIndicator
                        percentComplete={progress/100} 
                        barHeight={4}
                        description={this.getProgressBarDescription()}
                    />
                </Stack>
            )
        }
    }

    private footerElement = (): ReactElement => {
        return (
            <Stack tokens={SurveyGridItemStyle.stackTokens} styles={SurveyGridItemStyle.footerStyle} horizontal>
                { this.state.progress === 0 && 
                    <Text 
                        id="survey-start-course-label" 
                        variant={FontSize.medium} 
                        styles={SurveyGridItemStyle.startCourseText}>
                            Start Survey
                    </Text>
                }
                <Text id="survey-duration" variant={FontSize.medium}>{this.props.survey.duration}</Text>
            </Stack>
        )
    }

    private getProgressBarDescription = (): string | undefined => {
        const progress = this.state.progress ?? 0

        return this.state.progress === 100 
                    ? undefined 
                    : progress + this.i18n.get(i18nKey.percentComplete)
    }

    /**
     * Return the client ids that both the user and learning plan are a part of
     * @param userClientIds The clients the user belongs to
     * @param surveyClientIdsMap The clients the survey belongs to
     * @returns 
     */
     private getCommonClientIds(userClientIds: DocumentId[], surveyClientIdsMap: Dictionary): DocumentId[] {
        const surveyClientIds: DocumentId[] = Object.keys(surveyClientIdsMap)
		return userClientIds.filter(id => surveyClientIds.includes(id))
	}
}