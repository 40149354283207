import { IStackTokens } from "@fluentui/react";

export class CourseGridStyle {
    static stackTokens: IStackTokens = {
		childrenGap: '50px',
	}

    static titleStyle = {
        root: {
            width: "100%", 
            textAlign: "center"
        }
    }
}