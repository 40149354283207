import React, { Component } from 'react'
import { Stack, Text, Image } from '@fluentui/react'
import { MarketingIi18n, i18nKey } from '../global/Marketing-i18n'
import { container } from '../DIContainer'
import { TYPES } from '../Types'
import { FeaturesCallStyles } from './FeaturesCallStyles'

import callImage from '../images/marketing/features-call-image.png'

export class FeaturesCall extends Component {
    private i18n = container.get<MarketingIi18n>(TYPES.MarketingIi18n)

    render() {
        return (
            <Stack horizontal wrap horizontalAlign='space-between' styles={FeaturesCallStyles.outerStackStyles}>

                <Stack styles={FeaturesCallStyles.innerLeftStackStyles}>
                    <Image alt={this.i18n.get(i18nKey.featuresCallImageAltText)} src={callImage} styles={FeaturesCallStyles.imageStyles} />
                </Stack>

                <Stack styles={FeaturesCallStyles.innerRightStackStyles}>
                    <Text styles={FeaturesCallStyles.sectionNameTextStyles}>
                        {this.i18n.get(i18nKey.featuresCallSectionName).toUpperCase()}
                    </Text>
                    <Text styles={FeaturesCallStyles.headerTextStyles}>
                        {this.i18n.get(i18nKey.featuresCallHeader)} 
                    </Text>
                    <Text styles={FeaturesCallStyles.descriptionTextStyles}>
                        {this.i18n.get(i18nKey.featuresCallParagraph1)}
                    </Text>
                    <Text styles={FeaturesCallStyles.descriptionTextStyles}>
                        {this.i18n.get(i18nKey.featuresCallParagraph2)}
                    </Text>
                </Stack>

            </Stack>
        )
    }
}
