import { IStackStyles, IStackTokens } from "@fluentui/react";

export class LearningPlansPageStyles {
    
    static stackTokens: IStackTokens = {
		childrenGap: '50px',   
	}
    
    static stackStyles: IStackStyles = {
        root: {
            alignItems: 'center',
            display: 'flex',
            paddingTop: '30px'
        }
    }

    static stackGridStyles: IStackStyles = {
        root: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flexStart',
            paddingTop: '30px',
            paddingLeft: '3rem'
        }
    }

    static headerStackStyles: IStackStyles = {
        root: {
            alignItems: 'baseline',
            paddingTop: '50px',
        }
    }
}