import Course, { CourseType } from "./Course";
import { injectable } from "inversify";
import { Dictionary } from "../global/TypeAliases";

export interface ICourseParser {
	parse(id: string, data: Dictionary): Course;
}

@injectable()
export default class CourseParser implements ICourseParser {
	parse(id: string, data: Dictionary): Course {
		return new Course(
			id,
			data.type === undefined ? CourseType.training : data.type,
			data.lrsId === undefined ? "" : data.lrsId,
			data.name,
			data.description,
			data.imageUrl === undefined ? "" : data.imageUrl,
			data.locationUrl === undefined ? "" : data.locationUrl,
			data.cohorts === undefined ? {} : data.cohorts,
			data.clients === undefined ? {} : data.clients,
			data.duration === undefined ? "" : data.duration,
			data.courseFolder === undefined ? "" : data.courseFolder,
			data.lessonNames === undefined ? {} : data.lessonNames
		);
	}
}
