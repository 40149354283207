import { Checkbox, Dropdown, IDropdownOption, MarqueeSelection, MessageBar, MessageBarType, Modal, Stack, Selection, DetailsList, DetailsListLayoutMode, Spinner, Text} from "@fluentui/react";
import { useState, MouseEvent, useEffect } from "react";
import { container } from "../../DIContainer";
import { i18nKey, Ii18n } from "../../global/i18n";
import { TYPES } from "../../Types";
import PageTitle from "../../ui-kit/PageTitle";
import { log } from "../../global/Logger"
import { SecondaryButton } from "../../ui-kit/SecondaryButton";
import { DestructiveIconButton } from "../../ui-kit/DestructiveIconButton";
import { ConfirmationDialog } from "../../ui-kit/ConfirmationDialog";
import { Invitation } from "../../models/Invitation";
import { NewInvitationCodeModal } from "./NewInvitationCodeModal";
import { TextField } from "../../ui-kit/TextField";
import { PrimaryButton } from "../../ui-kit/PrimaryButton";
import "./ManageInvitationsHomePage.css"
import { IInvitationCodeService } from "../../services/InvitationCodeService";
import { DestructiveButton } from "../../ui-kit/DestructiveButton";
import { FontSize } from "../../ui-kit/FontSize";
import { IStringHelpers } from "../../utils/StringHelpers";

export interface IEntitiesListItem {
    key: string;
    name: string;
}

export const ManageInvitationsHomePage = () => {
    const i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    const invitationCodeService: IInvitationCodeService = container.get<IInvitationCodeService>(TYPES.IInvitationCodeService)
    const stringHelpers: IStringHelpers = container.get<IStringHelpers>(TYPES.IStringHelpers)
    const [selectedInvitation, setSelectedInvitation] = useState<Invitation | undefined>(undefined)
    const [codeDropdownOptions, setCodeDropdownOptions] = useState<IDropdownOption[]>([])
    const [currentSelectedCode, setCurrentSelectedCode] = useState<string>("")
    const [updateButtonDisabled, setUpdateButtonDisabled] = useState<boolean>(true)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    // eslint-disable-next-line
    const [listSelection, setListSelection] = useState<Selection>(new Selection())
    const listColumns = [
        { key: 'column1', name: 'Name', fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true }
    ]
    const [entityListItems, setEntityListItems] = useState<IEntitiesListItem[]>([])
    const [addEntityNameTextFieldValue, setAddEntityNameTextFieldValue] = useState<string>("")
    const [isInvitationExpired, setIsInvitationExpired] = useState<boolean>(false)
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [deleteDialogIsHidden, setDeleteDialogIsHidden] = useState<boolean>(true)
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const pageTitle = i18n.get(i18nKey.manageGuestCodes)

    const selectedCodeChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        log("Code selected: " + option?.data)
        const selectedCode = option?.text as string

        setCurrentSelectedCode(selectedCode)

        const invitation = option?.data
        setSelectedInvitation(invitation)
    }

    const addEntityNameTextFieldChanged = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return;
		}

        setAddEntityNameTextFieldValue(newValue)	
	}

    const modalDismissed = () => {
        setIsModalOpen(false)
	}

    const addInvitationCodeButtonClicked = () => {
        setIsModalOpen(true)
	}

    const dismissSuccessBanner = () => {
        setSuccessMessage(undefined)
	}

    const dismissErrorBanner = () => {
        setErrorMessage(undefined)
	}

    const updateButtonClicked = () => {
        setShowSpinner(true)

        if (!selectedInvitation) {
            setShowSpinner(false)
            setErrorMessage(i18n.get(i18nKey.guestCodeHasFailedToUpdate))
            return
        }

        let entities = selectedInvitation.entities
        if (addEntityNameTextFieldValue !== "") {
            const entitiesToAdd = stringHelpers.getCommaDeliminatedValues(addEntityNameTextFieldValue)
            entities = entities.concat(entitiesToAdd)
        }
        invitationCodeService.updateInvitation(selectedInvitation?.code, entities, isInvitationExpired, selectedInvitation.hookText, selectedInvitation.selectedTrainings)
            .then(() => {
                return invitationCodeService.getInvitation(selectedInvitation.code)
            }).then(invitation => {
                setSelectedInvitation(invitation)
                setAddEntityNameTextFieldValue("")
                setSuccessMessage(i18n.get(i18nKey.guestCodeHasBeenUpdatedExclamation))
            })
            .catch((error) => {
                setErrorMessage(i18n.get(i18nKey.guestCodeHasFailedToUpdate))
            }).finally(() => {
                setShowSpinner(false)
            })
    
    }

    const deleteEntitiesFromInvitationButtonClicked = () => {
        setShowSpinner(true)
        const entitiesToDelete = listSelection.getSelection()

        let updatedEntitiesList = entityListItems.map((item) => {
            if (!entitiesToDelete.includes(item)) {
                return item.name
            } else {
                return ""
            }
        })

        updatedEntitiesList = updatedEntitiesList.filter(entity => entity !== "")
        if (selectedInvitation) {
            invitationCodeService.updateInvitation(selectedInvitation?.code, updatedEntitiesList, selectedInvitation?.expired, selectedInvitation.hookText, selectedInvitation.selectedTrainings)
                .then(() => {
                    return invitationCodeService.getInvitation(selectedInvitation.code)
                }).then(invitation => {
                    setSelectedInvitation(invitation)
                    setSuccessMessage(i18n.get(i18nKey.selectedEntitiesHaveBeenDeletedExclamation))
                }).catch(error => {
                    setErrorMessage(error)
                }).finally(() => {
                    setShowSpinner(false)
                })
        }
    }

    const checkboxChanged = (
		_: React.FormEvent<HTMLElement> | HTMLInputElement | undefined, 
		isChecked: boolean | undefined) => {
		if (isChecked === undefined) {
			return
		}

        setIsInvitationExpired(isChecked)
	}

    const deleteDialogDismissed = () => {
        setDeleteDialogIsHidden(true)
	}

	const deleteButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
        setDeleteDialogIsHidden(false)
	}

    const deleteButtonClickedConfirmed = (_: MouseEvent<HTMLButtonElement>) => {
        setShowSpinner(true)
        invitationCodeService.deleteInvitation(currentSelectedCode)
            .then(() => {
                setDeleteDialogIsHidden(true)
                setShowSpinner(false)
                setErrorMessage(undefined)
                setSuccessMessage(i18n.get(i18nKey.codeHasBeenDeletedExclamation))
                setCurrentSelectedCode("")
            }).catch(error => {
                setErrorMessage(error)
            })
    }

    const reloadEntities = () => {
		log("Code added from modal")
        setSuccessMessage(i18n.get(i18nKey.codeSuccessfullyAddedExclamation))
        setIsModalOpen(false)
	}

    const onListItemInvoked = (item: IEntitiesListItem): void => {
        alert(`Item invoked: ${item.name}`);
      };

    useEffect(() => {
        invitationCodeService.invitations()
            .then((invitations) => {
                let invitationOptions: IDropdownOption[] = invitations.map((invitation) => {
                    return {
                        key: invitation.code,
                        text: invitation.code,
                        data: invitation
                    }
                })
                setCodeDropdownOptions(invitationOptions)
            }).finally(() => {
            })
    }, [invitationCodeService])

    useEffect(() => {
        
        if (selectedInvitation) {
            const entitiesList: IEntitiesListItem[] = selectedInvitation.entities.map((entity: any) => {
                return {key: entity, name: entity}
            })
            setEntityListItems(entitiesList)
            setIsInvitationExpired(selectedInvitation.expired)
        }
    }, [selectedInvitation])

    useEffect(() => {
        const enableUpdateButtonIfNeeded = () => {
            let disable = true;
    
            if (selectedInvitation && (isInvitationExpired !== selectedInvitation.expired || addEntityNameTextFieldValue !== "")) {
                disable = false;
            }
    
            setUpdateButtonDisabled(disable);
        }

        enableUpdateButtonIfNeeded()
    }, [selectedInvitation, isInvitationExpired, addEntityNameTextFieldValue])

    return(
        <div id="manage-invitations-home-page-container">
            <PageTitle title={pageTitle} />
            <Stack id="manage-invitations-home-page-container-stack"
                verticalFill={true}
                styles={{ root: { paddingLeft: 50, paddingRight: 50, marginTop: 20 }}}
                tokens={{childrenGap: "2rem"}}
            >
                <div className="admin-command-bar">
                    <SecondaryButton
                        id="add-new-invitation-button"
                        iconProps={{ iconName: "Education" }}
                        text={i18n.get(i18nKey.addNewGuestCode)}
                        onClick={addInvitationCodeButtonClicked}
                    />
                </div>
                <Dropdown
                    id="guest-code-selection-dropdown"
                    label={i18n.get(i18nKey.selectAGuestCodeToViewAndEditItExclamation)}
                    placeholder={i18n.get(i18nKey.guestCode)}
                    options={codeDropdownOptions}
                    onChange={selectedCodeChanged}
                    required
                    styles={{root: { 
                        width: 400, 
                    }}}
                />
                {successMessage !== undefined && (
                    <MessageBar
                        messageBarType={MessageBarType.success}
                        dismissButtonAriaLabel="Close"
                        onDismiss={dismissSuccessBanner}
                    >
                        {successMessage}
                    </MessageBar>
                )}
                {errorMessage !== undefined && (
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        dismissButtonAriaLabel="Close"
                        onDismiss={dismissErrorBanner}
                    >
                        {errorMessage}
                    </MessageBar>
                )}
                { (currentSelectedCode !== "") &&
                    <>
                        <Stack tokens={{childrenGap: 50}}>		
                            <MarqueeSelection selection={listSelection}>
                                <DetailsList
                                    items={entityListItems}
                                    columns={listColumns}
                                    setKey="set"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    selection={listSelection}
                                    selectionPreservedOnEmptyClick={true}
                                    ariaLabelForSelectionColumn="Toggle selection"
                                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                    checkButtonAriaLabel="select row"
                                    onItemInvoked={onListItemInvoked}
                                />
                            </MarqueeSelection>
                            <DestructiveButton
                                id="delete-entities-from-invitation-destructive-button"
                                text={"Remove Entities"}
                                onClick={deleteEntitiesFromInvitationButtonClicked}
                                disabled={listSelection.count === 0 }
							/>
                            <div id="add-entity-name-textfield-container">
                                <Text variant={FontSize.medium}>
                                    {i18n.get(i18nKey.addEntitiesHelperText)}
                                </Text>
                                <TextField
                                    id="add-entity-name-textfield"
                                    label={i18n.get(i18nKey.nameOfEntityToAdd)}
                                    onChange={addEntityNameTextFieldChanged}
                                    value={addEntityNameTextFieldValue}
                                />
                            </div>
                            <Checkbox 
                                id="invitation-expired-checkbox" 
                                label={i18n.get(i18nKey.expiredSingleWord)}
                                checked={isInvitationExpired} 
                                onChange={checkboxChanged} 
                            />
                            <PrimaryButton
                                id="invitation-code-update-primary-button"
                                disabled={updateButtonDisabled}
                                text={i18n.get(i18nKey.update)}
                                onClick={updateButtonClicked}
                                width="150px"
                            />
                            <DestructiveIconButton
                                id="manage-invitations-delete-button"
                                onClick={deleteButtonClicked}
                                disabled={false}
                            />
                            { !deleteDialogIsHidden && 
                                <ConfirmationDialog
                                    title={i18n.get(i18nKey.deleteGuestCodeTitle)}
                                    dialogMessage={i18n.get(i18nKey.deleteGuestCodeMessage)}
                                    affirmativeButtonText={i18n.get(i18nKey.delete)}
                                    dismissButtonText={i18n.get(i18nKey.dismiss)}
                                    affirmativeActionHandler={deleteButtonClickedConfirmed}
                                    dismissActionHandler={deleteDialogDismissed}
                                    isDestructive={true}
                                />
                            }
                        </Stack>
                    </>
                }
                { showSpinner && 
                    <Spinner
                        id="manage-invitations-spinner"
                        label={i18n.get(i18nKey.processing)}
                        ariaLive="assertive"
                        labelPosition="right"
                    />
                }
                <Modal
                    titleAriaId="add new invitation code modal"
                    isOpen={isModalOpen}
                    onDismiss={modalDismissed}
                    isBlocking={false}
                >
                    <NewInvitationCodeModal invitationCodeAdded={reloadEntities} />
                </Modal>
            </Stack>
        </div>
    )
}