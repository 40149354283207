import { Stack, Text } from "@fluentui/react";
import React, { Component, FormEvent } from "react";
import { Question, QuestionRatingModel } from "survey-react";
import { container } from "../../../../DIContainer";
import { Ii18n, i18nKey } from "../../../../global/i18n";
import { Survey } from "../../../../models/Survey";
import { TYPES } from "../../../../Types";
import { FontSize } from "../../../../ui-kit/FontSize";
import { RangedScaleSlider } from "../../../../ui-kit/RangedScaleSlider";
import { TextField } from "../../../../ui-kit/TextField";
import { ToggleControl } from "../../../../ui-kit/ToggleControl";

interface RatingPropertiesPanelProps {   
    survey: Survey
    model: QuestionRatingModel
    didUpdateSurvey: (survey: Survey) => void
    toggleRequireInteraction: (survey: Survey, model: Question, checked: boolean) => void
}

interface RatingPropertiesPanelState {}

export class RatingPropertiesPanel extends Component<RatingPropertiesPanelProps, RatingPropertiesPanelState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    render() {
        return (
            <Stack tokens={{childrenGap: 20}}>
                <Text 
                    id="title-label"
                    variant={FontSize.xLarge}>
                        {this.i18n.get(i18nKey.ratingProperties)}
                </Text>
                <TextField 
                    id="title-textfield"
                    type="text"
                    label={this.i18n.get(i18nKey.title)}
                    placeholder={this.i18n.get(i18nKey.surveyInteractionPlaceholder)}
                    onChange={this.titleTextFieldChanged}
                    required
                />
                <TextField 
                    id="description-textfield"
                    type="text"
                    label={this.i18n.get(i18nKey.description)}
                    value={this.props.model.description || ""}
                    onChange={this.descriptionTextFieldChanged}
                />
                <TextField 
                    id="low-description"
                    type="text"
                    label={this.i18n.get(i18nKey.lowRatingDescription)}
                    value={this.props.model.minRateDescription || ""}
                    onChange={this.lowEndDescriptionTextFieldChanged}
                />
                <TextField 
                    id="high-description"
                    type="text"
                    label={this.i18n.get(i18nKey.highRatingDescription)}
                    value={this.props.model.maxRateDescription || ""}
                    onChange={this.highEndDescriptionTextFieldChanged}
                />
                <RangedScaleSlider 
                    label={this.i18n.get(i18nKey.scale)}
                    min={this.props.model.rateMin}
                    max={this.props.model.rateMax}
                    scaleValueChanged={(_, range) => { this.onScaleChange(range) }}
                />
                <ToggleControl
                    id="required-toggle"
                    label={this.i18n.get(i18nKey.isRequired)}
                    checked={this.props.model.isRequired}
                    onChange={(_, checked) => this.requiredToggleChanged(checked)}
                />
            </Stack>
        )
    }

    /**
     * Private Functions
     */
    private titleTextFieldChanged = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.props.model.title = newValue? newValue : ""
        this.props.didUpdateSurvey(this.props.survey)
	}

    private descriptionTextFieldChanged = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.props.model.description = newValue? newValue : ""
        this.props.didUpdateSurvey(this.props.survey)
	}

    private lowEndDescriptionTextFieldChanged = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.props.model.minRateDescription = newValue? newValue : ""
        this.props.didUpdateSurvey(this.props.survey)
	}

    private highEndDescriptionTextFieldChanged = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.props.model.maxRateDescription = newValue? newValue : ""
        this.props.didUpdateSurvey(this.props.survey)
	}

    onScaleChange = (range: [number, number]) => {
        this.props.model.rateMin = range[0]
        this.props.model.rateMax = range[1]
        this.props.didUpdateSurvey(this.props.survey)
    }

    requiredToggleChanged = (checked?: boolean) => {
        if (checked !== undefined) {
            this.props.toggleRequireInteraction(this.props.survey, this.props.model, checked)
        }
    }
}