import { IStackTokens, IStackStyles, IImageStyles, IIconStyles, ITextStyles } from "@fluentui/react"

export class CourseGridItemStyle {
    static stackTokens: IStackTokens = {
        padding: '20px',
    }

    static completedDiv = {
        paddingLeft: "10px"
    }

    static containerStyle: IStackStyles = {
        root: {
            width: '275px',
            height: '325px',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px #eeeeee',
            cursor: 'pointer',
            backgroundColor: '#ffffff',
        }
    }

    static greyedOutContainerStyle: IStackStyles = {
        root: {
            width: '275px',
            height: '325px',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px #eeeeee',
            cursor: 'default',
            color: '#000000',
            opacity: "30%",
            border: "1px solid rgb(0,0,0,0.2)",
            backgroundColor: 'rgb(0,0,0,0.5)'
        }
    }
    
    static headerStyle: IStackStyles = {
        root: {
            height: '60%',
        },
    }

    static greyedOutHeaderStyle: IStackStyles = {
        root: {
            height: '60%',
        },
    }
    
    static imageStyle: IImageStyles = {
        image: {},
        root: {
            height: '100%',
            width: '100%',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px'
        }
    }

    static lockIconImageStyle: Partial<IImageStyles> = {
        image: {
            position: "absolute",
            top: 87,
            left: 62,
            width: 150
        }
    }

    static greyedOutImageStyle: IImageStyles = {
        image: {},
        root: {
            height: '100%',
            width: '100%',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
        }
    }
    
    static bodyStyle: IStackStyles = {
        root: {
            height: '20%',
        },
    }
    
    static footerStyle: IStackStyles = {
        root: {
            height: '20%',
            justifyContent: "space-between"
        },
    }

    static startCourseText: ITextStyles = {
        root: {
            color: '#24c4f0'
        }
    }

    static completedIcon: IIconStyles = {
        root: {
            color: 'green'
        },
    }

    static lockIcon: IIconStyles = {
        root: {
            color: 'red',
            fontSize: 80,
            justifyContent: 'center',
            alignItems: 'center'
        },
    }

    static deadlineStyle: ITextStyles = {
        root: {
            color: 'red'
        }
    }
}