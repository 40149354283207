import { IStackTokens, Label, MessageBar, MessageBarType, PrimaryButton, SearchBox, Spinner, Stack } from "@fluentui/react";
import { Component, ReactElement, MouseEvent } from "react";
import { container } from "../../../DIContainer";
import { i18nKey, Ii18n } from "../../../global/i18n";
import { log } from "../../../global/Logger";
import { DocumentId } from "../../../global/TypeAliases";
import DTSTableData from "../../../models/DTSTableData";
import { LearningPlan } from "../../../models/LearningPlan";
import { TYPES } from "../../../Types";
import { DestructiveButton } from "../../../ui-kit/DestructiveButton";
import DTSTable from "../../../ui-kit/DTSTable";
import { Loader } from "../../../ui-kit/Loader";
import PageTitle from "../../../ui-kit/PageTitle";
import { AdminUIStyles } from "../users/AdminUI";

interface CohortLearningPlanContentsProps {
    learningPlansTableData: DTSTableData;
    learningPlanSearchTableData: DTSTableData | undefined
    searchForLearningPlan: (value: string) => void
    searchButtonClicked: (selectedLearningPlansToAdd: string[]) => Promise<string>
    removeButtonClicked: (selectedLearningPlansToRemove: string[]) => Promise<string>
}

interface CohortLearningPlanContentsState {
    successMessage: string | undefined;
    errorMessage: string | null;
    showLearningPlanSearchSpinner: boolean;
    showLearningPlanAddSpinner: boolean;
    learningPlanSearchResults: LearningPlan[];
    addLearningPlansPrimaryButtonDisabled: boolean;
    selectedLearningPlansToAdd: DocumentId[];
    clearSelectedLearningPlanRows: boolean;
    selectedLearningPlans: DocumentId[];
    selectedLearningPlansPrimaryButtonDisabled: boolean;
    showRemoveLearningPlanSpinner: boolean;
}

const stackStyling: IStackTokens = {
	childrenGap: "20px",
}

export class CohortLearningPlanContents extends Component<CohortLearningPlanContentsProps, CohortLearningPlanContentsState> {
    private i18n = container.get<Ii18n>(TYPES.Ii18n)

    state: CohortLearningPlanContentsState = {
        successMessage: undefined,
		errorMessage: null,

        // remove learning plan states
        clearSelectedLearningPlanRows: false,
        selectedLearningPlans: [],
        selectedLearningPlansPrimaryButtonDisabled: true,
        showRemoveLearningPlanSpinner: false,

        // search/add learning plan states
        showLearningPlanSearchSpinner: false,
        showLearningPlanAddSpinner: false,
        learningPlanSearchResults: [],
        addLearningPlansPrimaryButtonDisabled: true,
        selectedLearningPlansToAdd: [],
	}

    render() {
        let learningPlansData = this.props.learningPlansTableData;

        if (this.props.learningPlansTableData === undefined) {
            return <Loader />;
        }

        let banner = null
		if (this.state.errorMessage) {
			banner = (
                <MessageBar
                    id="cohort-contents-page-error-banner"
                    messageBarType={MessageBarType.error}
                    dismissButtonAriaLabel="Close"
                    onDismiss={this.dismissErrorBanner}
                >
                    {this.state.errorMessage}
                </MessageBar>
			)
		} else if (this.state.successMessage) {
			banner = (
				<MessageBar
					id="cohort-contents-page-success-banner"
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissSuccessBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			)
		}

        let learningPlanSearchResult: ReactElement | undefined
		if (this.props.learningPlanSearchTableData !== undefined && !this.state.showLearningPlanSearchSpinner) {
			learningPlanSearchResult = (
				<div id="add-cohort-to-learning-plan-table">
					<Stack tokens={stackStyling} styles={{ root: { marginBottom: 25 }}}>
						<DTSTable
							data={this.props.learningPlanSearchTableData}
                            pagination={true}
                            onSelectedRowsChange={this.onSelectedLearningPlanSearchRowChange}
						/>
						<PrimaryButton
							id="add-cohort-to-learning-plan-primary-button"
							text={this.i18n.get(i18nKey.addSelectedLearningPlansButton)}
							onClick={this.searchLearningPlanPrimaryButtonClicked}
							disabled={this.state.addLearningPlansPrimaryButtonDisabled}
							hidden={this.state.showLearningPlanSearchSpinner}
						/>
						{ this.state.showLearningPlanAddSpinner &&
							<Spinner
								label={this.i18n.get(i18nKey.addSelectedLearningPlansSpinner)}
								ariaLive="assertive"
								labelPosition="right"
							/>
						}
					</Stack>
				</div>
			)
        }
        
        return (
            <Stack tokens={stackStyling} styles={{ root: { marginBottom: 25 }}}>
                {banner}
                <PageTitle title={this.i18n.get(i18nKey.learningPlansAssigned)} isEditGroupPageSubtitle={true}/>
                <DTSTable 
                    data={learningPlansData} 
                    aria-label="learning plans table" 
                    highlightOnHover={true}
                    pagination={true}
                    filter={true}
                    filterPlaceholder={this.i18n.get(i18nKey.searchForLearningPlan)}
                    onSelectedRowsChange={this.onSelectedLearningPlanRowsChange}
                    clearSelectedRows={this.state.clearSelectedLearningPlanRows}
                />
                <Label>{this.i18n.get(i18nKey.labelLearningPlansAddSearch)}</Label>
                <SearchBox
                    id="add-cohort-to-learning-plan-search-box"
                    styles={AdminUIStyles.searchBoxStyle}
                    placeholder={this.i18n.get(i18nKey.addSelectedLearningPlansSearch)}
                    onEscape={(ev) => {
                        log("Custom onEscape Called")
                    }}
                    onClear={(ev) => {
                        log("Custom onClear Called")
                    }}
                    onChange={(_, newValue) =>
                        log("SearchBox onChange fired: " + newValue)
                    }
                    onSearch={(newValue) => this.props.searchForLearningPlan(newValue)}
                />

                { this.state.showLearningPlanSearchSpinner && 
                    <Spinner
                        id="add-cohort-to-learning-plan-search-spinner"
                        label={this.i18n.get(i18nKey.spinnerSearching)}
                        ariaLive="assertive"
                        labelPosition="right"
                    />
                }

                {learningPlanSearchResult}
                <DestructiveButton
                    id="remove-learning-plan-primary-button"
                    text={this.i18n.get(i18nKey.removeSelected)}
                    onClick={this.removeLearningPlanPrimaryButtonClicked}
                    disabled={this.state.selectedLearningPlansPrimaryButtonDisabled}
                    hidden={this.state.showRemoveLearningPlanSpinner}
                />

                { this.state.showRemoveLearningPlanSpinner && 
                    <Spinner
                        id="remove-learning-plan-spinner"
                        label="Removing"
                        ariaLive="assertive"
                        labelPosition="right"
                    />
                }
            </Stack>
        )
    }

    private dismissErrorBanner = () => {
		this.setState({ errorMessage: null });
	}

    private dismissSuccessBanner = () => {
		this.setState({ successMessage: undefined })
	}

    onSelectedLearningPlanRowsChange = (selectedRowState: {
		allSelected: boolean
		selectedCount: number
		selectedRows: { [key: string]: any }[]
	}) => {
		if (selectedRowState.selectedCount === 0) {
			this.setState({ selectedLearningPlans: [] }, () => {
				this.enableRemoveLearningPlanPrimaryButtonIfNeeded()
			})
			return
		}

        const selectedIds = selectedRowState.selectedRows.map((row) => {
			return row.id
		})

		log(selectedIds.length + " learning plans selected")

		this.setState({ selectedLearningPlans: selectedIds }, () => {
			this.enableRemoveLearningPlanPrimaryButtonIfNeeded()
		})
	}

    onSelectedLearningPlanSearchRowChange = (selectedRowState: {
		allSelected: boolean
		selectedCount: number
		selectedRows: { [key: string]: any }[]
	}) => {
		if (selectedRowState.selectedCount === 0) {
			this.setState({ selectedLearningPlansToAdd: [] }, () => {
				this.enableSearchLearningPlanPrimaryButtonIfNeeded()
			})
			return
		}

        const selectedIds = selectedRowState.selectedRows.map((row) => {
			return row.id
		})

		log(selectedIds.length + " learning plans selected to add")

		this.setState({ selectedLearningPlansToAdd: selectedIds }, () => {
			this.enableSearchLearningPlanPrimaryButtonIfNeeded()
		})
	}

    enableSearchLearningPlanPrimaryButtonIfNeeded = () => {
		this.setState({
            addLearningPlansPrimaryButtonDisabled: !(this.state.selectedLearningPlansToAdd.length > 0)
		})
	}

    enableRemoveLearningPlanPrimaryButtonIfNeeded = () => {
		let disable = true

		if (this.state.selectedLearningPlans.length > 0) {
			disable = false
		}

		this.setState({ selectedLearningPlansPrimaryButtonDisabled: disable })
	}

    searchLearningPlanPrimaryButtonClicked = async (_: MouseEvent<HTMLElement>) => {
		this.setState({
            errorMessage: null,
			successMessage: undefined,
			showLearningPlanAddSpinner: true,
			addLearningPlansPrimaryButtonDisabled: true
		})

        this.props.searchButtonClicked(this.state.selectedLearningPlansToAdd)
            .then(message => {
                this.setState({
                    successMessage: message,
                    showLearningPlanAddSpinner: false,
                    addLearningPlansPrimaryButtonDisabled: true,
                    selectedLearningPlansToAdd: []
                })
            })
            .catch(error => {
                this.setState({
                    errorMessage: error.message,
                    showLearningPlanAddSpinner: false,
                    selectedLearningPlansPrimaryButtonDisabled: false
                })
            })   
	}

    removeLearningPlanPrimaryButtonClicked = async (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: null,
			successMessage: undefined,
            selectedLearningPlansPrimaryButtonDisabled: true,
            showRemoveLearningPlanSpinner: true
		})

        this.props.removeButtonClicked(this.state.selectedLearningPlans)
            .then(message => {
                this.setState({
                    successMessage: message,
                    showRemoveLearningPlanSpinner: false,
                    selectedLearningPlansPrimaryButtonDisabled: true,
                    selectedLearningPlans: [],
                    clearSelectedLearningPlanRows: true
                })
            })
            .catch(error => {
                this.setState({
                    errorMessage: error.message,
                    showRemoveLearningPlanSpinner: false,
                    selectedLearningPlansPrimaryButtonDisabled: false
                })
            })
    }
}