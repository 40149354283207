import { Component } from "react"
import { Text } from "@fluentui/react"
import { ChartData } from "../models/ChartData"
import { Theme } from "./Theme"

export interface DurationReportMetricProps {
    data: ChartData[]
}

export class DurationReportMetric extends Component<DurationReportMetricProps> {
    render() {
        const value: number = this.props.data.length > 0 ? this.props.data[0].value : 0
        const minString: string = value !== 1 ? "mins" : "min"

        return (
            <Text id="single-digit-metric" style={Theme.singleDigitMetricStyle}>
                {`${value.toFixed(0)} ${minString}`}
            </Text>
        )
    }
}
