import { IStackTokens, IStackStyles, IImageStyles, ITextStyles, IIconStyles } from "@fluentui/react"

export class LearningPlanGridItemStyle {
    static stackTokens: IStackTokens = {
        padding: '1.25rem',
    }
    
    static containerStyle: IStackStyles = {
        root: {
            width: '275px',
            height: '325px',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px #eeeeee',
            cursor: 'pointer',
            backgroundColor: '#ffffff',
        }
    }
    
    static headerStyle: IStackStyles = {
        root: {
            height: '60%',
        },
    }
    
    static imageStyle: IImageStyles = {
        image: {},
        root: {
            height: '100%',
            width: '100%',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px'
        }
    }
    
    static bodyStyle: IStackStyles = {
        root: {
            height: '20%',
        },
    }
    
    static footerStyle: IStackStyles = {
        root: {
            height: '20%',
            justifyContent: 'space-between'
        },
    }

    static secondCard = {
        root: {
            zIndex: 2
        }
    }

    static thirdCard = {
        root: {
            zIndex: 3
        }
    }

    static deadlineStyle: ITextStyles = {
        root: {
            color: 'red'
        }
    }

    static completedIcon: IIconStyles = {
        root: {
            color: 'green',
        }
    }

    static completedDiv = {
        paddingLeft: '0.625rem'
    }
}