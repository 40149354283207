import { LRSStatement, LRSCompletionStatement } from './../models/LRSStatement'
import { injectable } from 'inversify'
import { container } from '../DIContainer'
import { TYPES } from '../Types'
import { Dictionary, DocumentId, LrsId } from './../global/TypeAliases'
import { ILearningPlanService } from './LearningPlanService'
import { ILRSService } from './LRSService'
import { IAuth } from '../auth/Auth'
import { ContentType, IContent } from '../models/IContent'
import User from '../models/User'
import { LearningPlan } from '../models/LearningPlan'
import { IUserService } from './UserService'

export interface ILearningPlanContentData {
    /**
     * The contents of the learning plan
     */
    contents: IContent[]

    /**
     * A map consisting of the content document id and a boolean
     * to indicate if it has been completed or not
     * e.g. { 'content1Id': true } <-- A completed content
     *      { 'content2Id': false } <-- A incomplete content
     */
    completedContentIds: {[key: string]: boolean}

    /**
     * True if the first content has progress, False if no progress
     */
    isResumingLearningPlan: boolean

    /**
     * The next content to resume when the learner hits 'Resume' button
     * in the learning plan
     */
    nextContentToResume: IContent | null

    /**
     * A map consisting of the content document id and a number
     * to indicate content progress
     * e.g. { 'content1Id': 100 } <-- A completed content
     * e.g. { 'content2Id': 50 }  <-- A partially completed content
     */
    progressedContentIds: {[key: string]: number}

    /**
     * The document ID of the learning plan
     */
    learningPlanDocumentId: DocumentId
}


export interface ILearningPlanCompositionService {
    getLearningPlanData(learningPlanId: DocumentId, userClientIds: DocumentId[]): Promise<ILearningPlanContentData>
    getLearningPlanCompletionData(): Promise<Dictionary>
}

@injectable()
export class LearningPlanCompositionService implements ILearningPlanCompositionService {
    private learningPlanService: ILearningPlanService = container.get<ILearningPlanService>(TYPES.ILearningPlanService)
    private lrsService: ILRSService = container.get<ILRSService>(TYPES.ILRSService)
    private auth: IAuth = container.get<IAuth>(TYPES.IAuth)
    private userService: IUserService = container.get<IUserService>(TYPES.IUserService)

    getLearningPlanData(learningPlanId: DocumentId, userClientIds: DocumentId[]): Promise<ILearningPlanContentData> {
        const user = this.auth.user
        if (!user) {
            return Promise.reject(new Error("Invalid user"))
        }

        return new Promise((resolve, reject) => {
            this.learningPlanService.getLearningPlanByDocumentId(learningPlanId)
                .then(learningPlan => {
                    if (learningPlan.contents.length === 0 || userClientIds.length === 0) {
                        resolve(this.getBaseData(learningPlan.contents));
                        return
                    }

                    const clientIds = this.getCommonClientIds(userClientIds, learningPlan.clients)
                    let completionPromises = this.getCompletionPromises(clientIds, user, learningPlan.contents)
                    let progressionPromises = this.getProgressionPromises(clientIds, user, learningPlan.contents)

                    return Promise.all([Promise.resolve(learningPlan.contents), completionPromises, progressionPromises].flat())
                }).then(results => {
                    if (!results) {
                        resolve(this.getBaseData([]))
                        return
                    }

                    const contents: IContent[] = results.shift()
                    const statements = results.flat()
                    const statementData = this.getLearningPlanStatementDataFrom(learningPlanId, contents, statements)

                    const data: ILearningPlanContentData = {
                        contents: contents,
                        completedContentIds: statementData.completedContentIds,
                        isResumingLearningPlan: statementData.isResumingLearningPlan,
                        nextContentToResume: statementData.nextContentToResume,
                        progressedContentIds: statementData.progressedContentIds,
                        learningPlanDocumentId: learningPlanId
                    }
                    
                    resolve(data)
                }).catch(error => {
                    reject(error)
                })
            })
    }

    getLearningPlanCompletionData(): Promise<Dictionary> {
        const user = this.auth.user
        if (!user) {
            return Promise.reject(new Error("Invalid user"))
        }

        const promises: Promise<any>[] = [
            this.userService.getClientsForUser(user.cohorts!), 
            this.learningPlanService.learningPlansForCurrentUser()
        ]

        return new Promise((resolve, reject) => {
            Promise.all(promises).then(results => { 
                const userClientIds: DocumentId[] = results[0]
                const learningPlans: LearningPlan[] = results[1]

                if (!userClientIds) {
                    let error = Error("Failed to fetch user client ids")
                    reject(error)
                }

                if (!learningPlans) {
                    let error = Error("Failed to fetch learning plans")
                    reject(error)
                }

                return { userClientIds, learningPlans }
            }).then(result => {
                if (result.userClientIds.length === 0 || result.learningPlans.length === 0) {
                    resolve({})
                    return
                }
                
                const learningPlanPromises = result.learningPlans.map(learningPlan => {
                    return this.getLearningPlanData(learningPlan.documentId, result.userClientIds)
                })
                
                return Promise.all(learningPlanPromises)
            }).then(results => {
                if (!results) {
                    reject(new Error("Failed to fetch learning plan contents"))
                    return
                }

                let learningPlanCompletionMap: Dictionary = {}
                results.forEach(result => {
                    learningPlanCompletionMap[result.learningPlanDocumentId] = Object.values(result.completedContentIds).every(content => content === true)
                })
                
                resolve(learningPlanCompletionMap)
            }).catch(error => {
                reject(error)
            })
        })
    }

    /**
     * Private Functions
     */
    private getBaseData(contents: IContent[]): ILearningPlanContentData {
        let nextContent: IContent | null = null

        if (contents.length > 0) {
            nextContent = contents[0]
        }

        let data: ILearningPlanContentData = {
            contents: contents,
            completedContentIds: {},
            isResumingLearningPlan: false,
            nextContentToResume: nextContent,
            progressedContentIds: {},
            learningPlanDocumentId: ""
        }
        
        return data
    }

    /**
     * Gets content completions and progress data from LRSStatements and LRSCompletionStatements 
     * @param learningPlanId The documentId of the learning plan
     * @param contents An array of learning plan contents
     * @param statements LRS Statements
     * @returns ILearningPlanContentData
     */
    private getLearningPlanStatementDataFrom(learningPlanId: DocumentId, contents: IContent[], statements: any): ILearningPlanContentData {
        let completionIds: Set<LrsId> = new Set()
        let firstContentId = contents[0].lrsId
        let isResumingLearningPlan = false
        let progressedContents: Dictionary = {}

        statements.forEach((statement: any) => {
            if (statement instanceof LRSStatement) {
                if (statement.completed) {
                    completionIds.add(statement.activityId)

                    if (statement.activityId === firstContentId) {
                        isResumingLearningPlan = true
                    }

                } else if (statement.progress && statement.progress < 100) {
                    progressedContents[statement.activityId] = statement.progress
                } else if (statement.progress && statement.progress === 100) {
                    completionIds.add(statement.activityId)
                }

                if (statement.activityId === firstContentId && statement.progress) {
                    isResumingLearningPlan = statement.progress > 0
                }

            } else if (statement instanceof LRSCompletionStatement && statement.data !== undefined) {
                completionIds.add(statement.data.activityId)

                if (statement.data.activityId === firstContentId ) {
                    isResumingLearningPlan = true
                }
            } 
        })

        const completedContentsMap = this.mapCompletedContentsWith(completionIds, contents)
        const progressedContentsMap = this.mapProgressedContentsWith(progressedContents, contents, completionIds)

        const data: ILearningPlanContentData = {
            contents: contents,
            completedContentIds: completedContentsMap,
            isResumingLearningPlan: isResumingLearningPlan,
            nextContentToResume: this.getNextContentToResume(contents, completionIds),
            progressedContentIds: progressedContentsMap,
            learningPlanDocumentId: learningPlanId
        }

        return data
    }

    /**
     * Return the client ids that both the user and learning plan are a part of
     * @param userClientIds The clients the user belongs to
     * @param learningPlanClientIdsMap The clients the learning plan belongs to
     * @returns 
     */
    private getCommonClientIds(userClientIds: DocumentId[], learningPlanClientIdsMap: Dictionary): DocumentId[] {
        const learningPlanClientIds: DocumentId[] = Object.keys(learningPlanClientIdsMap)
		return userClientIds.filter(id => learningPlanClientIds.includes(id))
	}

    private getProgressionPromises(clientIds: DocumentId[], user: User, contents: IContent[]): Promise<any>[] {
        let promises: Promise<any>[] = []

        clientIds.forEach(clientId => {
            contents.forEach(content => {
                switch (content.contentType()) {
                    case ContentType.course:
                        const coursesPromise = this.lrsService.getProgressionOnCourseForUser(user.uid, clientId, content.lrsId)
                        promises.push(coursesPromise)
                        break
                    case ContentType.survey:
                        const surveysPromise = this.lrsService.getProgressionOnSurveyForUser(user.uid, clientId, content.lrsId)
                        promises.push(surveysPromise)
                        break
                    default:
                        break
                }
            })
        })

        return promises
    }

    private getCompletionPromises(clientIds: DocumentId[], user: User, contents: IContent[]): Promise<any>[] {
        let promises: Promise<any>[] = []
        clientIds.forEach(clientId => {
            contents.forEach(content => {
                let promise: Promise<any> | null = null
                switch (content.contentType()) {
                    case ContentType.course:
                        promise = this.lrsService.getOldestPassForCourse(user.uid, clientId, content.lrsId)
                        break
                    case ContentType.survey:
                        promise = this.lrsService.getSurveyCompletionForUser(user, content.lrsId, clientId)
                        break
                }

                if (promise) {
                    promises.push(promise)
                }
            })
        })

        return promises
    }

    private mapCompletedContentsWith(completionIds: Set<LrsId>, contents: IContent[]): Dictionary {
        let completionData: {[key: string]: boolean} = {}

        contents.forEach(content => {
            completionData[content.documentId] = completionIds.has(content.lrsId)
        })

        return completionData
    }

    private mapProgressedContentsWith(progressedIds: Dictionary, contents: IContent[], completionIds: Set<LrsId>): Dictionary {
        let progressionData: {[key: string]: number} = {}
   
        contents.forEach(content => {
            // if the content lrsId is in the set of completed content Ids
            if (completionIds.has(content.lrsId)) {            
                // map the content to progressionData as having 100 progress
                progressionData[content.documentId] = 100
                // if there is a content lrsId
            } else if (progressedIds[content.lrsId] !== undefined) {
                // map content progress as the statement progress
                progressionData[content.documentId] = progressedIds[content.lrsId]
                // else, there is no lrsId, so there is 0 content progress
            } else {
                progressionData[content.documentId] = 0
            }
        })

        return progressionData
    }

    private getNextContentToResume(contents: IContent[], completionIds: Set<LrsId>): IContent | null {
        if (contents.length === 0) {
            return null
        }

        let nextContent: IContent | null = null
        
        contents.forEach(content => {
            if (nextContent) {
                return
            }

            const found = completionIds.has(content.lrsId)

            if (!found) {
                nextContent = content
            }
        })

        if (!nextContent) {
            nextContent = contents[0]
        }

        return nextContent
    }
}