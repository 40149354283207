import { IFirebase } from "./Firebase"
import Client from "../models/Client"
import Cohort from "../models/Cohort"
import { injectable } from "inversify"
import { container } from "../DIContainer"
import { TYPES } from "../Types"
import { log } from '../global/Logger';
import { ClientParser } from '../models/ClientParser';
import { Dictionary } from "../global/TypeAliases"
import { IEventReportingService } from '../services/EventReportingService'

export interface IClientService {
	/**
	 * Get all clients
	 * @return An array of Clients
	 */
	clients(): Promise<Client[]>

	/**
	 * Get a client by id
	 * @param name The client document id
	 * @return The client
	 */
	clientForId(clientId: string): Promise<Client>

	/**
	 * Get a client by Clever district id
	 * @param districtId The Clever district id
	 * @return The client
	 */
	clientWithCleverDistrictId(districtId: string): Promise<Client | null>

	/**
	 * Add a new client
	 * @param name The client name
	 * @param description The client description
	 * @param domain The domain of the client. Generally derived from
	 * the email address of learners e.g. 'bob@isd.com' would result in a domain
	 * value of 'isd'
	 * @param cleverDistrictId The clever district id of the client.
	 * @param active The status of the client.
	 * @return The new client
	 */
    addClient(
		name: string, 
		description: string, 
		domain: string, 
		cleverDistrictId: string, 
		active: boolean
	): Promise<Client>
    
    /**
	 * Update client details
     * @param documentId The client document id
	 * @param name The client name
	 * @param description The client description
	 * @param cleverDistrictId The clever district id
	 * @param active The status of the client.
	 */
	updateClient(documentId: string, name: string, description: string, cleverDistrictId: string, active: boolean): Promise<void>

	/**
	 * Update client details
     * @param clientDocumentId The client document id
	 * @param licenseSeatCount The amount of seats allocated to the license.
     * @param validUntilDate The date of when the license is set to expire.
     * @param licenseNotes Notes by the admin about the client's license.
	 */
	 updateClientLicenseInfo(clientDocumentId: string, licenseSeatCount: number | undefined, validUntilDate: Date | undefined, licenseNotes: string | undefined): Promise<void>
	
	/**
     * Add cohorts to a client
     * @param {*} cohortDocumentIds The cohort document ids
     * @param {*} clientDocumentId The client document id
     */
	addCohortsToClient(cohortDocumentIds: string[], clientDocumentId: string): Promise<void>

	/**
	 * Assign a default cohort for a client
	 * @param cohortDocumentId The cohort document id
	 * @param clientId The client id
	 */
	assignCohortAsDefault(cohortDocumentId: string, clientId: string): Promise<void>

	/**
     * Remove cohorts from a client
     * @param {*} cohortDocumentIds The cohort document ids
     * @param {*} clientDocumentId The client document id
     */
	removeCohortsFromClient(cohortDocumentIds: string[], clientDocumentId: string): Promise<void>

	/**
	 * Get all cohorts belonging to the client document id
	 * @param clientDocumentId The client document id
	 */
	getCohortsForClient(clientDocumentId: string): Promise<Cohort[]>
}

@injectable()
export default class ClientService implements IClientService {
	private firebase = container.get<IFirebase>(TYPES.IFirebase)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)

	clients(): Promise<Client[]> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection("client")
				.withConverter(Client.Converter)
				.get()
				.then((querySnapshot) => {
					log("Success: ClientService - clients()")

					let clients: Client[] = []
					querySnapshot.forEach((doc) => {
						let client = doc.data()
						client.documentId = doc.id
						clients.push(client)
					})

					resolve(clients)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("Error: ClientService - clients() - " + error)
					reject(error)
				})
		})
	}

	clientForId(clientId: string): Promise<Client> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection("client")
				.doc(clientId)
				.withConverter(Client.Converter)
				.get()
				.then((documentSnapshot) => {

					const client: Client | undefined = documentSnapshot.data()

					if (client === undefined) {
						throw new Error("No client in document snapshot")
					}

					client.documentId = documentSnapshot.id
					
					log("Success: ClientService - clientForId()")
					resolve(client)
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("Error: ClientService - clientForId() - " + error)
					reject(error)
				})
		})
	}

	/* istanbul ignore next */
	clientWithCleverDistrictId(districtId: string): Promise<Client | null> {
		return new Promise((resolve, reject) => {
            let getClient = this.firebase.app.functions().httpsCallable('clientWithCleverDistrictId')

            getClient({ districtId: districtId }).then(result => {
				log("ClientService - clientWithCleverDistrictId - Success")

				const data = result.data.documentSnapshot
				const documentId = result.data.documentId
				
				if (!data) {
					resolve(null)
				} else {
					const parser = new ClientParser()
					const client = parser.parse(documentId, data)
	
					resolve(client)
				}

            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                log("ClientService - clientWithCleverDistrictId - Failed: " + error)
                reject(error)
            })
		})
	}

	/* istanbul ignore next */
	addClient(
		name: string, 
		description: string, 
		domain: string, 
		cleverDistrictId: string, 
		active: boolean): Promise<Client> {
		let domains: Dictionary = {}
		domains[domain] = true

		return new Promise((resolve, reject) => {
			let add = this.firebase.app.functions().httpsCallable('addClient')

			add({
				name: name,
				description: description,
				domains: domains,
				cleverDistrictId: cleverDistrictId,
				active: active
			}).then(result => {
				log("ClientService - addClient - Success")				
				const parser = new ClientParser()
				const client = parser.parse(result.data.documentId, result.data)

				resolve(client)
			}).catch(error => {
                this.eventReportingService.error(error.message, error)
				log("ClientService - addClient - Failed: " + error)
				reject(error)
			})
		})
    }
    
    updateClient(
		documentId: string, 
		name: string, 
		description: string, 
		cleverDistrictId: string, 
		active: boolean): Promise<void> {
		let data: Dictionary = {
			name: name,
			description: description,
			cleverDistrictId: cleverDistrictId,
			active: active
		}
		
		return new Promise((resolve, reject) => {
			this.firebase.db
                .collection("client")
                .doc(documentId)
				.update(data)
				.then(() => {
					log("Success: ClientService - updateClient()")
					resolve()
				})
				.catch(error => {
                	this.eventReportingService.error(error.message, error)
					log("Error: ClientService - updateClient() - " + error)
					reject(error)
				})
		})
	}

	updateClientLicenseInfo(
		clientDocumentId: string, 
		licenseSeatCount: number | undefined, 
		validUntilDate: Date | undefined, 
		licenseNotes: string | undefined
		): Promise<void> {
			let data: Dictionary = {
				licenseSeatCount: licenseSeatCount,
				validUntilDate: validUntilDate,
				licenseNotes: licenseNotes
			}

			return new Promise((resolve, reject) => {
				this.firebase.db
					.collection("client")
					.doc(clientDocumentId)
					.update(data)
					.then(() => {
						log("Success: ClientService - updateClientLicenseInfo()")
						resolve()
					})
					.catch(error => {
						this.eventReportingService.error(error.message, error)
						log("Error: ClientService - updateClientLicenseInfo() - " + error)
						reject(error)
					})
			})
		}
	
	/* istanbul ignore next */
	addCohortsToClient(cohortDocumentIds: string[], clientDocumentId: string): Promise<void> {
		var cohortMap: {[key: string]: boolean} = {}

		cohortDocumentIds.forEach(documentId => {
			cohortMap[documentId] = true
		})

        var clientMap: {[key: string]: boolean} = {}
        clientMap[clientDocumentId] = true

		return new Promise((resolve, reject) => {
            let add = this.firebase.app.functions().httpsCallable('addCohortsToClient')

            add({ clientDocumentId: clientDocumentId, cohorts: cohortMap, clients: clientMap }).then(function (result) {
				log("ClientService - addCohortsToClient() - Success")
                resolve()
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                log("ClientService - addCohortsToClient() - Failed: " + error)
                reject(error)
            })
        })
	}

	assignCohortAsDefault(cohortDocumentId: string, clientId: string): Promise<void> {
		return new Promise((resolve, reject) => {
			this.firebase.db.collection("client").doc(clientId)
			.update({
				defaultCohort: cohortDocumentId
			})
			.then(() => {
				this.firebase.db.collection("cohort").doc(cohortDocumentId)
				.update({
					isDefault: true
				})
                log("ClientService - assignCohortAsDefault() - Success")
                resolve()
			})
            .catch(error => {
				this.eventReportingService.error(error.message, error)
				log("ClientService - assignCohortAsDefault() - Failed: " + error)
				reject(error)
			})
		})
	}

	/* istanbul ignore next */
	removeCohortsFromClient(cohortDocumentIds: string[], clientDocumentId: string): Promise<void> {
        var cohortMap: {[key: string]: boolean} = {}

		cohortDocumentIds.forEach(documentId => {
			cohortMap[documentId] = true
		})

        return new Promise((resolve, reject) => {
            let remove = this.firebase.app.functions().httpsCallable('removeCohortsFromClient')

            remove({ cohorts: cohortMap, clientDocumentId: clientDocumentId }).then(function (result) {
                log("CohortService - removeCohortsFromClient() - Success")
                resolve()
            }).catch(error => {
               	this.eventReportingService.error(error.message, error)
                log("CohortService - removeCohortsFromClient() - Failed: " + error)
                reject(error)
            })
        })
	}

	getCohortsForClient(clientDocumentId: string): Promise<Cohort[]> {
        return new Promise((resolve, reject) => {
            this.firebase.db.collection('cohort')
                .withConverter(Cohort.Converter)
                .where(`clients.${clientDocumentId}`, '==', true)
                .get()
                .then((querySnapshot) => {
                    var result: Cohort[] = []
                    querySnapshot.forEach(function (doc) {
                        let item: Cohort = doc.data()
                        item.documentId = doc.id
                        result.push(item)
                    })

					log("ClientService - getCohortsForClient() - Success")
                    resolve(result)
                }).catch(error => {
               		this.eventReportingService.error(error.message, error)
					log("ClientService - getCohortsForClient() - Failed")
                    reject(error)
                })
        })
    }
}
