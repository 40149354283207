import React, { Component } from 'react'
import { Stack } from '@fluentui/react'

import { MarketingIi18n, i18nKey } from '../global/Marketing-i18n'
import { container } from '../DIContainer'
import { TYPES } from '../Types'

import { TestimonialCard } from './TestimonialCard'
import { TestimonialStyles } from './TestimonialStyles'
import './Testimonials.css'

import { MarketingButton } from './MarketingButton'

import BellevueSchool_Logo from '../images/marketing/testimonial-bellevueSchool-logo.jpg'
import Office365_Logo from '../images/marketing/testimonial-office365-logo.jpg'
import Issaquah_Logo from '../images/marketing/testimonial-issaquah-logo.jpg'
import NorthShore_Logo from '../images/marketing/testimonial-northshore-logo.jpg'
import RentonDistrict_Logo from '../images/marketing/testimonial-renton-logo.jpg'
import SeattlePublicSchools_Logo from '../images/marketing/testimonial-sps-logo.jpg'

interface TestimonialsProps {}

interface TestimonialsState {
    isMobileView: boolean
    showMoreTestimonials: boolean
}

export class Testimonials extends Component<TestimonialsProps, TestimonialsState> {
    private i18n = container.get<MarketingIi18n>(TYPES.MarketingIi18n)

    state: TestimonialsState = {
        isMobileView: false,
        showMoreTestimonials: false,
    }

    componentDidMount() {
        document.addEventListener('scroll', () => this.handleScroll)
        window.addEventListener('resize', this.checkWindowChange)

        this.checkWindowChange()
        this.handleScroll()
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', () => this.handleScroll)
        window.removeEventListener('resize', this.checkWindowChange)
    }

    render() {
        return (
            <Stack horizontal wrap styles={TestimonialStyles.outerStackStyles}>
                <Stack styles={TestimonialStyles.innerStackStyles}>
                    <Stack className='anim' id='testimonialstack-card1' styles={TestimonialStyles.cardStackStyles}>
                        <TestimonialCard
                            Title={this.i18n.get(i18nKey.testimonialBellevueSchoolCardTitle2)}
                            Text={`"${this.i18n.get(i18nKey.testimonialBellevueSchoolCardText2)}"`}
                            Logo={BellevueSchool_Logo}
                        />
                    </Stack>

                    <Stack className='anim' id='testimonialstack-card2' styles={TestimonialStyles.cardBigLogoStackStyles}>
                        <TestimonialCard
                            Title={this.i18n.get(i18nKey.testimonialSeattlePublicSchoolCardTitle1)}
                            Text={`"${this.i18n.get(i18nKey.testimonialSeattlePublicSchoolCardText1)}"`}
                            Logo={SeattlePublicSchools_Logo}
                        />
                    </Stack>

                    <Stack className='anim' id='testimonialstack-card3' styles={TestimonialStyles.cardStackStyles}>
                        <TestimonialCard
                            Title={this.i18n.get(i18nKey.testimonialNorthshoreSchoolCardTitle1)}
                            Text={`"${this.i18n.get(i18nKey.testimonialNorthshoreSchoolCardText1)}"`}
                            Logo={NorthShore_Logo}
                        />
                    </Stack>

                    <Stack className='anim' id='testimonialstack-card4' styles={TestimonialStyles.cardStackStyles}>
                        <TestimonialCard
                            Title={this.i18n.get(i18nKey.testimonialMicrosoftCardTitle2)}
                            Text={`"${this.i18n.get(i18nKey.testimonialMicrosoftCardText2)}"`}
                            Logo={Office365_Logo}
                        />
                    </Stack>
                </Stack>

                {this.state.isMobileView && !this.state.showMoreTestimonials && (
                    <MarketingButton
                        text={this.i18n.get(i18nKey.seeMoreTestimonials).toUpperCase()}
                        width='0vw'
                        mobileWidth='100%'
                        onClick={this.showAllTestimonialsMobileView}
                    />
                )}

                {(!this.state.isMobileView || this.state.showMoreTestimonials) && (
                    <>
                        <Stack styles={TestimonialStyles.innerStackStyles}>
                            <Stack className='anim' id='testimonialstack-card5' styles={TestimonialStyles.cardStackStyles}>
                                <TestimonialCard
                                    Title={this.i18n.get(i18nKey.testimonialNorthshoreSchoolCardTitle2)}
                                    Text={`"${this.i18n.get(i18nKey.testimonialNorthshoreSchoolCardText2)}"`}
                                    Logo={NorthShore_Logo}
                                />
                            </Stack>

                            <Stack className='anim' id='testimonialstack-card6' styles={TestimonialStyles.cardBigLogoStackStyles}>
                                <TestimonialCard
                                    Title={this.i18n.get(i18nKey.testimonialRentonSchoolCardTitle1)}
                                    Text={`"${this.i18n.get(i18nKey.testimonialRentonSchoolCardText1)}"`}
                                    Logo={RentonDistrict_Logo}
                                />
                            </Stack>

                            <Stack className='anim' id='testimonialstack-card7' styles={TestimonialStyles.cardStackStyles}>
                                <TestimonialCard
                                    Title={this.i18n.get(i18nKey.testimonialBellevueSchoolCardTitle3)}
                                    Text={`"${this.i18n.get(i18nKey.testimonialBellevueSchoolCardText3)}"`}
                                    Logo={BellevueSchool_Logo}
                                />
                            </Stack>

                            <Stack className='anim' id='testimonialstack-card8' styles={TestimonialStyles.cardStackStyles}>
                                <TestimonialCard
                                    Title={this.i18n.get(i18nKey.testimonialIssaquahSchoolCardTitle1)}
                                    Text={`"${this.i18n.get(i18nKey.testimonialIssaquahSchoolCardText1)}"`}
                                    Logo={Issaquah_Logo}
                                />
                            </Stack>

                            <Stack className='anim' id='testimonialstack-card9' styles={TestimonialStyles.cardBigLogoStackStyles}>
                                <TestimonialCard
                                    Title={this.i18n.get(i18nKey.testimonialRentonSchoolCardTitle2)}
                                    Text={`"${this.i18n.get(i18nKey.testimonialRentonSchoolCardText2)}"`}
                                    Logo={RentonDistrict_Logo}
                                />
                            </Stack>
                        </Stack>

                        <Stack styles={TestimonialStyles.innerStackStyles}>
                            <Stack className='anim' id='testimonialstack-card10' styles={TestimonialStyles.cardStackStyles}>
                                <TestimonialCard
                                    Title={this.i18n.get(i18nKey.testimonialMicrosoftCardTitle3)}
                                    Text={`"${this.i18n.get(i18nKey.testimonialMicrosoftCardText3)}"`}
                                    Logo={Office365_Logo}
                                />
                            </Stack>

                            <Stack className='anim' id='testimonialstack-card11' styles={TestimonialStyles.cardStackStyles}>
                                <TestimonialCard
                                    Title={this.i18n.get(i18nKey.testimonialIssaquahSchoolCardTitle2)}
                                    Text={`"${this.i18n.get(i18nKey.testimonialIssaquahSchoolCardText2)}"`}
                                    Logo={Issaquah_Logo}
                                />
                            </Stack>

                            <Stack className='anim' id='testimonialstack-card12' styles={TestimonialStyles.cardBigLogoStackStyles}>
                                <TestimonialCard
                                    Title={this.i18n.get(i18nKey.testimonialRentonSchoolCardTitle3)}
                                    Text={`"${this.i18n.get(i18nKey.testimonialRentonSchoolCardText3)}"`}
                                    Logo={RentonDistrict_Logo}
                                />
                            </Stack>

                            <Stack className='anim' id='testimonialstack-card13' styles={TestimonialStyles.cardStackStyles}>
                                <TestimonialCard
                                    Title={this.i18n.get(i18nKey.testimonialNorthshoreSchoolCardTitle3)}
                                    Text={`"${this.i18n.get(i18nKey.testimonialNorthshoreSchoolCardText3)}"`}
                                    Logo={NorthShore_Logo}
                                />
                            </Stack>

                            <Stack className='anim' id='testimonialstack-card14' styles={TestimonialStyles.cardStackStyles}>
                                <TestimonialCard
                                    Title={this.i18n.get(i18nKey.testimonialBellevueSchoolCardTitle4)}
                                    Text={`"${this.i18n.get(i18nKey.testimonialBellevueSchoolCardText4)}"`}
                                    Logo={BellevueSchool_Logo}
                                />
                            </Stack>
                        </Stack>
                    </>
                )}
            </Stack>
        )
    }

    handleScroll = (observerClass = IntersectionObserver) => {
        const card = document.querySelectorAll('.anim')

        let observer = new observerClass((entries) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) {
                    return
                } else {
                    entry.target.classList.add('appear')
                    observer.unobserve(entry.target)
                }
            })
        })

        card.forEach((card) => {
            observer.observe(card)
        })
    }

    checkWindowChange = () => {
        if (window.innerWidth <= 600) this.setState({ isMobileView: true })
        else this.setState({ isMobileView: false })

        setTimeout(() => {
            /* istanbul ignore next */
            this.handleScroll()
        }, 100)
    }

    showAllTestimonialsMobileView = () => {
        this.setState({ showMoreTestimonials: true })

        setTimeout(() => {
            /* istanbul ignore next */
            this.handleScroll()
        }, 100)
    }
}
