import { LearningPlan } from "./LearningPlan";
import { injectable } from "inversify";
import { Dictionary, DocumentId } from "../global/TypeAliases";
import { ICourseParser } from "./CourseParser";
import { ISurveyParser } from "./SurveyParser";
import { ContentType, IContent } from "./IContent";

export interface ILearningPlanParser {
	parse(id: DocumentId, data: Dictionary, courseParser: ICourseParser, surveyParser: ISurveyParser): LearningPlan
}

@injectable()
export class LearningPlanParser implements ILearningPlanParser {
	parse(id: DocumentId, data: Dictionary, courseParser: ICourseParser, surveyParser: ISurveyParser): LearningPlan {
		return new LearningPlan(
                  id,
                  data.name,
                  data.description,
                  data.clients === undefined ? {} : data.clients,
                  data.cohorts === undefined ? {} : data.cohorts,
                  data.trainings === undefined ? [] : data.trainings,
                  data.contents === undefined ? [] : this.parseContent(data.contents, courseParser, surveyParser)
		)
	}

    parseContent(contents: [], courseParser: ICourseParser, surveyParser: ISurveyParser) : IContent[] {
        const iContents: IContent[] = []
        contents.forEach((content: Dictionary)  => {
            if (content.contentType === ContentType.course) {
                iContents.push(courseParser.parse(content.documentId, content))
            }
            else if(content.contentType === ContentType.survey) {
                iContents.push(surveyParser.parse(content.documentId, content))
            }
        })
        return iContents;
    }
}
