import { LRSStatement, ExperienceType, ActivityType } from "./LRSStatement";
import { injectable } from "inversify";
import { Duration } from "luxon";
import { Dictionary } from "../global/TypeAliases";
import { LaunchLocations } from "./TrainingLaunchLocation";
import { LRSLaunchStatement } from "./LRSLaunchStatement";

export interface ILRSStatementParser {
    parseStatement(data: Dictionary): LRSStatement
    parseAttemptStatement(data: Dictionary): LRSStatement
    parseLaunchLocationStatement(data: Dictionary): LRSLaunchStatement
}

@injectable()
export class LRSStatementParser implements ILRSStatementParser {
    parseStatement(data: Dictionary): LRSStatement {   
        const id = data.id
        const timestamp = new Date(data.timestamp)
        const courseId = data.object.id
        const clientId = data.actor.name
        const userId = data.actor.account.name
        const activityType = this.getActivityType(data.object.definition.type)
        const experienceType = this.getExperienceType(data.verb.id)
        const duration = Duration.fromISO(data.result.duration).as('seconds')

        let completed = data.result.completion
        if (completed === undefined) {
            completed = false
        }
        
        const progress = experienceType === ExperienceType.passed ? 100 : this.getProgress(data.result.extensions)
        
        return new LRSStatement(
            id, 
            timestamp,
            courseId,
            clientId,
            userId,
            activityType,
            experienceType,
            completed,
            progress,
            duration
        )
    }

    parseAttemptStatement(data: Dictionary): LRSStatement {   
        const id = data.id
        const timestamp = new Date(data.timestamp)
        const courseId = data.object.id
        const clientId = data.actor.name
        const userId = data.actor.account.name
        const activityType = this.getActivityType(data.object.definition.type)
        const experienceType = this.getExperienceType(data.verb.id)
        const duration = Duration.fromISO(data.result.duration).as('seconds')
        const completed = true
        
        const progress = experienceType === ExperienceType.passed ? 100 : this.getProgress(data.result.extensions)
        
        return new LRSStatement(
            id, 
            timestamp,
            courseId,
            clientId,
            userId,
            activityType,
            experienceType,
            completed,
            progress,
            duration
        )
    }

    parseLaunchLocationStatement(data: Dictionary): LRSLaunchStatement {
        const id = data.id
        const timestamp = new Date(data.timestamp)
        const courseId = data.object.id
        const clientId = data.actor.name
        const userId = data.actor.account.name
        const activityType = this.getActivityType(data.object.definition.type)
        const launchLocation = this.getLaunchLocation(data.result.response)
        
        return new LRSLaunchStatement(
            id, 
            timestamp,
            courseId,
            clientId,
            userId,
            activityType,
            launchLocation        
        )
    }
    
    private getLaunchLocation(value: string): LaunchLocations {
        let result: LaunchLocations

        switch(value) {
            case LaunchLocations.IndividualTraining:
                result = LaunchLocations.IndividualTraining
                break
            case LaunchLocations.LearningPlan:
                result = LaunchLocations.LearningPlan
                break
            default:
                result = LaunchLocations.IndividualTraining
        }

        return result
    }

    private getExperienceType(value: string): ExperienceType {
        const verb = value.split('/').pop()

        switch (verb) {
            case ExperienceType.passed:
                return ExperienceType.passed
            case ExperienceType.attempted:
                return ExperienceType.attempted
            case ExperienceType.progressed:
                return ExperienceType.progressed
            case ExperienceType.experienced:
                return ExperienceType.experienced    
            default:
                return ExperienceType.unhandled
        }
    }

    private getActivityType(value: string): ActivityType {
        const verb = value.split('/').pop()

        switch (verb) {
            case ActivityType.course:
                return ActivityType.course
            case ActivityType.cModule:
                return ActivityType.cModule
            case ActivityType.survey:
                return ActivityType.survey
            default:
                return ActivityType.unhandled
        }
    }

    private getProgress(value: {[key: string]: number}): number | null {
        if (value === undefined) {
            return null
        }

        const keys = Object.keys(value)
        
        let progress: number | null = null
        
        if (keys.length === 1) {
            progress = value[keys[0]]
        }

        return progress
    }
}