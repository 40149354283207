import { Component } from "react";
import Course from "../../models/Course";
import Iframe from "react-iframe"
import './iFrameContainerPage.css'
import { TYPES } from "../../Types";
import { container } from "../../DIContainer";
import { Loader } from "../../ui-kit/Loader";
import { ILoadTimer, LoadType } from "../../utils/LoadTimer";

interface GuestCourseContainerPageProps {
    course: Course
    guestId: string
}

interface GuestCourseContainerPageState {
    loaded: boolean
}

enum Constant {
    Timeout = 8000 // 8 seconds
}

export class GuestCourseContainerPage extends Component<GuestCourseContainerPageProps, GuestCourseContainerPageState> {
    private timer: NodeJS.Timer | null = null
    private loadTimer: ILoadTimer = container.get<ILoadTimer>(TYPES.ILoadTimer)

    state: GuestCourseContainerPageState = {
        loaded: false
    }

    componentDidMount() {
        // documentElement is the <html> element
        document.documentElement.classList.add('auto-overflow')
        document.getElementById('container')?.classList.add('no-padding', 'no-margin')
        document.getElementById('content')?.classList.add('full-height', 'no-padding')
        document.getElementById('navigation-bar-container')?.classList.add('hidden')
        document.getElementById('navigation-bar')?.classList.add('hidden')

        window.addEventListener("message", this.handleMessage)
        this.startTimeout()
        this.loadTimer.start(LoadType.Course, this.props.course.documentId)
    }

    componentWillUnmount() {
        document.documentElement.classList.remove('auto-overflow')
        document.getElementById('container')?.classList.remove('no-padding', 'no-margin')
        document.getElementById('content')?.classList.remove('full-height', 'no-padding')
        document.getElementById('navigation-bar-container')?.classList.remove('hidden')
        document.getElementById('navigation-bar')?.classList.remove('hidden')

        window.removeEventListener("message", this.handleMessage)

        if (this.timer !== null) {
            clearTimeout(this.timer)
        }
    }

    render() {
        return (
            <>
                { this.state.loaded === false && 
                    <Loader text={`We are preparing the course for you. Please wait.`} />
                }
                
                <Iframe url={this.props.course.locationUrl} 
                    width={this.state.loaded === false ? "0%" : "100%"} 
                    height={this.state.loaded === false ? "0%" : "100%"} 
                    frameBorder={0} 
                    id="course-iframe" 
                    name={this.props.guestId} 
                />
            </>
        )
    }

    handleMessage = (_: any) => {
        this.setState({ loaded: true })
        this.loadTimer.stop(LoadType.Course)
    }

    /**
     * Fail safe for if we don't receive a "loaded" message from the iFrame
     * This timer will remove the loader after a period of time
     */
    startTimeout = () => {
        this.timer = setTimeout(this.timerHandler, Constant.Timeout)
    }

    timerHandler = () => {
        if (this.state.loaded === false) {
            this.setState({ loaded: true })
        }

        if (this.timer !== null) {
            clearTimeout(this.timer)
        }

        this.loadTimer.stop(LoadType.Course)
    }
}