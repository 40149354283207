import { MessageBar, MessageBarType, Stack } from "@fluentui/react"
import DTSTableData from "../../../models/DTSTableData"
import DTSTable from "../../../ui-kit/DTSTable"
import { Component, MouseEvent } from "react"
import { container } from "../../../DIContainer"
import { TYPES } from "../../../Types"
import { SecondaryButton } from "../../../ui-kit/SecondaryButton"
import { TabPanelProps } from "../TabPanelProps"
import { Loader } from "../../../ui-kit/Loader"
import { Survey } from "../../../models/Survey"
import { IRouter } from "../../../Router"
import { i18nKey, Ii18n } from "../../../global/i18n"
import { DocumentId } from "../../../global/TypeAliases"
import { ISurveyService } from "../../../services/SurveyService"
import { IDataTableFactory } from "../../../factories/DataTableFactory"
import { ISurveyFactory } from "./SurveyFactory"
import CancelablePromise from "cancelable-promise"
import { IPromiseHelper } from "../../../services/PromiseHelper"

interface SurveysContentPageProps extends TabPanelProps {
    clientId: DocumentId
}

interface SurveysContentPageState {
    tableData: DTSTableData | undefined
    surveys: Survey[]
    errorMessage: string | undefined
    duplicateSurveyButtonDisabled: boolean
    selectedSurveyDocumentId: DocumentId
}

export class SurveysContentPage extends Component<SurveysContentPageProps, SurveysContentPageState> {
    private surveyFactory: ISurveyFactory = container.get<ISurveyFactory>(TYPES.ISurveyFactory)
    private surveyService: ISurveyService = container.get<ISurveyService>(TYPES.ISurveyService)
    private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
    private router: IRouter = container.get<IRouter>(TYPES.IRouter)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
	private promiseHelper: IPromiseHelper = container.get<IPromiseHelper>(TYPES.IPromiseHelper)
    private dataPromise: CancelablePromise | undefined

    state: SurveysContentPageState = {
        tableData: undefined,
        surveys: [],
        errorMessage: undefined,
        duplicateSurveyButtonDisabled: true,
        selectedSurveyDocumentId: ""
    }

    componentDidMount() {
        this.loadSurveys()
	}

    componentWillUnmount(): void {
		this.promiseHelper.cancel(this.dataPromise)
	}
    
    render() {
        if (this.state.tableData === undefined) {
			return <Loader />;
        }

        let tableData = this.state.tableData

        return(
            <div
                className="admin-tab-panel"
                id={`admin-tab-panel-${this.props.index}`}
                aria-labelledby={`admin-tab-${this.props.index}`}
            >
                {this.state.errorMessage !== undefined && (
					<MessageBar
                        id="error-message-bar"
						messageBarType={MessageBarType.error}
						dismissButtonAriaLabel="Close"
						onDismiss={this.dismissErrorBanner}
					>
						{this.state.errorMessage}
					</MessageBar>
				)}
                <Stack 
					className="admin-command-bar" 
					horizontal={true} 
					tokens={{ childrenGap: 20 }}
				>
                    <SecondaryButton
                        id="add-new-survey-button"
                        iconProps={{ iconName: "Album" }}
                        text="Add New Survey"
                        onClick={this.addSurveyClicked}
                    />
                    <SecondaryButton
                        id="duplicate-survey-button"
                        iconProps={{ iconName: "Copy" }}
                        text="Duplicate Survey"
                        onClick={this.duplicateSurveyClicked}
                        disabled={this.state.duplicateSurveyButtonDisabled}
                    />
                </Stack>
                <DTSTable 
					data={tableData} 
					aria-label="survey table" 
					onRowClicked={this.onRowClicked}
                    onSelectedRowsChange={this.onSelectedRowChange} 
                    pointerOnHover={true}
                    selectableRowsSingle={true}
                    highlightOnHover={true}
                    pagination={true}
                    filter={true}
					filterPlaceholder={this.i18n.get(i18nKey.searchForSurvey)}
				/>
            </div>
        )
    }

    /**
     * Private Functions
     */
    
    private loadSurveys = () => {
        this.getSurveysFor(this.props.clientId)
    }

    private getSurveysFor = (documentId: DocumentId) => {
        this.dataPromise = this.promiseHelper.makeCancelable(this.surveyService.surveysForClientId(documentId))
        this.dataPromise
            .then(surveys => {
                let tableData = this.dataTableFactory.createSurveysTableData(surveys)
				this.setState({ 
                    surveys: surveys, 
                    tableData: tableData
                })
            })
            .catch(_ => {
                this.setState({ 
                    errorMessage: this.i18n.get(i18nKey.failedToLoadSurveys)
                })
            })
    }

    private addSurveyClicked = () => {
        const data = {
            surveyId: null,
            clientId: this.props.clientId
        }

        this.router.goToNewSurvey(data)
    }

    private duplicateSurveyClicked = (e: MouseEvent<HTMLButtonElement>) => {
        const surveyId: DocumentId = this.state.selectedSurveyDocumentId
        
        const survey = this.state.surveys.find(
            (survey) => survey.documentId === surveyId
        )

        if (survey !== undefined) {
            const duplicatedSurvey = this.surveyFactory.duplicateSurvey(survey)
            this.surveyService.save(duplicatedSurvey)
                .then(() => {
                    this.getSurveysFor(this.props.clientId)
                })
                .catch(_ => {
                    this.setState({ 
                        errorMessage: this.i18n.get(i18nKey.failedToSaveSurvey)
                    })
                })
        } else {
            this.setState({
                errorMessage: this.i18n.get(i18nKey.failedToDuplicateSurvey)
            })
        }
    }

    /**
     * Public Functions
     */

    onRowClicked = (row: {[key: string]: string}) => {
        const surveyId: DocumentId = row.id

        const survey = this.state.surveys.find(
            (survey) => survey.documentId === surveyId
        )

        if (survey !== undefined) {
            const data = {
                surveyId: survey.documentId,
                clientId: this.props.clientId
            }
            this.router.goToEditSurvey(data)
        } else {
            this.setState({ 
                errorMessage: this.i18n.get(i18nKey.failedToLoadSurveys)
            })
        }
    }

    onSelectedRowChange = (selectedRowState: {
        allSelected: boolean
        selectedCount: number
        selectedRows: { [key: string]: any }[]
	}) => {
        if (selectedRowState.selectedCount === 0) {
			this.setState({ selectedSurveyDocumentId: "" }, () => {
				this.enablePrimaryButtonIfNeeded()
			})
			return
        }
        
		const selectedId: DocumentId = selectedRowState.selectedRows[0].id
        
		this.setState({ selectedSurveyDocumentId: selectedId }, () => {
			this.enablePrimaryButtonIfNeeded()
		})
    }

    enablePrimaryButtonIfNeeded = () => {
		let disable = true

		if (this.state.selectedSurveyDocumentId !== "") {
			disable = false
		}

		this.setState({ duplicateSurveyButtonDisabled: disable })
	}

    dismissErrorBanner = () => {
		this.setState({ errorMessage: undefined });
	}
}