import React, { Component } from "react";
import {
	Label,
	Stack,
} from "@fluentui/react";
import "./ErrorPage.css"
import { PrimaryButton } from "../ui-kit/PrimaryButton"

interface ErrorPageProps {
    customMessage?: string
    retryHandler: () => void
}

export default class ErrorPage extends Component<ErrorPageProps> {
    render() {
        let message = this.props.customMessage ? 
                    this.props.customMessage : 
                    "Something went wrong. Want to try to reload it again?"

        return (
            <div id="error-page-container">
                <Stack tokens={{childrenGap: "20px"}}>
                    <Label>{message}</Label>
                    <PrimaryButton text="Reload the page" onClick={this.props.retryHandler} />
                </Stack>
            </div>
        )
    } 
}