import { Dictionary } from '../global/TypeAliases';
import { ILRSStatementParser } from './LRSStatementParser';
import { LRSStatement, LRSCompletionStatement } from "./LRSStatement";
import { container } from '../DIContainer';
import { TYPES } from '../Types';
import { injectable } from 'inversify';
import User from './User';
import { LRSLaunchStatement } from './LRSLaunchStatement';
import { RoleType } from './RoleType';

export interface ILRSParser {
    /**
     * Parse an array of statements from learning locker
     * @param data JSON data from learning locker
     */
    parseStatements(data: Dictionary): LRSStatement[]

    /**
     * Parse an array of statements that are marked as passed from learning locker
     * @param data JSON data from learning locker
     * @param users An array of users
     */
    parseCompletionStatements(data: Dictionary, users: User[]): LRSCompletionStatement[]

    /**
     * Parse an array of survey statements that are marked as passed from learning locker
     * @param data JSON data from learning locker
     * @param users An array of users
     * @param anonymousData A boolean to scrub identifiable data from the survey statement
     */
    parseSurveyCompletionStatements(data: Dictionary, users: User[], anonymousData: boolean): LRSCompletionStatement[]

    /**
     * Parse an array of statements that are marked as passed from learning locker, but only return the completed statements
     * @param data JSON data from learning locker
     * @param users An array of users
     */
    parseFilteredCompletionStatements(data: Dictionary, users: User[]): LRSCompletionStatement[]

    /**
     * Parse an array of statements that are marked as attempted from learning locker
     * @param data JSON data from learning locker
     * @param users An array of users
     */
    parseAttemptStatements(data: Dictionary, users: User[]): LRSCompletionStatement[]

    /**
     * Parse an array of statements that are marked as attempted from learning locker
     * @param data JSON data from learning locker
     * @param users An array of users
     * @param anonymousData A boolean to scrub identifiable data from the survey statement
     */
    parseSurveyAttemptStatements(data: Dictionary, users: User[], anonymousData: boolean): LRSCompletionStatement[]

    /**
     * Parse an array of statements that contain launch data
     * @param data JSON data from learning locker
     */
    parseLaunchStatements(data: Dictionary): LRSLaunchStatement[]
}

@injectable()
export class LRSParser implements ILRSParser {
    private statementParser: ILRSStatementParser = container.get<ILRSStatementParser>(TYPES.ILRSStatementParser)

    parseStatements(data: Dictionary): LRSStatement[] {
        const statementCollection: [Dictionary] = data.statements
        
        const statements = statementCollection.map(obj => {
            return this.statementParser.parseStatement(obj)
        })

        return statements
    }

    parseCompletionStatements(data: Dictionary, users: User[]): LRSCompletionStatement[] {
        if (data.statements && data.statements.length === 0) {
            return []
        }
        
        let statementMap: {[key: string]: LRSStatement} = {}
        let usersWhoPassed: Set<string> = new Set()
        
        const statementCollection: [Dictionary] = data.statements
        statementCollection.forEach(obj => {
            const statement = this.statementParser.parseStatement(obj)
            
            statementMap[statement.userId] = statement
            usersWhoPassed.add(statement.userId)
        })
        
        let statements: LRSCompletionStatement[] = []
        users.forEach(user => {
            const statement = new LRSCompletionStatement(statementMap[user.uid], user)
            statements.push(statement)
        })

        return statements
    }

    parseSurveyCompletionStatements(data: Dictionary, users: User[], anonymousData: boolean): LRSCompletionStatement[] {
        if (data.statements && data.statements.length === 0) {
            return []
        }
        
        let statementMap: {[key: string]: LRSStatement} = {}
        let usersWhoPassed: Set<string> = new Set()
        
        const statementCollection: [Dictionary] = data.statements
        statementCollection.forEach(obj => {
            const statement = this.statementParser.parseStatement(obj)
            
            statementMap[statement.userId] = statement
            usersWhoPassed.add(statement.userId)
        })
        
        let statements: LRSCompletionStatement[] = []
        users.forEach(user => {
            let statement: LRSCompletionStatement
            if (anonymousData) {
                const anonymousUser = new User(user.uid,user.firstName[0],user.lastName[0],"N/A","anonymous_user",RoleType.learner,"MM-DD-YYYY","","active","","", user.cohorts ? user.cohorts : {}, "", false)
                statement = new LRSCompletionStatement(statementMap[user.uid], anonymousUser)
            } else {
                statement = new LRSCompletionStatement(statementMap[user.uid], user)
            }
            statements.push(statement)
        })

        return statements
    }

    parseFilteredCompletionStatements(data: Dictionary, users: User[]): LRSCompletionStatement[] {
        let statementMap: {[key: string]: LRSStatement} = {}
        let usersWhoPassed: Set<string> = new Set()
        
        const statementCollection: [Dictionary] = data.statements
        statementCollection.forEach(obj => {
            const statement = this.statementParser.parseStatement(obj)
            
            statementMap[statement.userId] = statement
            usersWhoPassed.add(statement.userId)
        })
        
        let statements: LRSCompletionStatement[] = []
        users.forEach(user => {
            if (usersWhoPassed.has(user.uid)) {
                const statement = new LRSCompletionStatement(statementMap[user.uid], user)
                statements.push(statement)
            }
        })

        return statements
    }

    parseAttemptStatements(data: Dictionary, users: User[]): LRSCompletionStatement[] {
        let statementMap: {[key: string]: LRSStatement} = {}
        let usersWhoAttempted: Set<string> = new Set()
        
        const statementCollection: [Dictionary] = data.statements
        statementCollection.forEach(obj => {
            const statement = this.statementParser.parseAttemptStatement(obj)
            
            statementMap[statement.userId] = statement
            usersWhoAttempted.add(statement.userId)
        })
        
        let statements: LRSCompletionStatement[] = []
        users.forEach(user => {
            const statement = new LRSCompletionStatement(statementMap[user.uid], user)
            statements.push(statement)
        })

        return statements
    }

    parseSurveyAttemptStatements(data: Dictionary, users: User[], anonymousData: boolean): LRSCompletionStatement[] {
        let statementMap: {[key: string]: LRSStatement} = {}
        let usersWhoAttempted: Set<string> = new Set()
        
        const statementCollection: [Dictionary] = data.statements
        statementCollection.forEach(obj => {
            const statement = this.statementParser.parseAttemptStatement(obj)
            
            statementMap[statement.userId] = statement
            usersWhoAttempted.add(statement.userId)
        })
        
        let statements: LRSCompletionStatement[] = []
        users.forEach(user => {
            let statement: LRSCompletionStatement
            if (anonymousData) {
                const anonymousUser = new User(user.uid,user.firstName[0],user.lastName[0],"N/A","anonymous_user",RoleType.learner,"MM-DD-YYYY","","active","","", user.cohorts ? user.cohorts : {}, "", false)
                statement = new LRSCompletionStatement(statementMap[user.uid], anonymousUser)
            } else {
                statement = new LRSCompletionStatement(statementMap[user.uid], user)
            }
            statements.push(statement)
        })

        return statements
    }

    parseLaunchStatements(data: Dictionary): LRSLaunchStatement[] {
        const statementCollection: [Dictionary] = data.statements
        
        const statements = statementCollection.map(obj => {
            return this.statementParser.parseLaunchLocationStatement(obj)
        })

        return statements
    }
}