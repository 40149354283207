import { IClientService } from './ClientService'
import { IDropdownOption } from '@fluentui/react'
import { Dictionary, DocumentId } from './../global/TypeAliases'
import { RoleType } from './../models/RoleType';
import { IAuthService } from './AuthService';
import { container } from '../DIContainer';
import { TYPES } from '../Types';
import { ICohortService } from './CohortService';
import User from '../models/User';
import { injectable } from 'inversify';
import Client from '../models/Client';
import Cohort from '../models/Cohort';
import { IMailService } from './MailService';
import { IUserService } from './UserService';
import { IEventReportingService } from '../services/EventReportingService'
import { i18nKey, Ii18n } from '../global/i18n';
import { UploadUserError } from '../pages/admin/users/UserUploadValidator';
import { IPasswordGenerator } from '../utils/PasswordGenerator';
import { UUID } from '../utils/UUIDGenerator';

export interface CSVProcessedData { 
    newLearnersCount: number
    duplicateLearnersCount: number
    newLearnersData: Dictionary[]
    client: Client
    cohortListOptions: IDropdownOption[]
    selectedCohort: Cohort | null
}
export interface IUserUploadCompositionService {
    /**
     * Bulk upload users. Before writing to the database we do a check and get all learners
     * from the default cohort of the client id and cross reference with the user data we 
     * are about to upload. If any duplicate emails are found uploading will stop. If no 
     * duplicate emails are found then we will upload the users.
     * @param clientId The client document id
     * @param userData The user data
     * @param role The user role. Defaults to Learner if not present.
     * @param additionalCohort An additional cohort that should be assigned to the user.
     */
    uploadUsersForClient(client: Client, userData: Dictionary[], sendRegistrationReminder: boolean, role?: RoleType, additionalCohort?: Cohort): Promise<void>

    /**
     * Bulk upload users as part of onboarding v2
     * @param userData The user data
     * @param clientId The client document id
     * @param sendRegistrationReminder A boolean to send a registration reminder
     * @param role The role of all users
     * @param additionalCohort An additional cohort that should be assigned to the user.
     */
    uploadUsers(userData: Dictionary[], client: Client, sendRegistrationReminder: boolean, role: RoleType, additionalCohort?: Cohort): Promise<void>

    /**
     * Upload a single user as part of onboarding v2
     * @param userData The user data
     * @param client The client
     * @param sendRegistrationReminder A boolean to send a registration reminder
     * @param role The role of all users
     * @param additionalCohort An additional cohort that should be assigned to the user.
     */
    uploadSingleUser(userData: Dictionary, client: Client, sendRegistrationReminder: boolean, role: RoleType, additionalCohort?: Cohort): Promise<void>
    
    /** 
     * Sends a registration email to a user
     * @param email Email of the user to send a registration email to 
    */
    sendRegistrationEmailForExistingUser(email: string): Promise<void>

    /**
     * 
     * @param csvData The data from an uploaded CSV file containing potential new learners
     * @param clientId The client document id
     */
    processCSVData(csvData: Dictionary[], clientId: DocumentId): Promise<CSVProcessedData> 

    /**
     * Sends registration emails to learners 
     * @param users An array of users
     * @param clientId The clientId associated with the users
     */
    sendRegistrationEmailsForExistingUsers(users: User[], clientId: DocumentId): Promise<void> 
}

@injectable()
export class UserUploadCompositionService implements IUserUploadCompositionService {
    private authService: IAuthService = container.get<IAuthService>(TYPES.IAuthService)
    private cohortService: ICohortService = container.get<ICohortService>(TYPES.ICohortService)
    private mailService: IMailService = container.get<IMailService>(TYPES.IMailService)
    private userService: IUserService = container.get<IUserService>(TYPES.IUserService)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)
    private clientService: IClientService = container.get<IClientService>(TYPES.IClientService)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private passwordGenerator: IPasswordGenerator = container.get<IPasswordGenerator>(TYPES.IPasswordGenerator)
    private uuidGenerator: UUID = container.get<UUID>(TYPES.UUID)

    sendRegistrationEmailForExistingUser(email: string): Promise<void> {
        return new Promise((resolve, reject) => {
            let existingUser:User
            let client = undefined
            this.userService.getUserWithEmail(email)
            .then(user => {
                if (!user) {
                    let error = Error("Learner does not exist")
                    this.eventReportingService.warn(error.message, error)
                    reject(error)
                } else if (!user.cohorts) {
                    let error = Error("Learner does not have any cohorts")
                    this.eventReportingService.warn(error.message, error)
                    reject(error)
                } else {
                    existingUser = user
                    return this.userService.getClientsForUser(user.cohorts);
                }
            }) 
            .then(clients => {
                if (!clients || !clients.length) {
                    let error = Error("Learner does not have a client")
                    this.eventReportingService.warn(error.message, error)
                    reject(error)
                } else {
                    let userData: Dictionary[] = []
                    userData.push({
                        "firstName": existingUser.firstName,
                        "lastName": existingUser.lastName,
                        "emailAddress": existingUser.email
                    })
                    client = clients[0]
                    this.mailService.sendNewAccountEmails(userData, client)
                    resolve()
                }
            })
            .catch(error => {
                this.eventReportingService.error(error.message, error)
                reject(error)
            })
        })
    }

    uploadSingleUser(userData: Dictionary, client: Client, sendRegistrationReminder: boolean, role: RoleType, additionalCohort?: Cohort): Promise<void> {
        return new Promise((resolve, reject) => {
            this.userService
                .emailInUse(userData.emailAddress)
                .then(emailInUse => {
                    if (emailInUse) {
                        reject(new Error("Account already exists"))
                        return
                    }

                    return this.uploadUsers([userData], client, sendRegistrationReminder, role, additionalCohort)
                })
                .then(() => {
                    resolve()
                })
                .catch(_ => {
                    reject(new Error("Unable to create the learner. Please try again."))
                })
        })
    }

    uploadUsers(userData: Dictionary[], client: Client, sendRegistrationReminder: boolean, role: RoleType, additionalCohort?: Cohort): Promise<void> {
        return new Promise((resolve, reject) => {
            const defaultCohort = client.defaultCohort
            const domain = client.domain()

            if (defaultCohort === undefined) {
                let error = Error("The default cohort is undefined")
                this.eventReportingService.warn(error.message, error)
                reject(error)
                return
            }

            if (domain === null) {
                let error = Error("The client domain is missing")
                this.eventReportingService.warn(error.message, error)
                reject(error)
                return
            }

            const userDataWithPasswords = this.addPasswordAndUidTo(userData)

            this.authService
                .createUsers(userDataWithPasswords)
                .then(() => {
                    return this.authService.createUserDocuments(userDataWithPasswords, defaultCohort, role, null, domain)
                }).then(newUsersDocumentIds => {
                    /**
                     * We have users created. Now let's auto assign all of them to the 
                     * global (default) cohort.
                     */
                    let promises = [this.cohortService.addLearnersToCohort(newUsersDocumentIds, defaultCohort)]
                    
                    /**
                     * Add additional cohort
                     */
                    if (additionalCohort) {
                        promises.push(this.cohortService.addLearnersToCohort(newUsersDocumentIds, additionalCohort.documentId))
                    }

                    return Promise.all(promises)
                }).then(() => {
                    let promises: Promise<any>[] = []

                    if (userDataWithPasswords.length > 0) {
                        promises.push(this.mailService.sendNewAccountEmailsV2(userDataWithPasswords))
                    }

                    if (sendRegistrationReminder) {
                        promises.push(this.mailService.setOnboardingReminderEmails(userDataWithPasswords, client.documentId))
                    } 

                    return Promise.all(promises)
                }).then(() => {
                    resolve()
                }).catch(error => {
                    this.eventReportingService.error(error.message, error)
                    reject(error)
                })
        })
    }

    uploadUsersForClient(client: Client, userData: Dictionary[], sendRegistrationReminder: boolean, role?: RoleType, additionalCohort?: Cohort): Promise<void> {
        let newUserData: Dictionary[] = [] 

        return new Promise((resolve, reject) => {
            var defaultCohort = client.defaultCohort
            
            if (defaultCohort === undefined) {
                let error = Error("Missing default cohort id")
                this.eventReportingService.warn(error.message, error)
                reject(error)
            } else {
                this.cohortService
                .learnersForCohort(defaultCohort)
                .then(learners => {
                    if (defaultCohort === undefined) {
                        throw new Error(`The default cohort is undefined`)
                    }

                    const domain = client.domain()
                    if (domain === null) {
                        throw new Error(`The client domain is undefined`)
                    }

                    let finalRole: RoleType = RoleType.learner
                    let clientManaged: string | null = null

                    if (role !== undefined) {
                        finalRole = role

                        if (role === RoleType.clientAdmin) {
                            clientManaged = client.documentId
                        }
                    }

                    newUserData = this.getNewUserData(learners, userData)
                    return this.authService.uploadUsers(newUserData, defaultCohort, finalRole, clientManaged, domain)
                }).then(newUserDocumentIds => {
                    /**
                     * We have users created. Now let's auto assign all of them to the 
                     * global (default) cohort.
                     */
                    if (defaultCohort === undefined) {
                        throw new Error(`The default cohort is undefined`)
                    }
                    let promises = [this.cohortService.addLearnersToCohort(newUserDocumentIds, defaultCohort)]
                    
                    /**
                     * Add additional cohort to new and existing users
                     */
                    if (additionalCohort) {
                        promises.push(this.cohortService.addLearnersToCohort(newUserDocumentIds, additionalCohort.documentId))
                    }

                    return Promise.all(promises)
                }).then(() => {
                    let promises: Promise<any>[] = []

                    if (newUserData.length > 0) {
                        promises.push(this.mailService.sendNewAccountEmails(newUserData, client.documentId))
                    }

                    if (sendRegistrationReminder) {
                        promises.push(this.mailService.setOnboardingReminderEmails(newUserData, client.documentId))
                    } 

                    return Promise.all(promises)
                                .then(() => {
                                    resolve()
                                }).catch(error => {
                                    reject(error)
                                })
                }).catch(error => {
                    this.eventReportingService.error(error.message, error)
                    reject(error)
                })
            }
        })
    }

    processCSVData(csvData: Dictionary[], clientId: DocumentId): Promise<CSVProcessedData> {
        const clientPromise = this.clientService.clientForId(clientId)
        const cohortPromise = this.cohortService.cohortsForClient(clientId)

        let cohorts: Cohort[] = []
        let client: Client

        return new Promise((resolve, reject) => {
            Promise.all([clientPromise, cohortPromise]).then(result => {
                client = result[0]
                
                if (client.defaultCohort === undefined) {
                    throw new Error(this.i18n.get(i18nKey.theresAnIssueGettingCohortInfo))
                }

                cohorts = result[1]
                return this.cohortService.learnersForCohort(client.defaultCohort)
            }).then(learners => {
                /**
                 * Grab email addresses for all existing learners in the
                 * selected client's default cohort, and add each email
                 * to a set, exisingLearnerEmails 
                 */
                let existingLearnerEmails: Set<string> = new Set()
                learners.forEach(learner => {
                    const email = learner.email.toLowerCase()
                    existingLearnerEmails.add(email)
                })
                
                /**
                 * For each new learner from the CSV, grab each
                 * email add check if the email exists in exisingLearnerEmails,
                 * it is an existing user. Otherwise, we have encountered a
                 * new user.
                 */
                let duplicateEmails: Set<string> = new Set()
                let newLearnerEmails: Set<string> = new Set()
                let newLearnersToUpload: Dictionary[] = []

                csvData.forEach(newLearner => {
                    const email = newLearner.emailAddress.toLowerCase()
                    if (existingLearnerEmails.has(email)) {
                        duplicateEmails.add(email)
                    } else {
                        newLearnerEmails.add(email)
                        newLearnersToUpload.push(newLearner)
                    }
                })

                /**
                 * If after processing the CSV and the amount of net new learners is over the license count
                 * Throw an error preventing the upload
                 */
                if(client.licenseSeatCount) {
                    if((client.licenseSeatCount - newLearnerEmails.size - learners.length) < 0) {
                        throw new Error(this.i18n.get(i18nKey.addingMoreUsersThanAllowedError))
                    }
                }

                const optionResult = this.createCohortDropdownOptions(cohorts, client)
                const result: CSVProcessedData = {
                    newLearnersCount: newLearnerEmails.size,
                    duplicateLearnersCount: duplicateEmails.size,
                    newLearnersData: newLearnersToUpload,
                    client: client,
                    cohortListOptions: optionResult[0] as IDropdownOption[],
                    selectedCohort: optionResult[1] as Cohort | null
                }

                resolve(result)
            }).catch(error => { 
                reject({errors: [{error: UploadUserError.compositionServiceError, message: error.message}], data: csvData})
            })
        })
    }

    getNewUserData(learners: User[], data: Dictionary[]): Dictionary[] {
        const existingEmails: string[] = learners.map(learner => {
            return learner.email.trim().toLowerCase()
        })
        let newUserData: Dictionary[] = []
        data.forEach(dict => {
            if (!existingEmails.includes(dict.emailAddress.trim().toLowerCase())) {
                newUserData.push(dict)
            }
        })
        return newUserData
    }

    sendRegistrationEmailsForExistingUsers(users: User[], clientId: DocumentId): Promise<void> {
        return new Promise((resolve, reject) => {
            if (users.length === 0) {
                let error = Error("No learners to send emails to")
                reject(error)
                return
            }

            const userData: Dictionary[] = users.map((user) => {
                return {
                    "firstName": user.firstName,
                    "lastName": user.lastName, 
                    "emailAddress": user.email
                }
            })
            
            this.mailService.sendNewAccountEmails(userData, clientId).then(() => {
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    }

    private createCohortDropdownOptions = (cohorts: Cohort[], client: Client) => {
        let selectedCohort: Cohort | null = null

        const cohortOptions: IDropdownOption[] = cohorts.map(cohort => {
            const isSelected = cohort.documentId === client.defaultCohort

            if (isSelected) {
                selectedCohort = cohort
            }

            return {
                key: cohort.documentId,
                text: cohort.name,
                data: cohort,

                /**
                 * Autoselect the default cohort
                 */
                isSelected: isSelected
            }
        })

        return [cohortOptions, selectedCohort]
    }

    private addPasswordAndUidTo(users: Dictionary[]): Dictionary[] {
        const passwords = this.passwordGenerator.generate()

        users.forEach(data => {
            data.password = passwords.hash
            data.originalPassword = passwords.original
            data.uid = this.uuidGenerator.uuidv4()
        })

        return users
    }
}