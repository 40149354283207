import { FirebaseUserMock } from './../mocks/FirebaseUser.mock';
import { IFirebase } from './../services/Firebase';
import { injectable } from "inversify";
import { TYPES } from '../Types';
import { container } from '../DIContainer';
import { IUserService } from '../services/UserService';
import User from '../models/User';
import { log } from '../global/Logger';
import { ILocalStorage } from '../utils/LocalStorage';
import { IEventReportingService } from '../services/EventReportingService'
import { DocumentId, Uid } from '../global/TypeAliases';
import { RoleType } from '../models/RoleType';

export interface IAuth {
    /**
     * Returns true if the user is logged in and false if not logged in
     */
    isLoggedIn(): boolean

    /**
     * Returns the uid for the current user logged in
     */
    uid(): string | undefined

    /**
     * Returns the firebase auth user
     */
    firebaseUser(): firebase.User | null

    /**
     * This is a firebase user document that contains detailed info such
     * as cohorts, courses, role, etc
     */
    user: User | undefined

    /**
     * Add an observer who needs to be notified when the auth state changes
     * @param observer The observer to notify
     */
    addAuthObserver(observer: IAuthObserver): void

    /**
     * Removes an observer 
     * @param observer The observer to remove
     */
    removeAuthObserver(observer: IAuthObserver): void

    /**
     * Allows global admins to "log in as" a different user
     * @param uid The uid of the user to log in as
     */
    logInAs(uid: string): Promise<void>

    /**
     * Clear the mock user and reset it to the previous user logged in
     */
    turnOffLogInAs(): Promise<void>

    /**
     * Returns true if 'log in as' is ON, false if OFF
     */
    isLogInAsActive(): boolean 

    /**
     * Gets the client that is currently set.
     */
    getClientContext(): DocumentId | null

    /**
     * Sets the client that is administrated by the user. If a client admin, it will use their managed client.
     * For global admins, the client they will administrate as until changed.
     * @param clientId The clientId to set for the user.
     */
    setClientContext(clientId: DocumentId): void

    /**
     * 
     * @param uid The uid
     * @param logInAs If we are simulating a user
     */
    getAndSetUser(uid: Uid, logInAs: boolean): Promise<void>
}

@injectable()
export class Auth implements IAuth {
    /**
     * Static Properties
     */

    static logInAsKey = "logInAs"
    static selectClientContextKey = "selectClientContext"

    /**
     * Injected Properties
     */
    private userService: IUserService = container.get<IUserService>(TYPES.IUserService)
    private firebase = container.get<IFirebase>(TYPES.IFirebase)
    private storage = container.get<ILocalStorage>(TYPES.ILocalStorage)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)
    
    /**
     * Private properties
     */
    private authObservers: IAuthObserver[] = []
    private logInAs_uid: string | null = null
    private selectedClientContextId: DocumentId | null = null

    /**
     * Public Properties
     */
    user: User | undefined

    constructor() {
        log('auth object created')
        
        /**
         * Hydrate any local storage state
         */
        this.logInAs_uid = this.storage.get(Auth.logInAsKey)
        this.selectedClientContextId = this.storage.get(Auth.selectClientContextKey)

        this.onAuthStateChanged()
    }

    /**
     * Public Functions
     */
    public uid(): string | undefined {
        var result = this.firebase.auth.currentUser?.uid

        if (this.logInAs_uid) {
            /**
             * "Log in as" is active
             */
            result = this.logInAs_uid
        }

        return result
    }

    public getClientContext(): DocumentId | null {
        if (this.user?.role === RoleType.clientAdmin) {
            return this.user.clientManaged === "" ? null : this.user.clientManaged
          } else if (this.user?.role === RoleType.globalAdmin) {
            return this.selectedClientContextId
          }
          
          return null
          
    }

    public isLoggedIn(): boolean {
        log('auth - isLoggedIn - ' + this.logInAs_uid + " " + this.user + " " + this.user?.cleverId + " " + this.firebase.auth.currentUser)

        if (this.logInAs_uid) {
            /**
             * "Log in as" is active
             */
            log('auth - returning true - sim user')
            return true
        }

        if (this.user === undefined || this.firebase.auth.currentUser === null) {
            log('auth - returning false')
            return false
        }

        if (this.user.cleverId) {
            /**
             * Clever user so we don't need to check if their email has been verified
             */
            log('auth - returning true - has clever id')
            return true
        }
        
        /**
         * Athena user so make sure they've verified their email
         */
        log(`auth - emailVerified - ${this.firebase.auth.currentUser.emailVerified}`)
        return this.firebase.auth.currentUser.emailVerified
    }

    public addAuthObserver(observer: IAuthObserver) {
        this.authObservers.push(observer);
        log('auth - auth observer added')
    }

    public removeAuthObserver(observer: IAuthObserver) {
        const index = this.authObservers.indexOf(observer);
        if (index !== -1) {
          this.authObservers.splice(index, 1);
          log("auth - auth observer removed");
        }
    }

    public firebaseUser(): firebase.User | null {
        var user = this.firebase.auth.currentUser

        if (this.logInAs_uid) {
            /**
             * "Log in as" is active
             */
            user = new FirebaseUserMock()
            user.uid = this.logInAs_uid
        }

        return user
    }

    public logInAs(uid: string): Promise<void> {
        return this.getAndSetUser(uid, true)
    }

    public turnOffLogInAs(): Promise<void> {
        const uid = this.firebase.auth.currentUser?.uid

        if (uid === undefined) {
            let error = new Error("Unable to log original user back in. Missing uid")
            this.eventReportingService.error(error.message, error)
            return Promise.reject(error)
        }

        return this.getAndSetUser(uid, false)
    }

    public isLogInAsActive(): boolean {
        return this.logInAs_uid !== null
    }

    // make public, role is to save clientId locally and in storage
    public setClientContext(clientId: DocumentId): void {
        this.selectedClientContextId = clientId
        this.storage.save(Auth.selectClientContextKey, clientId)
    }

    public getAndSetUser(uid: Uid, logInAs: boolean): Promise<void> {
        log('auth - getAndSetUser for - ' + uid)
        return new Promise((resolve, reject) => {
            this.userService
                .getUser(uid)
                .then((user) => {
                    log('Auth - getAndSetUser - success')
    
                    this.user = user
    
                    if (logInAs) {
                        this.logInAs_uid = uid
                        this.storage.save(Auth.logInAsKey, uid)
                    } else {
                        this.logInAs_uid = null
                        this.storage.delete(Auth.logInAsKey)
                    }
    
                    this.notifyAuthObservers(true);
                    resolve()
                })
                .catch(error => {
                    log('Auth - getAndSetUser - fail - ' + error)
    
                    this.notifyAuthObservers(false)
                    reject(error)
                })
        })
    }
    
    /**
     * Private Functions
     */
    private notifyAuthObservers(isLoggedIn: boolean) {
        log(`Notifying auth observers - user logged in: ${isLoggedIn}` );
        this.authObservers.map((observer) => observer.onAuthStateChange(isLoggedIn))
    }

    private onAuthStateChanged() {
        this.firebase.auth.onAuthStateChanged(firebaseUser => {
            log(`Auth - fb - auth state changed`)
            
            if (firebaseUser) {
                log(`Auth - ${this.user} - ${this.user?.uid} - ${firebaseUser.uid}`)
                
                if (this.user === undefined || this.user.uid !== firebaseUser.uid) {
                    var uid = this.logInAs_uid !== null 
                                    ? this.logInAs_uid 
                                    : firebaseUser.uid

                    this.getAndSetUser(uid, this.logInAs_uid !== null).then(() => {
                        // Do nothing
                    }).catch(_ => {
                        this.clearAuthData()
                    })
                } else {
                    this.notifyAuthObservers(true);
                }
            } else {
                log('auth - onAuthStateChanged - setting user to undefined')
                this.user = undefined
                this.selectedClientContextId = null
                this.clearAuthData()
                this.notifyAuthObservers(false);
            }
        });
    }

    private clearAuthData() {
        this.user = undefined
        this.selectedClientContextId = null
        this.logInAs_uid = null
        this.selectedClientContextId = null
        this.storage.delete(Auth.logInAsKey)
        this.storage.delete(Auth.selectClientContextKey)
    }
}

export interface IAuthObserver {
    onAuthStateChange(isLoggedIn: boolean) : void
}