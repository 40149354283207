import React, { Component } from "react";
import { IStackProps, Stack, Label, Spinner, SpinnerSize, IStackTokens } from "@fluentui/react";
import "./Loader.css"

export interface LoaderProps {
	text?: string
}

export class Loader extends Component<LoaderProps> {
	rowProps: IStackProps = { horizontal: false, horizontalAlign: 'center' };

	stackTokens: IStackTokens = {
		childrenGap: 20,
	};
  
	render() {
		let message: string = this.props.text ? this.props.text : "Loading"

		return (
			<div id="loading-container">
				<Stack {...this.rowProps} tokens={this.stackTokens}>
					<Label text-align="center" id="loading-label">{message}</Label>
					<Spinner id="loading-spinner" size={SpinnerSize.large} labelPosition="top" />
				</Stack>
			</div>
		);
	}
}
