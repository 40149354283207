import { Component } from "react";
import { Pivot, PivotItem } from "@fluentui/react";

import EditCourseDetails from "./EditCourseDetails";
import AddCohortToCourse from "./AddCohortToCourse";

import "./EditCourse.css";
import { i18nKey, Ii18n } from "../../../global/i18n"
import Course from "../../../models/Course";
import RemoveCohortFromCourse from "./RemoveCohortFromCourse";
import { IAccessController } from "../../../services/AccessController";
import { container } from "../../../DIContainer";
import { TYPES } from "../../../Types";
import { DocumentId } from "../../../global/TypeAliases";

interface EditCourseProps {
	documentId: string | undefined;
	course: Course
	clientId: DocumentId
	courseUpdated: () => void
	courseDeletedHandler: (message: string) => void;
}

export default class EditCourse extends Component<EditCourseProps> {
	private acl: IAccessController = container.get<IAccessController>(TYPES.IAccessController)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	render() {
		if (this.props.documentId === undefined) {
			return null
		}

		return (
			<div id="modal-container-course">
				<div id="view-course-container">
					<a id="view-course-link" href={this.props.course.locationUrl}>{this.i18n.get(i18nKey.viewCourse)}</a>
				</div>
				<Pivot aria-label="edit course admin panels">
					{ this.acl.isGlobalAdmin() && 
						<PivotItem headerText="Edit Details">
							<EditCourseDetails
								title="Edit Details Panel"
								index={0}
								documentId={this.props.documentId}
								course={this.props.course}
								courseUpdated={this.props.courseUpdated}
								courseDeletedHandler={this.props.courseDeletedHandler}
							/>
						</PivotItem>
					}
					<PivotItem headerText={this.i18n.get(i18nKey.addGroups)}>
						<AddCohortToCourse
							title={`${this.i18n.get(i18nKey.addGroups)} Panel`}
							index={1}
							documentId={this.props.documentId}
							clientId={this.props.clientId}
						/>
					</PivotItem>
					<PivotItem headerText={this.i18n.get(i18nKey.removeGroups)}>
						<RemoveCohortFromCourse
							title={`${this.i18n.get(i18nKey.removeGroups)} Panel`}
							index={2}
							documentId={this.props.documentId}
							clientId={this.props.clientId}
						/>
					</PivotItem>
				</Pivot>
			</div>
		);
	}
}
