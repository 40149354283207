import { injectable } from 'inversify'
import axios from "axios"
import { container } from '../DIContainer'
import { TYPES } from '../Types'
import { IServiceConfig, LRSServiceConfig } from './ServiceConfig';
import { Dictionary } from '../global/TypeAliases';
import { log } from '../global/Logger'
import { IEventReportingService } from '../services/EventReportingService'
import { Ii18n, i18nKey } from '../global/i18n';

export interface IMailService {
	/**
	 * Send New Account Email
	 * @param userData dictionary of user object containing, firstName, lastName, emailAddress
	 * @param clientId client id 
	 */
	sendNewAccountEmails(userData: Dictionary[], clientId: string): Promise<string[]>

	/**
	 * Onboarding V2
	 * Send New Account Emails V2
	 * @param userData dictionary of user object containing, firstName, lastName, emailAddress
	 */
	sendNewAccountEmailsV2(userData: Dictionary[]): Promise<void>

	/**
	 * Set onboarding reminder emails for newly uploaded users
	 * @param userData dictionary of user object 
	 */
	setOnboardingReminderEmails(userData: Dictionary[], clientId: string): Promise<string[]>

	/**
	 * Once a user has registered on Athena, remove their email from the
	 * onboarding reminder table
	 * @param emailAddress the email of the recipient to be removed
	 */
	deleteOnboardingReminder(emailAddress: string): Promise<void>

	/**
	 * Send Marketing Demo Email
	 * @param formData dictionary of the marketing form data containing name, company, email, phone?, interest?
	 */
    sendMarketingDemoEmail(formData: Dictionary): Promise<void>

	/**
	 * Send Privacy Request Email
	 * @param formData dictionary of the privacy form data containing first name, last name, email, administrator?
	 */
	sendPrivacyRequestEmail(formData: Dictionary): Promise<void>
	
	/**
	 * Send Password Reset Email
	 * @param emailAddress The email address
	 * @param emailLink The email link that has been validated by Firebase as a legit password reset
	 */
	sendPasswordResetEmail(emailAddress: string, emailLink: string): Promise<void>
}

@injectable()
export default class MailService implements IMailService {
    private serviceConfig: IServiceConfig = container.get<IServiceConfig>(TYPES.IServiceConfig)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    async sendNewAccountEmails(userData: Dictionary[], clientId: string): Promise<string[]> {
		log(`MailService - ClientId - ${clientId}`)
		log(`MailService - UserData - ${userData}`)
		
		const options: LRSServiceConfig = await this.serviceConfig.getNewAccountEmailsConfig(userData, clientId)
		
        return new Promise((resolve, reject) => {
			axios.post(options.url, options.config.data, options.config.headers)
			.then(response => {
				log("MailService - sendNewAccountEmails - Success")
				resolve(response.data.emails)
			})
			.catch(error => {
				error.message = this.i18n.get(i18nKey.failedToSendNewAccountEmails)
                this.eventReportingService.error(error.message, error)
				log("MailService - sendNewAccountEmails - Failed: " + error)
                reject(error)
			})
        });
	}
    
	sendNewAccountEmailsV2(userData: Dictionary[]): Promise<void> {		
		const options = this.serviceConfig.getNewAccountEmailsConfigV2(userData)
		
        return new Promise((resolve, reject) => {
			axios.post(options.url, options.config.data, options.config.headers)
				.then(() => {
					log("MailService - sendNewAccountEmailsV2 - Success")
					resolve()
				})
				.catch(error => {
					error.message = this.i18n.get(i18nKey.failedToSendNewAccountEmails)
					this.eventReportingService.error(error.message, error)
					log("MailService - sendNewAccountEmailsV2 - Failed: " + error)
					reject(error)
				})
        })
	}

	async setOnboardingReminderEmails(userData: Dictionary[], clientId: string): Promise<string[]> {
		log(`MailService - UserData - ${userData}`)

		let options = await this.serviceConfig.getSetOnboardingRemindersConfig(userData, clientId)

        return new Promise((resolve, reject) => {
			axios.post(options.url, options.config.data, options.config.headers)
			.then(response => {
				log("MailService - setOnboardingReminderEmails - Success")
				resolve(response.data.emails)
			})
			.catch(error => {
				error.message = this.i18n.get(i18nKey.failedToSetRegistrationReminderEmails)
                this.eventReportingService.error(error.message, error)
				log( "MailService - setOnboardingReminderEmails - Failed: " + error)
                reject(error)
			})
        });
	}

	deleteOnboardingReminder(emailAddress: string): Promise<void> {
		let options = this.serviceConfig.getOnboardingReminderDeleteConfig(emailAddress)

		return new Promise((resolve, reject) => {
			axios.post(options.url, options.config.data, options.config.headers)
			.then(_ => {
				log("MailService - deleteOnboardingReminder - Success on Azure")
				resolve()
			})
			.catch(error => {
				const errorMessage = "MailService - deleteOnboardingReminder - Failed: " + error.message
				this.eventReportingService.error(errorMessage, error)
				log("MailService - deleteOnboardingReminder - Failed: " + error)
				reject(error)
			})
		})
	}
	
	sendMarketingDemoEmail(formData: Dictionary): Promise<void> {
		let options = this.serviceConfig.getMarketingDemoEmailConfig(formData)

        return new Promise((resolve, reject) => {
			axios.post(options.url, options.config.data, options.config.headers)
			.then(() => {
				log("MailService - marketingDemo - Success")
				resolve()
			})
			.catch(error => {
                this.eventReportingService.error(error.message, error)
				log( "MailService - sendMarketingDemoEmail - Failed: " + error)
                reject(error)
			})
        });
	}

	sendPrivacyRequestEmail(formData: Dictionary): Promise<void> {
		let options = this.serviceConfig.getPrivacyRequestEmailConfig(formData)

        return new Promise((resolve, reject) => {
			axios.post(options.url, options.config.data, options.config.headers)
			.then(() => {
				log("MailService - sendPrivacyRequestEmail - Success")
				resolve()
			})
			.catch(error => {
                this.eventReportingService.error(error.message, error)
				log( "MailService - sendPrivacyRequestEmail - Failed: " + error)
                reject(error)
			})
        });
	}
	
	sendPasswordResetEmail(emailAddress: string, emailLink: string): Promise<void> {
		let options = this.serviceConfig.getPasswordResetConfig(emailAddress, emailLink)

        return new Promise((resolve, reject) => {
			axios.post(options.url, options.config.data, options.config.headers)
			.then(() => {
				log("MailService - sendPasswordResetEmail - Success")
				resolve()
			})
			.catch(error => {
                this.eventReportingService.error(error.message, error)
				log( "MailService - sendPasswordResetEmail - Failed: " + error)
                reject(error)
			})
        })
	}
}