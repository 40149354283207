/* istanbul ignore file */
import { DocumentId, Dictionary, LrsId } from "../global/TypeAliases";

export enum ContentType {
	course = "course",
	survey = "survey"
}

export interface IContent {
    documentId: DocumentId
	lrsId: LrsId
	name: string
	description: string
	cohorts: Dictionary
	clients: Dictionary
	duration: string
	imageUrl: string
	locationUrl: string
    folderUrl: string
	contentType(): ContentType 
}