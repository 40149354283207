import { IButtonStyles } from "@fluentui/react";
import { Theme } from "./Theme";

export class ClientContextSelectorStyles {
    static actionButtonStyles: Partial<IButtonStyles> = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '1em',
                    width: '100%'
                },
            },
            color: Theme.color.primary,
            borderColor: Theme.color.primary,
            borderWidth: 1,
            borderRadius: 5,
            minHeight: 10,
            maxHeight: 40,
            width: 120,
            boxSizing: "content-box",
            overflow: 'hidden'
        },
        rootHovered: {
            color: Theme.color.fifth
        },
        flexContainer: {
            display: 'contents'
        }
    }
}