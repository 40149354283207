import { Dictionary, DocumentId } from './../global/TypeAliases';
import User from "./User";
import { injectable } from "inversify";
import { RoleType } from './RoleType';
import { IDateProvider } from '../services/DateProvider';
import { container } from '../DIContainer';
import { TYPES } from '../Types';

export interface IUserParser {
    parse(documentId: DocumentId, data: Dictionary): User
}

@injectable()
export class UserParser implements IUserParser {
    private dateProvider: IDateProvider = container.get<IDateProvider>(TYPES.IDateProvider)

    parse(documentId: DocumentId, data: Dictionary): User {
        return new User(
            data.uid,
            data.firstName,
            data.lastName,
            data.email,
            documentId,
            data.role === undefined ? RoleType.learner : data.role,
            data.createdAt === undefined ? "N/A" : this.dateProvider.parseFirebaseTimestampToStringDate(data.createdAt),
            data.clientManaged === undefined ? "" : data.clientManaged,
            data.status === undefined ? "" : data.status,
            data.cleverId,
            data.cleverDistrictId,
            data.cohorts === undefined ? {} : data.cohorts,
            data.mId === undefined ? "" : data.mId,
            data.tempPassword === undefined ? false : data.tempPassword
        )
    }
}
