import { injectable } from "inversify";

export interface IBase64Encoder {
    encode(val: string): string
}

@injectable()
export class Base64Encoder implements IBase64Encoder {
    encode(val: string): string {
        return window.btoa(val)
    }
}