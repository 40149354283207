import { injectable } from "inversify";
import { container } from "../../../DIContainer";
import { i18nKey, Ii18n } from "../../../global/i18n";
import { Dictionary } from "../../../global/TypeAliases";
import { Survey } from "../../../models/Survey";
import { TYPES } from "../../../Types";
import { surveyLocalization }  from 'survey-react'

const localizedSurveyStrings = surveyLocalization.locales["en"]
export interface ISurveyHelper {
    getProgress(survey: Survey, answers: Dictionary): number
    getCustomSurveyStrings(): Dictionary
}

@injectable()
export class SurveyHelper implements ISurveyHelper {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    getProgress(survey: Survey, answers: Dictionary): number {
        let progress = 0

        const questionCount = survey.pages[0].elements.length
        const answerCount = Object.keys(answers).length

        /**
         * e.g. 6 / 12 = 0.5 * 100 = 50 // 50%
         */
        progress = (answerCount / questionCount) * 100
        return progress
    }

    getCustomSurveyStrings(): Dictionary {
        /**
         * First, add any custom localized strings to the localizedStrings object. 
         * Can view a full list of what can be customized here: 
         * https://github.com/surveyjs/survey-library/blob/master/src/localization/english.ts
         */
        const customizedLocalizedStrings = {
            emptySurvey: `${this.i18n.get(i18nKey.emptySurveyText)}`
        }

        /**
         * Second, reassign the localized survey strings with the customized text
         */
        localizedSurveyStrings.emptySurvey = customizedLocalizedStrings.emptySurvey

        return customizedLocalizedStrings
    }
}