import { IStackStyles, IStackTokens, ITextFieldStyles, ITextStyles } from "@fluentui/react"
import { Theme } from "../../ui-kit/Theme"

export class GuestContentStyles {
    static dropDownContainerDiv = {
        width: 400
    }

    static stackStyles: Partial<IStackStyles> = {
        root: {
            marginLeft: 40,
            marginRight: 40,
            marginTop: 20,
            alignItems: "center",
            selectors: {
                ".ms-Label": {
                    color: Theme.color.primary,
                    fontSize: "1.5em",
                    fontWeight: "bold"
                }
            }
        }
    }

    static titleTextStackStyle: Partial<IStackStyles> = {
        root: {
            paddingRight: "5vw",
            fontFamily: "Noto Sans JP, sans serif",
            selectors: {
                "@media(max-width: 600px)": {
                    paddingRight: 0,
                    textAlign: "center"
                }
            }
        }
    }

    static guestPreviewStackStyles: Partial<IStackStyles> = {
        root: {
            paddingTop: "2vw",
            paddingBottom: "2vw",
            width: "80vw",
            justifyContent: "space-between",

            selectors: {
                '@media(max-width: 600px)': {
                    flexDirection: "column",
                    alignItems: "center",

                    '#click-right-arrow': {
                        display: "none"
                    }
                }
            }
        }
    }

    static lockedContentStyle: Partial<IStackStyles> = {
        root: {
            display: "flex",
            width: "80vw",
            height: "auto",
            justifyContent: "center"
        }
    }

    static guestHeaderTokens: Partial<IStackTokens> = {
        childrenGap: "10px"
    }

    static guestPreviewStackTokens: Partial<IStackTokens> = {
        childrenGap: "10vw"
    }

    static guestPreviewTextStackTokens: Partial<IStackTokens> = {
        childrenGap: "30px"
    }

    static guestDashboardStackStyles: IStackStyles = {
        root: {
            paddingTop: "2vw",
        }
    }

    static guestDashboardFormStackStyles: Partial<IStackStyles> = {
        root: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50vw",
            marginBottom: "5vw",
            padding: "2vw",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px #eeeeee",
            backgroundColor: "#ffffff",

            selectors: {
                '@media(max-width: 600px)': {
                    width: "80vw",
                }
            }
        }
    }

    static guestDashboardFormStyle: React.CSSProperties = {
        display: "flex",
        flexDirection: "column",
        width: "90%"
    }

    static guestDashboardFormSubmitStyle: React.CSSProperties = {
        margin: "auto",
        marginTop: "20px"
    }

    static guestTopHeaderRuleStyle: React.CSSProperties = {
        width: "80vw",
        height: 3.5,
        color: "black",
        backgroundColor: "black",
        display: "inline-block",
        position: "relative"
    }

    static guestHeaderContainerStyle: Partial<IStackStyles> = {
        root: {
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            alignItems: "center",
            width: "80vw"
        }
    }

    static guestSmallHeaderRuleStyle: React.CSSProperties = {
        width: "50%",
        height: 3.5,
        color: "black",
        backgroundColor: "black",
        display: "inline-block",
        position: "relative",
        top: ".3em"
    }

    static browseHeaderTextStyle: ITextStyles = {
        root: {
            selectors: {
                "@media(max-width: 600px)": {
                    fontSize: "4vw"
                }
            },

            fontFamily: "Noto Sans JP, sans serif",
            fontSize: "2vw",
            fontWeight: "bold",
            width: "100%",
            textAlign: "center"
        }
    }

    static formHeaderTextStyles: ITextStyles = {
        root: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '5vw',
                    paddingBottom: '5vw',
                },
            },

            fontFamily: 'Noto Sans JP, sans serif',
            fontSize: '2vw',
            fontWeight: 'bold',
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center"
        }
    }

    static textFieldStyles: Partial<ITextFieldStyles> = {
        root: {
            fontFamily: 'Noto Sans JP, sans serif',
            fontWeight: 'bold',
            fontSize: "1vw",
            paddingTop: '0.5vw',

            selectors: {
                '& label': {
                    fontFamily: 'Noto Sans JP, sans serif',
                    fontWeight: 'bold',
                    fontSize: '5vw',
                    color: '#000000',
                },
                '@media(max-width: 600px)': {
                    fontSize: '3vw'
                },
            },
        },

        fieldGroup: {
            selectors: {
                '@media(max-width: 600px)': {
                    height: '12vw',
                },
            },

            height: '2.5vw',
            borderColor: '#E7EBEF',
        },

        field: {
            selectors: {
                '@media(max-width: 600px)': {
                    fontSize: '4vw',
                },
            },

            fontSize: '1vw',
        },
    }
}
