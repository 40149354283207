import { 
    Dialog, 
    DialogFooter, 
    DialogType, 
    IDialogContentProps, 
    IModalProps, 
    IModalStyles } from "@fluentui/react";
import React, { Component } from "react";
import { container } from "../DIContainer";
import { i18nKey, Ii18n } from "../global/i18n";
import { TYPES } from "../Types";
import { SecondaryButton } from "./SecondaryButton";

interface ErrorDialogProps {
    title: string
    message: string
    onClick: () => void
}

interface ErrorDialogState {}

export class ErrorDialog extends Component<ErrorDialogProps, ErrorDialogState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    
    private contentProps: IDialogContentProps = {
        type: DialogType.normal,
        title: this.props.title,
        closeButtonAriaLabel: this.i18n.get(i18nKey.close),
        subText: this.props.message
    }

    private modalStyles: Partial<IModalStyles> = {
        main: {
            width: 400,
        }
    }

    private modalProps: IModalProps = {
        isBlocking: false,
        styles: this.modalStyles
    }

    render() {
        return (
            <Dialog
                hidden={false}
                dialogContentProps={this.contentProps}
                modalProps={this.modalProps}
            >
                <DialogFooter>
                    <SecondaryButton 
                        id="error-dialog-primary-button"
                        onClick={this.props.onClick} 
                        text={this.i18n.get(i18nKey.close)} 
                    />
                </DialogFooter>
            </Dialog>
        )
    }
}
