import React, { Component } from 'react'
import { Stack, Text, Image } from '@fluentui/react'
import { MarketingIi18n, i18nKey } from '../global/Marketing-i18n'
import { container } from '../DIContainer'
import { TYPES } from '../Types'
import { FeaturesApproachStyles } from './FeaturesApproachStyles'

import approachImage from '../images/marketing/features-approach-image.png'

export class FeaturesApproach extends Component {
    private i18n = container.get<MarketingIi18n>(TYPES.MarketingIi18n)

    render() {
        return (
            <Stack horizontal wrap horizontalAlign='space-between' styles={FeaturesApproachStyles.outerStackStyles}>

                <Stack id='features-approach-content' styles={FeaturesApproachStyles.innerLeftStackStyles}>
                    <Text styles={FeaturesApproachStyles.sectionNameTextStyles}>
                        {this.i18n.get(i18nKey.featuresApproachSectionName).toUpperCase()}
                    </Text>
                    <Text styles={FeaturesApproachStyles.headerTextStyles}>
                        {this.i18n.get(i18nKey.featuresApproachHeader)}
                    </Text>
                    <Text styles={FeaturesApproachStyles.descriptionTextStyles}>
                        {this.i18n.get(i18nKey.featuresApproachParagraph1)}
                    </Text>
                    <Text styles={FeaturesApproachStyles.descriptionTextStyles}>
                        {this.i18n.get(i18nKey.featuresApproachParagraph2)}
                    </Text>
                </Stack>

                <Stack styles={FeaturesApproachStyles.innerRightStackStyles}>
                    <Image 
                        alt={this.i18n.get(i18nKey.featuresApproachImageAltText)}
                        src={approachImage}
                        styles={FeaturesApproachStyles.imageStyles}
                        id='features-approach-image'
                    />
                </Stack>
                
            </Stack>
        )
    }
}
