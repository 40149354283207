import React, { CSSProperties, useEffect, useState } from "react";
import {
  Row,
  Col,
  Alert,
  Container,
  FormFeedback,
  Input,
  Label,
  Form
} from "reactstrap";
import { TYPES } from "../../Types";
import { container } from "../../DIContainer";
import { Ii18n, i18nKey } from "../../global/i18n";
import { NavLink } from "react-router-dom";
import logo from "../../images/logo-large-pink.svg";
import TestimonialsPageV2 from "../marketing/TestimonialsPageV2";
import { IResetPasswordCompositionService } from "../../services/ResetPasswordCompositionService"

interface ResetPasswordRequestPageV2Props {}

export const ResetPasswordRequestPageV2: React.FC<ResetPasswordRequestPageV2Props>  = () => {
  const i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n);
	const compositionService: IResetPasswordCompositionService = container.get<IResetPasswordCompositionService>(TYPES.IResetPasswordCompositionService)

  const [spinnerActive, setSpinnerActive] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>("")
  const [infoMessage, setInfoMessage] = useState<string | undefined>(i18n.get(i18nKey.enterYourEmailToReset))
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const [touched, setTouched] = useState<{ email: boolean }>({ email: false });

  /**
   *  toggle left hand side nav bar from login page to dashboard
   *  subscribe and unsubscribe to the auth observer
   *  leaving [] as the second parameter b/c we want this to run on component mount and umount
   */
  useEffect(() => {
    document.getElementById("container")?.classList.add("no-padding", "no-margin");
    document.getElementById("content")?.classList.add("no-padding", "no-margin");
    document.getElementById("navigation-bar-container")?.classList.add("hidden");
    document.getElementById("navigation-bar")?.classList.add("hidden");

    return () => {
      document.getElementById('container')?.classList.remove('no-padding', 'no-margin')
      document.getElementById('navigation-bar-container')?.classList.remove('hidden')
      document.getElementById('navigation-bar')?.classList.remove('hidden')
    }
  // eslint-disable-next-line
  }, []);

  const handleInputBlur = (field: 'email') => {
    setTouched((prevTouched) => ({ ...prevTouched, [field]: true }));
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setErrorMessage(undefined)
    setSpinnerActive(true)

    compositionService.handleResetRequestFor(email)
      .then(() => {
        setSpinnerActive(false)
        setShowSuccessMessage(true)
        setInfoMessage(undefined)
      })
      .catch(error => {
        setErrorMessage(error.message)
        setSpinnerActive(false)
        setInfoMessage(undefined)
        setShowSuccessMessage(false)
      })
  }

  const validate = (email: string) => {
    const errors: { email?: string} = {};
    if (!email) {
      errors.email = 'Please enter your email address';
    } 
    return errors;
  };

  const errors = validate(email);
  const alertStyles: CSSProperties = { marginTop: "13px", textAlign: "center" }
  
  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <div className="d-block auth-logo">
                        <img data-testid="logo" src={logo} alt={i18n.get(i18nKey.altDtsLogo)} height="150" />{" "}
                      </div>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">{i18n.get(i18nKey.resetPassword)}</h5>
                        <p className="text-muted mt-2">
                          {i18n.get(i18nKey.resetPasswordWithDTS)}
                        </p>
                      </div>
                      {errorMessage && errorMessage ? (
                        <Alert data-testid="error-banner" color="danger" style={alertStyles}> 
                          {errorMessage}
                        </Alert> ) : null }
                      {infoMessage && infoMessage ? (
                        <Alert data-testid="info-banner" color="warning" style={alertStyles}> 
                          {infoMessage}
                        </Alert> ) : null }
                      { showSuccessMessage && showSuccessMessage ? (
                        <Alert data-testid="success-banner" color="success">
                          <h4 className="alert-heading">{i18n.get(i18nKey.success)}</h4>
                            <p>
                              {i18n.get(i18nKey.resetPasswordSentPrefix) + email}
                            </p>
                            <p>
                             {i18n.get(i18nKey.resetPasswordSentExistingAccount)}
                            </p>
                            <p>
                              {i18n.get(i18nKey.resetPasswordSentNewAccount)}
                             </p>
                        </Alert> ): null}
                      {!showSuccessMessage && (
                        <Form
                          className="custom-form mt-4"
                          data-testid="reset-password-form"
                          onSubmit={handleSubmit}
                        >
                          <div className="mb-3">
                            <Label className="form-label">{i18n.get(i18nKey.emailAddress)}</Label>
                            <Input
                              name="email"
                              className="form-control"
                              data-testid="email-input"
                              placeholder="Enter email"
                              type="email"
                              onChange={handleEmailChange}
                              onBlur={() => handleInputBlur('email')}
                              value={email}
                              invalid={errors.email && touched.email ? true : false}
                            />
                          {errors.email && touched.email ? (
                                <FormFeedback type="invalid">{errors.email}</FormFeedback>
                              ) : null}
                          </div>
                          <Row className="mb-3">
                            <Col className="text-end">
                              {!spinnerActive && (
                                <button
                                  className="btn btn-secondary w-100 waves-effect waves-light"
                                  aria-roledescription="reset password primary button"
                                  type="submit"
                                  data-testid="reset-password-primary-button"
                                  disabled={!touched.email || errors.email ? true: false}
                                >
                                  {i18n.get(i18nKey.sendResetEmail)}
                                </button> )}
                              {spinnerActive && (
                                <>
                                  <button 
                                    type="button" 
                                    aria-roledescription="reset password spinner button"
                                    className="btn btn-light w-100 waves-effect"
                                    data-testid="spinner-button"
                                  >
                                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                                    {i18n.get(i18nKey.processing)} 
                                  </button>
                                </>
                              )}
                            </Col>
                          </Row>
                        </Form> 
                      )}
                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                        {i18n.get(i18nKey.rememberIt)}{" "}
                          <NavLink
                            to="/login"
                            className="text-primary fw-semibold"
                            data-testid="back-to-login-link"
                          >
                            {i18n.get(i18nKey.backToLogin)}
                          </NavLink>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <TestimonialsPageV2 />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
