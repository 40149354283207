import { IFirebase } from "./Firebase";
import Cohort from "../models/Cohort";
import User from "../models/User";
import { container } from "../DIContainer";
import { TYPES } from "../Types";
import { injectable } from "inversify";
import { log } from "../global/Logger";
import { IEventReportingService } from '../services/EventReportingService'

export interface ICohortService {
	/**
	 * Get all cohorts
	 * @return An array of Cohorts
	 */
	cohorts(): Promise<Cohort[]>;

	/**
	 * Get all cohorts for a client id
	 * @param clientId The client document id name
	 * @return An array of Cohorts
	 */
	cohortsForClient(clientId: string): Promise<Cohort[]>;

	/**
	 * Search cohorts by name
	 * @param searchTerm The search term
	 */
	searchCohorts(searchTerm: string): Promise<Cohort[]>

	/**
	 * Add a new cohort
	 * @param name The cohort name
	 * @param description The cohort description
	 * @param clientId The client to assign this cohort to
	 * @return The cohort document id
	 */
	addCohort(name: string, description: string, clientId: string): Promise<string>;

    /**
     * 
     * @param cohortDocumentId The cohort document id
     */
    deleteCohort(cohortDocumentId: string): Promise<void>

	/**
	 * Update a cohort
	 * @param cohortDocumentId The cohort document id
	 * @param name The updated cohort name
	 * @param description The updated cohort description
	 */
	updateCohortDetails(cohortDocumentId: string, name: string, description: string): Promise<void>

	/**
     * 
     * @param {*} learners A set of user document ids
     * @param {*} cohortDocumentId Cohort document id
     */
	addLearnersToCohort(learnerIds: string[], cohortDocumentId: string): Promise<void>

	/**
     * 
     * @param {*} learners A set of user document ids
     * @param {*} cohortDocumentId Cohort document id
     */
	removeLearnersFromCohort(learnerIds: string[], cohortDocumentId: string): Promise<void>

	/**
	 * Get learners in a cohort
	 * @param cohortDocumentId Cohort document id
	 */
	learnersForCohort(cohortDocumentId: string): Promise<User[]>
}

@injectable()
export class CohortService implements ICohortService {
	private firebase = container.get<IFirebase>(TYPES.IFirebase)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)

	cohorts(): Promise<Cohort[]> {
		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection("cohort")
				.withConverter(Cohort.Converter)
				.get()
				.then((querySnapshot) => {
					log("Success: CohortService - cohorts()");

					let cohorts: Cohort[] = [];
					querySnapshot.forEach((doc) => {
						let cohort = doc.data();
						cohort.documentId = doc.id;
						cohorts.push(cohort);
					});

					resolve(cohorts);
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("Error: CohortService - cohorts() - " + error);
					reject(error);
				});
		});
	}

	cohortsForClient(clientId: string): Promise<Cohort[]> {
        return new Promise((resolve, reject) => {
            this.firebase.db.collection('cohort')
                .withConverter(Cohort.Converter)
                .where(`clients.${clientId}`, '==', true)
                .get()
                .then((querySnapshot) => {
                    var cohorts: Cohort[] = []
                    querySnapshot.forEach(doc => {
                        const cohort = doc.data()
                        cohort.documentId = doc.id
                        cohorts.push(cohort)
                    })

					log("CohortService - cohortsForClient() - Success")
                    resolve(cohorts)
                }).catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("CohortService - cohortsForClient() - Failed: " + error)
                    reject(error)
                })
		})	
	}

	searchCohorts(searchTerm: string): Promise<Cohort[]> {
        return new Promise((resolve, reject) => {
            this.firebase.db.collection('cohort')
                .withConverter(Cohort.Converter)
                .where("name", "==", searchTerm)
                .get()
                .then((querySnapshot) => {
                    log('Cohort search returned results')
                    var cohorts: Cohort[] = []
                    querySnapshot.forEach(function (doc) {
                        var cohort = doc.data()
                        cohort.documentId = doc.id
                        cohorts.push(cohort)
                    })

                    log("CohortService - searchCohorts() - Success")
                    resolve(cohorts)
                }).catch(error => {
                    this.eventReportingService.error(error.message, error)
                    log("CohortService - searchCohorts() - Success")
                    reject(error)
                })
        })
    }

	addCohort(name: string, description: string, clientId: string): Promise<string> {
		var clientMap: { [key: string]: boolean } = {}
		clientMap[clientId] = true

		return new Promise((resolve, reject) => {
			this.firebase.db
				.collection("cohort")
				.add({
					name: name,
					description: description,
					clients: clientMap,
                    isDefault: false
				})
				.then((documentReference) => {
					log("Success: CohortService - addCohort()");
					resolve(documentReference.id);
				})
				.catch(error => {
                    this.eventReportingService.error(error.message, error)
					log("Error: CohortService - addCohort() - " + error);
					reject(error);
				});
		});
	}

    /* istanbul ignore next */
    deleteCohort(cohortDocumentId: string) : Promise<void> {
        let deleteCohort = this.firebase.app.functions().httpsCallable("deleteCohort")
        return new Promise((resolve, reject) => {
            deleteCohort({ documentId: cohortDocumentId })
            .then(() =>{
                log("CohortService - deleteCohort() - Success: " + cohortDocumentId)
                resolve()
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                log("CohortService - deleteCohort() - Failed: " + error)
                reject(error)
            })
        })
    }

	updateCohortDetails(cohortDocumentId: string, name: string, description: string): Promise<void> {
        return new Promise((resolve, reject) => {
			this.firebase.db
			.collection("cohort")
			.doc(cohortDocumentId)
            .update({
                name: name,
                description: description
            }).then(function () {
                log("CohortService - updateCohortDetails() - Success")
                resolve()
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                log("CohortService - updateCohortDetails() - Failed: " + error)
                reject(error)
            });
        });
	}
	
    /* istanbul ignore next */
    addLearnersToCohort(learnerIds: string[], cohortDocumentId: string): Promise<void> {
        var learnerMap: {[key: string]: boolean} = {}

		learnerIds.forEach(documentId => {
			learnerMap[documentId] = true
		});

        var cohortMap: {[key: string]: boolean} = {}
        cohortMap[cohortDocumentId] = true

        return new Promise((resolve, reject) => {
            let addLearnerFunction = this.firebase.app.functions().httpsCallable('addLearnerToCohort');

            addLearnerFunction({ learners: learnerMap, cohortDocumentId: cohortDocumentId, cohorts: cohortMap }).then(function (result) {
				log("CohortService - addLearnersToCohort() - Success")
                resolve()
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                log("CohortService - addLearnersToCohort() - Failed: " + error)
                reject(error)
            });
        })
	}
	
    /* istanbul ignore next */
    removeLearnersFromCohort(learnerIds: string[], cohortDocumentId: string): Promise<void> {
        var learnerMap: {[key: string]: boolean} = {}

		learnerIds.forEach(documentId => {
			learnerMap[documentId] = true
		});

        return new Promise((resolve, reject) => {
            let remove = this.firebase.app.functions().httpsCallable('removeLearnersFromCohort');

            remove({ learners: learnerMap, cohortDocumentId: cohortDocumentId }).then(function (result) {
                log("CohortService - removeLearnersFromCohort() - Success")
                resolve()
            }).catch(error => {
                this.eventReportingService.error(error.message, error)
                log("CohortService - removeLearnersFromCohort() - Failed: " + error)
                reject(error)
            });
        })
	}
	
	learnersForCohort(cohortDocumentId: string): Promise<User[]> {
        return new Promise((resolve, reject) => {
            this.firebase.db.collection('user')
                .withConverter(User.Converter)
                .where(`cohorts.${cohortDocumentId}`, '==', true)
                .get()
                .then((querySnapshot) => {
                    var users: User[] = []
                    querySnapshot.forEach(function (doc) {
                        var user = doc.data()
                        user.documentId = doc.id
                        users.push(user)
                    })

					log("CohortService - learnersForCohort() - Success")
                    resolve(users)
                }).catch(error => {
                	this.eventReportingService.error(error.message, error)
					log("CohortService - learnersForCohort() - Failed: " + error)
                    reject(error)
                })
        })
    }
}