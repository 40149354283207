import React, { Component } from 'react'
import { Stack, Text, Image } from '@fluentui/react'
import { MarketingIi18n, i18nKey } from '../global/Marketing-i18n'
import { container } from '../DIContainer'
import { TYPES } from '../Types'

import { FeaturesConvenienceStyles } from './FeaturesConvenienceStyles'

import convenienceImage from '../images/marketing/features-convenience-image.png'

export class FeaturesConvenience extends Component {
    private i18n = container.get<MarketingIi18n>(TYPES.MarketingIi18n)

    render() {
        return (
            <Stack horizontalAlign='center' styles={FeaturesConvenienceStyles.outerStackStyles}>
                <Stack styles={FeaturesConvenienceStyles.headerStackStyles}>
                    <Text styles={FeaturesConvenienceStyles.headerTextStyles}>{this.i18n.get(i18nKey.featuresConvenienceHeader)}</Text>
                </Stack>

                <Stack styles={FeaturesConvenienceStyles.subHeaderStackStyles}>
                    <Text styles={FeaturesConvenienceStyles.subHeaderTextStyles}>{this.i18n.get(i18nKey.featuresConvenienceSubHeader)}</Text>
                </Stack>

                <Stack>
                    <Image id='testimonial-company-logo' src={convenienceImage} styles={FeaturesConvenienceStyles.imageStyles} />
                </Stack>
            </Stack>
        )
    }
}
