import React, { ReactElement, useEffect, useState } from "react"
import { Stack } from "@fluentui/react"
import { LearningPlan } from "../../../models/LearningPlan"
import { container } from "../../../DIContainer"
import { TYPES } from "../../../Types"
import "./LearningPlanGridItem.css"
import { i18nKey, Ii18n } from "../../../global/i18n"
import { IFeatureFlagService } from "../../../services/FeatureFlagService"
import { IDeadlinesHelper } from "../../../utils/DeadlinesHelper"
import { Card, CardBody, CardFooter, Col } from "reactstrap"
import { ImageGridV2, LearningPlanImageProps } from "../../../ui-kit/ImageGridV2"

interface LearningPlanGridItemProps {
    learningPlan: LearningPlan
    isComplete?: boolean
    deadline?: string
    onLearningPlanClick: (e: React.MouseEvent<HTMLDivElement>) => void
}

interface LearningPlanGridItemState {
    contentCount: number
    imageProps: LearningPlanImageProps[]
}

export const LearningPlanGridItemV2: React.FC<LearningPlanGridItemProps> = ({learningPlan, isComplete, deadline, onLearningPlanClick}) => {
    const i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    const featureFlagService: IFeatureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService)
	const deadlinesHelper: IDeadlinesHelper = container.get<IDeadlinesHelper>(TYPES.IDeadlinesHelper)

    const [contentCount, setContentCount] = useState<LearningPlanGridItemState['contentCount']>(0)
    const [imageProps, setImageProps] = useState<LearningPlanGridItemState['imageProps']>([])

    useEffect(() => {
        const imageProps: LearningPlanImageProps[] = learningPlan.contents.map(content => {
            return {
                src: content.imageUrl,
                alt: content.name
            }
        })

        setContentCount(learningPlan.contents.length)
        setImageProps(imageProps)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderCardFooter = (): ReactElement => {
        if (featureFlagService.deadline() && deadline && !isComplete) {
            const deadlineCopy = deadlinesHelper.renderTrainingDeadline(deadline)
            if (deadlineCopy !== "") {
                return (
                    <>
                        <p id="learning-plan-grid-item-content-count" className="card-text font-size-16">{i18n.get(i18nKey.trainingsInPlan) + contentCount}</p>
                        <p id="learning-plan-grid-item-deadline" className="card-text text-danger font-size-16">{deadlineCopy}</p>
                    </>
                )
            } 
        }

        if (isComplete) {
            return (
                    <div className="mb-3">
                        <Stack horizontal={true}>
                            <i id="learning-plan-completed-icon" className="bx bxs-badge-check text-success font-size-16 align-middle me-2"></i>
                            <h5 id="learning-plan-completed-label" className="card-text font-size-16 text-success">
                                {i18n.get(i18nKey.completed)}
                            </h5>
                        </Stack>
                    </div>
            )
        } else {
            return <p id="learning-plan-grid-item-content-count" className="card-text font-size-16">{i18n.get(i18nKey.trainingsInPlan) + contentCount}</p>
        }
    }

    /**
     * Begin code to render the component
     */

    return (
        <div className='learning-plan-grid-item' id={learningPlan.documentId} onClick={onLearningPlanClick}>
            <Col md={6} xl={2}>
                <Card 
                    style={{
                        width: '300px', 
                        height: '355px',
                        borderRadius: '10px',
                        cursor: "pointer",
                        backgroundColor: "white", 
                        border: "none"
                    }}
                >
                    <div 
                        style={{
                            height: '12rem',
                            width: '100%',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            overflow: 'hidden'
                        }} 
                    >
                        <ImageGridV2 imageProps={imageProps} />
                    </div>
                    <CardBody style={{height: "25%"}}>
                        <h1 id="learning-plan-grid-item-name-title" className="card-title">{learningPlan.name}</h1>
                        <p id="learning-plan-grid-item-description" className="card-text">{learningPlan.description}</p>
                    </CardBody>
                    <CardFooter style={{height: "20%"}}>
                        <div 
                            style={{           
                                justifyContent: "space-between",
                                display: "flex"
                            }}
                        >
                            {renderCardFooter()}
                        </div>
                    </CardFooter>
                </Card>
            </Col>
        </div>
    )
}