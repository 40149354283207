import { injectable } from "inversify"

export interface IStringHelpers {
    getCommaDeliminatedValues(csvString: string): string[]
}
@injectable()
export class StringHelpers implements IStringHelpers {
    getCommaDeliminatedValues(csvString: string): string[] {
        let csvStringArray: string[] = []
    
        if (csvString === "") {
            return csvStringArray
        }
    
        if (csvString.includes(`,`)) {
            csvStringArray = csvString.split(`,`)
        } else {
            csvStringArray = [csvString]
        }
    
        return csvStringArray
    }
}