import React, { Component, MouseEvent } from "react";
import { container } from "../../../DIContainer";
import IUserService from "../../../services/UserService";
import { IUserUploadCompositionService } from "../../../services/UserUploadCompositionService"
import {
	Stack,
	IStackTokens,
	Spinner,
	MessageBar,
	MessageBarType,
	IDropdownOption,
	Label,
} from "@fluentui/react";
import "./EditUser.css";
import { TYPES } from "../../../Types";
import { RoleType } from "../../../models/RoleType";
import User, { UserStatus } from "../../../models/User";
import { TextField } from '../../../ui-kit/TextField'
import { PrimaryButton } from "../../../ui-kit/PrimaryButton"
import { Dropdown } from "../../../ui-kit/Dropdown"
import { log } from "../../../global/Logger";
import { i18nKey, Ii18n } from "../../../global/i18n";
import { ConfirmationDialog } from "../../../ui-kit/ConfirmationDialog";
import { DestructiveIconButton } from "../../../ui-kit/DestructiveIconButton";
import { IAccessController } from "../../../services/AccessController";
import { IAuth } from "../../../auth/Auth";
import { DocumentId } from "../../../global/TypeAliases";

interface EditUserProps {
	documentId: string;
	user: User;
	userUpdatedHandler: () => void;
	userDeletedHandler: (message: string) => void;
}

interface EditUserState {
	updateButtonDisabled: boolean;
	deleteButtonDisabled: boolean;
	registrationEmailButtonDisabled: boolean;
	showSpinner: boolean;
	showDeleteUserSpinner: boolean;
	showEmailSpinner: boolean;
	errorMessage: string | undefined;
	successMessage: string | undefined;
	selectedRole: RoleType;
	selectedClientId: DocumentId | undefined;
	deleteDialogIsHidden: boolean;
}

const stackStyling: IStackTokens = {
	childrenGap: "20px",
};

export default class EditUser extends Component<EditUserProps, EditUserState> {
	private userService: IUserService = container.get<IUserService>(
		TYPES.IUserService
	);
    private compositionService: IUserUploadCompositionService = container.get<IUserUploadCompositionService>(
		TYPES.IUserUploadCompositionService
	);
	private acl: IAccessController = container.get<IAccessController>(TYPES.IAccessController)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
	private auth: IAuth = container.get<IAuth>(TYPES.IAuth)

	state = {
		updateButtonDisabled: true,
		deleteButtonDisabled: false,
		registrationEmailButtonDisabled: false,
		showSpinner: false,
		showDeleteUserSpinner: false,
		showEmailSpinner: false,
		errorMessage: undefined,
		successMessage: undefined,
		selectedRole: this.props.user.role,
		selectedClientId: undefined,
		deleteDialogIsHidden: true
	};

	globalAdminOptions: IDropdownOption[] = [
		{
			key: RoleType.learner,
			text: "Learner",
			data: RoleType.learner,
			selected: true,
		},
		{
			key: RoleType.clientAdmin,
			text: "Client Admin",
			data: RoleType.clientAdmin,
		},
		{
			key: RoleType.globalAdmin,
			text: "Global Admin",
			data: RoleType.globalAdmin,
		},
	]

	clientAdminOptions: IDropdownOption[] = [
		{
			key: RoleType.learner,
			text: "Learner",
			data: RoleType.learner,
			selected: true,
		},
		{
			key: RoleType.clientAdmin,
			text: "Client Admin",
			data: RoleType.clientAdmin,
		}
	]

	render() {
		return (
			<div id="edit-user-modal-container">
				<Stack id="edit-user-stack" tokens={stackStyling}>
					<Label>
						Currently you will only be able to edit {this.props.user.firstName}
						's role.
					</Label>

					{ !this.state.showEmailSpinner && this.props.user.status === UserStatus.Pending && 
						<PrimaryButton
							id="send-registration-email-button"
							text="Send registration email"
							onClick={this.registrationEmailButtonClicked}
							disabled={this.state.registrationEmailButtonDisabled || this.state.showDeleteUserSpinner}
						/>
					}
					{ this.state.showEmailSpinner &&
						<Spinner
							id="send-registration-email-spinner"
							label="Sending registration email..."
							ariaLive="assertive"
							labelPosition="right"
						/>
					}
					<TextField
						label="First name"
						value={this.props.user.firstName}
						readOnly={true}
					/>
					<TextField
						label="Last name"
						value={this.props.user.lastName}
						readOnly={true}
					/>
					<TextField
						label="Email address"
						value={this.props.user.email}
						readOnly={true}
					/>

					{ this.acl.isGlobalAdmin() && 
						<Dropdown
							id="global-admin-role-dropdown"
							placeholder="Select a role"
							label="Role"
							options={this.globalAdminOptions}
							onChange={this.roleChanged}
							selectedKey={this.state.selectedRole}
						/>
					}

					{ this.acl.isClientAdmin() !== null && 
						<Dropdown
							id="client-admin-role-dropdown"
							placeholder="Select a role"
							label="Role"
							options={this.clientAdminOptions}
							onChange={this.roleChanged}
							selectedKey={this.state.selectedRole}
						/>
					}

					{this.createBanner()}

					{ !this.state.showSpinner && 
						<PrimaryButton
							id="edit-user-primary-button"
							text="Update"
							onClick={this.updateButtonClicked}
							disabled={this.state.updateButtonDisabled}
						/>
					}

					{ this.state.showSpinner && 
						<Spinner
							id="edit-user-spinner"
							label="Updating user..."
							ariaLive="assertive"
							labelPosition="right"
						/>
					}

					{ this.state.showDeleteUserSpinner && 
						<Spinner
							id="edit-user-delete-spinner"
							label="Deleting user..."
							ariaLive="assertive"
							labelPosition="right"
						/>
					}
					{ !this.state.showDeleteUserSpinner && this.acl.canDeleteRegisteredUser(this.props.user) &&
						<DestructiveIconButton
							id="edit-user-delete-button"
							onClick={this.deleteButtonClicked}
							disabled={false}
						/> 
					}
					{ !this.state.deleteDialogIsHidden && 
					<ConfirmationDialog
						title={this.i18n.get(i18nKey.deleteUserTitle)}
						dialogMessage={this.i18n.get(i18nKey.deleteUserMessage)}
						affirmativeButtonText={this.i18n.get(i18nKey.delete)}
						dismissButtonText={this.i18n.get(i18nKey.dismiss)}
						affirmativeActionHandler={this.deleteButtonDestructiveAction}
						dismissActionHandler={this.deleteDialogDismissed}
						isDestructive={true}
					/>
					}
				</Stack>
			</div>
		);
	}
	
	dismissBanner = () => {
		this.setState({ errorMessage: undefined, successMessage: undefined });
	};

	registrationEmailButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: undefined,
			successMessage: undefined,
			showEmailSpinner: true,
			registrationEmailButtonDisabled: true,
		});
		this.compositionService
			.sendRegistrationEmailForExistingUser(this.props.user.email)
			.then(() => {
				const message = "Registration email sent";
				this.setState({
					successMessage: message,
					showEmailSpinner: false,
					registrationEmailButtonDisabled: false,
				});
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showEmailSpinner: false,
					registrationEmailButtonDisabled: false,
				});
			});
	}

	deleteButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({deleteDialogIsHidden: false})
	}
	
	deleteDialogDismissed = () => {
		this.setState({deleteDialogIsHidden: true})
	}

	deleteButtonDestructiveAction = () => {
		this.setState({
			errorMessage: undefined,
			successMessage: undefined,
			showDeleteUserSpinner: true,
			updateButtonDisabled: true,
			deleteButtonDisabled: true,
		});
		this.userService
			.deleteUser(this.props.user.email)
			.then(() => {
				this.props.userDeletedHandler(this.props.user.fullName + " has been deleted")
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showDeleteUserSpinner: false,
					updateButtonDisabled: false,
					deleteButtonDisabled: false,
				});
			})
	}

	updateButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: undefined,
			successMessage: undefined,
			showSpinner: true,
			updateButtonDisabled: true,
		});

		const role =
			this.state.selectedRole === undefined
				? RoleType.learner
				: this.state.selectedRole;

		const clientManaged = this.state.selectedRole === RoleType.clientAdmin ? this.getManagedClientId() ?? undefined : undefined

		this.userService
			.updateUser(this.props.documentId, role, clientManaged)
			.then(() => {
				const message = this.props.user.fullName + " has been updated";
				this.setState({
					successMessage: message,
					showSpinner: false,
					updateButtonDisabled: true,
                });
                
                this.props.userUpdatedHandler()
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showSpinner: false,
					updateButtonDisabled: false,
				});
			});
	};

	roleChanged = (
		event: React.FormEvent<HTMLDivElement>,
		option?: IDropdownOption,
		index?: number
	) => {
		const selectedRole: RoleType = option?.data;
		log("Role selected: " + selectedRole);
		
		this.setState({ 
			selectedRole: selectedRole, 
			updateButtonDisabled: false 
		})
	};

	createBanner = () => {
		if (this.state.errorMessage) {
			return (
				<MessageBar
					id="edit-user-error-banner"
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
					>
					{this.state.errorMessage}
				</MessageBar>
			);
		} else if (this.state.successMessage) {
			return (
				<MessageBar
					id="edit-user-success-banner"
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			);
		}

		return null;
	};

	/**
	 * Private Functions
	 */

	private getManagedClientId = (): DocumentId | null | undefined => {
		if (this.acl.isGlobalAdmin()) {
			return this.auth.getClientContext()
		} else {
			return this.auth.user?.clientManaged
		}
	}
}
