import { MessageBar, MessageBarType, Stack } from "@fluentui/react";
import React, { Component } from "react";
import { container } from "../../../DIContainer";
import { IDataTableFactory } from "../../../factories/DataTableFactory";
import { i18nKey, Ii18n } from "../../../global/i18n";
import Cohort from "../../../models/Cohort";
import Course from "../../../models/Course";
import DTSTableData from "../../../models/DTSTableData";
import { LearningPlan } from "../../../models/LearningPlan";
import { Survey } from "../../../models/Survey";
import { ICohortsContentsPageCompositionService } from "../../../services/CohortsContentsPageCompositionService";
import { ICourseService } from "../../../services/CourseService";
import { ILearningPlanService } from "../../../services/LearningPlanService";
import { ISurveyService } from "../../../services/SurveyService";
import { TYPES } from "../../../Types";
import { Loader } from "../../../ui-kit/Loader";
import { TabPanelProps } from "../TabPanelProps";
import { ISearchFilter } from "../users/SearchFilter";
import { CohortLearningPlanContents } from "./CohortLearningPlanContents";
import { CohortSurveyContents } from "./CohortSurveyContents";
import { CohortTrainingContents } from "./CohortTrainingContents";

interface CohortContentsPageProps extends TabPanelProps {
    cohort: Cohort
}

interface CohortContentsPageState {
    coursesTableData: DTSTableData | undefined;
    learningPlansTableData: DTSTableData | undefined;
    surveysTableData: DTSTableData | undefined;
    errorMessage: string | null;

    // search/add courses states
    courseSearchTableData: DTSTableData | undefined;
    courseSearchResults: Course[];

    // search/add learning plan states
    learningPlanSearchTableData: DTSTableData | undefined;
    learningPlanSearchResults: LearningPlan[];

    // search/add surveys states
    surveySearchTableData: DTSTableData | undefined
    surveySearchResults: Survey[];
}

export class CohortContentsPage extends Component<CohortContentsPageProps, CohortContentsPageState> {
    private compositionService: ICohortsContentsPageCompositionService = container.get<ICohortsContentsPageCompositionService>(TYPES.ICohortsContentsPageCompositionService)
    private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
    private i18n = container.get<Ii18n>(TYPES.Ii18n)
    private courseService: ICourseService = container.get<ICourseService>(TYPES.ICourseService)
    private learningPlanService: ILearningPlanService = container.get<ILearningPlanService>(TYPES.ILearningPlanService)
    private surveyService: ISurveyService = container.get<ISurveyService>(TYPES.ISurveyService)
    private searchFilter: ISearchFilter = container.get<ISearchFilter>(TYPES.ISearchFilter)

    state: CohortContentsPageState = {
		coursesTableData: undefined,
        learningPlansTableData: undefined,
        surveysTableData: undefined,
		errorMessage: null,

        // search/add courses states
        courseSearchTableData: undefined,
        courseSearchResults: [],

        // search/add learning plan states
        learningPlanSearchTableData: undefined,
        learningPlanSearchResults: [],
        
        // search/add surveys states
        surveySearchTableData: undefined,
        surveySearchResults: []
	}

    componentDidMount() {
        this.loadData()
    }

    render() {
        if (this.state.coursesTableData === undefined || this.state.learningPlansTableData === undefined || this.state.surveysTableData === undefined) {
            return <Loader />;
        }

        let coursesData = this.state.coursesTableData;
        let learningPlansData = this.state.learningPlansTableData;
        let surveysData = this.state.surveysTableData;

        let banner = null
		if (this.state.errorMessage) {
			banner = (
                <MessageBar
                id="cohort-contents-page-error-banner"
                messageBarType={MessageBarType.error}
                dismissButtonAriaLabel="Close"
                onDismiss={this.dismissErrorBanner}
                >
                    {this.state.errorMessage}
                </MessageBar>
			)
		}

        return (
            <div 				
                className="cohort-tab-panel"
                id={`cohort-tab-panel-${this.props.index}`}
                aria-labelledby={`cohort-tab-${this.props.index}`}
            >
                {banner}
                <Stack>
                    <CohortLearningPlanContents  
                        learningPlansTableData={learningPlansData}
                        learningPlanSearchTableData={this.state.learningPlanSearchTableData} 
                        searchForLearningPlan={this.searchForLearningPlan}
                        searchButtonClicked={this.searchLearningPlanPrimaryButtonClicked}
                        removeButtonClicked={this.removeLearningPlanPrimaryButtonClicked}
                    />
                    <CohortTrainingContents
                        trainingsTableData={coursesData}
                        trainingSearchTableData={this.state.courseSearchTableData}
                        searchForTraining={this.searchForTraining}
                        searchButtonClicked={this.searchTrainingPrimaryButtonClicked}
                        removeButtonClicked={this.removeTrainingsPrimaryButtonClicked}
                    />
                    <CohortSurveyContents 
                        surveysTableData={surveysData}
                        surveySearchTableData={this.state.surveySearchTableData}
                        searchForSurvey={this.searchForSurvey}
                        searchButtonClicked={this.searchSurveyPrimaryButtonClicked}
                        removeButtonClicked={this.removeSurveysPrimaryButtonClicked}
                    />
                </Stack>
            </div>
        )
    }

    private loadData = () => {
        this.compositionService
            .loadData(this.props.cohort.documentId)
            .then((result) => {
                const coursesTableData = this.dataTableFactory.createCoursesTableData(result.courses, result.clients)
                const learningPlansTableData = this.dataTableFactory.createLearningPlansTableData(result.learningPlans)
                const surveysTableData = this.dataTableFactory.createSurveysTableData(result.surveys)
				this.setState({ 
                    coursesTableData: coursesTableData, 
                    learningPlansTableData: learningPlansTableData,
                    surveysTableData: surveysTableData
                });
            })
            .catch(error => {
				const coursesTableData = this.dataTableFactory.createCoursesTableData([], [])
                const learningPlansTableData = this.dataTableFactory.createLearningPlansTableData([])
                const surveysTableData = this.dataTableFactory.createSurveysTableData([])
				this.setState({
                    errorMessage: this.i18n.get(i18nKey.somethingWentWrongRefresh), 
                    coursesTableData: coursesTableData, 
                    learningPlansTableData: learningPlansTableData,
                    surveysTableData: surveysTableData
                })
			})
    }

    private dismissErrorBanner = () => {
		this.setState({ errorMessage: null });
	}

    removeTrainingsPrimaryButtonClicked = (selectedTrainingsToRemove: string[]): Promise<string> => {
        return new Promise((resolve, reject) => {
            const clientId = Object.keys(this.props.cohort.clients)[0]
            Promise.all([this.courseService.removeCoursesFromCohort(this.props.cohort.documentId, selectedTrainingsToRemove, clientId)])
                .then(_ => {
                    let message: string
                    if (selectedTrainingsToRemove.length === 1) {
                        message = this.i18n.get(i18nKey.selectedTrainingsRemovedSingle)
                    } else {
                        message = selectedTrainingsToRemove.length + this.i18n.get(i18nKey.selectedTrainingsRemovedMultiple)
                    }
        
                    this.loadData()
                    resolve(message)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    searchForTraining = (searchTerm: string) => {
        const clientDocumentIdKeys = Object.keys(this.props.cohort.clients)
    
        this.courseService
            .coursesForClientId(clientDocumentIdKeys[0])
            .then(courses => {
                const filtered = this.searchFilter.trainingsForSearchTerm(searchTerm, courses)
                const data = this.dataTableFactory.createCoursesTableData(filtered, [])
    
                this.setState({ courseSearchResults: filtered, courseSearchTableData: data })
            })
            .catch(error => {
                this.setState({ errorMessage: error.message })
            })
    }

    searchTrainingPrimaryButtonClicked = (selectedTrainingsToAdd: string[]): Promise<string> => {
        return new Promise((resolve, reject) => {
            const clientId = Object.keys(this.props.cohort.clients)[0]
            Promise.all([this.courseService.addCoursesToCohort(this.props.cohort.documentId, selectedTrainingsToAdd, clientId)])
                .then(_ => {
                    let message: string
                    if (selectedTrainingsToAdd.length === 1) {
                        message = this.i18n.get(i18nKey.selectedTrainingsAddedSingle)
                    } else {
                        message = selectedTrainingsToAdd.length + this.i18n.get(i18nKey.selectedTrainingsAddedMultiple)
                    }
    
                    this.loadData()
                    resolve(message)
                })
                .catch(error => {
                    reject(error)
                })
        })
	}

    removeLearningPlanPrimaryButtonClicked = (selectedLearningPlansToRemove: string[]): Promise<string> => {
        return new Promise((resolve, reject) => {
            Promise.all([this.learningPlanService.removeLearningPlansFromCohort(this.props.cohort.documentId, selectedLearningPlansToRemove)])
                .then(_ => {
                    let message: string
                    if (selectedLearningPlansToRemove.length === 1) {
                        message = this.i18n.get(i18nKey.selectedLearningPlansRemovedSingle)
                    } else {
                        message = selectedLearningPlansToRemove.length + this.i18n.get(i18nKey.selectedLearningPlansRemovedMultiple)
                    }
        
                    this.loadData()
                    resolve(message)
                })
                .catch(error => {
                    reject(error)
                })
        })
	}

    searchForLearningPlan = (searchTerm: string) => {
        const clientDocumentIdKeys = Object.keys(this.props.cohort.clients)

		this.learningPlanService
			.getLearningPlansFor(clientDocumentIdKeys[0])
			.then(learningPlans => {
				const filtered = this.searchFilter.learningPlansForSearchTerm(searchTerm, learningPlans)
				const data = this.dataTableFactory.createLearningPlansTableData(filtered)

				this.setState({ learningPlanSearchResults: filtered, learningPlanSearchTableData: data })
			})
			.catch(error => {
				this.setState({ errorMessage: error.message })
			})
	}

    searchLearningPlanPrimaryButtonClicked = (selectedLearningPlansToAdd: string[]): Promise<string> => {
        return new Promise((resolve, reject) => {
            Promise.all([this.learningPlanService.addLearningPlansToCohort(this.props.cohort.documentId, selectedLearningPlansToAdd)])
                .then(_ => {
                    let message: string
                    if (selectedLearningPlansToAdd.length === 1) {
                        message = this.i18n.get(i18nKey.selectedLearningPlansAddedSingle)
                    } else {
                        message = selectedLearningPlansToAdd.length + this.i18n.get(i18nKey.selectedLearningPlansAddedMultiple)
                    }
    
                    this.loadData()
                    resolve(message)
                    })
                .catch(error => {
                    reject(error)
                })
        })
	}
    
    searchSurveyPrimaryButtonClicked = (selectedSurveysToAdd: string[]): Promise<string> => {
        return new Promise((resolve, reject) => {
            const clientId = Object.keys(this.props.cohort.clients)[0]
            Promise.all([this.surveyService.addSurveysToGroup(this.props.cohort.documentId, selectedSurveysToAdd, clientId)])
                .then(_ => {
                    let message: string
                    if (selectedSurveysToAdd.length === 1) {
                        message = this.i18n.get(i18nKey.selectedSurveysAddedSingle)
                    } else {
                        message = selectedSurveysToAdd.length + this.i18n.get(i18nKey.selectedSurveysAddedMultiple)
                    }
    
                    this.loadData()
                    resolve(message)
                    })
                .catch(error => {
                    reject(error)
                })
        })
    }

    searchForSurvey = (searchTerm: string) => {
        const clientDocumentIdKeys = Object.keys(this.props.cohort.clients)

        this.surveyService
            .surveysForClientId(clientDocumentIdKeys[0])
            .then(surveys => {
                const filtered = this.searchFilter.surveysForSearchTerm(searchTerm, surveys)
                const data = this.dataTableFactory.createSurveysTableData(filtered)

                this.setState({ surveySearchResults: filtered, surveySearchTableData: data })
            })
            .catch(error => {
                this.setState({ errorMessage: error.message })
            })
    }

    removeSurveysPrimaryButtonClicked = (selectedSurveysToRemove: string[]): Promise<string> => {
        return new Promise((resolve, reject) => {
            const clientId = Object.keys(this.props.cohort.clients)[0]
            Promise.all([this.surveyService.removeSurveysFromGroup(this.props.cohort.documentId, selectedSurveysToRemove, clientId)])
                .then(_ => {
                    let message: string
                    if (selectedSurveysToRemove.length === 1) {
                        message = this.i18n.get(i18nKey.selectedSurveysRemovedSingle)
                    } else {
                        message = selectedSurveysToRemove.length + this.i18n.get(i18nKey.selectedSurveysRemovedMultiple)
                    }
        
                    this.loadData()
                    resolve(message)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}