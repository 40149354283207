import React, { Component, MouseEvent } from "react";
import IClientService from "../../../services/ClientService";
import {
	Stack,
	IStackTokens,
	Spinner,
	MessageBar,
	MessageBarType,
    SpinButton, 
	Text
} from "@fluentui/react";
import { TabPanelProps } from "../TabPanelProps";
import "./EditClientDetails.css";
import Client from "../../../models/Client";
import { TextField } from '../../../ui-kit/TextField'
import { PrimaryButton } from "../../../ui-kit/PrimaryButton"
import { container } from "../../../DIContainer";
import { TYPES } from "../../../Types";
import DatePicker from "react-datepicker"
import "./EditClientLicenseInfo.css"
import { i18nKey, Ii18n } from "../../../global/i18n";

interface EditClientLicenseInfoProps extends TabPanelProps {
    documentId: string;
	client: Client
	clientUpdated: (message?: string) => void
}

interface EditClientLicenseInfoState {
	name: string;
    licenseSeatCount: number | undefined;
    validUntilDate: Date | undefined
    licenseNotes: string | undefined;
	updateButtonDisabled: boolean;
	showSpinner: boolean;
	errorMessage: string | undefined;
	successMessage: string | undefined;
}

const stackStyling: IStackTokens = {
	childrenGap: "20px",
};

const datePickerStackStyling: IStackTokens = {
	childrenGap: "70px",
};
export class EditClientLicenseInfo extends Component<EditClientLicenseInfoProps, EditClientLicenseInfoState> {
	private clientService: IClientService = container.get<IClientService>(TYPES.IClientService)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	state: EditClientLicenseInfoState = {
		updateButtonDisabled: true,
		showSpinner: false,
		name: this.props.client.name,
        licenseSeatCount: this.props.client.licenseSeatCount,
        validUntilDate: this.props.client.validUntilDate,
        licenseNotes: this.props.client.licenseNotes,
		errorMessage: undefined,
		successMessage: undefined,
	};

	render() {
		let banner = null;
		if (this.state.errorMessage) {
			banner = (
				<MessageBar
					id="edit-client-license-info-error-banner"
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.errorMessage}
				</MessageBar>
			);
		} else if (this.state.successMessage) {
			banner = (
				<MessageBar
					id="edit-client-license-info-success-banner"
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			);
		}

		return (
			<div id="edit-client-license-info-modal-container">
				<Stack tokens={stackStyling}>
					<TextField
						id="edit-client-license-info-name-textfield"
						label={this.i18n.get(i18nKey.name)}
                        readOnly={true}
						value={this.state.name}
					/>
                    <Stack horizontal tokens={datePickerStackStyling}>
                        <Text>{this.i18n.get(i18nKey.licenseExpirationDate)}</Text>                        
						<DatePicker
							id="edit-client-license-info-datepicker"
                            wrapperClassName="edit-client-license-info-datepicker"
                            placeholderText={this.i18n.get(i18nKey.selectAnExpirationDate)}
                            selected={this.state.validUntilDate}
                            onChange={this.onLicenseExpirationDateChange}
                            selectsStart
                            showMonthDropdown
                            showYearDropdown
                        />
                    </Stack>
                    <SpinButton
                    	id="edit-client-license-info-seat-count-field"
                        label={this.i18n.get(i18nKey.numberOfSeatsOnTheLicense)}
                        value={this.state.licenseSeatCount?.toString()}
                        min={0}
                        max={1000000}
                        step={1}
                        onChange={this.licenseSeatCountFieldChanged}
                        incrementButtonAriaLabel="Increase seats by 1"
                        decrementButtonAriaLabel="Decrease seats by 1"
                    />
					<TextField
						id="edit-client-license-info-notes-textfield"
						label={this.i18n.get(i18nKey.notesAboutClientLicense)}
						onChange={this.licenseNotesTextFieldChanged}
                        multiline
                        rows={3}
						value={this.state.licenseNotes}
					/>

					{banner}

					{ !this.state.showSpinner &&
						<PrimaryButton
							id="edit-client-license-info-primary-button"
							text={this.i18n.get(i18nKey.update)}
							onClick={this.updateButtonClicked}
							disabled={this.state.updateButtonDisabled}
							hidden={this.state.showSpinner}
						/>						
					}

					{ this.state.showSpinner &&
						<Spinner
							id="edit-client-license-info-spinner"
							label={this.i18n.get(i18nKey.updatingClientEllipsesDots)}
							ariaLive="assertive"
							labelPosition="right"
						/>
					}
				</Stack>
			</div>
		);
	}

	dismissBanner = () => {
		this.setState({ errorMessage: undefined, successMessage: undefined });
	}

	updateButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: undefined,
			successMessage: undefined,
			showSpinner: true,
			updateButtonDisabled: true,
		});

		this.clientService
			.updateClientLicenseInfo(
				this.props.documentId,
                this.state.licenseSeatCount,
                this.state.validUntilDate,
                this.state.licenseNotes
			)
			.then(() => {
				const message = this.state.name + " has been updated";
				this.props.clientUpdated()
				this.setState({
					successMessage: message,
					showSpinner: false,
					updateButtonDisabled: false,
				});
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showSpinner: false,
					updateButtonDisabled: false,
				});
			});
	}

	licenseSeatCountFieldChanged = (event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
		if (newValue === undefined || !/^\d+$/.test(newValue)) {
			return;
		}

		this.setState({ licenseSeatCount: Number(newValue) }, () => {
			this.enableUpdateButtonIfNeeded();
		});
	}

	licenseNotesTextFieldChanged = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return;
		}

		this.setState({ licenseNotes: newValue }, () => {
			this.enableUpdateButtonIfNeeded();
		});
	}

    onLicenseExpirationDateChange = (date: Date) => {
        this.setState({
            validUntilDate: date
        })
    }

	enableUpdateButtonIfNeeded = () => {
		let disable = true;

		if ((this.state.licenseNotes !== undefined && this.state.licenseNotes.length > 0) ||
            (this.state.licenseSeatCount !== undefined && this.state.licenseSeatCount > 1)||
            this.state.validUntilDate !== undefined
            ) {
			disable = false;
		}

		this.setState({ updateButtonDisabled: disable });
	}
}
