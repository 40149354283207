import CourseGridItem from "../admin/courses/CourseGridItem";
import Course from "../../models/Course";
import React, { Component } from "react";
import { Stack } from "@fluentui/react";
import { CourseGridStyle } from "./CourseGridStyle";
import { Dictionary, DocumentId } from "../../global/TypeAliases";
import { i18nKey, Ii18n } from "../../global/i18n";
import { container } from "../../DIContainer";
import { TYPES } from "../../Types";


interface CourseGridProps {
	courses: Course[];
	userClients?: DocumentId[]
	showProgressBar: boolean
	onCourseClick: (e: React.MouseEvent<HTMLDivElement>) => void
	shouldWrap: boolean
	deadlines?: Dictionary
}

export default class CourseGrid extends Component<CourseGridProps> {
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	render() {
		if (this.props.courses.length === 0) {
			return <div id="course-grid-no-courses-displayed">{this.i18n.get(i18nKey.noCourses)}</div>
		}

		const gridItems = this.props.courses.map((course) => {
			let deadline: string | undefined;
			if (this.props.deadlines) {
				deadline = this.props.deadlines[course.documentId]
			}

			if (this.props.showProgressBar) {
				return <CourseGridItem 
							course={course} 
							key={course.documentId} 
							userClients={this.props.userClients!} 
							showProgressBar={this.props.showProgressBar} 
							onCourseClick={this.props.onCourseClick} 
							deadline={deadline}
						/>;
			}

			else {
				return <CourseGridItem 
							course={course} 
							key={course.documentId} 
							showProgressBar={this.props.showProgressBar} 
							onCourseClick={this.props.onCourseClick} 
							deadline={deadline}
						/>;
			}
		});
		
		return (
			<div id="course-grid">
				<Stack 
					horizontal={true}
					wrap={this.props.shouldWrap} 
					tokens={CourseGridStyle.stackTokens}
				>
					{gridItems}
				</Stack>
			</div>
		);
	}
}

