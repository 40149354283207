import { MessageBar, MessageBarType, Spinner, Stack } from "@fluentui/react";
import { Component } from "react";
import { container } from "../../../DIContainer";
import { i18nKey, Ii18n } from "../../../global/i18n";
import { Survey } from "../../../models/Survey";
import { IRouter, Router } from "../../../Router";
import { IFileUploader } from "../../../services/FileUploader";
import { ISurveyService } from "../../../services/SurveyService";
import { TYPES } from "../../../Types";
import { ConfirmationDialog } from "../../../ui-kit/ConfirmationDialog";
import { DestructiveButton } from "../../../ui-kit/DestructiveButton";
import { PrimaryButton } from "../../../ui-kit/PrimaryButton";
import { ISurveyFactory } from "./SurveyFactory";
import { SurveyPackage } from "./SurveyPage";
import { ISurveyValidator } from "./SurveyValidator";

interface SurveyCommandBarProps {
    didUpdateSurvey: (survey: Survey) => void
    interactionFocused: (id: string) => void
    surveyPackage: SurveyPackage
}

interface SurveyCommandBarState {
    survey: Survey,
    showSpinner: boolean
    errorMessage: string | null
    successMessage: string | null
    resetSurveyDialogIsHidden: boolean,
    deleteSurveyDialogIsHidden: boolean,
    showDeleteSurveySpinner: boolean
}

export class SurveyCommandBar extends Component<SurveyCommandBarProps, SurveyCommandBarState> {
    private surveyService: ISurveyService = container.get<ISurveyService>(TYPES.ISurveyService)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private fileUploader: IFileUploader = container.get<IFileUploader>(TYPES.IFileUploader)
    private surveyValidator: ISurveyValidator = container.get<ISurveyValidator>(TYPES.ISurveyValidator)
    private surveyFactory: ISurveyFactory = container.get<ISurveyFactory>(TYPES.ISurveyFactory)
    private router: Router = container.get<IRouter>(TYPES.IRouter)

    state: SurveyCommandBarState = {
        survey: this.props.surveyPackage.survey,
        showSpinner: false,
        errorMessage: null,
        successMessage: null,
        resetSurveyDialogIsHidden: true,
        deleteSurveyDialogIsHidden: true,
        showDeleteSurveySpinner: false
    }

    render() {
        return (
            <>
                { !this.state.showSpinner &&
                    <>
                        <Stack tokens={{ childrenGap: 20 }} styles={{ root: { width: '85%' }}} horizontal>
                            <>
                                <PrimaryButton 
                                    id="save-button" 
                                    text={this.i18n.get(i18nKey.save)} 
                                    onClick={this.primaryButtonClicked} 
                                />
                                <PrimaryButton 
                                    id="survey-settings-button"
                                    text={this.i18n.get(i18nKey.surveySettingsButtonText)}
                                    onClick={() => this.onInteractionFocusedClicked("")}
                                />
                                <PrimaryButton
                                    id="view-as-learner-button"
                                    text={this.i18n.get(i18nKey.viewAsLearner)}
                                    onClick={this.viewAsLearnerClicked}
                                    disabled={this.props.surveyPackage.survey.isNewSurvey()}
                                />
                            </>
                        </Stack>
                        <Stack tokens={{ childrenGap: 20 }} horizontal>
                            <>
                                <DestructiveButton
                                    id="reset-survey-button"
                                    text={this.i18n.get(i18nKey.resetSurvey)}
                                    onClick={this.resetSurveyButtonClicked}
                                />
                                <DestructiveButton
                                    id="delete-survey-button"
                                    text={this.i18n.get(i18nKey.deleteSurvey)}
                                    onClick={this.deleteSurveyButtonClicked}
                                />
                            </>
                        </Stack>
                    </>
                }

                { this.state.showSpinner &&
                    <Spinner
                        id="spinner"
                        ariaLive="assertive"
                        label={this.i18n.get(i18nKey.saving)}
                    />
                }

                { !this.state.resetSurveyDialogIsHidden && 
					<ConfirmationDialog 
                        title={this.i18n.get(i18nKey.resetSurvey)}
                        dialogMessage={this.i18n.get(i18nKey.resetSurveyDialogMessage)}                    
                        affirmativeButtonText={this.i18n.get(i18nKey.confirmResetSurvey)}
                        dismissButtonText={this.i18n.get(i18nKey.cancel)}
                        affirmativeActionHandler={this.resetSurveyDestructiveAction}
                        dismissActionHandler={this.resetDialogDismissed}
                        isDestructive={true}
                    />
				}

                { !this.state.deleteSurveyDialogIsHidden && 
					<ConfirmationDialog 
                        title={this.i18n.get(i18nKey.deleteSurvey)}
                        dialogMessage={this.i18n.get(i18nKey.deleteSurveyDialogMessage)}                    
                        affirmativeButtonText={this.i18n.get(i18nKey.confirmDeleteSurvey)}
                        dismissButtonText={this.i18n.get(i18nKey.cancel)}
                        affirmativeActionHandler={this.deleteSurveyDestructiveAction}
                        dismissActionHandler={this.deleteDialogDismissed}
                        isDestructive={true}
                    />
				}

                { this.state.showDeleteSurveySpinner && 
                    <Spinner
                        id="edit-learning-plan-details-delete-spinner"
                        label="Deleting Survey..."
                        ariaLive="assertive"
                        labelPosition="right"
                    />
                }

                {this.banner()}
            </>
        )
    }

    dismissBanner = () => {
		this.setState({ 
			errorMessage: null, 
			successMessage: null 
		})
	}

    /**
     * Private Functions
     */

    private enableSaveButtonIfNeeded = () => {
        let disable = true;

		if (this.state.survey.name !== null && 
			this.state.survey.name.length > 0 && 
			this.state.survey.description !== null && 
			this.state.survey.description.length > 0) {
			disable = false;
		}

        return disable
	}

    private primaryButtonClicked = async () => {
        this.setState({ 
            showSpinner: true, 
            successMessage: null, 
            errorMessage: null
        })

        try {
            const areChoicesValid = this.surveyValidator.validateQuestionChoices(this.state.survey)
            if (areChoicesValid === false) {
                throw(new Error(this.i18n.get(i18nKey.invalidSurveyInteractionChoiceValues)))
            }

            let survey = this.state.survey
            let isUpdating = false

            if (survey.isNewSurvey()) {
                const isAvailable = await this.surveyService.isSurveyNameAvailable(survey.name)

                if (!isAvailable) {
                    this.setState({ 
                        showSpinner: false, 
                        errorMessage: `The name ${survey.name} is not available. Please try a different name.`
                    })

                    return
                }
            } else {
                /**
                 * Indicates we need to call the update func instead of creating a new survey
                 */
                isUpdating = true
            }

            if (this.props.surveyPackage.previewCoverImage) {
                const imageUrl = await this.fileUploader.uploadSurveyImage(this.props.surveyPackage.previewCoverImage, survey.lrsId)
                survey.imageUrl = imageUrl
            }

            if (isUpdating) {
                await this.surveyService.update(survey)
            } else {
                survey = await this.surveyService.save(survey)
            }
            
            this.props.didUpdateSurvey(survey)
            
            this.setState({ 
                successMessage: this.i18n.get(i18nKey.saved), 
                showSpinner: false 
            })
        } catch (error) {
            let errorMessage = ''
            if (error instanceof Error && error.message === this.i18n.get(i18nKey.invalidSurveyInteractionChoiceValues)) {
                errorMessage = this.i18n.get(i18nKey.invalidSurveyInteractionChoiceValues)
            } else {
                errorMessage = this.i18n.get(i18nKey.failedToSaveSurvey)
            }
            this.setState({ 
                errorMessage: errorMessage, 
                showSpinner: false 
            })
        }
    }

    private banner = () => {
		let banner = null

		if (this.state.errorMessage) {
			banner = (
				<MessageBar
					id="error-banner"
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.errorMessage}
				</MessageBar>
			)
		} else if (this.state.successMessage) {
			banner = (
				<MessageBar
					id="success-banner"
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			)
		}

		return banner
	}

    private onInteractionFocusedClicked = (id: string) => {
        this.props.interactionFocused(id)
    }

    private resetSurveyButtonClicked = () => {
        this.setState({ resetSurveyDialogIsHidden: false })
    }

    private resetDialogDismissed = () => {
		this.setState({ resetSurveyDialogIsHidden: true })
	}

    private resetSurveyDestructiveAction = () => {
        const surveyInteractionData = this.surveyFactory.removeAllInteractions(this.props.surveyPackage.survey)
        
        this.props.didUpdateSurvey(surveyInteractionData.survey)
        this.props.interactionFocused(surveyInteractionData.interactionId)
	}

    private deleteSurveyButtonClicked = () => {
        this.setState({ deleteSurveyDialogIsHidden: false })
    }

    private deleteDialogDismissed = () => {
		this.setState({ deleteSurveyDialogIsHidden: true })
	}

    private deleteSurveyDestructiveAction = () => {
        this.setState({
			showDeleteSurveySpinner: true
		})

        this.surveyService
            .deleteSurvey(this.props.surveyPackage.survey.documentId)
            .then(_ => {
                this.setState({ 
                    showDeleteSurveySpinner: false
                })
                this.router.goToContentsSurveyTab()
            })
            .catch(error => {
                this.setState({ 
                    errorMessage: this.i18n.get(i18nKey.failedToDeleteSurvey),
                    showDeleteSurveySpinner: false
                })
            })
	}

    private viewAsLearnerClicked = () => {
        const data = {
            documentId: this.props.surveyPackage.survey.documentId,
            surveyCompleted: false,
            learnerViewMode: true
        }

        this.router.goToViewSurvey(data)
    }
}
