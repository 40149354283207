const TYPES = {
    /**
     * Singletons
     */
    IAuth: Symbol.for("IAuth"),
    IFirebase: Symbol.for("IFirebase"),
    ILocalStorage: Symbol.for("ILocalStorage"),

    /**
     * Services
     */
    IMailService: Symbol.for("IMailService"),
    IUserService: Symbol.for("IUserService"),
    ICourseService: Symbol.for("ICourseService"),
    IAuthService: Symbol.for("IAuthService"),
    IClientService: Symbol.for("IClientService"),
    ICohortService: Symbol.for("ICohortService"),
    ILRSService: Symbol.for("ILRSService"),
    IUserUploadCompositionService: Symbol.for("IUserUploadCompositionService"),
    IAPIKeyProvider: Symbol.for("IAPIKeyProvider"),
    ICleverCompositionService: Symbol.for("ICleverCompositionService"),
    IUserRepository: Symbol.for("IUserRepository"),
    ILearningPlanService: Symbol.for("ILearningPlanService"),
    IDateProvider: Symbol.for("IDateProvider"),
    IReportCompletionCompositionService: Symbol.for("IReportCompletionCompositionService"),
    IEventReportingService: Symbol.for("IEventReportingService"),
    IDashboardCompositionService: Symbol.for("IDashboardCompositionService"),
    ICoursesAdminCompositionService: Symbol.for("ICoursesAdminCompositionService"),
    ICohortsContentsPageCompositionService: Symbol.for("ICohortsContentsPageCompositionService"),
    ISurveyService: Symbol.for("ISurveyService"),
    IReportsHomePageCompositionService: Symbol.for("IReportsHomePageCompositionService"),
    ISurveyServiceConfig: Symbol.for("ISurveyServiceConfig"),
    ISurveyReportPageCompositionService: Symbol.for("ISurveyReportPageCompositionService"),
    ISurveyCompositionService: Symbol.for("ISurveyCompositionService"),
    IReportUserSurveyDetailsModalCompositionService: Symbol.for("IReportUserSurveyDetailsModalCompositionService"),
    ILearningPlanCompositionService: Symbol.for("ILearningPlanCompositionService"),
    IDeadlineService: Symbol.for("IDeadlineService"),
    IEmailRegistrationCompositionService: Symbol.for("IEmailRegistrationCompositionService"),
    IResetPasswordCompositionService: Symbol.for("IResetPasswordCompositionService"),
    IGroupIDService: Symbol.for("IGroupIDService"),
    IInvitationCodeParser: Symbol.for("IInvitationCodeParser"),
    IInvitationCodeService: Symbol.for("IInvitationCodeService"),
    IGuestDashboardCompositionService: Symbol.for("IGuestDashboardCompositionService"),
    IUsersAdminCompositionService: Symbol.for("IUsersAdminCompositionService"),

    /**
     * Classes
     */
    ICourseParser: Symbol.for("ICourseParser"),
    UUID: Symbol.for("UUID"),
    IFileUploader: Symbol.for("IFileUploader"),
    IAppInitializerInformer: Symbol.for("IAppInitializerInformer"),
    IAccessController: Symbol.for("IAccessController"),
    ISearchFilter: Symbol.for("ISearchFilter"),
    ILRSStatementParser: Symbol.for("ILRSStatementParser"),
    ILRSParser: Symbol.for("ILRSParser"),
    IServiceConfig: Symbol.for("IServiceConfig"),
    IURLCreator: Symbol.for("IURLCreator"),
    IEmailValidator: Symbol.for("IEmailValidator"),
    IUserUploadValidator: Symbol.for("IUserUploadValidator"),
    Ii18n: Symbol.for("Ii18n"),
    MarketingIi18n: Symbol.for("MarketingIi18n"),
    ICleverCodeParser: Symbol.for('ICleverCodeParser'),
    ICleverService: Symbol.for('ICleverService'),
    IBase64Encoder: Symbol.for('IBase64Encoder'),
    IClientParser: Symbol.for('IClientParser'),
    IUserParser: Symbol.for('IUserParser'),
    IRegistrationTokenService: Symbol.for('IRegistrationTokenService'),
    ILearningPlanParser: Symbol.for('ILearningPlanParser'),
    IFeatureFlagService: Symbol.for('IFeatureFlagService'),
    IRouter: Symbol.for('IRouter'),
    ILoadTimer: Symbol.for('ILoadTimer'),
    ISurveyParser: Symbol.for('ISurveyParser'),
    ISurveyResumer: Symbol.for('ISurveyResumer'),
    ISurveyValidator: Symbol.for('ISurveyValidator'),
    ISurveyHelper: Symbol.for('ISurveyHelper'),
    ILearningPlanHelper: Symbol.for('ILearningPlanHelper'),
    IDeadlinesHelper: Symbol.for('IDeadlinesHelper'),
    IPromiseHelper: Symbol.for('IPromiseHelper'),
    IPasswordGenerator: Symbol.for('IPasswordGenerator'),

    /**
     * Factories
     */
    IDataTableFactory: Symbol.for("IDataTableFactory"),
    ISurveyFactory: Symbol.for("ISurveyFactory"),
    ISurveyInteractionFactory: Symbol.for("ISurveyInteractionFactory"),
    IChartFactory: Symbol.for("IChartFactory"),
    ISurveyReportDataTableFactory: Symbol.for("ISurveyReportDataTableFactory"),

    /**
     * Utils
     */
    IDataTableFactoryUtils: Symbol.for("IDataTableFactoryUtils"),
    IStringHelpers: Symbol.for("IStringHelpers")
};

export { TYPES };
