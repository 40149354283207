import { Component } from "react"
import { container } from "../../DIContainer"
import React from "react"
import Course, { CourseType } from "../../models/Course"
import { LearningPlan } from "../../models/LearningPlan"
import { Loader } from "../../ui-kit/Loader"
import CourseGrid from "./CourseGrid"
import { TYPES } from "../../Types"
import { MessageBar, MessageBarType, Stack } from "@fluentui/react"
import PageTitle from "../../ui-kit/PageTitle"
import { log } from "../../global/Logger"
import { LearningPlanGrid } from "./LearningPlanGrid"
import { Modal } from "@fluentui/react"
import { LearningPlanDetailModal } from "./LearningPlanDetailModal"
import { Dictionary, DocumentId } from "../../global/TypeAliases"
import { IFeatureFlagService } from "../../services/FeatureFlagService"
import { IRouter } from "../../Router"
import { IDashboardCompositionService } from "../../services/DashboardCompositionService"
import { i18nKey, Ii18n } from "../../global/i18n"
import { IUserService } from "../../services/UserService"
import { IAuth } from "../../auth/Auth"
import { Survey } from "../../models/Survey"
import { SurveyGrid } from "./SurveyGrid"
import { ContentType, IContent } from "../../models/IContent"
import { PathNames } from "../../PathNames"
import { DocumentDeletedOrNotFound } from "../../ui-kit/DocumentDeletedOrNotFound"
import CancelablePromise from "cancelable-promise"
import { IPromiseHelper } from "../../services/PromiseHelper"
import { IAuthService } from "../../services/AuthService"

interface DashboardPageProps {}

export interface DashboardPageState {
	courses: Course[]
	learningPlans: LearningPlan[]
	surveys: Survey[]
	surveyCompletionData: Dictionary
	userClients: DocumentId[]
	errorMessage: string | undefined
	selectedLearningPlan: LearningPlan | undefined
	isLearningPlanModalOpen: boolean
	compositionServiceComplete: boolean
	deadlines: Dictionary
	learningPlanCompletionData: Dictionary
	clientIsActive: boolean | undefined
}

export default class DashboardPage extends Component<DashboardPageProps,DashboardPageState> {
	private featureFlagService: IFeatureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService)
	private router: IRouter = container.get<IRouter>(TYPES.IRouter)
	private compositionService: IDashboardCompositionService = container.get<IDashboardCompositionService>(TYPES.IDashboardCompositionService)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
	private userService: IUserService = container.get<IUserService>(TYPES.IUserService)
	private auth: IAuth = container.get<IAuth>(TYPES.IAuth)
	private authService: IAuthService = container.get<IAuthService>(TYPES.IAuthService)
	private promiseHelper: IPromiseHelper = container.get<IPromiseHelper>(TYPES.IPromiseHelper)
	private verificationPromise: CancelablePromise | undefined

	state: DashboardPageState = {
		courses: [],
		learningPlans: [],
		surveys: [],
		userClients: [],

		// error messages and service calls
		errorMessage: undefined,
		compositionServiceComplete: false,

		// modals and external page redirects
		selectedLearningPlan: undefined,
		isLearningPlanModalOpen: false,

		// deadlines
		deadlines: {},

		// completions
		surveyCompletionData: {},
		learningPlanCompletionData: {},

		//client is active
		clientIsActive: false
	}

	componentDidMount() {
		log('Dashboard - componentDidMount')

		if (this.auth.user?.cohorts) {
			this.verificationPromise = this.promiseHelper.makeCancelable(this.userService.verifyUserClientIsActive(this.auth.user?.cohorts, this.auth.user?.role))
			this.verificationPromise
				.then((isActive): Promise<any> => {
                    if (isActive) {
                        return this.compositionService.getData()
                    } else {
                        return Promise.resolve(false)
                    }
                }).then(result => {
                    if (typeof(result) === "boolean") {
                        this.setState({
                            clientIsActive: false,
                            compositionServiceComplete: true
                        })
                    } else {
                        this.setState({ 
                            courses: result.courses, 
                            learningPlans: result.learningPlans, 
                            surveys: result.surveys,
                            surveyCompletionData: result.surveyCompletionData,
                            userClients: result.associatedClientsOfUser, 
                            deadlines: result.deadlines,
                            learningPlanCompletionData: result.learningPlanCompletions,
                            compositionServiceComplete: true,
                            clientIsActive: true
                        })
                    }
                })
				.catch(error => {
                    let errorMessage = this.i18n.get(i18nKey.dashboardPageFailedToLoad)

                    /**
                     * Code 66 means the client id doesn't exist in the LRS
                     */
					if (error.message !== undefined) {		
						if (error.message.includes(this.i18n.get(i18nKey.code66))) {
							errorMessage = error.message
						} else if (error.message.includes(this.i18n.get(i18nKey.moreThanOneUserWithUidFoundError))) {
							errorMessage = this.i18n.get(i18nKey.unableToLoadDashboardNoUniqueUserError)
						} else if (error.message.includes(this.i18n.get(i18nKey.noUserWithUidFoundError))) {
							this.promiseHelper.makeCancelable(this.authService.logout())
								.then(_ => {
									this.router.goToLogin({reroutedToPageError: this.i18n.get(i18nKey.uidNotFoundError)})
								}).catch(_ => {
									/**
									 * Silent Fail
									*/
								})
						}
					}

                    this.setState({
                        errorMessage: errorMessage,
						clientIsActive: true,
                        compositionServiceComplete: true
                    })
                })
        } else {
			this.setState({
				clientIsActive: false,
				compositionServiceComplete: true
			})
		}
	}
	
	componentWillUnmount(): void {
		this.promiseHelper.cancel(this.verificationPromise)
	}

	render() {
		if (!this.state.compositionServiceComplete) {
			return <Loader text="Loading your content" />
		}

		return (
			<div id="dashboard-page-container">
				{ this.state.learningPlans.length > 0 &&
					<div id="learning-plan-grid-container">
						<Stack>
							<PageTitle title="Learning Plans" />
						</Stack>
						<Stack styles={{root: {paddingLeft: 50, paddingTop: 20, paddingBottom: 30}}}>
							<LearningPlanGrid
								learningPlans={this.state.learningPlans}
								completions={this.state.learningPlanCompletionData}
								deadlines={this.state.deadlines}
								onLearningPlanClick={this.learningPlanClicked}
							/>
							<Modal
								titleAriaId="learning-plan-modal"
								isOpen={this.state.isLearningPlanModalOpen}
								onDismiss={this.modalDismissed}
								isBlocking={false}
								>
								{this.createLearningPlanModalElement()}
							</Modal> 
						</Stack>
					</div>
				}
				
				{ (this.state.courses.length > 0 || 
					(this.state.courses.length === 0 && this.state.learningPlans.length === 0)) &&
					<div id="course-grid-container">
						<Stack>
							<PageTitle title="Trainings" />
						</Stack>
						<Stack styles={{ root: { paddingLeft: 50, paddingTop: 20, paddingBottom: 30 }}}>
							<CourseGrid
								courses={this.state.courses}
								userClients={this.state.userClients}
								showProgressBar={true}
								shouldWrap={true}
								onCourseClick={this.courseClicked}
								deadlines={this.state.deadlines}
							/>
						</Stack>
					</div>
				}

				{ this.state.surveys.length > 0 && this.featureFlagService.surveysFeature() &&
					<div id="survey-grid-container">
						<Stack>
							<PageTitle title="Surveys" />
						</Stack>
						<Stack styles={{ root: { paddingLeft: 50, paddingTop: 20 }}}>
							<SurveyGrid
								surveys={this.state.surveys}
								shouldWrap={true}
								onSurveyClick={this.surveyClicked}
								surveyCompletionData={this.state.surveyCompletionData}
								userClientIds={this.state.userClients}
							/>
						</Stack>
					</div>
				}

				{ !this.state.clientIsActive && this.state.compositionServiceComplete &&
					<>
						<Stack styles={{ root: { paddingLeft: 50, paddingTop: 20 }}}>
							<DocumentDeletedOrNotFound message={this.i18n.get(i18nKey.clientIsNotActive)}/>
						</Stack>
					</>
				}

				{ this.state.errorMessage !== undefined && (
					<MessageBar
						id="general-error-banner"
						messageBarType={MessageBarType.error}
						dismissButtonAriaLabel="Close"
						onDismiss={this.dismissBanner}
					>
						{this.state.errorMessage}
					</MessageBar>
				)}
			</div>
		)
	}

	modalDismissed = () => {
		this.setState({ isLearningPlanModalOpen: false })
    }

	dismissBanner = () => {
		this.setState({errorMessage: undefined})
	}

	createLearningPlanModalElement = () => {
		if (!this.state.isLearningPlanModalOpen) {
			return null
		}

		if (this.state.selectedLearningPlan === undefined) {
			return null
		}
		
		return (
            <LearningPlanDetailModal
				learningPlan={this.state.selectedLearningPlan}
				userClientIds={this.state.userClients}
				showProgressBar={true}
				onContentClick={this.contentInLearningPlanClicked}
			/>
	    )
    }

	contentInLearningPlanClicked = (e: React.MouseEvent<HTMLDivElement>, content: IContent) => {
		switch (content.contentType()) {
			case ContentType.course:
				const course = content as Course
				this.goToCourse(course)
				break
			case ContentType.survey:
				const survey = content as Survey
				this.goToSurvey(survey)
				break
			default:
				break
		}	
	}

	courseClicked = (e: React.MouseEvent<HTMLDivElement>) => {
		const courseID = e.currentTarget.id
		const course = this.state.courses.find(
			(course) => course.documentId === courseID
		)

		if (course === undefined) {
			return
		}

		this.goToCourse(course)
	}

	private goToCourse(course: Course) {
		const data = { course: course }
		
		switch (course.type) {
			case CourseType.training:
				this.router.goToCourse(data)
				break
			case CourseType.googleForm:
				this.router.goToGoogleFormTraining(data)
				break
		}
	}

	surveyClicked = (e: React.MouseEvent<HTMLDivElement>) => {
		const surveyID = e.currentTarget.id
		const survey = this.state.surveys.find(
			(survey) => survey.documentId === surveyID
		)

		if (survey === undefined) {
			return
		}

		this.goToSurvey(survey)
	}

	private goToSurvey(survey: Survey) {
		const data = { 
			documentId: survey.documentId, 
			surveyCompleted: this.state.surveyCompletionData[survey.documentId],
			learnerViewMode: false
		}
		
		this.router.goToViewSurvey(data)
	}

	learningPlanClicked = (e: React.MouseEvent<HTMLDivElement>) => {
		log("Learning Plan clicked: " + e.currentTarget.id)

		const lpID = e.currentTarget.id
		const learningPlan = this.state.learningPlans.find(
			(learningPlan) => learningPlan.documentId === lpID
		)

		if (learningPlan === undefined) {
			return
		}

		if (this.featureFlagService.learningPlansPage()) {
			const data = { 
				navigationItems: [
					{ text: 'Dashboard', key: 'Dashboard', href: PathNames.Dashboard },
					{ text: 'Learning Plans', key: 'LearningPlans', href: PathNames.LearningPlans, isCurrentItem: true }
				],
				learningPlanId: learningPlan.documentId,
				learningPlanName: learningPlan.name,
				userClientIds: this.state.userClients
			}
			
			this.router.goToLearningPlans(data)
		} else {
			this.setState({ selectedLearningPlan: learningPlan, isLearningPlanModalOpen: true })
		}
	}
}
