import { IDateProvider } from './../services/DateProvider';
import { injectable } from 'inversify';
import { container } from '../DIContainer';
import { TYPES } from '../Types';
export interface IDeadlinesHelper {
    renderTrainingDeadline(deadline: string): string
}
@injectable()
export class DeadlinesHelper implements IDeadlinesHelper {
    private dateProvider: IDateProvider = container.get<IDateProvider>(TYPES.IDateProvider)

    renderTrainingDeadline(deadline: string): string {
        if (deadline === "") { return ""}
        
        // the Z is necessary because it turns the timestamp into UTC time
        const deadlineAsDate: Date = new Date(deadline + "Z")
        const now: Date = this.dateProvider.getDate()

        // get the time between now and the deadline in miliseconds
        const remainingTime: number = deadlineAsDate.getTime() - now.getTime()
        const deadlineDiff: number = this.dateProvider.getDaysFromMiliseconds(remainingTime) + 1

        // get the appropriate deadline copy to display on the content grid item 
        return this.dateProvider.getDeadlineCopy(deadlineDiff)
    }
}