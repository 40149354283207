import React, { Component } from "react";
import { Text, Separator, Stack } from "@fluentui/react"
import { FontSize } from "./FontSize";
import { ProfileUIStyles } from "../pages/profile/ProfileUI";

interface PageTitleProps {
    title: string
    isSSO?: boolean
    isEditGroupPageSubtitle?: boolean
}

export default class PageTitle extends Component<PageTitleProps> {
    private setPaddingLeft = () => {
        if (this.props.isSSO) {
            return 0
        } else if (this.props.isEditGroupPageSubtitle) {
            return 10
        } else {
            return 50
        }
    }

    private styles = {
        root: {
            paddingLeft: this.setPaddingLeft(),
            paddingTop: this.props.isEditGroupPageSubtitle ? 15 : 0
        }
    }    

    private stackTokens = {
        childrenGap: 30
    }

    render() {
        return (
            <div id="page-title-container">
                <Stack tokens={this.stackTokens}>
                    <Text 
                        variant={FontSize.xLarge} 
                        styles={this.styles}>
                            {this.props.title}
                    </Text>
                    <Separator styles={ProfileUIStyles.separatorStyles} />
                </Stack>
            </div>    
        )
    }
}
