import React, { Component } from "react";
import { Stack } from "@fluentui/react";
import { CourseGridStyle } from "./CourseGridStyle";
import { i18nKey, Ii18n } from "../../global/i18n";
import { container } from "../../DIContainer";
import { TYPES } from "../../Types";
import { SurveyGridItem } from "../admin/surveys/SurveyGridItem";
import { Survey } from "../../models/Survey";
import { Dictionary, DocumentId } from "../../global/TypeAliases";

interface SurveyGridProps {
	surveys: Survey[]
	userClientIds: DocumentId[]
	onSurveyClick: (e: React.MouseEvent<HTMLDivElement>) => void
	shouldWrap: boolean
	surveyCompletionData: Dictionary
}

export class SurveyGrid extends Component<SurveyGridProps> {
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	render() {
		if (this.props.surveys.length === 0) {
			return <div id="survey-grid-no-surveys-displayed">{this.i18n.get(i18nKey.noSurveysLabel)}</div>
		}

		const gridItems = this.props.surveys.map((survey) => {
			let isComplete = this.props.surveyCompletionData[survey.documentId]
			
			if (isComplete === undefined) {
				isComplete = false
			}
			
			return <SurveyGridItem 
						key={survey.documentId}
                        survey={survey} 
						userClientIds={this.props.userClientIds}
                        onSurveyClick={this.props.onSurveyClick} 
						isComplete={isComplete}
                    />;
		});
		
		return (
			<div id="survey-grid">
				<Stack 
					horizontal={true}
					wrap={this.props.shouldWrap} 
					tokens={CourseGridStyle.stackTokens}
				>
					{gridItems}
				</Stack>
			</div>
		);
	}
}

