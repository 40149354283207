import { injectable } from "inversify"

export interface IEmailValidator {
    validate(emailAddress: string): boolean
}

@injectable()
export class EmailValidator implements IEmailValidator {

    /**
     * Solution was found here: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
     * @param emailAddress The email address to validate
     */
    validate(emailAddress: string): boolean {
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(emailAddress.toLowerCase())
    }
}