import React from "react";
import { Stack } from "@fluentui/react";
import { CourseGridStyle } from "./CourseGridStyle";
import { i18nKey, Ii18n } from "../../global/i18n";
import { container } from "../../DIContainer";
import { TYPES } from "../../Types";
import { Survey } from "../../models/Survey";
import { Dictionary, DocumentId } from "../../global/TypeAliases";
import { SurveyGridItemV2 } from "../admin/surveys/SurveyGridItemV2";

interface SurveyGridProps {
	surveys: Survey[]
	userClientIds: DocumentId[]
	onSurveyClick: (e: React.MouseEvent<HTMLDivElement>) => void
	shouldWrap: boolean
	surveyCompletionData: Dictionary
}

export const SurveyGridV2: React.FC<SurveyGridProps> = ({surveys, userClientIds, onSurveyClick, shouldWrap, surveyCompletionData}) => {
	const i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    if (surveys.length === 0) {
        return <p className="card-text font-size-16" id="survey-grid-no-surveys-displayed">{i18n.get(i18nKey.noSurveysLabel)}</p>
    }

    const gridItems = surveys.map((survey) => {
        let isComplete = surveyCompletionData[survey.documentId]
        
        if (isComplete === undefined) {
            isComplete = false
        }
        
        return (
            <SurveyGridItemV2
                key={survey.documentId}
                survey={survey} 
                userClientIds={userClientIds}
                onSurveyClick={onSurveyClick} 
                isComplete={isComplete}
            />
        )
    })
    
    return (
        <div id="survey-grid">
            <Stack 
                horizontal={true}
                wrap={shouldWrap} 
                tokens={CourseGridStyle.stackTokens}
            >
                {gridItems}
            </Stack>
        </div>
    )
}