/* istanbul ignore file */
export enum ChartType {
    pieChart = "pieChart"
}

export interface IChartData {
    name: string
    value: number
    chartType(): ChartType
}
