import React, { Component, FormEvent } from 'react'
import { 
    Stack,
    Text, 
    TextField, 
    MessageBar,
    MessageBarType 
} from '@fluentui/react'
import { MarketingIi18n, i18nKey } from '../global/Marketing-i18n'
import { container } from '../DIContainer'
import { TYPES } from '../Types'
import { MarketingFormStyles } from './MarketingFormStyles'
import { MarketingButton } from './MarketingButton'
import { IMailService } from '../services/MailService'
import { Dictionary } from '../global/TypeAliases'
import { IEventReportingService } from '../services/EventReportingService'

interface MarketingFormProps {}
interface MarketingFormState {
    name: string
    company: string
    email: string
    phone: string
    interests: string
    errorMessage: string | undefined
    successMessage: string | undefined
}

export class MarketingForm extends Component<MarketingFormProps, MarketingFormState> {
    private i18n = container.get<MarketingIi18n>(TYPES.MarketingIi18n)
    private mailService = container.get<IMailService>(TYPES.IMailService)
    private eventReportingService: IEventReportingService = container.get<IEventReportingService>(TYPES.IEventReportingService)

    state: MarketingFormState = {
        name: '',
        company: '',
        email: '',
        phone: '',
        interests: '',
        errorMessage: undefined,
        successMessage: undefined
    }

    render() {
        return (
            <Stack
                id='marketing-form'
                horizontal
                wrap
                horizontalAlign='space-around'
                styles={MarketingFormStyles.outerStackStyles}
            >
                <Stack styles={MarketingFormStyles.contentStackStyles} tokens={{ childrenGap: '1.5vw' }}>
                    <Text styles={MarketingFormStyles.contentHeaderTextStyles}>
                        {this.i18n.get(i18nKey.marketingFormDescriptionHeader)}
                    </Text>
                    <Text styles={MarketingFormStyles.contentTextStyles}>
                        {this.i18n.get(i18nKey.marketingFormDescription)}
                    </Text>
                </Stack>

                <Stack id="request-a-demo" styles={MarketingFormStyles.formStackStyles}>
                    <Text styles={MarketingFormStyles.formHeaderTextStyles}>
                        {this.i18n.get(i18nKey.marketingFormHeader)}
                    </Text>
                    
                    <form onSubmit={this.onFormSubmit}>
                        <TextField
                            label={this.i18n.get(i18nKey.name)}
                            styles={MarketingFormStyles.textFieldStyles}
                            onChange={this.nameTextFieldChanged}
                            value={this.state.name}
                            required
                            type='text'
                        />
                        <TextField
                            label={this.i18n.get(i18nKey.company)}
                            styles={MarketingFormStyles.textFieldStyles}
                            onChange={this.companyTextFieldChanged}
                            value={this.state.company}
                            required
                            type='text'
                        />
                        <TextField
                            label={this.i18n.get(i18nKey.email)}
                            styles={MarketingFormStyles.textFieldStyles}
                            onChange={this.emailTextFieldChanged}
                            value={this.state.email}
                            required
                            type='email'
                        />
                        <TextField
                            label={this.i18n.get(i18nKey.phone)}
                            styles={MarketingFormStyles.textFieldStyles}
                            onChange={this.phoneTextFieldChanged}
                            value={this.state.phone}
                            type='text'
                        />
                        <TextField
                            label={this.i18n.get(i18nKey.interests)}
                            styles={MarketingFormStyles.textFieldStyles}
                            onChange={this.interestsTextFieldChanged}
                            value={this.state.interests}
                            type='text'
                        />

                    { this.state.errorMessage !== undefined && (
						<MessageBar
							messageBarType={MessageBarType.error}
							dismissButtonAriaLabel="Close"
                            onDismiss={this.dismissBanner}
                            styles= {MarketingFormStyles.messageBarStyles}
						>
							{this.state.errorMessage}
                            <a href='mailto:hello@cyborgmobile.com'>
                                {this.i18n.get(i18nKey.cyborgMobileContactEmail)}
                            </a>
						</MessageBar>
					)}

                    { this.state.successMessage !== undefined && (
						<MessageBar
							messageBarType={MessageBarType.success}
							dismissButtonAriaLabel="Close"
                            onDismiss={this.dismissBanner}
                            styles= {MarketingFormStyles.messageBarStyles}
						>
							{this.state.successMessage}
						</MessageBar>
					)}
                    
                        <MarketingButton
                            id='landing-form-submit-button'
                            text={this.i18n.get(i18nKey.submit).toUpperCase()}
                            type='submit'
                            width='100%'
                        />
                    </form>
                </Stack>
            </Stack>
        )
    }

    dismissBanner = () => {
		this.setState({ errorMessage: undefined, successMessage: undefined })
	}

    onFormSubmit = (e: FormEvent) => {
        e.preventDefault()
        let formData: Dictionary = {
            name: this.state.name,
            company: this.state.company,
            email: this.state.email,
            phone: this.state.phone,
            interests: this.state.interests,
        }

        this.mailService
            .sendMarketingDemoEmail(formData)
            .then(() => {
                this.setState({ 
                    name: '',
                    company: '', 
                    email: '', 
                    phone: '', 
                    interests: '', 
                    errorMessage: undefined,
                    successMessage: this.i18n.get(i18nKey.marketingFormSuccessMessage)
                })
            })
            .catch(_ => {
                this.setState({errorMessage: this.i18n.get(i18nKey.marketingFormErrorMessage)})
            })
    }

    nameTextFieldChanged = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ name: newValue ? newValue : '' })
    }

    companyTextFieldChanged = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ company: newValue ? newValue : '' })
    }

    emailTextFieldChanged = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ email: newValue ? newValue : '' })
    }

    phoneTextFieldChanged = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ phone: newValue ? newValue : '' })
    }

    interestsTextFieldChanged = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({ interests: newValue ? newValue : '' })
    }
}
