import React, { Component, MouseEvent } from "react"
import { ICohortService } from "../../../services/CohortService"
import {
	Stack,
	IStackTokens,
	Spinner,
	MessageBar,
	MessageBarType,
} from "@fluentui/react"
import "./NewCohortModal.css"
import { TextField } from '../../../ui-kit/TextField'
import { PrimaryButton } from "../../../ui-kit/PrimaryButton"
import { container } from "../../../DIContainer"
import { TYPES } from "../../../Types"
import { IDropdownOption } from "@fluentui/react"
import { DocumentId } from "../../../global/TypeAliases"
import { i18nKey, Ii18n } from "../../../global/i18n"

interface NewCohortModalProps {
	clientId: DocumentId
	cohortAdded: (successMessage: string) => void
}

interface NewCohortModalState {
	name: string
	description: string
	addButtonDisabled: boolean
	showSpinner: boolean
	errorMessage: string | undefined
	clientOptions: IDropdownOption[]
	selectedClient: string | null
	clientRequestSent: boolean
	isGlobalAdmin: boolean
}

const stackStyling: IStackTokens = {
	childrenGap: "20px",
}

export default class NewCohortModal extends Component<NewCohortModalProps, NewCohortModalState> {
	private cohortService: ICohortService = container.get<ICohortService>(TYPES.ICohortService)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	state: NewCohortModalState = {
		addButtonDisabled: true,
		showSpinner: false,
		name: "",
		description: "",
		errorMessage: undefined,
		clientOptions: [],
		selectedClient: null,
		clientRequestSent: false,
		isGlobalAdmin: false
	}

	render() {
		return (
			<div id="add-new-cohort-modal-container">
				<Stack tokens={stackStyling}>
					{ this.state.errorMessage !== undefined &&
						<MessageBar
							messageBarType={MessageBarType.error}
							dismissButtonAriaLabel="Close"
							onDismiss={this.dismissErrorBanner}
						>
							{this.state.errorMessage}
						</MessageBar>
					}

					<TextField
						id="name-textfield"
						label={this.i18n.get(i18nKey.name)}
						onChange={this.nameTextFieldChanged} 
					/>

					<TextField
						id="description-textfield"
						label={this.i18n.get(i18nKey.description)}
						onChange={this.descriptionTextFieldChanged}
					/>

					{ !this.state.showSpinner &&
						<PrimaryButton
							id="new-cohort-primary-button"
							text={this.i18n.get(i18nKey.add)}
							onClick={this.addButtonClicked}
							disabled={this.state.addButtonDisabled}
						/>
					}

					{ this.state.showSpinner &&
						<Spinner
							id="spinner"
							label={this.i18n.get(i18nKey.addingGroup)}
							ariaLive="assertive"
							labelPosition="right"
						/>
					}
				</Stack>
			</div>
		)
	}
	
	dismissErrorBanner = () => {
		this.setState({ errorMessage: undefined })
	}

	addButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: undefined,
			showSpinner: true,
			addButtonDisabled: true,
		}, )

		this.cohortService
			.addCohort(this.state.name, this.state.description, this.props.clientId)
			.then(_ => {
				let message = this.state.name + ` ${this.i18n.get(i18nKey.hasBeenAdded)}`
				this.props.cohortAdded(message)
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showSpinner: false,
					addButtonDisabled: false,
				})
			})
	}

	nameTextFieldChanged = (
		_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return
		}

		this.setState({ name: newValue }, () => {
			this.enableAddButtonIfNeeded()
		})
	}

	descriptionTextFieldChanged = (
		_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string | undefined
	) => {
		if (newValue === undefined) {
			return
		}

		this.setState({ description: newValue }, () => {
			this.enableAddButtonIfNeeded()
		})
	}

	enableAddButtonIfNeeded =() => {
		let disable = true

		if (this.state.name.length > 0 && 
			this.state.description.length > 0) {
			disable = false
		}

		this.setState({ addButtonDisabled: disable })
	}
}
