import { Component, FormEvent, MouseEvent } from "react";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { 
	Image,
	MessageBar, 
	MessageBarType,
	Text,
	Stack, 
	Spinner
} from '@fluentui/react';
import { TextField } from '../../ui-kit/TextField';
import { PrimaryButton } from "../../ui-kit/PrimaryButton"
import logoLarge from "../../images/logo-large.svg";
import { AuthenticationUIStyles, AuthenticationUITokens } from "./AuthenticationUI"
import { container } from "../../DIContainer";
import { TYPES } from "../../Types";
import { IAuth } from "../../auth/Auth";
import { RoleType } from "../../models/RoleType";
import IAuthService from "../../services/AuthService";
import IUserService from "../../services/UserService"
import { Ii18n, i18nKey } from "../../global/i18n";
import { IRegistrationTokenService } from "../../services/RegistrationTokenService";
import { log } from "../../global/Logger"
import { IRouter } from "../../Router";
import { IMailService } from "../../services/MailService";
import { IEmailRegistrationCompositionService } from "../../services/EmailRegistrationCompositionService";
import { Uid } from "../../global/TypeAliases";
import { PathNames } from "../../PathNames";

interface RegistrationParams {
	token: string;
}

interface RegistrationPageState {
	registerButtonDisabled: boolean;
	spinnerActive: boolean;
	firstName: string;
	lastName: string;
	emailAddress: string;
	password: string;
	confirmPassword: string;
	errorMessage: string | null;
	goToDashboard: boolean
	initialized: boolean
	isRegistrationLinkExpired: boolean
	uidForRegistrationToken: Uid
}

export class RegistrationPage extends Component<RouteComponentProps<RegistrationParams>, RegistrationPageState> {
	private emailRegistrationCompositionService: IEmailRegistrationCompositionService = container.get<IEmailRegistrationCompositionService>(TYPES.IEmailRegistrationCompositionService)
	private authService = container.get<IAuthService>(TYPES.IAuthService)
	private userService = container.get<IUserService>(TYPES.IUserService)
	private mailService = container.get<IMailService>(TYPES.IMailService)
	private registrationTokenService = container.get<IRegistrationTokenService>(TYPES.IRegistrationTokenService)
    private auth = container.get<IAuth>(TYPES.IAuth)
	private i18n = container.get<Ii18n>(TYPES.Ii18n)
	private router = container.get<IRouter>(TYPES.IRouter)

	state: RegistrationPageState = {
		registerButtonDisabled: true,
		spinnerActive: false,
		firstName: "",
		lastName: "",
		emailAddress: "",
		password: "",
		confirmPassword: "",
		errorMessage: null,
		goToDashboard: false,
		initialized: false,
		isRegistrationLinkExpired: true,
		uidForRegistrationToken: ""
	};

	componentDidMount() {
        document.getElementById('container')?.classList.add('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.add('hidden')
		document.getElementById('navigation-bar')?.classList.add('hidden')
		this.stateFromToken()

		this.auth.addAuthObserver(this)
    }

    componentWillUnmount() {
        document.getElementById('container')?.classList.remove('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.remove('hidden')
		document.getElementById('navigation-bar')?.classList.remove('hidden')
	}

	render() {
		return (
			<Stack horizontalAlign="center" styles={AuthenticationUIStyles.containerStyle}>
				<Stack horizontalAlign="center" styles={AuthenticationUIStyles.logoStyle}>
					<Image 
						src={logoLarge} 
						alt={this.i18n.get(i18nKey.altDtsLogo)} 
						width={230} 
						height={100} 
					/>
				</Stack>

				<Stack tokens={AuthenticationUITokens.verticalGapStackTokens} styles={AuthenticationUIStyles.formStackStyles}>
					{this.state.errorMessage && 
						<MessageBar
							id="registration-page-error-banner"
							messageBarType={MessageBarType.error}
							isMultiline={true}
							onDismiss={this.dismissBanner}>
							{this.state.errorMessage} <NavLink to={PathNames.ResetPasswordRequest}>You may also register your account by resetting your password here.</NavLink>
						</MessageBar>
					}
					{ !this.state.isRegistrationLinkExpired &&
						(
							<Stack id="welcome-text-stack" horizontalAlign="center" tokens={{childrenGap: 10}}>
								<Text variant="xxLarge"><strong>{this.i18n.get(i18nKey.welcome)} {this.state.firstName}</strong></Text>
								<Text variant="medium">{this.i18n.get(i18nKey.createPasswordToRegister)}</Text>
							</Stack>
						)
					}
					<Stack tokens={AuthenticationUITokens.formFieldGapStackTokens} id="registrationForm">
						<TextField
							id="new-password-field"
							label={this.i18n.get(i18nKey.password)}
							type="password"
							onChange={this.passwordTextFieldChanged}
							autoComplete="current-password"
							name="newPasswordField"
							disabled={this.state.isRegistrationLinkExpired}
						/>
						<TextField
							id="confirm-new-password-field"
							label={this.i18n.get(i18nKey.confirmPassword)}
							type="password"
							onChange={this.confirmPasswordTextFieldChanged}
							autoComplete="new-password"
							name="confirmPasswordField"
							disabled={this.state.isRegistrationLinkExpired}
						/>

						{ !this.state.spinnerActive &&
							<PrimaryButton
								text={this.i18n.get(i18nKey.register)}
								onClick={this.registerButtonClicked}
								disabled={this.state.registerButtonDisabled}
								id="registerButton"
							/>
						}

						{ this.state.spinnerActive &&
							<Spinner
								label={this.i18n.get(i18nKey.spinnerRegisteringYourAccount)}
								ariaLive="assertive"
								labelPosition="right"
							/>
						}
					</Stack>
					<Stack horizontalAlign="center">
						<Text variant="medium">
						{this.i18n.get(i18nKey.alreadyHaveAnAccount)}{" "}
							<NavLink to="/login">{this.i18n.get(i18nKey.signIn)}</NavLink>
						</Text>
					</Stack>
				</Stack>
			</Stack>
		);
	}

	stateFromToken = () => {
		this.setState({ initialized: true })
		const token = this.props.match.params.token
		let registrationToken = this.registrationTokenService.parseToken(token)
		if (registrationToken === null) {
			log("Invalid token " + this.props.match.params.token)
			this.router.goToLogin()
		} else {
			log("Valid token")
			this.userService.getUserWithEmail(registrationToken.emailAddress)
			.then((user) => {
				if (user === null || user.status.toLowerCase() === "active" || registrationToken === null ) {
					log("User not found")
					this.router.goToLogin()
					return
				}

				log("User found with status: " + user.status)
				this.setState({
					firstName: registrationToken.firstName,
					lastName: registrationToken.lastName,
					emailAddress: registrationToken.emailAddress,
					uidForRegistrationToken: user.uid
				});
				
				/**
				 * Now that we've confirmed the token is valid, we need to make sure the 
				 * registration link (derived from the token) is also valid
				 */
				 return this.emailRegistrationCompositionService.isRegistrationLinkValid(user.uid, token)
			}).then((isValid) => {
				if (isValid) {
					this.setState({ isRegistrationLinkExpired: false })
				} else {
					log("Expired registration link")
					this.setState({ errorMessage: this.i18n.get(i18nKey.invalidRegistrationLink)})
				}
			})
			.catch(_ => {
				this.setState({errorMessage: "Registration Error"})
			})
		}
	}

	registerButtonClicked = (e: MouseEvent<HTMLButtonElement>) => {
		this.setState({ 
			errorMessage: null, 
			spinnerActive: true, 
			registerButtonDisabled: true
		});
		
		this.authService
			.signUp(this.state.firstName, 
				this.state.lastName, 
				this.state.emailAddress, 
				this.state.password, 
				RoleType.learner, 
				undefined,
				undefined)
			.then(() => {
				// Cloud function above does NOT sign the user in automatically so
				// we need to sign in for them
				return this.authService.login(this.state.emailAddress, this.state.password)
			})
			.then((user) => {
				if (user.emailVerified) {
					Promise.resolve()
				} else {
					return this.authService.verifyEmail(user)
				}
			}).then(() => {
				/**
				 * Once we've verified the user is valid, we need to delete any registration
				 * token(s) that are associated with this user's uid, and handle deletion of
				 */
				return this.registrationTokenService.deleteRegistrationTokenFor(this.state.uidForRegistrationToken)
			})
			.then(() => {
				this.mailService.deleteOnboardingReminder(this.state.emailAddress)
				this.setState({ 
					goToDashboard: true, 
					spinnerActive: false, 
					registerButtonDisabled: true 
				})
			})
			.catch(error => {
				this.setState({ 
					errorMessage: error.message, 
					spinnerActive: false, 
					registerButtonDisabled: false 
				})
			})
	}

	passwordTextFieldChanged = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({ password: newValue? newValue : ""}, () => {
			this.enableRegisterButtonIfNeeded();
		});
	}

	confirmPasswordTextFieldChanged = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({ confirmPassword: newValue? newValue : ""}, () => {
			this.enableRegisterButtonIfNeeded();
		});
	}

	enableRegisterButtonIfNeeded = () => {
		let disable = true;

		if (
			this.state.password.length > 0 &&
			this.state.confirmPassword.length > 0 &&
			this.state.password === this.state.confirmPassword
		) {
			disable = false;
		}

		this.setState({ registerButtonDisabled: disable });
	}

	dismissBanner = () => {
		this.setState({ errorMessage: null });
	}
		 /**
     * IAuthObserver delegate
     */
    onAuthStateChange(isLoggedIn: boolean): void {
        if (isLoggedIn) {
			this.router.goToDashboard()
		}
    }
}
