import { DetailsList, Stack, DetailsListLayoutMode, SelectionMode } from "@fluentui/react";
import { Component } from "react";
import { container } from "../../DIContainer";
import { i18nKey, Ii18n } from "../../global/i18n";
import { Dictionary, DocumentId } from "../../global/TypeAliases";
import { Survey } from "../../models/Survey";
import { TYPES } from "../../Types";
import PageTitle from "../../ui-kit/PageTitle";
import { QuestionCheckboxModel, QuestionCommentModel, QuestionDropdownModel, QuestionMatrixModel, QuestionRadiogroupModel, QuestionRankingModel, QuestionRatingModel, QuestionTextModel } from "survey-react";
import { ISurveyReportDataTableFactory } from "../../factories/SurveyReportDataTableFactory";
import DTSTableData from "../../models/DTSTableData";
import DTSTable from "../../ui-kit/DTSTable";
import { SurveyAggregatedReportDetailsTableRowStyles, SurveyAggregatedReportTableHeaderCustomStyles, questionNamesListStyles } from "./SurveyAggregatedReportDetailsContainerStyles";
import "./SurveyAggregatedReportDetails.css"

interface SurveyAggregatedReportDetailsContainerProps {
    survey: Survey
    surveyResponseData: Dictionary[]
    clientId: DocumentId
}

interface SurveyAggregatedReportDetailsContainerState {
    questionListItems: IQuestionListItem[],
    listColumns: any,
    selectedQuestion: IQuestionListItem | undefined
    aggregatedData: Dictionary[]
    tableData: DTSTableData | null
    pagination: boolean
}

export interface IQuestionListItem {
    key: string;
    name: string;
}

export class SurveyAggregatedReportDetailsContainer extends Component<SurveyAggregatedReportDetailsContainerProps,SurveyAggregatedReportDetailsContainerState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private dataTableFactory: ISurveyReportDataTableFactory = container.get<ISurveyReportDataTableFactory>(TYPES.ISurveyReportDataTableFactory)

    state: SurveyAggregatedReportDetailsContainerState = {
        questionListItems: [],
        listColumns: [{ key: 'nameColumn', fieldName: 'name', minWidth: 300, maxWidth: 500, isResizable: true, isMultiline: true }],
        selectedQuestion: undefined,
        aggregatedData: [],
        tableData: null,
        pagination: false
    }

    componentDidMount() {
        if (this.props.survey.pages !== undefined && this.props.survey.pages[0].elements)  {
            const elements = this.props.survey.pages[0].elements

            const questionNames: IQuestionListItem[] = elements.map((element: any) => {
                const questionNameTitle = (element.title !== undefined || element.title === "") ? element.title : element.name
                return {key: element.name, name: questionNameTitle}
            })

            const aggregatedData = this.obtainAggregatedData(questionNames)
            
            this.generateAggregatedDataTable(questionNames[0], aggregatedData)

            this.setState({
                questionListItems: questionNames,
                selectedQuestion: questionNames[0],
                aggregatedData: aggregatedData
            })
        }
    }

    render() {
        return(
            <div id="component-container">
                <Stack 
                    id="container-stack" 
                    horizontal
                    horizontalAlign="center"
                    verticalAlign="center"
                    wrap
                    tokens={{childrenGap: "1rem"}}
                >
                    <Stack id="questions-list-stack"  horizontalAlign="center">
                        <PageTitle title={this.i18n.get(i18nKey.questionsCapitalized)} isSSO/>  
                        <DetailsList
                            items={this.state.questionListItems}
                            columns={this.state.listColumns}
                            setKey="none"
                            layoutMode={DetailsListLayoutMode.justified}
                            selectionMode={SelectionMode.none}
                            selectionPreservedOnEmptyClick={true}
                            isHeaderVisible={false}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="select row"
                            onActiveItemChanged={this.onQuestionListItemClicked}
                            onItemInvoked={this.onQuestionListItemInvoked}
                            styles={questionNamesListStyles}
                        />
                    </Stack>
                    <Stack 
                        id="selected-question-aggregated-survey-data-stack" 
                        horizontalAlign="center" 
                        verticalAlign="center"
                        grow={true}
                        disableShrink 
                    >
                        { this.state.selectedQuestion !== undefined && 
                            <>
                                <PageTitle title={this.state.selectedQuestion.name} isSSO/>
                                { this.state.tableData !== null && 
                                    <DTSTable
                                        data={this.state.tableData}
                                        pagination={this.state.pagination}
                                        aria-label="survey-aggregated-report-details-data-table"
                                        highlightOnHover={true}
                                        conditionalRowStyles={SurveyAggregatedReportDetailsTableRowStyles}
                                        customStyles={SurveyAggregatedReportTableHeaderCustomStyles}
                                    />   
                                }

                                { this.state.tableData === null &&
                                    <div id="aggregated-survey-data-container">{this.i18n.get(i18nKey.aggregatedSurveyResponseDataNotSupportedYet)}</div>
                                }
                            </>
                        }
                    </Stack>
                </Stack>
            </div>
        )
    }

    onQuestionListItemInvoked = (question: IQuestionListItem): void => {
        this.setState({selectedQuestion: question})
        this.generateAggregatedDataTable(question, this.state.aggregatedData)
    };

    onQuestionListItemClicked = (question: IQuestionListItem): void => {
        this.setState({selectedQuestion: question})
        this.generateAggregatedDataTable(question, this.state.aggregatedData)
    };

    private generateAggregatedDataTable = (question: IQuestionListItem, aggregatedData: Dictionary[]) => {
        const questionElementModel = this.props.survey.pages[0].elements.find(element => element.name === question.key)
        let tableData: DTSTableData | null = null
        let pagination = false

        if (questionElementModel !== undefined) {
            if (questionElementModel instanceof QuestionRadiogroupModel) {
                const choices = questionElementModel.choices.map(choice => { return choice.propertyHash.value })
                tableData = this.dataTableFactory.createRadioChoiceGroupDataTable(question.key, choices, aggregatedData, !questionElementModel.isRequired)
            } else if (questionElementModel instanceof QuestionRankingModel) {
                const choices = questionElementModel.choices.map(choice => { return choice.propertyHash.value })
                tableData = this.dataTableFactory.createRankingGroupDataTable(question.key, choices, aggregatedData, !questionElementModel.isRequired)
            } else if (questionElementModel instanceof QuestionCheckboxModel) {
                const choices = questionElementModel.choices.map(choice => { return choice.propertyHash.value })
                tableData = this.dataTableFactory.createCheckboxGroupDataTable(question.key, choices, aggregatedData, !questionElementModel.isRequired)
            } else if (questionElementModel instanceof QuestionDropdownModel) {
                const choices = questionElementModel.choices.map(choice => { return choice.propertyHash.value })
                tableData = this.dataTableFactory.createDropdownGroupDataTable(question.key, choices, aggregatedData, !questionElementModel.isRequired)
            } else if (questionElementModel instanceof QuestionRatingModel) {
                tableData = this.dataTableFactory.createRatingGroupDataTable(question.key, questionElementModel.rateMin, questionElementModel.rateMax, questionElementModel.rateStep, aggregatedData, !questionElementModel.isRequired)
            } else if (questionElementModel instanceof QuestionMatrixModel) {
                const columns = questionElementModel.propertyHash.columns.map((column: any) => { return column.propertyHash.value})
                const rows = questionElementModel.propertyHash.rows.map((row: any) => { return row.propertyHash.value})
                tableData = this.dataTableFactory.createMatrixGroupDataTable(question.key, columns, rows, aggregatedData, !questionElementModel.isRequired)
            } else if (questionElementModel instanceof QuestionCommentModel || questionElementModel instanceof QuestionTextModel) {
                tableData = this.dataTableFactory.createTextGroupDataTable(question.key, aggregatedData, !questionElementModel.isRequired)
                pagination = true
            }
        }
        
        this.setState({tableData: tableData, pagination: pagination})
    }

    private obtainAggregatedData = (questionNames: IQuestionListItem[]) => {
        const filteredData: Dictionary[] = this.props.surveyResponseData.filter((response) => {
            if (response.data !== undefined) {
                return Object.keys(response.data).length > 0
            } else {
                return false
            }
        })

        let aggregatedData: Dictionary[] = []

        filteredData.forEach(response => {
            questionNames.forEach(question => {
                if (response.data[question.key] !== undefined) {
                    aggregatedData.push({[`${question.key}`]: response.data[question.key]})
                }
            })
        })

        return aggregatedData
    }
}