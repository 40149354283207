import { Dictionary } from './TypeAliases'
import i18nJson from '../global/Marketing-i18n.json'
import { injectable } from 'inversify'

export enum i18nKey {
    login,
    goToDashboard,
    altDtsLogo,
    home,
    features,
    testimonials,
    contactUs,
    learnMore,
    seeMoreTestimonials,
    landingTestimonialsTitle,
    landingTestimonialsSubTitle,
    digital,
    training,
    solutions,
    discoverMore,
    digitalTrainingSolutions,
    by,
    cyborgMobile,
    allRightsReserved,
    seeItInAction,
    landingVideoInfo,
    marketingFooterInfo,
    solutionsSubHeader,
    solutionsInfo,
    trainingSubHeader,
    trainingInfo,
    digitalSubHeader,
    digitalInfo,
    landingHeroHeader,
    landingHeroSubHeader,
    name,
    company,
    email,
    phone,
    interests,
    marketingFormDescriptionHeader,
    marketingFormDescription,
    marketingFormHeader,
    marketingFormSuccessMessage,
    marketingFormErrorMessage,
    submit,
    cyborgMobileContactEmail,
    testimonialsHeroHeader,
    testimonialsHeroSubHeader,
    featuresHeroHeader,
    featuresHeroSubHeader,
    featuresDifferentiatorsSectionName,
    featuresDifferentiatorsHeader,
    featuresDifferentiatorsParagraph1,
    featuresDifferentiatorsParagraph2,
    featuresDifferentiatorsSubHeader,
    featuresDifferentiatorsBullet1Title,
    featuresDifferentiatorsBullet1Text,
    featuresDifferentiatorsBullet2Title,
    featuresDifferentiatorsBullet2Text,
    featuresDifferentiatorsBullet3Title,
    featuresDifferentiatorsBullet3Text,
    featuresDifferentiatorsBullet4Title,
    featuresDifferentiatorsBullet4Text,
    featuresApproachSectionName,
    featuresApproachHeader,
    featuresApproachParagraph1,
    featuresApproachParagraph2,
    featuresApproachImageAltText,
    featuresConvenienceHeader,
    featuresConvenienceSubHeader,
    featuresCallSectionName,
    featuresCallHeader,
    featuresCallParagraph1,
    featuresCallParagraph2,
    featuresCallImageAltText,
    
    /* Testimonials */

    /* Bellevue School Testimonals */
    testimonialBellevueSchoolCardTitle1,
    testimonialBellevueSchoolCardText1,

    testimonialBellevueSchoolCardTitle2,
    testimonialBellevueSchoolCardText2,

    testimonialBellevueSchoolCardTitle3,
    testimonialBellevueSchoolCardText3,

    testimonialBellevueSchoolCardTitle4,
    testimonialBellevueSchoolCardText4,
    testimonialBellevueSchoolOrg,

    /* Microsoft-Office365 Testimonals */
    testimonialMicrosoftCardTitle1,
    testimonialMicrosoftCardText1,

    testimonialMicrosoftCardTitle2,
    testimonialMicrosoftCardText2,

    testimonialMicrosoftCardTitle3,
    testimonialMicrosoftCardText3,

    testimonialMicrosoftOrg,

    /* Northshore School Testimonials */
    testimonialNorthshoreSchoolCardTitle1,
    testimonialNorthshoreSchoolCardText1,

    testimonialNorthshoreSchoolCardTitle2,
    testimonialNorthshoreSchoolCardText2,

    testimonialNorthshoreSchoolCardTitle3,
    testimonialNorthshoreSchoolCardText3,
    testimonialNorthshoreSchoolOrg,

    /* Seattle Public School Testimonials */
    testimonialSeattlePublicSchoolCardTitle1,
    testimonialSeattlePublicSchoolCardText1,

    /* Renton School Testimonials */
    testimonialRentonSchoolCardTitle1,
    testimonialRentonSchoolCardText1,

    testimonialRentonSchoolCardTitle2,
    testimonialRentonSchoolCardText2,

    testimonialRentonSchoolCardTitle3,
    testimonialRentonSchoolCardText3,

    /* Issaquah School */
    testimonialIssaquahSchoolCardTitle1,
    testimonialIssaquahSchoolCardText1,

    testimonialIssaquahSchoolCardTitle2,
    testimonialIssaquahSchoolCardText2,
}   

export interface MarketingIi18n {
    get(key: i18nKey): string
}

@injectable()
export class MarketingI18n implements MarketingIi18n {
    get(key: i18nKey): string {
        return (i18nJson as Dictionary)[i18nKey[key]]
    }
}
