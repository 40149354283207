import React, { Component } from 'react'
import { Stack, Text } from '@fluentui/react'
import { MarketingButton } from './MarketingButton'
import { MarketingIi18n, i18nKey } from '../global/Marketing-i18n'
import { container } from '../DIContainer'
import { TYPES } from '../Types'

import { LandingHeroStyles } from './LandingHeroStyles'

export class LandingHero extends Component {
    private i18n = container.get<MarketingIi18n>(TYPES.MarketingIi18n)

    render() {
        return (
            <Stack styles={LandingHeroStyles.outerStackStyles} id='hero-section-container'>
                <Stack horizontal horizontalAlign='space-between' wrap>
                    <Stack styles={LandingHeroStyles.leftContentStyles} id='hero-left-content' tokens={{ childrenGap: '3vw' }}>
                        <Text styles={LandingHeroStyles.headerStyles} id='hero-sub-header'>
                            {this.i18n.get(i18nKey.landingHeroHeader)}
                        </Text>
                        <Text styles={LandingHeroStyles.subHeaderStyles} id='hero-sub-header'>
                            {this.i18n.get(i18nKey.landingHeroSubHeader)}
                        </Text>
                        <MarketingButton
                            text={this.i18n.get(i18nKey.learnMore).toUpperCase()}
                            width='13vw'
                            mobileWidth='50vw'
                            onClick={this.scrollToForm}
                            id='hero-button'
                        />
                    </Stack>
                    <Stack grow styles={LandingHeroStyles.rightContentStyles} id='hero-right-content'>
                        <video src="https://proddts.blob.core.windows.net/dts-storage/landing-hero-video.mp4" autoPlay muted loop id='hero-video' />
                    </Stack>
                </Stack>
            </Stack>
        )
    }

    scrollToForm = () => {
        const form = document.getElementById('marketing-form')

        if (form) {
            form.scrollIntoView({ behavior: 'smooth' })
        }
    }
}
