
export class LearningPlanContentGridItemStyles {

    static noShowProgressionArrowImageStyle = {
        root: {
            alignContent: 'center'
        }, 
        image: {
            opacity: 0
        }
    }

    static showRedProgressionArrowImageStyle = {
        root: {
            alignContent: 'center'
        }, 
        image: {
            opacity: 100,
            filter: 'invert(18%) sepia(66%) saturate(6545%) hue-rotate(352deg) brightness(95%) contrast(94%)'
        }
    }

    static showGreenProgressionArrowImageStyle = {
        root: {
            alignContent: 'center'
        }, 
        image: {
            opacity: 100,
            filter: 'invert(68%) sepia(26%) saturate(7094%) hue-rotate(79deg) brightness(113%) contrast(96%)'
        }
    }
}