import { Component, ReactElement } from "react";
import { Stack } from "@fluentui/react";
import { Survey } from "../../../models/Survey";
import { ShortTextPropertiesPanel } from "./interaction_panels/ShortTextPropertiesPanel";
import { LongTextPropertiesPanel } from "./interaction_panels/LongTextPropertiesPanel";
import { 
    QuestionCheckboxModel, 
    QuestionCommentModel, 
    QuestionTextModel,
    QuestionMatrixModel, 
    QuestionRadiogroupModel, 
    QuestionRatingModel, 
    QuestionRankingModel,
    QuestionDropdownModel,
    Question
} from "survey-react";
import { SurveySubPanel } from "./SurveySubPanel";
import { PrimaryButton } from "../../../ui-kit/PrimaryButton";
import { container } from "../../../DIContainer";
import { TYPES } from "../../../Types";
import { ISurveyFactory } from "./SurveyFactory";
import { i18nKey, Ii18n } from "../../../global/i18n";
import { RatingPropertiesPanel } from "./interaction_panels/RatingPropertiesPanel";
import { MatrixPropertiesPanel } from "./interaction_panels/MatrixPropertiesPanel";
import { SurveyPackage } from "./SurveyPage";
import { MultichoicePropertiesPanel } from "./interaction_panels/MultichoicePropertiesPanel";
import { DestructiveButton } from "../../../ui-kit/DestructiveButton";

interface SurveyPropertiesPanelProps {
    surveyPackage: SurveyPackage
    didUpdateSurvey: (survey: Survey) => void
    didUpdatePreviewImage: (imageFile: File) => void
    focusedInteractionId: string
    interactionFocused: (id: string) => void
}

interface SurveyPropertiesPanelState {}

export class SurveyPropertiesPanel extends Component<SurveyPropertiesPanelProps, SurveyPropertiesPanelState> {
    private surveyFactory: ISurveyFactory = container.get<ISurveyFactory>(TYPES.ISurveyFactory)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    
    render() {
        return (
            <Stack tokens={{ childrenGap: 20 }}>
                {this.renderInteraction()}

                { this.props.focusedInteractionId !== "" &&
                    <>
                        <DestructiveButton
                            id="delete-interaction"
                            text={this.i18n.get(i18nKey.deleteInteraction)}
                            iconProps={{ iconName: 'Delete' }}
                            ariaLabel="delete"
                            onClick={() => this.removeInteraction(this.props.focusedInteractionId)}
                        />
                        <PrimaryButton
                            id="duplicate-interaction" 
                            text={this.i18n.get(i18nKey.duplicateInteraction)}
                            iconProps={{ iconName: 'Copy' }} 
                            ariaLabel="duplicate"
                            onClick={() => this.duplicateInteraction(this.props.focusedInteractionId)}
                        />
                        { this.props.surveyPackage.survey.pages[0] !== undefined && 
                            this.props.surveyPackage.survey.pages[0].elements.length > 1 &&
                            <>
                                <PrimaryButton
                                    id="move-interaction-up"
                                    text={this.i18n.get(i18nKey.moveUp)}
                                    iconProps={{ iconName: 'Up' }} 
                                    ariaLabel="move-up"
                                    onClick={() => this.moveInteractionUp(this.props.focusedInteractionId)}
                                />
                                <PrimaryButton
                                    id="move-interaction-down"
                                    text={this.i18n.get(i18nKey.moveDown)}
                                    iconProps={{ iconName: 'Down' }} 
                                    ariaLabel="move-down"
                                    onClick={() => this.moveInteractionDown(this.props.focusedInteractionId)}
                                />
                            </>
                        }
                    </>
                }
            </Stack>
        )
    }

    toggleRequireInteraction = (survey: Survey, model: Question, checked: boolean) => {
        model.isRequired = checked

        if (checked === true) {
            model.requiredErrorText = `${this.i18n.get(i18nKey.requiredErrorMessage)}`
        }

        if (model instanceof QuestionMatrixModel) {
            model.isAllRowRequired = checked
        }

        this.props.didUpdateSurvey(survey)
        this.props.interactionFocused(model.name)
    }

    /**
     * Private Functions
     */

    private renderInteraction() {
        let element: ReactElement = <SurveySubPanel 
                                        surveyPackage={this.props.surveyPackage} 
                                        didUpdateSurvey={this.props.didUpdateSurvey} 
                                        didUpdatePreviewImage={this.props.didUpdatePreviewImage} 
                                    />
        
        if (this.props.surveyPackage.survey.pages.length > 0) {
            let model = this.props.surveyPackage.survey.pages[0].elements.find(element => element.name === this.props.focusedInteractionId)
    
            if (model instanceof QuestionCommentModel) {
                element = <LongTextPropertiesPanel 
                    survey={this.props.surveyPackage.survey} 
                    model={model} 
                    didUpdateSurvey={this.props.didUpdateSurvey} 
                    toggleRequireInteraction={this.toggleRequireInteraction}
                />
            } else if (model instanceof QuestionTextModel) {
                element = <ShortTextPropertiesPanel 
                    survey={this.props.surveyPackage.survey} 
                    model={model} 
                    didUpdateSurvey={this.props.didUpdateSurvey} 
                    toggleRequireInteraction={this.toggleRequireInteraction}
                />
            } else if (model instanceof QuestionRadiogroupModel) {
                element = <MultichoicePropertiesPanel
                    panelTitle={this.i18n.get(i18nKey.radioButtonProperties)}
                    hasOtherChoice={true}
                    survey={this.props.surveyPackage.survey} 
                    model={model} 
                    didUpdateSurvey={this.props.didUpdateSurvey}
                    toggleRequireInteraction={this.toggleRequireInteraction} 
                />
            /**
             * QuestionRankingModel extends QuestionCheckboxModel, so 
             * this else-if branch needs to be before QuestionCheckboxModel or 
             * the incorrect PropertiesPanel will render. 
             */
            } else if (model instanceof QuestionRankingModel) {
                element = <MultichoicePropertiesPanel
                    panelTitle={this.i18n.get(i18nKey.rankingProperties)}
                    hasOtherChoice={false}
                    survey={this.props.surveyPackage.survey} 
                    model={model} 
                    didUpdateSurvey={this.props.didUpdateSurvey}
                    toggleRequireInteraction={this.toggleRequireInteraction} 
                />
            } else if (model instanceof QuestionCheckboxModel) {
                element = <MultichoicePropertiesPanel
                    panelTitle={this.i18n.get(i18nKey.checkboxProperties)}
                    hasOtherChoice={true}
                    survey={this.props.surveyPackage.survey} 
                    model={model} 
                    didUpdateSurvey={this.props.didUpdateSurvey}
                    toggleRequireInteraction={this.toggleRequireInteraction} 
                />
            } else if (model instanceof QuestionRatingModel) {
                element = <RatingPropertiesPanel
                    survey={this.props.surveyPackage.survey}
                    model={model}
                    didUpdateSurvey={this.props.didUpdateSurvey}
                    toggleRequireInteraction={this.toggleRequireInteraction}
                />
            } else if (model instanceof QuestionMatrixModel) {
                element = <MatrixPropertiesPanel
                    survey={this.props.surveyPackage.survey}
                    model={model}
                    didUpdateSurvey={this.props.didUpdateSurvey}
                    toggleRequireInteraction={this.toggleRequireInteraction}
                />
            } else if (model instanceof QuestionDropdownModel) {
                element = <MultichoicePropertiesPanel
                    panelTitle={this.i18n.get(i18nKey.dropDownProperties)}
                    hasOtherChoice={true}
                    survey={this.props.surveyPackage.survey} 
                    model={model} 
                    didUpdateSurvey={this.props.didUpdateSurvey}
                    toggleRequireInteraction={this.toggleRequireInteraction} 
                />
            }
        }

        return element
    }
    
    private removeInteraction = (id: string) => {
        const surveyInteractionData = this.surveyFactory.removeInteraction(this.props.surveyPackage.survey, id)

        this.props.didUpdateSurvey(surveyInteractionData.survey)
        this.props.interactionFocused(surveyInteractionData.interactionId)
    }

    private duplicateInteraction = (id: string) => {
        const survey = this.props.surveyPackage.survey
        const interactionToDuplicate = survey.pages[0].getQuestionByName(id)
        const surveyInteractionData = this.surveyFactory.duplicateInteraction(survey, interactionToDuplicate)
        
        this.props.didUpdateSurvey(surveyInteractionData.survey)
        this.props.interactionFocused(surveyInteractionData.interactionId)
    }

    private moveInteractionUp = (id: string) => {
        const survey = this.props.surveyPackage.survey
        const interactionToMoveUp = survey.pages[0].getQuestionByName(id)
        const srcIndex = survey.pages[0].elements.indexOf(interactionToMoveUp)
        let targetIndex = srcIndex - 1

        if (targetIndex < 0) {
            return
        }

        const surveyInteractionData = this.surveyFactory.reorderInteraction(survey, interactionToMoveUp, targetIndex)
        this.props.didUpdateSurvey(surveyInteractionData.survey)
        this.props.interactionFocused(surveyInteractionData.interactionId)
    }

    private moveInteractionDown = (id: string) => {
        const survey = this.props.surveyPackage.survey
        const interactionToMoveDown = survey.pages[0].getQuestionByName(id)
        const srcIndex = survey.pages[0].elements.indexOf(interactionToMoveDown)
        let targetIndex = srcIndex + 1

        if (targetIndex >= survey.pages[0].elements.length) {
            return
        }

        const surveyInteractionData = this.surveyFactory.reorderInteraction(survey, interactionToMoveDown, targetIndex)
        this.props.didUpdateSurvey(surveyInteractionData.survey)
        this.props.interactionFocused(surveyInteractionData.interactionId)
    }
}