import { injectable } from "inversify";
import { QuestionCheckboxModel, QuestionMatrixModel, QuestionRadiogroupModel, QuestionRankingModel } from "survey-react";
import { Survey } from "../../../models/Survey";


export interface ISurveyValidator {
    validateQuestionChoices(survey: Survey): boolean
}

@injectable()
export class SurveyValidator implements ISurveyValidator {
    validateQuestionChoices(survey: Survey): boolean {
        let areChoicesUnique = true

        survey.pages[0].elements.forEach(element => {
            if (element instanceof QuestionRankingModel || element instanceof QuestionCheckboxModel || element instanceof QuestionRadiogroupModel) {
                for (let i = 0; i < element.choices.length; i++) {
                    const comparedChoice = element.choices[i]
                    for (let j = i+1; j < element.choices.length; j++) {
                        if (comparedChoice.value === element.choices[j].value) {
                            areChoicesUnique = false
                        } 
                    }
                }
            } else if (element instanceof QuestionMatrixModel) { 
                for (let i = 0; i < element.rows.length; i++) {
                    const comparedChoice = element.rows[i]
                    for (let j = i+1; j < element.rows.length; j++) {
                        if (comparedChoice.value === element.rows[j].value) {
                            areChoicesUnique = false
                        } 
                    }
                }

                for (let i = 0; i < element.columns.length; i++) {
                    const comparedChoice = element.columns[i]
                    for (let j = i+1; j < element.columns.length; j++) {
                        if (comparedChoice.value === element.columns[j].value) {
                            areChoicesUnique = false
                        } 
                    }
                }
            } else {
                return;
            }
        })

        return areChoicesUnique
    }

}