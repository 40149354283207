import { Stack, Text } from "@fluentui/react";
import { Component } from "react";
import Iframe from "react-iframe";
import { container } from "../../DIContainer";
import { i18nKey, Ii18n } from "../../global/i18n";
import { ENVIRONMENT } from "../../services/Firebase";
import { TYPES } from "../../Types";
import PageTitle from "../../ui-kit/PageTitle";

interface GlobalAdminReportHomePageProps {}

interface GlobalAdminReportHomePageState {}

export class GlobalAdminReportHomePage extends Component<GlobalAdminReportHomePageProps, GlobalAdminReportHomePageState> {
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

    state: GlobalAdminReportHomePageState = {}

    componentDidMount() {}

    render() {
        const pageTitle = this.i18n.get(i18nKey.globalAdminPageTitle)
        let disclaimerTitle = null

        if(process.env.REACT_APP_ENV !== ENVIRONMENT.prod) {
            disclaimerTitle = "*Displayed Data Is Production Data*"
        }

        return(
            <div id="global-admin-report-home-page-container">
                    <PageTitle title={pageTitle} />

                { disclaimerTitle !== null &&
                    <Text>{disclaimerTitle}</Text>
                }
                <Stack id="global-admin-report-home-page-container-stack"
                        verticalFill={true}>
                        <Iframe id="global-admin-report-home-page-dashboard-iframe" 
                                url="https://ll.cyborgmobile.com/dashboards/60305b5e21050f3c36039892/6030640621050f3c3603994e/Shareable"
                                height="1200"
                                width="1600"
                                scrolling="no" 
                                frameBorder={0} />
                </Stack>
            </div>    
    )}
}