import React, { Component, FormEvent, MouseEvent } from "react"
import { NavLink } from "react-router-dom"
import { 
	Image,
	MessageBar, 
	MessageBarType,
	Text,
	Stack, 
} from '@fluentui/react'
import { AuthenticationUIStyles, AuthenticationUITokens } from "./AuthenticationUI"
import logoLarge from "../../images/logo-large.svg"
import { IAuthService } from "../../services/AuthService"
import { TextField } from '../../ui-kit/TextField'
import { PrimaryButton } from "../../ui-kit/PrimaryButton"
import { container } from "../../DIContainer"
import { TYPES } from "../../Types"
import { Ii18n, i18nKey } from "../../global/i18n"
import { Spinner } from "@fluentui/react"
import { Loader } from "../../ui-kit/Loader"

interface ResetPasswordPageProps {}
interface ResetPasswordPageState {
	showPageLoader: boolean
	primaryButtonDisabled: boolean
	showSpinner: boolean
	password: string
    confirmPassword: string
	errorMessage: string | null
	successMessage: string | null
}

export default class ResetPasswordPage extends Component<ResetPasswordPageProps, ResetPasswordPageState> {
	private authService: IAuthService = container.get<IAuthService>(TYPES.IAuthService)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	state: ResetPasswordPageState = {
		showPageLoader: true,
		primaryButtonDisabled: true,
		showSpinner: false,
		password: "",
        confirmPassword: "",
		errorMessage: null,
		successMessage: null,
	}

	actionCode: string = ""
	
	componentDidMount() {
        document.getElementById('container')?.classList.add('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.add('hidden')
        document.getElementById('navigation-bar')?.classList.add('hidden')

		this.getOobCodeFromURL()
    }

    componentWillUnmount() {
        document.getElementById('container')?.classList.remove('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.remove('hidden')
        document.getElementById('navigation-bar')?.classList.remove('hidden')
	}

    render() {
		if (this.state.showPageLoader) {
            return <Loader /> 
        } 
		
		return (
			<Stack horizontalAlign="center" styles={AuthenticationUIStyles.containerStyle}>
				<Stack horizontalAlign="center" styles={AuthenticationUIStyles.logoStyle}>
					<Image 
						id="dts-logo" 
						src={logoLarge} 
						alt={this.i18n.get(i18nKey.altDtsLogo)} 
						width={230} 
						height={100} 
					/>
				</Stack>
				<Stack tokens={AuthenticationUITokens.verticalGapStackTokens} styles={AuthenticationUIStyles.formStackStyles}>
					{this.state.errorMessage != null && 
						<MessageBar
							id="reset-password-error-banner"
							messageBarType={MessageBarType.error}
							onDismiss={this.dismissBanner}>
							{this.state.errorMessage}
						</MessageBar>
					}
					{ this.state.successMessage !== null &&
                        <MessageBar
                            id="reset-password-success-banner"
                            messageBarType={MessageBarType.success}
                            dismissButtonAriaLabel="Close"
                            onDismiss={this.dismissSuccessBanner}>
                            {this.state.successMessage}
                        </MessageBar>
                    }
					<Stack tokens={AuthenticationUITokens.formFieldGapStackTokens} id="reset-password">
						<TextField
							id="new-password-textfield"
							label={this.i18n.get(i18nKey.newPassword)}
							type="password"
							onChange={this.passwordTextFieldChanged}
							errorMessage={(this.state.password.length < 6 && this.state.errorMessage === null) ? 
								this.i18n.get(i18nKey.passwordsTooShort) : ""}
						/>
                        <TextField
							id="confirm-password-textfield"
							label={this.i18n.get(i18nKey.confirmPassword)}
							type="password"
							onChange={this.confirmPasswordTextFieldChanged}
							errorMessage={(this.state.password !== this.state.confirmPassword && this.state.errorMessage === null) ? 
								this.i18n.get(i18nKey.passwordsDontMatch) : ""}
						/>
						{ !this.state.showSpinner &&
							<PrimaryButton
								id="reset-password-primary-button"
								text={this.i18n.get(i18nKey.changePassword)}
								onClick={this.submitButtonClicked}
								disabled={this.state.primaryButtonDisabled}
							/>
						}
						{ this.state.showSpinner &&
							<Spinner
								id="reset-password-spinner"
								ariaLive="assertive"
								labelPosition="right"
							/>
						}
					</Stack>
					<Stack horizontalAlign="center" tokens={{ childrenGap: 10 }}>
						<Text variant="medium">
							<NavLink to="/login" id="back-to-login-link">{this.i18n.get(i18nKey.backToLogin)}</NavLink>
						</Text>
						<Text variant="medium">
							<NavLink to="/reset_password_request" id="back-to-reset-password-request-link">{this.i18n.get(i18nKey.requestNewResetLink)}</NavLink>
						</Text>
					</Stack>
				</Stack>
			</Stack>
        )
    }

	getOobCodeFromURL = () => {
		const urlSearchParams = new URLSearchParams(window.location.search)
		const params = Object.fromEntries(urlSearchParams.entries())
		const oobCode = params["oobCode"]
		
		this.setState({ showPageLoader: true })
		this.authService
			.verifyOobCode(oobCode)
			.then(() => {
				this.actionCode = oobCode
				this.setState({
					showPageLoader: false
				})
			}).catch(error => {
				this.setState({ 
					errorMessage: this.i18n.get(i18nKey.invalidActionCode),
					showPageLoader: false
				})	
			})
	}

	disableResetButtonIfNeeded = () => {
		let disable = true
		const minCharPasswordRequirement = 6

		if (
			this.state.password.length > minCharPasswordRequirement &&
			this.state.confirmPassword.length > minCharPasswordRequirement &&
			this.state.password === this.state.confirmPassword &&
			this.state.successMessage === null &&
			this.state.errorMessage === null
			) { 
			disable = false
		}

		this.setState({ primaryButtonDisabled: disable })
	}

	dismissBanner = () => {
		this.setState({ errorMessage: null })
	}

	dismissSuccessBanner = () => {
        this.setState({ successMessage: null })
    }

	passwordTextFieldChanged = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({ password: newValue ? newValue : ""}, () => {
			this.disableResetButtonIfNeeded()
		})
	}

    confirmPasswordTextFieldChanged = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({ confirmPassword: newValue ? newValue : ""}, () => {
			this.disableResetButtonIfNeeded()
		})
	}

	submitButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			showSpinner: true,
			errorMessage: null,
			successMessage: null
		})

		this.authService
			.handleResetPassword(this.actionCode, this.state.confirmPassword)
			.then(() => {
				this.setState({ 
					showSpinner: false,
					primaryButtonDisabled: true,
					successMessage: this.i18n.get(i18nKey.changePasswordSuccess)
				})
			}).catch(error => {
				this.setState({ 
					showSpinner: false,
					errorMessage: this.i18n.get(i18nKey.failedToResetPassword),
				})	
			})
	}
}