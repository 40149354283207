import { ActionButton } from "@fluentui/react";
import React, { Component } from "react";
import { container } from "../DIContainer";
import { i18nKey, Ii18n } from "../global/i18n";
import { DocumentId } from "../global/TypeAliases";
import { IClientService } from "../services/ClientService";
import { TYPES } from "../Types";
import { ClientContextSelectorStyles } from "./ClientContextSelectorStyles";
import { SelectClientDialog } from "./SelectClientDialog";

interface ClientContextSelectorProps {
    clientId: DocumentId | null
}

interface ClientContextSelectorState {
    showDialog: boolean
    actionButtonText: string
}

export class ClientContextSelector extends Component<ClientContextSelectorProps, ClientContextSelectorState> {
    private clientService: IClientService = container.get<IClientService>(TYPES.IClientService)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    
    state: ClientContextSelectorState = {
        showDialog: false,
        actionButtonText: ""
    }

    componentDidMount() {
        if (this.props.clientId) {
            this.getClient(this.props.clientId)
        } else {
            this.setState({ actionButtonText: this.i18n.get(i18nKey.selectClient)})
        }
    }

    render() {
        return (
            <>
                <ActionButton 
                    id="actionButton" 
                    onClick={this.actionButtonClicked} 
                    styles={ClientContextSelectorStyles.actionButtonStyles}
                    text={this.state.actionButtonText}
                />
                                    
                { this.state.showDialog &&
                    <SelectClientDialog
                        target="#actionButton" 
                        onDismiss={this.dismissDialog}
                        onSelectClientClicked={this.didSelectClient}
                        clientId={this.props.clientId}
                    />
                }
            </> 
        )
    }
    
    /**
     * Public Functions
     */

    didSelectClient = (_: DocumentId) => {
        this.dismissDialog()
    }

    /**
     * Private Functions
     */

    private getClient = (clientId: DocumentId) => {
        this.clientService
            .clientForId(clientId)
            .then(client => {
                this.setState({ actionButtonText: client.name })
            }).catch(_ => {
                this.setState({ actionButtonText: this.i18n.get(i18nKey.selectClient) })
            })
    }

    private actionButtonClicked = () => {
        this.setState({ showDialog: true })
    }

    private dismissDialog = () => {
        this.setState({ showDialog: false })
    }
}
