import { Dropdown, IDropdownOption, MessageBar, MessageBarType, Modal, SearchBox, Spinner, Stack, Text } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { IAuth } from "../../../auth/Auth";
import { container } from "../../../DIContainer";
import { IDataTableFactory } from "../../../factories/DataTableFactory";
import { i18nKey, Ii18n } from "../../../global/i18n";
import { Uid } from "../../../global/TypeAliases";
import DTSTableData from "../../../models/DTSTableData";
import { UserDocumentKey } from "../../../models/User";
import { IRouter } from "../../../Router";
import { IUserService } from "../../../services/UserService";
import { TYPES } from "../../../Types";
import DTSTable from "../../../ui-kit/DTSTable";
import { FontSize } from "../../../ui-kit/FontSize";
import PageTitle from "../../../ui-kit/PageTitle";
import { PrimaryButton } from "../../../ui-kit/PrimaryButton";
import { SecondaryButton } from "../../../ui-kit/SecondaryButton";
import { LogInAsStyles } from "./LogInAsStyles";
import './LogInAsPage.css'

interface LogInAsPageProperties {}

interface LogInAsPageState {
    searchTerm: string
    showSpinner: boolean
    showYesSpinner: boolean
    tableData: DTSTableData | null
    selectedUser: SelectedUser | null
    selectedFilterKey: UserDocumentKey
    successMessage: string | null
    errorMessage: string | null
    showModal: boolean
}

interface SelectedUser {
    uid: Uid
    name: string
}

export class LogInAsPage extends Component<LogInAsPageProperties, LogInAsPageState> {
    private userService: IUserService = container.get<IUserService>(TYPES.IUserService)
    private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
    private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)
    private auth: IAuth = container.get<IAuth>(TYPES.IAuth)
    private router: IRouter = container.get<IRouter>(TYPES.IRouter)

    state: LogInAsPageState = {
        searchTerm: "",
        showSpinner: false,
        showYesSpinner: false,
        tableData: null,
        selectedUser: null,
        selectedFilterKey: UserDocumentKey.Email,
        successMessage: null,
        errorMessage: null,
        showModal: false
    }

    
    private queryFilters: IDropdownOption[] = [
        { key: UserDocumentKey.Email, text: this.i18n.get(i18nKey.logInAsEmailAddress)},
        { key: UserDocumentKey.FirstName, text: this.i18n.get(i18nKey.logInAsFirstName)},
        { key: UserDocumentKey.LastName, text: this.i18n.get(i18nKey.logInAsLastName)},
        { key: UserDocumentKey.UID, text: this.i18n.get(i18nKey.logInAsUid)}
    ]

    render() {
        return (
        <>
            <PageTitle title={this.i18n.get(i18nKey.logInAsTitle)} />
            <Stack tokens={LogInAsStyles.tokens} styles={LogInAsStyles.stackStyles}>
    
                { this.createBannerIfNeeded() }

                <Stack tokens={LogInAsStyles.tokens} styles={LogInAsStyles.searchStack}  horizontal={true}>
                    <Text 
                        variant={FontSize.medium} 
                        styles={LogInAsStyles.searchByTextStyles}>
                            {this.i18n.get(i18nKey.searchLearnersBy)}
                    </Text>
                    <Dropdown
                        id="filters"
                        styles={LogInAsStyles.dropDownStyles}
                        defaultSelectedKey={this.state.selectedFilterKey}
                        options={this.queryFilters} 
                        onChange={this.onQueryFilterChange}
                        required={true}
                    />
                    <SearchBox 
                        id="searchBox"
                        styles={LogInAsStyles.searchBoxStyles}
                        placeholder={this.i18n.get(i18nKey.logInAsSearchPlaceholder)}
                        onSearch={this.onSearch}
                        onChange={this.onSearchBoxChange}
                    />
                    <PrimaryButton 
                        id="search-button"
                        text={this.i18n.get(i18nKey.search)} 
                        onClick={this.searchButtonClicked} 
                    />
                </Stack>

                { this.state.showSpinner &&
                    <Spinner
                        id="spinner"
                        ariaLive="assertive"
                        labelPosition="right"
                    />
                }
                
                { this.state.tableData &&
                    <DTSTable
                        data={this.state.tableData}
                        pagination={true}
                        onRowClicked={this.onRowClicked}
                        pointerOnHover={true}
                    />
                }

                { this.state.showModal && this.state.selectedUser &&
                    <Modal
                        titleAriaId="confirmation modal"
                        isOpen={this.state.showModal}
                        onDismiss={this.dismissModal}
                        isBlocking={false}
                        isDarkOverlay={false}
                        overlay={{styles: LogInAsStyles.modalOverlayStyles}}
                    >
                        <Stack styles={LogInAsStyles.modalStackStyles} tokens={LogInAsStyles.tokens}>
                            <Text variant={FontSize.large}>
                                {this.i18n.get(i18nKey.logInAsTheLearner)}
                            </Text>
                            <Text variant={FontSize.medium}>
                                { this.state.selectedUser.name }
                            </Text>
                            <Stack tokens={LogInAsStyles.defaultTokens} horizontal>
                                { !this.state.showYesSpinner &&
                                    <>
                                        <PrimaryButton 
                                            id="yesButton" 
                                            text={this.i18n.get(i18nKey.yes)} 
                                            onClick={this.primaryButtonClicked} 
                                        />
                                        <SecondaryButton 
                                            id="cancelButton" 
                                            text={this.i18n.get(i18nKey.cancel)}
                                            onClick={this.dismissModal} 
                                        />
                                    </>
                                }

                                { this.state.showYesSpinner &&
                                    <Spinner
                                        id="yes-spinner"
                                        ariaLive="assertive"
                                        labelPosition="right"
                                    />
                                }
                            </Stack>
                        </Stack>
                    </Modal>
                }
            </Stack>
        </>
        )
    }

    /**
     * Public Functions
     */

     onSearch = (value: string) => {
        this.setState({ errorMessage: null })
        
        this.userService
            .searchUsers(value, this.state.selectedFilterKey)
            .then(users => {
                const tableData = this.dataTableFactory.createUsersTableData(users)
                
                this.setState({
                    showSpinner: false,
                    tableData: tableData
                })
            }).catch(_ => {
                this.setState({ 
                    showSpinner: false,
                    errorMessage: this.i18n.get(i18nKey.failedToGetLearners)
                })
            })
    }

    dismissModal = () => {
        this.setState({ showModal: false })
    }

    onRowClicked = (row: {[key: string]: string}) => {
        const selectedUser: SelectedUser = {
            uid: row.uid,
            name: row.name 
        }

        this.setState({ 
            selectedUser: selectedUser, 
            showModal: true 
        })
    }

    onQueryFilterChange = (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (!option) {
            return
        }

        this.setState({ selectedFilterKey: option.key as UserDocumentKey})
    }

    dismissBanner = () => {
        this.setState({ errorMessage: null, successMessage: null })
    }

    /**
     * Private Functions
     */

    private onSearchBoxChange = (_?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        let searchTerm = ''

        if (newValue) {
            searchTerm = newValue
        }

        this.setState({ searchTerm: searchTerm })
    }

    private searchButtonClicked = () => {
        this.onSearch(this.state.searchTerm)        
    }

    private primaryButtonClicked = () => {
        if (this.state.selectedUser === null) {
            return
        }

        this.setState({ showYesSpinner: true })

        this.auth
            .logInAs(this.state.selectedUser.uid)
            .then(() => {
                this.router.refreshPage()
            }).catch(_ => {
                const errorMessage = `${this.i18n.get(i18nKey.failedToLogInAs)} ${this.state.selectedUser?.name}. ${this.i18n.get(i18nKey.pleaseTryAgain)}`
                this.setState({ 
                    errorMessage: errorMessage, 
                    showModal: false, 
                    showYesSpinner: false 
                })
            })
    }

    private createBannerIfNeeded = () => {
        let banner: ReactNode = null

        if (this.state.successMessage) {
            banner =
                <MessageBar
                    id="success-banner"
                    messageBarType={MessageBarType.success}
                    dismissButtonAriaLabel="Close"
                    onDismiss={this.dismissBanner}
                >
                    {this.state.successMessage}
                </MessageBar>
        } else if (this.state.errorMessage) {
            banner =
                <MessageBar
                    id="error-banner"
                    messageBarType={MessageBarType.error}
                    dismissButtonAriaLabel="Close"
                    onDismiss={this.dismissBanner}
                >
                    {this.state.errorMessage}
                </MessageBar>
        }

        return banner
    }
}
