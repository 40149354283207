import { FC } from "react";
import { 
    PrimaryButton as FluentUIPrimaryButton,
    IButtonProps,
    IButtonStyles,
} from '@fluentui/react';
import { Dictionary } from "../global/TypeAliases";
import { Theme } from "./Theme";

export const DestructiveButton: FC<IButtonProps> = (props) => {

    const height = 40
    const cornerRadius = 5

    const style: Dictionary = {
        root: {
            height: `${height}px`,
            borderRadius: `${cornerRadius}px`,
            backgroundColor: Theme.destructiveColor.primary,
            borderColor: Theme.destructiveColor.primary
        },
        rootHovered: {
            backgroundColor: Theme.destructiveColor.secondary,
            borderColor: Theme.destructiveColor.secondary
        },
        rootPressed: {
            backgroundColor: Theme.destructiveColor.tertiary,
            borderColor: Theme.destructiveColor.tertiary
        },
        
    }

    if (props.width) {
        style.root['width'] = props.width
    }

    const buttonStyle: Partial<IButtonStyles> = style

    return (
        <FluentUIPrimaryButton 
            {...props} 
            styles={buttonStyle}
        />  
    )
}