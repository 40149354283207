import { Question } from 'survey-react';
import { ISurveyInteractionFactory } from '../../../models/SurveyInteractionFactory';
import { UUID } from './../../../utils/UUIDGenerator'
import { injectable } from 'inversify';
import { Survey } from './../../../models/Survey'
import { container } from '../../../DIContainer';
import { TYPES } from '../../../Types';
import { DocumentId } from '../../../global/TypeAliases';
import { InteractionType } from './SurveyPage';

export interface ISurveyFactory {
    create(clientId: DocumentId): Survey
    removeInteraction(survey: Survey, interactionId: string): SurveyInteractionData
    removeAllInteractions(survey: Survey): SurveyInteractionData
    addShortTextInteraction(survey: Survey, interactionTitle?: string): SurveyInteractionData
    addLongTextInteraction(survey: Survey, interactionTitle?: string): SurveyInteractionData
    addRadioButtonsInteraction(survey: Survey, interactionTitle?: string): SurveyInteractionData
    addCheckboxInteraction(survey: Survey, interactionTitle?: string): SurveyInteractionData
    addRatingInteraction(survey: Survey, interactionTitle?: string): SurveyInteractionData
    addMatrixInteraction(survey: Survey, interactionTitle?: string): SurveyInteractionData
    addRankingInteraction(survey: Survey, interactionTitle?: string): SurveyInteractionData
    addDropDownInteraction(survey: Survey, interactionTitle?: string): SurveyInteractionData
    duplicateInteraction(survey: Survey, interaction: Question): SurveyInteractionData
    duplicateSurvey(survey: Survey): Survey
    reorderInteraction(survey: Survey, interaction: Question, targetIndex: number): SurveyInteractionData
}
export interface SurveyInteractionData {
    survey: Survey
    interactionId: string
}

@injectable()
export class SurveyFactory implements ISurveyFactory {
    static readonly NewSurveyPrefix = "new_"
    static readonly DuplicatePrefix = "copy_"

    private uuid: UUID = container.get<UUID>(TYPES.UUID)
    private surveyInteractionFactory: ISurveyInteractionFactory = container.get<ISurveyInteractionFactory>(TYPES.ISurveyInteractionFactory)

    create(clientId: DocumentId): Survey {
        const page = this.surveyInteractionFactory.page()
        
        return new Survey(
            `${SurveyFactory.NewSurveyPrefix}${this.uuid.uuid()}`, // document id
            `${process.env.REACT_APP_ENV_LRS_HOME_PAGE_URL}survey/${this.uuid.uuid()}`, // lrs id
            "", // name
            "", // canonical name
            "", // description
            "", // image url
            "", // location url
            {}, // cohorts
            {
                [clientId]: true
            }, // clients
            "", // duration
            "", // survey folder
            [page], // survey pages
            false //anonymous reporting
        )
    }

    removeInteraction(survey: Survey, interactionId: string): SurveyInteractionData {
        const page = survey.pages[0]
        const elements = page.elements

        const index = elements.findIndex(element => element.name === interactionId)
        page.removeElement(elements[index])

        return { 
            survey: survey, 
            interactionId: elements.length > 0 ? elements[elements.length-1]["name"] : ""
        }
    }

    removeAllInteractions(survey: Survey): SurveyInteractionData {
        survey.pages[0].dispose()

        return { 
            survey: survey, 
            interactionId: ""
        }
    }

    addLongTextInteraction(survey: Survey, interactionTitle?: string): SurveyInteractionData {
        if (survey.pages.length === 0) {
            return { 
                survey: survey, 
                interactionId: "" 
            }
        }

        const newInteractionName = interactionTitle ? interactionTitle : ""
        const longText = this.surveyInteractionFactory.longText(newInteractionName)
        survey.pages[0].addElement(longText)

        return { 
            survey: survey, 
            interactionId: longText.name 
        }
    }

    addShortTextInteraction(survey: Survey, interactionTitle?: string): SurveyInteractionData {
        if (survey.pages.length === 0) {
            return { 
                survey: survey, 
                interactionId: "" 
            }
        }

        const newInteractionName = interactionTitle ? interactionTitle : ""
        const shortText = this.surveyInteractionFactory.shortText(newInteractionName)
        survey.pages[0].addElement(shortText)

        return { 
            survey: survey, 
            interactionId: shortText.name 
        }
    }

    addRadioButtonsInteraction(survey: Survey, interactionTitle?: string): SurveyInteractionData {
        if (survey.pages.length === 0) {
            return { 
                survey: survey, 
                interactionId: "" 
            }
        }
        
        const newInteractionName = interactionTitle ? interactionTitle : ""
        const radioButtons = this.surveyInteractionFactory.radioButtons(newInteractionName)
        survey.pages[0].addElement(radioButtons)

        return { 
            survey: survey, 
            interactionId: radioButtons.name 
        }
    }

    addCheckboxInteraction(survey: Survey, interactionTitle?: string): SurveyInteractionData {
        if (survey.pages.length === 0) {
            return { 
                survey: survey, 
                interactionId: "" 
            }
        }
        
        const newInteractionName = interactionTitle ? interactionTitle : ""
        const checkbox = this.surveyInteractionFactory.checkboxes(newInteractionName)
        survey.pages[0].addElement(checkbox)

        return { 
            survey: survey, 
            interactionId: checkbox.name 
        }
    }

    addRatingInteraction(survey: Survey, interactionTitle?: string): SurveyInteractionData {
        if (survey.pages.length === 0) {
            return { 
                survey: survey, 
                interactionId: "" 
            }
        }
        
        const newInteractionName = interactionTitle ? interactionTitle : ""
        const rating = this.surveyInteractionFactory.rating(newInteractionName)
        survey.pages[0].addElement(rating)

        return { 
            survey: survey, 
            interactionId: rating.name 
        }
    }

    addMatrixInteraction(survey: Survey, interactionTitle?: string): SurveyInteractionData {
        if (survey.pages.length === 0) {
            return { 
                survey: survey, 
                interactionId: "" 
            }
        }
        
        const newInteractionName = interactionTitle ? interactionTitle : ""
        const matrix = this.surveyInteractionFactory.matrix(newInteractionName)
        survey.pages[0].addElement(matrix)

        return { 
            survey: survey, 
            interactionId: matrix.name 
        }
    }

    addRankingInteraction(survey: Survey, interactionTitle?: string): SurveyInteractionData {
        if (survey.pages.length === 0) {
            return { 
                survey: survey, 
                interactionId: "" 
            }
        }
        
        const newInteractionName = interactionTitle ? interactionTitle : ""
        const ranking = this.surveyInteractionFactory.ranking(newInteractionName)
        survey.pages[0].addElement(ranking)

        return { 
            survey: survey, 
            interactionId: ranking.name 
        }
    }

    addDropDownInteraction(survey: Survey, interactionTitle?: string): SurveyInteractionData {
        if (survey.pages.length === 0) {
            return { 
                survey: survey, 
                interactionId: "" 
            }
        }
        
        const newInteractionName = interactionTitle ? interactionTitle : ""
        const dropDown = this.surveyInteractionFactory.dropDown(newInteractionName)
        survey.pages[0].addElement(dropDown)

        return { 
            survey: survey, 
            interactionId: dropDown.name 
        }
    }

    duplicateInteraction(survey: Survey, interaction: Question): SurveyInteractionData {    
        const duplicateInteractionTitle = `${SurveyFactory.DuplicatePrefix}${interaction.title}`
        const interactionType = interaction.getType()
        
        switch(interactionType) {
            case InteractionType.shortText:
                return this.addShortTextInteraction(survey, duplicateInteractionTitle)
            case InteractionType.longText:
                return this.addLongTextInteraction(survey, duplicateInteractionTitle)
            case InteractionType.radioButtons:
                return this.addRadioButtonsInteraction(survey, duplicateInteractionTitle)
            case InteractionType.checkbox:
                return this.addCheckboxInteraction(survey, duplicateInteractionTitle)
            case InteractionType.rating:
                return this.addRatingInteraction(survey, duplicateInteractionTitle)
            case InteractionType.matrix:
                return this.addMatrixInteraction(survey, duplicateInteractionTitle)
            case InteractionType.ranking:
                return this.addRankingInteraction(survey, duplicateInteractionTitle)
            case InteractionType.dropDown:
                return this.addDropDownInteraction(survey, duplicateInteractionTitle)
            default:
                return { 
                    survey: survey, 
                    interactionId: ""
                }
        }
    }

    duplicateSurvey(survey: Survey): Survey {
        const clientKeys = Object.keys(survey.clients)
        let surveyClientId: DocumentId = ""
        const cohortKeys = Object.keys(survey.cohorts)
        let surveyCohortId: DocumentId = ""
        const surveyPages = survey.pages[0]

        if (clientKeys.length > 0) {
            surveyClientId = Object.keys(survey.clients)[0]
        }

        if (cohortKeys.length > 0) {
            surveyCohortId = Object.keys(survey.cohorts)[0]
        }
        
        return new Survey(
            `${SurveyFactory.NewSurveyPrefix}${this.uuid.uuid()}`, // document id
            `${process.env.REACT_APP_ENV_LRS_HOME_PAGE_URL}survey/${this.uuid.uuid()}`, // lrs id
            `${SurveyFactory.DuplicatePrefix}_${survey.name}`, // name
            `${SurveyFactory.DuplicatePrefix}_${survey.canonicalName}${this.uuid.uuid()}`, // canonical name
            survey.description, // description
            survey.imageUrl, // image url
            "", // location url
            {
                [surveyCohortId]: true
            }, // cohorts
            {
                [surveyClientId]: true
            }, // clients
            survey.duration, // duration
            "", // survey folder
            [surveyPages], // survey pages
            survey.anonymous // anonymous reporting
        )
    }    
    
    reorderInteraction(survey: Survey, interaction: Question, targetIndex: number): SurveyInteractionData {
        survey.pages[0].removeElement(interaction)
        survey.pages[0].addElement(interaction, targetIndex)

        return { 
            survey: survey, 
            interactionId: interaction.name 
        }
    }
}