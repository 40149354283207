import React, { Component } from 'react'
import { Stack } from '@fluentui/react'
import { MarketingHeader } from '../../ui-kit/MarketingHeader'
import { MarketingFooter } from '../../ui-kit/MarketingFooter'
import { FeaturesHero } from '../../ui-kit/FeaturesHero'
import { FeaturesDifferentiators } from '../../ui-kit/FeaturesDifferentiators'
import { FeaturesApproach } from '../../ui-kit/FeaturesApproach'
import { MarketingForm } from "../../ui-kit/MarketingForm"
import { FeaturesConvenience } from '../../ui-kit/FeaturesConvenience'
import { FeaturesCall } from '../../ui-kit/FeaturesCall'

import './MarketingStyles.css'

export class FeaturesPage extends Component {
    componentDidMount() {
        document.getElementById('container')?.classList.add('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.add('hidden')
        document.getElementById('navigation-bar')?.classList.add('hidden')
        window.scrollTo(0,0)
    }

    componentWillUnmount() {
        document.getElementById('container')?.classList.remove('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.remove('hidden')
        document.getElementById('navigation-bar')?.classList.remove('hidden')
    }

    render() {
        return (
            <Stack id='marketing-content'>
                <MarketingHeader />
                <FeaturesHero />
                <FeaturesDifferentiators />
                <FeaturesConvenience />
                <FeaturesApproach />
                <FeaturesCall />
                <MarketingForm />
                <MarketingFooter />
            </Stack>
        )
    }
}
