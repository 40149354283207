import { Dictionary } from '../global/TypeAliases';

export default class Cohort {
	documentId: string;
	clients: Dictionary
	name: string;
	description: string;
	learners: Dictionary;
	isDefault: boolean;

	constructor(
		documentId: string,
		clients: Dictionary,
		name: string,
		description: string,
		learners: Dictionary,
		isDefault: boolean
	) {
		this.documentId = documentId;
		this.clients = clients;
		this.name = name;
		this.description = description;
		this.learners = learners;
		this.isDefault =  isDefault;
	}

	static Converter = {
		toFirestore: function (cohort: Cohort) {
			return {
				documentId: cohort.documentId,
				name: cohort.name,
				description: cohort.description,
				learners: cohort.learners,
				isDefault: cohort.isDefault
			};
		},
		fromFirestore: function (snapshot: any, options: any) {
			const data = snapshot.data(options);

			return new Cohort(
				data.documentId,
				data.clients,
				data.name,
				data.description,
				data.learners === undefined ? {} : data.learners,
				data.isDefault
			);
		},
	};
}
