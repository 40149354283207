import React, { useEffect, useState, MouseEvent } from "react";
import { Row, Col, Container, Form, Input, Label, UncontrolledAlert, FormFeedback } from "reactstrap";
import { NavLink } from "react-router-dom";
import logo from "../../images/logo-large-pink.svg";
import TestimonialsPageV2 from "../marketing/TestimonialsPageV2";
import { TYPES } from "../../Types";
import { container } from "../../DIContainer";
import { Ii18n, i18nKey } from "../../global/i18n";
import { IAuthService } from "../../services/AuthService";
import { IRouter } from "../../Router";
import { IFeatureFlagService } from "../../services/FeatureFlagService";
import { IUserService } from "../../services/UserService";
import { PathNames } from "../../PathNames";
import { IAuth, IAuthObserver } from "../../auth/Auth";
import { ILocalStorage } from "../../utils/LocalStorage";

interface LoginPageV2Props {
    initialErrorMessage?: string;
}

export const LoginPageV2: React.FC<LoginPageV2Props> = (props) => {
    const i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n);
    const authService: IAuthService = container.get<IAuthService>(TYPES.IAuthService);
    const auth: IAuth = container.get<IAuth>(TYPES.IAuth);
    const router: IRouter = container.get<IRouter>(TYPES.IRouter);
    const featureFlags: IFeatureFlagService = container.get<IFeatureFlagService>(TYPES.IFeatureFlagService);
    const userService: IUserService = container.get<IUserService>(TYPES.IUserService);
    const storage = container.get<ILocalStorage>(TYPES.ILocalStorage)

    const [spinnerActive, setSpinnerActive] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [infoMessage, setInfoMessage] = useState<string | undefined>(undefined);
    const [showVerifyLink, setShowVerifyLink] = useState<boolean>(false);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [touched, setTouched] = useState<{ email: boolean; password: boolean }>({
        email: false,
        password: false,
    });
    const [showPw, setShowPw] = useState<boolean>(false);

    const showUpdatePWModalStorageKey = "showUpdatePWModal"

    /**
     *  toggle left hand side nav bar from login page to dashboard
     *  subscribe and unsubscribe to the auth observer
     *  leaving [] as the second parameter b/c we want this to run on component mount and umount
     */
    useEffect(() => {
        document.getElementById("container")?.classList.add("no-padding", "no-margin");
        document.getElementById("content")?.classList.add("no-padding", "no-margin");
        document.getElementById("navigation-bar-container")?.classList.add("hidden");
        document.getElementById("navigation-bar")?.classList.add("hidden");
        auth.addAuthObserver(authObserver);

        return () => {
            document.getElementById("container")?.classList.remove("no-padding", "no-margin");
            document.getElementById("navigation-bar-container")?.classList.remove("hidden");
            document.getElementById("navigation-bar")?.classList.remove("hidden");
            auth.removeAuthObserver(authObserver);
        };
        // eslint-disable-next-line
    }, []);

    // route to dashboard when the value of isLoggedIn is changed
    useEffect(() => {
        if (auth.isLoggedIn()) {
            router.goToDashboard();
        } else if (props.initialErrorMessage) {
            setErrorMessage(props.initialErrorMessage);
        }
        // eslint-disable-next-line
    }, [isLoggedIn, props.initialErrorMessage]);

    // auth observer delegate
    const authObserver: IAuthObserver = {
        onAuthStateChange: (loggedIn: boolean) => {
            setIsLoggedIn(loggedIn);
        },
    };

    const validate = (email: string, password: string) => {
        const errors: { email?: string; password?: string } = {};
        if (!email) {
            errors.email = "Please enter your email address";
        }

        if (!password) {
            errors.password = "Please enter your password";
        }
        return errors;
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleInputBlur = (field: "email" | "password") => {
        setTouched((prevTouched) => ({ ...prevTouched, [field]: true }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const errors = validate(email, password);
        if (Object.keys(errors).length === 0) {
            signInButtonClicked(email, password);
        }
    };

    const errors = validate(email, password);

    const signInButtonClicked = (emailAddress: string, password: string) => {
        setSpinnerActive(true);
        setErrorMessage(undefined);

        userService
            .isCleverUser(emailAddress)
            .then((isCleverUser) => {
                if (isCleverUser) {
                    setInfoMessage(i18n.get(i18nKey.pleaseSignInWithClever));
                    setSpinnerActive(false);
                    return Promise.resolve(null);
                }
                return authService.login(emailAddress, password);
            })
            .then((user) => {
                if (!user) {
                    return;
                }

                if (!user.emailVerified) {
                    setInfoMessage(i18n.get(i18nKey.stillNeedToVerifyEmail));
                    setShowVerifyLink(!user.emailVerified);
                }
                storage.save(showUpdatePWModalStorageKey, "true")
                setSpinnerActive(user.emailVerified);
            })
            .catch((error) => {
                setErrorMessage(error.message);
                setSpinnerActive(false);
            });
    };

    const signInWithCleverButtonClicked = () => {
        router.goToCleverLogin();
    };

    const sendEmailVerification = (_: MouseEvent<HTMLButtonElement>) => {
        const user = auth.firebaseUser();

        if (user === null) {
            // TODO: Try to get a user again
            return;
        }

        authService
            .sendEmailVerification(user)
            .then(() => {
                setInfoMessage(i18n.get(i18nKey.verificationEmailSent));
            })
            .catch((error) => {
                setErrorMessage(error.message);
                setInfoMessage(undefined);
            });
    };

    return (
        <React.Fragment>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col lg={4} md={5} className="col-xxl-3">
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5 text-center">
                                            <div className="d-block auth-logo">
                                                <img
                                                    data-testid="logo"
                                                    src={logo}
                                                    alt={i18n.get(i18nKey.altDtsLogo)}
                                                    height="150"
                                                />{" "}
                                            </div>
                                        </div>
                                        <div className="auth-content my-auto">
                                            <div data-testid="sign-in-copy" className="text-center">
                                                {errorMessage && (
                                                    <UncontrolledAlert data-testid="error-banner" color="danger">
                                                        {errorMessage}
                                                    </UncontrolledAlert>
                                                )}
                                                {infoMessage && (
                                                    <UncontrolledAlert data-testid="info-banner" color="warning">
                                                        {infoMessage}
                                                    </UncontrolledAlert>
                                                )}
                                                <h5 className="mb-0">{i18n.get(i18nKey.welcome)}!</h5>
                                                <p className="text-muted mt-2">{i18n.get(i18nKey.signInToContinue)}</p>
                                            </div>
                                            <Form
                                                className="custom-form mt-4 pt-2"
                                                data-testid="sign-in-form"
                                                onSubmit={handleSubmit}
                                            >
                                                <div className="mb-3">
                                                    <Label className="form-label">
                                                        {i18n.get(i18nKey.emailAddress)}
                                                    </Label>
                                                    <Input
                                                        name="email"
                                                        type="email"
                                                        data-testid="email-input"
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        onChange={handleEmailChange}
                                                        onBlur={() => handleInputBlur("email")}
                                                        value={email}
                                                        invalid={errors.email && touched.email ? true : false}
                                                    />
                                                    {errors.email && touched.email ? (
                                                        <FormFeedback type="invalid">{errors.email}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">{i18n.get(i18nKey.password)}</Label>
                                                    <div className="input-group auth-pass-inputgroup">
                                                        <Input
                                                            name="password"
                                                            type={showPw ? "text" : "password"}
                                                            data-testid="password-input"
                                                            placeholder="Enter Password"
                                                            onChange={handlePasswordChange}
                                                            onBlur={() => handleInputBlur("password")}
                                                            invalid={errors.password && touched.password ? true : false}
                                                        />
                                                        <button
                                                            onClick={() => setShowPw(!showPw)}
                                                            className="btn btn-light shadow-none ms-0"
                                                            type="button"
                                                            aria-roledescription="show password button toggle"
                                                        >
                                                            {showPw ? (
                                                                <i className="mdi mdi-eye-outline"></i>
                                                            ) : (
                                                                <i className="mdi mdi-eye-off"></i>
                                                            )}
                                                        </button>
                                                    </div>
                                                    {errors.password && touched.password ? (
                                                        <FormFeedback type="invalid">{errors.password}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div data-testid="forgot-password-copy" className="mb-3">
                                                    <p className="text-muted mb-0">
                                                        {i18n.get(i18nKey.forgotPassword)}
                                                        <NavLink
                                                            to={PathNames.ResetPasswordRequest}
                                                            className="text-primary fw-semibold"
                                                        >
                                                            {" "}
                                                            {i18n.get(i18nKey.resetPassword)}{" "}
                                                        </NavLink>{" "}
                                                    </p>
                                                </div>
                                                <div className="row mb-4">
                                                    <div className="col">
                                                        <div className="mt-4 d-grid">
                                                            {!spinnerActive && (
                                                                <>
                                                                    {showVerifyLink && (
                                                                        <button
                                                                            className="btn btn-secondary btn-block mt-4 pt-2"
                                                                            data-testid="send-email-verification-button"
                                                                            type="submit"
                                                                            aria-roledescription="send email verification primary button"
                                                                            onClick={sendEmailVerification}
                                                                        >
                                                                            {i18n.get(i18nKey.sendEmailVerification)}
                                                                        </button>
                                                                    )}
                                                                    {!showVerifyLink && (
                                                                        <>
                                                                            <button
                                                                                className="btn btn-secondary btn-block mt-4 pt-2"
                                                                                data-testid="sign-in-button"
                                                                                aria-roledescription="sign in primary button"
                                                                                type="submit"
                                                                                disabled={
                                                                                    !touched.email && !touched.password
                                                                                }
                                                                            >
                                                                                {i18n.get(i18nKey.signIn)}
                                                                            </button>
                                                                            {featureFlags.signInWithClever() && (
                                                                                <button
                                                                                    className="btn btn-primary btn-block mt-4 pt-2"
                                                                                    type="submit"
                                                                                    data-testid="sign-in-clever-button"
                                                                                    aria-roledescription="sign in clever primary button"
                                                                                    onClick={
                                                                                        signInWithCleverButtonClicked
                                                                                    }
                                                                                >
                                                                                    {i18n.get(i18nKey.signInWithClever)}
                                                                                </button>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                            {spinnerActive && (
                                                                <>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-light waves-effect"
                                                                        data-testid="spinner-button"
                                                                        aria-roledescription="sign in spinner button"
                                                                    >
                                                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                                        {i18n.get(i18nKey.spinnerSigningIn)}
                                                                    </button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <TestimonialsPageV2 />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
