import { Dictionary, LrsId } from './../global/TypeAliases'
import { AxiosRequestConfig } from "axios"
import { injectable } from "inversify"
import { container } from "../DIContainer"
import { DocumentId, Uid } from "../global/TypeAliases"
import { HTTPMethods } from "../HTTPMethods"
import { TYPES } from "../Types"
import { UUID } from "../utils/UUIDGenerator"
import { IAPIKeyProvider } from "./APIKeyProvider"
import { IDateProvider } from "./DateProvider"
import { Constants, LRSServiceConfig } from "./ServiceConfig"

export interface ISurveyServiceConfig {
    getSurveyCompletionConfig(surveyId: string, clientId: DocumentId): LRSServiceConfig
    getSurveyAttemptsConfig(surveyId: string, clientId: DocumentId): LRSServiceConfig
    postSurveyAttemptConfig(userId: Uid, clientId: DocumentId, activityId: string): LRSServiceConfig
    postSurveyProgressConfig(userId: Uid, clientId: DocumentId, activityId: string, progress: number): LRSServiceConfig
    postSurveyCompletionConfig(userId: Uid, clientId: DocumentId, activityId: string): LRSServiceConfig
    postSurveyStateConfig(userId: Uid, clientId: DocumentId, activityId: string, data: Dictionary): LRSServiceConfig
    getSurveyCompletionForUserConfig(userId: string, clientId: string, surveyId: string): LRSServiceConfig
    getSurveyProgressForUserConfig(userId: Uid, clientId: DocumentId, contentId: LrsId): LRSServiceConfig
    getSurveyStateConfig(userId: Uid, clientId: DocumentId, activityId: string): LRSServiceConfig
}

@injectable()
export class SurveyServiceConfig implements ISurveyServiceConfig {
    private dateProvider: IDateProvider = container.get<IDateProvider>(TYPES.IDateProvider)
    private apiKeyProvider: IAPIKeyProvider = container.get<IAPIKeyProvider>(TYPES.IAPIKeyProvider)
    private uuid: UUID = container.get<UUID>(TYPES.UUID)

    getSurveyCompletionConfig(surveyId: string, clientId: DocumentId): LRSServiceConfig {
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL

        const config: AxiosRequestConfig = {
            method: HTTPMethods.get,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            },
            params: {
                verb: Constants.xAPIVerbPassed,
                activity: surveyId
            }
        }

        return {
            url: learningLockerBaseUrl + Constants.statementPathUrl,
            config: config
        }
    }

    getSurveyAttemptsConfig(surveyId: string, clientId: DocumentId): LRSServiceConfig {
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL

        const config: AxiosRequestConfig = {
            method: HTTPMethods.get,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            },
            params: {
                verb: Constants.xAPIVerbAttempted,
                activity: surveyId
            }
        }

        return {
            url: learningLockerBaseUrl + Constants.statementPathUrl,
            config: config
        }
    }

    postSurveyAttemptConfig(userId: Uid, clientId: DocumentId, activityId: string): LRSServiceConfig {
        const homePageUrl = process.env.REACT_APP_ENV_LRS_HOME_PAGE_URL
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL

        const statement = {
            [Constants.authority]: {
                [Constants.objectType]: Constants.agent,
                [Constants.name]: Constants.authorityName,
                [Constants.mailboxName]: Constants.mailboxAddress
            },
            [Constants.actor]: {
                [Constants.objectType]: Constants.agent,
                [Constants.account]: {
                    [Constants.name]: userId,
                    [Constants.homePage]: homePageUrl,
                },
                [Constants.name]: userId
            },
            [Constants.verb]: {
                [Constants.id]: Constants.xAPIVerbAttempted,
                [Constants.display]: {
                    [Constants.enUS]: Constants.attempted
                }
            },
            [Constants.timestamp]: this.dateProvider.getDateAsISOString(),
            [Constants.version]: Constants.statementVersionNumber,
            [Constants.id]: this.uuid.uuidv4(),
            [Constants.result]: {
                [Constants.completion]: false,
            },
            [Constants.object]: {
                [Constants.id]: activityId,
                [Constants.objectType]: Constants.activity,
                [Constants.definition]: {
                    [Constants.typeConstant]: Constants.xAPIActivitySurvey
                }
            }
        }
        
        const config: AxiosRequestConfig = {
            method: HTTPMethods.post,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            },

            data: statement
        }
        
        return {
            url: learningLockerBaseUrl + Constants.statementPathUrl,
            config: config
        }
    }

    postSurveyProgressConfig(userId: Uid, clientId: DocumentId, activityId: string, progress: number): LRSServiceConfig {
        const homePageUrl = process.env.REACT_APP_ENV_LRS_HOME_PAGE_URL
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL

        const statement = {
            [Constants.authority]: {
                [Constants.objectType]: Constants.agent,
                [Constants.name]: Constants.authorityName,
                [Constants.mailboxName]: Constants.mailboxAddress
            },
            [Constants.actor]: {
                [Constants.objectType]: Constants.agent,
                [Constants.account]: {
                    [Constants.name]: userId,
                    [Constants.homePage]: homePageUrl,
                },
                [Constants.name]: userId
            },
            [Constants.verb]: {
                [Constants.id]: Constants.xAPIVerbProgressed,
                [Constants.display]: {
                    [Constants.enUS]: Constants.progressed
                }
            },
            [Constants.timestamp]: this.dateProvider.getDateAsISOString(),
            [Constants.version]: Constants.statementVersionNumber,
            [Constants.id]: this.uuid.uuidv4(),
            [Constants.result]: {
                [Constants.extensions]: {
                    [Constants.xAPIExtensionProgress]: progress
                },
            },
            [Constants.object]: {
                [Constants.id]: activityId,
                [Constants.objectType]: Constants.activity,
                [Constants.definition]: {
                    [Constants.typeConstant]: Constants.xAPIActivitySurvey
                }
            }
        }
        
        const config: AxiosRequestConfig = {
            method: HTTPMethods.post,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            },

            data: statement
        }
        
        return {
            url: learningLockerBaseUrl + Constants.statementPathUrl,
            config: config
        }
    }

    postSurveyCompletionConfig(userId: Uid, clientId: DocumentId, activityId: string): LRSServiceConfig {
        const homePageUrl = process.env.REACT_APP_ENV_LRS_HOME_PAGE_URL
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL

        const statement = {
            [Constants.authority]: {
                [Constants.objectType]: Constants.agent,
                [Constants.name]: Constants.authorityName,
                [Constants.mailboxName]: Constants.mailboxAddress
            },
            [Constants.actor]: {
                [Constants.objectType]: Constants.agent,
                [Constants.account]: {
                    [Constants.name]: userId,
                    [Constants.homePage]: homePageUrl,
                },
                [Constants.name]: userId
            },
            [Constants.verb]: {
                [Constants.id]: Constants.xAPIVerbPassed,
                [Constants.display]: {
                    [Constants.enUS]: Constants.passed
                }
            },
            [Constants.timestamp]: this.dateProvider.getDateAsISOString(),
            [Constants.version]: Constants.statementVersionNumber,
            [Constants.id]: this.uuid.uuidv4(),
            [Constants.result]: {
                [Constants.success]: true,
                [Constants.completion]: true
            },
            [Constants.object]: {
                [Constants.id]: activityId,
                [Constants.objectType]: Constants.activity,
                [Constants.definition]: {
                    [Constants.typeConstant]: Constants.xAPIActivitySurvey
                }
            }
        }
        
        const config: AxiosRequestConfig = {
            method: HTTPMethods.post,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            },

            data: statement
        }
        
        return {
            url: learningLockerBaseUrl + Constants.statementPathUrl,
            config: config
        }
    }

    postSurveyStateConfig(userId: Uid, clientId: DocumentId, activityId: string, data: Dictionary): LRSServiceConfig {
        const homePageUrl = process.env.REACT_APP_ENV_LRS_HOME_PAGE_URL
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL
        
        const agent = {
            [Constants.account]: {
                [Constants.name]: userId,
                [Constants.homePage]: homePageUrl
            }
        }

        const config: AxiosRequestConfig = {
            method: HTTPMethods.post,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            },
            params: {
                activityId: activityId,
                agent: agent,
                stateId: userId
            },
            data: data
        }
        
        return {
            url: learningLockerBaseUrl + Constants.statePathUrl,
            config: config
        }
    }

    getSurveyCompletionForUserConfig(userId: string, surveyId: string, clientId: string): LRSServiceConfig {
        const homePageUrl = process.env.REACT_APP_ENV_LRS_HOME_PAGE_URL
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL

        const agent = {
            [Constants.account]: {
                [Constants.name]: userId,
                [Constants.homePage]: homePageUrl
            }
        }
        
        const config: AxiosRequestConfig = {
            method: HTTPMethods.get,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            },

            params: {
                verb: Constants.xAPIVerbPassed,
                agent: agent,
                activity: surveyId
            }
        }

        return {
            url: learningLockerBaseUrl + Constants.statementPathUrl,
            config: config
        }
    }

    getSurveyProgressForUserConfig(userId: Uid, clientId: DocumentId, contentId: LrsId): LRSServiceConfig {
        const homePageUrl = process.env.REACT_APP_ENV_LRS_HOME_PAGE_URL
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL

        const agent = {
            [Constants.account]: {
                [Constants.name]: userId,
                [Constants.homePage]: homePageUrl
            },
            [Constants.name]: userId
        }
        
        const config: AxiosRequestConfig = {
            method: HTTPMethods.get,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            },

            params: {
                verb: Constants.xAPIVerbProgressed,
                agent: agent,
                activity: contentId,
                ascending: false
            }
        }

        return {
            url: learningLockerBaseUrl + Constants.statementPathUrl,
            config: config
        }
    }

    getSurveyStateConfig(userId: Uid, clientId: DocumentId, activityId: string): LRSServiceConfig {
        const homePageUrl = process.env.REACT_APP_ENV_LRS_HOME_PAGE_URL
        const learningLockerBaseUrl = process.env.REACT_APP_ENV_LEARNING_LOCKER_URL
        
        const agent = {
            [Constants.account]: {
                [Constants.name]: userId,
                [Constants.homePage]: homePageUrl
            }
        }

        const config: AxiosRequestConfig = {
            method: HTTPMethods.get,
            headers: {
                [Constants.authorization]: this.apiKeyProvider.learningLockerKeyForClientId(clientId),
                [Constants.xApiVersionKey]: Constants.xApiVersion
            },
            params: {
                activityId: activityId,
                agent: agent,
                stateId: userId
            }
        }
        
        return {
            url: learningLockerBaseUrl + Constants.statePathUrl,
            config: config
        }

    }
}