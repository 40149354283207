import { Component, FormEvent, MouseEvent } from "react"
import { NavLink } from "react-router-dom"
import { 
	Image,
	MessageBar, 
	MessageBarType,
	Text,
	Stack, 
} from '@fluentui/react'
import { AuthenticationUIStyles, AuthenticationUITokens } from "./AuthenticationUI"
import logoLarge from "../../images/logo-large.svg"
import { TextField } from '../../ui-kit/TextField'
import { PrimaryButton } from "../../ui-kit/PrimaryButton"
import { container } from "../../DIContainer"
import { TYPES } from "../../Types"
import { Ii18n, i18nKey } from "../../global/i18n"
import { Spinner } from "@fluentui/react"
import { IRouter } from "../../Router"
import { IResetPasswordCompositionService } from "../../services/ResetPasswordCompositionService"

interface ResetPasswordRequestPageProps {}

interface ResetPasswordRequestPageState {
	primaryButtonDisabled: boolean
	showSpinner: boolean
	emailAddress: string
	sent: boolean
	errorMessage: string | null
}

export default class ResetPasswordRequestPage extends Component<ResetPasswordRequestPageProps, ResetPasswordRequestPageState> {
	private compositionService: IResetPasswordCompositionService = container.get<IResetPasswordCompositionService>(TYPES.IResetPasswordCompositionService)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n) 
	private router: IRouter = container.get<IRouter>(TYPES.IRouter)

	state: ResetPasswordRequestPageState = {
		primaryButtonDisabled: true,
		showSpinner: false,
		emailAddress: "",
		sent: false,
		errorMessage: null,
	}
	
	componentDidMount() {
        document.getElementById('container')?.classList.add('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.add('hidden')
        document.getElementById('navigation-bar')?.classList.add('hidden')
    }

    componentWillUnmount() {
        document.getElementById('container')?.classList.remove('no-padding', 'no-margin')
        document.getElementById('navigation-bar-container')?.classList.remove('hidden')
        document.getElementById('navigation-bar')?.classList.remove('hidden')
	}

    render() {
		if (this.state.sent) {
			const data = {
				email: this.state.emailAddress
			}
			this.router.goToPasswordResetSent(data)
		}

		return (
			<Stack horizontalAlign="center" styles={AuthenticationUIStyles.containerStyle}>
				<Stack horizontalAlign="center" styles={AuthenticationUIStyles.logoStyle}>
					<Image 
						id="dts-logo" 
						src={logoLarge} 
						alt={this.i18n.get(i18nKey.altDtsLogo)} 
						width={230} 
						height={100} 
					/>
				</Stack>

				<Stack tokens={AuthenticationUITokens.verticalGapStackTokens} styles={AuthenticationUIStyles.formStackStyles}>
					{this.state.errorMessage != null && 
						<MessageBar
							id="reset-password-error-banner"
							messageBarType={MessageBarType.error}
							onDismiss={this.dismissBanner}>
							{this.state.errorMessage}
						</MessageBar>
					}
					<Stack tokens={AuthenticationUITokens.formFieldGapStackTokens}>
						<TextField
							id="reset-password-email-textfield"
							label={this.i18n.get(i18nKey.emailAddress)}
							type="email"
							onChange={this.emailAddressTextFieldChanged}
							autoComplete="username"
						/>

						{ !this.state.showSpinner &&
							<PrimaryButton
								id="reset-password-primary-button"
								text={this.i18n.get(i18nKey.sendResetEmail)}
								onClick={this.submitButtonClicked}
								disabled={this.state.primaryButtonDisabled}
							/>
						}

						{ this.state.showSpinner &&
							<Spinner
								id="reset-password-spinner"
								ariaLive="assertive"
								labelPosition="right"
							/>
						}
					</Stack>
					<Stack horizontalAlign="center">
						<Text variant="medium">
							<NavLink to="/login" id="back-to-login-link">{this.i18n.get(i18nKey.backToLogin)}</NavLink>
						</Text>
					</Stack>
				</Stack>
			</Stack>
        )
    }

	submitButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({ 
			errorMessage: null, 
			showSpinner: true, 
			primaryButtonDisabled: true 
		})

		this.compositionService
			.handleResetRequestFor(this.state.emailAddress)
			.then(() => {
                this.setState({ 
					sent: true, 
					showSpinner: false, 
					primaryButtonDisabled: false 
				})
			})
			.catch(error => {				
				this.setState({ 
					errorMessage: error.message,
					showSpinner: false, 
					primaryButtonDisabled: false
				})
			})
	}

	emailAddressTextFieldChanged = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({ emailAddress: newValue? newValue : ""}, () => {
			this.disableResetButtonIfNeeded()
		})
	}

	disableResetButtonIfNeeded = () => {
		var disable = true

		if (this.state.emailAddress.length > 0) {
			disable = false
		}

		this.setState({ primaryButtonDisabled: disable })
	}

	dismissBanner = () => {
		this.setState({ errorMessage: null })
	}
}