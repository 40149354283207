import { Component, MouseEvent } from "react"
import IClientService from "../../../services/ClientService"
import {
	Stack,
	IStackTokens,
	Spinner,
	MessageBar,
	MessageBarType,
} from "@fluentui/react"
import { TabPanelProps } from "../TabPanelProps"
import "./RemoveCohortFromClient.css"
import DTSTable from "../../../ui-kit/DTSTable"
import DTSTableData from "../../../models/DTSTableData"
import IDataTableFactory from "../../../factories/DataTableFactory"
import { log } from "../../../global/Logger"
import { Loader } from "../../../ui-kit/Loader"
import { container } from "../../../DIContainer"
import { TYPES } from "../../../Types"
import { DestructiveButton } from "../../../ui-kit/DestructiveButton"
import { i18nKey, Ii18n } from "../../../global/i18n"

interface RemoveCohortFromClientProps extends TabPanelProps {
	documentId: string
}

interface RemoveCohortFromClientState {
	tableData: DTSTableData | undefined
	destructiveButtonDisabled: boolean
	showSpinner: boolean
	loadingData: boolean
	errorMessage: string | undefined
	successMessage: string | undefined
    selectedCohorts: string[]
    clearSelectedRows: boolean
}

const stackStyling: IStackTokens = {
	childrenGap: "20px",
}

export default class RemoveCohortFromClient extends Component<RemoveCohortFromClientProps, RemoveCohortFromClientState> {
	private clientService: IClientService = container.get<IClientService>(TYPES.IClientService)
	private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
	private i18n: Ii18n = container.get<Ii18n>(TYPES.Ii18n)

	state: RemoveCohortFromClientState = {
		loadingData: false,
		tableData: undefined,
		destructiveButtonDisabled: true,
		showSpinner: false,
		errorMessage: undefined,
		successMessage: undefined,
		selectedCohorts: [],
		clearSelectedRows: false
	}

	componentDidMount() {
		this.loadData()
	}

	render() {
		if (this.state.loadingData) {
			return <Loader />
		}

		let banner = null
		if (this.state.errorMessage) {
			banner = (
				<MessageBar
					id="remove-cohort-from-client-error-banner"
					messageBarType={MessageBarType.error}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.errorMessage}
				</MessageBar>
			)
		} else if (this.state.successMessage) {
			banner = (
				<MessageBar
					id="remove-cohort-from-client-success-banner"
					messageBarType={MessageBarType.success}
					dismissButtonAriaLabel="Close"
					onDismiss={this.dismissBanner}
				>
					{this.state.successMessage}
				</MessageBar>
			)
		}

		return (
			<div id="remove-cohort-from-client-modal-container">
				<Stack tokens={stackStyling}>
					{this.state.tableData !== undefined && (
						<div id="remove-cohort-from-client-table">
							<Stack tokens={stackStyling}>
								<DTSTable
									data={this.state.tableData}
									pagination={true}
                                    onSelectedRowsChange={this.onSelectedRowsChange}
                                    clearSelectedRows={this.state.clearSelectedRows}
								/>

								{ !this.state.showSpinner &&
									<DestructiveButton
										id="remove-cohort-from-client-destructive-button"
										text={this.i18n.get(i18nKey.removeGroups)}
										onClick={this.destructiveButtonClicked}
										disabled={this.state.destructiveButtonDisabled}
										hidden={this.state.showSpinner}
									/>
								}

								{ this.state.showSpinner &&
									<Spinner
										label="Removing"
										ariaLive="assertive"
										labelPosition="right"
									/>
								}
							</Stack>
						</div>
					)}

					{banner}

				</Stack>
			</div>
		)
	}

    onSelectedRowsChange = (selectedRowState: {
		allSelected: boolean
		selectedCount: number
		selectedRows: { [key: string]: any }[]
	}) => {
		if (selectedRowState.selectedCount === 0) {
			this.setState({ selectedCohorts: [] }, () => {
				this.enableDestructiveButtonIfNeeded()
			})
			return
		}

        const selectedIds = selectedRowState.selectedRows.map((row) => {
			return row.id
		})

		log(selectedIds.length + " cohorts selected")

		this.setState({ selectedCohorts: selectedIds }, () => {
			this.enableDestructiveButtonIfNeeded()
		})
	}

	private loadData = () => {
		this.setState({ loadingData: true })
		this.clientService
			.getCohortsForClient(this.props.documentId)
			.then((cohorts) => {
				const data = this.dataTableFactory.createCohortsTableData(cohorts)
				this.setState({ loadingData: false, tableData: data, clearSelectedRows: false })
			})
			.catch(error => {
				this.setState({ loadingData: false, errorMessage: error.message })
			})
	}

	dismissBanner = () => {
		this.setState({ errorMessage: undefined, successMessage: undefined })
	}

	destructiveButtonClicked = (_: MouseEvent<HTMLButtonElement>) => {
		this.setState({
			errorMessage: undefined,
			successMessage: undefined,
			showSpinner: true,
			destructiveButtonDisabled: true,
		})

		this.clientService
			.removeCohortsFromClient(this.state.selectedCohorts, this.props.documentId)
			.then(() => {
				let message: string
                if (this.state.selectedCohorts.length === 1) {
                    message = "1 cohort removed"
                } else {
                    message = this.state.selectedCohorts.length + " cohorts removed"
                }

				this.setState({
					successMessage: message,
					showSpinner: false,
					destructiveButtonDisabled: true,
                    selectedCohorts: [],
                    clearSelectedRows: true
				})

				this.loadData()
			})
			.catch(error => {
				this.setState({
					errorMessage: error.message,
					showSpinner: false,
					destructiveButtonDisabled: false,
				})
			})
	}

	enableDestructiveButtonIfNeeded = () => {
		let disable = true

		if (this.state.selectedCohorts !== undefined && this.state.selectedCohorts.length > 0) {
			disable = false
		}

		this.setState({ destructiveButtonDisabled: disable })
	}
}
